<template>
  <div>
    <GameCard1
      title="限制容器性能规格"
      actions="指定容器的启动参数，<br>可限制CPU，内存，IO性能等。<br>自主探索。"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
在限制容器性能和虚拟机性能方面，容器和虚拟机都通过控制资源的分配来管理性能，但它们的机制和实现方式有较大差异。以下是对 Docker 容器和虚拟机性能限制方法的比较。

### 1. **限制容器性能的方法（Docker）**

#### a) **CPU 限制**

- **\`--cpus\`**：限制容器可使用的 CPU 核心数。例如，\`--cpus="0.5"\` 限制容器使用 50% 的一个 CPU 核心。
  
  \`\`\`bash
  docker run --cpus="0.5" <image>
  \`\`\`

- **\`--cpuset-cpus\`**：指定容器可以使用的 CPU 核心。例如，\`--cpuset-cpus="0,1"\` 限制容器只能在 CPU 0 和 CPU 1 上运行。
  
  \`\`\`bash
  docker run --cpuset-cpus="0,1" <image>
  \`\`\`

- **\`--cpu-shares\`**：相对 CPU 权重，设置为整数，值越大，获得的 CPU 资源比例越大，默认值为 1024。

  \`\`\`bash
  docker run --cpu-shares=512 <image>
  \`\`\`

#### b) **内存限制**

- **\`--memory\`**：设置容器的最大内存。例如，\`--memory="512m"\` 限制容器最大可使用 512MB 内存。
  
  \`\`\`bash
  docker run --memory="512m" <image>
  \`\`\`

- **\`--memory-swap\`**：限制容器的内存和交换内存总量。如果设置为 1GB，则总内存（RAM + Swap）不能超过 1GB。

  \`\`\`bash
  docker run --memory="512m" --memory-swap="1g" <image>
  \`\`\`

#### c) **I/O 限制**

- **\`--blkio-weight\`**：设置容器的块 I/O 权重，范围为 10-1000。值越高，容器的 I/O 优先级越高。

  \`\`\`bash
  docker run --blkio-weight=500 <image>
  \`\`\`

- **\`--device-read-bps\` 和 \`--device-write-bps\`**：限制设备的读写速率。例如，\`--device-read-bps\` 限制 \`/dev/sda\` 的读取速率为 1MB/s。

  \`\`\`bash
  docker run --device-read-bps /dev/sda:1mb <image>
  \`\`\`

#### d) **网络限制**

- **自定义网络插件**：可以通过第三方插件（如 Cilium、Calico）实现复杂的网络限速和 QoS 管理。
  
  **\`tc\`命令**：通过 Linux 的 \`tc\` 工具，可以对 Docker 容器的网络带宽进行限速和控制。

### 2. **限制虚拟机性能的方法（KVM/VMware/VirtualBox 等）**

#### a) **CPU 限制**

- **vCPU 核心数**：虚拟机可以指定虚拟 CPU 的数量，通常通过虚拟化平台的设置指定。例如，给虚拟机分配 2 个虚拟 CPU 核心。

  \`\`\`bash
  # 在 KVM/VMware 中通过管理界面设置
  \`\`\`

- **CPU 限额**：部分虚拟化平台支持为虚拟机设置 CPU 占用率的上限，确保虚拟机不会使用超过指定百分比的 CPU 资源。例如，在 VMware 中可以设置 CPU 预留和限制。

- **CPU 优先级**：部分虚拟化平台（如 VMware）支持为虚拟机设置 CPU 共享比例和优先级。

#### b) **内存限制**

- **虚拟内存分配**：虚拟机的内存是静态分配的，可以通过管理工具直接为虚拟机分配固定大小的内存。例如，给虚拟机分配 4GB 内存。

  \`\`\`bash
  # 在 KVM/VMware 中通过管理界面设置
  \`\`\`

- **内存预留**：一些虚拟化平台支持预留内存，确保虚拟机在需要时可以获得足够的内存资源。

- **内存超分配**：虚拟化平台可以通过超分配技术（memory overcommit）允许多个虚拟机共享物理内存，这样总内存分配可以超过物理内存的总和。

#### c) **I/O 限制**

- **磁盘 I/O 限制**：虚拟化平台通常支持设置磁盘的 IOPS 限制，确保虚拟机的磁盘操作不会过度占用主机资源。例如，在 VMware 中可以设置虚拟机的 I/O 限制。

- **带宽控制**：在某些虚拟化平台上，可以通过设置虚拟机的网络带宽上限或最低带宽。

#### d) **网络限制**

- **网络带宽控制**：虚拟化平台通常可以通过流量整形、QoS（服务质量）等技术限制虚拟机的网络带宽。例如，KVM 支持通过 \`tc\` 命令为虚拟机设置网络限速。

  \`\`\`bash
  # 使用 tc 设置网络限速
  tc qdisc add dev eth0 root tbf rate 100mbit burst 32kbit latency 400ms
  \`\`\`

### 3. **比较：容器 vs 虚拟机性能限制**

| 特性                 | Docker 容器                                            | 虚拟机                                               |
|----------------------|--------------------------------------------------------|------------------------------------------------------|
| **CPU 限制**         | 动态限制单个容器的 CPU 核心数和权重，通过 cgroups 实现  | 分配虚拟 CPU 数量，通常通过管理工具静态分配         |
| **内存限制**         | 使用 \`--memory\` 动态限制内存和交换分区                   | 分配固定大小的内存，可支持内存超分配                |
| **I/O 限制**         | 限制容器的磁盘读写速率和块 I/O 权重                      | 支持虚拟机磁盘 IOPS 和带宽控制                       |
| **网络限制**         | 使用 \`tc\` 或网络插件来控制网络带宽                      | 通过管理平台或 \`tc\` 控制网络流量，支持 QoS         |
| **资源隔离机制**     | 基于 Linux 内核的 cgroups 和 namespaces                 | 完全虚拟化环境下的硬件隔离，通过 hypervisor 管理    |
| **弹性扩展**         | 容器启动和资源分配更快，适合微服务架构                  | 虚拟机资源分配较慢，但可以运行完整的操作系统        |
| **资源利用效率**     | 更高效，接近裸机性能，启动时间更快                      | 性能比容器稍低，启动时间和资源开销较大              |

### 总结1

- **容器（Docker）** 使用 Linux 内核的 \`cgroups\` 和 \`namespaces\` 实现资源的动态分配和隔离，适合在微服务和分布式系统中使用。Docker 容器启动快，资源利用率高，但隔离级别相对虚拟机略弱。
- **虚拟机** 使用完整的硬件虚拟化技术（如 KVM、VMware）提供更强的隔离和安全性，适合运行独立的操作系统。虚拟机的资源限制主要依赖于 hypervisor 提供的管理工具，启动和资源分配速度较慢，但隔离性强。

容器适合轻量化、高密度、弹性扩展的场景，虚拟机适合需要完全操作系统隔离和更强安全性的场景。

## Nginx为例
Nginx 的最小运行规格非常低，因为它设计为高效的轻量级 HTTP 服务器和反向代理。在资源受限的环境中，Nginx 仍然能够稳定运行。以下是一些关于 Nginx 最小运行需求的说明：

### 1. **CPU 最小规格**

Nginx 对 CPU 的要求很低，特别是在负载较小的情况下：

- **0.1 个 CPU 核心是可行的**：对于静态内容服务或低负载下的反向代理，Nginx 可以在非常有限的 CPU 资源下运行。\`--cpus="0.1"\` 相当于允许 Nginx 使用 10% 的 CPU 资源（一个 CPU 核心的 10%），在这种情况下，Nginx 仍然可以运行。

  **测试场景**：Nginx 在 \`0.1\` 核心的 CPU 下可以正常启动并服务于小规模请求，但在高并发或复杂请求处理中，可能会出现性能瓶颈。

  \`\`\`bash
  docker run --cpus="0.1" nginx
  \`\`\`

### 2. **内存最小规格**

Nginx 的内存占用也非常低，主要受配置文件的复杂性和所处理的请求量影响。

- **200MB 内存是可行的**：Nginx 本身启动时消耗的内存非常少，通常在 10MB 到 50MB 之间，具体取决于配置和模块的数量。200MB 的内存对 Nginx 来说足够满足静态网站、小型应用代理的需求。

  - 如果处理的并发连接数较大，Nginx 可能会需要更多内存，尤其是 SSL 终端或代理较多请求时，但 200MB 对于大多数基本场景是绰绰有余的。

  \`\`\`bash
  docker run --memory="200m" nginx
  \`\`\`

### 3. **实际资源消耗**

- **Nginx 的基本进程占用**：通常，Nginx 的主进程占用较少的 CPU 和内存，子进程根据请求的数量和类型进行扩展。
- **静态内容 vs 动态代理**：Nginx 服务静态内容时的资源消耗非常低，但作为反向代理，尤其是 SSL 处理、大量并发连接的情况下，内存和 CPU 消耗会增加。

### 4. **在低资源配置下的性能注意事项**

- **限制并发连接数**：在低资源的环境中，控制并发连接数是关键，可以通过调整 Nginx 的 \`worker_connections\` 和 \`worker_processes\` 参数来优化。
  
  \`\`\`nginx
  worker_processes  1;
  events {
      worker_connections  1024;
  }
  \`\`\`

- **日志管理**：为了减少 IO 操作，可以关闭或减少访问日志的记录频率。

  \`\`\`nginx
  access_log off;
  \`\`\`

### 总结2

- **CPU**：Nginx 可以在非常有限的 CPU 资源下运行，0.1 个 CPU 核心（即 10% 的 CPU）是可行的，但在高并发或复杂场景中可能会受限。
- **内存**：200MB 内存对于大多数轻量级的 Nginx 部署来说是足够的，特别是静态内容或小型反向代理的场景。对于更复杂的工作负载，如大量 SSL 请求或高并发，可能需要更多内存。

Nginx 的轻量级特性使得它非常适合在资源有限的环境中运行。如果需求简单，可以进一步减少资源分配，保持性能的平衡。        
        `,
      interviewContent: `
### 选择题

#### 1. **哪个参数用于限制容器使用的 CPU 数量？**
   A) \`--cpu-shares\`  
   B) \`--cpus\`  
   C) \`--cpuset-cpus\`  
   D) \`--memory\`

#### 2. **Docker 中哪一个命令可以限制容器使用的内存？**
   A) \`--mem-limit\`  
   B) \`--memory\`  
   C) \`--mem-reserve\`  
   D) \`--memory-cap\`

#### 3. **下列哪一个参数能够设置容器的 I/O 权重？**
   A) \`--blkio-weight\`  
   B) \`--io-shares\`  
   C) \`--disk-limit\`  
   D) \`--storage-weight\`

#### 4. **\`--cpuset-cpus\` 参数的作用是？**
   A) 设置容器的 CPU 使用时间上限  
   B) 限制容器使用的 CPU 核心数  
   C) 设定容器可以使用的 CPU 核心列表  
   D) 动态调整容器 CPU 使用的优先级

#### 5. **使用 Docker 限制容器的内存时，默认值会是什么？**
   A) 512 MB  
   B) 1 GB  
   C) 4 GB  
   D) 没有限制

---

### 问答题

#### 1. **解释 \`--cpu-shares\` 参数的作用以及它和 \`--cpus\` 的区别。**

#### 2. **当为 Docker 容器使用 \`--memory\` 和 \`--memory-swap\` 参数时，分别限制了什么？请详细解释这些参数的含义和作用。**

#### 3. **Docker 中如何限制容器的网络带宽？除了 \`tc\` 命令之外，还有哪些工具或插件可以实现网络限速？**

#### 4. **为什么 \`--blkio-weight\` 在 I/O 密集型的容器中很重要？请解释块 I/O 权重对容器性能的影响。**

#### 5. **如果你要在容器中运行一个内存密集型的应用程序，如何为它正确配置 Docker 的性能参数？哪些参数需要特别关注？**

---

### 答案

#### 选择题答案：

1. **B) --cpus**  
   \`--cpus\` 用于限制容器的 CPU 使用量，指定容器最多可以使用的 CPU 核心数量。

2. **B) --memory**  
   \`--memory\` 用于限制容器的最大内存。

3. **A) --blkio-weight**  
   \`--blkio-weight\` 设置容器的块 I/O 权重，影响容器的磁盘读写优先级。

4. **C) 设定容器可以使用的 CPU 核心列表**  
   \`--cpuset-cpus\` 用于指定容器只能运行在特定的 CPU 核心上。

5. **D) 没有限制**  
   如果未指定 \`--memory\` 参数，默认情况下容器可以使用主机上的全部可用内存。

#### 问答题答案：

1. **\`--cpu-shares\` 参数的作用以及它和 \`--cpus\` 的区别：**  
   \`--cpu-shares\` 用于设置容器的相对 CPU 权重，影响多个容器之间 CPU 资源的分配。默认值为 1024，数值越大，容器得到的 CPU 资源越多。  
   \`--cpus\` 则直接限制容器可以使用的 CPU 核心数，是一个绝对限制。\`--cpu-shares\` 是相对权重，而 \`--cpus\` 是绝对数量。

2. **\`--memory\` 和 \`--memory-swap\` 参数的作用：**  
   - \`--memory\`：用于限制容器的最大内存使用量。如果容器使用的内存超过这个限制，容器可能会被系统终止。  
   - \`--memory-swap\`：定义容器可使用的总内存量（内存+交换内存）。例如，\`--memory=512m --memory-swap=1g\` 表示容器可以使用 512MB 的 RAM 和 512MB 的 swap，总共 1GB。

3. **限制容器网络带宽的工具：**  
   Docker 没有内置的参数来直接限制容器的网络带宽，通常通过 Linux 的 \`tc\` 命令来实现。此外，第三方网络插件如 Calico 和 Cilium 可以提供更复杂的网络带宽控制和 QoS 管理。

4. **\`--blkio-weight\` 在 I/O 密集型容器中的重要性：**  
   \`--blkio-weight\` 控制容器的磁盘 I/O 权重，在多个容器共享磁盘资源的情况下尤为重要。它确保 I/O 密集型容器不会完全占用主机的磁盘资源，而是根据权重合理分配。

5. **配置内存密集型容器的 Docker 参数：**  
   - 使用 \`--memory\` 限制容器的内存，确保不会超出系统允许的范围。  
   - 使用 \`--memory-swap\` 来合理设置交换内存的使用。  
   - 可以根据应用的需求调整 \`--oom-kill-disable\` 来防止内存不足时容器被杀死。
      
      `,
    };
  },
});
</script>
<style scoped></style>
