<template>
  <div class="dsp">
    <el-tooltip
      class="box-item"
      effect="dark"
      content="关闭和展开"
      placement="top-start"
    >
      <div class="toggle-button" @click="toggleSidebar">
        <el-icon v-if="isSidebarOpen">
          <Fold />
        </el-icon>
        <el-icon v-else>
          <Expand />
        </el-icon>
      </div>
    </el-tooltip>

    <div class="search-box">
      <el-input
        placeholder="搜索命令"
        v-model="searchQuery"
        @input="searchCommand"
        clearable
      />
      <ul v-if="searchResults.length > 0" class="search-results">
        <li v-for="(result, index) in searchResults" :key="index">
          <el-link
            type="primary"
            @click="openComponent(result.component)"
            class="search-result-link"
          >
            {{ result.label }}
          </el-link>
        </li>
      </ul>
    </div>

    <div v-show="isSidebarOpen" class="sidebar">
      <ul>
        <li v-for="(button, index) in buttons" :key="index">
          <el-link
            type="primary"
            @click="currentComponent = button.component"
            class="sidebar-link"
          >
            {{ button.label }}
          </el-link>
        </li>
      </ul>
    </div>

    <div class="content">
      <component :is="currentComponent" />
    </div>
  </div>
</template>

<script scoped>
import { ref } from "vue";
import { ElLink } from "element-plus";
import { Fold, Expand } from "@element-plus/icons-vue";
import DefaultBasic from "./cmd/0/DefaultBasic.vue";

import CmdAlias from "./cmd/a/CmdAlias.vue";
import CmdAdduser from "./cmd/a/CmdAdduser.vue";
import CmdAptGet from "./cmd/a/CmdAptGet.vue";
import CmdArch from "./cmd/a/CmdArch.vue";
import CmdAscii from "./cmd/a/CmdAscii.vue";
import CmdAt from "./cmd/a/CmdAt.vue";
import CmdAwk from "./cmd/a/CmdAwk.vue";

import CmdBase32 from "./cmd/b/CmdBase32.vue";
import CmdBase64 from "./cmd/b/CmdBase64.vue";
import CmdBasename from "./cmd/b/CmdBasename.vue";
import CmdBc from "./cmd/b/CmdBc.vue";
import CmdBg from "./cmd/b/CmdBg.vue";
import CmdBlkid from "./cmd/b/CmdBlkid.vue";

import CmdCat from "./cmd/c/CmdCat.vue";
import CmdCd from "./cmd/c/CmdCd.vue";
import CmdChage from "./cmd/c/CmdChage.vue";
import CmdChmod from "./cmd/c/CmdChmod.vue";
import CmdChattr from "./cmd/c/CmdChattr.vue";
import CmdChown from "./cmd/c/CmdChown.vue";
import CmdCp from "./cmd/c/CmdCp.vue";
import CmdCrontab from "./cmd/c/CmdCrontab.vue";
import CmdCurl from "./cmd/c/CmdCurl.vue";

import CmdDate from "./cmd/d/CmdDate.vue";
import CmdDeclare from "./cmd/d/CmdDeclare.vue";
import CmdDd from "./cmd/d/CmdDd.vue";
import CmdDiff from "./cmd/d/CmdDiff.vue";
import CmdDig from "./cmd/d/CmdDig.vue";
import CmdDir from "./cmd/d/CmdDir.vue";
import CmdDisown from "./cmd/d/CmdDisown.vue";
import CmdDos2unix from "./cmd/d/CmdDos2unix.vue";
import CmdDepmod from "./cmd/d/CmdDepmod.vue";
import CmdDf from "./cmd/d/CmdDf.vue";
import CmdDmesg from "./cmd/d/CmdDmesg.vue";
import CmdDmidecode from "./cmd/d/CmdDmidecode.vue";
import CmdDnf from "./cmd/d/CmdDnf.vue";
import CmdDu from "./cmd/d/CmdDu.vue";

import CmdEcho from "./cmd/e/CmdEcho.vue";
import CmdEnv from "./cmd/e/CmdEnv.vue";
import CmdExit from "./cmd/e/CmdExit.vue";

import CmdFdisk from "./cmd/f/CmdFdisk.vue";
import CmdFg from "./cmd/f/CmdFg.vue";
import CmdFile from "./cmd/f/CmdFile.vue";
import CmdFind from "./cmd/f/CmdFind.vue";
import CmdFree from "./cmd/f/CmdFree.vue";

import CmdGCC from "./cmd/g/CmdGCC.vue";
import CmdGrowpart from "./cmd/g/CmdGrowpart.vue";
import CmdGetent from "./cmd/g/CmdGetent.vue";
import CmdGrep from "./cmd/g/CmdGrep.vue";
import CmdGroupadd from "./cmd/g/CmdGroupadd.vue";
import CmdGroupdel from "./cmd/g/CmdGroupdel.vue";
import CmdGroupmod from "./cmd/g/CmdGroupmod.vue";
import CmdGroups from "./cmd/g/CmdGroups.vue";
import CmdGunzip from "./cmd/g/CmdGunzip.vue";
import CmdGzip from "./cmd/g/CmdGzip.vue";

import CmdHalt from "./cmd/h/CmdHalt.vue";
import CmdHead from "./cmd/h/CmdHead.vue";
import CmdHelp from "./cmd/h/CmdHelp.vue";
import CmdHistory from "./cmd/h/CmdHistory.vue";
import CmdHostname from "./cmd/h/CmdHostname.vue";
import CmdHostnamectl from "./cmd/h/CmdHostnamectl.vue";
import CmdHwclock from "./cmd/h/CmdHwclock.vue";

import CmdId from "./cmd/i/CmdId.vue";
import CmdIf from "./cmd/i/CmdIf.vue";
import CmdIfconfig from "./cmd/i/CmdIfconfig.vue";
import CmdInit from "./cmd/i/CmdInit.vue";
import CmdIp from "./cmd/i/CmdIp.vue";
import CmdIptables from "./cmd/i/CmdIptables.vue";

import CmdJobs from "./cmd/j/CmdJobs.vue";
import CmdJournalctl from "./cmd/j/CmdJournalctl.vue";

import CmdKill from "./cmd/k/CmdKill.vue";
import CmdKillall from "./cmd/k/CmdKillall.vue";
import CmdKmod from "./cmd/k/CmdKmod.vue";

import CmdLast from "./cmd/l/CmdLast.vue";
import CmdLsof from "./cmd/l/CmdLsof.vue";
import CmdLsblk from "./cmd/l/CmdLsblk.vue";
import CmdLocalectl from "./cmd/l/CmdLocalectl.vue";
import CmdLshw from "./cmd/l/CmdLshw.vue";
import CmdLastb from "./cmd/l/CmdLastb.vue";
import CmdLscpu from "./cmd/l/CmdLscpu.vue";
import CmdLsmem from "./cmd/l/CmdLsmem.vue";
import CmdLdd from "./cmd/l/CmdLdd.vue";
import CmdLess from "./cmd/l/CmdLess.vue";
import CmdLet from "./cmd/l/CmdLet.vue";
import CmdLn from "./cmd/l/CmdLn.vue";
import CmdLocale from "./cmd/l/CmdLocale.vue";
import CmdLogger from "./cmd/l/CmdLogger.vue";
import CmdLogout from "./cmd/l/CmdLogout.vue";
import CmdLs from "./cmd/l/CmdLs.vue";
import CmdLsattr from "./cmd/l/CmdLsattr.vue";
import CmdLspci from "./cmd/l/CmdLspci.vue";
import CmdLsusb from "./cmd/l/CmdLsusb.vue";
import CmdLsmod from "./cmd/l/CmdLsmod.vue";
import CmdLosetup from "./cmd/l/CmdLosetup.vue";

import CmdMan from "./cmd/m/CmdMan.vue";
import CmdMandb from "./cmd/m/CmdMandb.vue";
import CmdMkdir from "./cmd/m/CmdMkdir.vue";
import CmdMkfifo from "./cmd/m/CmdMkfifo.vue";
import CmdMkfs from "./cmd/m/CmdMkfs.vue";
import CmdMktemp from "./cmd/m/CmdMktemp.vue";
import CmdModinfo from "./cmd/m/CmdModinfo.vue";
import CmdMore from "./cmd/m/CmdMore.vue";
import CmdMount from "./cmd/m/CmdMount.vue";
import CmdModprobe from "./cmd/m/CmdModprobe.vue";
import CmdMv from "./cmd/m/CmdMv.vue";

import CmdNetstat from "./cmd/n/CmdNetstat.vue";
import CmdNano from "./cmd/n/CmdNano.vue";
import CmdNc from "./cmd/n/CmdNc.vue";
import CmdNice from "./cmd/n/CmdNice.vue";
import CmdNohup from "./cmd/n/CmdNohup.vue";
import CmdNslookup from "./cmd/n/CmdNslookup.vue";
import CmdNl from "./cmd/n/CmdNl.vue";

import CmdPing from "./cmd/p/CmdPing.vue";
import CmdPasswd from "./cmd/p/CmdPasswd.vue";
import CmdPaste from "./cmd/p/CmdPaste.vue";
import CmdParted from "./cmd/p/CmdParted.vue";
import CmdPartprobe from "./cmd/p/CmdPartprobe.vue";
import CmdPkill from "./cmd/p/CmdPkill.vue";
import CmdPoweroff from "./cmd/p/CmdPoweroff.vue";
import CmdPrintenv from "./cmd/p/CmdPrintenv.vue";
import CmdPrintf from "./cmd/p/CmdPrintf.vue";
import CmdPs from "./cmd/p/CmdPs.vue";
import CmdPstree from "./cmd/p/CmdPstree.vue";

import CmdRead from "./cmd/r/CmdRead.vue";
import CmdReadelf from "./cmd/r/CmdReadelf.vue";
import CmdReboot from "./cmd/r/CmdReboot.vue";
import CmdRenice from "./cmd/r/CmdRenice.vue";
import CmdReturn from "./cmd/r/CmdReturn.vue";
import CmdRm from "./cmd/r/CmdRm.vue";
import CmdRmdir from "./cmd/r/CmdRmdir.vue";
import CmdRsync from "./cmd/r/CmdRsync.vue";

import CmdSar from "./cmd/s/CmdSar.vue";
import CmdService from "./cmd/s/CmdService.vue";
import CmdScp from "./cmd/s/CmdScp.vue";
import CmdSeq from "./cmd/s/CmdSeq.vue";
import CmdScreen from "./cmd/s/CmdScreen.vue";
import CmdSleep from "./cmd/s/CmdSleep.vue";
import CmdSmartctl from "./cmd/s/CmdSmartctl.vue";
import CmdSort from "./cmd/s/CmdSort.vue";
import CmdSource from "./cmd/s/CmdSource.vue";
import CmdSsh from "./cmd/s/CmdSsh.vue";
import CmdSs from "./cmd/s/CmdSs.vue";
import CmdSshCopyId from "./cmd/s/CmdSshCopyId.vue";
import CmdStrings from "./cmd/s/CmdStrings.vue";
import CmdStrace from "./cmd/s/CmdStrace.vue";
import CmdSu from "./cmd/s/CmdSu.vue";
import CmdSudo from "./cmd/s/CmdSudo.vue";
import CmdSwapon from "./cmd/s/CmdSwapon.vue";
import CmdSwapoff from "./cmd/s/CmdSwapoff.vue";
import CmdSync from "./cmd/s/CmdSync.vue";
import CmdSysctl from "./cmd/s/CmdSysctl.vue";
import CmdSystemctl from "./cmd/s/CmdSystemctl.vue";
import CmdSpawn from "./cmd/s/CmdSpawn.vue";
import CmdSha256sum from "./cmd/s/CmdSha256sum.vue";

import CmdTac from "./cmd/t/CmdTac.vue";
import CmdTar from "./cmd/t/CmdTar.vue";
import CmdTcpdump from "./cmd/t/CmdTcpdump.vue";
import CmdTop from "./cmd/t/CmdTop.vue";
import CmdTail from "./cmd/t/CmdTail.vue";
import CmdTaskset from "./cmd/t/CmdTaskset.vue";
import CmdTime from "./cmd/t/CmdTime.vue";
import CmdTimedatectl from "./cmd/t/CmdTimedatectl.vue";
import CmdTimeout from "./cmd/t/CmdTimeout.vue";
import CmdTouch from "./cmd/t/CmdTouch.vue";
import CmdTput from "./cmd/t/CmdTput.vue";
import CmdTrap from "./cmd/t/CmdTrap.vue";
import CmdTree from "./cmd/t/CmdTree.vue";
import CmdTune2fs from "./cmd/t/CmdTune2fs.vue";
import CmdType from "./cmd/t/CmdType.vue";
import CmdTelinit from "./cmd/t/CmdTelinit.vue";

import CmdUdevadm from "./cmd/u/CmdUdevadm.vue";
import CmdUlimit from "./cmd/u/CmdUlimit.vue";
import CmdUmount from "./cmd/u/CmdUmount.vue";
import CmdUname from "./cmd/u/CmdUname.vue";
import CmdUniq from "./cmd/u/CmdUniq.vue";
import CmdUnix2dos from "./cmd/u/CmdUnix2dos.vue";
import CmdUnlink from "./cmd/u/CmdUnlink.vue";
import CmdUnset from "./cmd/u/CmdUnset.vue";
import CmdUnzip from "./cmd/u/CmdUnzip.vue";
import CmdUnxz from "./cmd/u/CmdUnxz.vue";
import CmdUptime from "./cmd/u/CmdUptime.vue";
import CmdUseradd from "./cmd/u/CmdUseradd.vue";
import CmdUserdel from "./cmd/u/CmdUserdel.vue";
import CmdUsermod from "./cmd/u/CmdUsermod.vue";
import CmdUsers from "./cmd/u/CmdUsers.vue";
import CmdUuidgen from "./cmd/u/CmdUuidgen.vue";

import CmdView from "./cmd/v/CmdView.vue";
import CmdVi from "./cmd/v/CmdVi.vue";
import CmdVim from "./cmd/v/CmdVim.vue";
import CmdVisudo from "./cmd/v/CmdVisudo.vue";

import CmdW from "./cmd/w/CmdW.vue";
import CmdWipefs from "./cmd/w/CmdWipefs.vue";
import CmdWait from "./cmd/w/CmdWait.vue";
import CmdWall from "./cmd/w/CmdWall.vue";
import CmdWatch from "./cmd/w/CmdWatch.vue";
import CmdWc from "./cmd/w/CmdWc.vue";
import CmdWget from "./cmd/w/CmdWget.vue";
import CmdWhereis from "./cmd/w/CmdWhereis.vue";
import CmdWho from "./cmd/w/CmdWho.vue";
import CmdWhoami from "./cmd/w/CmdWhoami.vue";
import CmdWrite from "./cmd/w/CmdWrite.vue";

import CmdYes from "./cmd/y/CmdYes.vue";

import CmdXargs from "./cmd/x/CmdXargs.vue";

import CmdZip from "./cmd/z/CmdZip.vue";
import CmdZcat from "./cmd/z/CmdZcat.vue";
import CmdZless from "./cmd/z/CmdZless.vue";
import CmdZmore from "./cmd/z/CmdZmore.vue";

export default {
  name: "BasicView",
  components: {
    DefaultBasic,

    CmdAlias,
    CmdAdduser,
    CmdAptGet,
    CmdArch,
    CmdAscii,
    CmdAwk,
    CmdAt,
    CmdCat,
    CmdCd,
    CmdChage,
    CmdChmod,
    CmdChattr,
    CmdChown,
    CmdCp,
    CmdCrontab,
    CmdCurl,

    CmdBase32,
    CmdBase64,
    CmdBasename,
    CmdBg,
    CmdBlkid,
    CmdBc,

    CmdDate,
    CmdDd,
    CmdDeclare,
    CmdDepmod,
    CmdDf,
    CmdDiff,
    CmdDig,
    CmdDir,
    CmdDisown,
    CmdDos2unix,
    CmdDmesg,
    CmdDmidecode,
    CmdDnf,
    CmdDu,

    CmdEcho,
    CmdEnv,
    CmdExit,

    CmdFdisk,
    CmdFg,
    CmdFile,
    CmdFind,
    CmdFree,

    CmdGrowpart,
    CmdGCC,
    CmdGetent,
    CmdGrep,
    CmdGroupadd,
    CmdGroupdel,
    CmdGroupmod,
    CmdGroups,
    CmdGunzip,
    CmdGzip,

    CmdHalt,
    CmdHead,
    CmdHelp,
    CmdHistory,
    CmdHostname,
    CmdHostnamectl,
    CmdHwclock,

    CmdId,
    CmdIf,
    CmdIfconfig,
    CmdInit,
    CmdIp,
    CmdIptables,

    CmdJobs,
    CmdJournalctl,

    CmdKill,
    CmdKillall,
    CmdKmod,

    CmdLast,
    CmdLsof,
    CmdLsblk,
    CmdLshw,
    CmdLosetup,
    CmdLastb,
    CmdLscpu,
    CmdLsmem,
    CmdLdd,
    CmdLess,
    CmdLet,
    CmdLn,
    CmdLocale,
    CmdLocalectl,
    CmdLogger,
    CmdLogout,
    CmdLs,
    CmdLsmod,
    CmdLsattr,
    CmdLsusb,
    CmdLspci,

    CmdMan,
    CmdMandb,
    CmdMkdir,
    CmdMkfifo,
    CmdMkfs,
    CmdMktemp,
    CmdModinfo,
    CmdMore,
    CmdMount,
    CmdModprobe,
    CmdMv,

    CmdNetstat,
    CmdNc,
    CmdNano,
    CmdNice,
    CmdNohup,
    CmdNl,
    CmdNslookup,

    CmdPing,
    CmdPasswd,
    CmdPaste,
    CmdParted,
    CmdPartprobe,
    CmdPkill,
    CmdPoweroff,
    CmdPrintenv,
    CmdPrintf,
    CmdPs,
    CmdPstree,

    CmdRead,
    CmdReadelf,
    CmdReboot,
    CmdRenice,
    CmdReturn,
    CmdRm,
    CmdRmdir,
    CmdRsync,

    CmdSar,
    CmdService,
    CmdScp,
    CmdSeq,
    CmdScreen,
    CmdSleep,
    CmdSmartctl,
    CmdSort,
    CmdSource,
    CmdSsh,
    CmdSs,
    CmdSshCopyId,
    CmdStrings,
    CmdStrace,
    CmdSu,
    CmdSudo,
    CmdSwapon,
    CmdSwapoff,
    CmdSync,
    CmdSysctl,
    CmdSystemctl,
    CmdSpawn,
    CmdSha256sum,

    CmdTac,
    CmdTar,
    CmdTcpdump,
    CmdTop,
    CmdTail,
    CmdTaskset,
    CmdTime,
    CmdTimedatectl,
    CmdTimeout,
    CmdTouch,
    CmdTput,
    CmdTrap,
    CmdTree,
    CmdTune2fs,
    CmdType,
    CmdTelinit,

    CmdUdevadm,
    CmdUlimit,
    CmdUmount,
    CmdUname,
    CmdUniq,
    CmdUnix2dos,
    CmdUnlink,
    CmdUnset,
    CmdUnxz,
    CmdUnzip,
    CmdUptime,
    CmdUseradd,
    CmdUserdel,
    CmdUsers,
    CmdUsermod,
    CmdUuidgen,

    CmdView,
    CmdVi,
    CmdVim,
    CmdVisudo,

    CmdW,
    CmdWipefs,
    CmdWait,
    CmdWall,
    CmdWatch,
    CmdWc,
    CmdWget,
    CmdWhereis,
    CmdWho,
    CmdWhoami,
    CmdWrite,

    CmdXargs,

    CmdYes,

    CmdZip,
    CmdZcat,
    CmdZless,
    CmdZmore,
    ElLink,
    Fold,
    Expand,
  },
  setup() {
    const isSidebarOpen = ref(true);
    const currentComponent = ref(DefaultBasic);
    const searchQuery = ref("");
    const searchResults = ref([]);

    const searchCommand = () => {
      if (searchQuery.value) {
        searchResults.value = buttons.filter((button) =>
          button.label.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      } else {
        searchResults.value = [];
      }
    };

    const openComponent = (component) => {
      currentComponent.value = component;
      searchQuery.value = ""; // 清空搜索框
      searchResults.value = []; // 清空搜索结果
    };

    const buttons = [
      { label: "cd - 改变工作目录", component: "CmdCd" },
      { label: "ls - 列出目录内容", component: "CmdLs" },
      { label: "cp - 复制文件或目录", component: "CmdCp" },
      { label: "mv - 移动或重命名文件", component: "CmdMv" },
      { label: "mkdir - 创建目录", component: "CmdMkdir" },
      { label: "rm - 删除文件或目录", component: "CmdRm" },
      { label: "touch - 创建空文件", component: "CmdTouch" },
      { label: "chmod - 修改文件权限", component: "CmdChmod" },
      { label: "chown - 更改文件所有者", component: "CmdChown" },
      { label: "exit - 用于退出当前的 shell", component: "CmdExit" },
      { label: "find - 查找文件和目录", component: "CmdFind" },
      { label: "man - 查看帮助手册", component: "CmdMan" },
      { label: "grep - 文本搜索工具", component: "CmdGrep" },
      { label: "cat - 查看文件内容", component: "CmdCat" },
      { label: "echo - 输出文本", component: "CmdEcho" },
      { label: "ps - 查看进程状态", component: "CmdPs" },
      { label: "top - 实时进程监控", component: "CmdTop" },
      { label: "df - 查看磁盘空间占用", component: "CmdDf" },
      { label: "du - 计算目录或文件大小", component: "CmdDu" },
      { label: "free - 查看内存使用信息", component: "CmdFree" },
      { label: "history - 显示执行命令记录", component: "CmdHistory" },
      { label: "env - 显示环境变量", component: "CmdEnv" },
      { label: "printenv - 显示环境变量", component: "CmdPrintenv" },
      { label: "alias - 创建命令别名", component: "CmdAlias" },
      { label: "unzip - 解压zip文件", component: "CmdUnzip" },
      { label: "zip - 压缩文件", component: "CmdZip" },
      { label: "zcat - 查看gzip压缩包文件内容", component: "CmdZcat" },
      { label: "zless - 分页查看压缩文件内容", component: "CmdZless" },
      { label: "zmore - 分页查看压缩文件内容", component: "CmdZmore" },
      { label: "tar - 压缩和解压缩文件", component: "CmdTar" },
      { label: "sleep - 暂停一段时间再执行", component: "CmdSleep" },
      { label: "nano - 轻量文本编辑器", component: "CmdNano" },
      { label: "vi - 文本编辑器", component: "CmdVi" },
      { label: "ping - 测试网络连通性", component: "CmdPing" },
      { label: "curl - 数据传输工具", component: "CmdCurl" },
      { label: "wget - 下载文件", component: "CmdWget" },
      { label: "ssh - 远程登录", component: "CmdSsh" },
      { label: "scp - 安全复制文件", component: "CmdScp" },
      { label: "rsync - 远程同步文件", component: "CmdRsync" },
      { label: "adduser - 添加新用户", component: "CmdAdduser" },
      { label: "userdel - 删除用户", component: "CmdUserdel" },
      { label: "usermod - 修改用户信息", component: "CmdUsermod" },
      { label: "passwd - 修改用户密码", component: "CmdPasswd" },
      { label: "crontab - 定时任务管理", component: "CmdCrontab" },
      { label: "service - 管理系统服务", component: "CmdService" },
      { label: "systemctl - 管理系统服务", component: "CmdSystemctl" },
      { label: "ln - 创建软链接和硬链接", component: "CmdLn" },
      { label: "depmod - 更新内核模块的依赖", component: "CmdDepmod" },
      { label: "journalctl - 查看系统日志", component: "CmdJournalctl" },
      { label: "netstat - 显示网络状态", component: "CmdNetstat" },
      { label: "ss - 显示套接字信息", component: "CmdSs" },
      { label: "ifconfig - 查看网络接口配置", component: "CmdIfconfig" },
      { label: "ip - 显示网络配置", component: "CmdIp" },
      { label: "iptables - 管理IPv4防火墙", component: "CmdIptables" },
      { label: "tcpdump - 网络流量捕获", component: "CmdTcpdump" },
      { label: "lsof - 列出打开的文件端口等", component: "CmdLsof" },
      { label: "fdisk - 磁盘分区工具", component: "CmdFdisk" },
      { label: "mkfs - 创建文件系统", component: "CmdMkfs" },
      { label: "mount - 挂载和显示文件系统", component: "CmdMount" },
      { label: "umount - 卸载文件系统", component: "CmdUmount" },
      { label: "blkid - 显示块设备UUID", component: "CmdBlkid" },
      { label: "lsblk - 磁盘设备及其分区信息", component: "CmdLsblk" },
      { label: "lsusb - 列出USB设备", component: "CmdLsusb" },
      { label: "lspci - 列出PCI设备", component: "CmdLspci" },
      { label: "uptime - 查看系统运行时间和负载", component: "CmdUptime" },
      { label: "hostname - 查看或设置主机名", component: "CmdHostname" },
      { label: "date - 显示或设置系统日期和时间", component: "CmdDate" },
      {
        label: "timedatectl - 管理系统时间和时区",
        component: "CmdTimedatectl",
      },
      { label: "who - 显示当前登录用户", component: "CmdWho" },
      { label: "w - 显示当前登录用户及其活动", component: "CmdW" },
      { label: "last - 显示用户的登录历史", component: "CmdLast" },
      { label: "whoami - 显示当前用户名称", component: "CmdWhoami" },
      { label: "id - 显示用户和组信息", component: "CmdId" },
      { label: "groups - 显示用户所属组", component: "CmdGroups" },
      { label: "hwclock - 管理硬件时钟", component: "CmdHwclock" },
      { label: "su - 切换用户身份", component: "CmdSu" },
      { label: "sudo - 以超级用户身份执行命令", component: "CmdSudo" },
      { label: "groupadd - 添加新组", component: "CmdGroupadd" },
      { label: "groupdel - 删除组", component: "CmdGroupdel" },
      { label: "groupmod - 修改组信息", component: "CmdGroupmod" },
      { label: "chage - 修改用户密码有效期", component: "CmdChage" },
      { label: "getent - 获取系统数据库条目", component: "CmdGetent" },
      { label: "dmesg - 显示内核环缓冲区信息", component: "CmdDmesg" },
      { label: "kill - 终止进程", component: "CmdKill" },
      { label: "killall - 终止所有匹配的进程", component: "CmdKillall" },
      { label: "nice - 设置进程优先级", component: "CmdNice" },
      { label: "renice - 更改进程优先级", component: "CmdRenice" },
      { label: "bg - 将进程放入后台执行", component: "CmdBg" },
      { label: "fg - 将后台进程移至前台", component: "CmdFg" },
      { label: "jobs - 显示后台任务", component: "CmdJobs" },
      { label: "nohup - 使进程忽略挂起信号", component: "CmdNohup" },
      { label: "strace - 跟踪系统调用", component: "CmdStrace" },
      { label: "sar - 系统活动报告", component: "CmdSar" },
      {
        label: "hostnamectl - 查看或设置主机名",
        component: "CmdHostnamectl",
      },
      { label: "lsmod - 显示已加载的内核模块", component: "CmdLsmod" },
      { label: "modinfo - 显示内核模块信息", component: "CmdModinfo" },
      { label: "modprobe - 加载或移除内核模块", component: "CmdModprobe" },
      { label: "depmod - 生成模块依赖关系", component: "CmdDepmod" },
      { label: "sysctl - 配置内核参数", component: "CmdSysctl" },
      { label: "parted - 管理磁盘分区", component: "CmdParted" },
      { label: "swapoff - 关闭交换分区", component: "CmdSwapoff" },
      { label: "swapon - 启用交换分区", component: "CmdSwapon" },
      { label: "wipefs - 擦除磁盘的文件系统签名", component: "CmdWipefs" },
      { label: "losetup - 设置和控制环回设备", component: "CmdLosetup" },
      { label: "read - 从标准输入读取数据", component: "CmdRead" },
      { label: "readelf - 显示ELF文件信息", component: "CmdReadelf" },
      {
        label: "dmidecode - 获取DMI信息",
        component: "CmdDmidecode",
      },
      { label: "lshw - 显示硬件信息", component: "CmdLshw" },
      {
        label: "smartctl - 硬盘的SMART信息",
        component: "CmdSmartctl",
      },
      { label: "lscpu - 显示CPU信息", component: "CmdLscpu" },
      { label: "lsattr - 显示文件属性", component: "CmdLsattr" },
      { label: "chattr - 改变文件属性", component: "CmdChattr" },
      {
        label: "partprobe - 重新读取分区表",
        component: "CmdPartprobe",
      },
      { label: "sync - 将数据写入磁盘", component: "CmdSync" },
      { label: "reboot - 重启系统", component: "CmdReboot" },
      { label: "halt - 停止系统", component: "CmdHalt" },
      { label: "poweroff - 关闭电源", component: "CmdPoweroff" },
      { label: "init - 初始化系统", component: "CmdInit" },
      { label: "localectl - 控制系统本地化设置", component: "CmdLocalectl" },
      { label: "yum - Red Hat的软件包管理器", component: "CmdDnf" },
      { label: "dnf - Fedora的软件包管理器", component: "CmdDnf" },
      { label: "apt-get - Debian软件包管理工具", component: "CmdAptGet" },
      { label: "telinit - 改变系统运行级别", component: "CmdTelinit" },
      { label: "at - 定时执行命令", component: "CmdAt" },
      { label: "users - 显示当前登录用户列表", component: "CmdUsers" },
      { label: "logout - 退出当前登录会话", component: "CmdLogout" },
      { label: "useradd - 添加用户", component: "CmdUseradd" },
      { label: "ldd - 显示共享库依赖关系", component: "CmdLdd" },
      { label: "readelf - 显示ELF文件信息", component: "CmdReadelf" },
      {
        label: "strings - 显示二进制文件的字符",
        component: "CmdStrings",
      },
      { label: "file - 确定文件类型", component: "CmdFile" },
    ];

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    return {
      isSidebarOpen,
      currentComponent,
      searchQuery,
      searchResults,
      searchCommand,
      openComponent,
      buttons,
      toggleSidebar,
    };
  },
};
</script>

<style scoped>
.dsp {
  display: flex;
  height: 100vh; /* 确保容器高度为视口高度 */
  background-color: #ececec;
  position: relative;
  overflow: hidden; /* 防止子元素溢出 */
}

.toggle-button {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 3px solid green;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 13px;
  opacity: 0.5;
}

.sidebar {
  background-color: #f5f5f5;
  padding: 30px 20px 20px 20px;
  border-right: 2px solid #ddd;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1);
  min-width: 250px; /* 设置固定宽度 */
  height: 100%; /* 使sidebar占满父容器的高度 */
  overflow-y: auto; /* 内容超出时出现滚动条 */
  position: relative; /* 添加相对定位，以便search-box能够相对于它定位 */
}

.search-box {
  position: absolute; /* 设置为绝对定位 */
  top: 6px; /* 顶部对齐 */
  left: 42px; /* 左侧对齐 */
  width: 120px; /* 宽度占满sidebar */
  z-index: 9; /* 确保它位于sidebar内容之上，但在toggle-button之下 */
  padding: 2px; /* 添加一些内边距 */
  box-sizing: border-box; /* 确保内边距和边框被包含在宽度内 */
}

/* 确保搜索框内的输入框和搜索结果列表样式不受影响 */
.search-box .el-input,
.search-box .search-results {
  /* background-color: #fff; */
  border-radius: 4px; /* 添加圆角 */
  padding: 0px; /* 添加内边距 */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 16px; /* 为搜索框留出空间 */
}

.sidebar ul li {
  margin-bottom: 15px;
  text-align: left;
}

.sidebar-link {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sidebar-link:hover {
  color: #007bff;
  text-decoration: underline;
}

.content {
  flex-grow: 1; /* 占据剩余的空间 */
  padding: 1.2rem;
  background-color: #ffffff;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: auto; /* 当内容超出时使用浏览器滚动条 */
  height: 100%; /* 让内容区占满父容器的高度 */
}

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.search-result-link {
  display: block;
  padding: 8px 16px;
  color: #409eff;
  text-decoration: none;
}

.search-result-link:hover {
  background-color: #f5f7fa;
}
</style>
