<template>
  <div class="top-container">
    <h2>每日一题(基础)</h2>
    <DailyOpsQuestion />
    <h2>每日一题(经典)</h2>
    <!-- <DailyOpsQuestion /> -->
  </div>
</template>

<script>
import DailyOpsQuestion from "./base/DailyOpsQuestion.vue";

export default {
  name: "OpsInterview",
  components: {
    DailyOpsQuestion,
  },
  data() {
    return {
      name: "checker",
    };
  },
  methods: {
    handleCardClick() {
      console.log(this.name);
    },
  },
};
</script>

<style scoped>
h2 {
  color: #333; /* 设置字体颜色 */
  text-align: left; /* 设置文本对齐方式 */
  background-color: #f9f9f9; /* 设置背景颜色 */
  border-radius: 5px; /* 设置边框圆角 */
  padding: 6px 4px;
  width: 80%;
  margin: 1rem auto;
}
</style>
