<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## whoami 命令详解

\`whoami\` 命令用于显示当前用户的用户名，它非常简单但在系统管理和脚本中非常实用。通过运行 \`whoami\`，你可以快速确认当前会话的用户身份，特别是在切换用户时。

### 基本语法

\`\`\`bash
whoami
\`\`\`

该命令没有复杂的选项或参数，通常只需直接运行。

### 功能简介

- 显示当前有效用户的用户名。
- 对于使用 \`su\` 或 \`sudo\` 切换用户的场景，它有助于确认切换后的身份。
- \`whoami\` 与 \`id -un\` 的功能类似，都是用于显示用户名。

### 使用示例

#### 1. 显示当前登录用户

在命令行直接输入 \`whoami\`，可以显示当前有效用户：

\`\`\`bash
whoami
\`\`\`

**示例输出：**

\`\`\`bash
sre
\`\`\`

**解读：** 这里的输出表明，当前用户为 \`sre\`，此用户的 \`uid\` 为 1005，组为 \`sre-group\`。如果当前用户通过 \`su\` 或 \`sudo\` 切换到其他用户，则输出会显示目标用户的用户名。

#### 2. 与 \`su\` 结合使用

假设用户 \`sre\` 切换到用户 \`dev01\`，执行以下操作：

\`\`\`bash
su dev01
whoami
\`\`\`

**示例输出：**

\`\`\`bash
dev01
\`\`\`

**解读：** 这表示当前会话的用户已经切换到 \`dev01\`，此时 \`whoami\` 显示 \`dev01\` 的用户名。

#### 3. 在 \`sudo\` 环境下的使用

当使用 \`sudo\` 执行命令时，\`whoami\` 也可以帮助确认当前的权限状态。例如，使用 \`sudo\` 以 \`root\` 用户执行命令：

\`\`\`bash
sudo whoami
\`\`\`

**示例输出：**

\`\`\`bash
root
\`\`\`

**解读：** 由于使用了 \`sudo\`，\`whoami\` 输出当前是以 \`root\` 用户身份执行命令。

#### 4. 使用 \`id\` 命令获取更多详细信息

如果你想查看更多与用户相关的信息，例如用户ID（\`uid\`）、组ID（\`gid\`）等，可以结合 \`id\` 命令：

\`\`\`bash
id -un
\`\`\`

**示例输出：**

\`\`\`bash
sre
\`\`\`

这与 \`whoami\` 的输出一致。

### 多用户场景

如果系统中存在多个用户（如 \`sre\`、\`dev01\`、\`app01\` 等），\`whoami\` 可以在这些用户之间切换时帮助确认当前会话的用户。以下是一个使用 \`su\` 命令切换到不同用户的示例：

\`\`\`bash
su - dev01
whoami
\`\`\`

**示例输出：**

\`\`\`bash
dev01
\`\`\`

此时 \`whoami\` 输出当前用户为 \`dev01\`。

### 不同架构下的表现

在 \`aarch64\` 和 \`x86_64/amd64\` 架构下，\`whoami\` 命令的输出没有差异，均返回当前有效用户的用户名。该命令的跨平台一致性很高，不存在架构差异问题。

### 版本差异

\`whoami\` 命令通常在各种 Unix-like 系统中提供，并且功能相对稳定。不同版本的系统间并没有显著差异。例如，在 Linux 系统和 macOS 系统上，\`whoami\` 的行为和输出是一致的。

### 安装信息

在大多数 Linux 发行版中，\`whoami\` 是默认安装的，不需要额外安装。如果系统中缺失该命令，可以通过以下方式进行安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL/CentOS**:

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

\`whoami\` 是 \`coreutils\` 包的一部分，该包在大多数发行版中默认包含。

### 使用 root 权限的情况

通常 \`whoami\` 不需要 root 权限，但在使用 \`sudo\` 时，\`whoami\` 会显示 \`root\` 用户身份，验证当前权限。以下示例展示了 \`whoami\` 在 \`sudo\` 下的行为：

\`\`\`bash
sudo whoami
\`\`\`

**示例输出：**

\`\`\`bash
root
\`\`\`

这表明命令以 \`root\` 用户身份运行。

### 总结

\`whoami\` 是一个简单而有效的命令，主要用于确认当前有效用户的身份。在多用户系统或需要频繁切换用户的场景中，它非常有用。尽管该命令的功能相对基础，但结合 \`su\` 和 \`sudo\` 命令，它能帮助用户和管理员快速确认当前的权限状态。

        `,
    };
  },
});
</script>
<style scoped></style>
