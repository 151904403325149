<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sar 命令详解

\`sar\`（System Activity Report）命令是一个强大的工具，用于收集和报告系统活动信息。它能够监控 CPU 使用率、内存使用情况、I/O 负载、网络流量等，对系统性能的分析和调优非常有帮助。

### 基本语法

\`\`\`bash
sar [选项] [interval] [count]
\`\`\`

- **选项**：指定输出内容。
- **interval**：指定收集数据的间隔时间（以秒为单位）。
- **count**：指定输出的次数。

### 常用选项

- \`-u\`：显示 CPU 使用率。
- \`-r\`：显示内存使用情况。
- \`-q\`：显示队列长度和负载平均值。
- \`-d\`：显示块设备的 I/O 活动。
- \`-n\`：显示网络相关的信息。
- \`-P ALL\`：显示所有 CPU 的使用情况。

### 示例用法

#### 示例一：查看 CPU 使用率

运行 \`sar\` 命令以查看 CPU 使用率：

\`\`\`bash
sar -u 1 5
\`\`\`

**输出：**

\`\`\`plaintext
Linux 5.4.0-42-generic (azlinux-prod-cn)  09/25/2024  _x86_64_  (4 CPU)

12:00:01 AM     CPU     %user     %nice   %system   %iowait    %steal   %idle
12:00:02 AM     all      2.50      0.00      0.50      0.00      0.00     97.00
12:00:03 AM     all      3.00      0.00      0.50      0.00      0.00     96.50
12:00:04 AM     all      2.00      0.00      1.00      0.00      0.00     97.00
12:00:05 AM     all      3.50      0.00      0.50      0.00      0.00     96.00
\`\`\`

**解读**：输出中显示了 CPU 使用情况的不同参数，包括用户空间使用率（%user）、系统空间使用率（%system）以及空闲时间（%idle）。例如，在第一秒内，CPU 的空闲时间为 97%。

#### 示例二：查看内存使用情况

使用 \`-r\` 选项查看内存的使用情况：

\`\`\`bash
sar -r 1 5
\`\`\`

**输出：**

\`\`\`plaintext
Linux 5.4.0-42-generic (azlinux-prod-cn)  09/25/2024  _x86_64_  (4 CPU)

12:00:01 AM kbmemfree kbmemused %memused
12:00:02 AM    1048576    1024000    49.50
12:00:03 AM    1048570    1024006    49.55
12:00:04 AM    1048572    1024004    49.52
12:00:05 AM    1048578    1023998    49.48
\`\`\`

**解读**：输出显示了内存使用情况，包括空闲内存（kbmemfree）和已用内存（kbmemused），以及内存使用率（%memused）。

### 重要性

\`sar\` 命令对于系统管理员和运维工程师非常重要，尤其在以下情况下：

- 监控系统性能，识别瓶颈。
- 分析资源使用情况，帮助优化系统配置。
- 生成报告，以便更好地理解系统负载情况。

### 适用的系统架构

在 aarch64 和 x86_64/amd64 架构下，\`sar\` 命令的行为没有差异，输出结果一致，因此无需列出不同架构的输出。

### 版本差异

\`sar\` 命令是 \`sysstat\` 软件包的一部分，可能在不同版本中有所不同。可以通过以下命令查看当前 \`sysstat\` 的版本：

\`\`\`bash
sar -V
\`\`\`

**输出示例：**

\`\`\`plaintext
sysstat version 11.7.3
\`\`\`

不同版本之间可能会有新功能或 bug 修复，但基本功能通常保持一致。

### 安装方法

在 Ubuntu 和 RHEL 系统中，\`sar\` 命令的安装包分别为：

- **Ubuntu**：\`sysstat\`
- **RHEL**：\`sysstat\`

#### 安装示例

在 Ubuntu 上安装：

\`\`\`bash
sudo apt install sysstat
\`\`\`

在 RHEL 上安装：

\`\`\`bash
sudo yum install sysstat
\`\`\`

### 权限要求

某些 \`sar\` 命令的功能可能需要 root 权限，特别是在监控所有用户的活动时。可以使用以下命令以 root 用户身份运行：

\`\`\`bash
sudo sar -u 1 5
\`\`\`

**输出：**

\`\`\`plaintext
Linux 5.4.0-42-generic (azlinux-prod-cn)  09/25/2024  _x86_64_  (4 CPU)

12:00:01 AM     CPU     %user     %nice   %system   %iowait    %steal   %idle
12:00:02 AM     all      3.00      0.00      0.50      0.00      0.00     96.50
\`\`\`

**解读**：如上所示，通过 root 权限执行 \`sar\` 命令，获取的 CPU 使用情况。

### 总结

\`sar\` 命令是一个强大的性能监控工具，对于系统的运行状态提供了深入的分析。合理使用该命令，能够帮助运维工程师和系统管理员及时发现和解决性能问题，提升系统的稳定性与可靠性。

        `,
    };
  },
});
</script>
<style scoped></style>
