<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## printf 命令详解

### 概述

\`printf\` 命令在 Unix 和类 Unix 系统中用于格式化输出文本。与 \`echo\` 命令不同，\`printf\` 提供了更丰富的格式化选项，允许用户精确控制输出格式。

### 基本语法

\`\`\`bash
printf FORMAT [ARGUMENTS...]
\`\`\`

- **FORMAT**: 一个字符串，包含文本和格式化指示符。
- **ARGUMENTS**: 要格式化的值，可以是多个参数。

### 常用参数和选项

#### 格式化指示符

- **\`%s\`**: 字符串
- **\`%d\`**: 十进制整数
- **\`%f\`**: 浮点数
- **\`%x\`**: 十六进制整数

#### 示例用法

###### 1. 基本字符串输出

\`\`\`bash
printf "Hello, %s!\n" "world"
\`\`\`

**输出：**

\`\`\`text
Hello, world!
\`\`\`

###### 2. 格式化整数

\`\`\`bash
printf "Number: %04d\n" 42
\`\`\`

**输出：**

\`\`\`text
Number: 0042
\`\`\`

###### 3. 格式化浮点数

\`\`\`bash
printf "Float: %.2f\n" 3.14159
\`\`\`

**输出：**

\`\`\`text
Float: 3.14
\`\`\`

###### 4. 多个参数

\`\`\`bash
printf "Name: %s, Age: %d\n" "Alice" 30
\`\`\`

**输出：**

\`\`\`text
Name: Alice, Age: 30
\`\`\`

### 注意事项

- \`printf\` 不会自动添加换行符，必须手动添加 \`\n\`。
- 格式化指示符需要与参数类型匹配，否则可能导致错误或意外的输出。

### 不同架构和版本的输出差异

\`printf\` 命令在不同架构和版本中通常表现一致。用户可以通过以下命令检查 \`printf\` 的版本：

\`\`\`bash
printf --version
\`\`\`

### 安装与配置

\`printf\` 是 GNU Core Utilities 的一部分，通常预装在大多数 Unix-like 系统中。如果没有，可以通过以下方式安装：

#### 在 Ubuntu 上安装

\`printf\` 是 \`coreutils\` 包的一部分，因此可以使用以下命令安装：

\`\`\`bash
sudo apt-get update
sudo apt-get install coreutils
\`\`\`

#### 在 RHEL/CentOS 上安装

\`\`\`bash
sudo yum install coreutils
\`\`\`

### 权限要求

\`printf\` 命令无需特权权限，可以在普通用户权限下运行。

### 总结

\`printf\` 是一个强大的工具，用于精确控制文本输出格式。与 \`echo\` 相比，它提供了更多的格式化选项，适合需要复杂输出格式的场景。
 `,
    };
  },
});
</script>
<style scoped></style>
