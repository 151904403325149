<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ssh-copy-id 命令详解

\`ssh-copy-id\` 是一个用于将本地的公钥复制到远程主机的授权密钥文件中，从而实现无密码 SSH 登录的命令。使用此命令，可以方便地设置 SSH 公钥认证，提升安全性并简化登录过程。

### 基本语法

\`\`\`bash
ssh-copy-id [选项] [用户@]主机
\`\`\`

### 主要功能

- 将 SSH 公钥添加到远程主机的 \`~/.ssh/authorized_keys\` 文件中。
- 支持自定义 SSH 公钥文件。
- 简化了手动编辑 \`authorized_keys\` 的过程。

### 常用选项

- \`-i\`：指定要复制的公钥文件，默认为 \`~/.ssh/id_rsa.pub\`。
- \`-p\`：指定 SSH 端口，默认是 22。
- \`-o\`：传递选项给 SSH 客户端。

### 示例一：将默认公钥复制到远程主机

假设你的用户名为 \`sre\`，需要将公钥复制到远程主机 \`azlinux-prod-cn\`。

\`\`\`bash
ssh-copy-id sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
/usr/bin/ssh-copy-id: INFO: Source of key(s) to be installed: "/home/sre/.ssh/id_rsa.pub"
The authenticity of host 'azlinux-prod-cn (192.168.1.1)' can't be established.
ECDSA key fingerprint is SHA256:XXXXXXXXXXXXXXXXXXXX.
Are you sure you want to continue connecting (yes/no)? yes
Number of key(s) added: 1

Now try logging into the machine, with:   "ssh 'sre@azlinux-prod-cn'"
and check to make sure that only the key(s) you wanted were added.
\`\`\`

**解读**：输出说明公钥文件的来源，并且提示连接主机的指纹，确认连接后，将公钥成功添加到远程主机的 \`authorized_keys\` 文件中。

### 示例二：使用自定义公钥文件

如果公钥文件不在默认位置，可以使用 \`-i\` 选项指定公钥文件。例如：

\`\`\`bash
ssh-copy-id -i ~/.ssh/my_custom_key.pub sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
/usr/bin/ssh-copy-id: INFO: Source of key(s) to be installed: "/home/sre/.ssh/my_custom_key.pub"
The authenticity of host 'azlinux-prod-cn (192.168.1.1)' can't be established.
ECDSA key fingerprint is SHA256:XXXXXXXXXXXXXXXXXXXX.
Are you sure you want to continue connecting (yes/no)? yes
Number of key(s) added: 1
\`\`\`

**解读**：与之前的输出相似，只是这次指定了自定义的公钥文件，其他过程保持一致。

### 示例三：使用自定义端口

如果远程主机的 SSH 服务在非标准端口上运行，可以使用 \`-p\` 选项指定端口。例如，假设 SSH 服务运行在端口 2222：

\`\`\`bash
ssh-copy-id -p 2222 sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
/usr/bin/ssh-copy-id: INFO: Source of key(s) to be installed: "/home/sre/.ssh/id_rsa.pub"
The authenticity of host 'azlinux-prod-cn (192.168.1.1)' can't be established.
ECDSA key fingerprint is SHA256:XXXXXXXXXXXXXXXXXXXX.
Are you sure you want to continue connecting (yes/no)? yes
Number of key(s) added: 1
\`\`\`

**解读**：输出与之前相似，指定了自定义的端口，成功添加公钥。

### 架构差异

\`ssh-copy-id\` 命令在 aarch64 和 x86_64/amd64 架构下的输出表现没有差异，因此无需列出不同架构的输出。

### 版本差异

不同版本的 \`OpenSSH\` 可能导致 \`ssh-copy-id\` 命令的选项或输出略有不同。一般来说，较新版本可能提供更好的错误消息和支持更多功能。建议查看手册页 (\`man ssh-copy-id\`) 获取具体版本的信息。

### 安装方法

在大多数 Linux 发行版中，\`ssh-copy-id\` 命令通常随 \`OpenSSH\` 客户端工具包一起安装。可以通过以下方式确认是否已安装：

- **Ubuntu/Debian**：

  \`\`\`bash
  dpkg -l | grep openssh-client
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  rpm -q openssh-clients
  \`\`\`

如果未安装，可以通过以下命令安装：

- **Ubuntu**：

  \`\`\`bash
  sudo apt install openssh-client
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  sudo yum install openssh-clients
  \`\`\`

### 权限

\`ssh-copy-id\` 命令通常不需要特定的权限，但在复制公钥到远程主机时，用户需要具有登录的权限。如果远程主机需要使用 root 权限，可以使用 \`sudo\` 进行操作，例如：

\`\`\`bash
sudo ssh-copy-id sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
/usr/bin/ssh-copy-id: INFO: Source of key(s) to be installed: "/home/sre/.ssh/id_rsa.pub"
The authenticity of host 'azlinux-prod-cn (192.168.1.1)' can't be established.
ECDSA key fingerprint is SHA256:XXXXXXXXXXXXXXXXXXXX.
Are you sure you want to continue connecting (yes/no)? yes
Number of key(s) added: 1
\`\`\`

**解读**：在此示例中，使用 \`sudo\` 提升了权限，确保能够将公钥成功添加到远程主机。

### 总结

\`ssh-copy-id\` 命令是一个非常方便的工具，用于将 SSH 公钥复制到远程主机，简化无密码 SSH 登录的设置过程。通过本文的详细介绍，用户可以掌握如何使用 \`ssh-copy-id\` 命令来实现安全的远程访问。

        `,
    };
  },
});
</script>
<style scoped></style>
