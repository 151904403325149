<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## history 命令详解

### 概述

\`history\` 是一个 Bash 内置命令，用于显示和管理用户的命令历史记录。它记录用户在当前 shell 会话中的所有命令，并允许用户查看、执行和删除这些命令。\`history\` 命令对调试脚本和回顾之前的操作特别有用。

### 使用语法

\`\`\`bash
history [选项] [N]
\`\`\`

- \`选项\`：用于修改 \`history\` 命令的行为。
- \`N\`：显示最近 N 条命令记录。如果省略，将显示全部命令记录。

### 重要参数

- \`-c\`：清除命令历史记录。
- \`-d OFFSET\`：删除历史记录中指定偏移量（OFFSET）的条目。
- \`-a\`：将当前会话中的命令记录追加到历史文件中。
- \`-r\`：从历史文件中读取命令并追加到当前历史记录中。
- \`-w\`：将当前的历史记录写入历史文件。
- \`-n\`：从历史文件中读取新的命令记录而不写入。

### \`HISTTIMEFORMAT\` 使用

\`HISTTIMEFORMAT\` 环境变量用于设置 \`history\` 命令显示时间戳的格式。通过设置 \`HISTTIMEFORMAT\`，用户可以将时间戳添加到历史记录中，以便跟踪命令的执行时间。

#### 设置时间格式

\`\`\`bash
export HISTTIMEFORMAT="%F %T "
\`\`\`

- \`%F\`：日期（yyyy-mm-dd 格式）
- \`%T\`：时间（hh:mm:ss 格式）

### 基本用法

#### 例1：显示全部命令历史记录

\`\`\`bash
history
\`\`\`

**输出解释**:
该命令显示当前用户在 shell 中执行的所有命令记录。

\`\`\`bash
  1  ls
  2  cd /var/log
  3  cat syslog
  4  history
\`\`\`

**解读**:
输出显示了用户在 shell 会话中输入的所有命令及其对应的历史编号。每条记录包括一个唯一的编号和执行的命令。

#### 例2：显示最近 5 条命令记录

\`\`\`bash
history 5
\`\`\`

**输出解释**:
该命令显示最近 5 条命令记录。

\`\`\`bash
  1  tail -f /var/log/syslog
  2  ps aux
  3  history 5
  4  cd ~
  5  ls
\`\`\`

**解读**:
输出显示了最近 5 条命令记录及其编号。适用于快速查看最近执行的命令。

#### 例3：删除指定编号的历史记录

\`\`\`bash
history -d 2
\`\`\`

**输出解释**:
该命令删除历史记录中编号为 2 的条目。

\`\`\`bash
  1  ls
  2  cat syslog
  3  history
\`\`\`

**解读**:
删除指定编号的记录后，历史记录中的该条命令被移除。删除后的命令列表中不再包含被删除的命令。

### \`HISTTIMEFORMAT\` 相关示例

#### 例4：显示命令历史记录及其时间戳

首先设置 \`HISTTIMEFORMAT\`：

\`\`\`bash
export HISTTIMEFORMAT="%F %T "
\`\`\`

然后显示历史记录：

\`\`\`bash
history
\`\`\`

**输出解释**:
设置 \`HISTTIMEFORMAT\` 后，历史记录将显示时间戳。

\`\`\`bash
  1  2024-09-09 12:00:01 ls
  2  2024-09-09 12:05:23 cd /var/log
  3  2024-09-09 12:10:15 cat syslog
  4  2024-09-09 12:15:30 history
\`\`\`

**解读**:
每条历史记录前面显示了时间戳，帮助用户跟踪命令的执行时间。

#### 例5：清除所有历史记录

\`\`\`bash
history -c
\`\`\`

**输出解释**:
该命令清除当前 shell 会话中的所有命令历史记录。

\`\`\`bash
# 没有输出
\`\`\`

**解读**:
\`-c\` 选项清除所有记录，执行后不会有输出，历史记录将被重置。

#### 例6：将当前历史记录写入历史文件

\`\`\`bash
history -w
\`\`\`

**输出解释**:
该命令将当前 shell 会话的命令记录写入历史文件（通常是 \`~/.bash_history\`）。

\`\`\`bash
# 没有输出
\`\`\`

**解读**:
\`-w\` 选项将当前的历史记录保存到历史文件中，执行后没有直接输出，但历史文件将更新。

### 不同架构下的表现差异

\`history\` 命令在不同架构（如 \`aarch64\` 和 \`x86_64/amd64\`）下表现一致。由于 \`history\` 是 Bash 的内置命令，其行为在所有平台上都是相同的，不会因系统架构而变化。

### 不同版本的差异

在不同版本的 Bash 中，\`history\` 命令的基本功能保持一致，但一些高级选项或行为可能会有所不同。例如，旧版本的 Bash 可能不支持 \`-n\` 选项。可以通过 \`bash --version\` 命令查看当前 Bash 版本，并参考版本发布说明来了解具体差异。

### 安全性与替代命令

\`history\` 命令本身是安全的，因为它只是管理和显示历史记录。如果需要处理更复杂的历史记录管理任务，可以考虑使用 \`awk\`, \`grep\`, \`sed\` 等命令来处理历史文件，例如：

\`\`\`bash
grep "ls" ~/.bash_history
\`\`\`

        `,
    };
  },
});
</script>
<style scoped></style>
