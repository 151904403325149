<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## awk 命令详解

\`awk\` 是一个强大的文本处理工具，用于在文本文件中执行模式匹配和报告。它广泛用于数据提取、格式化以及报告生成。

### 基本语法

\`\`\`bash
awk 'pattern { action }' file
\`\`\`

- \`pattern\`：匹配模式，用于选择需要处理的文本行。
- \`action\`：对匹配的行执行的操作。
- \`file\`：要处理的文件。如果省略，则从标准输入读取。

### 常用参数和选项

- \`-F FIELD_SEPARATOR\`：指定字段分隔符。默认是空格或制表符。
- \`-v VAR=VALUE\`：定义变量并赋值。

### 示例用法

#### 1. 基本用法

提取文件中的第二列：

\`\`\`bash
awk '{print $2}' filename
\`\`\`

**输出示例**：

假设文件内容如下：

\`\`\`
name age city
Alice 30 NewYork
Bob 25 Boston
\`\`\`

执行上述命令将得到：

\`\`\`
age
30
25
\`\`\`

**解读**：\`awk\` 默认以空格分隔字段，\`$2\` 表示第二列的内容。

#### 2. 使用自定义字段分隔符

假设文件使用逗号分隔：

\`\`\`bash
awk -F, '{print $1}' filename
\`\`\`

**输出示例**：

如果文件内容为：

\`\`\`
name,age,city
Alice,30,NewYork
Bob,25,Boston
\`\`\`

执行上述命令将得到：

\`\`\`
name
Alice
Bob
\`\`\`

**解读**：\`-F,\` 指定逗号作为字段分隔符。

#### 3. 条件匹配

打印年龄大于 25 的记录：

\`\`\`bash
awk '$2 > 25 {print $0}' filename
\`\`\`

**输出示例**：

如果文件内容为：

\`\`\`
name age city
Alice 30 NewYork
Bob 25 Boston
\`\`\`

执行上述命令将得到：

\`\`\`
Alice 30 NewYork
\`\`\`

**解读**：\`$2 > 25\` 是条件，\`$0\` 表示整行内容。

#### 4. 使用变量

计算文件中的总记录数：

\`\`\`bash
awk -v total=0 '{total++} END {print total}' filename
\`\`\`

**输出示例**：

假设文件有 3 行数据（包括标题行）：

\`\`\`
name age city
Alice 30 NewYork
Bob 25 Boston
\`\`\`

执行上述命令将得到：

\`\`\`
3
\`\`\`

**解读**：\`-v total=0\` 定义变量 \`total\`，\`{total++}\` 增加计数，\`END {print total}\` 在文件处理完后打印总数。

### 不同架构下的输出

\`awk\` 命令在不同架构下的输出通常没有差异，因为 \`awk\` 是标准的文本处理工具。

### 版本差异

不同版本的 \`awk\`（如 \`gawk\`）可能会有一些扩展功能或差异，但基本功能和用法保持一致。

### 替代命令

\`awk\` 是一个经典的工具，通常没有直接的替代命令，但在某些情况下，可以使用 \`sed\` 或 \`cut\` 进行简单的文本处理。

### 安装包

\`awk\` 通常包含在基本的 Linux 发行版中，无需额外安装。如果需要安装 \`gawk\`，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install gawk
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install gawk
  \`\`\`

### 使用权限

\`awk\` 本身不需要 root 权限，但处理某些系统文件或特定目录下的文件时可能需要适当的权限。

### 总结

\`awk\` 是一个强大的文本处理工具，可以高效地处理和格式化文本数据。掌握 \`awk\` 的基本用法和一些常用选项，可以大大提高文本处理的效率。

        `,
    };
  },
});
</script>
<style scoped></style>
