<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dmesg 命令详解

\`dmesg\` 是一个用于查看内核消息缓冲区内容的命令。它主要用于诊断和调试系统的启动过程、驱动程序的加载情况以及硬件问题。\`dmesg\` 输出的消息来自内核，通常包括系统启动时的硬件检测信息、驱动程序加载情况以及其他内核生成的消息。

### 基本语法

\`dmesg\` 命令的基本语法如下：

\`\`\`bash
dmesg [选项]
\`\`\`

### 常用参数

#### 1. \`-C\` 参数

- \`-C\`：清空内核缓冲区中的消息。

\`\`\`bash
$ sudo dmesg -C
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令清空了内核消息缓冲区中的内容。通常需要 \`sudo\` 权限执行此操作。

#### 2. \`-c\` 参数

- \`-c\`：清空内核缓冲区中的消息并显示当前的消息。

\`\`\`bash
$ sudo dmesg -c
\`\`\`

**输出：**

\`\`\`
[    0.000000] Booting Linux on physical CPU 0x0
[    0.000000] Linux version 5.15.0-46-generic ...
...
\`\`\`

**解读：**
此命令显示当前的内核消息，并在显示后清空内核消息缓冲区。适用于需要检查并清空消息缓冲区的情况。

#### 3. \`-T\` 参数

- \`-T\`：将内核消息的时间戳转换为人类可读的格式。

\`\`\`bash
$ dmesg -T
\`\`\`

**输出：**

\`\`\`
[Sat Sep  7 14:53:12 2024] Booting Linux on physical CPU 0x0
[Sat Sep  7 14:53:12 2024] Linux version 5.15.0-46-generic ...
...
\`\`\`

**解读：**
此命令将内核消息中的时间戳转换为可读的日期和时间格式，有助于理解消息的产生时间。

#### 4. \`-l\` 参数

- \`-l\`：指定显示的日志级别。日志级别包括 \`emerg\`, \`alert\`, \`crit\`, \`err\`, \`warning\`, \`notice\`, \`info\`, \`debug\` 等。

\`\`\`bash
$ dmesg -l err
\`\`\`

**输出：**

\`\`\`
[    0.000000] Error in system initialization
...
\`\`\`

**解读：**
此命令仅显示日志级别为错误的内核消息。可以根据需求筛选不同级别的日志信息。

#### 5. \`-n\` 参数

- \`-n\`：设置内核消息的最大日志级别。指定的级别将显示及以下级别的消息。

\`\`\`bash
$ dmesg -n info
\`\`\`

**输出：**

\`\`\`
[    0.000000] Booting Linux on physical CPU 0x0
...
\`\`\`

**解读：**
此命令设置内核日志级别为 \`info\`，显示所有 \`info\` 及其更高级别的消息。

### 实际示例

#### 查看内核启动信息

\`\`\`bash
$ dmesg | less
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将内核消息通过 \`less\` 分页显示，方便查看详细的启动信息和硬件检测情况。

#### 查找特定关键字

\`\`\`bash
$ dmesg | grep "error"
\`\`\`

**输出：**

\`\`\`
[    0.000000] Error: Unable to initialize device
...
\`\`\`

**解读：**
此命令筛选出包含 \`error\` 关键字的内核消息，用于查找和诊断特定的错误信息。

#### 显示最新的内核消息

\`\`\`bash
$ dmesg | tail -n 20
\`\`\`

**输出：**

\`\`\`
[    0.000000] Initializing USB devices
[    0.000000] USB device initialization complete
...
\`\`\`

**解读：**
此命令显示内核缓冲区中最新的 20 条消息，有助于快速查看最近的事件或问题。

### 总结

\`dmesg\` 命令是用于查看和调试内核消息的重要工具。通过使用不同的参数，您可以清空缓冲区、格式化时间戳、筛选日志级别等。这对于系统管理员和开发人员在处理硬件问题、调试内核驱动程序以及查看系统启动过程中的详细信息非常有帮助。掌握 \`dmesg\` 的使用，可以提高您对系统状态的监控和诊断能力。

        `,
    };
  },
});
</script>
<style scoped></style>
