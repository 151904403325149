<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## passwd 命令详解

\`passwd\` 命令是用来修改用户密码的工具，它允许用户更改自己的密码或管理员更改其他用户的密码。这个命令也可以用来设置密码过期策略。

### 命令格式

\`\`\`bash
passwd [选项] [用户名]
\`\`\`

### 主要参数

- \`[用户名]\`：指定要修改密码的用户。如果省略用户名，\`passwd\` 命令将修改当前用户的密码。
- \`-l\`：锁定指定用户的账户，防止该用户登录。
- \`-u\`：解锁指定用户的账户。
- \`-d\`：删除指定用户的密码，使用户可以以空密码登录。
- \`-e\`：强制用户在下次登录时更改密码。
- \`-i <days>\`：设置密码过期前的警告天数。

### 使用示例

1. **修改当前用户的密码**

   \`\`\`bash
   passwd
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Changing password for sre.
   Current password:
   New password:
   Retype new password:
   passwd: all authentication tokens updated successfully.
   \`\`\`

   **解读**：
   - 提示用户输入当前密码、新密码以及确认新密码。
   - 如果密码更改成功，会显示 \`passwd: all authentication tokens updated successfully.\`。

2. **修改指定用户的密码（需要root权限）**

   \`\`\`bash
   sudo passwd dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Changing password for dev01.
   New password:
   Retype new password:
   passwd: all authentication tokens updated successfully.
   \`\`\`

   **解读**：
   - 需要输入新密码和确认新密码。

3. **锁定用户账户**

   \`\`\`bash
   sudo passwd -l dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Locking the password for user dev01.
   \`\`\`

   **解读**：
   - 锁定账户后，该用户将无法登录系统。

4. **解锁用户账户**

   \`\`\`bash
   sudo passwd -u dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Unlocking the password for user dev01.
   \`\`\`

   **解读**：
   - 解锁账户后，该用户可以重新登录系统。

5. **删除用户密码（使用户可以以空密码登录）**

   \`\`\`bash
   sudo passwd -d dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Removing password for user dev01.
   \`\`\`

   **解读**：
   - 删除用户密码后，用户可以通过空密码登录，但这样可能会降低安全性。

6. **强制用户在下次登录时更改密码**

   \`\`\`bash
   sudo passwd -e dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Password expiry information changed.
   \`\`\`

   **解读**：
   - 强制用户在下次登录时更改密码。

7. **设置密码过期警告天数**

   \`\`\`bash
   sudo passwd -i 7 dev01
   \`\`\`

   **输出示例**：

   \`\`\`bash
   Setting password expiration warning for user dev01.
   \`\`\`

   **解读**：
   - 设置密码过期前7天开始警告用户。

### 不同架构下的输出

\`passwd\` 命令的输出在不同架构下通常是一致的，不会因为架构不同而有所变化。因此，本节通常不需要特殊处理。

### 版本差异

\`passwd\` 命令在不同版本中，其主要功能和用法保持一致，但可能会有些微的变化或新增选项。可以使用 \`passwd --version\` 查看当前版本及其支持的功能。

### 安全性及替代命令

\`passwd\` 命令是系统中常用的工具，没有弃用或不安全的替代命令。为了增强安全性，可以定期更改密码，并且设置密码策略来加强密码复杂性。

### 安装

\`passwd\` 命令通常包含在基本的Linux系统安装中。如果需要重新安装，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install passwd
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install passwd
  \`\`\`

### 需要root权限的选项

某些操作（如修改其他用户的密码、锁定或解锁账户）需要root权限。例如：

\`\`\`bash
sudo passwd -l dev01
\`\`\`

**示例输出**：

\`\`\`bash
Locking the password for user dev01.
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以执行需要更高权限的操作。

        `,
    };
  },
});
</script>
<style scoped></style>
