<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## crontab 命令详解

\`crontab\` 命令是Linux系统中用于管理定时任务的工具，通过它可以设置定期运行的命令或脚本。\`crontab\` 结合cron守护进程，实现了自动化任务的调度，是系统管理员和开发人员常用的工具之一。

### 基本语法

\`crontab\` 命令的基本语法如下：

\`\`\`bash
crontab [选项]
\`\`\`

常见的选项包括：
- \`-e\`：编辑当前用户的crontab文件。
- \`-l\`：显示当前用户的crontab文件内容。
- \`-r\`：删除当前用户的crontab文件。
- \`-u\`：指定用户，用于查看或编辑其他用户的crontab文件。

### 编辑定时任务

通过 \`crontab -e\` 命令可以编辑当前用户的定时任务列表。这会打开一个文本编辑器，用户可以在其中添加、修改或删除任务。

\`\`\`bash
$ crontab -e
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令打开当前用户的crontab文件，用户可以在文件中添加任务。每行定义一个任务，格式如下：

\`\`\`bash
* * * * * /path/to/command
\`\`\`

每个 \`*\` 对应一分钟、小时、日、月、星期几，命令后跟要执行的指令或脚本路径。例如，每天凌晨2点执行备份脚本：

\`\`\`bash
0 2 * * * /home/sre/backup.sh
\`\`\`

**解读：**
该任务会在每天的凌晨2点运行 \`backup.sh\` 脚本。

### 查看当前定时任务

通过 \`crontab -l\` 命令可以查看当前用户的所有定时任务。

\`\`\`bash
$ crontab -l
\`\`\`

**输出：**

\`\`\`
0 2 * * * /home/sre/backup.sh
\`\`\`

**解读：**
该输出显示了当前用户设置的所有crontab任务，每个任务一行。

### 删除定时任务

可以使用 \`crontab -r\` 删除当前用户的所有定时任务。

\`\`\`bash
$ crontab -r
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令会删除当前用户的所有crontab任务列表，执行后将无法恢复。因此，建议在执行此命令前备份任务列表。

### 使用指定用户的crontab

系统管理员可以使用 \`-u\` 选项来查看或编辑其他用户的crontab。例如，查看用户 \`user1\` 的crontab任务：

\`\`\`bash
$ sudo crontab -u user1 -l
\`\`\`

**输出：**

\`\`\`
0 3 * * * /home/user1/cleanup.sh
\`\`\`

**解读：**
该命令显示了用户 \`user1\` 的所有定时任务。

### 示例：每周一凌晨4点清理临时文件

通过如下crontab配置，可以设置每周一凌晨4点清理临时文件：

\`\`\`bash
0 4 * * 1 /usr/bin/find /tmp -type f -delete
\`\`\`

**解读：**
此任务每周一凌晨4点执行，查找并删除 \`/tmp\` 目录下的所有文件。

### crontab格式详解

\`crontab\` 文件的格式由五个时间字段和一个命令字段组成，具体如下：

- \`分钟\`：0-59
- \`小时\`：0-23
- \`日\`：1-31
- \`月\`：1-12
- \`星期\`：0-7（0和7都是星期天）

例如，每天晚上11:30运行备份命令的设置为：

\`\`\`bash
30 23 * * * /home/sre/backup.sh
\`\`\`

**解读：**
这将使 \`backup.sh\` 每天晚上11:30自动执行。

### 查看cron服务状态

可以通过 \`systemctl\` 命令检查cron服务是否正在运行：

\`\`\`bash
$ systemctl status cron
\`\`\`

**输出：**

\`\`\`
● cron.service - Regular background program processing daemon
   Loaded: loaded (/lib/systemd/system/cron.service; enabled; vendor preset: enabled)
   Active: active (running)
\`\`\`

**解读：**
此输出显示了cron服务正在运行。如果服务未运行，定时任务将不会被执行。

### 总结

\`crontab\` 是一个强大且灵活的工具，可以帮助系统管理员和用户在指定的时间自动执行任务。通过掌握\`crontab\`的用法，可以极大地提高工作效率和自动化程度。无论是定期备份、清理日志，还是执行其他系统维护任务，\`crontab\` 都能为你提供一个可靠的解决方案。

        `,
    };
  },
});
</script>
<style scoped></style>
