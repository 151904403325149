<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dir 命令详解

\`dir\` 是一个用于列出目录内容的命令。它是 Unix 和类 Unix 系统中常用的命令之一。\`dir\` 命令的功能与 \`ls\` 命令类似，但它具有一些特定的选项和行为，主要用于列出目录中的文件和子目录。

### 基本用法

\`\`\`bash
dir [options] [directory]
\`\`\`

- \`[options]\`：指定命令选项。
- \`[directory]\`：要列出内容的目录。如果省略，将列出当前目录的内容。

**示例**

\`\`\`bash
$ dir
\`\`\`

**示例输出**

\`\`\`bash
Desktop  Documents  Downloads  Music  Pictures  Videos
\`\`\`

**输出解读**:

- \`dir\` 列出了当前目录中的所有文件和子目录。

### 常用选项

#### 1. \`-l\`（长格式）

以长格式显示文件和目录，包括权限、所有者、大小和修改时间等详细信息。

\`\`\`bash
$ dir -l
\`\`\`

**示例输出**

\`\`\`bash
drwxr-xr-x  2 sre sre-group 4096 Sep 10 10:00 Desktop
drwxr-xr-x  3 sre sre-group 4096 Sep  9 15:00 Documents
drwxr-xr-x  5 sre sre-group 4096 Sep  8 12:00 Downloads
\`\`\`

**输出解读**:

- 列出了文件和目录的详细信息。
- 包含文件权限、所有者、组、大小和最后修改时间。

#### 2. \`-a\`（包括隐藏文件）

列出所有文件，包括以点（.）开头的隐藏文件。

\`\`\`bash
$ dir -a
\`\`\`

**示例输出**

\`\`\`bash
.  ..  .bashrc  .profile  Desktop  Documents  Downloads
\`\`\`

**输出解读**:

- 包括了隐藏文件（例如 \`.bashrc\` 和 \`.profile\`）以及普通文件和目录。

#### 3. \`-h\`（人类可读格式）

以人类可读的格式显示文件大小（例如 KB、MB）。

\`\`\`bash
$ dir -lh
\`\`\`

**示例输出**

\`\`\`bash
drwxr-xr-x  2 sre sre-group 4.0K Sep 10 10:00 Desktop
drwxr-xr-x  3 sre sre-group 4.0K Sep  9 15:00 Documents
drwxr-xr-x  5 sre sre-group 4.0K Sep  8 12:00 Downloads
\`\`\`

**输出解读**:

- 文件大小以易读的格式显示，如 \`4.0K\`。

#### 4. \`-R\`（递归）

递归地列出所有子目录的内容。

\`\`\`bash
$ dir -R
\`\`\`

**示例输出**

\`\`\`bash
Desktop:
file1.txt  file2.txt

Documents:
doc1.pdf  doc2.pdf

Downloads:
fileA.zip  fileB.zip
\`\`\`

**输出解读**:

- 列出了所有子目录中的文件和目录。

### 内置命令与外部命令

\`dir\` 命令是 Unix 和类 Unix 系统中的外部命令，通常在核心工具包中提供（如 GNU coreutils）。它不是 Bash 内置命令，但在一些系统上，\`dir\` 可能是 \`ls\` 命令的别名。

### 不同架构下的输出

\`dir\` 命令的输出在不同架构下是一致的，因为它依赖于软件工具的实现，而不是硬件架构。

### 版本差异

\`dir\` 命令的输出在不同版本的工具中可能有所不同，但大多数版本提供的选项和功能是一致的。最新版本的 \`dir\` 可能会添加新功能或改进现有功能。

### 替代命令

- \`ls\`：功能类似于 \`dir\`，用于列出目录内容，并且提供了更多的选项和灵活性。
- \`find\`：用于递归地查找文件和目录，比 \`dir -R\` 更加全面和灵活。

### 安装

\`dir\` 命令是 GNU coreutils 包的一部分。要安装 \`dir\`，需要安装 coreutils 包：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 需要 root 权限的选项

\`dir\` 命令本身不需要 root 权限。普通用户可以执行所有常规操作，但在查看或修改其他用户的文件时，可能需要更高的权限。

**示例**

\`\`\`bash
sudo dir /var/log
\`\`\`

**示例输出**

\`\`\`bash
$ sudo dir /var/log
auth.log  syslog  dmesg
\`\`\`

**输出解读**:

- 使用 \`sudo\` 查看系统日志目录中的内容，需要 root 权限。

### 总结

\`dir\` 命令是一个简单但强大的工具，用于列出目录中的文件和子目录。通过使用 \`dir\` 的各种选项，用户可以获得详细的文件信息、隐藏文件、以及递归的目录内容。虽然 \`ls\` 命令通常更为常用，但 \`dir\` 仍然是一个值得掌握的有用工具。

        `,
    };
  },
});
</script>
<style scoped></style>
