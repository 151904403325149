<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ping 命令详解

\`ping\` 命令是用于测试计算机网络中另一台主机是否可达，并测量数据包往返时间的工具。它通过向目标主机发送ICMP（Internet Control Message Protocol）回显请求报文，并等待响应，以检查网络连接的连通性。

### 命令格式

\`\`\`bash
ping [选项] [主机名/IP地址]
\`\`\`

### 主要参数

- \`-c <count>\`: 发送指定数量的请求报文后停止。例如，\`ping -c 4 example.com\` 只发送4个请求。
- \`-i <interval>\`: 设置发送数据包的时间间隔（秒）。默认每1秒发送一个数据包。
- \`-s <size>\`: 设置发送数据包的大小（字节）。默认数据包大小为56字节（不包括ICMP头部）。
- \`-t <ttl>\`: 设置数据包的生存时间（TTL）。TTL的初始值通常为64或128。
- \`-W <timeout>\`: 设置等待响应的超时时间（秒）。如果超时，ping将停止等待响应。

### 使用示例

1. **基本用法**

   \`\`\`bash
   ping example.com
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PING example.com (93.184.216.34) 56(84) bytes of data.
   64 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=56 time=12.3 ms
   64 bytes from example.com (93.184.216.34): icmp_seq=2 ttl=56 time=11.8 ms
   \`\`\`

   **解读**：
   - \`icmp_seq=1\` 和 \`icmp_seq=2\` 表示数据包的序列号。
   - \`ttl=56\` 是数据包的生存时间。
   - \`time=12.3 ms\` 是数据包往返的时间。

2. **限制请求数量**

   \`\`\`bash
   ping -c 4 example.com
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PING example.com (93.184.216.34) 56(84) bytes of data.
   64 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=56 time=12.3 ms
   64 bytes from example.com (93.184.216.34): icmp_seq=2 ttl=56 time=11.8 ms
   64 bytes from example.com (93.184.216.34): icmp_seq=3 ttl=56 time=12.2 ms
   64 bytes from example.com (93.184.216.34): icmp_seq=4 ttl=56 time=12.0 ms

   --- example.com ping statistics ---
   4 packets transmitted, 4 received, 0% packet loss, time 3000ms
   rtt min/avg/max/mdev = 11.800/12.080/12.300/0.220 ms
   \`\`\`

   **解读**：
   - 发送了4个数据包，全部接收到回复。
   - \`0% packet loss\` 表示没有丢包。
   - \`rtt min/avg/max/mdev\` 显示最小、平均、最大和平均偏差的响应时间。

3. **设置数据包大小**

   \`\`\`bash
   ping -s 100 example.com
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PING example.com (93.184.216.34) 100(128) bytes of data.
   108 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=56 time=12.4 ms
   \`\`\`

   **解读**：
   - \`100\` 字节的数据包大小，加上ICMP头部，总共108字节。

4. **设置时间间隔**

   \`\`\`bash
   ping -i 2 example.com
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PING example.com (93.184.216.34) 56(84) bytes of data.
   64 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=56 time=12.3 ms
   64 bytes from example.com (93.184.216.34): icmp_seq=2 ttl=56 time=12.1 ms
   \`\`\`

   **解读**：
   - 数据包每2秒发送一次。

5. **设置TTL值**

   \`\`\`bash
   ping -t 50 example.com
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PING example.com (93.184.216.34) 56(84) bytes of data.
   64 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=50 time=12.3 ms
   \`\`\`

   **解读**：
   - 设置了TTL值为50。

### 不同架构下的输出

在不同架构（如aarch64和x86_64/amd64）下，\`ping\` 命令的输出通常是一致的，主要差异可能体现在某些特定选项的支持上，但基本输出格式相同。因此，本节通常不需要特殊处理。

### 版本差异

\`ping\` 命令的基本用法在不同版本中大致相同，但新版本可能会引入新的选项或改变某些默认行为。用户可以使用 \`ping --version\` 查看当前版本及其支持的功能。

### 安全性及替代命令

\`ping\` 命令是一种常见且安全的网络诊断工具，没有弃用或不安全的替代命令。如果需要更高级的网络测试工具，可以考虑使用 \`traceroute\` 或 \`mtr\`。

### 安装

在大多数Linux发行版中，\`ping\` 命令默认包含在系统中。如果需要重新安装，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install iputils-ping
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install iputils
  \`\`\`

### 需要root权限的选项

\`ping\` 命令的某些选项（如设置TTL）可能需要root权限。以下是一个示例：

\`\`\`bash
sudo ping -t 10 example.com
\`\`\`

**示例输出**：

\`\`\`bash
PING example.com (93.184.216.34) 56(84) bytes of data.
64 bytes from example.com (93.184.216.34): icmp_seq=1 ttl=10 time=12.4 ms
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以设置TTL为10。

        `,
    };
  },
});
</script>
<style scoped></style>
