<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## kmod 命令详解

\`kmod\` 是 Linux 系统中用于管理内核模块的工具。内核模块是扩展 Linux 内核功能的动态加载组件，例如驱动程序、文件系统等。通过 \`kmod\`，系统管理员可以动态地加载、卸载、查询内核模块，而无需重新编译或重启内核。

\`kmod\` 是一个外部命令，通常由 \`kmod\` 包提供，因此在使用之前需要确保系统已安装该工具。

### 1. 基本语法

\`\`\`bash
kmod [选项] <子命令> [模块名]
\`\`\`

- \`<子命令>\`：指定的操作，例如 \`load\`（加载模块）、\`unload\`（卸载模块）等。
- \`[模块名]\`：模块的名称。

### 2. 常用选项和子命令

- \`-V\`：显示 \`kmod\` 版本信息。
- \`list\`：列出已加载的内核模块。
- \`load\`：加载指定的内核模块。
- \`unload\`：卸载指定的内核模块。
- \`--help\`：显示帮助信息。

### 3. 常用命令示例

#### 3.1 列出已加载的内核模块

\`\`\`bash
$ kmod list
\`\`\`

**示例输出**：

\`\`\`
Module                  Size  Used by
nvidia_drm             45056  2
nvidia_modeset       1110016  1 nvidia_drm
nvidia              20021248  10 nvidia_modeset
\`\`\`

**解释**：
- 使用 \`kmod list\` 列出所有当前加载的内核模块，包括模块名称、大小以及使用该模块的进程数。

#### 3.2 加载内核模块

\`\`\`bash
$ sudo kmod load vfat
\`\`\`

**输出解释**：
- \`vfat\` 是一个文件系统模块，通过 \`kmod load\` 命令可以动态加载此模块。

- 【无输出，命令已成功执行】

此命令没有输出时，表示模块成功加载。

#### 3.3 卸载内核模块

\`\`\`bash
$ sudo kmod unload vfat
\`\`\`

**输出解释**：
- \`kmod unload\` 用于卸载已加载的模块。在上例中，\`vfat\` 模块被卸载。

- 【无输出，命令已成功执行】

#### 3.4 查看 \`kmod\` 版本

\`\`\`bash
$ kmod -V
kmod version 27
\`\`\`

**解释**：
- 通过 \`-V\` 参数可以查看当前系统上安装的 \`kmod\` 版本。

#### 3.5 通过模块名称卸载模块

\`\`\`bash
$ sudo kmod unload <module_name>
\`\`\`

**解释**：
- \`<module_name>\` 需要替换为你想要卸载的模块名称。该命令没有输出时，表示卸载成功。

### 4. 输出结果解读

#### 4.1 模块加载成功

\`\`\`bash
$ sudo kmod load vfat
【无输出，命令已成功执行】
\`\`\`

- 模块加载成功时通常没有输出，这意味着模块已成功加载。

#### 4.2 模块卸载成功

\`\`\`bash
$ sudo kmod unload vfat
【无输出，命令已成功执行】
\`\`\`

- 成功卸载模块时没有输出，表示模块已成功从内核中卸载。

### 5. 不同架构下的输出差异

在 \`x86_64\` 和 \`aarch64\` 架构下，\`kmod\` 命令的行为和输出保持一致，因此无需单独列出架构差异。

### 6. 版本差异

\`kmod\` 命令的不同版本在功能上保持一致，但某些旧版本可能不支持特定的内核模块管理功能。建议使用较新的版本以确保完整的功能。

### 7. 安全性与替代

\`kmod\` 是管理内核模块的核心工具，没有被淘汰或替代。旧工具如 \`modprobe\` 也可以用于加载和卸载模块，但在大多数现代 Linux 系统上，\`kmod\` 是推荐的替代品。

### 8. 安装信息

\`kmod\` 通常是 Linux 系统的默认组件。如果需要安装或升级 \`kmod\`，可以通过以下命令安装：

- **Ubuntu**：使用 \`apt install kmod\` 安装。
- **RHEL/CentOS**：使用 \`yum install kmod\` 安装。

### 9. 用到 root 权限的场景

由于内核模块的加载和卸载操作影响系统内核的行为，通常需要使用 \`root\` 权限。以下命令示例演示如何使用 \`sudo\` 加载和卸载内核模块：

#### 9.1 使用 root 权限加载模块

\`\`\`bash
$ sudo kmod load vfat
\`\`\`

**解释**：
- \`sudo\` 提升权限后可以加载内核模块，适用于需要管理员权限的操作。

#### 9.2 使用 root 权限卸载模块

\`\`\`bash
$ sudo kmod unload vfat
\`\`\`

**解释**：
- 使用 \`sudo\` 卸载内核模块时，确保你有足够权限执行此操作。

### 10. 总结

\`kmod\` 是 Linux 系统中用于管理内核模块的强大工具，它可以动态加载、卸载、列出内核模块，帮助管理员在运行时调整系统功能。\`kmod\` 提供了灵活的内核模块管理方法，尤其是在无需重启系统的情况下，可以方便地管理驱动程序或其他功能模块。

        `,
    };
  },
});
</script>
<style scoped></style>
