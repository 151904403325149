<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## chattr 命令详解

\`chattr\` 是 Linux 系统中用于更改文件或目录属性的命令。它的功能类似于 \`chmod\`，但更为高级。通过 \`chattr\`，你可以设置文件的特殊属性，使其具备防篡改、不可删除、不可修改等功能。这在系统安全、数据保护场景中非常重要，尤其是防止系统关键文件被意外修改或删除。

在系统管理中，运维人员经常使用 \`chattr\` 来加强对重要文件的保护，尤其是在防止系统被恶意软件或用户操作破坏方面。

### 安装情况

在大多数现代的 Linux 系统中，\`chattr\` 是 \`e2fsprogs\` 包的一部分，通常默认安装。如果没有该命令，用户可以通过以下命令安装。

#### Ubuntu 和 RHEL 安装包

- **Ubuntu**: \`sudo apt-get install e2fsprogs\`
- **RHEL**: \`sudo yum install e2fsprogs\`

### 基本语法

\`\`\`bash
chattr [操作符] [选项] 文件名
\`\`\`

- \`操作符\`：可以是 \`+\`（添加属性）、\`-\`（移除属性）或 \`=\`（设置属性）。
- \`选项\`：用于指定要操作的属性。
- \`文件名\`：需要修改属性的文件或目录。

### 常见选项与属性

- **\`i\`**：不可更改（immutable）。文件设置此属性后，不能删除、修改，也不能创建硬链接。
- **\`a\`**：只能追加（append only）。文件只能被追加内容，不能删除或修改已有内容。
- **\`u\`**：删除后可以恢复（undelete）。文件被删除后，数据依然保留，便于恢复。
- **\`s\`**：安全删除（secure delete）。文件被删除后，其内容会被完全清除。
- **\`A\`**：不修改访问时间（no atime）。当文件被访问时，不更新文件的访问时间。
  
### 查看文件属性

要查看文件的特殊属性，可以使用 \`lsattr\` 命令：

\`\`\`bash
lsattr 文件名
\`\`\`

**示例**：

\`\`\`bash
lsattr /tmp/testfile
\`\`\`

**输出示例**：

\`\`\`plaintext
----i--------e---- /tmp/testfile
\`\`\`

**解读**：文件 \`/tmp/testfile\` 具有不可修改（\`i\`）属性。

### 示例一：设置文件为不可修改

\`\`\`bash
sudo chattr +i /tmp/testfile
\`\`\`

**解读**：此命令将 \`/tmp/testfile\` 设置为不可修改，即使是 root 用户也无法删除或修改该文件。

\`\`\`bash
lsattr /tmp/testfile
\`\`\`

**输出示例**：

\`\`\`plaintext
----i--------e---- /tmp/testfile
\`\`\`

此时，尝试修改或删除该文件将会失败：

\`\`\`bash
rm /tmp/testfile
\`\`\`

**错误输出**：

\`\`\`plaintext
rm: cannot remove '/tmp/testfile': Operation not permitted
\`\`\`

**解读**：由于文件已被设置为不可修改，删除操作被阻止。

### 示例二：解除不可修改属性

\`\`\`bash
sudo chattr -i /tmp/testfile
\`\`\`

**解读**：此命令移除了文件的不可修改属性，允许对文件进行删除或修改。

\`\`\`bash
lsattr /tmp/testfile
\`\`\`

**输出示例**：

\`\`\`plaintext
---------------- /tmp/testfile
\`\`\`

此时，文件的保护状态已经解除，可以正常删除：

\`\`\`bash
rm /tmp/testfile
\`\`\`

**无输出，命令已成功执行**。

### 示例三：设置文件为只能追加内容

\`\`\`bash
sudo chattr +a /var/log/mylogfile
\`\`\`

**解读**：此命令设置 \`/var/log/mylogfile\` 为只能追加内容。现有内容无法被修改或删除，只能通过追加方式写入新的内容。

尝试写入新内容：

\`\`\`bash
echo "New log entry" >> /var/log/mylogfile
\`\`\`

**无输出，命令已成功执行**。

如果尝试覆盖或删除文件内容，会失败：

\`\`\`bash
echo "Overwrite log" > /var/log/mylogfile
\`\`\`

**错误输出**：

\`\`\`plaintext
bash: /var/log/mylogfile: Operation not permitted
\`\`\`

**解读**：由于文件具有只能追加内容的属性，覆盖操作被阻止。

### 示例四：为目录设置递归属性

\`chattr\` 可以对目录进行递归操作，使该目录下的所有文件和子目录都继承相同的属性。使用 \`-R\` 选项递归设置属性。

\`\`\`bash
sudo chattr -R +i /home/sre/documents
\`\`\`

**解读**：此命令将 \`/home/sre/documents\` 目录及其所有子文件、子目录设置为不可修改。

### 权限需求

\`chattr\` 命令通常需要 root 权限来修改文件的属性，尤其是涉及系统级文件时。你可以使用 \`sudo\` 来执行 \`chattr\` 命令。

例如：

\`\`\`bash
sudo chattr +i /etc/important_config
\`\`\`

**解读**：此命令将 \`/etc/important_config\` 文件设置为不可修改，任何用户包括 root 用户都无法直接修改或删除该文件，必须先解除该属性。

### 多用户环境示例

假设有多个用户 \`sre\`、\`dev01\`、\`app01\` 共享一个系统，需要确保某些系统关键文件不被误操作，可以为某些重要文件添加只读属性：

\`\`\`bash
sudo chattr +i /etc/system.conf
\`\`\`

此时，任何用户，包括 \`dev01\` 和 \`app01\`，都无法修改该文件：

\`\`\`bash
su - dev01
echo "modify" >> /etc/system.conf
\`\`\`

**错误输出**：

\`\`\`plaintext
bash: /etc/system.conf: Operation not permitted
\`\`\`

**解读**：\`dev01\` 用户试图修改系统文件，但由于该文件设置了不可修改属性，操作失败。

### 不同架构下的表现

在 \`x86_64\` 和 \`aarch64\` 架构下，\`chattr\` 的功能和输出没有显著区别。该命令的表现和操作原理在不同硬件平台上保持一致。

### 总结

\`chattr\` 是 Linux 系统中用于设置文件和目录属性的强大工具，可以通过添加各种属性增强文件的安全性和保护性。在多用户系统、关键文件保护和日志安全管理中，\`chattr\` 能有效防止误操作和恶意篡改。了解并善用 \`chattr\` 可以帮助系统管理员更加灵活地管理文件权限和安全策略。

        `,
    };
  },
});
</script>
<style scoped></style>
