<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## free 命令详解

\`free\` 命令是Linux系统中用于显示当前内存和交换空间使用情况的命令。它提供了系统物理内存、交换内存的使用和空闲情况，帮助管理员监控系统的内存使用情况。

### 1. 基本用法

\`free\` 命令的最简单用法是直接输入 \`free\`，它会显示系统内存的概要信息。

\`\`\`bash
sre@azlinux-prod-cn:~$ free
\`\`\`

**输出示例**：

\`\`\`plaintext
               total        used        free      shared  buff/cache   available
Mem:         16269388     1023456      756304      132448    14479628     1234568
Swap:        2097148          128     2097020
\`\`\`

**解读**：

- **total**：总内存量（包括物理内存和交换空间）。
- **used**：已使用的内存量。
- **free**：未使用的内存量。
- **shared**：共享内存量。
- **buff/cache**：系统用于缓存和缓冲的内存量。
- **available**：当前可用的内存量，考虑了缓存和缓冲后计算得出。

### 2. 常用参数

#### -h (human-readable)

以人类可读的格式显示内存信息（单位为KB、MB、GB）。

\`\`\`bash
sre@azlinux-prod-cn:~$ free -h
\`\`\`

**输出示例**：

\`\`\`plaintext
              total        used        free      shared  buff/cache   available
Mem:           15G        1.2G        1.0G        132M         13G        1.2G
Swap:         2.0G        128K        2.0G
\`\`\`

**解读**：与未加参数时相比，\`-h\` 选项让输出更易读，使用了MB和GB来表示内存大小。

#### -m (显示为MB)

以MB为单位显示内存信息。

\`\`\`bash
sre@azlinux-prod-cn:~$ free -m
\`\`\`

**输出示例**：

\`\`\`plaintext
              total        used        free      shared  buff/cache   available
Mem:          15900        1023         739         132        14135        1203
Swap:         2047           0        2047
\`\`\`

**解读**：这个选项非常有用，特别是在监控或汇报系统状态时使用。

#### -g (显示为GB)

以GB为单位显示内存信息。

\`\`\`bash
sre@azlinux-prod-cn:~$ free -g
\`\`\`

**输出示例**：

\`\`\`plaintext
              total        used        free      shared  buff/cache   available
Mem:             15           1           1           0          13           1
Swap:             1           0           1
\`\`\`

**解读**：用于在大内存系统上工作时，快速查看内存使用情况。

#### -t (显示总和)

显示物理内存和交换空间的总和。

\`\`\`bash
sre@azlinux-prod-cn:~$ free -t
\`\`\`

**输出示例**：

\`\`\`plaintext
              total        used        free      shared  buff/cache   available
Mem:          16269388     1023456      756304      132448    14479628     1234568
Swap:         2097148        128     2097020
Total:        18366536     1023584     2853324
\`\`\`

**解读**：在输出的最后一行显示了内存和交换空间的总和，这是一个很有用的选项，可以迅速查看系统总共可用的内存资源。

### 3. 注意事项

- **root权限**：\`free\` 命令通常不需要root权限即可运行，但是在某些安全策略严格的系统中，可能会限制普通用户的使用。

- **多架构输出差异**：在不同架构的系统（如x86_64/amd64与aarch64）上，\`free\` 命令的输出内容和格式基本一致。某些极端情况下，系统的内存分配策略不同，可能会导致不同架构下的 \`available\` 或 \`buff/cache\` 数值有所不同。

### 4. 版本差异

旧版本的 \`free\` 命令（如procps-ng 3.3.10之前的版本）输出格式可能会有所不同，特别是 \`available\` 字段是后来引入的。因此在使用较老系统时需注意输出格式的差异。

### 5. 示例总结

使用 \`free\` 命令可以迅速查看系统的内存使用情况，并通过不同的选项调整输出格式，方便系统监控和管理。它是系统管理员日常工作中不可或缺的工具之一。

\`\`\`bash
sre@azlinux-prod-cn:~$ free -h
              total        used        free      shared  buff/cache   available
Mem:           15G        1.2G        1.0G        132M         13G        1.2G
Swap:         2.0G        128K        2.0G
\`\`\`

【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
