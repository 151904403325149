<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## cd 命令详解

\`cd\`（change directory）命令是Linux系统中用于改变当前工作目录的基本命令。通过\`cd\`命令，用户可以在文件系统中移动到不同的目录。本文将详细介绍\`cd\`命令的使用方法、常见参数及其应用示例，并对命令的输出结果进行解读。

### 基本用法

\`cd\`命令最基本的用法是指定目标目录的路径来更改当前工作目录。例如，要进入\`/home/sre/Documents\`目录，可以使用以下命令：

\`\`\`bash
cd /home/sre/Documents
\`\`\`

**示例：**

\`\`\`bash
cd /home/sre/Documents
pwd
\`\`\`

**示例输出：**

\`\`\`
/home/sre/Documents
\`\`\`

**解读：** \`cd\`命令将当前工作目录更改为\`/home/sre/Documents\`，\`pwd\`命令（print working directory）显示了新的当前目录，验证了\`cd\`命令的成功执行。

### 使用相对路径

\`cd\`命令也支持相对路径，相对路径是相对于当前目录的路径。例如，从\`/home/sre\`目录切换到\`/home/sre/Documents\`的相对路径是\`Documents\`，从当前目录切换到上级目录使用\`..\`。

**示例：**

\`\`\`bash
cd Documents
pwd
\`\`\`

**示例输出：**

\`\`\`
/home/sre/Documents
\`\`\`

**解读：** 如果当前目录是\`/home/sre\`，\`cd Documents\`将会进入\`Documents\`子目录，并且\`pwd\`显示了正确的路径。

### 使用环境变量

\`cd\`命令支持使用环境变量来指定目录。例如，\`$HOME\`环境变量代表用户的主目录。

**示例：**

\`\`\`bash
cd $HOME/Documents
pwd
\`\`\`

**示例输出：**

\`\`\`
/home/sre/Documents
\`\`\`

**解读：** \`$HOME\`代表的是用户\`sre\`的主目录，因此\`cd $HOME/Documents\`命令将当前目录更改为\`/home/sre/Documents\`，\`pwd\`确认了路径的正确性。

### 错误处理

如果尝试切换到一个不存在的目录，\`cd\`命令将返回错误消息。此时可以使用\`pwd\`命令来验证当前目录是否未改变。

**示例：**

\`\`\`bash
cd /home/sre/nonexistent_directory
\`\`\`

**示例输出：**

\`\`\`
bash: cd: /home/sre/nonexistent_directory: No such file or directory
pwd
\`\`\`

**示例输出（pwd命令）：**

\`\`\`
/home/sre/Documents
\`\`\`

**解读：** 错误消息表明目标目录\`/home/sre/nonexistent_directory\`不存在。\`pwd\`命令显示当前目录仍为\`/home/sre/Documents\`，表明\`cd\`命令未成功执行，当前目录未发生变化。

### 特殊目录符号

\`cd\`命令支持一些特殊的目录符号，这些符号可以简化目录的导航操作：

- **\`.\`（当前目录）**：表示当前目录。例如，\`cd .\`不会改变目录。
- **\`..\`（上级目录）**：表示当前目录的上一级目录。例如，\`cd ..\`会切换到上级目录。

**示例：**

\`\`\`bash
cd ..
pwd
\`\`\`

**示例输出：**

\`\`\`
/home/sre
\`\`\`

**解读：** \`cd ..\`将当前目录更改为上级目录。如果当前目录是\`/home/sre/Documents\`，执行后将变为\`/home/sre\`，\`pwd\`命令确认了当前目录的变化。

### 总结

\`cd\`命令是文件系统导航的核心工具。通过使用绝对路径、相对路径和环境变量，你可以高效地在目录中移动。同时，理解如何处理错误和使用特殊目录符号能够帮助你更灵活地管理工作目录。掌握这些基本用法和技巧，将大大提高你在Linux系统中的操作效率。

      `,
    };
  },
});
</script>
<style scoped></style>
