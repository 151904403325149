<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## fg 命令详解

\`fg\` 命令是Linux系统中用于将后台作业（jobs）切换到前台运行的一个常用命令。它通常与 \`&\`、\`jobs\` 和 \`bg\` 命令一起使用，用于管理当前Shell中的作业。\`fg\` 命令是一个Bash内置命令，它没有外部命令的版本，因此所有的用法和操作都在当前的Shell环境中进行。

### 基本语法

\`fg\` 命令的基本语法如下：

\`\`\`bash
fg [作业编号]
\`\`\`

- **作业编号**: 指定要切换到前台的作业号，如果省略，\`fg\` 会默认将最近一个后台作业切换到前台。

### 使用场景与示例

#### 1. 将后台作业切换到前台

假设你启动了一个长时间运行的任务，并且使用 \`&\` 将其放入后台运行，此时可以使用 \`fg\` 命令将其切换到前台。

\`\`\`bash
$ sleep 300 &
[1] 12345
$ fg
sleep 300
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
在该示例中，\`sleep 300 &\` 将 \`sleep\` 命令放到后台执行，并分配作业编号 \`[1]\` 和进程ID \`12345\`。使用 \`fg\` 命令后，\`sleep 300\` 被切换到前台继续执行。

#### 2. 使用 \`jobs\` 命令查看后台作业

在使用 \`fg\` 命令之前，你可以使用 \`jobs\` 命令查看当前所有的后台作业，确定要切换到前台的作业编号。

\`\`\`bash
$ sleep 300 &
$ sleep 600 &
$ jobs
[1]-  Running                 sleep 300 &
[2]+  Running                 sleep 600 &
\`\`\`

**解读：**
命令 \`jobs\` 列出了所有当前在后台运行的作业及其编号，编号 \`[1]\` 和 \`[2]\` 分别对应两个 \`sleep\` 命令。

#### 3. 将指定作业切换到前台

如果有多个后台作业，你可以通过指定作业编号来将特定作业切换到前台。

\`\`\`bash
$ fg %2
sleep 600
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将编号为 \`2\` 的 \`sleep 600\` 作业切换到前台运行。前缀 \`%\` 用于表示作业编号。

### 重要参数

\`fg\` 命令没有复杂的选项，它主要依赖作业编号来确定要切换到前台的作业。以下是一些常见的使用方式：

- **默认用法**: 不带参数时，\`fg\` 默认切换最近一个后台作业到前台。
- **指定作业编号**: 通过 \`fg %[作业编号]\` 指定要切换的作业。

### 常见问题

#### 1. 使用 \`Ctrl+Z\` 将作业移至后台

在实际操作中，可以使用 \`Ctrl+Z\` 快捷键将当前正在前台运行的作业暂停并移到后台，此时可以使用 \`fg\` 命令将其恢复到前台继续运行。

\`\`\`bash
$ sleep 1000
^Z
[1]+  Stopped                 sleep 1000
$ fg
sleep 1000
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
使用 \`Ctrl+Z\` 后，\`sleep 1000\` 命令被暂停并移至后台。使用 \`fg\` 后，作业继续在前台执行。

### 权限要求

\`fg\` 命令通常不涉及特定的权限要求，因为它仅管理当前Shell会话中的作业。即使你使用 \`sudo\` 执行某些后台命令，\`fg\` 也可以直接切换这些作业到前台。

### 多用户场景

在多用户环境中，各个用户管理自己的作业，\`fg\` 命令只会影响当前Shell会话中的作业，不能影响其他用户的作业。以下是一个示例：

\`\`\`bash
# 用户sre的操作
$ sleep 1000 &
[1] 23456
$ fg %1
sleep 1000
\`\`\`

**解读：**
\`fg\` 命令仅影响 \`sre\` 用户的作业，而不会影响其他用户（如 \`dev01\`、\`app01\`）的作业。

### 总结

\`fg\` 命令是一个简单但功能强大的工具，用于管理和控制Shell会话中的作业。通过掌握 \`fg\` 命令及其相关命令（如 \`jobs\`、\`bg\`、\`Ctrl+Z\`），你可以更灵活地处理前台和后台作业，从而提高工作效率。掌握这些工具对系统管理员和开发人员来说尤为重要，特别是在需要同时处理多个任务的场景下。

        `,
    };
  },
});
</script>
<style scoped></style>
