<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dnf/yum 命令详解

\`dnf\` 和 \`yum\` 是 Linux 系统中两个常用的软件包管理工具，主要用于在 RHEL、CentOS、Fedora 等系统上安装、更新和删除软件包。虽然 \`dnf\` 是 \`yum\` 的升级版，但两者的基本功能类似。本文将详细介绍这两个命令的常见用法和重要参数。

### 1. 安装软件包

#### dnf 命令

\`dnf\` 是 Fedora 和 RHEL 8 及其之后版本默认的软件包管理工具，提供了更加高效的依赖解析机制。

\`\`\`bash
sudo dnf install httpd
\`\`\`

输出:
\`\`\`bash
Last metadata expiration check: 0:03:41 ago on Wed 02 Oct 2024 10:20:16 AM UTC.
Dependencies resolved.
================================================================================
 Package               Arch       Version              Repository         Size
================================================================================
Installing:
 httpd                 x86_64     2.4.37-43.module_el8.4.0+843+33ecb3c2   AppStream     1.4 M

Transaction Summary
================================================================================
Install  1 Package

Total download size: 1.4 M
Installed size: 4.9 M
Is this ok [y/N]: y
Downloading Packages:
httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64.rpm             556 kB/s | 1.4 MB     00:02    
----------------------------------------------------------------------------------------------
Total                                                               556 kB/s | 1.4 MB     00:02     
Running transaction check
Transaction check succeeded.
Running transaction test
Transaction test succeeded.
Running transaction
  Preparing        :                                                                    1/1 
  Installing       : httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                 1/1 
  Verifying        : httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                 1/1 
Installed:
  httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                                        

Complete!
\`\`\`

命令成功执行后，\`httpd\` 已成功安装。可以通过 \`dnf list installed | grep httpd\` 来验证。

#### yum 命令

\`yum\` 是 \`dnf\` 的前身，仍然在一些旧版本的 RHEL 和 CentOS 系统上使用。

\`\`\`bash
sudo yum install httpd
\`\`\`

输出与 \`dnf\` 类似，命令完成后同样可使用 \`yum list installed | grep httpd\` 验证安装。

**对比**: \`yum\` 在依赖解析和性能上不如 \`dnf\`，因此在 RHEL 8 及之后的版本中，\`dnf\` 被推荐使用。

### 2. 升级系统

#### dnf 命令

系统升级是维护服务器的重要工作，可以通过以下命令来升级所有已安装的软件包：

\`\`\`bash
sudo dnf upgrade
\`\`\`

输出:
\`\`\`bash
Last metadata expiration check: 0:00:05 ago on Wed 02 Oct 2024 10:30:21 AM UTC.
Dependencies resolved.
Nothing to do.
Complete!
\`\`\`

表示系统已经是最新的，无需进行任何升级操作。

#### yum 命令

\`\`\`bash
sudo yum update
\`\`\`

与 \`dnf upgrade\` 类似，输出信息会列出需要更新的包，如果没有更新，会提示【Nothing to do】。

### 3. 查找软件包

可以通过 \`dnf search\` 或 \`yum search\` 命令查找特定的软件包。示例命令：

\`\`\`bash
dnf search nginx
\`\`\`

输出:
\`\`\`bash
nginx.x86_64 : A high performance web server and reverse proxy server
nginx-mod-http-image-filter.x86_64 : HTTP image filter module for Nginx
nginx-mod-http-perl.x86_64 : Perl module for Nginx
\`\`\`

该命令列出了与 \`nginx\` 相关的包，提供详细描述。

### 4. 删除软件包

#### dnf 命令

删除已经安装的软件包时，可以使用 \`dnf remove\` 命令。例如，删除 \`httpd\` 软件包：

\`\`\`bash
sudo dnf remove httpd
\`\`\`

输出:
\`\`\`bash
Dependencies resolved.
================================================================================
 Package        Arch          Version                    Repository         Size
================================================================================
Removing:
 httpd          x86_64        2.4.37-43.module_el8.4.0+843+33ecb3c2         @AppStream         4.9 M

Transaction Summary
================================================================================
Remove  1 Package

Is this ok [y/N]: y
Running transaction
  Preparing        :                                                                    1/1 
  Erasing          : httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                 1/1 
  Verifying        : httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                 1/1 
Removed:
  httpd-2.4.37-43.module_el8.4.0+843+33ecb3c2.x86_64                                        

Complete!
\`\`\`

#### yum 命令

\`\`\`bash
sudo yum remove httpd
\`\`\`

与 \`dnf\` 删除软件包的方式类似。

### 5. 列出已安装软件包

#### dnf 命令

\`\`\`bash
dnf list installed
\`\`\`

输出会列出系统上所有已安装的软件包：

\`\`\`bash
Installed Packages
bash.x86_64                4.4.20-2.el8_4                @BaseOS
coreutils.x86_64           8.30-8.el8_4.1                @BaseOS
\`\`\`

#### yum 命令

\`\`\`bash
yum list installed
\`\`\`

两者输出非常相似。

### 6. 清理缓存

\`dnf\` 和 \`yum\` 都会在使用过程中缓存下载的软件包和元数据。可以使用以下命令清理缓存。

#### dnf 命令

\`\`\`bash
sudo dnf clean all
\`\`\`

输出:
\`\`\`bash
8 files removed
\`\`\`

#### yum 命令

\`\`\`bash
sudo yum clean all
\`\`\`

与 \`dnf\` 类似，输出信息通常是删除的缓存文件数量。

### 7. root 权限的使用

在大多数情况下，安装、删除和更新软件包都需要 root 权限。使用 \`sudo\` 命令可以临时提升权限。例如：

\`\`\`bash
sudo dnf install vim
\`\`\`

这会以 root 用户的权限执行 \`dnf\` 安装操作。

### 8. 替代命令

在现代系统上，\`dnf\` 已逐渐取代 \`yum\` 成为默认的包管理器。对于老版本的系统，如 RHEL 7，仍然推荐使用 \`yum\`。

### 9. 不同架构下的行为

\`dnf\` 和 \`yum\` 在不同架构（如 aarch64 和 x86_64）上的行为和输出基本相同，没有明显差异。

### 总结

\`dnf\` 和 \`yum\` 是 Linux 环境中强大的包管理工具，支持多种操作如安装、更新、删除、查找软件包等。随着系统的演进，\`dnf\` 在新版本的 Linux 系统中逐渐取代了 \`yum\`，提供了更高效的依赖管理和性能优化。

        `,
    };
  },
});
</script>
<style scoped></style>
