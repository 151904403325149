<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## chmod 命令详解

\`chmod\` 命令用于更改文件或目录的权限。在 Linux 和 Unix 系统中，文件权限决定了谁可以读取、写入或执行文件。掌握 \`chmod\` 命令的使用方法对于文件和目录的安全管理至关重要。本文将详细介绍 \`chmod\` 命令的使用方法，包括常见参数的功能和示例。

### 基本语法

\`chmod\` 命令的基本语法如下：

\`\`\`bash
chmod [选项] 模式 文件或目录
\`\`\`

### 权限模式

\`chmod\` 命令的权限模式有两种主要表示方法：

1. **符号模式（Symbolic Mode）**: 使用字符表示权限。
2. **数字模式（Numeric Mode）**: 使用数字表示权限。

#### 符号模式

- **r**: 读权限
- **w**: 写权限
- **x**: 执行权限

权限可以应用到三类用户：

- **u**: 文件所有者（User）
- **g**: 文件所属组（Group）
- **o**: 其他用户（Others）

符号模式的基本格式为：

\`\`\`bash
chmod [ugoa][+-=][rwx] 文件或目录
\`\`\`

- **+**: 添加权限
- **-**: 删除权限
- **=**: 设定权限

#### 数字模式

数字模式使用三个八进制数字表示权限：

- **4**: 读权限
- **2**: 写权限
- **1**: 执行权限

权限的计算是将权限值相加得到的。例如，**7** 代表读、写和执行权限（4+2+1）。

### 常用参数

#### 1. 添加权限

- **符号模式**: \`chmod +rwx 文件\`
  
  \`\`\`bash
  $ chmod +x script.sh
  \`\`\`
  
  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`script.sh\` 文件的执行权限添加到文件所有者、所属组和其他用户。

- **数字模式**: \`chmod 755 文件\`
  
  \`\`\`bash
  $ chmod 755 file1.txt
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`file1.txt\` 文件的权限设置为 \`755\`，即所有者具有读、写和执行权限，所属组和其他用户具有读和执行权限。

#### 2. 删除权限

- **符号模式**: \`chmod -r 文件\`
  
  \`\`\`bash
  $ chmod -w file2.txt
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`file2.txt\` 文件的写权限删除。

- **数字模式**: \`chmod 644 文件\`
  
  \`\`\`bash
  $ chmod 644 file3.txt
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`file3.txt\` 文件的权限设置为 \`644\`，即所有者具有读和写权限，所属组和其他用户具有读权限。

#### 3. 设定权限

- **符号模式**: \`chmod u=rwx,g=rx,o=r 文件\`
  
  \`\`\`bash
  $ chmod u=rwx,g=rx,o=r file4.txt
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`file4.txt\` 文件的权限设置为所有者具有读、写和执行权限，所属组具有读和执行权限，其他用户仅具有读权限。

- **数字模式**: \`chmod 700 文件\`
  
  \`\`\`bash
  $ chmod 700 file5.txt
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`

  **解读：**
  此命令将 \`file5.txt\` 文件的权限设置为 \`700\`，即所有者具有读、写和执行权限，所属组和其他用户没有任何权限。

#### 4. 递归更改目录及其内容的权限

- **符号模式**: \`chmod -R u+rwx 文件夹\`
  
  \`\`\`bash
  $ chmod -R u+rwx /home/sre/myfolder
  \`\`\`

  **输出：**

  \`\`\`
  【无输出，命令已成功执行】
  \`\`\`
  **解读：**
  此命令递归地将 \`/home/sre/myfolder\` 目录及其所有子目录和文件的所有者权限更改为读、写和执行权限。

- **数字模式**: \`chmod -R 755 目录\`
  
  \`\`\`bash
  $ chmod -R 755 /var/log/myapp
  \`\`\`

  **输出：**

  【无输出，命令已成功执行】

  **解读：**
  此命令递归地将 \`/var/log/myapp\` 目录及其所有子目录和文件的权限设置为 \`755\`。

### 权限要求

某些 \`chmod\` 命令选项可能需要 root 权限才能执行。例如，递归更改目录及其内容的权限通常需要管理员权限。如果当前用户没有足够权限，则会收到权限拒绝错误。

### 总结

\`chmod\` 命令是管理 Linux 系统文件和目录权限的重要工具。通过掌握 \`chmod\` 的符号模式和数字模式，您可以灵活地调整文件权限，确保系统的安全性和资源的合理利用。

        `,
    };
  },
});
</script>
<style scoped></style>
