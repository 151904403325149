<template>
  <div class="diy-css">
    <TaskBoxLeftLine
      task="command not found，新入职的开发人员反馈，他Ubuntu系统里没有zsh命令，导致一些工作无法完成，请你帮它安装上zsh命令。"
      source="该需求来自于开发部门"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink
      :resId="resId"
      :defaultLink="bilibiliLink"
      style="margin-bottom: 1rem"
      >视频解说</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLine from "../../base/TaskBoxLeftLine.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdApt",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLine,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10062",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
在 Ubuntu 系统中，使用 \`apt\` 命令安装软件非常简单。下面是一个常见的安装软件的示例：

### 使用 \`apt\` 安装软件的步骤：

1. **更新软件包索引（推荐步骤，尤其是新系统，如果忽略该步骤，您要安装的软件可能无法搜索到，apt search zsh）**：
   \`\`\`bash
   sudo apt update
   \`\`\`

   **原因**：\`apt update\` 会更新本地软件包索引，使系统知道最新的可用软件包版本。如果不执行这个步骤，系统可能会安装旧版本的包或者提示某些软件包不可用。所以在大多数情况下，建议在安装软件之前运行 \`apt update\`。

2. **安装软件**：
   \`\`\`bash
   sudo apt install <package_name>
   \`\`\`
   示例：
   \`\`\`bash
   sudo apt install curl
   \`\`\`

   这会在系统上安装 \`curl\` 工具。

3. **（可选）清理不再需要的包**：
   \`\`\`bash
   sudo apt autoremove
   \`\`\`
   这会清理掉不再需要的依赖包，节省磁盘空间。

---

### \`apt-get\` vs \`apt\` vs \`aptitude\`

- **\`apt-get\`**：这是一个经典的命令行工具，用于管理Debian和基于Debian的系统（如Ubuntu）中的软件包。它非常稳定，并且在所有Debian系系统上可用，但它的输出和界面不太直观，主要适用于脚本或需要更高级选项的用户。

- **\`apt\`**：\`apt\` 是较新的工具，设计用于替代 \`apt-get\` 和 \`apt-cache\`，使得包管理更加简单直观。它的输出更用户友好，适合终端操作。\`apt\` 集成了 \`apt-get\` 的许多常用功能，因此推荐普通用户日常使用 \`apt\` 而不是 \`apt-get\`。

  常见用法：
  \`\`\`bash
  sudo apt update         # 更新软件包索引
  sudo apt upgrade        # 升级系统中的软件包
  sudo apt install curl   # 安装软件包
  sudo apt remove curl    # 卸载软件包
  sudo apt autoremove     # 清理不再需要的依赖
  \`\`\`

- **\`aptitude\`**：\`aptitude\` 是一个功能更强大的包管理工具，它提供了图形化的终端界面和命令行功能。\`aptitude\` 可以自动处理包依赖问题，并提供比 \`apt-get\` 和 \`apt\` 更复杂的包管理功能。然而，\`aptitude\` 在Ubuntu中不是默认安装的，需要手动安装。

  示例安装 \`aptitude\`：
  \`\`\`bash
  sudo apt install aptitude
  \`\`\`

### 建议使用哪个？

对于大多数用户来说，**\`apt\`** 是推荐的工具，因为它足够强大且简单易用，涵盖了日常使用的所有场景。只有在特殊需求或自动化脚本中，才需要考虑使用 \`apt-get\` 或 \`aptitude\`。

        `,
      markdownContentExpand: `
### Zsh 简单介绍与优点

**Zsh**（Z Shell）是一种功能强大的命令行 shell，它扩展了传统的 Bourne Shell（sh），并融合了 Bash 和 tcsh 的特性。Zsh 不仅具备高度可定制性，还为用户提供了高效和便利的开发体验。下面是 Zsh 的一些主要特性和优点：

#### 1. **自动补全与建议**
Zsh 拥有非常智能的命令补全功能。当你输入命令的一部分时，Zsh 可以提供命令、文件路径、参数的自动补全，并给出多项建议。这一特性极大地提高了工作效率，尤其在处理复杂命令时非常有用。

#### 2. **命令纠错**
输入命令时，Zsh 可以自动检测并纠正拼写错误。例如，如果你输入错了常用命令名，Zsh 会智能地提出纠正建议并允许快速确认修改。

#### 3. **丰富的插件系统**
Zsh 通过插件可以进一步扩展其功能，最著名的插件管理器是 **Oh My Zsh**。通过插件，用户可以轻松集成 Git 状态显示、自动高亮命令、增加更多的命令补全等功能。插件系统极大地增强了 Zsh 的可扩展性。

#### 4. **主题支持**
Zsh 提供了丰富的主题支持，用户可以通过简单的配置文件修改来实现自定义的提示符和外观。许多主题可以展示当前的 Git 分支、系统状态等有用信息。

#### 5. **历史记录搜索**
Zsh 允许用户通过简单快捷的方式搜索之前输入的命令历史。例如，使用 \`Ctrl + R\` 可以根据输入内容实时匹配命令历史，快速找到并执行之前的命令。

#### 6. **全局别名**
Zsh 支持全局别名，允许你在命令的任何位置使用定义好的别名，进一步缩短常用命令的输入时间。这在处理长命令时非常实用。

#### 7. **强大的脚本编写能力**
Zsh 提供了许多增强的脚本功能，如数组处理、模式匹配等。与传统的 Bash 相比，Zsh 的脚本编写功能更加强大，特别适合复杂脚本的编写。

### 结论
Zsh 是一款现代、功能强大的 shell，提供了高效的自动补全、命令纠错等特性，使其成为开发者的高效工具。通过插件和主题的支持，Zsh 的用户体验可以根据个人需求进行深度定制，是替代 Bash 的理想选择之一。

`,
      markdownInterview: ` 
#### 选择题

1. **APT 是什么的缩写？**
   - A. Advanced Program Tool
   - B. Advanced Packaging Tool
   - C. Automatic Package Tool
   - D. Advanced Processor Tool

2. **使用 \`apt\` 命令更新包数据库的命令是什么？**
   - A. \`apt update\`
   - B. \`apt upgrade\`
   - C. \`apt dist-upgrade\`
   - D. \`apt install\`

3. **\`apt install\` 命令的主要作用是什么？**
   - A. 卸载软件包
   - B. 清理包缓存
   - C. 安装软件包
   - D. 列出已安装的软件包

4. **以下哪个命令用于清除已经下载但不再需要的包文件？**
   - A. \`apt clean\`
   - B. \`apt autoremove\`
   - C. \`apt purge\`
   - D. \`apt autoclean\`

5. **如果想升级已安装的所有包，但保持现有配置文件不变，应该使用哪个命令？**
   - A. \`apt install\`
   - B. \`apt update\`
   - C. \`apt upgrade\`
   - D. \`apt dist-upgrade\`

#### 问答题

1. **简要描述 \`apt update\` 和 \`apt upgrade\` 之间的区别。**

2. **如何使用 APT 命令删除软件包并删除与该包相关的配置文件？**

3. **解释 \`apt autoremove\` 命令的作用及其在系统维护中的重要性。**

4. **如何查看 APT 安装的某个软件包的详细信息？**

5. **在 Ubuntu 系统中，如何通过 APT 查找并安装指定版本的软件包？**

---

### 答案

1. **选择题**
   - 1. **B**
   - 2. **A**
   - 3. **C**
   - 4. **D**
   - 5. **C**

2. **问答题**
   - 1. \`apt update\` 用于更新本地包数据库，确保系统知道最新的可用包版本；\`apt upgrade\` 则用于将系统上安装的所有软件包更新至最新版本。
   - 2. 使用 \`apt purge package_name\` 命令可以删除软件包以及与之相关的配置文件。
   - 3. \`apt autoremove\` 用于删除那些不再被系统所需的依赖包。它帮助维护系统的清洁和节省磁盘空间，避免无用包长期占用存储。
   - 4. 使用 \`apt show package_name\` 可以查看某个软件包的详细信息，包括版本、依赖关系、安装大小等。
   - 5. 使用 \`apt list package_name\` 可以列出该软件包的所有可用版本，并使用 \`apt install package_name=version_number\` 安装指定版本的包。

  `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
