<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## whereis 命令详解

\`whereis\` 是一个用于定位程序、源代码和手册页的命令行工具。它通常用于快速查找安装在系统上的文件位置，包括可执行文件、源代码文件和文档文件。\`whereis\` 是 Unix 和类 Unix 系统中常用的工具，如 Linux 和 macOS。

### 基本用法

\`whereis\` 命令的基本语法如下：

\`\`\`bash
whereis [选项] [程序名]
\`\`\`

其中，\`[选项]\` 是用来控制输出的选项，\`[程序名]\` 是你要查找的程序或文件名。

### 常用选项

- \`-b\`：只查找二进制文件。
- \`-m\`：只查找手册页。
- \`-s\`：只查找源代码文件。
- \`-u\`：仅显示未在标准路径中的文件。
- \`-B 目录\`：指定二进制文件的搜索目录。
- \`-M 目录\`：指定手册页的搜索目录。
- \`-S 目录\`：指定源代码文件的搜索目录。

### 示例用法

#### 示例 1: 查找程序的位置

\`\`\`bash
whereis ls
\`\`\`

**输出：**

\`\`\`text
ls: /bin/ls /usr/share/man/man1/ls.1.gz
\`\`\`

**解读：** 该命令显示了 \`ls\` 命令的可执行文件的位置以及其手册页的位置。

#### 示例 2: 只查找二进制文件

\`\`\`bash
whereis -b ls
\`\`\`

**输出：**

\`\`\`text
ls: /bin/ls
\`\`\`

**解读：** 该命令只显示 \`ls\` 命令的二进制文件位置，不包括手册页或源代码文件。

#### 示例 3: 只查找手册页

\`\`\`bash
whereis -m ls
\`\`\`

**输出：**

\`\`\`text
ls: /usr/share/man/man1/ls.1.gz
\`\`\`

**解读：** 该命令只显示 \`ls\` 命令的手册页的位置。

#### 示例 4: 只查找源代码文件

\`\`\`bash
whereis -s ls
\`\`\`

**输出：**

\`\`\`text
ls:
\`\`\`

**解读：** \`ls\` 命令没有源代码文件，因此没有输出。

#### 示例 5: 使用多个搜索目录

\`\`\`bash
whereis -B /usr/local/bin -M /usr/local/share/man -S /usr/local/src ls
\`\`\`

**输出：**

\`\`\`text
ls: /usr/local/bin/ls /usr/share/man/man1/ls.1.gz
\`\`\`

**解读：** 该命令在指定的搜索目录中查找 \`ls\` 命令的位置。

### 在不同架构下的表现

\`whereis\` 命令在 aarch64 和 x86_64/amd64 架构下的输出是一致的，命令行为不会因架构不同而变化。

### 版本差异

\`whereis\` 的功能在不同版本间基本保持一致。使用以下命令检查版本：

\`\`\`bash
whereis --version
\`\`\`

**示例输出：**

\`\`\`text
whereis 1.16
\`\`\`

### 安全性和替代命令

\`whereis\` 是一个稳定且广泛使用的工具，没有被淘汰的风险。如果你需要更复杂的查找功能，可以使用 \`locate\` 命令。\`locate\` 通过维护一个文件数据库来快速查找文件。

### 安装

在 Ubuntu 和 RHEL 上，\`whereis\` 的安装包名称为 \`util-linux\`。可以使用以下命令安装：

**Ubuntu：**

\`\`\`bash
sudo apt-get install util-linux
\`\`\`

**RHEL：**

\`\`\`bash
sudo yum install util-linux
\`\`\`

### 权限

\`whereis\` 命令通常不需要 root 权限即可运行。但是，如果你需要查找系统范围的文件，可能需要 root 权限。

\`\`\`bash
sudo whereis ls
\`\`\`

**输出：**

\`\`\`text
ls: /bin/ls /usr/share/man/man1/ls.1.gz
\`\`\`

**解读：** 该命令以 root 权限运行，显示 \`ls\` 命令的所有位置，包括系统目录。

### 总结

\`whereis\` 是一个简洁且高效的工具，用于查找系统上的程序、源代码和手册页。它提供了多种选项来定制搜索范围，使得定位文件变得更加容易。

        `,
    };
  },
});
</script>
<style scoped></style>
