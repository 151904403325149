<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## uname 命令详解

\`uname\` 是一个用于显示系统信息的命令。在 Linux 系统中，它主要用于获取有关系统内核、机器硬件和操作系统的详细信息。这个命令非常有用，可以帮助用户了解当前运行的系统环境。

### 1. 基本用法

\`uname\` 命令的基本形式如下：

\`\`\`bash
uname [OPTION...]
\`\`\`

### 2. 常用选项

- \`-a\`：显示所有可用的信息，包括内核名称、节点名称、内核版本、操作系统类型等。
- \`-s\`：显示内核名称（默认行为）。
- \`-n\`：显示网络节点主机名。
- \`-r\`：显示内核版本。
- \`-v\`：显示内核版本的详细信息。
- \`-m\`：显示机器硬件名称。
- \`-p\`：显示处理器类型。
- \`-i\`：显示硬件平台信息。
- \`-o\`：显示操作系统名称。

### 3. 示例及解释

#### 3.1 显示所有信息

\`\`\`bash
$ uname -a
Linux azlinux-prod-cn 5.15.0-76-generic #1 SMP Thu Mar 28 22:31:17 UTC 2024 x86_64 x86_64 x86_64 GNU/Linux
\`\`\`

**输出解释**：
- \`Linux\`：内核名称
- \`azlinux-prod-cn\`：网络节点主机名
- \`5.15.0-76-generic\`：内核版本
- \`#1 SMP Thu Mar 28 22:31:17 UTC 2024\`：内核编译日期
- \`x86_64\`：机器硬件名称
- \`x86_64\`：处理器架构
- \`GNU/Linux\`：操作系统

#### 3.2 显示内核名称

\`\`\`bash
$ uname -s
Linux
\`\`\`

**输出解释**：\`Linux\` 表示当前系统的内核名称。

#### 3.3 显示内核版本

\`\`\`bash
$ uname -r
5.15.0-76-generic
\`\`\`

**输出解释**：\`5.15.0-76-generic\` 表示当前系统的内核版本。

#### 3.4 显示机器硬件名称

\`\`\`bash
$ uname -m
x86_64
\`\`\`

**输出解释**：\`x86_64\` 表示当前系统的机器硬件名称，通常是 64 位架构。

### 4. 不同架构下的输出差异

在不同的架构下，\`uname\` 命令的输出可能会有所不同。例如：

- 在 \`x86_64\` 架构下，\`uname -m\` 的输出通常是 \`x86_64\`。
- 在 \`aarch64\` 架构下，\`uname -m\` 的输出通常是 \`aarch64\`。

### 5. 版本差异

\`uname\` 命令的输出在不同的版本中通常没有显著差异，但在某些系统中，某些选项的实现可能有所不同。请参考系统的手册页或官方文档来获取详细信息。

### 6. 安装信息

\`uname\` 命令通常是 Linux 系统的默认工具，不需要单独安装。在 Ubuntu 和 RHEL 系统中，它通常包含在 \`coreutils\` 包中。

- **Ubuntu**：\`coreutils\` 包中已包含 \`uname\`。
- **RHEL**：\`coreutils\` 包中已包含 \`uname\`。

### 7. 需要的权限

\`uname\` 命令不需要特殊权限。普通用户即可执行。

### 8. 总结

\`uname\` 命令是一个强大且简单的工具，用于获取系统信息。了解其基本选项和用法，可以帮助用户更好地管理和诊断系统问题。

【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
