<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## bc 命令详解

\`bc\` 是一个用于数学计算的命令行工具，支持高精度的浮点数运算。它可以用作计算器，支持基本的算术运算、变量定义、控制结构以及更复杂的数学函数。

### bc 是 bash 内置命令还是外部命令？

\`bc\` 是一个外部命令，通常安装在 \`/usr/bin/bc\`，而不是 Bash 的内置命令。用户可以在终端中直接调用它进行计算。

### 基本语法

\`bc\` 命令的基本语法如下：

\`\`\`bash
bc [选项] [文件]
\`\`\`

### 重要参数及示例

#### 1. 启动 bc

启动 \`bc\` 命令，可以直接在终端输入 \`bc\`：

\`\`\`bash
bc
# 输出：
# 无输出，命令已成功执行
\`\`\`

在进入 \`bc\` 后，用户可以直接输入表达式进行计算。

#### 2. 进行基本运算

例如，计算 5 + 3：

\`\`\`bash
echo "5 + 3" | bc
# 输出：
# 8
\`\`\`

在这个示例中，\`bc\` 返回了简单算术表达式的结果。

#### 3. 浮点数运算

要进行浮点数运算，首先需要设置小数点的精度。可以通过设置 \`scale\` 来定义小数点后位数：

\`\`\`bash
echo "scale=2; 5 / 3" | bc
# 输出：
# 1.66
\`\`\`

这里，\`scale=2\` 指定结果保留两位小数。

#### 4. 使用变量

\`bc\` 允许用户定义变量并进行运算：

\`\`\`bash
echo "a=5; b=3; a + b" | bc
# 输出：
# 8
\`\`\`

通过定义变量 \`a\` 和 \`b\`，用户可以轻松进行运算。

#### 5. 使用条件语句

\`bc\` 支持控制结构，例如条件语句：

\`\`\`bash
echo "if (5 > 3) 1 else 0" | bc
# 输出：
# 1
\`\`\`

该命令判断 \`5\` 是否大于 \`3\`，并返回 \`1\` 表示真。

### 检查安装状态

用户可以使用 \`which\` 命令检查 \`bc\` 是否已安装：

\`\`\`bash
which bc
# 输出示例：
# /usr/bin/bc
\`\`\`

输出表明 \`bc\` 已安装并位于 \`/usr/bin\` 目录。

### 不同架构下的执行结果

\`bc\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

不同版本的 \`bc\` 可能会在支持的功能和选项上有所不同。可以使用以下命令检查版本：

\`\`\`bash
bc --version
# 输出示例：
# bc version 1.07.1
\`\`\`

版本信息可能会随着系统的不同而有所不同。

### 安装

在大多数 Linux 发行版中，\`bc\` 命令通常与 \`bc\` 包一起提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install bc
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install bc
  \`\`\`

### 使用root权限的场景

通常情况下，使用 \`bc\` 命令不需要 root 权限，但在某些情况下，例如执行权限受到限制的脚本时，可能需要使用 root 权限。

\`\`\`bash
sudo echo "scale=2; 5 / 3" | bc
# 输出：
# 1.66
\`\`\`

通过 \`sudo\` 提高权限，确保命令能成功执行。

### 总结

\`bc\` 是一个强大的命令行计算工具，适用于执行高精度的数学运算。熟悉 \`bc\` 的用法可以帮助用户在 Linux 环境中高效地进行复杂的计算。通过合理利用其功能，用户可以进行简单的计算以及处理更复杂的数学问题。

        `,
    };
  },
});
</script>
<style scoped></style>
