<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## find 命令详解

\`find\` 命令是Linux和Unix系统中非常强大的文件搜索工具。它能够在指定的目录及其子目录中，根据用户定义的条件查找文件和目录。本文将详细介绍\`find\`命令的使用方法及其重要参数。

### 1. 基本用法

\`find\`命令的基本语法如下：

\`\`\`bash
find [路径] [选项] [表达式]
\`\`\`

- **路径**：指定搜索的目录，默认为当前目录。
- **选项**：指定搜索条件，如文件名、大小、类型等。
- **表达式**：指定操作，如打印结果、删除文件等。

### 2. 常用选项

#### 2.1 -name 选项

\`-name\`选项用于按文件名查找文件。例如，查找当前目录中名为\`test.txt\`的文件：

\`\`\`bash
find . -name "test.txt"
\`\`\`

**输出：**

\`\`\`bash
./test.txt
\`\`\`

**解释：**
该命令会在当前目录及其子目录中查找名为\`test.txt\`的文件，并输出其路径。

#### 2.2 -type 选项

\`-type\`选项用于按文件类型查找文件。常见类型有：
- \`f\`：普通文件
- \`d\`：目录

例如，查找当前目录中的所有目录：

\`\`\`bash
find . -type d
\`\`\`

**输出：**

\`\`\`bash
.
./dir1
./dir2
\`\`\`

**解释：**
此命令查找当前目录及其子目录中的所有目录，并输出它们的路径。

#### 2.3 -size 选项

\`-size\`选项按文件大小查找文件。例如，查找大于1MB的文件：

\`\`\`bash
find . -size +1M
\`\`\`

**输出：**

\`\`\`bash
./largefile.txt
\`\`\`

**解释：**
此命令查找当前目录中大于1MB的文件，并输出其路径。

### 3. 组合条件查找

可以使用\`-and\`和\`-or\`等逻辑操作符组合多个条件。例如，查找文件名以\`.log\`结尾且大小大于1MB的文件：

\`\`\`bash
find . -name "*.log" -and -size +1M
\`\`\`

**输出：**

\`\`\`bash
./logs/biglog.log
\`\`\`

**解释：**
此命令查找当前目录中所有符合这两个条件的文件，并输出路径。

### 4. 执行命令

\`find\`命令不仅可以查找文件，还可以对找到的文件执行特定操作。常用的操作是\`-exec\`选项。例如，查找所有\`.log\`文件并删除：

\`\`\`
find . -name "*.log" -exec rm {} \\;
\`\`\`

**无输出，命令已成功执行。**

**解释：**
此命令找到所有以\`.log\`结尾的文件并删除它们。

### 5. 按文件修改时间查找

使用\`-mtime\`选项可以按文件修改时间查找文件。\`-mtime n\`表示查找修改时间为n天前的文件：

\`\`\`bash
find . -mtime -7
\`\`\`

**输出：**

\`\`\`bash
./recentfile.txt
\`\`\`

**解释：**
此命令查找7天内修改过的文件并输出路径。

### 6. 在不同架构下的表现

\`find\`命令在不同的架构下，如\`aarch64\`和\`x86_64/amd64\`，表现是相同的。两者的输出结果也不会因架构不同而发生变化。

### 7. 需要root权限的操作

如果需要查找整个系统中的文件，通常需要使用root权限：

\`\`\`bash
sudo find / -name "passwd"
\`\`\`

**输出：**

\`\`\`bash
/etc/passwd
/usr/share/doc/passwd
\`\`\`

**解释：**
此命令在整个文件系统中查找名为\`passwd\`的文件。

### 总结

\`find\`命令是一个功能强大且灵活的工具，适用于各种文件搜索任务。通过理解和使用不同的选项，用户可以精确定位到所需的文件并对其进行相应的操作。

        `,
    };
  },
});
</script>
<style scoped></style>
