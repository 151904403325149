<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ssh 命令详解

\`ssh\`（Secure Shell）是一种安全的远程登录协议，用于在不安全的网络上安全地访问远程计算机。它通过加密来保护数据传输，确保通信的安全性。\`ssh\` 通常用于远程登录、执行命令以及安全传输文件。

### 基本语法

\`\`\`bash
ssh [选项] [用户@]主机 [命令]
\`\`\`

- **用户**：可选，远程主机的用户名。
- **主机**：远程计算机的IP地址或域名。
- **命令**：可选，在远程主机上执行的命令。

### 主要功能

- 安全的远程登录。
- 远程命令执行。
- SSH 隧道和端口转发。
- 文件传输（结合 \`scp\` 和 \`sftp\`）。

### 常用选项

- \`-p PORT\`：指定连接的端口，默认为22。
- \`-i FILE\`：指定私钥文件进行身份验证。
- \`-v\`：详细模式，显示连接过程的调试信息。
- \`-X\`：启用 X11 转发，支持图形界面应用。
- \`-C\`：启用压缩，提高速度。

### 示例一：基本的SSH连接

连接到远程主机 \`azlinux-prod-cn\`，使用用户 \`sre\`：

\`\`\`bash
ssh sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
The authenticity of host 'azlinux-prod-cn (192.168.1.1)' can't be established.
ECDSA key fingerprint is SHA256:abc123...
Are you sure you want to continue connecting (yes/no)? 
\`\`\`

**解读**：首次连接时，会提示验证主机的指纹。输入 \`yes\` 继续连接。

### 示例二：指定端口连接

如果远程SSH服务运行在不同的端口（例如2222），可以使用 \`-p\` 选项：

\`\`\`bash
ssh -p 2222 sre@azlinux-prod-cn
\`\`\`

**输出：**

\`\`\`plaintext
Last login: Mon Sep 25 12:34:56 2024 from 192.168.1.100
sre@azlinux-prod-cn:~$ 
\`\`\`

**解读**：成功连接后，显示最后登录信息并返回命令行提示符。

### 示例三：在远程主机上执行命令

可以直接在 SSH 命令中指定要在远程主机上执行的命令。例如，查看远程主机的当前目录：

\`\`\`bash
ssh sre@azlinux-prod-cn "pwd"
\`\`\`

**输出：**

\`\`\`plaintext
/home/sre
\`\`\`

**解读**：远程执行命令并返回当前工作目录。

### 示例四：使用私钥文件进行身份验证

如果使用私钥文件进行身份验证，可以使用 \`-i\` 选项：

\`\`\`bash
ssh -i ~/.ssh/id_rsa sre@azlinux-prod-cn
\`\`\`

**输出**：

\`\`\`plaintext
Last login: Mon Sep 25 12:34:56 2024 from 192.168.1.100
sre@azlinux-prod-cn:~$ 
\`\`\`

**解读**：使用私钥成功连接到远程主机。

### 架构差异

在 aarch64 和 x86_64/amd64 架构下，\`ssh\` 命令的输出表现没有差异，因此无需列出不同架构的输出。

### 版本差异

不同版本的 \`ssh\` 命令可能在功能和错误处理上有所不同。可以通过以下命令查看当前版本：

\`\`\`bash
ssh -V
\`\`\`

**输出示例：**

\`\`\`plaintext
OpenSSH_8.4, OpenSSL 1.1.1j  16 Feb 2021
\`\`\`

### 安装方法

\`ssh\` 命令通常随 OpenSSH 客户端安装。如果未安装，可以通过以下方式确认：

- **Ubuntu/Debian**：

  \`\`\`bash
  dpkg -l | grep openssh-client
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  rpm -q openssh-clients
  \`\`\`

如果未安装，可以通过以下命令安装：

- **Ubuntu**：

  \`\`\`bash
  sudo apt install openssh-client
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  sudo yum install openssh-clients
  \`\`\`

### 权限

\`ssh\` 命令通常不需要特定的权限，但登录的用户需要在远程主机上有适当的访问权限。对于需要 root 权限的操作，可以使用 \`sudo\` 进行远程执行，例如：

\`\`\`bash
ssh sre@azlinux-prod-cn "sudo apt update"
\`\`\`

**输出：**

\`\`\`plaintext
[sudo] password for sre: 
Hit:1 http://archive.ubuntu.com/ubuntu focal InRelease
...
\`\`\`

**解读**：使用 \`sudo\` 提升权限后，执行了远程更新命令。

### 总结

\`ssh\` 命令是远程管理和操作的强大工具，通过本文的详细介绍，用户可以掌握如何使用 \`ssh\` 命令安全地连接和管理远程主机，从而提升工作效率和安全性。

        `,
    };
  },
});
</script>
<style scoped></style>
