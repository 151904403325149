<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dd 命令详解

\`dd\` 是一个用于低级别数据复制和转换的命令行工具。它可以用来从一个文件或设备复制数据到另一个文件或设备，也可以用于转换数据格式、创建磁盘映像等。\`dd\` 命令在系统管理和备份中非常有用，但也需要谨慎使用，因为它不会进行任何安全检查。

### 主要参数和选项

#### 1. \`if\`（输入文件）

指定输入文件或设备。例如，从 \`/dev/sda\` 读取数据。

\`\`\`bash
dd if=/dev/sda of=/dev/sdb bs=4M
\`\`\`

**示例输出**

\`\`\`plaintext
1024+0 records in
1024+0 records out
4294967296 bytes (4.3 GB) copied, 45.678 s, 94.2 MB/s
\`\`\`

**输出解读**:

- \`1024+0 records in\` 和 \`1024+0 records out\` 表示从输入文件读取和写入的记录数。
- \`4294967296 bytes copied\` 表示复制的数据总量。
- \`45.678 s\` 表示复制过程的总时间。
- \`94.2 MB/s\` 表示复制的速度。

#### 2. \`of\`（输出文件）

指定输出文件或设备。例如，将数据写入 \`/dev/sdb\`。

\`\`\`bash
dd if=/dev/sda of=/dev/sdb bs=4M
\`\`\`

**示例输出**

\`\`\`plaintext
1024+0 records in
1024+0 records out
4294967296 bytes (4.3 GB) copied, 45.678 s, 94.2 MB/s
\`\`\`

**输出解读**:

- 与输入文件的输出解释相同。

#### 3. \`bs\`（块大小）

指定读取和写入的块大小。例如，\`bs=4M\` 表示每次读取和写入 4MB 的数据。

\`\`\`bash
dd if=input_file of=output_file bs=4M
\`\`\`

**示例输出**

\`\`\`plaintext
256+0 records in
256+0 records out
268435456 bytes (268 MB) copied, 15.678 s, 17.1 MB/s
\`\`\`

**输出解读**:

- \`256+0 records in\` 和 \`256+0 records out\` 表示读取和写入的块数。
- \`268435456 bytes copied\` 表示复制的数据总量。
- \`15.678 s\` 表示复制过程的总时间。
- \`17.1 MB/s\` 表示复制的速度。

#### 4. \`count\`（块数）

指定要复制的块数。例如，\`count=10\` 表示只复制 10 个块。

\`\`\`bash
dd if=input_file of=output_file bs=1M count=10
\`\`\`

**示例输出**

\`\`\`plaintext
10+0 records in
10+0 records out
10485760 bytes (10 MB) copied, 2.345 s, 4.5 MB/s
\`\`\`

**输出解读**:

- \`10+0 records in\` 和 \`10+0 records out\` 表示读取和写入的块数。
- \`10485760 bytes copied\` 表示复制的数据总量。
- \`2.345 s\` 表示复制过程的总时间。
- \`4.5 MB/s\` 表示复制的速度。

#### 5. \`seek\`（跳过块数）

指定在输出文件中跳过的块数。例如，\`seek=2\` 表示在输出文件中跳过 2 个块的位置开始写入数据。

\`\`\`bash
dd if=input_file of=output_file bs=1M seek=2
\`\`\`

**示例输出**

\`\`\`plaintext
1024+0 records in
1024+0 records out
268435456 bytes (268 MB) copied, 30.567 s, 8.8 MB/s
\`\`\`

**输出解读**:

- \`seek=2\` 表示在输出文件中跳过了 2 个块的位置。
- 数据写入从第三个块开始。

### 内置命令与外部命令

\`dd\` 是一个外部命令，不是 Bash 内置命令。它通常位于 \`/bin/dd\` 路径下，用于进行低级别的数据操作。不同的 Unix-like 系统中，\`dd\` 的实现和功能大体相同，但某些选项或输出格式可能会有所不同。

### 不同架构下的输出

\`dd\` 命令的基本输出在不同架构下不会有显著差异，因为它主要依赖于系统的 I/O 操作。不过，具体的块大小和读写速度可能会受到系统架构和硬件性能的影响。

### 版本差异

\`dd\` 命令在不同版本的核心工具包中（如 GNU coreutils）可能会有所不同。不同版本可能会增加新功能或修复漏洞，但基础功能和参数通常保持一致。

### 替代命令

- \`cp\`：用于简单的文件复制，但不提供 \`dd\` 命令的所有低级功能。
- \`rsync\`：用于文件和目录的同步，可以提供更多功能，如增量复制和网络传输。

### 安装

\`dd\` 命令是 GNU coreutils 工具包的一部分，通常在大多数 Linux 系统中默认安装。如果系统中未安装，可以使用以下命令进行安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 需要 root 权限的选项

\`dd\` 命令本身不需要 root 权限，但在操作某些设备（如 \`/dev/sda\`）时可能需要管理员权限。

**示例**

\`\`\`bash
sudo dd if=/dev/sda of=/dev/sdb bs=4M
\`\`\`

**示例输出**:

\`\`\`plaintext
1024+0 records in
1024+0 records out
4294967296 bytes (4.3 GB) copied, 45.678 s, 94.2 MB/s
\`\`\`

### 总结

\`dd\` 命令是一个功能强大的工具，适用于各种低级别的数据操作任务。它可以用于复制数据、创建磁盘映像、转换数据格式等。通过使用不同的参数，可以精确控制数据的读写过程。由于其强大的功能，\`dd\` 命令也需要谨慎使用，以避免误操作带来的数据丢失风险。

        `,
    };
  },
});
</script>
<style scoped></style>
