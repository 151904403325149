<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## last 命令详解

\`last\` 命令用于显示系统登录记录。它读取 \`/var/log/wtmp\` 文件，该文件记录了用户的登录和注销信息，以及系统启动和关机记录。\`last\` 命令非常有用，特别是在需要审计或查看系统使用情况时。

### 基本用法

\`last\` 命令的基本语法如下：

\`\`\`bash
last [options] [username...]
\`\`\`

其中 \`username\` 是可选参数，用于过滤特定用户的登录记录。

#### 示例

\`\`\`bash
last
\`\`\`

输出结果：

\`\`\`plaintext
sre       pts/0        192.168.1.10    Sat Sep  7 14:01   still logged in
sre       pts/0        192.168.1.10    Sat Sep  7 13:47 - 14:00  (00:12)
reboot    system boot  5.4.0-42-generic Sat Sep  7 13:46   still running
\`\`\`

在这个示例中，\`last\` 命令显示了用户 \`sre\` 的登录记录，包括登录终端、IP 地址、登录时间、登出时间以及系统重启记录。

### 重要参数和功能

- **-a**: 显示登录时的主机名。

  示例：

  \`\`\`bash
  last -a
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       pts/0        192.168.1.10    azlinux-prod-cn Sat Sep  7 14:01   still logged in
  \`\`\`

  这个选项在显示登录记录时附加了主机名信息。

- **-n NUM**: 显示最近的 NUM 条记录。

  示例：

  \`\`\`bash
  last -n 5
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       pts/0        192.168.1.10    Sat Sep  7 14:01   still logged in
  sre       pts/0        192.168.1.10    Sat Sep  7 13:47 - 14:00  (00:12)
  reboot    system boot  5.4.0-42-generic Sat Sep  7 13:46   still running
  \`\`\`

  该选项限制了显示的记录数为最近的 5 条。

- **-x**: 显示系统启动和关机记录。

  示例：

  \`\`\`bash
  last -x
  \`\`\`

  输出结果：

  \`\`\`plaintext
  reboot    system boot  5.4.0-42-generic Sat Sep  7 13:46   still running
  runlevel (to init 5)  Sat Sep  7 13:46 - 13:46  (00:00)
  \`\`\`

  显示了系统启动和运行级别的记录。

- **-f FILE**: 指定要查看的日志文件（默认为 \`/var/log/wtmp\`）。

  示例：

  \`\`\`bash
  last -f /var/log/wtmp.1
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       pts/0        192.168.1.10    Fri Sep  6 14:01   still logged in
  \`\`\`

  这个选项允许从指定的日志文件中查看记录。

### 特殊用法示例

- **查看特定用户的登录记录**：

  \`\`\`bash
  last sre
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       pts/0        192.168.1.10    Sat Sep  7 14:01   still logged in
  sre       pts/0        192.168.1.10    Sat Sep  7 13:47 - 14:00  (00:12)
  \`\`\`

  显示用户 \`sre\` 的所有登录记录。

- **检查系统重启记录**：

  \`\`\`bash
  last reboot
  \`\`\`

  输出结果：

  \`\`\`plaintext
  reboot    system boot  5.4.0-42-generic Sat Sep  7 13:46   still running
  \`\`\`

  显示系统的重启记录。

### 不同架构下的输出

\`last\` 命令在 \`x86_64\` 和 \`aarch64\` 架构下的行为和输出基本相同。以下是一个典型的输出示例：

#### aarch64架构下

\`\`\`bash
last
\`\`\`

输出结果：

\`\`\`plaintext
sre       pts/0        192.168.1.10    Sat Sep  7 14:01   still logged in
sre       pts/0        192.168.1.10    Sat Sep  7 13:47 - 14:00  (00:12)
reboot    system boot  5.4.0-42-generic Sat Sep  7 13:46   still running
\`\`\`

与 \`x86_64\` 架构相同，该命令在 \`aarch64\` 架构下也正常显示登录记录。

### 不同版本的差异

\`last\` 命令在不同版本的 \`util-linux\` 包中可能有一些细微的差异，但基本功能和输出格式在现代 Linux 系统中是一致的。旧版本可能不支持某些选项，但核心功能仍然保留。

### 安装信息

- **Ubuntu/Debian**: \`last\` 命令是 \`util-linux\` 包的一部分，通常系统默认安装。

  安装命令：

  \`\`\`bash
  sudo apt-get install util-linux
  \`\`\`

- **RHEL/CentOS**: 同样，\`last\` 命令是 \`util-linux\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo yum install util-linux
  \`\`\`

### 使用root权限

\`last\` 命令通常不需要 root 权限即可查看登录记录。只有在查看某些受保护的日志文件时（如 \`/var/log/wtmp\`），可能需要适当的权限。

### 总结

\`last\` 命令是一个有用的工具，用于查看和分析系统的登录记录及重启历史。通过不同的选项，用户可以灵活地获取所需的信息，帮助进行系统审计和监控。

        `,
    };
  },
});
</script>
<style scoped></style>
