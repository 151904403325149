<template>
  <div class="diy-css">
    <AlarmBox :level="level" :title="title" :content="content" />

    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="5" :popularity="5" />
      <GameScoreDisplay score="30" />
      <SalaryMedal salaryType="2" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import AlarmBox from "../../base/AlarmBox.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdMd5sum",
  components: {
    AlarmBox,
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10065",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      level: "紧急告警",
      title: "磁盘分区/var/log/的利用率达到91%，请扩容或清理空间。",
      content:
        "开发总监已确认：/var/log/applogs/里的文件都可以删掉，用不到了。上次上线忘了关闭debug开关，导致文件超级大。目前日志在applogs2里。",
      markdownContent: `
\`df\` 命令用于显示文件系统的磁盘空间使用情况，常用的命令为\`df -hT\`。

### 1. \`-h\` （人阅读起来很直观的格式） 【重要】
- **功能**：以更友好的方式显示磁盘使用情况，使用MB、GB等单位代替字节。
- **示例**：\`df -h\`
- **用途**：快速查看磁盘的使用情况，易于阅读和理解。
  
### 2. \`-T\` （显示文件系统类型） 【重要】
- **功能**：显示文件系统的类型，如 ext4、xfs、tmpfs 等。
- **示例**：\`df -T\`
- **用途**：确定文件系统类型，排查特定文件系统相关的问题。

### 3. \`-i\` （显示 inode 信息） 【重要】
- **功能**：显示文件系统中的 inode 使用情况，而非块空间使用情况。
- **示例**：\`df -i\`
- **用途**：检查 inode 是否耗尽，inode 用尽时虽然磁盘空间未满，仍无法创建新文件。

### 4. \`--output\`（自定义输出格式） 【重要】,请练习该项，脚本中可能会用到。
- **功能**：允许用户选择显示的列，如文件系统、大小、使用空间、挂载点等。
- **示例**：\`df --output=source,fstype,size,used,avail,pcent,target\`
- **用途**：定制输出格式，便于将结果集成到自动化运维工具中。

### 其他可能用到的参数：
- **\`-l\`**：仅显示本地文件系统，排除网络文件系统。
- **\`-k\`**：以KB为单位显示（默认）。
- **\`--sync\`**：在获取数据前进行文件系统同步（适合精确查看实时情况）。

这些参数在日常运维中十分常用，有助于快速排查磁盘空间问题、确定文件系统类型以及生成脚本友好的输出结果。

`,
      markdownContentExpand: `
### 磁盘空间利用率告警阈值设置
#### 磁盘利用率达到80%的时候，开始报【重要告警】。
#### 磁盘利用率达到90%的时候，报【紧急告警】。

#### 删除服务器文件前，一定要确认该文件可以被删除，尤其是业务服务器的文件，要找到服务负责人进行确认，一般是微服务负责人，或者研发组长等。给出答复后，再删除。  
#### 删除的操作，运维同事也应当确认下操作。

在生产环境中删除文件时，需要非常谨慎，以避免对系统和应用造成不可逆转的损害。以下是一些注意事项：

### 1. 确认文件的重要性
- **备份**：在删除任何文件之前，确保已经备份了重要数据，以防止误删造成数据丢失。
- **文件用途**：了解文件的用途和影响，确保该文件不是系统或应用正常运行所必需的。

### 2. 使用合适的命令和参数
- **使用 \`rm -i\`**：在删除文件时，使用 \`-i\` 选项来进行交互式删除，系统会提示确认，降低误删风险。
- **不要使用 \`rm -rf /\`**：避免使用这种危险的命令，尤其是在没有明确目的和理解的情况下。

### 3. 检查文件和路径
- **确认路径**：确保在删除时输入的路径是正确的，避免误删其他文件或目录。
- **使用绝对路径**：尽量使用绝对路径来减少混淆。

### 4. 了解文件类型
- **符号链接**：确认删除的是实际文件还是符号链接，以防意外删除了其他重要文件的指向。
- **隐藏文件**：注意隐藏文件（以点 \`.\` 开头的文件），这些文件在某些情况下可能是配置文件，删除后可能影响系统或应用行为。

### 5. 检查依赖关系
- **应用依赖**：了解待删除文件是否被其他应用或服务依赖，删除后可能导致服务中断。
- **系统服务**：检查是否有系统服务正在使用该文件，必要时停止相关服务。

### 6. 使用审计和监控
- **审计日志**：在删除操作之前，查看相关的审计日志，以确保该文件的删除不会影响系统的安全性和稳定性。
- **监控**：确保有适当的监控工具来检测文件删除事件，以便及时响应潜在问题。

### 7. 计划停机维护
- **维护窗口**：如果文件删除会影响服务，考虑在维护窗口中进行操作，并提前通知相关用户。
- **回滚计划**：制定回滚计划，以便在删除后遇到问题时可以迅速恢复。

### 8. 测试删除操作
- **测试环境**：在生产环境中进行任何删除操作之前，先在测试环境中验证删除的影响和结果。
- **脚本审核**：如果使用脚本进行删除操作，确保经过严格审核，确认逻辑正确无误。

### 9. 记录和文档
- **记录操作**：将删除操作及其理由记录在案，便于日后参考和追踪。
- **更新文档**：如果删除了文件，确保相关文档（如配置文件、架构图）也进行相应更新。

通过遵循这些注意事项，可以显著降低在生产环境中删除文件所带来的风险，确保系统的稳定性和数据的安全性。

`,
      markdownInterview: `
### 选择题

1. **\`df\` 命令的主要功能是什么？**
   - A) 显示系统的内存使用情况
   - B) 显示文件系统的磁盘使用情况
   - C) 显示网络连接状态
   - D) 显示正在运行的进程

2. **使用 \`df -h\` 命令时，输出的单位是什么？**
   - A) 字节
   - B) 千字节
   - C) 人类可读格式（如 MB、GB）
   - D) 只显示占用的块数

3. **以下哪个选项可以用于显示文件系统类型？**
   - A) \`df -a\`
   - B) \`df -i\`
   - C) \`df -T\`
   - D) \`df -B\`

4. **如果你希望在输出中包括所有文件系统，包括虚拟文件系统，你应该使用哪个选项？**
   - A) \`df -h\`
   - B) \`df -a\`
   - C) \`df -P\`
   - D) \`df -t\`

5. **\`df --total\` 命令的用途是什么？**
   - A) 显示所有挂载点的详细信息
   - B) 显示所有文件系统的总使用情况
   - C) 显示磁盘空间的历史记录
   - D) 显示当前目录的使用情况

### 问答题

1. **请简要描述如何使用 \`df\` 命令来查看本地文件系统的磁盘使用情况，并解释输出的各个字段的含义。**

2. **在日常运维中，如何判断某个特定文件系统的 inode 是否耗尽？请给出相关命令。**

3. **在一个运行 \`df -i\` 的结果中，你发现某个文件系统的 inode 使用率达到 100%。这会导致什么后果？**

4. **如果你想在一个脚本中自动化检查某个特定文件系统的使用情况，如何使用 \`df\` 命令，并将结果输出到一个日志文件中？**

5. **如何在 \`df\` 命令中排除某一类型的文件系统的输出？请给出具体的命令示例。**

---

### 答案

**选择题答案：**
1. B) 显示文件系统的磁盘使用情况
2. C) 人类可读格式（如 MB、GB）
3. C) \`df -T\`
4. B) \`df -a\`
5. B) 显示所有文件系统的总使用情况

**问答题参考答案：**

1. **描述如何使用 \`df\` 命令查看本地文件系统的磁盘使用情况**：
   - 使用命令 \`df -h\` 可以查看本地文件系统的磁盘使用情况，输出的字段包括：
     - **Filesystem**: 文件系统名称
     - **Size**: 文件系统的总大小
     - **Used**: 已使用的空间
     - **Avail**: 可用空间
     - **Use%**: 已使用空间的百分比
     - **Mounted on**: 文件系统挂载点

2. **判断 inode 是否耗尽**：
   - 使用命令 \`df -i\` 来查看 inode 使用情况。输出会显示每个文件系统的 inode 总数、已用数量和可用数量。

3. **inode 使用率达到 100% 的后果**：
   - 当 inode 使用率达到 100% 时，系统无法再创建新文件，即使文件系统的磁盘空间尚未满，仍然无法进行文件操作。

4. **自动化检查特定文件系统的使用情况并输出到日志文件**：
   - 可以使用以下命令：
     \`\`\`bash
     df -h > /path/to/logfile.log
     \`\`\`
   - 这会将当前文件系统的磁盘使用情况输出到指定的日志文件中。

5. **排除某一类型的文件系统的输出**：
   - 使用命令 \`df -x tmpfs\` 来排除 tmpfs 类型的文件系统，输出将不包括该类型的文件系统信息。
`,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
