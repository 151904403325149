<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## nano 命令详解

\`nano\` 是一个易于使用的命令行文本编辑器，通常用于编辑文本文件。它在大多数 Linux 发行版中预装，可以让用户方便地进行文件编辑，适合初学者和普通用户使用。

### nano 是 bash 内置命令还是外部命令？

\`nano\` 是一个外部命令，通常位于 \`/usr/bin/nano\`。与一些其他文本编辑器相比，它的操作简单直观，适合于不熟悉命令行的用户。

### 基本语法

\`nano\` 命令的基本语法如下：

\`\`\`bash
nano [选项] [文件名]
\`\`\`

### 重要参数及示例

#### 1. 打开文件

要使用 \`nano\` 打开一个文件，可以直接在命令后指定文件名。如果文件不存在，\`nano\` 会创建一个新文件：

\`\`\`bash
nano myfile.txt
# 输出：
# 无输出，命令已成功执行
\`\`\`

此时，用户将进入 \`nano\` 编辑器界面，用户可以直接在界面中进行编辑。

#### 2. 创建新文件

如果指定的文件名不存在，\`nano\` 会创建一个新的文件。例如：

\`\`\`bash
nano newfile.txt
# 输出：
# 无输出，命令已成功执行
\`\`\`

用户可以在 \`nano\` 中输入内容，保存后即为新创建的文件。

#### 3. 保存文件

在编辑完成后，用户可以使用 \`Ctrl + O\` 来保存文件。系统会提示输入文件名，直接按 Enter 即可保存当前文件。

\`\`\`bash
# 使用 Ctrl + O 保存文件后
# 输出：
# 无输出，命令已成功执行
\`\`\`

#### 4. 退出 nano

要退出 \`nano\`，可以使用 \`Ctrl + X\`。如果未保存更改，\`nano\` 会询问是否保存。

\`\`\`bash
# 使用 Ctrl + X 退出后
# 输出：
# 无输出，命令已成功执行
\`\`\`

#### 5. 查找文本

在 \`nano\` 中，用户可以使用 \`Ctrl + W\` 查找特定文本。输入要查找的内容后，按 Enter 进行搜索。

\`\`\`bash
# 使用 Ctrl + W 查找文本后
# 输出：
# 无输出，命令已成功执行
\`\`\`

#### 6. 显示帮助

\`nano\` 提供内置的帮助文档，用户可以通过 \`Ctrl + G\` 查看帮助信息。

\`\`\`bash
# 使用 Ctrl + G 查看帮助后
# 输出：
# 无输出，命令已成功执行
\`\`\`

### 检查安装状态

用户可以使用 \`which\` 命令检查 \`nano\` 是否已安装：

\`\`\`bash
which nano
# 输出示例：
# /usr/bin/nano
\`\`\`

输出表明 \`nano\` 已安装并位于 \`/usr/bin\` 目录。

### 不同架构下的执行结果

\`nano\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

不同版本的 \`nano\` 可能会在功能和选项上有所不同。可以使用以下命令检查版本：

\`\`\`bash
nano --version
# 输出示例：
# GNU nano version 5.4
\`\`\`

版本信息可能会随着系统的不同而有所不同。

### 安装

在大多数 Linux 发行版中，\`nano\` 通常与 \`nano\` 包一起提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install nano
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install nano
  \`\`\`

### 使用root权限的场景

通常情况下，使用 \`nano\` 编辑用户文件不需要 root 权限，但在某些情况下，例如编辑系统配置文件时，可能需要使用 root 权限。

\`\`\`bash
sudo nano /etc/hosts
# 输出：
# 无输出，命令已成功执行
\`\`\`

通过 \`sudo\` 提高权限，确保命令能成功执行。

### 总结

\`nano\` 是一个用户友好的命令行文本编辑器，适合用于快速编辑文件和简单的文本处理。熟悉 \`nano\` 的基本用法，可以帮助用户在 Linux 环境中高效地进行文本编辑。通过合理利用其功能，用户可以轻松地管理和修改文本文件。

        `,
    };
  },
});
</script>
<style scoped></style>
