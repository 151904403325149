<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lsusb 命令详解

\`lsusb\` 是 Linux 系统中用于列出所有 USB 设备的命令。它可以显示系统上已连接的 USB 设备及其相关信息，例如厂商、设备 ID 等。\`lsusb\` 是一个外部命令，并不属于 Bash 内置命令，它主要依赖于 \`usbutils\` 软件包，用户可根据系统需求来安装。

在日常运维工作中，\`lsusb\` 主要用于调试和检测 USB 设备，尤其是在排查 USB 设备连接问题时非常有用。本文将详细介绍 \`lsusb\` 的常用参数、使用方法，并结合输出示例来解释。

### 安装 lsusb

通常，\`lsusb\` 在大多数 Linux 发行版中默认安装。如果系统没有安装，可以使用以下命令进行安装：

- **Ubuntu**:
    \`\`\`bash
    sudo apt-get install usbutils
    \`\`\`

- **RHEL/CentOS**:
    \`\`\`bash
    sudo yum install usbutils
    \`\`\`

### 基本用法

\`lsusb\` 的基本用法非常简单，只需要在终端中直接输入 \`lsusb\` 命令即可。

\`\`\`bash
lsusb
\`\`\`

**输出示例**：
\`\`\`bash
Bus 002 Device 003: ID 8087:0024 Intel Corp. Integrated Rate Matching Hub
Bus 002 Device 002: ID 8087:0024 Intel Corp. Integrated Rate Matching Hub
Bus 002 Device 001: ID 1d6b:0002 Linux Foundation 2.0 root hub
Bus 001 Device 003: ID 046d:c52b Logitech, Inc. Unifying Receiver
Bus 001 Device 002: ID 04f2:b2eb Chicony Electronics Co., Ltd Integrated Camera
Bus 001 Device 001: ID 1d6b:0002 Linux Foundation 2.0 root hub
\`\`\`

**解释**：
- \`Bus 002 Device 003\`: 表示设备位于 002 号总线上，设备号为 003。
- \`ID 8087:0024\`: USB 设备的厂商 ID 和设备 ID。
- \`Intel Corp.\`: 设备的制造商。
- 其他信息如 \`Unifying Receiver\` 和 \`Integrated Camera\` 显示设备类型。

### 常用参数

#### 1. -v：显示详细信息

使用 \`-v\` 参数可以查看更加详细的 USB 设备信息。

\`\`\`bash
lsusb -v
\`\`\`

**输出示例**（部分）：
\`\`\`bash
Bus 002 Device 003: ID 8087:0024 Intel Corp. Integrated Rate Matching Hub
Device Descriptor:
  bLength                18
  bDescriptorType         1
  bcdUSB               2.00
  bDeviceClass            9 Hub
  idVendor           0x8087 Intel Corp.
  idProduct          0x0024 Integrated Rate Matching Hub
  bcdDevice            0.00
  iManufacturer           0
  iProduct                0
  iSerial                 0
\`\`\`

**解释**：
- \`bDeviceClass\` 和 \`bcdUSB\` 显示设备类型和支持的 USB 版本。
- \`idVendor\` 和 \`idProduct\` 是设备的厂商 ID 和产品 ID。
- 其他信息如 \`iManufacturer\`、\`iProduct\` 和 \`iSerial\` 可以提供更多细节，如厂商和产品名称。

#### 2. -s：指定总线和设备编号

如果只想查看特定的 USB 设备信息，可以使用 \`-s\` 参数来指定设备的总线和设备编号。

\`\`\`bash
lsusb -s 001:003
\`\`\`

**输出示例**：
\`\`\`bash
Bus 001 Device 003: ID 046d:c52b Logitech, Inc. Unifying Receiver
\`\`\`

**解释**：
该命令仅列出了 001 号总线上的 003 号设备信息。

#### 3. -t：以树形结构显示设备

使用 \`-t\` 参数可以以树形结构显示设备连接关系，非常适合查看设备层级。

\`\`\`bash
lsusb -t
\`\`\`

**输出示例**：
\`\`\`bash
/:  Bus 02.Port 1: Dev 1, Class=root_hub, Driver=xhci_hcd/4p, 5000M
    |__ Port 3: Dev 2, If 0, Class=Hub, Driver=hub/4p, 5000M
        |__ Port 1: Dev 3, If 0, Class=Vendor Specific Class, Driver=usbfs, 480M
/:  Bus 01.Port 1: Dev 1, Class=root_hub, Driver=xhci_hcd/4p, 480M
    |__ Port 2: Dev 2, If 0, Class=Hub, Driver=hub/4p, 480M
\`\`\`

**解释**：
- \`Bus 02.Port 1: Dev 1\` 显示设备的总线、端口、设备号等信息。
- \`Class=root_hub\` 表示根集线器，\`Driver=xhci_hcd\` 表示设备驱动。
- \`5000M\` 和 \`480M\` 分别表示设备运行的速度。

#### 4. -d：按厂商和设备 ID 过滤

使用 \`-d\` 参数可以根据厂商 ID 和设备 ID 来过滤设备。

\`\`\`bash
lsusb -d 046d:c52b
\`\`\`

**输出示例**：
\`\`\`bash
Bus 001 Device 003: ID 046d:c52b Logitech, Inc. Unifying Receiver
\`\`\`

**解释**：
此命令仅显示厂商 ID 为 \`046d\`，设备 ID 为 \`c52b\` 的设备信息。

#### 5. -D：从设备文件获取信息

你也可以从特定的设备文件中获取设备信息，使用 \`-D\` 参数即可。

\`\`\`bash
sudo lsusb -D /dev/bus/usb/001/003
\`\`\`

**输出示例**：
\`\`\`bash
Device: ID 046d:c52b Logitech, Inc. Unifying Receiver
Device Descriptor:
  bLength                18
  bDescriptorType         1
  bcdUSB               2.00
  bDeviceClass            0 (Defined at Interface level)
  idVendor           0x046d Logitech, Inc.
  idProduct          0xc52b Unifying Receiver
\`\`\`

**解释**：
该命令直接从 \`/dev/bus/usb/001/003\` 设备文件中读取设备信息。

### 没有输出时的处理

如果某些情况下 \`lsusb\` 没有输出（例如没有找到 USB 设备），可以使用以下命令检查是否加载了正确的 USB 相关内核模块。

\`\`\`bash
lsmod | grep usb
\`\`\`

**输出示例**：
\`\`\`bash
usb_storage            73728  1
usbhid                 53248  0
xhci_hcd              188416  1 xhci_pci
\`\`\`

**解释**：
- \`usb_storage\` 模块负责处理 USB 存储设备。
- \`usbhid\` 模块用于处理 USB 键盘和鼠标等设备。
- \`xhci_hcd\` 是 USB 3.0 控制器的驱动模块。

### lsusb 与不同架构的差异

\`lsusb\` 在不同架构的系统上表现基本一致，无论是 \`x86_64/amd64\` 还是 \`aarch64\` 架构，输出和功能上几乎没有区别。因此，这部分无需特殊说明。

### 旧版本与新版本的差异

在一些旧版本的 \`lsusb\` 工具中，可能对较新型号的 USB 设备支持不全，显示的设备名称可能是未识别的 \`Unknown\` 设备。较新版本的 \`lsusb\` 工具通常能更好地识别和显示新设备的详细信息。因此，建议确保系统中 \`usbutils\` 软件包是最新版本。

### root 权限需求

在某些系统中，查看设备详细信息（如使用 \`-v\` 参数）可能需要 \`root\` 权限。在这些情况下，可以使用 \`sudo\` 命令来提升权限：

\`\`\`bash
sudo lsusb -v
\`\`\`

### 总结

\`lsusb\` 是一个简单但非常实用的工具，可以帮助用户快速了解系统中已连接的 USB 设备信息。无论是基础的设备列表，还是详细的设备描述，\`lsusb\` 都可以提供有用的信息。通过结合使用常用参数，系统管理员可以方便地调试 USB 设备连接问题，检查设备状态，并排查硬件相关的故障。

        `,
    };
  },
});
</script>
<style scoped></style>
