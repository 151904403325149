<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## nl 命令详解

\`nl\` 命令用于给文件或标准输入的每一行加上行号。这在处理长文本文件或脚本时非常有用，可以帮助快速定位某一行。

### 基本用法

\`nl\` 命令的基本语法如下：

\`\`\`bash
nl [选项] [文件...]
\`\`\`

如果没有指定文件，\`nl\` 会读取标准输入。

### 主要参数

- \`-b, --body-numbering=TYPE\`  
  指定如何给正文部分编号。\`TYPE\` 可以是：
  - \`a\`：每行编号（默认）
  - \`t\`：只有当存在空行时才编号
  - \`n\`：不编号

- \`-f, --footer-numbering=TYPE\`  
  指定如何给页脚部分编号。\`TYPE\` 选项与 \`-b\` 参数相同。

- \`-h, --header-numbering=TYPE\`  
  指定如何给页眉部分编号。\`TYPE\` 选项与 \`-b\` 参数相同。

- \`-i, --join-chars=STRING\`  
  在行号和内容之间插入自定义分隔符。

- \`-l, --line-numbering=TYPE\`  
  指定如何编号行。\`TYPE\` 可以是：
  - \`n\`：为所有行编号（默认）
  - \`p\`：只有当存在空行时才编号

- \`-n, --numbering=TYPE\`  
  指定如何给行编号。\`TYPE\` 可以是：
  - \`n\`：为所有行编号（默认）
  - \`l\`：只为非空行编号
  - \`a\`：为所有行编号，包括空行

- \`-w, --number-width=NUMBER\`  
  指定行号的宽度。\`NUMBER\` 是行号的最大宽度，默认为6。

- \`-v, --no-headers\`  
  不显示页眉。

### 示例

以下是 \`nl\` 命令的一些示例用法：

1. **为文件添加行号**

    \`\`\`bash
    nl example.txt
    \`\`\`

    **输出示例：**

    \`\`\`text
         1  This is the first line.
         2  This is the second line.
         3  This is the third line.
    \`\`\`

2. **使用自定义分隔符**

    \`\`\`bash
    nl -i " | " example.txt
    \`\`\`

    **输出示例：**

    \`\`\`text
         1 | This is the first line.
         2 | This is the second line.
         3 | This is the third line.
    \`\`\`

3. **只为非空行编号**

    \`\`\`bash
    nl -l n example.txt
    \`\`\`

    **输出示例：**

    \`\`\`text
         1  This is the first line.
         2  This is the second line.
         3  This is the third line.
    \`\`\`

4. **显示页脚部分编号**

    \`\`\`bash
    nl -f a example.txt
    \`\`\`

    **输出示例：**

    \`\`\`text
    Page 1
         1  This is the first line.
         2  This is the second line.
         3  This is the third line.
    \`\`\`

### 版本差异

不同版本的 \`nl\` 命令可能会有些许差异，例如，早期版本可能不支持 \`-i\` 和 \`-n\` 选项，或者这些选项的语法有所不同。建议使用最新版本的 \`nl\` 命令以获取最新的功能和修复的错误。

### 安全性与替代

\`nl\` 命令本身没有安全问题，也没有被弃用。如果需要更多的文本处理功能，可以考虑使用 \`awk\` 或 \`sed\` 作为替代。

### 安装

- **Ubuntu：**

    \`nl\` 命令通常包含在 \`bsdmainutils\` 包中，可以通过以下命令安装：

    \`\`\`bash
    sudo apt-get install bsdmainutils
    \`\`\`

- **RHEL：**

    \`nl\` 命令通常包含在 \`util-linux\` 包中，可以通过以下命令安装：

    \`\`\`bash
    sudo yum install util-linux
    \`\`\`

### 权限要求

\`nl\` 命令通常不需要 root 权限。如果处理的是系统文件或其他用户的文件，可能需要相应的读权限。

### 结论

\`nl\` 命令是一个简单而强大的工具，适用于需要给文本行加上编号的场景。通过掌握其主要参数和选项，可以灵活地处理各种文本文件。

        `,
    };
  },
});
</script>
<style scoped></style>
