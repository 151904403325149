<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sha256sum 命令详解

\`sha256sum\` 命令是用于计算并验证文件的 SHA-256 哈希值的工具。SHA-256 是一种加密哈希函数，它生成一个 256 位（32 字节）的哈希值，通常用于文件完整性验证和数字签名。

### 基本语法

\`\`\`bash
sha256sum [选项] [文件...]
\`\`\`

### 主要功能

- 计算指定文件的 SHA-256 哈希值。
- 可以验证文件的完整性，确保文件未被篡改。
- 支持从标准输入计算哈希值。

### 常用选项

- \`-b\`：以二进制模式读取文件。
- \`-c\`：检查哈希值。
- \`-h\`：显示帮助信息。
- \`--tag\`：输出哈希值时添加文件名。

### 示例一：计算文件的 SHA-256 哈希值

假设有一个名为 \`example.txt\` 的文件，我们可以使用 \`sha256sum\` 来计算它的哈希值。

\`\`\`bash
echo "Hello, World!" > example.txt
sha256sum example.txt
\`\`\`

**输出：**

\`\`\`plaintext
a591a6d40bf420404a011733cfb7b190d62c65bf0bcda190e3b2c2b0d7f7b0f  example.txt
\`\`\`

**解读**：输出的第一个字段是文件 \`example.txt\` 的 SHA-256 哈希值，第二个字段是文件名。这个哈希值可以用来验证文件内容的完整性。

### 示例二：验证哈希值

可以使用 \`-c\` 选项来验证文件的哈希值。首先，生成一个哈希值并将其写入 \`example.txt.sha256\` 文件。

\`\`\`bash
sha256sum example.txt > example.txt.sha256
\`\`\`

接下来，使用以下命令来验证：

\`\`\`bash
sha256sum -c example.txt.sha256
\`\`\`

**输出：**

\`\`\`plaintext
example.txt: OK
\`\`\`

**解读**：输出表明 \`example.txt\` 文件的哈希值与 \`example.txt.sha256\` 文件中的哈希值匹配，文件未被修改。

### 示例三：从标准输入计算哈希值

可以使用 \`sha256sum\` 从标准输入计算哈希值。例如：

\`\`\`bash
echo "Hello, World!" | sha256sum
\`\`\`

**输出：**

\`\`\`plaintext
a591a6d40bf420404a011733cfb7b190d62c65bf0bcda190e3b2c2b0d7f7b0f  -
\`\`\`

**解读**：通过标准输入传入的内容的哈希值与之前示例中的相同。输出的 \`-\` 表示输入来源于标准输入。

### 架构差异

\`sha256sum\` 命令在 aarch64 和 x86_64/amd64 架构下的输出表现没有差异，因此无需列出不同架构的输出。

### 版本差异

不同版本的 \`coreutils\` 可能导致 \`sha256sum\` 命令的选项或输出略有不同。一般来说，较新版本提供更好的错误消息和支持更多功能。建议查看手册页 (\`man sha256sum\`) 获取具体版本的信息。

### 安装方法

在大多数 Linux 发行版中，\`sha256sum\` 命令是 \`coreutils\` 工具包的一部分。可以通过以下方式确认是否已安装：

- **Ubuntu/Debian**：

  \`\`\`bash
  dpkg -l | grep coreutils
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  rpm -q coreutils
  \`\`\`

如果未安装，可以通过以下命令安装：

- **Ubuntu**：

  \`\`\`bash
  sudo apt install coreutils
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 权限

\`sha256sum\` 命令通常不需要特定的权限，任何用户都可以使用它来计算和验证文件的哈希值。

### 总结

\`sha256sum\` 命令是一个非常实用的工具，用于计算和验证文件的 SHA-256 哈希值。通过本文的详细介绍，用户可以掌握如何使用 \`sha256sum\` 命令来确保文件的完整性和安全性。

        `,
    };
  },
});
</script>
<style scoped></style>
