<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## wc 命令详解

\`wc\`（word count）命令用于统计文件中的行数、字数和字符数。它是一个非常有用的工具，特别是在处理文本文件时，可以快速获取文件的基本统计信息。

### 基本用法

\`wc\` 命令的基本用法如下：

\`\`\`bash
wc [选项] [文件]
\`\`\`

例如，要统计文件 \`example.txt\` 的行数、字数和字符数，可以使用以下命令：

\`\`\`bash
wc example.txt
\`\`\`

### 常用选项

- \`-l\`：仅显示行数。
- \`-w\`：仅显示字数。
- \`-c\`：仅显示字符数。
- \`-m\`：显示字符数（与 \`-c\` 不同，\`-c\` 统计字节数，\`-m\` 统计字符数）。
- \`-L\`：显示最长行的长度。

### 示例

假设我们有一个名为 \`example.txt\` 的文件，内容如下：

\`\`\`text
This is a test file.
It contains some example text.
\`\`\`

我们可以使用 \`wc\` 命令来获取文件的统计信息。

#### 统计所有信息

\`\`\`bash
wc example.txt
\`\`\`

#### 输出示例

\`\`\`text
  2  10  51 example.txt
\`\`\`

输出说明：
- \`2\`：行数
- \`10\`：字数
- \`51\`：字符数

#### 仅显示行数

\`\`\`bash
wc -l example.txt
\`\`\`

#### 输出示例

\`\`\`text
2 example.txt
\`\`\`

#### 仅显示字数

\`\`\`bash
wc -w example.txt
\`\`\`

#### 输出示例

\`\`\`text
10 example.txt
\`\`\`

#### 仅显示字符数

\`\`\`bash
wc -c example.txt
\`\`\`

#### 输出示例

\`\`\`text
51 example.txt
\`\`\`

#### 显示最长行的长度

\`\`\`bash
wc -L example.txt
\`\`\`

#### 输出示例

\`\`\`text
30
\`\`\`

### 详细解读

- **行数（-l）**：表示文件中包含的行数。对于空行，也会被计入。
- **字数（-w）**：表示文件中包含的单词数。默认情况下，单词是以空白字符（空格、制表符、换行符等）分隔的。
- **字符数（-c）**：表示文件中包含的字节数。在使用 UTF-8 编码的文件中，字符数可能与字节数不同。
- **字符数（-m）**：表示文件中的字符数，在多字节字符集下，这可能与字节数不同。
- **最长行的长度（-L）**：显示文件中最长行的字符数。

### 安装

\`wc\` 命令通常包含在 GNU coreutils 包中，这是大多数 Linux 发行版的标准组件。因此，一般情况下，你无需单独安装它。

- **Ubuntu**

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL/CentOS**

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 不同架构的输出

\`wc\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的输出行为是一致的。它的工作原理和输出结果在不同架构的机器上没有差异。

### 版本差异

\`wc\` 命令的基本功能在不同版本之间没有显著变化。可能会有微小的改进和修复，但其核心功能保持一致。

### 替代命令

\`wc\` 命令是处理文本文件统计的标准工具，通常没有直接的替代命令。如果需要处理其他格式的文件，可能需要使用其他专用工具。

### 权限要求

\`wc\` 命令本身不需要 root 权限，但访问或修改某些文件可能需要相应的权限。如果遇到权限问题，确保你有访问目标文件的权限。

        `,
    };
  },
});
</script>
<style scoped></style>
