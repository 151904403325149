<template>
  <div class="alert-box">
    <el-divider content-position="center">告警消息区域</el-divider>

    <!-- 替换后的 div 实现 -->
    <div class="alert-box-title">
      <p style="padding: 0; margin: 0">{{ level }}</p>
    </div>

    <div class="details-error">
      <p class="details-title">{{ title }}</p>
      <p>{{ content }}</p>
      <span
        >可能的磁盘分区挂载点:
        /var/log（不一定准确，请你们运维大佬检查确认）</span
      >
      <el-progress
        :text-inside="true"
        :stroke-width="22"
        :percentage="91"
        status="exception"
        class="progress-bar"
      />
    </div>

    <el-divider />
  </div>
</template>

<script>
export default {
  props: ["level", "title", "content"],
};
</script>

<style scoped>
.alert-box {
  margin: 1rem;
  text-align: left;
}

.alert-box-title {
  width: 6rem;
  background-color: #f56c6c;
  color: white;
  padding: 0.4rem 0;
  text-align: center;
}

.details-error {
  background-color: #fab8b8;
  padding: 0.4rem;
  font-size: 14px;
}

.details-title {
  font-weight: 600;
  font-size: 16px;
}

.progress-bar {
  max-width: 340px;
  margin-top: 0.4rem;
}
</style>
