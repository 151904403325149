<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## zmore 命令详解

\`zmore\` 是一个用于查看压缩文件内容的命令行工具，功能类似于 \`more\` 命令，但能够直接处理 \`.gz\` 格式的压缩文件。它使用户可以逐页浏览压缩文件的内容，而不需要先解压缩文件。这对于处理大型日志文件或文本文件非常有用。

### 安装

在使用 \`zmore\` 之前，确保系统中安装了 \`gzip\` 包。它通常在大多数 Linux 发行版中默认安装。如果需要安装，可以使用以下命令：

#### Ubuntu

\`\`\`bash
sudo apt install gzip
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install gzip
\`\`\`

### 基本语法

\`\`\`bash
zmore [选项] [压缩文件]
\`\`\`

- **选项**：用于指定所需的操作。
- **压缩文件**：指定要查看的 \`.gz\` 文件。

### 常用选项

- \`-f\`：强制查看即使文件不是 \`.gz\` 格式。
- \`-s\`：在每个页面的结尾时显示压缩信息。

### 示例用法

#### 示例一：查看压缩文件内容

可以使用以下命令查看压缩文件的内容：

\`\`\`bash
zmore example.log.gz
\`\`\`

**输出**：

\`\`\`plaintext
<内容将显示在 zmore 界面中，用户可以使用空格键翻页，使用 \`q\` 退出>
\`\`\`

**解读**：在这里，输出将直接在 \`zmore\` 的界面中显示。用户可以使用空格键向下翻页，使用 \`q\` 退出。

#### 示例二：强制查看文件

如果希望强制查看非压缩文件，可以使用 \`-f\` 选项：

\`\`\`bash
zmore -f example.txt
\`\`\`

**输出**：

\`\`\`plaintext
<内容将显示在 zmore 界面中，用户可以使用空格键翻页，使用 \`q\` 退出>
\`\`\`

**解读**：使用 \`-f\` 选项后，即使文件不是 \`.gz\` 格式，\`zmore\` 也会尝试打开它。

### 不同架构的输出

\`zmore\` 在不同架构（如 aarch64 和 x86_64/amd64）上通常表现一致，因此不需要额外的输出比较。

### 版本差异

\`zmore\` 的不同版本可能会在选项和功能上有差异。用户可以通过以下命令查看当前版本：

\`\`\`bash
zmore --version
\`\`\`

**输出示例**：

\`\`\`plaintext
zmore 5.4.0
\`\`\`

不同版本的 \`zmore\` 可能增加了新选项或修复了已知问题。

### 权限要求

\`zmore\` 命令不需要特定的权限，任何用户都可以查看自己的压缩文件。如果需要查看其他用户的文件，可能需要使用 \`sudo\` 提升权限。例如：

\`\`\`bash
sudo zmore /var/log/syslog.gz
\`\`\`

#### 示例输出

\`\`\`plaintext
<内容将显示在 zmore 界面中，用户可以使用空格键翻页，使用 \`q\` 退出>
\`\`\`

### 总结

\`zmore\` 是一个非常实用的工具，用于方便地查看压缩文件的内容。通过灵活的选项设置，用户可以根据需要浏览文件，快速查找所需的信息。了解并熟练使用 \`zmore\` 可以提高工作效率，尤其是在处理大文件和日志时。

        `,
    };
  },
});
</script>
<style scoped></style>
