<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## readelf 命令详解

\`readelf\` 命令是一个用于显示 ELF（Executable and Linkable Format）文件信息的工具。ELF 文件格式是 Linux 和 UNIX 系统中常用的可执行文件格式，\`readelf\` 命令可以帮助开发者和系统管理员检查 ELF 文件的各个部分，包括头信息、段信息、符号表等。

### 命令格式

\`\`\`bash
readelf [选项] 文件名
\`\`\`

### 主要参数

- \`-h\`：显示 ELF 文件头信息。
- \`-S\`：显示节头表（Section Header Table）。
- \`-l\`：显示程序头表（Program Header Table）。
- \`-d\`：显示动态段（Dynamic Section）信息。
- \`-s\`：显示符号表（Symbol Table）。
- \`-r\`：显示重定位表（Relocation Table）。
- \`-a\`：显示所有信息，相当于同时使用 \`-h\`, \`-S\`, \`-l\`, \`-d\`, \`-s\`, \`-r\` 等选项。
- \`-x\`：显示指定节的内容。
- \`--segments\`：显示 ELF 文件中的段信息。
- \`--sections\`：显示 ELF 文件中的节信息。

### 使用示例

1. **显示 ELF 文件头信息**

   \`\`\`bash
   readelf -h /bin/ls
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ readelf -h /bin/ls
   ELF Header:
     Magic:   7f 45 4c 46 01 01 01 00 00 00 00 00 00 00 00 00
     Class:                             ELF32
     Data:                              2's complement, little endian
     Version:                           1 (current)
     OS/ABI:                            UNIX - System V
     ABI Version:                       0
     Type:                              EXEC (Executable file)
     Machine:                           Intel 80386
     Version:                           0x1
     Entry point address:               0x80483b0
     Start of program headers:          52 (bytes into file)
     Start of section headers:          41068 (bytes into file)
     Flags:                             0x0
     Size of this header:               52 (bytes)
     Size of program headers:           32 (bytes)
     Number of program headers:         9
     Size of section headers:           40 (bytes)
     Number of section headers:         29
     Section header string table index: 28
   \`\`\`

   **解读**：
   - \`-h\` 选项显示了 ELF 文件的头信息，包括文件的魔数、类、数据格式、入口地址等基本信息。

2. **显示节头表**

   \`\`\`bash
   readelf -S /bin/ls
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ readelf -S /bin/ls
   There are 29 section headers, starting at offset 0x9fc8:
   
   Section Headers:
     [Nr] Name              Type             Address           Offset
         Size              EntSize          Flags  Link  Info  Align
     [ 0] .interp           PROGBITS         0x00000000  0x000034  0x000000  0x000000  0x000000  0x000000
     [ 1] .note.ABI-tag     NOTE             0x00000000  0x000040  0x000020  0x000000  0x000000  0x000000
     ...
   \`\`\`

   **解读**：
   - \`-S\` 选项显示了 ELF 文件的节头表，提供了节的名称、类型、地址、偏移量等详细信息。

3. **显示符号表**

   \`\`\`bash
   readelf -s /bin/ls
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ readelf -s /bin/ls
   Symbol table '.symtab' contains 204 entries:
     Num:    Value          Size Type    Bind   Vis      Ndx Name
     0: 00000000     0 NOTYPE  LOCAL  DEFAULT  ABS 
     1: 00000000     0 SECTION LOCAL  DEFAULT    1 
     ...
   \`\`\`

   **解读**：
   - \`-s\` 选项显示了符号表，列出了 ELF 文件中的所有符号及其相关信息，如名称、类型、绑定属性等。

4. **显示动态段信息**

   \`\`\`bash
   readelf -d /bin/ls
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ readelf -d /bin/ls
   Dynamic section at offset 0x1d8e0 contains 25 entries:
     Tag        Type                         Name/Value
     DT_NEEDED  NEEDED                       [libc.so.6]
     DT_INIT    INIT                         0x8048340
     ...
   \`\`\`

   **解读**：
   - \`-d\` 选项显示动态段的内容，包括需要的共享库、初始化函数等信息。

### 不同架构下的输出

\`readelf\` 命令的输出在不同架构的机器上通常是相似的，因为 ELF 格式是标准化的。但由于不同系统的文件可能有不同的实现细节，输出可能会有所不同。

### 版本差异

\`readelf\` 命令的输出可能会因版本不同而有所差异。可以通过运行 \`readelf --version\` 来检查当前版本，并根据具体版本的文档来确认输出的差异。

### 安全性及替代命令

\`readelf\` 命令是用于读取 ELF 文件信息的标准工具，没有被弃用。与之类似的工具包括 \`objdump\` 和 \`elfutils\`，它们也可以用来分析 ELF 文件，但 \`readelf\` 提供了更为详细的信息。

### 安装

\`readelf\` 命令是 GNU Binutils 工具集的一部分，通常随系统预装。若需安装或更新，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install binutils
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install binutils
  \`\`\`

### 需要root权限的选项

\`readelf\` 命令本身不需要 root 权限来运行，但访问受限的文件或目录时可能需要。

\`\`\`bash
sudo readelf -h /usr/bin/somefile
\`\`\`

**示例输出**：

\`\`\`bash
$ sudo readelf -h /usr/bin/somefile
ELF Header:
  Magic:   7f 45 4c 46 01 01 01 00 00 00 00 00 00 00 00 00
  Class:                             ELF64
  Data:                              2's complement, little endian
  ...
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以访问受限文件的信息。
        `,
    };
  },
});
</script>
<style scoped></style>
