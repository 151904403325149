<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## tail 命令详解

\`tail\` 是一个用于查看文件内容的命令行工具，它可以显示文件的最后几行，通常用于查看日志文件的最新条目。该命令非常有用，特别是在处理动态更新的日志文件时。

### 基本语法

\`\`\`bash
tail [选项] [文件]
\`\`\`

### 重要参数

- \`-n <行数>\`：指定要显示的行数。例如，\`tail -n 10 filename\` 显示文件 \`filename\` 的最后 10 行。
- \`-f\`：跟踪文件的最新内容。当文件内容实时更新时，\`tail\` 将持续显示新增的内容。适用于查看实时日志。
- \`-c <字节数>\`：以字节为单位显示文件的最后部分。例如，\`tail -c 100 filename\` 显示文件 \`filename\` 的最后 100 个字节。
- \`-q\`：不显示文件名。用于在多个文件时只显示文件内容，不显示文件头部。
- \`-v\`：显示文件名（如果 \`-q\` 未使用）。在处理多个文件时非常有用。

### 示例用法

#### 示例 1：显示最后 10 行

\`\`\`bash
tail -n 10 /var/log/syslog
\`\`\`

**输出：**

\`\`\`
Sep 10 15:00:00 azlinux-prod-cn systemd[1]: Starting Daily apt upgrade and clean activities...
Sep 10 15:00:01 azlinux-prod-cn apt-daily[1234]: Starting daily apt-get upgrade and clean...
Sep 10 15:00:01 azlinux-prod-cn apt-daily[1234]: Finished daily apt-get upgrade and clean.
Sep 10 15:00:02 azlinux-prod-cn systemd[1]: Started Daily apt upgrade and clean activities.
\`\`\`

**解读：** 该命令显示了 \`/var/log/syslog\` 文件的最后 10 行内容。这对于查看最新的系统日志信息非常有用。

#### 示例 2：实时跟踪文件更新

\`\`\`bash
tail -f /var/log/syslog
\`\`\`

**输出：**

\`\`\`
Sep 10 15:05:00 azlinux-prod-cn systemd[1]: Starting Daily apt upgrade and clean activities...
Sep 10 15:05:01 azlinux-prod-cn apt-daily[1234]: Starting daily apt-get upgrade and clean...
\`\`\`

**解读：** 使用 \`-f\` 参数可以实时跟踪文件的新增内容，适用于监控日志文件的动态更新。

#### 示例 3：显示最后 100 字节

\`\`\`bash
tail -c 100 /var/log/syslog
\`\`\`

**输出：**

\`\`\`
Sep 10 15:00:01 azlinux-prod-cn apt-daily[1234]: Finished daily apt-get upgrade and clean.
Sep 10 15:05:01 azlinux-prod-cn apt-daily[1234]: Starting daily apt-get upgrade and clean...
\`\`\`

**解读：** \`-c\` 参数用于以字节为单位显示文件的最后部分，这在需要查看文件末尾的具体字节数时非常有用。

### 不同架构的输出

\`tail\` 命令在不同架构的机器上表现没有差异。因此，在 aarch64 和 x86_64/amd64 架构下的输出是相同的。

### 不同版本的输出差异

\`tail\` 命令的输出格式在不同版本中通常没有显著差异，但某些 Linux 发行版的 \`tail\` 实现可能会有所不同。务必参考系统文档确认具体细节。

### 安全性与替代命令

\`tail\` 命令没有被淘汰。其功能在现代系统中依然有效且安全。

### 安装

在大多数 Linux 发行版中，\`tail\` 命令是 \`coreutils\` 软件包的一部分。通常情况下，\`coreutils\` 已预安装在系统中。

**Ubuntu:**

\`\`\`bash
sudo apt-get install coreutils
\`\`\`

**RHEL:**

\`\`\`bash
sudo yum install coreutils
\`\`\`

### 使用 root 权限

大多数 \`tail\` 命令的使用不需要 root 权限。然而，查看系统日志文件（如 \`/var/log/syslog\`）可能需要 root 权限。使用 \`sudo\` 可以提升权限：

\`\`\`bash
sudo tail -n 10 /var/log/syslog
\`\`\`

**示例输出：**

\`\`\`
Sep 10 15:00:00 azlinux-prod-cn systemd[1]: Starting Daily apt upgrade and clean activities...
Sep 10 15:00:01 azlinux-prod-cn apt-daily[1234]: Starting daily apt-get upgrade and clean...
\`\`\`

**解读：** 使用 \`sudo\` 运行 \`tail\` 命令可以确保你有权限查看受保护的系统日志文件。

        `,
    };
  },
});
</script>
<style scoped></style>
