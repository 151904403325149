<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## poweroff 命令详解

\`poweroff\` 是一个用于关闭计算机的命令。它可以安全地关闭系统，确保所有进程被正确地终止，并最终关闭电源。这个命令通常需要管理员权限来执行。

### poweroff 是 bash 内置命令还是外部命令？

\`poweroff\` 是一个外部命令，通常位于 \`/sbin/poweroff\`，是系统管理工具的一部分。

### 基本语法

\`poweroff\` 命令的基本语法如下：

\`\`\`bash
poweroff [选项]
\`\`\`

### 重要参数及示例

#### 1. 直接关闭系统

使用 \`poweroff\` 命令可以直接关闭系统：

\`\`\`bash
poweroff
\`\`\`

**示例输出：**

\`\`\`plaintext
[  OK  ] Stopped target Shutdown.
[  OK  ] Stopped target Final Step.
[  OK  ] Reached target Power-Off.
\`\`\`

- **解读**：
  - 该输出表明系统正在停止各种服务和目标，最终将关闭电源。

#### 2. 使用选项进行延时关闭

可以使用 \`-d\` 选项指定延迟关闭的时间（单位为秒）：

\`\`\`bash
poweroff -d 60
\`\`\`

**示例输出：**

\`\`\`plaintext
Powering off in 60 seconds...
\`\`\`

- **解读**：
  - 系统将在 60 秒后关闭，用户可以在此期间保存工作。

### 检查安装状态

\`poweroff\` 命令通常是默认安装在大多数 Linux 发行版中的，因此不需要额外安装。如果需要检查它的位置，可以使用 \`which\` 命令：

\`\`\`bash
which poweroff
\`\`\`

**输出示例：**

\`\`\`plaintext
/sbin/poweroff
\`\`\`

### 不同架构下的执行结果

\`poweroff\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

\`poweroff\` 的功能在不同版本的 Linux 中基本一致，但在某些发行版中，可能存在更高级的关机选项。在较新的系统中，\`systemctl poweroff\` 是更推荐的替代命令：

\`\`\`bash
systemctl poweroff
\`\`\`

**输出示例：**

\`\`\`plaintext
[  OK  ] Stopped target Shutdown.
[  OK  ] Stopped target Final Step.
[  OK  ] Reached target Power-Off.
\`\`\`

- **解读**：
  - \`systemctl poweroff\` 与 \`poweroff\` 命令的功能相同，但提供了更现代的服务管理方法。

### 安装

在大多数情况下，\`poweroff\` 命令默认安装在 Linux 系统中，但如果由于某种原因需要安装它，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install systemd
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install systemd
  \`\`\`

### 使用root权限的场景

由于 \`poweroff\` 是一个敏感命令，因此通常需要使用 root 权限来执行：

\`\`\`bash
sudo poweroff
\`\`\`

**输出示例：**

\`\`\`plaintext
[  OK  ] Stopped target Shutdown.
[  OK  ] Stopped target Final Step.
[  OK  ] Reached target Power-Off.
\`\`\`

### 总结

\`poweroff\` 命令是一个简单而有效的工具，用于安全地关闭 Linux 系统。它的主要优点是能够确保所有进程被正常终止并最终关闭电源。虽然在现代 Linux 发行版中，推荐使用 \`systemctl poweroff\` 命令，但 \`poweroff\` 仍然是一个常用的关机命令。通过理解其参数和用法，用户可以有效地管理系统的电源状态。

        `,
    };
  },
});
</script>
<style scoped></style>
