<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## gcc 命令详解

\`gcc\`（GNU Compiler Collection）是 GNU 项目中的一部分，是一个广泛使用的编程语言编译器，主要用于 C 和 C++ 语言的编译。它提供了丰富的选项和功能，适用于从简单的程序到复杂的系统软件的构建。

### gcc 是 bash 内置命令还是外部命令？

\`gcc\` 是一个外部命令，通常安装在 \`/usr/bin/gcc\`。它不是 Bash 的内置命令，因此需要确保系统中已安装该命令。

### 基本语法

\`gcc\` 命令的基本语法如下：

\`\`\`bash
gcc [选项] [源文件] [目标文件]
\`\`\`

### 重要参数及示例

#### 1. 编译单个源文件

编译一个简单的 C 程序，可以使用以下命令：

\`\`\`bash
gcc hello.c -o hello
# 输出：
# 无输出，命令已成功执行
\`\`\`

在上面的示例中，\`hello.c\` 是源文件，\`-o hello\` 指定输出的可执行文件名为 \`hello\`。执行后不会产生任何输出，表示编译成功。

#### 2. 显示编译警告

为了显示所有的编译警告，可以使用 \`-Wall\` 选项：

\`\`\`bash
gcc -Wall hello.c -o hello
# 输出：
# 无输出，命令已成功执行
\`\`\`

使用该选项后，如果有潜在的警告，编译时将显示相关信息。

#### 3. 编译多个源文件

可以同时编译多个源文件。例如，编译 \`file1.c\` 和 \`file2.c\`：

\`\`\`bash
gcc file1.c file2.c -o myprogram
# 输出：
# 无输出，命令已成功执行
\`\`\`

这将生成名为 \`myprogram\` 的可执行文件。

#### 4. 生成调试信息

使用 \`-g\` 选项可以生成调试信息，便于使用调试工具（如 gdb）调试程序：

\`\`\`bash
gcc -g hello.c -o hello
# 输出：
# 无输出，命令已成功执行
\`\`\`

通过 \`-g\` 选项编译的程序可以使用调试器进行调试。

#### 5. 指定编译标准

可以通过 \`-std=\` 选项指定编译的 C 语言标准，例如：

\`\`\`bash
gcc -std=c99 hello.c -o hello
# 输出：
# 无输出，命令已成功执行
\`\`\`

这将使用 C99 标准编译 \`hello.c\`。

### 检查生成的可执行文件

可以使用 \`file\` 命令检查生成的可执行文件的类型：

\`\`\`bash
file hello
# 输出示例：
# hello: ELF 64-bit LSB executable, x86-64, version 1 (SYSV), dynamically linked, interpreter /lib64/ld-linux-x86-64.so.2, for GNU/Linux 3.2.0, BuildID[sha1]=..., with debug_info, not stripped
\`\`\`

输出结果提供了关于可执行文件的详细信息。

### 不同架构下的执行结果

\`gcc\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

不同版本的 \`gcc\` 可能会在可用选项和支持的标准上有所不同。例如，在较新版本的 \`gcc\` 中，可能增加了一些新的优化选项和警告级别。可以使用以下命令检查版本：

\`\`\`bash
gcc --version
# 输出示例：
# gcc (Ubuntu 9.4.0-1ubuntu1~20.04) 9.4.0
# Copyright (C) 2020 Free Software Foundation, Inc.
\`\`\`

### 安装

在大多数 Linux 发行版中，\`gcc\` 命令通常与 \`build-essential\` 包一起提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install build-essential
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum groupinstall "Development Tools"
  \`\`\`

### 使用root权限的场景

一般情况下，\`gcc\` 命令不需要 root 权限即可执行，但在某些情况下，例如安装编译的可执行文件到系统目录时，可能需要使用 root 权限。以下示例显示了如何将编译的程序安装到 \`/usr/local/bin\` 目录：

\`\`\`bash
sudo cp hello /usr/local/bin/
# 输出：
# 无输出，命令已成功执行
\`\`\`

### 总结

\`gcc\` 是一个功能强大的编译器，适用于 C 和 C++ 等编程语言。掌握 \`gcc\` 的使用方法和参数，可以有效地编译、调试和优化代码，使程序员能够高效地进行软件开发。通过合理使用编译选项，用户可以确保生成高质量的可执行文件。

        `,
    };
  },
});
</script>
<style scoped></style>
