<template>
  <div class="super-btns-zone">
    <el-tooltip
      class="box-item"
      effect="dark"
      content="实验环境的自动销毁时间点。"
      placement="left"
    >
      <span class="release_dt"> release: {{ release_datetime }}</span>
    </el-tooltip>
    <el-tooltip
      class="box-item"
      effect="dark"
      content="约耗时10-30秒，如无法连接，请5秒后再【连接环境】。"
      placement="top"
    >
      <el-button type="success" @click="getTaskServer">准备环境</el-button>
    </el-tooltip>
    <el-button
      type="success"
      @click="connectBox"
      :disabled="!connectButtonEnabled"
      >连接环境</el-button
    >
    <el-button
      type="success"
      :disabled="!checkButtonEnabled"
      @click="submitBox"
    >
      提交任务
    </el-button>
    <el-tooltip
      class="box-item"
      effect="dark"
      content="如不再使用，可【销毁环境】。"
      placement="bottom"
    >
      <el-button
        type="success"
        @click="destroyMyTaskServer"
        :disabled="!destroyOSEnabled"
        >销毁环境</el-button
      >
    </el-tooltip>
  </div>

  <div class="hint-zone">
    {{ hint }}
    <p>{{ hint2 }}</p>
  </div>

  <div class="result-panel">
    <div class="youpass" v-show="win">
      <ChapPass :score="20" />
    </div>

    <div class="youfail" v-show="fail">
      <ChapFail />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import ChapPass from "./ChapPass.vue";
import ChapFail from "./ChapFail.vue";

export default {
  name: "OperationBar",
  props: {
    resId: {
      type: String,
      required: true,
    },
  },
  emits: ["connectstr-update"],
  data() {
    return {
      hint: "",
      hint2: "",
      responseData: {},
      win: "",
      fail: "",
      connectStr: "",
      whost: "sre.azlinux.com",
      wport: 8858,
      thost: "sre.azlinux.com",
      port: 22,
      lname: "sre",
      passwd: "YXpMaW51eC5jb20z",
      connectButtonEnabled: false,
      checkButtonEnabled: false,
      destroyOSEnabled: false,
      release_datetime: "",
    };
  },
  components: {
    ChapPass,
    ChapFail,
  },
  methods: {
    updateLink(currentUrl, taskId) {
      // 使用正则表达式匹配最后的 taskId 并替换它
      return currentUrl.replace(/\/\d+$/, `/${taskId}`);
    },
    getTaskServer() {
      let baseUrl = "http://sre.azlinux.com:5000/get_server";
      let id = this.resId;
      let email_user = localStorage.getItem("email");

      if (!email_user) {
        ElMessage({
          message: "发生了异常，无法检测账号，请重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      let postData = {
        id: id,
        email: email_user.substring(4),
      };

      // console.log("POST 请求地址:", baseUrl);
      // console.log("POST 请求数据:", postData);

      this.connectButtonEnabled = true;
      this.checkButtonEnabled = true;
      this.destroyOSEnabled = true;
      this.release_datetime = "waiting...";

      this.hint =
        "环境准备中，可能需要数十秒，如无法连接，请间隔5秒再次点击【连接环境】，【SSH 连接】蓝色按钮。";
      axios
        .post(baseUrl, postData)
        .then((response) => {
          console.log(response.data);
          this.responseData = response.data;
          if (response.data.vip_status == 0) {
            ElMessageBox.confirm("您的账号VIP状态已过期，需充值续费", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                console.log("MessageBox OK");
              })
              .catch(() => {
                console.log("MessageBox Cancel/Close");
              });
            return;
          }
          this.whost = this.responseData.data.whost;
          this.wport = this.responseData.data.wport;
          this.thost = this.responseData.data.thost;
          this.port = this.responseData.data.port;
          this.lname = this.responseData.data.username;
          this.passwd = this.responseData.data.passwd;
          this.release_datetime = this.responseData.data.release_datetime;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.hint2 =
            "旧环境环境: ip地址: " +
            error.response.data.ip_address +
            " 【或销毁中，需等待1~2分钟（频繁操作的保护机制）】" +
            "账户名 sre 和密码 (个人中心查看)";
          if (error.response.data.status === "error_exists") {
            ElMessage({
              message:
                "旧环境存在!请点击【连接环境】开始使用，或者【销毁环境】。",
              type: "error",
              duration: 6000,
              showClose: true,
              offset: 240,
            });
            const taskId = error.response.data.task_id;
            this.thost = error.response.data.ip_address;
            this.release_datetime = error.response.data.release_datetime;
            this.passwd = error.response.data.sre_password;
            const link = this.updateLink(window.location.href, taskId);

            // 判断当前页面是否已经是目标页面
            const currentUrl = window.location.href;
            if (currentUrl === link) {
              console.log("link: ", link);
              // ElMessage({
              //   message:
              //     "您已经在目标页面，请依次点击【准备环境】=>【销毁环境】。",
              //   type: "warning",
              //   duration: 6000,
              //   offset: 240,
              // });
            } else {
              ElMessage({
                message: h("span", { style: { fontSize: "14px" } }, [
                  "请到页面操作: ",
                  h(
                    "a",
                    {
                      href: link,
                      target: "_blank", // 在新标签页打开链接
                      style: {
                        color: "teal",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "14px",
                      },
                    },
                    `需销毁旧实例: ${taskId}，跳转后依次点击【准备环境】=>【销毁环境】` // 使用模板字符串插入 taskId
                  ),
                ]),
                type: "error",
                duration: 6000,
                offset: 240,
              });
            }
          }
        });
    },
    async destroyMyTaskServer() {
      console.log("destroyMyTaskServer");
      try {
        const email_user = localStorage.getItem("email");
        if (!email_user) {
          ElMessage({
            message: "发生了异常，无法检测账号，请重新登录",
            type: "error",
            duration: 2000,
            offset: 240,
          });
          return;
        }

        const response = await axios.post(
          "http://sre.azlinux.com:5032/delete_task_server",
          {
            email: email_user.substring(4),
          }
        );
        ElMessage({
          message: "环境销毁成功！",
          type: "success",
          duration: 2000,
          offset: 240,
        });
        console.log("Success:", response.data);
      } catch (error) {
        console.error("Error:", error);
        ElMessage({
          message: "环境销毁失败！可能已被动销毁，无需继续操作。",
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
      this.release_datetime = "";
    },
    connectBox() {
      this.connectStr = this.start_task();
      console.log(this.connectStr);
      this.$emit("connectstr-update", this.connectStr);
    },
    start_task() {
      return (this.connectStr =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd);
    },
    submitBox() {
      console.log(this.connectStr);
      let baseUrl = "http://sre.azlinux.com:5010/check_task?";
      let email_user = localStorage.getItem("email").substring(4);
      if (!email_user) {
        alert("Email not found in localStorage");
        return;
      }
      let url = baseUrl + "email=" + email_user;
      console.log(url);
      axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          if ("pass" == response.data.check_result) {
            this.win = true;
            this.fail = false;
            console.log("srepass");
          } else {
            this.fail = true;
            this.win = false;
            console.log("srefail");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<style scoped>
.youpass,
.youfail {
  width: 100%;
  margin: 1rem;
}
.super-btns-zone {
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.hint-zone {
  font-size: 14px;
}
.release_dt {
  font-size: 14px;
  color: #7986cb;
  margin-right: 12px;
}
</style>
