<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
    <!-- <div class="search-section">
      <el-input
        placeholder="搜索命令"
        v-model="cmdKeyword"
        @keyup.enter="handleSearch"
        clearable
        style="width: 100%; max-width: 360px; margin: 20px auto"
      >
        <template v-slot:append>
          <el-button @click="handleSearch">搜索</el-button>
        </template>
      </el-input>
      <div v-if="searchResult" class="search-results">
        <h3>搜索结果:</h3>
        <pre>{{ searchResult }}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import axios from "axios";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
---
  #### 1. 通过学习本模块，您能较深入地掌握Linux命令的使用。
  #### 2. 点击左侧链接，或者【搜索命令】，展开的页面是命令的详细解释，从简单到内部原理。
  #### 3. 建议您在页面的终端中动手练习，加深对知识点的理解。也可以复制浏览器的Tab页签，新窗口中练习。
  #### 4. 包含运维高频面试题笔试题等。

---

## man手册：命令的格式说明

在Linux和Unix系统中，\`man\`命令用于查看系统命令的手册（manual）页。这些手册以一种标准化的格式呈现，便于用户理解命令的用法、选项和参数。了解这些格式的含义对于有效使用命令至关重要。以下是一些常见的格式及其含义，并结合简单的命令进行说明：

### 格式说明

1. **方括号 \`[]\`**：
   - 方括号表示内容是可选的。例如，命令 \`ls [OPTION]\` 中，\`OPTION\` 是可选的。如果没有提供选项，\`ls\` 将以默认方式列出当前目录的文件。

2. **省略号 \`...\`**：
   - 省略号表示可以重复出现前面的选项或参数。比如，\`cp [OPTION]... SOURCE... DEST\` 表示可以提供一个或多个选项和源文件。

3. **大写字母**：
   - 通常用来表示需要以大写形式输入的参数。例如，\`-A\` 通常代表一个特定的选项，像 \`tar -A\` 用于追加归档，像ls命令的\`-A\`和\`-a\`含义不同。

4. **大括号 \`{}\`**：
   - 大括号用于表示一组选项中必须选择一个。例如，\`{start|stop|restart}\` 表示必须在这三个选项中选择一个，类似于 \`systemctl {start|stop|restart} service_name\`。

5. **普通文本**：
   - 普通文本通常指命令、文件名或参数的名称，用户需要根据实际情况提供这些内容。例如，在 \`rm FILE\` 中，\`FILE\` 是你需要指定的文件名。

### 示例：\`date\` 命令

以 \`date\` 命令为例，下面是如何使用 \`man\` 命令查看其手册页：

\`\`\`bash
man date
\`\`\`

在手册中，你可能会看到如下内容：

\`\`\`
Usage: date [OPTION]... [+FORMAT]
\`\`\`

- 在这个示例中，\`OPTION\` 和 \`FORMAT\` 都是可选的。这意味着你可以单独使用 \`date\` 命令来显示当前日期和时间，而无需任何选项。
- 如果你需要自定义输出格式，可以使用 \`+FORMAT\`，例如 \`date "+%Y-%m-%d"\`。

#### 具体选项

- \`-u\`：显示协调世界时（UTC）。
- \`--help\`：显示帮助信息。
- \`--version\`：显示版本信息。

如果你输入 \`date -u\`，将只显示当前的UTC时间。通过理解这些选项和格式，你可以更灵活地使用命令。

### 总结

熟悉 \`man\` 手册格式是有效使用Linux命令的关键。方括号表示可选项，省略号表示可重复，大写字母表示特定格式的输入，大括号表示必须选择一个选项，普通文本指命令或文件名。掌握这些符号能帮助你更好地理解和使用命令。在实际工作中，借助 \`man\` 命令，你可以快速查找所需的信息，提高工作效率。

          `,
      cmdKeyword: "",
      searchResult: null, // 初始化为null，以便在v-if中判断
    };
  },
  methods: {
    //     async handleSearch() {
    //       const trimmedQuery = this.cmdKeyword.trim();
    //       if (trimmedQuery) {
    //         try {
    //           const response = await axios.post(
    //             `http://sre.azlinux.com:5033/cmd_search`, // 确保URL正确
    //             {
    //               cmdKeyword: trimmedQuery, // 将查询字符串作为请求体发送
    //             }
    //           );
    //           this.searchResult = response.data; // 保存搜索结果
    //         } catch (error) {
    //           console.error("搜索请求失败:", error);
    //         }
    //       } else {
    //         this.searchResult = null; // 清空搜索结果
    //       }
    //     },
  },
});
</script>

<style scoped>
.search-section {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-results {
  margin-top: 20px;
  text-align: left;
}

.search-results pre {
  white-space: pre-wrap; /* 使pre元素内的文本可以换行 */
  word-wrap: break-word; /* 对长单词进行换行 */
}
</style>
