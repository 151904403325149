<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## rsync 命令详解

\`rsync\` 是一个高效的文件同步工具，用于在本地或通过网络复制和同步文件和目录。它以增量方式工作，仅复制源与目标之间的差异部分，因此在执行大规模数据传输时非常高效。

### 安装

\`rsync\` 通常在大多数 Linux 发行版中预装。如果未安装，可以使用以下命令进行安装。

#### Ubuntu

\`\`\`bash
sudo apt install rsync
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install rsync
\`\`\`

### 基本语法

\`\`\`bash
rsync [选项] 源 目标
\`\`\`

### 常用选项

- \`-a\`：归档模式，等同于 \`-rlptgoD\`，用于递归复制文件并保持文件属性。
- \`-v\`：详细输出，显示正在传输的文件。
- \`-z\`：在传输过程中压缩文件，减少带宽使用。
- \`-r\`：递归复制目录。
- \`-u\`：仅复制源中比目标新的文件。
- \`--delete\`：删除目标中在源中不存在的文件。
- \`-e\`：指定使用的远程 shell（例如 SSH）。
- \`-P\`：显示进度并允许中断后继续传输。

### 示例用法

#### 示例一：基本的本地文件复制

将 \`source.txt\` 文件复制到 \`/backup/\` 目录中：

\`\`\`bash
rsync -av source.txt /backup/
\`\`\`

**输出示例**：

\`\`\`plaintext
sending incremental file list
source.txt
sent 123 bytes  received 45 bytes  114.43 bytes/sec
total size is 1024 bytes  speedup is 7.67
\`\`\`

**解读**：该命令使用归档模式和详细模式将 \`source.txt\` 文件复制到目标目录 \`/backup/\`，输出显示了文件的传输速度和总大小。

#### 示例二：递归复制目录

将整个 \`myfolder\` 目录复制到 \`/backup/\` 中：

\`\`\`bash
rsync -av myfolder/ /backup/myfolder/
\`\`\`

**输出示例**：

\`\`\`plaintext
sending incremental file list
myfolder/
myfolder/file1.txt
myfolder/file2.txt
sent 456 bytes  received 98 bytes  179.00 bytes/sec
total size is 2048 bytes  speedup is 3.92
\`\`\`

**解读**：该命令递归复制 \`myfolder\` 目录及其所有内容，并输出文件传输的详细信息。

#### 示例三：通过 SSH 远程复制文件

将本地文件 \`file.txt\` 复制到远程主机的 \`/remote/\` 目录中：

\`\`\`bash
rsync -av -e ssh file.txt user@remote_host:/remote/
\`\`\`

**输出示例**：

\`\`\`plaintext
sending incremental file list
file.txt
sent 123 bytes  received 45 bytes  114.43 bytes/sec
total size is 1024 bytes  speedup is 7.67
\`\`\`

**解读**：该命令通过 SSH 将文件 \`file.txt\` 传输到远程主机，输出显示传输的详细信息。

#### 示例四：使用 \`--delete\` 选项

同步本地目录到远程目录，并删除目标中不在源中的文件：

\`\`\`bash
rsync -av --delete myfolder/ user@remote_host:/remote/myfolder/
\`\`\`

**输出示例**：

\`\`\`plaintext
sending incremental file list
deleting oldfile.txt
myfolder/
myfolder/file1.txt
sent 456 bytes  received 98 bytes  179.00 bytes/sec
total size is 2048 bytes  speedup is 3.92
\`\`\`

**解读**：该命令同步本地目录，并删除远程目录中不再存在的文件，输出中显示了被删除的文件。

#### 示例五：压缩传输

使用压缩传输整个 \`myfolder\` 目录：

\`\`\`bash
rsync -avz myfolder/ /backup/myfolder/
\`\`\`

**输出示例**：

\`\`\`plaintext
sending incremental file list
myfolder/
myfolder/file1.txt
myfolder/file2.txt
sent 456 bytes  received 98 bytes  179.00 bytes/sec
total size is 2048 bytes  speedup is 3.92
\`\`\`

**解读**：该命令通过压缩提高传输效率，适用于带宽有限的网络。

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上的 \`rsync\` 命令输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

\`rsync\` 在不同版本之间可能存在某些功能的差异，可以通过以下命令检查当前版本：

\`\`\`bash
rsync --version
\`\`\`

**输出示例**：

\`\`\`plaintext
rsync version 3.1.3 protocol version 31
\`\`\`

### 权限要求

某些操作（例如，写入目标目录）可能需要 root 权限。在这种情况下，可以使用 \`sudo\` 提升权限。例如：

\`\`\`bash
sudo rsync -av source.txt /backup/
\`\`\`

#### 示例输出

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 总结

\`rsync\` 是一个强大且灵活的工具，用于高效的文件和目录同步。掌握常用选项和用法，可以帮助用户轻松管理数据传输与备份，提高工作效率。无论是本地还是远程操作，\`rsync\` 都能提供卓越的性能和便捷性。

        `,
    };
  },
});
</script>
<style scoped></style>
