<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dmidecode 命令详解

\`dmidecode\` 是用于提取系统硬件信息的命令行工具，它通过读取系统 BIOS 或 UEFI 中的 DMI (Desktop Management Interface) 表来显示主板、处理器、内存等硬件信息。该命令特别适合用于诊断和查看系统硬件配置，运维人员可以在不需要拆开主机的情况下获取详细的硬件信息。

### 1. 安装 dmidecode

\`dmidecode\` 通常默认安装在大多数 Linux 发行版上，但如果未安装，可以通过以下命令进行安装：

- **Ubuntu/Debian**:
  \`\`\`bash
  sudo apt install dmidecode
  \`\`\`

- **RHEL/CentOS**:
  \`\`\`bash
  sudo yum install dmidecode
  \`\`\`

- **Fedora**:
  \`\`\`bash
  sudo dnf install dmidecode
  \`\`\`

### 2. 使用方式概述

\`dmidecode\` 可以通过 root 用户执行以读取系统的 DMI 数据。它输出的内容包含丰富的硬件信息，例如 BIOS 版本、制造商、序列号等。

基本命令格式：
\`\`\`bash
sudo dmidecode [选项]
\`\`\`

### 3. 常用参数

#### 3.1. -t, --type TYPE
\`-t\` 或 \`--type\` 用于只显示特定类型的信息。可指定的类型包括 \`bios\`, \`system\`, \`baseboard\`, \`processor\`, \`memory\` 等。

示例：查看处理器信息
\`\`\`bash
sudo dmidecode -t processor
\`\`\`

**输出**:
\`\`\`
# dmidecode 3.2
Getting SMBIOS data from sysfs.
SMBIOS 2.8 present.

Handle 0x0004, DMI type 4, 48 bytes
Processor Information
        Socket Designation: CPU1
        Type: Central Processor
        Family: Core i7
        Manufacturer: Intel
        Version: Intel(R) Core(TM) i7-7700HQ CPU @ 2.80GHz
        ...
\`\`\`

**解释**: 此命令输出了有关处理器的详细信息，包括型号、制造商和频率。

#### 3.2. -s, --string KEYWORD
\`-s\` 或 \`--string\` 可以指定显示特定关键字的信息。例如，显示系统序列号。

示例：查看系统序列号
\`\`\`bash
sudo dmidecode -s system-serial-number
\`\`\`

**输出**:
\`\`\`
ABC1234567890
\`\`\`

**解释**: 此命令输出了系统的序列号，常用于设备识别。

#### 3.3. --dump-bin FILE
将 DMI 数据保存到文件中，便于之后查看。可用于调试或备份系统硬件信息。

示例：将数据保存到文件
\`\`\`bash
sudo dmidecode --dump-bin /tmp/dmi_data.bin
\`\`\`

**解释**: 该命令不会产生直接输出，而是将 DMI 数据保存到指定的二进制文件中。

#### 3.4. --from-dump FILE
从二进制文件中读取 DMI 数据，而不直接访问 BIOS。这通常与 \`--dump-bin\` 配合使用。

示例：从文件读取 DMI 数据
\`\`\`bash
sudo dmidecode --from-dump /tmp/dmi_data.bin
\`\`\`

**解释**: 该命令会从之前保存的二进制文件中读取并展示硬件信息，而无需重新读取 BIOS。

#### 3.5. --no-sysfs
不从 \`/sys\` 文件系统读取 DMI 数据，而是直接从设备中提取数据。这在某些情况下可能会产生不同的结果。

示例：
\`\`\`bash
sudo dmidecode --no-sysfs
\`\`\`

**输出**:
\`\`\`
# dmidecode 3.2
Getting SMBIOS data from memory.
SMBIOS 2.8 present.
...
\`\`\`

**解释**: 该命令跳过 \`/sys\` 文件系统，并直接从内存中读取数据。

### 4. 命令输出解读

\`dmidecode\` 的输出分为多个部分，每一部分都提供了关于不同硬件组件的信息。常见的几个类别如下：

- **BIOS Information**: 显示 BIOS 版本、制造日期等信息。
- **System Information**: 包括制造商、产品名称、序列号等。
- **Baseboard Information**: 显示主板的详细信息。
- **Processor Information**: 包含处理器型号、频率、核心数量等。

### 5. 版本差异

\`dmidecode\` 在不同版本中主要是对支持的硬件类型的扩展和对新 DMI 类型的支持。如果遇到新硬件信息未能显示的情况，建议更新到最新版本。

例如，在 \`dmidecode\` 3.x 版本中，增加了对 SMBIOS 3.x 的支持，这使得它能够读取更新的系统硬件信息。

### 6. 架构差异

在 aarch64 和 x86_64 架构下，\`dmidecode\` 的输出基本一致，因为它依赖于 BIOS/UEFI 中存储的标准化 DMI 数据。两者在硬件信息格式和内容上通常没有显著差异。

### 7. 使用 root 权限

由于 \`dmidecode\` 需要读取系统 BIOS 中的敏感信息，所以通常需要 root 权限来执行。可以通过 \`sudo\` 来提升权限：

\`\`\`bash
sudo dmidecode -t memory
\`\`\`

**输出**:
\`\`\`
# dmidecode 3.2
Getting SMBIOS data from sysfs.
SMBIOS 2.8 present.

Handle 0x0038, DMI type 17, 34 bytes
Memory Device
        Size: 8192 MB
        ...
\`\`\`

**解释**: 该命令显示了系统的内存设备信息，包括每条内存条的大小、类型和制造商信息。

### 8. 已弃用功能

\`dmidecode\` 没有明显的弃用功能，但在未来可能会因为系统架构的变化或 BIOS/UEFI 的变化需要进行更新。如果 \`dmidecode\` 无法获取数据，可以考虑使用新的硬件信息提取工具如 \`lshw\`。

### 9. 示例环境信息

假设当前主机名为 \`azlinux-prod-cn\`，用户 \`sre\` 运行命令。以下为一些示例输出。

\`\`\`bash
sudo dmidecode -t system
\`\`\`

**输出**:
\`\`\`
# dmidecode 3.2
Getting SMBIOS data from sysfs.
SMBIOS 2.8 present.

Handle 0x0001, DMI type 1, 27 bytes
System Information
        Manufacturer: QEMU
        Product Name: Standard PC (i440FX + PIIX, 1996)
        Version: pc-i440fx-2.9
        Serial Number: Not Specified
        ...
\`\`\`

**解释**: 输出了系统的制造商、产品名称、版本和序列号等信息。

### 10. 总结

\`dmidecode\` 是一个强大的工具，用于提取系统硬件信息，通过简单的命令就能获取大量的硬件细节。它在诊断硬件故障、获取设备详细信息时非常有用，特别是在无物理访问机器时。

        `,
    };
  },
});
</script>
<style scoped></style>
