<template>
  <div class="back-to-top" v-show="isVisible" @click="scrollToTop">⬆️</div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "BackToTop",
  setup() {
    const isVisible = ref(false);

    const toggleVisibility = () => {
      isVisible.value = window.scrollY > 300;
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      window.addEventListener("scroll", toggleVisibility);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", toggleVisibility);
    });

    return {
      isVisible,
      scrollToTop,
    };
  },
};
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3498db;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 99;
}

.back-to-top:hover {
  transform: scale(1.1);
}
</style>
