<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## who 命令详解

\`who\`命令是Linux和类Unix系统中一个非常常用的命令，用于显示当前登录的用户信息。通过该命令，系统管理员可以轻松查看系统上有哪些用户正在登录以及相关的登录细节，如登录时间、登录终端等。

### 基本语法

\`\`\`bash
who [选项]
\`\`\`

\`who\`命令的基本输出包括用户名、终端、登录时间、主机信息等。该命令既没有内置于Bash中，也没有其他系统的内置版本，它通常是GNU Coreutils包的一部分。

### 常用选项

#### 1. 不带任何选项

默认情况下，\`who\`命令会输出当前所有已登录用户的信息。示例：

\`\`\`bash
who
\`\`\`

输出示例：

\`\`\`bash
sre      pts/0        2024-09-11 14:23 (192.168.1.100)
dev01    pts/1        2024-09-11 15:01 (192.168.1.102)
app01    pts/2        2024-09-11 15:05 (192.168.1.103)
\`\`\`

解读：
- \`sre\`, \`dev01\`, \`app01\` 分别是三个正在登录的用户。
- \`pts/0\`, \`pts/1\`, \`pts/2\` 是登录的虚拟终端。
- \`2024-09-11 14:23\` 等时间为用户的登录时间。
- 括号中的IP地址（如\`192.168.1.100\`）显示了用户从哪个IP登录。

#### 2. \`-b\` 显示系统上次重启的时间

该选项用于显示系统上次启动的时间。

\`\`\`bash
who -b
\`\`\`

输出示例：

\`\`\`bash
         system boot  2024-09-10 08:15
\`\`\`

解读：系统上次重启的时间为2024年9月10日的8点15分。

#### 3. \`-q\` 显示当前登录用户的数量

这个选项简洁地显示当前登录用户，并在最后一行显示总的用户数量。

\`\`\`bash
who -q
\`\`\`

输出示例：

\`\`\`bash
sre dev01 app01
# users=3
\`\`\`

解读：当前系统有三个用户登录，分别是\`sre\`, \`dev01\`, \`app01\`。

#### 4. \`-u\` 显示用户的活动信息

该选项会在基本信息后添加更多关于用户活动的信息。

\`\`\`bash
who -u
\`\`\`

输出示例：

\`\`\`bash
sre      pts/0        2024-09-11 14:23   .          3057 (:0)
dev01    pts/1        2024-09-11 15:01   04:00      2345 (:0)
app01    pts/2        2024-09-11 15:05   01:30      4567 (:1)
\`\`\`

解读：
- \`.\` 表示用户处于活跃状态。
- \`04:00\` 和 \`01:30\` 表示这些用户上次活动距离现在的时间（小时:分钟）。
- 最后一列数字（如\`3057\`, \`2345\`, \`4567\`）是用户的进程ID（PID）。

#### 5. \`-T\` 显示终端的状态

\`-T\`选项可以查看每个用户的终端是否允许写入消息。

\`\`\`bash
who -T
\`\`\`

输出示例：

\`\`\`bash
sre      + pts/0        2024-09-11 14:23   .          3057 (:0)
dev01    - pts/1        2024-09-11 15:01   04:00      2345 (:0)
app01    + pts/2        2024-09-11 15:05   01:30      4567 (:1)
\`\`\`

解读：
- \`+\` 表示终端允许写入消息。
- \`-\` 表示终端不允许写入消息。

#### 6. \`-H\` 显示输出的标题行

为了便于阅读，可以通过\`-H\`选项为输出添加标题行。

\`\`\`bash
who -H
\`\`\`

输出示例：

\`\`\`bash
NAME     LINE         TIME             IDLE          PID COMMENT  EXIT
sre      pts/0        2024-09-11 14:23   .          3057 (:0)
dev01    pts/1        2024-09-11 15:01   04:00      2345 (:0)
app01    pts/2        2024-09-11 15:05   01:30      4567 (:1)
\`\`\`

解读：标题行增加了对每一列的解释。

### 特殊用法

#### 查看特定用户的信息

如果想要查看某个特定用户的登录信息，可以通过管道和\`grep\`命令配合使用。例如，要查看\`sre\`用户的登录信息：

\`\`\`bash
who | grep sre
\`\`\`

输出示例：

\`\`\`bash
sre      pts/0        2024-09-11 14:23 (192.168.1.100)
\`\`\`

### 安装信息

在大多数Linux发行版中，\`who\`命令作为\`coreutils\`包的一部分预装。若系统中未安装该命令，可以通过以下方式安装：

#### Ubuntu/Debian

\`\`\`bash
sudo apt install coreutils
\`\`\`

#### RHEL/CentOS

\`\`\`bash
sudo yum install coreutils
\`\`\`

### 注意事项

- \`who\`命令与\`w\`命令功能类似，但\`w\`命令提供了更多关于用户活动的信息（如进程占用资源等）。
- 不同的\`who\`命令版本间输出可能存在细微差异，但核心功能基本保持一致。

### 结论

\`who\`命令是一个简单但非常有用的工具，系统管理员可以借助它快速查看当前登录的用户信息。该命令的多个选项允许用户按需定制输出，以获取更多关于登录用户的详细信息。

        `,
    };
  },
});
</script>
<style scoped></style>
