<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## nslookup 命令详解

\`nslookup\` 是一个用于查询 DNS（域名系统）记录的命令行工具。它可以帮助用户获取与域名相关的各种信息，如 IP 地址、邮件交换记录等。

### nslookup 是 bash 内置命令还是外部命令？

\`nslookup\` 是一个外部命令，通常位于 \`/usr/bin/nslookup\`。它是系统自带的 DNS 查询工具，广泛用于网络故障排除和域名信息查询。

### 基本语法

\`nslookup\` 命令的基本语法如下：

\`\`\`bash
nslookup [选项] [域名]
\`\`\`

### 重要参数及示例

#### 1. 查询域名的 IP 地址

要查询特定域名的 IP 地址，可以直接在命令后输入域名：

\`\`\`bash
nslookup azlinux-prod-cn
\`\`\`

**示例输出：**

\`\`\`plaintext
Server:		8.8.8.8
Address:	8.8.8.8#53

Non-authoritative answer:
Name:		azlinux-prod-cn
Address:	192.168.1.100
\`\`\`

- **解读**：
  - \`Server\`：使用的 DNS 服务器。
  - \`Address\`：DNS 服务器的 IP 地址。
  - \`Non-authoritative answer\`：非权威回答，表示该信息可能来自缓存。
  - \`Name\`：查询的域名。
  - \`Address\`：域名对应的 IP 地址。

#### 2. 查询特定 DNS 记录

可以通过指定记录类型来查询特定的 DNS 记录，例如查询 MX（邮件交换）记录：

\`\`\`bash
nslookup -query=MX azlinux-prod-cn
\`\`\`

**示例输出：**

\`\`\`plaintext
Server:		8.8.8.8
Address:	8.8.8.8#53

Non-authoritative answer:
azlinux-prod-cn MX preference = 10, mail exchanger = mail.azlinux-prod-cn
\`\`\`

- **解读**：
  - \`MX preference\`：优先级，数值越小优先级越高。
  - \`mail exchanger\`：邮件交换主机的名称。

#### 3. 指定 DNS 服务器进行查询

可以使用 \`server\` 命令切换到特定的 DNS 服务器进行查询：

\`\`\`bash
nslookup
> server 1.1.1.1
> azlinux-prod-cn
\`\`\`

**示例输出：**

\`\`\`plaintext
Server:		1.1.1.1
Address:	1.1.1.1#53

Non-authoritative answer:
Name:		azlinux-prod-cn
Address:	192.168.1.100
\`\`\`

- **解读**：
  - 切换到 Cloudflare 的 DNS 服务器（1.1.1.1）进行查询。

### 检查安装状态

可以通过 \`which\` 命令检查 \`nslookup\` 是否已安装：

\`\`\`bash
which nslookup
# 输出示例：
# /usr/bin/nslookup
\`\`\`

输出表明 \`nslookup\` 已安装并位于 \`/usr/bin\` 目录。

### 不同架构下的执行结果

\`nslookup\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

\`nslookup\` 在某些 Linux 发行版中被标记为不推荐使用，推荐使用 \`dig\` 命令作为替代。以下是 \`dig\` 的基本用法示例：

\`\`\`bash
dig azlinux-prod-cn
\`\`\`

**输出示例：**

\`\`\`plaintext
; <<>> DiG 9.10.6 <<>> azlinux-prod-cn
;; global options: +cmd
;; Got answer:
;; ->>HEADER<<- opcode: QUERY, status: NOERROR, id: 13667
;; flags: qr rd ra; QUERY: 1, ANSWER: 1, AUTHORITY: 0, ADDITIONAL: 0

;; QUESTION SECTION:
;azlinux-prod-cn.		IN	A

;; ANSWER SECTION:
azlinux-prod-cn.	3600	IN	A	192.168.1.100
\`\`\`

### 安装

在某些 Linux 发行版中，\`nslookup\` 命令由 \`dnsutils\` 包提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install dnsutils
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install bind-utils
  \`\`\`

### 使用root权限的场景

一般情况下，\`nslookup\` 不需要 root 权限，但在某些系统中，为了访问网络，可能需要使用 \`sudo\`：

\`\`\`bash
sudo nslookup azlinux-prod-cn
\`\`\`

**输出示例：**

\`\`\`plaintext
Server:		8.8.8.8
Address:	8.8.8.8#53

Non-authoritative answer:
Name:		azlinux-prod-cn
Address:	192.168.1.100
\`\`\`

### 总结

\`nslookup\` 是一个实用的 DNS 查询工具，适用于网络故障排除和域名信息查询。尽管现代 Linux 系统推荐使用 \`dig\` 命令替代 \`nslookup\`，但 \`nslookup\` 仍然是很多用户的首选工具。通过熟练使用 \`nslookup\` 的各种参数，用户可以轻松获取域名的各种 DNS 记录，为网络维护和管理提供有力支持。

        `,
    };
  },
});
</script>
<style scoped></style>
