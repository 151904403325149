<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## useradd 命令详解

\`useradd\` 命令用于在 Linux 系统中创建一个新用户。它是一个非常重要的系统管理工具，允许管理员添加新的用户账户，并配置用户的基本属性。

### 1. 基本用法

\`useradd\` 命令的基本形式如下：

\`\`\`bash
useradd [OPTION...] USERNAME
\`\`\`

其中，\`USERNAME\` 是要创建的用户的用户名。

### 2. 常用选项

- \`-c, --comment COMMENT\`：为用户添加注释信息（通常是全名或描述）。
- \`-d, --home-dir HOME_DIR\`：指定用户的主目录。
- \`-e, --expiredate EXPIRE_DATE\`：指定用户账户过期的日期（格式：YYYY-MM-DD）。
- \`-f, --inactive INACTIVE\`：指定用户账户在密码过期后多少天被禁用。
- \`-g, --gid GROUP\`：指定用户的主组。
- \`-G, --groups GROUPS\`：指定用户所属的附加组（用逗号分隔）。
- \`-m, --create-home\`：创建用户的主目录（如果不存在的话）。
- \`-s, --shell SHELL\`：指定用户的登录 shell。
- \`-u, --uid UID\`：指定用户的用户 ID。

### 3. 示例及解释

#### 3.1 创建一个新用户

\`\`\`bash
$ sudo useradd -m -c "SRE User" -s /bin/bash -G sre-group dev01
\`\`\`

**输出解释**：
- \`-m\`：创建用户的主目录 \`/home/dev01\`（如果不存在）。
- \`-c "SRE User"\`：为用户添加描述信息。
- \`-s /bin/bash\`：指定用户的登录 shell。
- \`-G sre-group\`：将用户添加到 \`sre-group\` 组中。
- \`dev01\`：新创建的用户名。

#### 3.2 创建用户并指定主目录

\`\`\`bash
$ sudo useradd -m -d /home/dev01 -s /bin/zsh dev01
\`\`\`

**输出解释**：
- \`-m\`：创建用户的主目录。
- \`-d /home/dev01\`：指定主目录为 \`/home/dev01\`。
- \`-s /bin/zsh\`：指定用户的登录 shell 为 \`zsh\`。

#### 3.3 创建用户并设置过期日期

\`\`\`bash
$ sudo useradd -e 2024-12-31 -m dev02
\`\`\`

**输出解释**：
- \`-e 2024-12-31\`：指定用户账户的过期日期为 \`2024-12-31\`。
- \`-m\`：创建用户的主目录。

### 4. 需要的权限

\`useradd\` 命令需要 root 权限才能创建用户。普通用户没有权限执行该命令。

\`\`\`bash
$ sudo useradd [OPTIONS] USERNAME
\`\`\`

### 5. 安装信息

\`useradd\` 命令通常包含在 \`passwd\` 包中，这个包是大多数 Linux 发行版的默认安装包之一。

- **Ubuntu**：\`passwd\` 包中包含 \`useradd\`。
- **RHEL**：\`passwd\` 包中包含 \`useradd\`。

### 6. 版本差异

\`useradd\` 命令的基本功能在不同版本中通常没有显著差异，但某些选项可能会有所不同。建议查看系统的手册页以获取详细的版本信息。

### 7. 总结

\`useradd\` 命令是系统管理员管理用户账户的基本工具。通过掌握其常用选项，可以有效地创建和管理用户账户，确保系统的正常运行。

        `,
    };
  },
});
</script>
<style scoped></style>
