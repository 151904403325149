<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## grep 命令详解

\`grep\` 命令是一个用于在文件中搜索文本模式的强大工具。它会根据用户指定的模式搜索文件，并输出包含该模式的行。\`grep\` 是文本处理和系统管理中非常常用的工具。

### 基本用法

\`\`\`bash
grep [options] pattern [file...]
\`\`\`

- \`[options]\`：指定命令选项。
- \`pattern\`：要搜索的文本模式。
- \`[file...]\`：待搜索的文件。如果未指定文件，\`grep\` 从标准输入读取数据。

**示例**

假设有一个文件 \`example.txt\`，内容如下：

\`\`\`txt
apple
banana
cherry
apple pie
\`\`\`

使用 \`grep\` 命令搜索包含“apple”的行：

\`\`\`bash
$ grep "apple" example.txt
\`\`\`

**示例输出**

\`\`\`bash
apple
apple pie
\`\`\`

**输出解读**:

- \`grep "apple"\` 搜索文件 \`example.txt\` 中所有包含 "apple" 的行。
- 输出显示了匹配的所有行。

### 常用选项

#### 1. \`-i\`（忽略大小写）

忽略大小写进行搜索。

\`\`\`bash
$ grep -i "APPLE" example.txt
\`\`\`

**示例输出**

\`\`\`bash
apple
apple pie
\`\`\`

**输出解读**:

- \`-i\` 选项使得搜索不区分大小写，因此 "APPLE" 和 "apple" 都被匹配。

#### 2. \`-v\`（反向匹配）

显示不包含模式的所有行。

\`\`\`bash
$ grep -v "apple" example.txt
\`\`\`

**示例输出**

\`\`\`bash
banana
cherry
\`\`\`

**输出解读**:

- \`-v\` 选项使得 \`grep\` 显示不包含 "apple" 的所有行。

#### 3. \`-r\`（递归搜索）

递归地搜索目录中的文件。

\`\`\`bash
$ grep -r "apple" /path/to/directory
\`\`\`

**示例输出**

\`\`\`bash
/path/to/directory/file1.txt:apple pie
/path/to/directory/file2.txt:apple tart
\`\`\`

**输出解读**:

- \`-r\` 选项使得 \`grep\` 递归地搜索指定目录及其子目录中的文件。

#### 4. \`-n\`（显示行号）

显示匹配行的行号。

\`\`\`bash
$ grep -n "apple" example.txt
\`\`\`

**示例输出**

\`\`\`bash
1:apple
4:apple pie
\`\`\`

**输出解读**:

- \`-n\` 选项显示了匹配行的行号。

#### 5. \`-l\`（仅显示文件名）

仅显示包含模式的文件名，而不是匹配的行。

\`\`\`bash
$ grep -l "apple" *.txt
\`\`\`

**示例输出**

\`\`\`bash
example.txt
file1.txt
file2.txt
\`\`\`

**输出解读**:

- \`-l\` 选项只列出包含模式的文件名。

#### 6. \`-c\`（计数匹配行数）

显示匹配模式的行数。

\`\`\`bash
$ grep -c "apple" example.txt
\`\`\`

**示例输出**

\`\`\`bash
2
\`\`\`

**输出解读**:

- \`-c\` 选项显示匹配模式的行数，这里为 2。

### 内置命令与外部命令

\`grep\` 是一个外部命令，通常由 GNU grep 工具包提供。在 Bash 中没有内置的 \`grep\` 命令，但可以使用 \`grep\` 的功能来实现类似的文本处理任务。

### 不同架构下的输出

\`grep\` 命令的输出在不同架构下是一致的，因为它依赖于工具的实现，而不是硬件架构。

### 版本差异

不同版本的 \`grep\` 可能会有不同的功能或改进。新版本可能支持更多的选项或改进了性能。可以通过 \`grep --version\` 查看当前版本。

**示例**

\`\`\`bash
$ grep --version
grep (GNU grep) 3.8
\`\`\`

### 替代命令

- \`ack\`：一个类似于 \`grep\` 的工具，专为程序员设计，支持更多功能。
- \`ag\`（The Silver Searcher）：一个比 \`grep\` 更快的工具，适用于大规模代码库搜索。

### 安装

\`grep\` 命令通常预装在 Unix 和类 Unix 系统中。如果需要安装，可以使用以下方法：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install grep
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install grep
  \`\`\`

### 需要 root 权限的选项

\`grep\` 命令本身不需要 root 权限。普通用户可以在其有权限访问的文件中进行搜索。如果要搜索系统目录中的文件，可能需要使用 \`sudo\`：

**示例**

\`\`\`bash
sudo grep "password" /etc/*
\`\`\`

**示例输出**

\`\`\`bash
/etc/passwd:root:x:0:0:root:/root:/bin/bash
\`\`\`

**输出解读**:

- 使用 \`sudo\` 搜索系统目录中的文件，显示了包含 "password" 的行。

### 总结

\`grep\` 命令是一个强大的文本搜索工具，可以帮助用户在文件和目录中快速找到所需的信息。通过掌握 \`grep\` 的常用选项和用法，用户可以更加高效地进行文本处理和数据分析。

        `,
    };
  },
});
</script>
<style scoped></style>
