<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="时间紧迫，任务重。公司部门研发了一款新的手机操作系统，即将开发布会。请将os_release.txt和os_release_zh_CN.txt两个文件中的一些公司名字(Google)替换为RedHeart，也请将Android替换为RedHeart，注意Android可能有小写的情况。"
      source="基础产品研发中心-OS部门"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink
      :resId="resId"
      :defaultLink="bilibiliLink"
      style="margin-bottom: 1rem"
      >视频解说</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdRm",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10066",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
##  \`sed\` 是一个强大的流编辑器，用于处理文本文件。以下是 \`-i\`、\`-e\` 和 \`-E\` 选项的详细解释：

### 1. \`-i\` 选项

- **功能**: 该选项用于直接在文件中进行修改，而不是将输出打印到标准输出。使用 \`-i\` 选项后，原文件将被替换为修改后的内容。
- **用法**: 
  \`\`\`bash
  sed -i 's/old_text/new_text/g' filename
  \`\`\`
- **示例**: 
  \`\`\`bash
  sed -i 's/Google/RedHeart/g' myfile.txt
  \`\`\`
  上述命令将在 \`myfile.txt\` 文件中将所有的 "Google" 替换为 "RedHeart"。

### 2. \`-e\` 选项

- **功能**: 该选项允许您在同一命令中执行多个 \`sed\` 表达式。每个表达式用 \`-e\` 选项指定。
- **用法**: 
  \`\`\`bash
  sed -e 'expression1' -e 'expression2' filename
  \`\`\`
- **示例**: 
  \`\`\`bash
  sed -e 's/Google/RedHeart/g' -e 's/Android/RedHeart OS/g' myfile.txt
  \`\`\`
  上述命令将在 \`myfile.txt\` 文件中同时将 "Google" 替换为 "RedHeart" 和 "Android" 替换为 "RedHeart OS"。

### 3. \`-E\` 选项

- **功能**: 该选项启用扩展正则表达式（ERE），允许使用更多的正则表达式特性，例如 \`+\`、\`?\`、\`|\` 等，而不需要使用反斜杠转义。
- **用法**: 
  \`\`\`bash
  sed -E 's/(Google|Android)/RedHeart/g' filename
  \`\`\`
- **示例**: 
  \`\`\`bash
  sed -E 's/([Gg]oogle|[Aa]ndroid)/RedHeart/g' myfile.txt
  \`\`\`
  上述命令将在 \`myfile.txt\` 文件中将 "Google"（无论大小写）和 "Android"（无论大小写）都替换为 "RedHeart"。

### 示例代码(不可直接使用)
\`\`\`bash
#!/bin/bash

# 要处理的文件名
input_file="your_file.txt"  # 将此处的 your_file.txt 替换为实际文件名
output_file="output_file.txt"  # 输出文件名

# 使用sed进行替换
sed -e 's/Google/RedHeart/g' \
    -e 's/[Aa]ndroid/RedHeart OS/g' \
    "$input_file" > "$output_file"

echo "替换完成，结果已保存到 $output_file"
\`\`\`

### 说明：

1. **\`input_file\`**: 将 \`your_file.txt\` 替换为您要处理的实际文件名。
2. **\`output_file\`**: 指定输出文件名，您可以根据需要修改。
3. **\`sed\` 命令**:
   - \`-e 's/Google/RedHeart/g'\`：将 "Google" 替换为 "RedHeart"，\`g\` 表示全局替换。
   - \`-e 's/[Aa]ndroid/RedHeart OS/g'\`：使用正则表达式匹配 "Android" 和 "android"，并将其替换为 "RedHeart OS"。
4. **输出**：结果将写入到指定的输出文件中。

### 总结

- **\`-i\`**: 直接在文件中进行修改。
- **\`-e\`**: 允许在同一命令中使用多个表达式。
- **\`-E\`**: 启用扩展正则表达式，使得正则表达式更强大和灵活。

这些选项的结合使用可以让 \`sed\` 更高效地处理文本替换和处理任务。
        `,
      markdownContentExpand: `
## \`sed\` 有许多重要的参数和选项，可以让您灵活地处理文本。以下是一些常用且重要的 \`sed\` 参数及其说明：

### 1. \`-n\` 选项

- **功能**: 抑制默认输出。使用 \`-n\` 时，\`sed\` 只会输出那些通过特定命令明确请求的行。
- **用法**:
  \`\`\`bash
  sed -n 's/old/new/p' filename
  \`\`\`
- **示例**: 
  \`\`\`bash
  sed -n 's/Google/RedHeart/p' myfile.txt
  \`\`\`
  这将只打印替换了 "Google" 的行。

### 2. \`-r\` 选项

- **功能**: 与 \`-E\` 相同，启用扩展正则表达式。该选项在某些版本的 \`sed\` 中使用。
- **用法**:
  \`\`\`bash
  sed -r 's/(Google|Android)/RedHeart/g' filename
  \`\`\`

### 3. \`-f\` 选项

- **功能**: 从文件中读取 \`sed\` 脚本。允许您将多个 \`sed\` 命令存储在一个文件中，然后通过该选项执行。
- **用法**:
  \`\`\`bash
  sed -f script.sed filename
  \`\`\`
- **示例**: 
  \`\`\`bash
  sed -f myscript.sed myfile.txt
  \`\`\`
  其中 \`myscript.sed\` 是包含多个 \`sed\` 命令的文件。

### 4. \`s\` 命令

- **功能**: 进行替换操作。基本格式为 \`s/pattern/replacement/flags\`。
- **用法**:
  \`\`\`bash
  sed 's/old/new/g' filename
  \`\`\`
- **示例**:
  - \`g\`: 全局替换。
  - \`p\`: 打印替换的行。
  
### 5. \`d\` 命令

- **功能**: 删除匹配的行。
- **用法**:
  \`\`\`bash
  sed '/pattern/d' filename
  \`\`\`
- **示例**:
  \`\`\`bash
  sed '/Google/d' myfile.txt
  \`\`\`
  这将删除所有包含 "Google" 的行。

### 6. \`a\` 和 \`i\` 命令

- **功能**: 分别在匹配行后面添加（append）或前面插入（insert）文本。
- **用法**:
  \`\`\`bash
  sed '/pattern/a\
  New line of text' filename
  \`\`\`
  \`\`\`bash
  sed '/pattern/i\
  New line of text' filename
  \`\`\`
- **示例**:
  \`\`\`bash
  sed '/Google/a\\This is a new line' myfile.txt
  \`\`\`

### 7. \`p\` 命令

- **功能**: 打印匹配的行。通常与 \`-n\` 选项一起使用。
- **用法**:
  \`\`\`bash
  sed -n '/pattern/p' filename
  \`\`\`

### 8. \`y\` 命令

- **功能**: 替换字符（transliterate）。相当于字符的映射。
- **用法**:
  \`\`\`bash
  sed 'y/abc/xyz/' filename
  \`\`\`
- **示例**:
  \`\`\`bash
  sed 'y/abc/xyz/' myfile.txt
  \`\`\`
  将 "a" 替换为 "x"，"b" 替换为 "y"，"c" 替换为 "z"。

### 9. \`-e\` 选项（再提一次）

- **功能**: 允许您在同一命令中使用多个表达式，使用方法如前所述。

### 10. 行号和地址

- **功能**: \`sed\` 允许您指定行号或地址范围来限制命令的应用。
- **用法**:
  \`\`\`bash
  sed '2,4s/old/new/g' filename  # 只替换第2到第4行
  \`\`\`
        `,
      markdownInterview: `
### 选择题

1. **\`sed\` 的基本功能是什么？**
   - A. 复制文件
   - B. 文本替换和处理
   - C. 文件权限管理
   - D. 网络请求

2. **以下哪个命令可以用 \`sed\` 替换文件中的所有 "apple" 为 "orange"？**
   - A. \`sed 's/apple/orange/' file.txt\`
   - B. \`sed 's/apple/orange/g' file.txt\`
   - C. \`sed 's/apple/orange/i' file.txt\`
   - D. \`sed 's/apple/orange/1' file.txt\`

3. **\`sed\` 的哪个选项用于直接修改文件？**
   - A. \`-i\`
   - B. \`-e\`
   - C. \`-n\`
   - D. \`-f\`

4. **使用 \`sed\` 从文件中删除所有空行，正确的命令是：**
   - A. \`sed '/^$/d' file.txt\`
   - B. \`sed 'd /^$/' file.txt\`
   - C. \`sed 's/^$//g' file.txt\`
   - D. \`sed 'g/^$/d' file.txt\`

5. **在 \`sed\` 中，\`s/old/new/g\` 中的 \`g\` 表示什么？**
   - A. 仅替换第一处
   - B. 全局替换
   - C. 生成新文件
   - D. 改变大小写

### 问答题

1. **解释 \`sed\` 中的流编辑（stream editing）概念。**

2. **如何使用 \`sed\` 删除文件中的特定行（例如，第3行）？**

3. **\`sed\` 和 \`awk\` 有什么区别？在什么情况下应该使用 \`sed\` 而不是 \`awk\`？**

4. **编写一个 \`sed\` 命令，将文件中的 "foo" 替换为 "bar"，但只在包含 "baz" 的行中进行替换。**

5. **解释 \`sed\` 中的地址范围（address range）概念，并举例说明。**

### 答案

1. **选择题**
   - 1. **B**
   - 2. **B**
   - 3. **A**
   - 4. **A**
   - 5. **B**

2. **问答题**
   - 1. 流编辑是一种在数据流上进行编辑的技术，\`sed\` 从标准输入（如文件或管道）读取文本流，并在不将其全部加载到内存中的情况下对其进行处理。它可以进行替换、删除、插入等操作，并将结果输出到标准输出或文件中。
   - 2. 可以使用命令 \`sed '3d' file.txt\` 来删除文件中的第3行。这里的 \`d\` 是删除命令。
   - 3. \`sed\` 是一个流编辑器，主要用于文本替换和处理，而 \`awk\` 是一个功能更强大的文本处理工具，适合于复杂的数据分析和报告生成。\`sed\` 更适合于简单的文本替换和删除操作，而 \`awk\` 适合于需要根据列进行操作的场景。
   - 4. 可以使用命令 \`sed '/baz/s/foo/bar/g' file.txt\`，这将只在包含 "baz" 的行中将 "foo" 替换为 "bar"。
   - 5. 地址范围是指在 \`sed\` 命令中指定操作的行的范围。可以使用行号或模式来定义地址范围。例如，\`sed '1,3d' file.txt\` 删除文件中的第1到第3行，而 \`sed '/start/,/end/d' file.txt\` 则删除从匹配 "start" 到匹配 "end" 的所有行。
`,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
