<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## du 命令详解

\`du\` 命令用于显示文件和目录的磁盘使用情况。它可以帮助您了解系统中各个文件和目录的大小，从而更好地管理磁盘空间。本文将详细介绍 \`du\` 命令的使用方法及其常见参数，并通过示例说明其用法。

### 基本语法

\`du\` 命令的基本语法如下：

\`\`\`bash
du [选项] [文件或目录]
\`\`\`

### 常用参数

#### 1. \`-h\` 参数

- \`-h\`：以人类可读的格式显示大小（例如 KB, MB, GB）。

\`\`\`bash
$ du -h /home/sre
\`\`\`

**输出：**

\`\`\`
4.0K    /home/sre/tmp
8.0K    /home/sre/docs
12K     /home/sre
\`\`\`

**解读：**
此命令以人类可读的格式显示 \`/home/sre\` 目录及其子目录的大小，便于快速查看和理解文件和目录的大小。

#### 2. \`-s\` 参数

- \`-s\`：仅显示每个指定目录的总大小，不显示子目录的大小。

\`\`\`bash
$ du -s /home/sre
\`\`\`

**输出：**

\`\`\`
12K     /home/sre
\`\`\`

**解读：**
此命令显示了 \`/home/sre\` 目录的总大小，不包括其子目录的详细信息。

#### 3. \`-a\` 参数

- \`-a\`：显示所有文件和目录的大小，而不仅仅是目录的大小。

\`\`\`bash
$ du -a /home/sre
\`\`\`

**输出：**

\`\`\`
4.0K    /home/sre/tmp/file1
4.0K    /home/sre/tmp/file2
8.0K    /home/sre/docs/file3
12K     /home/sre
\`\`\`

**解读：**
此命令显示了 \`/home/sre\` 目录及其所有文件和子目录的大小，提供了更详细的信息。

#### 4. \`--max-depth=N\` 参数

- \`--max-depth=N\`：限制显示的目录深度为 \`N\`。\`N\` 为目录层级的深度。

\`\`\`bash
$ du --max-depth=1 /home/sre
\`\`\`

**输出：**

\`\`\`
4.0K    /home/sre/tmp
8.0K    /home/sre/docs
12K     /home/sre
\`\`\`

**解读：**
此命令显示 \`/home/sre\` 目录及其第一层子目录的大小。\`--max-depth=1\` 表示只显示一层目录的大小。

#### 5. \`-c\` 参数

- \`-c\`：在输出末尾显示总计大小。

\`\`\`bash
$ du -c /home/sre
\`\`\`

**输出：**

\`\`\`
4.0K    /home/sre/tmp
8.0K    /home/sre/docs
12K     /home/sre
12K     总计
\`\`\`

**解读：**
此命令在输出中包含了 \`/home/sre\` 目录及其子目录的总大小。

### 实际示例

#### 查看目录大小

\`\`\`bash
$ du -sh /var/log
\`\`\`

**输出：**

\`\`\`
200M    /var/log
\`\`\`

**解读：**
此命令显示 \`/var/log\` 目录的总大小，便于快速了解日志文件所占用的磁盘空间。

#### 查看目录及其子目录的大小

\`\`\`bash
$ du -h --max-depth=2 /usr
\`\`\`

**输出：**

\`\`\`
4.0K    /usr/local/bin
8.0K    /usr/local
12K     /usr
\`\`\`

**解读：**
此命令显示 \`/usr\` 目录及其子目录的大小，最多显示到第二层级目录，帮助分析目录结构和磁盘使用情况。

#### 查看所有文件和目录的大小

\`\`\`bash
$ du -ah /home/sre | less
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令显示 \`/home/sre\` 目录及其所有文件和子目录的大小，并通过 \`less\` 分页显示，便于查看详细信息。

### 总结

\`du\` 命令是管理和监控磁盘空间的重要工具。通过不同的参数选项，您可以灵活地查看目录和文件的大小，从而更好地管理系统的磁盘资源。无论是查看总大小、限制显示深度，还是显示所有文件的大小，掌握 \`du\` 的用法都能帮助您高效地处理磁盘空间管理任务。

        `,
    };
  },
});
</script>
<style scoped></style>
