<template>
  <div class="diy-css">
    <TaskBoxLeftLineA
      task="文件破损-请恢复-审计部门随机审计要用到该文件-碰巧破损了。系统账户etc99，/opt/data/ETC.data.2023.09.24。备份路径为/nfs_backup/"
      source="安全审计中心"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="30" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineA from "../../base/TaskBoxLeftLineA.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdMd5sum",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineA,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10068",
      taskOS: "Rocky Linux",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
\`md5sum\` 是 Linux 和 Unix 系统中的一个常用工具，用于生成和验证文件的 **MD5** 哈希值（消息摘要）。  
MD5 是一种广泛使用的加密散列函数，主要用于验证数据的完整性。它在日常运维工作中有多种应用，以下是几种常见的用法和场景。

### 1. **文件完整性验证**
在运维工作中，传输文件（如软件包、配置文件、备份文件等）是非常常见的任务。为了确保文件在传输过程中没有损坏或被篡改，可以使用 \`md5sum\` 来生成文件的 MD5 校验和，并在传输后验证其一致性。

#### 示例：
首先在文件传输前生成源文件的 MD5 校验和：
\`\`\`bash
md5sum filename.tar.gz
\`\`\`
输出结果类似于：
\`\`\`
d41d8cd98f00b204e9800998ecf8427e  filename.tar.gz
\`\`\`

将文件传输到目标系统后，使用 \`md5sum\` 再次生成校验和并对比：
\`\`\`bash
md5sum filename.tar.gz
\`\`\`
如果输出的哈希值与源文件相同，则文件在传输过程中没有损坏。

### 2. **备份文件的一致性验证**
在备份和恢复过程中，\`md5sum\` 可用于验证备份文件在备份和恢复后的完整性，以防止数据丢失或损坏。

#### 示例：
创建备份时，生成备份文件的 MD5 校验和：
\`\`\`bash
tar -czf /backup/home_backup.tar.gz /home
md5sum /backup/home_backup.tar.gz > /backup/home_backup.md5
\`\`\`

在恢复时，使用 \`md5sum\` 检查备份文件是否保持完整：
\`\`\`bash
md5sum -c /backup/home_backup.md5
\`\`\`
输出结果：
\`\`\`
/backup/home_backup.tar.gz: OK
\`\`\`

### 3. **文件一致性检查**
对于多个相同的文件副本，例如在多台服务器上分发同一配置文件时，使用 \`md5sum\` 可以快速检查这些文件是否一致，确保它们没有发生修改。

#### 示例：
对比两台服务器上的相同文件：
\`\`\`bash
md5sum /etc/nginx/nginx.conf
\`\`\`
在每台服务器上运行并对比结果。如果 MD5 值一致，则文件相同。

### 4. **脚本自动化中的验证**
在自动化运维脚本中，可以使用 \`md5sum\` 来验证某些重要文件或包是否被意外修改。比如在部署脚本中，检测到文件的 MD5 值不一致时，可以触发重新下载或报警。

#### 示例：
\`\`\`bash
#!/bin/bash
expected_md5="d41d8cd98f00b204e9800998ecf8427e"
current_md5=$(md5sum /path/to/important_file | awk '{ print $1 }')

if [ "$expected_md5" != "$current_md5" ]; then
    echo "File has been modified, triggering alarm!"
    # 触发报警或重新下载文件
else
    echo "File is intact."
fi
\`\`\`

### 5. **软件验证**
运维中安装软件时，尤其是从网络下载的软件，通常会附带一个 MD5 校验值，用于验证下载的软件包是否完整。例如，当从官方网站下载 Linux 发行版 ISO 文件时，通常会提供一个 MD5 校验值，用户可以使用 \`md5sum\` 命令来验证下载的文件是否被篡改。

#### 示例：
下载后验证软件包：
\`\`\`bash
md5sum linux.iso
\`\`\`
将结果与官方提供的 MD5 校验和进行对比。如果一致，说明下载的文件未被篡改。

### 6. **diff命令比较文本文件差异**
\`\`\`bash
diff ETC.data.2023.09.24 /opt/data/ETC.data.2023.09.24
8c8
< 应扣费用: 425元
---
> 应扣费用: 0.5元
\`\`\`

### 总结
\`md5sum\` 在日常运维中应用广泛，主要用于以下场景：
- 确保文件在传输过程中的完整性。
- 备份与恢复过程中的一致性验证。
- 检查多个服务器上配置文件的统一性。
- 软件安装前后的完整性验证。
- 日志文件的完整性检查。

尽管 MD5 有已知的安全漏洞，并不适用于高安全性需求的场景，但在一般运维工作中，\`md5sum\` 仍然是一个非常实用的工具，尤其在确保文件一致性方面。

  `,
      markdownContentExpand: `
MD5（Message Digest Algorithm 5）确实存在已知的安全漏洞，尤其在密码学应用中。  
尽管 MD5 仍然在一些场景中用于验证文件完整性，但在安全性要求较高的场景下，它已被认为是不安全的，具体有以下几点原因：

### 1. **碰撞攻击（Collision Attack）**
MD5 的主要安全漏洞是容易受到**碰撞攻击**（collision attack）的影响。碰撞攻击是指找到两个不同的输入数据，它们在经过 MD5 哈希运算后产生相同的输出哈希值。由于 MD5 哈希值的长度为128位，哈希空间相对较小，理论上碰撞攻击在较短时间内就能找到这样的冲突。

- **实际影响**：攻击者可以伪造两个不同的数据（例如两个不同的文件或文档），使它们产生相同的 MD5 哈希值。这就意味着使用 MD5 校验哈希来验证文件真实性或完整性时，攻击者可能提供一个伪造的文件，而验证仍然通过。

- **历史事件**：早在2004年，研究人员就成功展示了 MD5 碰撞攻击的可能性，之后一些高级碰撞攻击技术继续被发现，导致该算法逐渐被弃用。

### 2. **快速计算**
MD5 算法设计得比较高效，现代计算机能够非常快速地计算哈希值。这一特性导致了另一个严重问题：MD5 哈希值可以通过暴力破解（brute-force attack）或者彩虹表攻击（rainbow table attack）轻易破解。

- **暴力破解**：由于计算速度快，攻击者可以在较短时间内枚举出所有可能的输入，直到找到与某个哈希值匹配的输入。
  
- **彩虹表攻击**：彩虹表是一种预计算的哈希值表，攻击者可以通过查找表来逆推出某个哈希值对应的原始输入。由于 MD5 计算速度快，彩虹表攻击在破解密码等场景中表现得非常高效。

### 3. **被弃用的安全标准**
由于上述问题，MD5 已经被许多安全标准和协议弃用。许多组织和机构，如 **NIST（美国国家标准与技术研究院）**，明确建议不再使用 MD5 作为加密哈希算法。MD5 已经被更安全的哈希算法，如 **SHA-256**（Secure Hash Algorithm 256-bit）取代。

### 使用 MD5 的风险
- 在**文件完整性验证**中，MD5 仍然可以用于简单场景，例如验证下载文件是否损坏，因为碰撞攻击的复杂性较高，通常需要大量计算资源。
- 但在需要高安全性的场景下（如密码存储、数字签名、证书验证等），使用 MD5 是不安全的，应该使用更安全的哈希算法，如 **SHA-256** 或 **SHA-3**。

### 替代方案
- **SHA-256**：一种更安全的散列算法，长度为256位，相较于 MD5，能提供更强的抗碰撞能力和抗暴力破解能力。
- **SHA-3**：一种新型的哈希算法，经过加密社区的广泛审查，并提供了更高的安全性。

### 总结
MD5 的安全性已经被证明存在重大漏洞，尤其在碰撞攻击和暴力破解方面。因此，虽然 MD5 仍然适用于某些文件完整性验证的简单场景，但在涉及安全性的重要任务中（如密码存储、数字签名、数据加密等），建议使用更现代化、更安全的哈希算法，例如 SHA-256。

  `,
      markdownInterview: ` 
### 选择题：

1. **md5sum 是用于以下哪项操作的工具？**
   - A. 压缩文件
   - B. 检查文件一致性
   - C. 文件加密
   - D. 文件备份

2. **下列哪个命令会生成文件 \`example.txt\` 的 MD5 哈希值并保存到 \`example.md5\` 文件中？**
   - A. \`md5sum example.txt > example.md5\`
   - B. \`md5sum example.txt >> example.md5\`
   - C. \`md5sum -c example.txt > example.md5\`
   - D. \`md5sum -t example.txt > example.md5\`

3. **以下哪种操作是使用 \`md5sum\` 来检查文件的一致性？**
   - A. \`md5sum -v example.txt\`
   - B. \`md5sum example.txt > example.md5\`
   - C. \`md5sum -c example.md5\`
   - D. \`md5sum -d example.txt\`

4. **在验证文件时，以下哪个选项可以使用 \`md5sum\` 验证多个文件？**
   - A. \`md5sum -v file1.txt file2.txt\`
   - B. \`md5sum file1.txt file2.txt > hash.md5\`
   - C. \`md5sum -c hash.md5\`
   - D. \`md5sum -l file1.txt file2.txt\`

5. **\`md5sum\` 的输出长度是多少？**
   - A. 32位
   - B. 64位
   - C. 128位
   - D. 256位

---

### 问答题：

1. **在运维中，使用 \`md5sum\` 来检查文件完整性时，实际应用场景是什么？请举例说明。**

2. **如果你要对一个文件夹中的所有文件生成 MD5 哈希值并验证其完整性，应该如何操作？请给出命令示例。**

3. **\`md5sum\` 在当前安全环境中有什么已知的安全风险？为什么建议在某些情况下不要使用 \`md5sum\`？**

4. **解释如何使用 \`md5sum\` 进行文件下载后的完整性检查。**

---

### 答案：

#### 选择题答案：
1. B
2. A
3. C
4. C
5. C

#### 问答题答案：
1. 在运维中，\`md5sum\` 经常用于检查文件在传输或备份过程中是否发生变化。一个常见的应用场景是软件包的下载和验证。例如，在从网络上下载一个软件包时，软件发布方通常提供一个 MD5 校验和文件，用户可以使用 \`md5sum -c\` 来验证下载的文件是否与原始文件一致。

2. 生成文件夹中所有文件的 MD5 哈希值：
   \`\`\`bash
   find /path/to/directory -type f -exec md5sum {} \\; > hash.md5
   \`\`\`
   验证所有文件的完整性：
   \`\`\`bash
   md5sum -c hash.md5
   \`\`\`

3. \`md5sum\` 的主要安全风险是其容易受到**碰撞攻击**的影响。这意味着攻击者可能生成两个不同的文件，但它们具有相同的 MD5 哈希值。这使得 \`md5sum\` 不再适用于安全敏感的应用，例如密码学签名或文件验证。

4. 在下载一个大文件时，发布者通常提供一个 \`md5sum\` 校验值。用户可以在下载完成后运行以下命令：
   \`\`\`bash
   md5sum downloaded_file
   \`\`\`
   然后将输出的 MD5 值与发布者提供的值进行比较，以确保文件没有损坏或被篡改。

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
