<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## depmod 命令详解

\`depmod\` 命令用于生成 Linux 内核模块的依赖关系列表，并根据这些依赖关系生成模块符号文件。\`depmod\` 命令通常在内核模块被安装或升级后使用，确保模块可以正确加载。本文将详细介绍 \`depmod\` 命令的使用方法，特别是一些重要的选项，并通过示例展示实际用法和输出结果。

### 基本语法

\`depmod\` 命令的基本语法如下：

\`\`\`bash
depmod [选项] [内核版本]
\`\`\`

- **内核版本**: 指定要处理的内核版本。如果省略，则默认处理当前正在运行的内核。

### 常用选项

#### 1. \`-a\` 或 \`--all\`

\`-a\` 或 \`--all\` 选项用于处理所有已安装的内核。这是 \`depmod\` 的默认行为。

\`\`\`bash
$ sudo depmod -a
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令生成所有已安装内核版本的模块依赖关系和符号文件。由于是默认行为，省略 \`-a\` 选项也可以达到相同效果。

#### 2. \`-n\` 或 \`--dry-run\`

\`-n\` 或 \`--dry-run\` 选项用于模拟命令的执行，但不做出任何实际更改。此选项常用于测试目的。

\`\`\`bash
$ sudo depmod -n
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令模拟了 \`depmod\` 的执行过程，但没有生成任何实际的依赖文件。通常用于检查命令的效果。

#### 3. \`-v\` 或 \`--verbose\`

\`-v\` 或 \`--verbose\` 选项用于输出更详细的过程信息。这个选项非常有用，可以帮助调试问题。

\`\`\`bash
$ sudo depmod -v
\`\`\`

**输出：**

\`\`\`
depmod: WARNING: could not open directory /lib/modules/5.4.0-72-generic: No such file or directory
depmod: FATAL: could not search modules: No such file or directory
\`\`\`

**解读：**
此输出显示了 \`depmod\` 命令执行过程中遇到的问题，详细列出了每个步骤的执行状态。如果所有步骤都成功完成，则不会有任何输出。

#### 4. \`-b\` 或 \`--basedir\`

\`-b\` 或 \`--basedir\` 选项用于指定基目录。该选项允许在不影响实际系统的情况下，对特定目录中的内核模块进行测试。

\`\`\`bash
$ sudo depmod -b /mnt/test-modules
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令在 \`/mnt/test-modules\` 目录下的内核模块上运行 \`depmod\`，不会影响系统的实际模块配置。

#### 5. \`-F\` 或 \`--filesyms\`

\`-F\` 或 \`--filesyms\` 选项用于指定内核符号文件，该文件通常是 \`/proc/kallsyms\`。这对于生成自定义模块的依赖关系非常有用。

\`\`\`bash
$ sudo depmod -F /proc/kallsyms
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令使用当前内核符号文件生成依赖关系。如果文件路径正确且权限合适，命令将成功执行且无任何输出。

### 示例操作

#### 1. 处理当前运行的内核

\`\`\`bash
$ sudo depmod
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令处理当前运行内核的模块依赖关系，生成所需的模块符号文件。

#### 2. 处理指定内核版本

\`\`\`bash
$ sudo depmod 5.4.0-72-generic
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令生成 \`5.4.0-72-generic\` 版本内核的模块依赖关系。指定内核版本对管理多个内核非常有用。

### 权限要求

\`depmod\` 通常需要 root 权限才能成功执行，尤其是在涉及到实际更改系统模块配置的情况下。如果以非 root 用户执行命令，可能会遇到权限拒绝错误。

### 总结

\`depmod\` 命令是管理 Linux 内核模块的重要工具。通过掌握 \`depmod\` 的不同选项，您可以灵活地生成模块依赖关系，确保内核模块在系统中的正确加载和运行。特别是在升级内核或安装新模块后，使用 \`depmod\` 确保系统的模块依赖关系正确是非常必要的。

        `,
    };
  },
});
</script>
<style scoped></style>
