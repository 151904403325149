<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## vim 命令详解

\`vim\` 是 Unix 和 Linux 系统中功能强大的文本编辑器，是 \`vi\` 编辑器的增强版本（Vi IMproved），因此 \`vim\` 也被称为“增强版的 vi”。相比 \`vi\`，\`vim\` 提供了语法高亮、插件支持、命令历史、撤销重做等更高级的功能。本文将详细介绍 \`vim\` 命令的使用方法、常用选项以及各类场景中的实际操作。

### 1. \`vim\` 命令概述
\`vim\` 是一款非常灵活的文本编辑器，它在命令行环境中运行，并且默认分为三种工作模式：
- **普通模式**：用于执行命令和导航。
- **插入模式**：用于输入文本内容。
- **命令模式**：用于保存、退出或执行特定命令。

#### 命令格式：
\`\`\`bash
vim [选项] 文件名
\`\`\`

#### 示例命令：
\`\`\`bash
vim /etc/hosts
\`\`\`
**输出：**
\`\`\`
"azlinux-prod-cn" 12L, 342C
\`\`\`
解读：\`vim\` 打开了 \`/etc/hosts\` 文件，该文件包含 12 行和 342 个字符。

### 2. \`vim\` 基本操作
#### 2.1 启动和退出 \`vim\`
在 \`vim\` 中，文件默认以普通模式打开。以下是进入和退出 \`vim\` 的几种方式：

- **启动 \`vim\`**：
  \`\`\`bash
  vim 文件名
  \`\`\`
  **示例：**
  \`\`\`bash
  vim /home/sre/testfile.txt
  \`\`\`
  **输出：**
  \`\`\`
  "testfile.txt" [New File]
  \`\`\`
  解读：\`vim\` 创建了一个名为 \`testfile.txt\` 的新文件。

- **保存并退出 \`vim\`**：
  在普通模式下输入 \`:wq\` 保存并退出文件。
  \`\`\`bash
  :wq
  \`\`\`
  **输出：**
  \`\`\`
  "testfile.txt" 3L, 58C written
  \`\`\`
  解读：\`testfile.txt\` 文件已保存，包含 3 行、58 个字符。

- **强制退出 \`vim\` 而不保存**：
  \`\`\`bash
  :q!
  \`\`\`
  【无输出，命令已成功执行】

#### 2.2 切换到插入模式
按 \`i\` 进入插入模式，开始编辑文件。插入模式用于输入文本。当需要保存内容或退出时，按 \`Esc\` 键回到普通模式。

示例命令：
\`\`\`bash
i
\`\`\`
【无输出，命令已成功执行】

#### 2.3 保存文件
输入 \`:w\` 来保存文件而不退出 \`vim\`。

示例命令：
\`\`\`bash
:w
\`\`\`
**输出：**
\`\`\`
"testfile.txt" 3L, 58C written
\`\`\`
解读：文件已保存，包含 3 行和 58 个字符。

### 3. 常见参数
#### 3.1 \`-R\` 只读模式
\`-R\` 参数可以让 \`vim\` 以只读模式打开文件，防止修改文件。

示例命令：
\`\`\`bash
vim -R /etc/passwd
\`\`\`
**输出：**
\`\`\`
"/etc/passwd" [readonly] 24L, 1210C
\`\`\`
解读：\`vim\` 以只读模式打开 \`/etc/passwd\` 文件，文件包含 24 行、1210 个字符。

#### 3.2 \`+\` 选项
使用 \`+\` 选项可以在打开文件时直接跳到文件的最后一行。

示例命令：
\`\`\`bash
vim + /var/log/syslog
\`\`\`
**输出：**
\`\`\`
"/var/log/syslog" [readonly] 1345L, 52510C
\`\`\`
解读：文件打开时直接跳到了最后一行，非常适合查看最新日志内容。

### 4. 安装方法
\`vim\` 通常默认包含在大多数 Linux 系统中。如果没有安装，可以通过以下命令进行安装：

#### Ubuntu 系统：
\`\`\`bash
sudo apt install vim
\`\`\`

#### RHEL/CentOS 系统：
\`\`\`bash
sudo yum install vim
\`\`\`

### 5. \`vim\` 的版本差异
\`vim\` 不同版本之间可能存在功能差异。例如，旧版本的 \`vim\` 可能不支持一些现代插件或高亮功能。可以使用以下命令查看 \`vim\` 的版本号：

\`\`\`bash
vim --version
\`\`\`

**输出：**
\`\`\`
VIM - Vi IMproved 8.2 (2019 Dec 12, compiled Mar 30 2022 12:00:00)
Included patches: 1-875, 1000, 1001
Compiled by sre@azlinux-prod-cn
\`\`\`
解读：此系统中安装的是 \`vim\` 8.2 版本。

### 6. 使用 root 权限的 \`vim\`
有时候需要编辑一些只有 root 用户能修改的系统文件，例如 \`/etc/shadow\` 文件。这时需要使用 \`sudo\` 提升权限。

示例命令：
\`\`\`bash
sudo vim /etc/shadow
\`\`\`
**输出：**
\`\`\`
"/etc/shadow" 44L, 1787C
\`\`\`
解读：\`/etc/shadow\` 文件成功打开，文件包含 44 行，1787 个字符。

### 7. 多用户操作中的 \`vim\`
在多用户系统中，管理员可能需要查看不同用户的文件。例如，用户 \`dev01\` 和 \`app01\` 的文件可以通过以下命令进行操作：

#### 示例：
\`\`\`bash
sudo -u dev01 vim /home/dev01/project.txt
\`\`\`
**输出：**
\`\`\`
"/home/dev01/project.txt" [readonly] 10L, 320C
\`\`\`
解读：以用户 \`dev01\` 的身份打开其文件，默认以只读模式。

### 8. 替代命令
虽然 \`vim\` 功能强大，但如果系统上没有 \`vim\`，可以使用 \`nano\` 编辑器替代，特别是在 \`vim\` 不方便使用时。以下是 \`nano\` 的替代命令：

\`\`\`bash
nano /etc/hosts
\`\`\`
**输出：**
\`\`\`
GNU nano 5.4        File: /etc/hosts
\`\`\`
解读：\`nano\` 是更简单的文本编辑器，但功能不如 \`vim\` 强大。

### 结论
\`vim\` 是一款非常强大的文本编辑器，适用于各类文本文件的编辑操作。通过合理掌握它的基本功能和命令，用户可以极大提高编辑效率。在日常使用中，结合 \`vim\` 的各类选项和插件扩展，可以轻松应对复杂的文本编辑需求。
        `,
    };
  },
});
</script>
<style scoped></style>
