<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## tar 命令详解

\`tar\`（tape archive）命令是用于将多个文件和目录打包成一个归档文件的工具。它常用于备份和恢复数据，以及将文件和目录传输到其他系统。

### 基本用法

\`tar\`命令的基本语法如下：

\`\`\`bash
tar [OPTION...] [FILE...]
\`\`\`

### 常用参数

- \`-c\`：创建新的归档文件。
- \`-x\`：从归档文件中提取文件。
- \`-t\`：列出归档文件的内容。
- \`-f\`：指定归档文件名。此选项必须紧跟在其他选项后面。
- \`-v\`：显示详细的操作过程（verbose）。
- \`-z\`：通过\`gzip\`进行压缩。
- \`-j\`：通过\`bzip2\`进行压缩。
- \`-J\`：通过\`xz\`进行压缩。
- \`-C\`：指定解压缩的目标目录。

### 示例用法

#### 创建归档文件

创建一个名为\`archive.tar\`的归档文件，包含\`dir1\`和\`file1.txt\`：

\`\`\`bash
tar -cvf archive.tar dir1 file1.txt
\`\`\`

**输出：**

\`\`\`
dir1/
dir1/file2.txt
file1.txt
\`\`\`

**解读：** 使用\`-c\`选项创建新的归档文件，\`-v\`选项显示详细过程，\`-f\`指定归档文件名。输出显示了被归档的文件和目录。

#### 查看归档文件内容

查看\`archive.tar\`归档文件的内容：

\`\`\`bash
tar -tvf archive.tar
\`\`\`

**输出：**

\`\`\`
drwxr-xr-x  2 sre sre-group 4096 Sep 10 12:00 dir1/
-rw-r--r--  1 sre sre-group   23 Sep 10 12:01 file1.txt
\`\`\`

**解读：** 使用\`-t\`选项列出归档文件的内容，\`-v\`选项显示详细信息，\`-f\`指定归档文件名。输出显示归档文件中包含的文件和目录及其权限。

#### 提取归档文件

从\`archive.tar\`中提取文件到当前目录：

\`\`\`bash
tar -xvf archive.tar
\`\`\`

**输出：**

\`\`\`
dir1/
dir1/file2.txt
file1.txt
\`\`\`

**解读：** 使用\`-x\`选项从归档文件中提取文件，\`-v\`选项显示详细过程，\`-f\`指定归档文件名。输出显示了被提取的文件和目录。

#### 创建压缩归档文件

使用\`gzip\`压缩创建归档文件：

\`\`\`bash
tar -czvf archive.tar.gz dir1 file1.txt
\`\`\`

**输出：**

\`\`\`
dir1/
dir1/file2.txt
file1.txt
\`\`\`

**解读：** 使用\`-z\`选项通过\`gzip\`压缩归档文件，\`-c\`选项创建新的归档，\`-v\`选项显示详细过程，\`-f\`指定归档文件名。输出显示了被归档的文件和目录。

#### 提取压缩归档文件

从\`archive.tar.gz\`中提取文件：

\`\`\`bash
tar -xzvf archive.tar.gz
\`\`\`

**输出：**

\`\`\`
dir1/
dir1/file2.txt
file1.txt
\`\`\`

**解读：** 使用\`-x\`选项提取文件，\`-z\`选项解压缩，\`-v\`选项显示详细过程，\`-f\`指定归档文件名。输出显示了被提取的文件和目录。

### 版本差异

不同版本的\`tar\`可能会在选项或功能上有所不同。通常情况下，基本用法和常用选项在各个版本之间是一致的。如果发现版本差异，可以查阅\`tar\`的手册页（\`man tar\`）或使用\`tar --version\`命令查看当前版本。

### 安全性和替代命令

\`tar\`命令本身没有被弃用，它是标准的归档工具。如果需要更高效的压缩算法或功能，可以考虑\`pigz\`（并行gzip）等工具。

### 安装

在Ubuntu和RHEL系统上，\`tar\`通常是预装的。如果需要安装，可以使用以下命令：

- **Ubuntu：**

  \`\`\`bash
  sudo apt-get install tar
  \`\`\`

- **RHEL：**

  \`\`\`bash
  sudo yum install tar
  \`\`\`

### 权限使用

创建和提取归档文件不需要特别的root权限，但如果操作的文件或目录需要root权限，则需要以root用户运行\`tar\`命令。

**示例：**

\`\`\`bash
sudo tar -cvf /root/archive.tar /var/log
\`\`\`

**输出：**

\`\`\`
/var/log/
/var/log/syslog
/var/log/auth.log
\`\`\`

**解读：** 使用\`sudo\`以root权限运行\`tar\`命令来归档需要root权限访问的目录。

        `,
    };
  },
});
</script>
<style scoped></style>
