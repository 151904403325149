<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## df 命令详解

\`df\` 命令用于显示文件系统的磁盘空间使用情况。它提供了有关磁盘总量、已用空间和可用空间的信息，对于磁盘管理和维护非常有用。本文将详细介绍 \`df\` 命令的使用方法及其常见参数，并通过示例说明其用法。

### 基本语法

\`df\` 命令的基本语法如下：

\`\`\`bash
df [选项] [文件或目录]
\`\`\`

### 常用参数

#### 1. \`-h\` 参数

- \`-h\`：以人类可读的格式显示磁盘使用情况（例如 KB, MB, GB）。

\`\`\`bash
$ df -h
\`\`\`

**输出：**

\`\`\`
Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
tmpfs            16G  1.2G   15G   8% /dev/shm
/dev/sdb1       100G  45G   50G  48% /data
\`\`\`

**解读：**
此命令以人类可读的格式显示所有挂载的文件系统的磁盘使用情况。输出包括文件系统、大小、已用空间、可用空间、使用百分比以及挂载点。

#### 2. \`-T\` 参数

- \`-T\`：显示文件系统类型。

\`\`\`bash
$ df -T
\`\`\`

**输出：**

\`\`\`
Filesystem     Type     1K-blocks    Used Available Use% Mounted on
/dev/sda1      ext4     52428800 31457280  18874368  62% /
tmpfs           tmpfs    16777216  1258296 15718920   8% /dev/shm
/dev/sdb1      ext4    104857600 47185920  52428800  48% /data
\`\`\`

**解读：**
此命令显示了每个文件系统的类型（例如 \`ext4\`, \`tmpfs\`），以及文件系统的大小、已用空间和可用空间等信息。

#### 3. \`-i\` 参数

- \`-i\`：显示文件系统的 inode 使用情况，而不是磁盘空间使用情况。

\`\`\`bash
$ df -i
\`\`\`

**输出：**

\`\`\`
Filesystem      Inodes IUsed  IFree IUse% Mounted on
/dev/sda1       3276800 204800 3072000   7% /
tmpfs            4194304  1220 4193084   1% /dev/shm
/dev/sdb1       6553600  1024 6552576   1% /data
\`\`\`

**解读：**
此命令显示了每个文件系统的 inode 使用情况。输出包括 inode 总数、已用 inode、剩余 inode 以及 inode 使用百分比。

#### 4. \`--total\` 参数

- \`--total\`：在输出末尾显示所有文件系统的总使用情况。

\`\`\`bash
$ df --total
\`\`\`

**输出：**

\`\`\`
Filesystem     Size  Used Avail Use% Mounted on
/dev/sda1      50G   30G   18G  62% /
tmpfs           16G  1.2G   15G   8% /dev/shm
/dev/sdb1      100G  45G   50G  48% /data
tmpfs           16G  1.2G   15G   8% /run
tmpfs           16G  1.2G   15G   8% /sys/fs/cgroup
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
tmpfs           16G  1.2G   15G   8% /var/lib/docker/overlay2
total          464G  68G  343G  13%
\`\`\`

**解读：**
此命令在输出的最后一行显示了所有文件系统的总磁盘使用情况，帮助用户快速查看总体磁盘使用情况。

### 实际示例

#### 查看根文件系统的磁盘使用情况

\`\`\`bash
$ df -h /
\`\`\`

**输出：**

\`\`\`
Filesystem     Size  Used Avail Use% Mounted on
/dev/sda1      50G   30G   18G  62% /
\`\`\`

**解读：**
此命令显示了根文件系统 (\`/\`) 的磁盘使用情况。它显示了根文件系统的总大小、已用空间、可用空间以及使用百分比。

#### 查看指定目录的磁盘使用情况

\`\`\`bash
$ df -h /data
\`\`\`

**输出：**

\`\`\`
Filesystem     Size  Used Avail Use% Mounted on
/dev/sdb1     100G   45G   50G  48% /data
\`\`\`

**解读：**
此命令显示了 \`/data\` 目录所在文件系统的磁盘使用情况。

### 总结

\`df\` 命令是监控和管理磁盘空间的重要工具。通过不同的参数选项，您可以查看磁盘使用情况、文件系统类型、inode 使用情况，以及总体磁盘使用情况。掌握 \`df\` 的用法将帮助您更好地管理磁盘资源和进行系统维护。

        `,
    };
  },
});
</script>
<style scoped></style>
