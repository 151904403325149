<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## pstree 命令详解

\`pstree\` 是一个用来以树状图形式展示进程的工具。它可以帮助用户查看进程及其子进程的关系，通常用于系统管理和故障排查。

### 基本用法

\`pstree\` 命令展示系统中所有进程及其层级结构。它以树形结构显示进程及其父进程之间的关系。命令格式如下：

\`\`\`bash
pstree [options] [pid...]
\`\`\`

其中 \`[pid...]\` 是可选的，表示要查看的特定进程ID。常见选项包括：

- \`-p\`：显示进程ID
- \`-u\`：显示进程的用户
- \`-a\`：显示进程的命令行参数
- \`-h\`：高亮当前进程
- \`-n\`：按进程号排序

### 示例和输出

#### 示例 1: 基本用法

\`\`\`bash
pstree
\`\`\`

**输出示例：**

\`\`\`plaintext
init─┬─cron
     ├─sshd─┬─sshd───bash─┬─pstree
     │       │            └─vim
     │       └─sshd───bash─┬─vim
     │                    └─less
     ├─systemd─┬─dbus-daemon
     │         ├─gnome-shell─┬─gnome-terminal─┬─bash
     │         │             │               └─pstree
     │         │             └─gnome-shell
     │         └─systemd-journal
\`\`\`

**解读：**

- \`init\` 是系统的根进程。
- \`init\` 启动了多个子进程，例如 \`cron\` 和 \`sshd\`。
- \`sshd\` 进一步启动了多个进程，包括 \`bash\` 和 \`vim\`。
- \`gnome-shell\` 也是一个重要的进程，管理着用户界面及其子进程。

#### 示例 2: 显示进程ID

\`\`\`bash
pstree -p
\`\`\`

**输出示例：**

\`\`\`plaintext
init(1)─┬─cron(1234)
        ├─sshd(5678)─┬─sshd(9101)───bash(1112)───pstree(1314)
        │            └─sshd(5679)───bash(1516)───vim(1718)
        ├─systemd(1920)─┬─dbus-daemon(2122)
        │               ├─gnome-shell(2324)─┬─gnome-terminal(2526)─┬─bash(2728)───pstree(2930)
        │               │                   └─gnome-shell(2325)
        │               └─systemd-journal(3132)
\`\`\`

**解读：**

- 在树状结构中，每个进程ID都被显示在进程名称旁边，便于识别。

#### 示例 3: 显示进程的用户

\`\`\`bash
pstree -u
\`\`\`

**输出示例：**

\`\`\`plaintext
init─┬─cron (root)
     ├─sshd (root)─┬─sshd (user)─┬─bash (user)─┬─pstree (user)
     │              │             │            └─vim (user)
     │              └─sshd (user)─┬─bash (user)───vim (user)
     │                          └─less (user)
     ├─systemd (root)─┬─dbus-daemon (messagebus)
     │                ├─gnome-shell (user)─┬─gnome-terminal (user)─┬─bash (user)───pstree (user)
     │                │                   └─gnome-shell (user)
     │                └─systemd-journal (root)
\`\`\`

**解读：**

- 每个进程旁边显示的用户名帮助识别哪个用户启动了进程。

### 不同架构和版本的输出

\`pstree\` 在不同架构和版本上的输出通常一致，但在某些特定系统上，可能会有微小的差异。这里以 x86_64 和 aarch64 架构为例：

- **x86_64**: 输出与示例一致。
- **aarch64**: 输出通常与 x86_64 相似。

### 命令安装

如果系统上没有安装 \`pstree\`，可以通过以下方式安装：

- **Ubuntu**: 安装包为 \`pstree\`，可以通过以下命令安装：
  \`\`\`bash
  sudo apt-get install pstree
  \`\`\`
- **RHEL**: 安装包为 \`pstree\`，可以通过以下命令安装：
  \`\`\`bash
  sudo yum install pstree
  \`\`\`

### 替代命令

\`pstree\` 是一个专门的进程树显示工具，目前没有直接的替代命令。但 \`ps\` 命令可以用来查看进程信息，虽然不如 \`pstree\` 直观：

\`\`\`bash
ps aux
\`\`\`

### 需要 root 权限的选项

\`pstree\` 本身不需要 root 权限，但某些情况下，查看某些进程的详细信息可能需要 root 权限。可以使用 \`sudo\` 来运行：

\`\`\`bash
sudo pstree
\`\`\`

**示例输出：**

\`\`\`plaintext
root─┬─cron
     ├─sshd─┬─sshd───bash─┬─pstree
     │       │            └─vim
     │       └─sshd───bash─┬─vim
     │                    └─less
     ├─systemd─┬─dbus-daemon
     │         ├─gnome-shell─┬─gnome-terminal─┬─bash
     │         │             │               └─pstree
     │         │             └─gnome-shell
     │         └─systemd-journal
\`\`\`

**解读：**

- 使用 \`sudo\` 可以查看所有进程，包括系统进程和其他用户的进程。

        `,
    };
  },
});
</script>
<style scoped></style>
