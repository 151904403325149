<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## declare 命令详解

\`declare\` 是 Bash 中的一个内置命令，用于声明变量并设置其属性。它可以用来定义变量类型、限制变量的范围以及其他属性。\`declare\` 主要用于在脚本中控制变量的行为，使得脚本更加可靠和可维护。

### 基本用法

#### 声明变量

\`\`\`bash
declare var="value"
\`\`\`

**示例输出**

\`\`\`bash
$ declare var="value"
$ echo $var
value
\`\`\`

**输出解读**:

- \`var\` 被声明并赋值为 \`"value"\`。
- 使用 \`echo\` 命令可以查看变量的值。

### 常用选项

#### 1. \`-r\`（只读）

将变量设置为只读，无法修改。

\`\`\`bash
declare -r myvar="readonly"
\`\`\`

**示例输出**

\`\`\`bash
$ declare -r myvar="readonly"
$ echo $myvar
readonly
$ myvar="newvalue"  # 尝试修改只读变量
-bash: myvar: cannot assign value
\`\`\`

**输出解读**:

- \`myvar\` 被设置为只读。
- 尝试修改只读变量会导致错误。

#### 2. \`-a\`（数组）

声明一个数组变量。

\`\`\`bash
declare -a myarray
myarray=(one two three)
\`\`\`

**示例输出**

\`\`\`bash
$ declare -a myarray
$ myarray=(one two three)
$ echo \${myarray[@]}
one two three
\`\`\`

**输出解读**:

- \`myarray\` 被声明为数组，并赋值为 \`one\`, \`two\`, \`three\`。
- 使用 \`\${myarray[@]}\` 可以查看数组的所有元素。

#### 3. \`-i\`（整数）

声明一个整数变量，变量值会被自动转换为整数。

\`\`\`bash
declare -i num=10
\`\`\`

**示例输出**

\`\`\`bash
$ declare -i num=10
$ echo $num
10
$ num="20.5"
$ echo $num
20
\`\`\`

**输出解读**:

- \`num\` 被声明为整数。
- 字符串 \`"20.5"\` 被自动转换为整数 \`20\`。

#### 4. \`-x\`（导出）

将变量导出到子进程中。

\`\`\`bash
declare -x myexport="exported"
\`\`\`

**示例输出**

\`\`\`bash
$ declare -x myexport="exported"
$ echo $myexport
exported
\`\`\`

**输出解读**:

- \`myexport\` 被导出，子进程也能访问该变量。

#### 5. \`-p\`（打印）

打印变量及其属性。

\`\`\`bash
declare -p myvar
\`\`\`

**示例输出**

\`\`\`bash
$ declare -r myvar="readonly"
$ declare -p myvar
declare -r myvar="readonly"
\`\`\`

**输出解读**:

- 显示了变量 \`myvar\` 的声明及其属性。

### 内置命令与外部命令

\`declare\` 是 Bash 的内置命令，用于在 Bash 脚本中声明和控制变量。它不是一个外部命令，位于 Bash 解释器内部，不需要安装或调用外部程序。

### 不同架构下的输出

\`declare\` 命令的输出在不同架构下是一致的，因为它依赖于 Bash 的内部实现，而不是硬件架构。

### 版本差异

\`declare\` 命令的基本功能在不同版本的 Bash 中通常是一致的。但某些高级功能或选项可能在不同版本中有所不同。建议使用最新版本的 Bash 以获得最新的功能和修复。

### 替代命令

- \`typeset\`：在早期版本的 Bash 和一些其他 Shell 中，可以用来声明变量。\`typeset\` 在功能上与 \`declare\` 类似，但 \`declare\` 更加一致和通用。

### 安装

\`declare\` 是 Bash 的一部分，因此安装 Bash 即可获得 \`declare\` 命令。如果系统中未安装 Bash，可以通过以下命令进行安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install bash
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install bash
  \`\`\`

### 需要 root 权限的选项

\`declare\` 命令本身不需要 root 权限。大多数操作可以由普通用户执行，但在一些情况下，如更改系统级别的环境变量，可能需要 root 权限。

**示例**

\`\`\`bash
sudo declare -x SYSTEM_VAR="system_value"
\`\`\`

**示例输出**:

\`\`\`bash
$ sudo declare -x SYSTEM_VAR="system_value"
$ echo $SYSTEM_VAR
system_value
\`\`\`

### 总结

\`declare\` 是一个功能强大的 Bash 内置命令，用于声明和控制变量的属性。通过使用 \`declare\`，用户可以控制变量的类型、读写权限、导出状态等，使得 Bash 脚本更具灵活性和可靠性。了解并善用 \`declare\` 命令有助于编写更为健壮和易维护的脚本。

        `,
    };
  },
});
</script>
<style scoped></style>
