<template>
  <div class="markdown-body" v-html="renderedMarkdown" />
</template>

<script>
import { defineComponent, computed } from "vue";
import MarkdownIt from "markdown-it";
import "github-markdown-css/github-markdown-light.css";
import "../../assets/styles/sre-markdown.css";

export default defineComponent({
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const md = new MarkdownIt();
    const renderedMarkdown = computed(() => md.render(props.markdown));

    return { renderedMarkdown };
  },
});
</script>

<style scoped>
.markdown-body {
  text-align: left;
}
</style>
