<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## timedatectl 命令详解

\`timedatectl\` 是一个用于管理系统时间和日期的命令，属于 \`systemd\` 系统的一部分。它提供了获取和设置系统时间、日期、时区和网络时间同步的功能。

### 安装

在大多数现代 Linux 发行版中，\`timedatectl\` 已经预装，作为 \`systemd\` 的一部分。因此通常不需要单独安装。

### 基本语法

\`\`\`bash
timedatectl [选项] [COMMAND] [参数]
\`\`\`

### 常用选项和命令

- \`status\`：显示当前系统的时间和日期信息。
- \`set-time\`：设置系统时间。
- \`set-timezone\`：设置时区。
- \`set-ntp\`：启用或禁用网络时间同步。
- \`list-timezones\`：列出可用的时区。

### 示例用法

#### 示例一：查看当前时间和日期

要查看当前系统的时间和日期，可以使用以下命令：

\`\`\`bash
timedatectl status
\`\`\`

**输出示例**：

\`\`\`plaintext
                      Local time: Tue 2024-09-25 12:00:00 CST
                  Universal time: Tue 2024-09-25 04:00:00 UTC
                        RTC time: Tue 2024-09-25 04:00:00
                       Time zone: Asia/Shanghai (CST, +0800)
       System clock synchronized: yes
\`\`\`

**解读**：该命令显示了本地时间、协调世界时间 (UTC)、RTC 时间、时区信息以及系统时钟是否已同步。

#### 示例二：设置系统时间

可以通过 \`set-time\` 命令设置系统时间：

\`\`\`bash
sudo timedatectl set-time '2024-09-25 13:00:00'
\`\`\`

**输出示例**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

**解读**：此命令将系统时间设置为 2024 年 9 月 25 日 13:00:00。没有输出表明命令已成功执行。

#### 示例三：设置时区

要设置系统时区，可以使用 \`set-timezone\` 命令：

\`\`\`bash
sudo timedatectl set-timezone Asia/Shanghai
\`\`\`

**输出示例**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

**解读**：该命令将系统时区设置为上海。没有输出表明命令已成功执行。

#### 示例四：启用网络时间同步

要启用网络时间同步，可以使用 \`set-ntp\` 命令：

\`\`\`bash
sudo timedatectl set-ntp true
\`\`\`

**输出示例**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

**解读**：该命令启用了网络时间同步功能。没有输出表明命令已成功执行。

#### 示例五：列出可用的时区

要列出系统支持的所有时区，可以使用 \`list-timezones\` 命令：

\`\`\`bash
timedatectl list-timezones
\`\`\`

**输出示例**：

\`\`\`plaintext
Africa/Abidjan
Africa/Accra
Africa/Addis_Ababa
...
Asia/Shanghai
...
\`\`\`

**解读**：该命令列出了所有可用的时区，包括 "Asia/Shanghai"。由于输出较长，这里仅展示了一部分。

### 不同架构的输出

\`timedatectl\` 命令在 aarch64 和 x86_64/amd64 架构上的输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

不同版本的 \`systemd\` 可能导致 \`timedatectl\` 的某些功能或输出有所不同。可以通过以下命令检查版本：

\`\`\`bash
timedatectl --version
\`\`\`

**输出示例**：

\`\`\`plaintext
timedatectl 247 (ubuntu)
\`\`\`

### 权限要求

大多数 \`timedatectl\` 命令操作需要 \`root\` 权限。例如，设置时间和时区时需要使用 \`sudo\`：

\`\`\`bash
sudo timedatectl set-timezone Asia/Shanghai
\`\`\`

**示例输出**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 替代命令

\`timedatectl\` 是现代 Linux 系统管理时间和日期的推荐命令，暂时没有被替代的命令。在较旧的系统中，可以使用 \`date\` 和 \`hwclock\` 命令来管理时间。

### 总结

\`timedatectl\` 命令是一个强大且灵活的工具，用于管理系统时间、日期和时区。通过掌握 \`timedatectl\` 的使用，可以有效地配置和维护系统的时间设置，确保系统和应用程序的正常运行。

        `,
    };
  },
});
</script>
<style scoped></style>
