<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## diff 命令详解

\`diff\` 命令是一个用于比较文件内容并显示差异的工具。它可以用来比较两个文件的内容，查看它们之间的不同之处。\`diff\` 命令通常用于源代码管理和文件同步等场景。

### 基本用法

\`\`\`bash
diff [options] file1 file2
\`\`\`

- \`[options]\`：指定命令选项。
- \`file1\`：第一个待比较的文件。
- \`file2\`：第二个待比较的文件。

**示例**

假设有两个文件 \`file1.txt\` 和 \`file2.txt\`，内容如下：

**file1.txt**

\`\`\`txt
apple
banana
cherry
\`\`\`

**file2.txt**

\`\`\`txt
apple
blueberry
cherry
\`\`\`

使用 \`diff\` 命令比较这两个文件：

\`\`\`bash
$ diff file1.txt file2.txt
\`\`\`

**示例输出**

\`\`\`bash
2c2
< banana
---
> blueberry
\`\`\`

**输出解读**:

- \`2c2\`：表示在文件 \`file1.txt\` 的第 2 行和文件 \`file2.txt\` 的第 2 行之间有差异。
- \`< banana\`：表示 \`file1.txt\` 中的内容是 \`banana\`。
- \`> blueberry\`：表示 \`file2.txt\` 中的内容是 \`blueberry\`。

### 常用选项

#### 1. \`-u\`（统一格式）

以统一格式显示差异，这种格式包含了上下文信息，更易于阅读。

\`\`\`bash
$ diff -u file1.txt file2.txt
\`\`\`

**示例输出**

\`\`\`bash
--- file1.txt
+++ file2.txt
@@ -1,3 +1,3 @@
 apple
-banana
+blueberry
 cherry
\`\`\`

**输出解读**:

- \`--- file1.txt\` 和 \`+++ file2.txt\` 分别表示两个文件的版本。
- \`@@ -1,3 +1,3 @@\` 表示差异发生在文件的第 1 行到第 3 行。
- \`-banana\` 表示 \`file1.txt\` 中的 \`banana\` 被删除。
- \`+blueberry\` 表示 \`file2.txt\` 中的 \`blueberry\` 被添加。

#### 2. \`-c\`（上下文格式）

以上下文格式显示差异，显示更多的上下文信息。

\`\`\`bash
$ diff -c file1.txt file2.txt
\`\`\`

**示例输出**

\`\`\`bash
*** file1.txt   2024-09-10 10:00:00.000000000 +0000
--- file2.txt   2024-09-10 10:00:00.000000000 +0000
***************
*** 1,3 ****
  apple
- banana
  cherry
--- 1,3 ----
  apple
+ blueberry
  cherry
\`\`\`

**输出解读**:

- \`*** file1.txt\` 和 \`--- file2.txt\` 分别表示文件的版本。
- \`***************\` 分隔符用于显示不同之处。
- \`- banana\` 和 \`+ blueberry\` 显示了不同的内容。

#### 3. \`-r\`（递归）

递归比较目录中的文件。

\`\`\`bash
$ diff -r dir1 dir2
\`\`\`

**示例输出**

\`\`\`bash
diff -r dir1/file1.txt dir2/file1.txt
2c2
< banana
---
> blueberry
\`\`\`

**输出解读**:

- 比较了 \`dir1\` 和 \`dir2\` 目录中的文件，并显示了文件 \`file1.txt\` 的差异。

### 内置命令与外部命令

\`diff\` 是一个外部命令，通常包含在 GNU diffutils 包中。它不是 Bash 内置命令，但在许多 Unix 和类 Unix 系统中预装。

### 不同架构下的输出

\`diff\` 命令的输出在不同架构下是一致的，因为它依赖于工具的实现，而不是硬件架构。

### 版本差异

不同版本的 \`diff\` 可能会有不同的功能或改进。新版本可能支持更多的选项或提供更好的输出格式。可以通过 \`diff --version\` 查看当前版本。

**示例**

\`\`\`bash
$ diff --version
diff (GNU diffutils) 3.8
\`\`\`

### 替代命令

- \`cmp\`：用于逐字节比较两个文件，适合二进制文件。
- \`sdiff\`：用于以侧边方式显示两个文件的差异，便于合并。

### 安装

\`diff\` 命令是 GNU diffutils 包的一部分。要安装 \`diff\`，需要安装 diffutils 包：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install diffutils
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install diffutils
  \`\`\`

### 需要 root 权限的选项

\`diff\` 命令本身不需要 root 权限。普通用户可以比较任何他们有权限访问的文件。如果要比较系统目录中的文件，可能需要使用 \`sudo\`：

**示例**

\`\`\`bash
sudo diff /etc/passwd /etc/group
\`\`\`

**示例输出**

\`\`\`bash
1c1
< root:x:0:0:root:/root:/bin/bash
---
> root:x:0:0:root:/root:/bin/dash
\`\`\`

**输出解读**:

- 使用 \`sudo\` 比较系统文件 \`/etc/passwd\` 和 \`/etc/group\`，显示了它们的差异。

### 总结

\`diff\` 命令是一个强大的工具，用于比较文件和目录之间的差异。通过使用 \`diff\` 的各种选项，用户可以获取详细的差异信息，并根据需要进行进一步的操作。掌握 \`diff\` 命令可以帮助用户有效地管理和比较文件内容。

        `,
    };
  },
});
</script>
<style scoped></style>
