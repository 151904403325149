<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## users 命令详解

\`users\` 命令用于显示当前系统上登录的用户列表。它提供了一种简单的方式来查看哪些用户正在活动中。

### 命令简介

\`users\` 命令是一个简单的工具，不接受任何参数，输出当前系统上所有登录用户的用户名，以空格分隔。它通常用来快速检查哪些用户在系统上登录。

### 语法

\`\`\`bash
users
\`\`\`

### 主要参数

\`users\` 命令没有额外的参数或选项。其输出是一个简单的用户列表。

### 使用示例

#### 示例 1: 基本使用

\`\`\`bash
$ users
sre dev01 app01 mon_user
\`\`\`

**输出解释**:
在这个示例中，\`users\` 命令列出了当前系统上登录的所有用户，用户名之间用空格分隔。可以看出，用户 \`sre\`、\`dev01\`、\`app01\` 和 \`mon_user\` 正在登录。

#### 示例 2: 与其他命令配合使用

\`users\` 命令可以与其他命令结合使用，以获取更详细的信息。例如，结合 \`who\` 命令可以查看具体的登录信息。

\`\`\`bash
$ who | grep $(users)
sre      tty7         2024-09-10 09:03 (:0)
dev01    pts/0        2024-09-10 09:05 (:0)
app01    pts/1        2024-09-10 09:07 (:0)
mon_user pts/2        2024-09-10 09:10 (:0)
\`\`\`

**输出解释**:
在这个示例中，\`who\` 命令显示了详细的用户登录信息，\`grep\` 通过 \`users\` 命令的输出筛选出正在登录的用户。

### 版本差异

\`users\` 命令的输出在不同的系统或不同版本上可能会有所不同，但其基本功能和输出格式通常是一致的。此命令没有版本参数，因此不会有版本间的差异。

### 替代命令

如果 \`users\` 命令不可用，可以使用其他命令来获取类似的信息，例如 \`who\` 或 \`w\` 命令。这些命令提供了有关登录用户的更多详细信息。

- \`who\` 命令: 显示当前登录用户的信息，包括他们的登录时间和终端。
- \`w\` 命令: 显示当前登录用户及其活动信息。

### 安装信息

\`users\` 命令通常是 \`procps\` 软件包的一部分，这个包在大多数Linux发行版中默认安装。

- **Ubuntu**: \`procps\` 包通常默认安装，不需要额外安装。
- **RHEL/CentOS**: \`procps-ng\` 包包含 \`users\` 命令，也通常默认安装。

\`\`\`bash
# Ubuntu
$ sudo apt-get install procps

# RHEL/CentOS
$ sudo yum install procps-ng
\`\`\`

### root 权限

\`users\` 命令不需要 root 权限来运行。普通用户可以使用该命令查看当前登录的用户列表。

### 结论

\`users\` 命令是一个简单而有用的工具，提供了有关当前登录用户的快速信息。虽然它没有额外的参数和选项，但可以与其他命令结合使用，获取更多详细的用户登录信息。

        `,
    };
  },
});
</script>
<style scoped></style>
