<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## tcpdump 命令详解

\`tcpdump\` 是一款强大的网络抓包工具，用于截取和分析网络中的数据包。它允许用户监听通过指定接口的网络流量，通常用于网络问题诊断和安全审计。\`tcpdump\` 广泛应用于网络运维和安全工程中，是网络分析必不可少的工具之一。

### 安装

#### Ubuntu

\`\`\`bash
sudo apt-get install tcpdump
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install tcpdump
\`\`\`

### 基本语法

\`\`\`bash
tcpdump [选项] [过滤表达式]
\`\`\`

- \`选项\`：用来调整输出格式、指定监听接口等。
- \`过滤表达式\`：用于过滤需要捕获的数据包。

### 常用选项

- \`-i <interface>\`：指定监听的网络接口。如果不指定接口，\`tcpdump\` 会选择第一个找到的接口。
- \`-n\`：不解析主机名和端口号，将 IP 地址和端口号直接显示出来，提升抓包速度。
- \`-c <number>\`：捕获指定数量的数据包后停止。
- \`-w <filename>\`：将捕获的数据包写入文件。
- \`-r <filename>\`：从文件读取并分析数据包。
- \`-vvv\`：提供更加详细的信息输出。
- \`-s <snaplen>\`：指定捕获数据包的长度，默认是 65535。

### 过滤表达式

过滤表达式用于指定抓取哪些类型的数据包，例如只捕获 HTTP 流量、指定 IP 地址或端口的流量。常见的过滤表达式有：

- \`host <IP>\`：指定目标 IP 地址。
- \`port <number>\`：指定端口号。
- \`tcp\`：只捕获 TCP 数据包。
- \`udp\`：只捕获 UDP 数据包。

### 常见用法示例

#### 示例一：监听网络接口上的所有流量

\`\`\`bash
tcpdump -i eth0
\`\`\`

**输出示例**：

\`\`\`plaintext
tcpdump: listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes
09:52:43.000000 IP azlinux-prod-cn.ssh > 192.168.1.10.50794: Flags [P.], seq 1125:1281, ack 1, win 29200, options [nop,nop,TS val 4294728714 ecr 123456789], length 156
\`\`\`

**解读**：该命令监听了 \`eth0\` 网络接口的所有流量，输出了一个 TCP 数据包（SSH 流量），显示了数据包的源地址、目标地址、端口号、标志位和数据包长度。

#### 示例二：只捕获指定主机的流量

\`\`\`bash
tcpdump -i eth0 host 192.168.1.100
\`\`\`

**输出示例**：

\`\`\`plaintext
tcpdump: listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes
09:55:12.123456 IP azlinux-prod-cn.ssh > 192.168.1.100.50794: Flags [P.], seq 1125:1281, ack 1, win 29200, options [nop,nop,TS val 4294728714 ecr 123456789], length 156
\`\`\`

**解读**：该命令只抓取与 IP 地址 \`192.168.1.100\` 有关的流量，输出了从主机 \`azlinux-prod-cn\` 向 \`192.168.1.100\` 发出的 SSH 数据包。

#### 示例三：捕获指定端口的流量

\`\`\`bash
tcpdump -i eth0 port 80
\`\`\`

**输出示例**：

\`\`\`plaintext
tcpdump: listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes
09:58:07.654321 IP 192.168.1.5.http > 192.168.1.10.60000: Flags [P.], seq 1125:1281, ack 1, win 29200, length 156
\`\`\`

**解读**：该命令只抓取与 80 端口（HTTP 流量）有关的网络数据包，输出了一条从 \`192.168.1.5\` 发出的 HTTP 数据包。

#### 示例四：将捕获的数据包保存到文件

\`\`\`bash
tcpdump -i eth0 -w capture.pcap
\`\`\`

**输出**：无输出，命令已成功执行。

**解读**：此命令将 \`eth0\` 接口的所有流量捕获并保存到 \`capture.pcap\` 文件中。

#### 示例五：从文件读取并分析数据包

\`\`\`bash
tcpdump -r capture.pcap
\`\`\`

**输出示例**：

\`\`\`plaintext
reading from file capture.pcap, link-type EN10MB (Ethernet)
09:52:43.000000 IP azlinux-prod-cn.ssh > 192.168.1.10.50794: Flags [P.], seq 1125:1281, ack 1, win 29200, length 156
\`\`\`

**解读**：此命令从 \`capture.pcap\` 文件中读取并解析数据包，显示捕获的网络流量。

#### 示例六：指定捕获数据包的数量

\`\`\`bash
tcpdump -i eth0 -c 10
\`\`\`

**输出示例**：

\`\`\`plaintext
tcpdump: listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes
10 packets captured
10 packets received by filter
0 packets dropped by kernel
\`\`\`

**解读**：此命令在捕获到 10 个数据包后自动停止抓包。输出结果显示抓取了 10 个数据包，并未丢弃任何数据包。

### 权限要求

\`tcpdump\` 通常需要 \`root\` 权限来监听网络接口。可以使用 \`sudo\` 提升权限：

\`\`\`bash
sudo tcpdump -i eth0
\`\`\`

**输出示例**：

\`\`\`plaintext
tcpdump: listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes
\`\`\`

**解读**：此命令在 \`root\` 权限下成功启动，开始监听 \`eth0\` 接口的流量。

### 结果保存与分析

通过 \`-w\` 参数，可以将捕获到的数据包保存到文件中，稍后可以用 \`tcpdump\` 或其他工具（如 Wireshark）进行分析。

#### 示例：保存数据包并分析

\`\`\`bash
tcpdump -i eth0 -w output.pcap
tcpdump -r output.pcap
\`\`\`

**解读**：第一条命令将数据包保存到 \`output.pcap\`，第二条命令读取并分析文件。

### 过滤表达式的使用

\`tcpdump\` 允许通过丰富的过滤表达式来指定要捕获的数据包类型。通过组合条件可以更精准地抓取特定的流量。

#### 示例：捕获 TCP 流量

\`\`\`bash
tcpdump -i eth0 tcp
\`\`\`

#### 示例：捕获与指定 IP 地址相关的流量

\`\`\`bash
tcpdump -i eth0 host 192.168.1.100
\`\`\`

### 架构无差异

在 aarch64 和 x86_64/amd64 架构下，\`tcpdump\` 的输出没有显著差异，因此不需要单独列出不同架构的输出。

### 版本差异

\`tcpdump\` 的版本更新主要集中在对新协议的支持和性能优化上。可以使用以下命令查看当前版本：

\`\`\`bash
tcpdump -v
\`\`\`

### 总结

\`tcpdump\` 是一款强大的网络分析工具，广泛应用于网络管理和安全审计。通过对数据包的详细捕获和分析，管理员可以快速定位网络问题、分析安全隐患。在使用时，通过结合过滤表达式和各种选项，\`tcpdump\` 能够灵活处理各种复杂的网络环境。

        `,
    };
  },
});
</script>
<style scoped></style>
