<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lspci 命令详解

\`lspci\` 是一个用于列出计算机系统中所有 PCI 总线和连接到它们的设备的命令。它能够显示系统的 PCI 硬件组件的详细信息，例如显卡、网卡、USB 控制器等。\`lspci\` 是一个外部命令，并不属于 Bash 的内置命令。它通常用于系统硬件信息的查看、故障排查和调试。

### 安装 lspci

通常，\`lspci\` 作为 \`pciutils\` 软件包的一部分，默认安装在大多数 Linux 发行版中。如果系统没有安装，可以通过以下命令安装：

- **Ubuntu**:
    \`\`\`bash
    sudo apt-get install pciutils
    \`\`\`

- **RHEL/CentOS**:
    \`\`\`bash
    sudo yum install pciutils
    \`\`\`

### 基本用法

运行 \`lspci\` 可以显示系统中所有 PCI 设备的列表。使用时不需要额外的参数，只需在命令行中输入：

\`\`\`bash
lspci
\`\`\`

**输出示例**：
\`\`\`bash
00:00.0 Host bridge: Intel Corporation 6th Gen Core Processor Host Bridge/DRAM Registers (rev 07)
00:02.0 VGA compatible controller: Intel Corporation HD Graphics 530 (rev 06)
00:14.0 USB controller: Intel Corporation Sunrise Point-H USB 3.0 xHCI Controller (rev 31)
00:1f.3 Audio device: Intel Corporation Sunrise Point-H HD Audio (rev 31)
\`\`\`

**解释**：
- 每一行显示一个 PCI 设备。
- \`00:00.0\` 表示设备的总线号和设备号。
- \`Host bridge\` 和 \`VGA compatible controller\` 表示设备的类型。
- \`Intel Corporation\` 显示设备的制造商信息。
- \`rev 07\` 是设备的修订版本号。

### 常用参数

#### 1. -v：显示详细信息

使用 \`-v\` 参数可以显示设备的更多详细信息，例如设备的子系统 ID、内存地址和驱动信息。

\`\`\`bash
lspci -v
\`\`\`

**输出示例**（部分）：
\`\`\`bash
00:02.0 VGA compatible controller: Intel Corporation HD Graphics 530 (rev 06) (prog-if 00 [VGA controller])
    Subsystem: Lenovo Device 380d
    Flags: bus master, fast devsel, latency 0, IRQ 129
    Memory at de000000 (64-bit, non-prefetchable) [size=16M]
    Memory at c0000000 (64-bit, prefetchable) [size=256M]
    Capabilities: [40] Vendor Specific Information: Len=0c <?>
\`\`\`

**解释**：
- \`Subsystem\` 表示子系统信息，如 \`Lenovo Device 380d\`。
- \`Flags\` 显示设备的状态信息，如 \`bus master\` 表示总线控制器。
- \`Memory at\` 显示设备占用的内存地址区域。

#### 2. -vv：更详细的输出

使用 \`-vv\` 参数可以输出更加详细的信息，适合调试或深入了解设备的硬件细节。

\`\`\`bash
lspci -vv
\`\`\`

**输出示例**（部分）：
\`\`\`bash
00:14.0 USB controller: Intel Corporation Sunrise Point-H USB 3.0 xHCI Controller (rev 31) (prog-if 30 [XHCI])
    Subsystem: Lenovo Device 380d
    Flags: bus master, medium devsel, latency 0, IRQ 129
    Memory at df240000 (64-bit, non-prefetchable) [size=64K]
    Capabilities: [70] Power Management version 3
    Capabilities: [80] MSI: Enable+ Count=1/8 Maskable- 64bit+
    Kernel driver in use: xhci_hcd
\`\`\`

**解释**：
- \`Kernel driver in use\` 显示使用的内核驱动，如 \`xhci_hcd\`。
- \`MSI: Enable+\` 表示设备启用了消息信号中断。
- \`Power Management\` 显示设备支持的电源管理功能。

#### 3. -k：显示内核模块信息

使用 \`-k\` 参数可以显示设备使用的内核驱动模块信息。这对于调试驱动问题非常有用。

\`\`\`bash
lspci -k
\`\`\`

**输出示例**：
\`\`\`bash
00:02.0 VGA compatible controller: Intel Corporation HD Graphics 530 (rev 06)
    Subsystem: Lenovo Device 380d
    Kernel driver in use: i915
    Kernel modules: i915
\`\`\`

**解释**：
- \`Kernel driver in use\` 表示当前正在使用的驱动程序。
- \`Kernel modules\` 列出了与该设备相关的所有内核模块。

#### 4. -nn：显示厂商和设备 ID

使用 \`-nn\` 参数可以显示设备的厂商和设备 ID。这对于查找不明设备的详细信息非常有帮助。

\`\`\`bash
lspci -nn
\`\`\`

**输出示例**：
\`\`\`bash
00:14.0 USB controller [0c03]: Intel Corporation Sunrise Point-H USB 3.0 xHCI Controller [8086:a12f] (rev 31)
\`\`\`

**解释**：
- \`[8086:a12f]\` 是厂商和设备的 ID，\`8086\` 表示厂商 Intel，\`a12f\` 是设备 ID。
- 可以根据这些 ID 在 PCI 数据库中查找具体设备信息。

#### 5. -s：过滤指定设备

使用 \`-s\` 参数可以只显示特定总线和设备编号的设备信息。

\`\`\`bash
lspci -s 00:02.0
\`\`\`

**输出示例**：
\`\`\`bash
00:02.0 VGA compatible controller: Intel Corporation HD Graphics 530 (rev 06)
\`\`\`

**解释**：
- 该命令仅显示 \`00:02.0\` 设备的信息，即 Intel HD Graphics 530。

#### 6. -t：以树形结构显示设备

使用 \`-t\` 参数可以以树形结构显示 PCI 设备的连接关系，适合查看设备的层级结构。

\`\`\`bash
lspci -t
\`\`\`

**输出示例**：
\`\`\`bash
-[0000:00]---00.0
           +-02.0
           +-14.0
           +-1f.0
\`\`\`

**解释**：
- \`-[0000:00]\` 表示总线 00。
- 下方的子项目表示与该总线相连的设备。

### 没有输出时的处理

在某些情况下，执行 \`lspci\` 可能没有输出，可能是因为系统没有加载正确的 PCI 相关模块。这时，可以使用以下命令查看是否加载了相关模块：

\`\`\`bash
lsmod | grep pci
\`\`\`

**输出示例**：
\`\`\`bash
pci_stub               16384  1
pci_bus               20480  1 pci_stub
xhci_pci              16384  0
\`\`\`

**解释**：
- \`pci_stub\` 模块是 PCI 的占位符驱动。
- \`xhci_pci\` 是 USB 控制器的 PCI 驱动。

### 不同架构下的输出差异

\`lspci\` 命令在 \`x86_64/amd64\` 和 \`aarch64\` 架构下的输出通常没有显著差异。无论是 ARM 架构还是 Intel/AMD 架构，\`lspci\` 都会显示相似的 PCI 设备信息。因此，输出差异不明显，无需做特殊说明。

### 旧版本与新版本的差异

在较旧版本的 \`lspci\` 中，可能会对较新的硬件设备支持不完善，导致某些设备信息显示为 \`Unknown\`。更新到最新版本的 \`pciutils\` 可以解决这些问题，并更好地支持新硬件设备。建议定期检查和更新系统中的 \`pciutils\` 软件包。

### root 权限需求

在某些情况下，获取设备的详细信息或与设备交互时可能需要 \`root\` 权限。例如，使用 \`-v\` 或 \`-k\` 参数查看驱动信息时，可能需要使用 \`sudo\` 命令：

\`\`\`bash
sudo lspci -v
\`\`\`

### 总结

\`lspci\` 是一个强大的工具，可以帮助系统管理员和开发人员查看和调试系统中的 PCI 设备。通过结合使用不同的参数，用户可以获取详细的硬件信息、驱动状态和设备连接关系。作为硬件检测和故障排查的重要工具，\`lspci\` 在 Linux 运维中的应用非常广泛。
        `,
    };
  },
});
</script>
<style scoped></style>
