<template>
  <div>
    <GameCard1
      title="Docker Hub 上搜索镜像"
      actions="搜索常见镜像即可，例如nginx, redis"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
\`docker search\` 是一个用于在 Docker Hub 上搜索镜像的命令。它允许用户根据关键字查找公开可用的 Docker 镜像，提供镜像名称、描述、星级、官方认证等信息。

### 语法
\`\`\`bash
docker search [OPTIONS] TERM
\`\`\`

### 选项
- \`--filter\` 或 \`-f\`: 使用过滤器限制搜索结果。
- \`--no-trunc\`: 显示完整的镜像描述。
- \`--limit\`: 限制返回的结果数量。

### 示例

#### 1. 基本搜索
假设您想要查找与 Nginx 相关的镜像，可以使用以下命令：
\`\`\`bash
docker search nginx
\`\`\`

#### 输出示例
\`\`\`
NAME                             DESCRIPTION                                     STARS     OFFICIAL   AUTOMATED
nginx                            Official build of Nginx.                        17960     [OK]
richarvey/nginx-php-fpm          Container running Nginx + PHP-FPM.              1179                 
jc21/nginx-proxy-manager         Docker container for managing Nginx proxy       1964                 
...
\`\`\`

#### 2. 使用过滤器
您可以使用过滤器来限制结果。例如，只查找官方镜像：
\`\`\`bash
docker search --filter is-official=true nginx
\`\`\`

#### 输出示例
\`\`\`
NAME                             DESCRIPTION                                     STARS     OFFICIAL   AUTOMATED
nginx                            Official build of Nginx.                        17960     [OK]
\`\`\`

#### 3. 显示完整描述
如果您希望查看镜像的完整描述而不进行截断，可以使用 \`--no-trunc\` 选项：
\`\`\`bash
docker search --no-trunc nginx
\`\`\`

#### 输出示例
\`\`\`
NAME                             DESCRIPTION                                     STARS     OFFICIAL   AUTOMATED
nginx                            Official build of Nginx.                        17960     [OK]
richarvey/nginx-php-fpm         Container running Nginx + PHP-FPM.             1179                 
jc21/nginx-proxy-manager         Docker container for managing Nginx proxy       1964                 
...
\`\`\`

### 使用场景
- **查找可用镜像**: 当您想要找出哪些 Docker 镜像可供使用，或者想要找到特定功能或服务的镜像时，\`docker search\` 是非常有用的工具。
- **评估镜像**: 通过查看星级和描述，可以对镜像的质量和适用性做出初步判断。

### 注意事项
- \`docker search\` 只会返回公开可用的镜像，不包括私有镜像。
- 结果的星级反映了该镜像在 Docker Hub 上的受欢迎程度，通常可以用作选择镜像的参考。


通过 \`docker search\` 命令，用户可以快速找到所需的镜像，加快了镜像的使用效率。

在 Docker 中，镜像可以分为两种主要类型：个人镜像和官方镜像。理解这两者之间的区别有助于用户更好地选择高质量的 Docker 镜像。

### 1. 个人镜像 (User Images)

- **定义**: 个人镜像是由 Docker Hub 用户或第三方组织创建和维护的镜像。这些镜像可以是开源的，也可以是私有的。
- **特点**:
  - 质量不一：个人镜像的质量和维护状况可能差异很大，用户需要进行额外的调查。
  - 星级和评论：通常可以通过星级、下载量和用户评论来判断镜像的质量。
  - 不一定安全：由于没有官方认证，使用这些镜像时可能面临安全风险。

### 2. 官方镜像 (Official Images)

- **定义**: 官方镜像是由 Docker 官方或与 Docker 认证的组织维护的镜像。这些镜像通常遵循更严格的标准。
- **特点**:
  - 高质量：官方镜像经过验证，通常包含安全性、性能和更新的保证。
  - 官方认证：镜像前面有 [OK] 标志，表示它是官方维护的镜像。
  - 更频繁的更新：官方镜像会定期更新以修复漏洞和改进功能。

### 如何获取高质量镜像？

1. **选择官方镜像**:
   - 在 Docker Hub 上寻找带有 [OK] 标志的官方镜像，例如 \`nginx\`, \`ubuntu\`, \`alpine\` 等。
   - 使用命令 \`docker search --filter is-official=true <镜像名>\` 来仅显示官方镜像。

2. **查看星级和下载量**:
   - 星级越高的镜像通常更受欢迎，可以更好地反映出镜像的质量。
   - 下载量也可以作为参考，显示出镜像的使用广泛程度。

3. **阅读文档和评论**:
   - 在 Docker Hub 上查看镜像的 README 文档，了解镜像的功能和使用方法。
   - 阅读其他用户的评论和反馈，以了解镜像的优缺点。

4. **检查维护状态**:
   - 查看镜像的更新历史，确保镜像得到及时维护和更新。
   - 关注镜像的 GitHub 仓库（如果有），了解开发活动和社区支持。

5. **测试镜像**:
   - 在本地环境中进行测试，以确保该镜像符合您的需求，并能正常运行。

通过上述方法，用户可以更容易地找到高质量的 Docker 镜像，确保在使用时的安全性和可靠性。
        
        `,
      interviewContent: `
### 选择题

1. **关于 \`docker search\` 命令，以下说法正确的是：**
   - A. \`docker search\` 只能搜索官方镜像。
   - B. \`docker search\` 支持通过关键词过滤搜索结果。
   - C. \`docker search\` 只返回最近更新的镜像。
   - D. \`docker search\` 不支持使用过滤器。

2. **使用 \`docker search\` 命令时，返回的每个镜像项包含的信息中，不包括：**
   - A. 镜像的名称
   - B. 镜像的版本号
   - C. 镜像的描述
   - D. 星级评分

3. **如果想查找带有“mysql”关键词的官方镜像，正确的命令是：**
   - A. \`docker search mysql --filter is-official=true\`
   - B. \`docker search --official mysql\`
   - C. \`docker search mysql\`
   - D. \`docker pull mysql --official\`

### 问答题

1. **什么是 \`docker search\` 命令，它的主要用途是什么？**

2. **在使用 \`docker search\` 时，如何筛选出高评分的镜像？请说明方法和示例。**

3. **使用 \`docker search\` 命令时，如何查看某个特定镜像的详细信息？**

### 答案

**选择题答案：**

1. B. \`docker search\` 支持通过关键词过滤搜索结果。
2. B. 镜像的版本号。
3. A. \`docker search mysql --filter is-official=true\`。

**问答题答案：**

1. **\`docker search\` 命令**: \`docker search\` 是用于在 Docker Hub 上搜索镜像的命令。它的主要用途是帮助用户查找所需的 Docker 镜像，提供镜像的名称、描述和评分等信息，以便用户选择合适的镜像进行使用。

2. **筛选高评分镜像**: 可以使用 \`--filter\` 选项，例如 \`docker search --filter stars=3\`，这个命令会返回星级评分在 3 星及以上的镜像。示例：\`docker search --filter stars=3 mysql\`，会列出所有至少有 3 星的 MySQL 相关镜像。

3. **查看详细信息**: 使用 \`docker inspect <镜像名>\` 命令来查看某个特定镜像的详细信息。这个命令会返回镜像的 JSON 格式的元数据，包括创建时间、大小、标签等信息。例如，\`docker inspect nginx\` 会显示 Nginx 镜像的详细信息。
      
      `,
    };
  },
});
</script>
<style scoped></style>
