<template>
  <div class="container">
    <div class="search-bar">
      <el-input
        v-model.trim="searchQuery"
        style="width: 12rem; margin-right: 1rem"
        placeholder="输入术语"
      />
      <el-button type="primary" @click="search">搜索</el-button>
    </div>

    <div class="term-list">
      <div class="alphabet-buttons">
        <el-button
          class="btn-terms-index"
          v-for="letter in alphabet_1"
          :key="letter"
          @click="filterByLetter(letter)"
        >
          {{ letter }}
        </el-button>
        <br />
        <el-button
          class="btn-terms-index"
          v-for="letter in alphabet_2"
          :key="letter"
          @click="filterByLetter(letter)"
        >
          {{ letter }}
        </el-button>
        <br />
        <el-button
          class="btn-terms-index"
          v-for="number in numbers"
          :key="number"
          @click="filterByLetter(number)"
        >
          {{ number }}
        </el-button>
      </div>

      <el-table :data="filteredTerms" stripe style="width: 100%">
        <el-table-column prop="term" label="术语" width="160" />
        <el-table-column prop="eng" label="英文" width="200" />
        <el-table-column prop="explanation" label="解释" />
        <el-table-column prop="example" label="对话示例" />
      </el-table>
    </div>
  </div>
</template>

<script>
// import { ref, computed } from "vue";

export default {
  name: "App",
  data() {
    return {
      searchQuery: "",
      terms: [
        {
          term: "胶片",
          eng: "Power Point",
          category: "J",
          explanation:
            "在华为公司里，其含义为PPT，其名称来源于过去幻灯片胶片的制作方式，即将文字和图像印在胶片上，然后通过投影仪进行放映。",
          example:
            "今天在的年度会议上，我们使用胶片展示了我们公司过去一年的成绩，以及未来的发展规划。",
        },
        {
          term: "虚拟机",
          eng: "Virtual Machine",
          category: "X",
          explanation:
            "虚拟机是在物理服务器上运行的软件，模拟完整的计算机硬件系统。虚拟机技术非常流行，几乎IT从业者都应当掌握。",
          example:
            '运维总监说: "开发和测试人员如果申请【2核心4GB内存120GB磁盘】规格以下的虚拟机的话，不需要我审批，如果规格超过这个，那就将申请工单转给我，需要我来审批。"\n运维小钟: "好的领导，我注意工单。资源确实有点紧张。"',
        },
        {
          term: "用户生命周期",
          eng: "User Lifecycle",
          category: "Y",
          explanation:
            "用户生命周期是指用户从首次接触应用到最终卸载应用的全过程，包括获取、激活、留存、变现和流失等阶段。",
          example:
            "某健身App运营人员说: 我们可以通过精细化运营和个性化推荐等方式来延长用户生命周期。",
        },
        {
          term: "IDC",
          eng: "Internet Data Center",
          category: "I",
          explanation:
            "IDC是互联网数据中心的简称，它提供集中的数据存储、管理和交换服务，是云计算和大数据时代的重要基础设施。",
          example:
            "运维经理说: 我们公司正在考虑租用一家IDC来托管我们的36台服务器。咱们自己的机房太小了，后面还有新增服务器，放不下了。某电信运营商的机房怎么样？",
        },
        {
          term: "云存储",
          eng: "Cloud Storage",
          category: "Y",
          explanation:
            "云存储是一种将数据存储在互联网数据中心上的技术，其中，分布式存储最为常见。",
          example:
            "X公司的系统管理员说: 我们的备份数据现在都在云存储上，这样即使本地设备出现问题，数据也不会丢失，能快速恢复到本地。",
        },
        {
          term: "基础设施即服务",
          eng: "IaaS(Infrastructure as a Service)",
          category: "J",
          explanation:
            "是一种偏向底层的云计算服务模型，提供虚拟化的计算资源、存储资源和网络资源等。",
          example:
            '运维工程师说: "新产品服务上线海外的话，我们准备选用AWS的EC2实例来部署，EC2是IaaS的一个典型代表，非常适合需要快速扩展计算能力的场景。"\n运维总监说：同意！',
        },
        {
          term: "平台即服务",
          eng: "PaaS(Platform as a Service)",
          category: "P",
          explanation:
            "一种云计算服务模型，提供开发、测试、部署和管理应用程序的平台，用户无需管理底层的计算资源、存储资源和网络资源，只需关注应用程序的开发和部署。",
          example:
            '面试官问: "PaaS和IaaS有什么区别？"。\n运维小张: "PaaS提供了更高级别的服务，包括应用程序运行时、数据库、开发工具等，而IaaS主要提供基础设施资源。"',
        },
        {
          term: "云计算",
          eng: "Cloud Computing",
          category: "Y",
          explanation:
            "我们公司计划将IT基础设施迁移到云上，以提高灵活性和降低成本。",
          example:
            "运维总监说: 我们公司计划将IT基础设施迁移到云上，以提高灵活性和降低成本。云计算可以帮助我们快速响应业务需求变化。",
        },
        {
          term: "软件即服务 ",
          eng: "SaaS(Software as a Service)",
          category: "R",
          explanation: "软件即服务，通过网络提供软件应用程序的云服务模型。",
          example:
            '某新入职员工: "我们公司的CRM系统是基于什么模型的？"\n老员工: "我们的CRM系统是SaaS模型的，用户可以通过浏览器访问并使用。"',
        },
        {
          term: "CI/CD",
          eng: "Continuous Integration\n/Continuous Delivery",
          category: "J",
          explanation:
            "持续集成/持续交付，是一种软件开发实践，强调代码频繁提交、自动化构建和测试。",
          example:
            '新人小张: "我们的CI/CD流程是怎样的？"\n项目经理: "我们使用Git进行代码管理，Jenkins进行持续集成，然后自动部署到测试环境。"',
        },
        {
          term: "技术支持",
          eng: "Technical Support",
          category: "J",
          explanation:
            "为用户提供技术帮助和解决问题的服务，常见的岗位为: 售后工程师。市场需求较多。",
          example:
            '办公室丽丽说: "我电脑坏了，需要找技术支持来看看。"\nIT小钟回复到: "没问题，我帮你联系技术支持团队（Technical Support Team）。',
        },
        {
          term: "996",
          eng: "996 work schedule",
          category: "9",
          explanation:
            "每周工作6天，每天工作时间从早上9点到晚上9点的工作制度。",
          example:
            '员工小张: "听说最近公司要实行996工作制了？"\n员工小李: "是啊，不过这样工作压力就更大了。"',
        },
        {
          term: "运维",
          eng: "Operations and Maintenance",
          category: "Y",
          explanation: "设备和系统的稳定运行和日常维护。",
          example:
            '部门领导: "最近系统总是频繁告警出问题，运维团队查下根因！。"\n运维总监: "已经在查了，分析报告明天下午就会发出来的。"',
        },
        {
          term: "自动化部署",
          eng: "Automated Deployment",
          category: "Z",
          explanation:
            "通过自动化工具（例如网页发布平台，脚本等）将软件或系统部署到生产环境中，以减少手动操作和提高部署效率。",
          example:
            'Python运维开发小张: "这次我们采用自动化部署，可以分钟级快速将2.0版本上线。"\n运维老李: "以前手动部署容易出错，而且耗时半小时以上。谢谢你开发的自动部署系统。"',
        },
        {
          term: "生产环境",
          eng: "Production Environment",
          category: "S",
          explanation:
            "有的公司也称之为【现网环境】。正式提供对外服务的软件或服务运行环境。公司盈利主要靠生产环境。因此其稳定性、可靠性、安全性、性能至关重要。也是运维工程师重点工作对象。",
          example:
            '领导讲话中说道: "生产环境的变更管理要谨慎，审批流程不准绕过！以避免对业务和用户造成不必要的影响。",',
        },
        {
          term: "升级",
          eng: "Upgrade",
          category: "S",
          explanation: "将软件、系统或硬件更新到更高版本。",
          example:
            '安全工程师小铭: "OpenSSH的旧版本有严重漏洞爆出来了，赶紧更新下咱们环境里的OpenSSH。我们写的更新方案在wiki上。"\n运维小张: "你们发现的真及时，正好我们也在处理这安全事件"。"',
        },
        {
          term: "备份",
          eng: "Backup",
          category: "B",
          explanation:
            "对数据进行复制，以防止数据丢失或系统崩溃时无法找回数据的情况。",
          example:
            '运维总监: "咱们的MySQL数据备份是什么时间点？"\n运维小钟: "每天凌晨3点备份的，保留最近一星期的7个备份副本。".',
        },
        {
          term: "数据清洗",
          eng: "Data Cleaning",
          category: "S",
          explanation:
            "对数据进行检查、纠正和处理的过程，以确保数据的准确性、完整性和一致性。",
          example:
            '面试官A: "数据清洗为什么重要？"\n应聘者B: "因为原始数据中往往存在错误、重复或缺失等问题，这些问题会影响数据分析的准确性和可靠性。"',
        },
        {
          term: "中央处理器",
          eng: "CPU",
          category: "Z",
          explanation: "计算机的核心部件，负责执行程序指令和处理数据。",
          example:
            '运维小张: "有开发人员反馈，开发服务器响应速度好慢！问是不是CPU性能不行？"\n运维小钟: "你用lscpu查看下型号，就会发现是10年前的CPU，已经反馈给硬件部门让换新的了。"',
        },
        {
          term: "多因素认证",
          eng: "Multi-Factor Authentication, MFA",
          category: "D",
          explanation:
            "一种更安全的身份验证方法，要求用户提供多种认证因素（如密码、指纹、短信验证码等）以验证身份。",
          example:
            '开发工程师小铭: "为了增强运维工程师管理账户安全性，我们近期强制启用了MFA。"\n运维小钟: "挺好的，支持！MFA可以大大提高账户的安全性。其实也不麻烦。"',
        },
        {
          term: "零日漏洞",
          eng: "Zero-Day Vulnerability",
          category: "L",
          explanation:
            "已经被发现但尚未被软件厂商修复或公开披露的漏洞。攻击者可以利用这类漏洞发动攻击。",
          example:
            '安全工程师小铭: "最近有一个零日漏洞被曝光，很多系统都受到了影响。"\n运维小钟: "我们必须密切关注这个漏洞的补丁发布情况，并及时更新系统。"',
        },
        {
          term: "漏洞",
          eng: "Vulnerability",
          category: "L",
          explanation:
            "系统、网络或应用中的安全弱点，可能被攻击者利用来实现未授权访问或破坏。",
          example:
            '安全工程师小铭: "这个CentOS-6系统存在多个漏洞，需要尽快修复。"\n运维小钟: "确实是高风险的漏洞，我也马上联系业务，让迁移到新的系统上。"',
        },
        {
          term: "DevOps",
          eng: "Development and Operations",
          category: "D",
          explanation:
            "DevOps是一种文化和方法的集合，旨在促进开发（Development）和运维（Operations）团队之间的沟通和协作，以加快软件交付速度并提高软件质量。",
          example:
            'Python运维开发小张: "我们公司正在推广DevOps文化，以促进开发和运维团队之间的紧密合作。"\n运维小钟: "快速迭代，快速上线部署，棒！更快地响应业务需求并交付高质量的软件。"',
        },
        {
          term: "SLA",
          eng: "Service Level Agreement",
          category: "S",
          explanation:
            "服务级别协议，是服务提供商与客户之间关于服务质量和责任的正式协议。",
          example:
            '运维小张: "我们与供应商签订的SLA中规定了哪些关键指标？"\n运维小钟: "SLA中明确规定了服务的可用性需达到4个9（99.99%），且故障响应时间不得超过2小时。"',
        },
        {
          term: "MTBF",
          eng: "Mean Time Between Failures",
          category: "M",
          explanation:
            "平均无故障时间，指系统连续工作到下一次发生故障的平均时间。",
          example:
            '运维总监: "这款服务器的MTBF是多少？"\n运维小钟: "品牌服务器，放心吧。根据制造商提供的数据，这款服务器的MTBF高达10万小时。"',
        },
        {
          term: "MTTR",
          eng: "Mean Time To Repair",
          category: "M",
          explanation: "平均修复时间，指从系统故障发生到修复完成的平均时间。",
          example:
            '运维总监: "我们需要优化MTTR，以减少业务中断时间。"\n运维小张: "可以考虑建立更完善的故障响应机制和备件库，以加快修复速度，目前有的流程是人工跟踪，确实有点慢。"',
        },
        {
          term: "安全加固",
          eng: "Security Hardening",
          category: "A",
          explanation:
            "对系统进行安全配置设置的加强，以提高系统的安全性和防护能力。",
          example:
            '运维总监: "你新安装的CentOS-9，运行了安全加固脚本了吗？"\n运维小张: "确实没有加固。"\n运维总监: "你找安全的小铭获取下加固脚本，然后记得新机器上运行下。"',
        },
        {
          term: "容器化",
          eng: "Containerization",
          category: "R",
          explanation:
            "容器化是一种将应用程序及其所有依赖项打包在一起的方法，使其可以在任何环境中无缝运行。",
          example:
            "运维人员说: 我们计划将应用程序容器化，这样可以提高部署的灵活性和效率，消除环境差异。",
        },
        {
          term: "自动化运维",
          eng: "Automated Operations",
          category: "Z",
          explanation:
            "自动化运维是指使用工具和技术来自动执行运维任务，尤其是重复性的耗时较长的工作，减少人工干预。",
          example:
            "运维主管说: 我们正在实施自动化运维，以减少手动执行常规任务的工作量，今天的运维工作自动化目标是从当前的30%提升到50%。",
        },
        {
          term: "容量规划",
          eng: "Capacity Planning",
          category: "R",
          explanation:
            "容量规划是预测和管理IT资源（如存储、处理能力和网络带宽）的过程，以满足业务需求。",
          example:
            "运维主管说: 我们正在进行容量规划，以确保在未来2年内能够满足业务增长的需求，其中存储容量规划了100PB，存储服务器的采购分2次完成。",
        },
        {
          term: "灾难恢复计划",
          eng: "Disaster Recovery Plan",
          category: "Z",
          explanation:
            "灾难恢复计划是一份详细的文档，描述了如何在发生灾难性事件后恢复业务运营。",
          example:
            '运维经理说: "我们的灾难恢复计划是2年前制定的，以确保在发生意外时能够快速恢复业务，并减少损失。最近随着新架构的上线，可能要完善下新架构下的场景了。"\n运维小张说:"好的，我去调研，一星期后给出方案。"',
        },
        {
          term: "数据迁移",
          eng: "Data Migration",
          category: "S",
          explanation:
            "数据迁移是将数据从一个存储系统移动到另一个存储系统的过程。",
          example:
            "运维主管说: 我们计划将数据从旧服务器迁移到新服务器，以提高存储性能，旧的存储服务器快10年了，该下线了。今天晚上10点开始迁移，预计3小时，小张和小钟辛苦下了。下个月多个你们一人发600块钱加班费。",
        },
        {
          term: "PMP",
          eng: "Project Management Professional",
          category: "P",
          explanation:
            "PMP是指项目管理专业人士，它是由美国项目管理协会（PMI）颁发的国际公认的项目管理专业人士认证。",
          example:
            '项目经理小杜说: "我2018年就获得了PMP认证。2019年在外包公司当上了主管。"\n团队成员小王回应: "太棒了，原来我们的项目管理专业又高效是这个原因！厉害了，杜总。"',
        },
        {
          term: "ITSM",
          eng: "IT Service Management",
          category: "I",
          explanation:
            "ITSM是信息技术服务管理的缩写，它是一套用于管理信息技术服务的最佳实践框架，旨在提高服务质量和客户满意度。",
          example:
            '运维小钟: "陈总，咱们公司的ITSM平台是用什么开发的？Python吗？"\n运维总监陈军说: "Java开发的。公司内部IT请求通过ITSM能快速找到责任人，流程流畅，迅捷方便，我都忍不住想开源给其他公司企业用。"',
        },
        {
          term: "微服务",
          eng: "Microservices",
          category: "W",
          explanation:
            "微服务是一种软件架构风格，它将应用程序构建为一组小型、自治的服务，每个服务运行在其独立的进程中，并使用轻量级通信机制进行通信。",
          example:
            '新入职运维小李: "咱们当前的新能源充电产品一共多少个微服务呢？"\n运维老张: "30-50个吧，我也没太记清楚。有的是下载服务，有的是在线计费等。你去看看配置文件呗。"  ',
        },
        {
          term: "功能测试",
          eng: "Functional Testing",
          category: "G",
          explanation:
            "功能测试是软件测试的一种类型，旨在验证软件系统的基本功能能否正常工作，功能测试非常重要，不可不测。",
          example:
            '某小公司里，运维人员说："一部分客户报告故障和投诉了，说咱们的充电计费产品计费有误，使用优惠券之后却比没有使用贵3倍！功能测试有遗漏？？？"\n测试小李:"当时觉得优惠券的业务逻辑很简单，只看了下，就没有测试，写了个【通过】。"',
        },
        {
          term: "发行版",
          eng: "Distribution",
          category: "F",
          explanation:
            "指某个软件或操作系统的特定版本，通常由特定的团队或组织进行打包、分发和维护。",
          example:
            '系统管理员小张说: "我们决定使用Ubuntu Server的22.04版本作为我们的服务器发行版。"\n运维主管回应: "好的，确保所有依赖和兼容性都已经测试过。"',
        },
        {
          term: "文件系统",
          eng: "File System",
          category: "W",
          explanation:
            "用于存储、组织、管理文件的系统结构，决定了数据如何在存储设备上进行存储和访问。",
          example:
            '开发者小李说: "我们需要确保新开发的文件管理系统能够兼容Windows和Linux的文件系统。"\n项目经理回应: "好的，我们也需要考虑文件系统的性能和安全性。"',
        },
        {
          term: "命令行界面",
          eng: "Command Line Interface",
          category: "M",
          explanation:
            "一种用户与计算机交互的方式，用户通过输入命令来执行操作，与图形用户界面(GUI)相对。",
          example:
            '开发者小王说: "我更喜欢使用命令行界面来部署和管理服务器。"\n同事回应: "是的，命令行界面在某些操作上确实更加高效，也很强大。"',
        },
        {
          term: "内核",
          eng: "Kernel",
          category: "N",
          explanation:
            "操作系统的核心部分，负责管理硬件、内存、进程等基本功能，为上层应用程序提供运行环境，底层开发人员可以调用内核API进行开发。",
          example:
            '系统架构师说: "我们正在考虑将系统的内核升级到最新稳定版以提高性能，还有内核新特性要用到。"\n项目经理回应: "好的，但我们需要确保升级后的内核与现有应用程序兼容。"',
        },
        {
          term: "Shell",
          eng: "Shell",
          category: "S",
          explanation:
            "一种命令行界面，允许用户与操作系统进行交互，敲命令、执行脚本等。",
          example:
            '运维工程师X说: "Bash Shell是很多Linux发行版的默认Shell，业界标准。"\n其同事回应: "是的，Linux环境下Bash脚本最流行了。"',
        },
        {
          term: "用户空间",
          eng: "User Space",
          category: "Y",
          explanation:
            "操作系统中，普通应用程序运行的内存区域，与内核空间相对，用户空间的应用程序不能直接访问内核资源。",
          example:
            '系统安全专家说: "我们需要确保用户空间的应用程序不能非法访问内核资源。"\n项目经理回应: "是的，这是保障系统安全的重要一环。"',
        },
        {
          term: "进程",
          eng: "Process",
          category: "J",
          explanation:
            "操作系统中进行资源分配和调度的基本单位，是应用程序的执行实例。",
          example:
            '开发者小张说: "我们的应用程序在Linux上运行时产生了多个进程。"\n同事回应: "是的，我们需要监控这些进程的资源使用情况。"',
        },
        {
          term: "线程",
          eng: "Thread",
          category: "X",
          explanation:
            "进程中的执行单元，多个线程可以在同一个进程中并发执行，共享进程的资源。",
          example:
            '开发者小王说: "为了提高性能，我们决定使用多线程来处理并发请求。"\n项目经理回应: "好的，但需要注意线程同步和资源共享的问题。"',
        },
        {
          term: "系统调用",
          eng: "System Call",
          category: "X",
          explanation:
            "应用程序请求操作系统内核执行特定功能的接口，如文件操作、进程控制等。",
          example:
            '开发者小李说: "我们的应用程序需要通过系统调用来访问硬件设备。"\n同事回应: "是的，系统调用是应用程序与操作系统交互的重要方式。"',
        },
        {
          term: "守护进程",
          eng: "Daemon",
          category: "S",
          explanation:
            "在后台运行的进程，通常用于执行特定的系统任务，如日志记录、网络服务等。",
          example:
            '系统管理员说: "我们需要确保Web服务器的守护进程一直在运行。"\n运维主管回应: "是的，守护进程的稳定性对系统运行至关重要。"',
        },
        {
          term: "RAID",
          eng: "Redundant Array of Independent Disks",
          category: "R",
          explanation:
            "独立磁盘冗余阵列，通过数据冗余和分散存储提高数据可靠性和读取性能。",
          example:
            '面试官: "请解释一下RAID技术的原理及其常见级别。"\n面试者: "RAID技术通过组合多个物理磁盘来创建一个逻辑磁盘，通过数据冗余和分散存储提高数据可靠性和读取性能。常见的RAID级别包括RAID 0、RAID 1、RAID 5等。"',
        },
        {
          term: "日志管理",
          eng: "Log Management",
          category: "R",
          explanation:
            "日志管理是运维工作中的重要环节，通过对系统日志的收集、存储、分析和告警，帮助运维人员及时发现和解决问题。",
          example:
            '面试官: "请谈谈你在日志管理方面的经验。"\n面试者: "我使用ELK Stack（Elasticsearch、Logstash、Kibana）进行日志管理，通过Logstash收集日志，Elasticsearch进行索引和存储，Kibana提供可视化界面，方便进行日志分析和告警。"',
        },
        {
          term: "性能调优",
          eng: "Performance Tuning",
          category: "X",
          explanation:
            "性能调优是指通过调整系统配置、优化代码和资源分配等手段，提高系统或应用的运行效率和性能。",
          example:
            '面试官: "请谈谈你在性能调优方面的经验。"\n面试者: "我曾在项目中遇到数据库查询性能瓶颈，通过优化SQL语句、调整数据库索引和配置缓存策略，成功提高了查询性能。"',
        },

        {
          term: "故障排查",
          eng: "Troubleshooting",
          category: "G",
          explanation:
            "故障排查是指通过收集和分析系统日志、性能指标等信息，定位并解决系统或应用中的故障和问题。运维工程师重要能力之一。",
          example:
            '面试官: "请描述一次你进行故障排查的经历。"\n面试者: "有一次系统突然崩溃重启了，我首先通过查看mesg系统日志定位到是内存泄漏问题，然后通过逐步排查和代码审查，最终找到了问题根源并进行了修复，我们调用的一个库的5.6版本有内存泄漏问题导致的。"',
        },
        {
          term: "Kubernetes",
          eng: "Kubernetes",
          category: "K",
          explanation:
            "Kubernetes是一个开源的容器编排平台，用于自动部署、扩展和管理容器化应用程序，提供自动上线回滚、服务发现、负载均衡等功能。",
          example:
            '面试官: "你在项目中是如何使用Kubernetes的？"\n面试者: "在项目中，我们使用Kubernetes来管理容器化应用，通过YAML文件定义服务，利用Kubernetes的自动调度和负载均衡功能，确保应用的高可用性。"',
        },
        {
          term: "版本控制",
          eng: "Version Control",
          category: "B",
          explanation:
            "一种记录文件、目录或项目变更历史，以便管理和追踪修改的系统。常见的有Git, SVN等。",
          example:
            '开发小张: "我刚刚提交了一个新的版本到版本控制系统。"\n运维小钟: "好的，我等下部署这个版本到测试环境。"',
        },
        {
          term: "故障转移",
          eng: "Failover",
          category: "G",
          explanation:
            "当主系统或组件发生故障时，自动切换到备用系统或组件，以保证服务的连续性和可用性。",
          example:
            '运维小钟: "由于主服务器故障，系统已经自动进行了故障转移。"\n运维经理: "好的，你马上检查一下备用服务器的状态，看看能不能扛得住这么大的流量，我去看看有没有客户业务受影响，一会儿消息发我。"',
        },
        {
          term: "负载均衡",
          eng: "Load Balancing",
          category: "F",
          explanation:
            "将网络请求或工作负载分散到多个服务器或资源上，以提高性能和可用性。",
          example:
            '运维小钟: "由于访问量过大，我需要添加更多的服务器到负载均衡池中。"\n运维总监: "好的，操作吧。确保负载均衡策略能够均匀分配请求。"',
        },
        {
          term: "基线",
          eng: "Baseline",
          category: "J",
          explanation: "一个已知的良好状态或配置，用作后续更改或比较的基准。",
          example:
            '运维经理: "我们需要建立一个系统基线，以便后续进行配置管理。"\n运维工程师Z: "好的，我根据以前的基线，适配到新系统上做个新基线。"',
        },
        {
          term: "回滚",
          eng: "Rollback",
          category: "H",
          explanation:
            "将系统或应用恢复到之前的状态，以撤销最近的更改或修复引入的问题。上线和变更，一定要有回滚方案。",
          example:
            '运维总监: "今天凌晨上线的3.0.1这个新版本有问题，需要马上回滚到上一个版本。"\n运维小钟: "好的，我马上执行回滚操作。"',
        },
        {
          term: "构建",
          eng: "Build",
          category: "G",
          explanation: "将源代码编译、打包成可执行文件或库文件的过程。",
          example:
            '开发新人小张: "我刚刚提交了代码，你那边可以开始构建了。"\n运维小钟: "测试环境的话，我把账号密码发你，你自己构建。"',
        },
        {
          term: "分支",
          eng: "Branch",
          category: "F",
          explanation:
            "在版本控制系统中，用于隔离不同工作线或实验性更改的代码副本。",
          example:
            '开发小李: "我在feature分支上添加了一个新功能。"\n运维小钟: "好的，那你构建，然后部署测试呗，不需要通知我的。"',
        },
        {
          term: "变更",
          eng: "Change",
          category: "B",
          explanation: "对环境(生产环境或测试环境)或系统进行配置修改的过程。",
          example:
            '运维经理: "今晚的变更计划需要审批后才能执行。"\n运维工程师: "好的，这个变更影响面有点大，我去详细备注下变更注意事项，然后提交审批。"',
        },
        {
          term: "测试环境",
          eng: "Testing Environment",
          category: "C",
          explanation: "用于测试软件或系统功能的模拟生产环境。",
          example:
            '开发小张: "新功能已经在测试环境验证过了。"\n运维小钟: "好的，那我准备部署到生产环境了。"',
        },
        {
          term: "SRE",
          eng: "Site Reliability Engineering",
          category: "S",
          explanation:
            "网站可靠性工程，是一种专注于通过软件工程方法解决系统运维问题的职业角色。",
          example:
            '面试官: "你有SRE的工作经验吗？"\n应聘者YY: "是的，我之前在Google Cloud 担任过SRE，主要负责保障中间件产品服务的稳定性和可靠性。"\n面试官:"这是一个为期8个月的外包岗位，8k的薪资能接受吗?一周6天。"',
        },
        {
          term: "告警",
          eng: "Alert",
          category: "G",
          explanation:
            "当系统或应用出现异常情况时，自动发送的通知或提醒(短信,email,钉钉消息,微信机器人消息等)，以便运维人员及时处理。",
          example:
            '运维小钟: "我刚刚收到了一个CPU使用率过高的告警，每小时告警3次，CPU性能不足，需要升级配置。"\n运维总监: "好的，同意!"',
        },
        {
          term: "CMDB",
          eng: "Configuration Management Database",
          category: "C",
          explanation:
            "配置管理数据库，用于存储和管理IT环境中的各种配置项信息。",
          example:
            '运维经理: "我们需要更新CMDB中的服务器配置信息。"\n运维工程师: "好的，我马上去更新。"',
        },
        {
          term: "合规",
          eng: "Compliance",
          category: "H",
          explanation:
            "遵守相关法律、法规、标准或政策的要求，不同国家，不同区域有不同的要求。",
          example:
            '运维总监: "我们的App在欧洲受到了不合规警告，需要一星期内整改完毕。"\n运维小钟: "好的，合规第一。我马上联系开发调整数据存储策略，cookie策略等。"',
        },
        {
          term: "预算",
          eng: "Budget",
          category: "Y",
          explanation: "指在特定时间内，为达成特定目标而计划使用的财务资源。",
          example:
            '运维总监: "今年的运维预算是多少？我申请的200万能下来吗？"\n财务: "今年的运维预算是240万，比去年增加了20%。"\n运维总监: "真给力"',
        },
        {
          term: "金丝雀发布",
          eng: "Canary Release",
          category: "J",
          explanation:
            "一种降低在生产环境中引入新软件版本风险的技术，通过先让一小部分用户升级新版本进行测试。",
          example:
            '运维小钟: "这次新功能上线，我们采用金丝雀发布怎么样？"\n运维总监: "好主意，先让一小部分用户试试水，5%吧，没问题再全面推广。"',
        },
        {
          term: "RHCE",
          eng: "Red Hat Certified Engineer",
          category: "R",
          explanation:
            "红帽认证工程师，证明具备在Red Hat Enterprise Linux环境中进行系统安装、配置和管理的技能。",
          example:
            '面试官: "你有RHCE认证吗？"\n应聘者: "是的，我去年通过了RHCE认证考试。所以具备基础的Linux环境管理能力，胜任当前工作无压力！"',
        },
        {
          term: "RHCA",
          eng: "Red Hat Certified Architect",
          category: "R",
          explanation:
            "红帽认证架构师，是Red Hat公司推出的最高级别的认证，证明具备高级系统设计和管理的技能。",
          example:
            '同事A: "听说你拿到了RHCA认证？"\n同事B: "是啊，这可是个不小的挑战呢！单位报销100%，而且还奖励6000块钱呢。"',
        },
        {
          term: "CCNA",
          eng: "Cisco Certified Network Associate",
          category: "C",
          explanation:
            "思科认证网络工程师，证明具备安装、配置和运营局域网、广域网的能力。",
          example:
            '运维小钟: "我刚通过了CCNA考试，感觉网络技能又提升了一大截！"\n运维总监: "不错，继续保持学习的热情！证书复印件发我，给你报销。"',
        },
        {
          term: "变更窗口",
          eng: "Change Window",
          category: "B",
          explanation:
            "指定可以进行系统变更或维护的时间段，以减少对业务的影响。",
          example:
            '运维经理: "这次系统升级安排在周六的变更窗口00:00~04:00进行，大家做好准备。"\n运维团队: "明白了，我们会确保一切顺利进行。"',
        },
        {
          term: "混沌工程",
          eng: "Chaos Engineering",
          category: "H",
          explanation: "一种通过在生产环境中模拟故障来提高系统弹性的方法。",
          example:
            '运维总监: "我们得开始考虑实施混沌工程，提升系统的容错能力。"\n运维小钟: "好主意，我们可以先从模拟网络延迟开始。下周我提交一个演练说明书。"',
        },
        {
          term: "客户",
          eng: "Customer",
          category: "K",
          explanation: "购买或使用产品或服务的人或组织。",
          example:
            '运维经理: "我们要时刻关注客户的需求，提供优质的服务。"\n运维团队: "明白，我们会定期收集客户反馈，不断优化服务，主动服务客户。"',
        },
        {
          term: "并发",
          eng: "Concurrency",
          category: "B",
          explanation: "指系统同时处理多个任务或操作的能力。",
          example:
            '开发经理: "这个系统能支持多少并发用户？"\n运维小钟: "根据测试，系统可以稳定支持2000个并发用户。"',
        },
        {
          term: "事业部",
          eng: "Business Unit",
          category: "S",
          explanation: "公司内负责特定业务领域的部门。",
          example:
            '运维总监: "这次项目需要事业部的全力支持。"\n事业部经理: "放心，我们会全力配合，确保项目顺利进行。"',
        },
        {
          term: "一线",
          eng: "Frontline",
          category: "Y",
          explanation: "直接面对客户或用户，负责处理日常问题和请求的运维团队。",
          example:
            '一线运维: "用户报告说系统登录不了，我正在处理。"\n二线运维: "好的，如果需要技术支持，随时告诉我。"',
        },
        {
          term: "二线",
          eng: "Secondline",
          category: "E",
          explanation: "为一线团队提供技术支持和解决方案的运维团队。",
          example:
            '一线运维: "这个数据库问题我解决不了，需要二线支持。"\n二线运维: "好的，我马上来看看是什么问题，工单号链接发我。"',
        },
        {
          term: "准则",
          eng: "Standard",
          category: "Z",
          explanation: "指导行为或决策的规则或原则。",
          example:
            '运维总监: "我们要遵循行业最佳准则，确保服务的质量和安全性。"\n运维团队: "明白，我们会定期审查并更新我们的运维准则。"',
        },
        {
          term: "爆炸半径",
          eng: "Blast Radius",
          category: "B",
          explanation: "指系统故障或问题时可能影响的范围或程度。",
          example:
            '运维经理: "这次系统升级可能会有什么爆炸半径？"\n运维小钟: "我们已经做了充分的测试，预计爆炸半径会很小，主要影响的是登录功能。"',
        },
        {
          term: "故障影响面",
          eng: "Failure Impact Scope",
          category: "G",
          explanation: "指系统故障或问题时实际影响的业务范围或用户数量。",
          example:
            '运维总监: "这次数据库故障的影响面有多大？"\n运维小钟: "初步评估，影响了大约10%的用户，主要集中在华东地区。"',
        },
        {
          term: "迭代",
          eng: "Iteration",
          category: "D",
          explanation:
            "指项目开发或运维过程中的一个周期，通常包括规划、执行、评估和反馈等步骤。",
          example:
            '项目经理: "这次迭代我们主要解决用户反馈的登录问题。"\n运维团队: "好的，我们会确保在这次迭代中完成这个任务。"',
        },
        {
          term: "ITIL",
          eng: "IT Infrastructure Library",
          category: "I",
          explanation: "一套用于指导IT服务管理的最佳实践框架。",
          example:
            '运维总监: "我们要按照ITIL的框架来优化我们的运维流程。"\n运维团队: "明白，我们会开始学习并实施ITIL的最佳实践。"',
        },
        {
          term: "知识库",
          eng: "Knowledge Base",
          category: "Z",
          explanation:
            "存储组织内部知识、经验和解决方案的数据库，用于帮助员工解决问题和提高工作效率。",
          example:
            '运维小钟: "我遇到了一个数据库连接问题，先去知识库里找找有没有解决方案。"\n运维经理: "好主意，知识库里有很多前辈的经验和解决方案，可以节省很多时间。"',
        },
        {
          term: "离岸",
          eng: "Offshoring",
          category: "L",
          explanation: "将业务或服务外包给其他国家或地区的公司或团队。",
          example:
            '运维总监: "考虑到成本因素，我们可以考虑将部分运维工作离岸出去。"\n公司高层: "这是个值得考虑的方案，但需要确保服务质量和安全性。"',
        },
        {
          term: "外包",
          eng: "Outsourcing",
          category: "W",
          explanation: "将业务或服务的部分或全部交给外部公司或团队来完成。",
          example:
            '运维经理: "这次系统升级工作我们可以考虑外包给专业的团队。"\n公司高层: "好的，但我们要确保外包团队的技术实力和信誉。"',
        },
        {
          term: "拉通",
          eng: "Alignment",
          category: "L",
          explanation:
            "指不同团队或部门之间在目标、策略或行动上达成一致和协同。",
          example:
            '运维总监: "我们要和开发团队拉通一下，确保项目按时交付。"\n开发经理: "没问题，我们会定期开会，确保两边的进度和目标是对齐的。"',
        },
        {
          term: "对齐",
          eng: "Alignment",
          category: "D",
          explanation: "指团队或部门之间的目标、策略或行动保持一致和协同。",
          example:
            '运维经理: "我们要确保所有运维活动和公司的战略目标对齐。"\n运维团队: "明白，我们会定期审查我们的工作，确保和公司目标保持一致。"',
        },
        {
          term: "二线支持",
          eng: "Second-line Support",
          category: "E",
          explanation:
            "为一线支持团队提供技术援助和解决方案的更高层级的支持团队。",
          example:
            '一线运维: "这个复杂的问题我需要二线支持。"\n二线运维: "好的，我马上来帮你看看这个问题怎么解决。"',
        },
        {
          term: "混合云",
          eng: "Hybrid Cloud",
          category: "H",
          explanation:
            "结合了公有云和私有云的优势，允许企业在不同环境之间灵活地迁移和部署应用程序和数据。",
          example:
            'CTO: "我们正在考虑采用混合云架构，以满足业务的灵活性和安全性需求。"\n云架构师: "混合云是一个不错的选择，可以让我们根据需要在公有云和私有云之间进行资源调配。"',
        },
        {
          term: "私有云",
          eng: "Private Cloud",
          category: "S",
          explanation:
            "为单个组织构建和运营的云计算环境，提供更高的安全性和控制性。",
          example:
            '安全经理: "我们公司是金融业务，出于数据安全考虑，我们决定构建自己的私有云。"\nIT经理: "好的，我会安排团队开始规划和部署私有云。"',
        },
        {
          term: "公有云",
          eng: "Public Cloud",
          category: "G",
          explanation:
            "由第三方云服务提供商提供的云计算服务，用户可以通过互联网访问和使用。",
          example:
            '开发人员: "我们打算将新应用部署到公有云上，以加快上线速度。"\n运维工程师: "好的，我会协助你选择合适的公有云平台并完成部署。"',
        },
        {
          term: "中间件",
          eng: "Middleware",
          category: "Z",
          explanation:
            "位于操作系统和应用程序之间的软件层，提供各种服务，如通信、数据管理、安全等，以支持应用程序的运行。",
          example:
            '架构师: "我们需要选择一款合适的中间件来连接我们的前端应用和后端数据库。"\n开发人员: "我推荐使用轻量级的消息队列中间件，以实现解耦和异步通信。"',
        },
        {
          term: "指标",
          eng: "Metrics",
          category: "Z",
          explanation:
            "用于衡量系统或应用程序性能的可量化数据，如CPU使用率、内存占用、网络流量等。",
          example:
            '运维工程师: "我们需要收集关键指标来监控系统的运行状况。"\n监控人员: "好的，我会配置监控系统来收集这些指标，并设置报警阈值。"',
        },
        {
          term: "关键指标",
          eng: "Key Performance Indicators (KPIs)",
          category: "G",
          explanation:
            "用于衡量组织或项目成功程度的关键指标，如用户满意度、收入增长、成本降低等。",
          example:
            '业务经理: "我们需要定义一些关键指标来评估新产品的市场表现。"\n数据分析师: "好的，我会根据业务目标和用户行为数据来制定合适的KPI。"',
        },
        {
          term: "Unix",
          eng: "Unix",
          category: "U",
          explanation:
            "一种多用户、多任务的操作系统，广泛应用于服务器和工作站。",
          example:
            '系统管理员: "我正在配置一台新的Unix服务器。"\n运维工程师: "好的，需要我帮忙安装哪些软件吗？"',
        },
        {
          term: "SNMP",
          eng: "Simple Network Management Protocol",
          category: "J",
          explanation: "一种用于管理和监控网络设备的协议。",
          example:
            '网络工程师: "我们需要使用SNMP来收集网络设备的性能数据。"\n运维工程师: "好的，我会配置SNMP代理并设置监控系统。"',
        },
        {
          term: "TCP",
          eng: "Transmission Control Protocol",
          category: "C",
          explanation:
            "一种面向连接的、可靠的传输层协议，用于保证数据在网络中的传输。",
          example:
            '开发人员: "我们的应用程序需要使用TCP协议来传输数据。"\n网络工程师: "好的，我会确保网络配置支持TCP连接。"',
        },
        {
          term: "UDP",
          eng: "User Datagram Protocol",
          category: "U",
          explanation:
            "一种无连接的、不可靠的传输层协议，用于不需要保证数据传输顺序和完整性的场景。",
          example:
            '开发人员: "我们的实时音视频应用需要使用UDP协议来传输数据。"\n网络工程师: "好的，我会确保网络配置支持UDP流量。"',
        },
        {
          term: "事件管理",
          eng: "Event Management",
          category: "S",
          explanation:
            "对系统或应用程序中发生的事件进行识别、分类、优先级排序和响应的过程。",
          example:
            '运维经理: "我们需要建立一个有效的事件管理流程来应对突发事件。"\n运维工程师: "好的，我会制定事件处理流程并配置报警系统。"',
        },
        {
          term: "事件",
          eng: "Event",
          category: "S",
          explanation:
            "系统或应用程序中发生的任何可观察到的变化或活动，如系统故障、安全警报、用户操作等。",
          example:
            '监控人员: "系统刚刚发生了一个严重错误事件，我们需要立即处理。"\n运维工程师: "好的，我马上查看日志并进行故障排除。"',
        },
        {
          term: "GCP",
          eng: "Google Cloud Platform",
          category: "G",
          explanation:
            "谷歌提供的公有云平台，提供各种云计算服务，如计算、存储、数据库、人工智能等。",
          example:
            '开发人员: "我们打算将新项目部署到GCP上，以利用其强大的计算和存储能力。"\n云架构师: "好的，我会协助你选择合适的GCP服务并完成部署。"',
        },
        {
          term: "Git",
          eng: "Git",
          category: "G",
          explanation: "一种分布式版本控制系统，用于跟踪和管理代码的变更。",
          example:
            '开发人员A: "我刚刚提交了代码修改，请帮忙review一下。"\n开发人员B: "好的，我这就用Git拉取最新的代码并进行review。"',
        },
        {
          term: "服务器",
          eng: "Server",
          category: "F",
          explanation:
            "提供计算、存储、网络等服务的计算机系统，用于支持应用程序的运行。",
          example:
            '运维工程师: "我们需要增加一台服务器来应对业务增长。"\nIT经理: "好的，我会批准采购新的服务器。"',
        },
        {
          term: "存储服务器",
          eng: "Storage Server",
          category: "C",
          explanation:
            "专门用于存储数据的服务器，通常具有大容量存储设备和高性能网络接口。",
          example:
            '运维工程师: "我们的存储服务器空间不足了，需要扩容。"\nIT经理: "好的，我会安排采购新的存储设备。"',
        },
        {
          term: "固态硬盘",
          eng: "Solid-State Drive (SSD)",
          category: "G",
          explanation:
            "一种基于闪存芯片的存储设备，具有比传统机械硬盘更快的读写速度和更低的功耗。",
          example:
            '运维工程师: "为了提高系统性能，我们打算将服务器的硬盘升级为固态硬盘。"\nIT经理: "好的，我会批准采购新的固态硬盘。"',
        },
        {
          term: "机械硬盘",
          eng: "Hard Disk Drive (HDD)",
          category: "J",
          explanation:
            "一种传统的存储设备，通过磁头在旋转的磁盘上读写数据，存储容量大且价格不高，常见的是SATA盘和SAS盘。",
          example:
            '运维工程师: "我们需要更换一台服务器上的故障机械硬盘。"\nIT经理: "好的，我把机房人员的电话发给你，你联系更换吧，备件应该有的。"',
        },
        {
          term: "DNS",
          eng: "Domain Name System",
          category: "Y",
          explanation: "一种用于将域名转换为IP地址的分布式数据库系统。",
          example:
            '网络工程师: "我们需要配置DNS服务器来解析我们的域名。"\n运维工程师: "好的，我会设置DNS记录并确保解析正常，我配置好之后给你发消息。"',
        },
        {
          term: "应用程序性能管理（APM）",
          eng: "Application Performance Management (APM)",
          category: "Y",
          explanation:
            "监控和管理应用程序性能的过程，以确保应用程序的可用性、可靠性和性能。",
          example:
            '运维经理: "我们需要部署APM工具来监控应用程序的性能。"\n运维工程师: "好的，我会选择合适的APM工具并进行配置。"',
        },
        {
          term: "AWS",
          eng: "Amazon Web Services",
          category: "A",
          explanation:
            "亚马逊提供的公有云平台，提供各种云计算服务，如计算、存储、数据库、人工智能等。",
          example:
            '开发人员: "我们打算将新项目部署到AWS上，以利用其丰富的云服务和弹性伸缩能力。"\n云架构师: "好的，我会协助你选择合适的AWS服务并完成部署。"',
        },
        {
          term: "EC2",
          eng: "Elastic Compute Cloud",
          category: "E",
          explanation:
            "AWS提供的一种可扩展的虚拟服务器服务，允许用户按需创建和管理虚拟机实例。",
          example:
            '开发人员: "我们需要在AWS上创建几台EC2实例来运行我们的应用程序。"\n运维工程师: "好的，我会帮你创建和配置EC2实例。"',
        },
        {
          term: "5个9的可用性",
          eng: "Five Nines Availability",
          category: "5",
          explanation:
            "指系统在一年中的可用性达到99.999%，即每年停机时间不超过5分钟。",
          example:
            '客户: "我们需要保证系统的可用性达到5个9，以确保业务的连续性。"\n运维经理: "我们会采取一系列措施，包括冗余设计、故障转移等，来实现这个目标。"',
        },
        {
          term: "7x24小时服务",
          eng: "24/7 Service",
          category: "7",
          explanation:
            "指全天候提供服务，随时接入环境处理问题，时间上没有中断。",
          example:
            '客户: "我们需要7x24小时的技术支持，以确保业务的正常运行。"\n客服人员: "没问题，您购买了企业支持计划，我们的技术支持团队全天候为您服务。"',
        },
        {
          term: "80端口",
          eng: "Port 80",
          category: "8",
          explanation:
            "用于HTTP（超文本传输协议）的默认端口，例如Nginx默认的http端口为80。",
          example:
            '开发人员: "我在本地搭建了一个Web服务器，可以通过80端口访问。"\n测试人员: "好的，我这就来测试一下连通性。"',
        },
        {
          term: "100% CPU使用率",
          eng: "100% CPU usage",
          category: "1",
          explanation:
            "指CPU的所有处理能力都被占用，可能导致系统变慢或无响应。",
          example:
            '运维工程师: "服务器的CPU使用率已经达到100%，持续10分钟了，我们需要尽快找出原因并解决。"\n开发人员: "好的，我这就来检查一下代码是否有性能问题。"',
        },
        {
          term: "3层架构",
          eng: "3-tier architecture",
          category: "3",
          explanation:
            "一种常见的软件架构模式，将应用分为表示层、业务逻辑层和数据访问层。",
          example:
            '项目经理: "我们采用3层架构来开发这个应用，以便更好地分离关注点，提高可维护性。"\n开发人员: "明白，我会按照3层架构的原则来组织代码。"',
        },
        {
          term: "0day漏洞",
          eng: "Zero-day vulnerability",
          category: "0",
          explanation:
            "指已经被发现但尚未有补丁或解决方案的安全漏洞，攻击者可以利用这些漏洞进行攻击。",
          example:
            '安全专家: "最近发现了一个严重的0day漏洞，影响范围很广，我们需要尽快采取措施。"\n运维工程师小中: "好的，我会立即检查我们的系统是否受到影响，并尽快部署临时解决方案。"',
        },
        {
          term: "漏扫",
          eng: "Vulnerability Scanning",
          category: "L",
          explanation:
            "使用自动化工具对系统、网络或应用程序进行扫描，以识别潜在的安全漏洞。",
          example:
            '安全工程师: "我们需要定期进行漏扫，以确保系统安全。"\n运维工程师: "好的，你需要什么资源和部署呢？我们全力配合。"',
        },
        {
          term: "勒索病毒",
          eng: "Ransomware",
          category: "L",
          explanation:
            "一种恶意软件，通过加密用户的文件或系统，然后要求支付赎金来解密。",
          example:
            '用户: "我的电脑被勒索病毒感染了，所有文件都被加密了！"\n安全专家: "不要支付赎金！首先隔离受感染的设备，然后尝试使用解密工具或寻求专业帮助。"',
        },
        {
          term: "IaC",
          eng: "Infrastructure as Code",
          category: "J",
          explanation:
            "使用代码和自动化工具来管理和配置基础设施，从而实现基础设施的快速、可重复和可靠的部署。",
          example:
            '运维工程师: "我们打算采用IaC来管理我们的云基础设施。"\n开发人员: "这太棒了！IaC可以让我们更轻松地管理和扩展基础设施。"',
        },
        {
          term: "Azure",
          eng: "Azure",
          category: "A",
          explanation:
            "微软提供的公有云平台，提供各种云计算服务，如计算、存储、数据库、人工智能等。",
          example:
            '开发人员: "我们计划将新的应用程序部署到Azure上。"\n云架构师: "好的，Azure提供了丰富的云服务和工具，可以帮助我们快速构建和部署应用程序。"',
        },
        {
          term: "aarch64",
          eng: "aarch64",
          category: "A",
          explanation: "一种64位ARM架构，广泛应用于移动设备和嵌入式系统。",
          example:
            '开发人员: "我们需要为aarch64架构编译我们的应用程序。"\n运维工程师: "好的，我会配置编译环境并生成相应的二进制文件。"',
        },
        {
          term: "MIPS",
          eng: "MIPS",
          category: "M",
          explanation:
            "一种精简指令集计算机（RISC）架构，常用于嵌入式系统和网络设备。",
          example:
            '嵌入式工程师: "我们正在开发一款基于MIPS架构的路由器。"\n硬件工程师: "MIPS架构具有低功耗和高性能的特点，非常适合嵌入式应用。"',
        },
        {
          term: "工作量",
          eng: "Workload",
          category: "G",
          explanation: "系统或应用程序在特定时间段内处理的任务或请求的数量。",
          example:
            '运维经理: "最近系统的工作量明显增加，我们需要评估是否需要扩容。"\n运维工程师: "好的，我会分析监控数据并提出扩容建议。"',
        },
        {
          term: "单点故障",
          eng: "Single Point of Failure (SPOF)",
          category: "D",
          explanation: "系统中一旦发生故障就会导致整个系统不可用的组件或节点。",
          example:
            '架构师: "我们需要避免单点故障，以提高系统的可用性。"\n运维工程师: "好的，我们会采用冗余设计和故障转移机制来消除单点故障。"',
        },
        {
          term: "冗余",
          eng: "Redundancy",
          category: "R",
          explanation:
            "在系统中添加额外的组件或节点，以便在某个组件或节点发生故障时，系统仍然可以继续运行。",
          example:
            '运维工程师: "为了提高系统的可靠性，我们建议增加服务器冗余。"\nIT经理: "好的，我会批准采购新的服务器。"',
        },
        {
          term: "看板",
          eng: "Kanban",
          category: "K",
          explanation:
            "一种可视化工作流程管理方法，通过看板上的卡片来表示任务，并通过移动卡片来跟踪任务的进度。",
          example:
            '项目经理: "我们采用看板来管理项目的开发流程。"\n开发人员: "看板可以让我们更直观地了解任务的进度和瓶颈。"',
        },
        {
          term: "信息技术",
          eng: "Information Technology (IT)",
          category: "X",
          explanation:
            "利用计算机、网络和通信技术来创建、存储、处理、传输和管理信息的领域。",
          example:
            '学生: "我想学习信息技术，将来从事IT行业。"\n老师: "信息技术是一个充满机遇的领域，你可以选择学习编程、网络、数据库等方向。"',
        },
        {
          term: "行业标准",
          eng: "Industry Standards",
          category: "H",
          explanation:
            "由行业组织或监管机构制定的规范和指南，用于确保产品、服务或流程的质量、安全性和互操作性。",
          example:
            '质量经理: "我们需要确保我们的产品符合相关的行业标准。"\n工程师: "好的，我会查阅相关标准并进行合规性测试。"',
        },
        {
          term: "堡垒机",
          eng: "Bastion Host",
          category: "B",
          explanation:
            "用户连接堡垒机之后，开始访问堡垒机管理的所有资源。它一种安全审计设备，用于控制和监控对内部网络的访问。",
          example:
            '安全工程师: "我们需要部署堡垒机来加强对生产环境的访问控制。"\n运维工程师: "好的，我会配置堡垒机并设置访问策略，管理所有Windows和Linux机器，堡垒机一主一备高可用。"',
        },
        {
          term: "跳板机",
          eng: "Jump Server",
          category: "T",
          explanation:
            "堡垒机的另一种说法，或者说是早期的称呼。用户连接该机器，才能访问其他内网机器资源。",
          example:
            '安全工程师: "我们的开源跳板机的审计功能有点弱，没法对Windows的用户操作进行审计呢。"\n运维工程师: "好的，我查查新版本有没有支持Windows审计的功能或者计划。"',
        },
        {
          term: "审批",
          eng: "Approval",
          category: "S",
          explanation:
            "对某个请求或变更进行授权的过程，通常需要经过多个层级的审核和批准。",
          example:
            'SRE人员老钟: "我提交了一个生产环境的今晚的变更请求，需要您的审批。"\n运维主管: "好的，我这会儿在地铁上，我马上用手机给你审批。"',
        },
        {
          term: "集群",
          eng: "Cluster",
          category: "J",
          explanation:
            "由多台服务器节点组成的集合，共同协作完成任务，提供高可用性、负载均衡和可扩展性。",
          example:
            '运维工程师: "为了应对业务增长，我们需要搭建一个数据库集群来提高系统的性能和可用性。"\n架构师: "好的，集群可以帮助我们实现负载均衡和故障转移，确保数据库服务的稳定运行。"',
        },
        {
          term: "流程",
          eng: "Process",
          category: "L",
          explanation: "一系列有序的活动或任务，用于实现特定目标或结果。",
          example:
            '运维经理: "我们需要优化事件处理流程，以提高响应速度和效率。"\n运维工程师: "好的，我会梳理现有的流程，并提出改进建议。"',
        },
        {
          term: "DMZ",
          eng: "Demilitarized Zone",
          category: "J",
          explanation:
            "位于内部网络和外部网络之间的隔离区域，用于部署面向外部服务的服务器，提高网络安全性。",
          example:
            '安全工程师: "我们需要在DMZ中部署Web服务器和邮件服务器，以保护内部网络免受攻击。"\n网络工程师: "好的，我会配置防火墙规则，将DMZ与内部网络隔离。"',
        },
        {
          term: "域名",
          eng: "Domain Name",
          category: "Y",
          explanation:
            "用于标识互联网上某个实体或资源的易于记忆的名称，如www.azlinux.com。",
          example:
            '市场人员: "我们需要注册一个新的域名来推广我们的新产品。"\nIT经理: "好的，我会联系域名注册商并完成注册。"',
        },
        {
          term: "丢包率",
          eng: "Packet Loss Rate",
          category: "D",
          explanation: "在网络传输过程中丢失的数据包占总数据包数的比例。",
          example:
            '网络工程师: "最近用户反馈网络延迟严重，我怀疑是丢包率过高导致的。"\n运维工程师: "好的，我会使用网络监控工具来检查丢包率，并找出问题原因。"',
        },
        {
          term: "四层负载均衡",
          eng: "Layer 4 Load Balancing",
          category: "S",
          explanation:
            "基于传输层（通常是TCP或UDP）信息进行负载均衡的技术，通过转发数据包到不同的服务器来分担流量。",
          example:
            '运维工程师: "我们需要部署四层负载均衡器来分担Web服务器的流量。"\n网络工程师: "好的，四层负载均衡器可以根据IP地址和端口号来转发请求，提高系统的性能和可用性。"',
        },
        {
          term: "七层负载均衡",
          eng: "Layer 7 Load Balancing",
          category: "Q",
          explanation:
            "基于应用层信息（如HTTP头、URL等）进行负载均衡的技术，可以实现更智能的流量分配和内容路由。",
          example:
            '运维工程师: "我们需要部署七层负载均衡器来实现基于URL的请求转发。"\n网络工程师: "好的，七层负载均衡器可以根据请求的URL路径来转发请求到不同的后端服务器，实现更灵活的流量控制。"',
        },
        {
          term: "AIX",
          eng: "Advanced Interactive eXecutive",
          category: "A",
          explanation:
            "IBM开发的一款Unix操作系统，主要用于运行在IBM Power Systems服务器上。",
          example:
            '系统管理员: "我们需要在新的Power服务器上安装AIX操作系统。"\n运维工程师: "好的，我会下载AIX安装镜像并开始安装。"',
        },
        {
          term: "Solaris",
          eng: "Solaris",
          category: "S",
          explanation:
            "由Sun Microsystems开发的Unix操作系统，现在由Oracle维护。",
          example:
            '运维工程师: "我们有一台老旧的Solaris服务器需要升级。"\n系统管理员: "好的，我会评估升级方案并安排实施。"',
        },
        {
          term: "HPUX",
          eng: "HP-UX",
          category: "H",
          explanation:
            "惠普公司开发的Unix操作系统，主要用于运行在HP Integrity服务器上。",
          example:
            '系统管理员: "我们需要在新的HP服务器上安装HPUX操作系统。"\n运维工程师: "好的，我会下载HPUX安装镜像并开始安装。"',
        },
        {
          term: "浮动IP",
          eng: "Floating IP",
          category: "F",
          explanation:
            "一种可以动态分配给不同云服务器实例的公网IP地址，用于实现高可用性和负载均衡。",
          example:
            '云架构师: "我们需要为Web服务器配置浮动IP，以确保在服务器故障时服务不中断。"\n运维工程师: "好的，我会在云平台上创建浮动IP并将其关联到后端Web服务器。"',
        },
        {
          term: "ISP",
          eng: "Internet Service Provider",
          category: "I",
          explanation: "提供互联网接入服务的公司或组织。",
          example:
            '用户: "我的网络连接不稳定，我需要联系我的ISP。"\n客服人员: "好的，请提供您的ISP名称和账户信息，我将为您查询。"',
        },
        {
          term: "木马",
          eng: "Trojan Horse",
          category: "M",
          explanation:
            "一种伪装成合法软件的恶意软件，用于获取对计算机系统的未授权访问。",
          example:
            '安全专家: "我们检测到系统中存在一个木马程序，它正在窃取用户数据。"\n运维工程师: "我会立即隔离受感染的系统，并进行彻底的病毒查杀。"',
        },
        {
          term: "URL",
          eng: "Uniform Resource Locator",
          category: "U",
          explanation:
            "用于标识互联网上某个资源的地址，包括协议、域名、端口号和路径等信息。",
          example:
            '用户: "请问这个网站的URL是什么？"\n客服人员: "网站的URL是 https://sre.azlinux.com。"',
        },
        {
          term: "URI",
          eng: "Uniform Resource Identifier",
          category: "U",
          explanation:
            "用于标识互联网上某个资源的字符串，包括URL和URN（统一资源名称）。",
          example:
            '开发人员: "我们需要使用URI来标识API接口的资源。"\n架构师: "URI可以让我们更清晰地定义和管理API资源。"',
        },
        {
          term: "HTTPS",
          eng: "Hyper Text Transfer Protocol Secure",
          category: "H",
          explanation:
            "HTTP的安全版本，通过SSL/TLS加密来保护数据传输的机密性和完整性。",
          example:
            '用户: "为什么这个网站的地址栏前面有一个锁的图标？"\n网络工程师: "这是因为这个网站使用了HTTPS协议，你的数据传输是安全的。"',
        },
        {
          term: "HTTP",
          eng: "Hyper Text Transfer Protocol",
          category: "H",
          explanation: "用于在Web浏览器和Web服务器之间传输数据的应用层协议。",
          example:
            '开发人员: "我们的Web应用程序使用HTTP协议与服务器进行通信。"\n网络工程师: "好的，我会确保网络配置支持HTTP流量。"',
        },
        {
          term: "资源池",
          eng: "Resource Pool",
          category: "Z",
          explanation:
            "一组可共享的计算、存储或网络资源，用于动态分配给不同的应用程序或用户。对资源进行池化，能很灵活地调度资源。",
          example:
            '云架构师: "我们创建一个资源池来管理我们的虚拟机实例，以便更灵活地分配资源。"\n运维工程师: "好的，资源池可以帮助我们提高资源利用率和降低成本。"',
        },
        {
          term: "根因",
          eng: "Root Cause",
          category: "G",
          explanation: "导致问题或故障的根本原因，而不是表面现象或症状。",
          example:
            '运维经理: "我们需要找出这次系统故障的根因，以防止类似问题再次发生。上次偷懒没有定位根因，这次又故障了。"\n运维工程师: "好的，我会通过分析日志和监控数据来定位根因。"',
        },
        {
          term: "重点保障",
          eng: "Key Protection",
          category: "Z",
          explanation:
            "对关键业务系统或应用程序提供更高水平的可用性、性能和安全保障。",
          example:
            '业务经理: "我们的电商平台是公司的核心业务，需要重点保障，双11提前一周准备。"\n运维经理: "好的，我们会为电商平台制定专门的运维保障计划，确保其稳定运行。"',
        },
        {
          term: "应用运维",
          eng: "Application Operations",
          category: "Y",
          explanation: "负责部署、监控、维护和优化应用程序的团队或角色。",
          example:
            '开发人员: "新版本的应用程序已经开发完成，可以交给应用运维团队部署上线了。"\n运维工程师: "好的，我们会尽快安排部署并进行测试。"',
        },
        {
          term: "业务运维",
          eng: "Business Operations",
          category: "Y",
          explanation:
            "负责确保业务流程顺畅运行、满足业务需求并实现业务目标的团队或角色。",
          example:
            '业务经理: "我们需要与业务运维团队合作，优化订单处理流程，提高客户满意度。"\n运维经理: "好的，我们会与业务运维团队密切配合，共同改进流程。"',
        },
        {
          term: "on-demand",
          eng: "On-demand",
          category: "A",
          explanation: "按需提供服务或资源，用户可以根据需要随时获取和使用。",
          example:
            '用户: "我需要临时增加云服务器的配置，以应对突发流量。"\n云服务提供商: "没问题，我们的云服务器支持on-demand扩容，您可以随时调整配置。"',
        },
        {
          term: "云原生",
          eng: "Cloud Native",
          category: "Y",
          explanation:
            "一种面向云计算环境的应用程序设计和开发方法，充分利用云的优势，如弹性、可扩展性和敏捷性。",
          example:
            '架构师: "我们计划采用云原生架构来构建新的应用程序。"\n开发人员: "云原生架构可以让我们更轻松地利用云的优势，实现应用程序的快速迭代和部署。"',
        },
        {
          term: "CDN",
          eng: "Content Delivery Network",
          category: "C",
          explanation:
            "一种分布式网络，通过将内容缓存在靠近用户的边缘服务器上，加速内容的交付。",
          example:
            '网站管理员: "我们的网站访问速度太慢，影响了用户体验。"\n网络工程师: "我们可以使用CDN来加速网站内容的交付，提高访问速度。"',
        },
      ],
      alphabet_1: "ABCDEFGHIJKLMN".split(""),
      alphabet_2: "OPQRSTUVWXYZ".split(""),
      numbers: "0123456789".split(""),
      selectedLetter: "", // 用于存储当前选中的字母或数字
      displayedTerms: [], // 用于在表格中显示的数据, default, top X iterms
      initialized: 0,
    };
  },
  computed: {
    filteredTerms() {
      let filtered = this.terms;

      // 按搜索词筛选
      if (this.searchQuery) {
        filtered = filtered.filter((term) =>
          term.term.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      // 按字母或数字筛选
      if (this.selectedLetter) {
        filtered = filtered.filter((term) =>
          term.category
            .toLowerCase()
            .startsWith(this.selectedLetter.toLowerCase())
        );
      }

      return filtered;
    },
  },
  methods: {
    search() {
      this.selectedLetter = ""; // 清除字母筛选
      this.initialized = 1;
    },
    filterByLetter(letter) {
      this.selectedLetter = letter;
      this.initialized = 1;
    },
  },
};
</script>
<style scoped>
.search-bar {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.btn-terms-index {
  border-bottom: 2px solid orange;
  margin-top: 1rem;
  padding: 1rem;
}

.term-list {
  margin: 1rem;
}

.alphabet-buttons {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* 选中 "对话示例" 列的单元格，设置字体大小 */
.el-table ::v-deep .cell {
  font-size: 16px;
  white-space: pre-wrap;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
</style>
