<template>
  <div class="task-box">
    <el-text type="primary">【重要工单】</el-text>
    <div>{{ taskContent }}</div>
    <el-link type="info" style="font-style: italic;font-size: 13px"
      >-- 来自: {{ taskSource }}</el-link
    >
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "TaskBoxLeftLineB",
  props: {
    task: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const taskContent = ref(props.task);
    const taskSource = ref(props.source);

    return {
      taskContent,
      taskSource,
    };
  },
};
</script>

<style scoped>
.task-box {
  margin: 2rem;
  border-left: 8px solid #4fc3f7;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: left;
  padding: 1rem;
  line-height: 2.3;
  background-color: #f8f8f8;
  font-size: 14px;
  min-height: 6rem;
}
</style>
