<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## source 命令详解

\`source\` 是一个非常重要的 Bash 内置命令，用于从指定的文件读取和执行命令。与直接运行脚本的方式不同，\`source\` 在当前 Shell 环境中执行脚本文件中的命令，因此任何变量、函数或别名的定义会立即在当前环境中生效。

### 基本语法

\`\`\`bash
source [文件名]
\`\`\`

或

\`\`\`bash
. [文件名]
\`\`\`

这两种形式的效果完全相同，都是将指定的文件加载到当前的 Shell 环境中。

### 主要功能

1. **加载和执行脚本**：将某个脚本中的命令在当前 Shell 环境中执行。
2. **更新环境变量**：在当前环境中设置新的环境变量或更新现有的环境变量。
3. **定义函数**：通过加载脚本定义在当前环境中使用的函数。

### 示例一：加载环境变量

假设有一个 \`env.sh\` 文件，内容如下：

\`\`\`bash
#!/bin/bash
export VAR1="Hello"
export VAR2="World"
\`\`\`

使用 \`source\` 命令加载这个文件，设置其中的环境变量：

\`\`\`bash
source env.sh
\`\`\`

**输出：**

【无输出，命令已成功执行】

可以通过 \`echo\` 来验证环境变量是否已经设置：

\`\`\`bash
echo $VAR1 $VAR2
\`\`\`

**输出：**

\`\`\`bash
Hello World
\`\`\`

**解读**：\`source env.sh\` 加载了 \`env.sh\` 文件中的命令，并将 \`VAR1\` 和 \`VAR2\` 变量设置在当前环境中，后续的命令能够直接访问这些变量。

### 示例二：定义函数

假设有一个 \`functions.sh\` 文件，内容如下：

\`\`\`bash
#!/bin/bash
function greet {
    echo "Hello, $1!"
}
\`\`\`

使用 \`source\` 加载这个文件：

\`\`\`bash
source functions.sh
\`\`\`

**输出：**

【无输出，命令已成功执行】

验证函数是否已加载：

\`\`\`bash
greet sre
\`\`\`

**输出：**

\`\`\`bash
Hello, sre!
\`\`\`

**解读**：加载文件后，\`greet\` 函数已经可以在当前 Shell 中使用，并成功输出结果。

### 示例三：更新 PATH 变量

通过 \`source\` 可以动态更新当前 Shell 的环境变量，比如修改 \`PATH\` 变量：

假设有一个 \`update_path.sh\` 文件，内容如下：

\`\`\`bash
#!/bin/bash
export PATH=$PATH:/opt/custom/bin
\`\`\`

使用 \`source\` 加载该文件：

\`\`\`bash
source update_path.sh
\`\`\`

**输出：**

【无输出，命令已成功执行】

通过 \`echo $PATH\` 验证：

\`\`\`bash
echo $PATH
\`\`\`

**输出：**

\`\`\`bash
/usr/local/bin:/usr/bin:/bin:/opt/custom/bin
\`\`\`

**解读**：执行 \`source update_path.sh\` 后，\`PATH\` 变量已经更新，加入了 \`/opt/custom/bin\` 目录。

### 示例四：使用 \`.\` 符号代替 \`source\`

\`source\` 也可以简写为 \`.\`。例如，上面的加载 \`env.sh\` 文件的操作可以这样执行：

\`\`\`bash
. env.sh
\`\`\`

效果与 \`source env.sh\` 完全一致。

**输出：**

【无输出，命令已成功执行】

### 与直接执行脚本的区别

直接运行脚本文件和使用 \`source\` 命令加载文件有本质的区别：

- **直接执行脚本**：脚本在一个新的 Shell 进程中运行，脚本中的环境变量或函数不会影响到当前的 Shell 环境。
  
  \`\`\`bash
  ./env.sh
  \`\`\`

  **输出：**

  【无输出，命令已成功执行】

  但是执行完脚本后，当前环境并不会有任何改变，\`echo $VAR1\` 不会输出任何结果。

- **使用 \`source\`**：脚本在当前 Shell 环境中执行，环境变量和函数在当前环境中生效。

### root 权限示例

有时候，加载的文件可能需要修改系统的某些环境变量或配置文件，这些操作可能需要 \`root\` 权限。

假设我们有一个需要修改系统全局路径的 \`system_path.sh\` 文件：

\`\`\`bash
#!/bin/bash
export PATH=/root/custom/bin:$PATH
\`\`\`

使用 \`source\` 加载它时需要 \`root\` 权限：

\`\`\`bash
sudo source system_path.sh
\`\`\`

**输出：**

【无输出，命令已成功执行】

通过 \`echo $PATH\` 查看是否更新：

\`\`\`bash
echo $PATH
\`\`\`

**输出：**

\`\`\`bash
/root/custom/bin:/usr/local/bin:/usr/bin:/bin
\`\`\`

### 架构差异

\`source\` 命令作为 Bash 的内置命令，它在不同架构（如 aarch64 和 x86_64/amd64）下的行为没有差异。因此无需担心在不同平台下使用 \`source\` 会有不同的输出。

### 版本差异

由于 \`source\` 是 Bash 的内置命令，基本上没有版本差异。其行为与 Bash 版本相关，在现代 Linux 系统中，\`source\` 的功能非常稳定。如果在使用过程中发现问题，建议检查当前使用的 Bash 版本。

### 外部命令与内置命令的区别

\`source\` 是 Bash 的**内置命令**，这意味着它只能在 Bash 环境下使用。在其他 Shell 中，\`source\` 命令可能会有不同的表现。例如，在 \`sh\` 中可能不支持 \`source\`，但可以使用 \`.\` 来代替。

### 安装方法

由于 \`source\` 是 Bash 内置命令，通常不需要单独安装，只要系统中安装了 Bash 即可。如果 Bash 未安装，可以通过以下方式安装：

- **Ubuntu/Debian** 系统中安装 Bash：

  \`\`\`bash
  sudo apt-get install bash
  \`\`\`

- **RHEL/CentOS** 系统中安装 Bash：

  \`\`\`bash
  sudo yum install bash
  \`\`\`

### 总结

\`source\` 命令是 Bash 中用于加载并执行脚本的重要工具。通过 \`source\`，我们可以将环境变量、函数、别名等内容加载到当前的 Shell 环境中，而无需启动新的 Shell 进程。本文详细介绍了 \`source\` 命令的基本用法、常见的应用场景，以及与直接执行脚本的区别。无论是开发人员还是系统管理员，掌握 \`source\` 命令的用法都是管理和操作 Linux 系统的重要技能。

        `,
    };
  },
});
</script>
<style scoped></style>
