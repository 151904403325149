<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## spawn 命令详解

\`spawn\` 是一个在 \`Expect\` 脚本中使用的命令，主要用于启动一个新进程并与之进行交互。\`Expect\` 是一个用于自动化交互式应用程序的工具，\`spawn\` 命令可以帮助用户自动化输入过程。

### 基本语法

\`\`\`bash
spawn [选项] 命令 [参数]
\`\`\`

### 主要功能

1. **启动进程**：\`spawn\` 命令用于启动一个新的进程，例如一个交互式 Shell、SSH 登录或 FTP 会话。
2. **与进程交互**：一旦进程启动，\`Expect\` 可以用来发送输入到这个进程并获取输出，从而实现自动化。

### 示例一：使用 spawn 启动 SSH 会话

在以下示例中，用户想要通过 SSH 连接到一个远程主机。

\`\`\`tcl
#!/usr/bin/expect

set timeout 20
spawn ssh sre@azlinux-prod-cn
expect "password:"
send "your_password\r"
interact
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读**：在这个示例中，\`spawn\` 命令启动了一个 SSH 会话，\`expect\` 等待输入 "password:"，并使用 \`send\` 命令发送密码。最后，\`interact\` 命令允许用户与 SSH 会话进行交互。

### 示例二：使用 spawn 进行 FTP 登录

以下是使用 \`spawn\` 启动 FTP 会话的示例：

\`\`\`tcl
#!/usr/bin/expect

set timeout 20
spawn ftp azlinux-prod-cn
expect "Name*"
send "sre\r"
expect "Password:"
send "your_password\r"
interact
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读**：在这个示例中，\`spawn\` 命令用于启动 FTP 客户端。\`expect\` 命令用于等待用户输入用户名和密码，并通过 \`send\` 命令将其发送。最后，\`interact\` 允许用户与 FTP 会话进行交互。

### 示例三：使用 spawn 执行命令并获取输出

在以下示例中，使用 \`spawn\` 来执行一个命令并捕获输出：

\`\`\`tcl
#!/usr/bin/expect

set timeout 20
spawn ls -l
expect eof
\`\`\`

**输出：**

\`\`\`plaintext
总用量 8
-rw-r--r-- 1 sre sre-group 4096 9月 25 12:00 file1.txt
-rw-r--r-- 1 sre sre-group 4096 9月 25 12:01 file2.txt
\`\`\`

**解读**：\`spawn ls -l\` 命令在当前目录中列出文件，\`expect eof\` 等待进程结束，最后输出当前目录下的文件列表。

### 架构差异

由于 \`spawn\` 是 \`Expect\` 的一个命令，其在 aarch64 和 x86_64/amd64 架构下的表现没有差异，因此无需在此处列出不同架构下的输出。

### 版本差异

在 \`Expect\` 的不同版本之间，\`spawn\` 的行为一般保持一致。但对于特定版本的差异或bug，建议查看 \`Expect\` 的发布说明或文档。

### 安装方法

要使用 \`spawn\` 命令，必须安装 \`Expect\` 工具。在 Ubuntu 和 RHEL 系统中，可以使用以下命令进行安装：

- **Ubuntu/Debian**：

  \`\`\`bash
  sudo apt-get install expect
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  sudo yum install expect
  \`\`\`

### 总结

\`spawn\` 命令在 \`Expect\` 脚本中是一个非常有用的工具，能够帮助用户自动化与交互式程序的交互。通过使用 \`spawn\`，用户可以方便地启动进程，并与之交互，从而提高工作效率。本文详细介绍了 \`spawn\` 的基本用法、主要功能及其在自动化任务中的应用，旨在帮助用户更好地理解和使用这一命令。

        `,
    };
  },
});
</script>
<style scoped></style>
