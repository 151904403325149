<template>
  <div class="task-card">
    <div class="card-header">
      <h2 class="title">{{ title }}</h2>
    </div>
    <div class="card-body">
      <p class="description" v-html="formattedActions"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameCard1",
  props: {
    title: {
      type: String,
      required: true,
    },
    actions: {
      type: String,
      required: true,
    },
  },
  computed: {
    // 计算属性，用于将换行符替换为 <br> 标签
    formattedActions() {
      return this.actions.replace(/\n/g, "<br/>");
    },
  },
};
</script>

<style scoped>
.task-card {
  background: #f8f8e8; /* Cartoony light background */
  border: 3px solid #ffca28; /* Golden yellow outline for a 'treasure map' vibe */
  border-radius: 15px;
  width: 360px;
  padding: 16px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  position: relative;
}

/* Hover Effect */
.task-card:hover {
  transform: scale(1.05);
}

/* Title Section */
.card-header {
  background: linear-gradient(135deg, #ffca28 20%, #f57f17 100%);
  border-radius: 10px;
  padding: 2px 10px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 2px #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Description Section */
.card-body {
  padding: 15px;
  background-color: #fffbe6; /* Creamy, parchment-like background */
  border-radius: 10px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  color: #424242;
  margin-top: 10px;
  line-height: 1.6;
}

/* Task Description */
.description {
  font-style: italic;
  color: #795548;
  margin-bottom: 10px;
  text-align: left;
}

/* Fun, cartoony outline for card edges */
.task-card::before,
.task-card::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  top: -10px;
  left: -10px;
  z-index: -1;
}
</style>
