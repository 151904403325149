<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## uptime 命令详解

\`uptime\` 命令用于显示系统的运行时间、当前时间、系统已启动的时间、在线用户的数量以及系统负载。它是一种简单而有效的方法来了解系统的健康状况和负载情况。

### 语法

\`\`\`bash
uptime [OPTION...]
\`\`\`

### 重要参数

- \`-p\`：以更简洁的格式输出系统运行时间。例如："up 2 hours, 10 minutes"。
- \`-s\`：显示系统启动时间。

### 示例用法

以下是一些常见的 \`uptime\` 命令用法示例：

#### 基本用法

\`\`\`bash
uptime
\`\`\`

**示例输出**：

\`\`\`bash
 09:42:18 up 10 days,  2:34,  3 users,  load average: 0.10, 0.20, 0.15
\`\`\`

**解读**：
- \`09:42:18\`：当前时间。
- \`up 10 days,  2:34\`：系统已经运行了10天2小时34分钟。
- \`3 users\`：当前有3个用户登录。
- \`load average: 0.10, 0.20, 0.15\`：系统负载的平均值，分别是过去1分钟、5分钟和15分钟的平均负载。

#### 简洁格式

\`\`\`bash
uptime -p
\`\`\`

**示例输出**：

\`\`\`bash
up 10 days, 2 hours, 34 minutes
\`\`\`

**解读**：
- 以更加简洁的方式显示系统运行时间。

#### 显示系统启动时间

\`\`\`bash
uptime -s
\`\`\`

**示例输出**：

\`\`\`bash
2024-08-31 07:07:00
\`\`\`

**解读**：
- 系统的启动时间为2024年8月31日07:07:00。

### 不同架构下的输出

\`uptime\` 命令的输出在不同架构下通常没有差异。

### 版本差异

\`uptime\` 命令的输出格式可能会因不同的操作系统或版本有所不同，但基本信息保持一致。

### 替代命令

在现代系统中，\`uptime\` 命令通常不会被淘汰，它是一个标准的命令。然而，可以使用 \`top\` 命令查看系统负载和运行时间等信息。

### 安装

\`uptime\` 命令通常是操作系统的一部分，不需要单独安装。它属于 \`procps\` 包。

- **Ubuntu**：
  - \`procps\` 包通常已经预装。可以通过以下命令检查是否安装：
    \`\`\`bash
    dpkg -l | grep procps
    \`\`\`
  - 如未安装，可使用以下命令安装：
    \`\`\`bash
    sudo apt-get install procps
    \`\`\`

- **RHEL**：
  - \`procps\` 包通常已经预装。可以通过以下命令检查是否安装：
    \`\`\`bash
    rpm -q procps
    \`\`\`
  - 如未安装，可使用以下命令安装：
    \`\`\`bash
    sudo yum install procps
    \`\`\`

### root权限

\`uptime\` 命令不需要 root 权限即可运行。

### 参考

\`uptime\` 命令是系统管理中非常实用的工具，可以帮助用户快速了解系统的基本状态。通过以上命令和参数的使用，可以灵活地获取系统的运行时间和负载情况。

        `,
    };
  },
});
</script>
<style scoped></style>
