<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## systemctl 命令详解

\`systemctl\` 是用于管理和控制 \`systemd\` 系统和服务管理器的命令。它是现代 Linux 系统中管理系统服务和系统状态的主要工具。\`systemctl\` 可以启动、停止、重启、重新加载服务，查看服务状态，管理系统的运行级别等。

### 基本语法

\`\`\`bash
systemctl [OPTIONS] COMMAND [SERVICE]
\`\`\`

其中，\`OPTIONS\` 是可选参数，\`COMMAND\` 是要执行的操作，\`SERVICE\` 是要操作的服务名称或目标单元（unit）。

### 常用命令

#### 启动服务

\`\`\`bash
sudo systemctl start SERVICE
\`\`\`

**示例：**

\`\`\`bash
sudo systemctl start nginx
\`\`\`

**输出示例：**

\`\`\`bash
[sudo] password for sre:
\`\`\`

**解释：**  
该命令启动 \`nginx\` 服务。如果服务已成功启动，则不会有输出。可以通过 \`systemctl status nginx\` 查看服务状态。

#### 停止服务

\`\`\`bash
sudo systemctl stop SERVICE
\`\`\`

**示例：**

\`\`\`bash
sudo systemctl stop nginx
\`\`\`

**输出示例：**

\`\`\`bash
[sudo] password for sre:
\`\`\`

**解释：**  
该命令停止 \`nginx\` 服务。如果服务已成功停止，则不会有输出。可以通过 \`systemctl status nginx\` 查看服务状态。

#### 重启服务

\`\`\`bash
sudo systemctl restart SERVICE
\`\`\`

**示例：**

\`\`\`bash
sudo systemctl restart nginx
\`\`\`

**输出示例：**

\`\`\`bash
[sudo] password for sre:
\`\`\`

**解释：**  
该命令重启 \`nginx\` 服务。如果服务已成功重启，则不会有输出。可以通过 \`systemctl status nginx\` 查看服务状态。

#### 查看服务状态

\`\`\`bash
systemctl status SERVICE
\`\`\`

**示例：**

\`\`\`bash
systemctl status nginx
\`\`\`

**输出示例：**

\`\`\`bash
● nginx.service - A high performance web server and a reverse proxy server
   Loaded: loaded (/lib/systemd/system/nginx.service; enabled; vendor preset: enabled)
   Active: active (running) since Fri 2024-09-06 10:00:00 UTC; 1h 30min ago
     Docs: man:nginx(8)
 Main PID: 1234 (nginx)
    Tasks: 3 (limit: 4915)
   Memory: 6.5M
   CGroup: /system.slice/nginx.service
           ├─1234 nginx: master process /usr/sbin/nginx -g daemon on; master_process on;
           └─1235 nginx: worker process

Sep 06 10:00:00 azlinux-prod-cn systemd[1]: Started A high performance web server and a reverse proxy server.
\`\`\`

**解释：**  
此命令显示 \`nginx\` 服务的详细状态，包括服务的活动状态、进程 ID 和资源使用情况。

#### 列出所有服务

\`\`\`bash
systemctl list-units --type=service
\`\`\`

**示例：**

\`\`\`bash
systemctl list-units --type=service
\`\`\`

**输出示例：**

\`\`\`bash
UNIT                        LOAD   ACTIVE SUB     DESCRIPTION
apache2.service             loaded active running Apache HTTP Server
nginx.service               loaded active running A high performance web server and a reverse proxy server
ssh.service                 loaded active running OpenBSD Secure Shell server
\`\`\`

**解释：**  
该命令列出所有服务单元及其当前状态，包括服务名称、加载状态、活动状态和描述。

#### 设置服务开机自启

\`\`\`bash
sudo systemctl enable SERVICE
\`\`\`

**示例：**

\`\`\`bash
sudo systemctl enable nginx
\`\`\`

**输出示例：**

\`\`\`bash
[sudo] password for sre:
\`\`\`

**解释：**  
该命令将 \`nginx\` 服务设置为开机自启。如果服务已成功配置，则不会有输出。可以使用 \`systemctl is-enabled nginx\` 来验证设置。

#### 禁用服务开机自启

\`\`\`bash
sudo systemctl disable SERVICE
\`\`\`

**示例：**

\`\`\`bash
sudo systemctl disable nginx
\`\`\`

**输出示例：**

\`\`\`bash
[sudo] password for sre:
\`\`\`

**解释：**  
该命令将 \`nginx\` 服务设置为不再开机自启。如果服务已成功配置，则不会有输出。可以使用 \`systemctl is-enabled nginx\` 来验证设置。

### 不同架构下的表现

\`systemctl\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为和输出一致，因此此处不需要额外说明。

### 版本差异

\`systemctl\` 命令的功能在不同版本的Linux系统中通常一致，但在较新的版本中可能会引入新的功能或改进，例如新的选项或更详细的输出格式。

### 安全性和替代命令

\`systemctl\` 是现代 Linux 系统中的标准服务管理工具，没有已知的安全问题，也没有被弃用的风险。它是替代传统的 \`service\` 命令的标准工具。

### 安装

\`systemctl\` 命令包含在 \`systemd\` 包中。你可以使用以下命令来安装：

- **Ubuntu:**

  \`\`\`bash
  sudo apt-get install systemd
  \`\`\`

- **RHEL/CentOS:**

  \`\`\`bash
  sudo yum install systemd
  \`\`\`

### 总结

\`systemctl\` 是一个强大而灵活的工具，用于管理和控制系统服务。通过掌握 \`systemctl\` 的使用，可以有效地管理系统服务和系统状态，确保系统的稳定运行。

        `,
    };
  },
});
</script>
<style scoped></style>
