<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## base64 命令详解

\`base64\` 命令是用于编码和解码数据的常用工具，主要用于在文本文件中存储二进制数据或在网络上传输时将数据转换为一种通用的文本格式。本文将详细介绍 \`base64\` 命令的用法、重要参数以及不同架构或版本下的表现。

### 基本用法

\`base64\` 命令的基本用法非常简单，主要分为编码和解码两部分。

- 编码：将文件或文本数据转换为base64格式。
- 解码：将base64格式的数据转换为原始的二进制格式。

#### 示例：编码文件

\`\`\`bash
echo "Hello, World!" | base64
\`\`\`

**输出：**
\`\`\`
SGVsbG8sIFdvcmxkIQ==
\`\`\`

这个命令将字符串 "Hello, World!" 编码为base64格式。

#### 示例：解码文件

\`\`\`bash
echo "SGVsbG8sIFdvcmxkIQ==" | base64 --decode
\`\`\`

**输出：**
\`\`\`
Hello, World!
\`\`\`

这个命令将先前编码的base64字符串解码为原始文本。

### 重要参数

\`base64\` 命令提供了一些有用的参数，帮助用户更灵活地进行编码和解码操作。

- \`-w, --wrap=COLS\`：设置输出的列宽。默认情况下，每76个字符后会自动换行。如果指定为0，输出将不换行。

\`\`\`bash
echo "Hello, World!" | base64 -w 0
\`\`\`

**输出：**
\`\`\`
SGVsbG8sIFdvcmxkIQ==
\`\`\`

解析：此命令将base64编码的输出设为不换行，适合在需要一行输出的场景使用。

- \`--decode\`：对输入的base64数据进行解码，恢复为原始数据。

\`\`\`bash
echo "SGVsbG8sIFdvcmxkIQ==" | base64 --decode
\`\`\`

**输出：**
\`\`\`
Hello, World!
\`\`\`

解析：\`--decode\` 参数用于将base64编码的数据还原为原始文本。

### 示例：处理二进制文件

\`base64\` 还可以用于编码和解码二进制文件，比如图像文件。

\`\`\`bash
base64 image.png > image.b64
\`\`\`

此命令将 \`image.png\` 文件编码为base64格式，并保存为 \`image.b64\` 文件。

要将其解码回来：

\`\`\`bash
base64 --decode image.b64 > image_decoded.png
\`\`\`

**无输出，命令已成功执行**

解析：此命令将base64编码的图像文件解码回原始的png格式文件。

### 不同架构下的输出对比

\`base64\` 命令的输出在不同架构下通常是一致的，因为它只是对数据进行编码或解码，与硬件架构无关。无论是在 \`aarch64\` 还是 \`x86_64/amd64\` 架构下，编码和解码后的数据应该完全一致。

### 版本差异

较新的 \`base64\` 版本可能会引入一些新的参数或对现有参数的行为进行优化。在较旧的系统中，可能不支持某些新参数，如 \`--wrap\`。在这种情况下，需要使用旧版的参数进行操作。

### 需要root权限的操作

通常情况下，\`base64\` 不需要root权限。但是，如果要编码或解码的文件或数据对当前用户不可访问，则需要使用 \`sudo\` 提升权限。例如：

\`\`\`bash
sudo base64 /var/log/secure > secure.b64
\`\`\`

**无输出，命令已成功执行**

解析：此命令将 \`secure\` 日志文件编码为base64格式，并保存为 \`secure.b64\` 文件。

### 总结

\`base64\` 是一个强大且灵活的工具，适用于在文本环境中处理二进制数据。通过使用其多种参数，用户可以根据需要调整输出格式或处理数据。在不同的架构下，\`base64\` 的表现通常是一致的。无论是编码还是解码，都能轻松应对各种需求。

如果你需要将数据编码为base64格式以便存储或传输，或者解码base64格式的数据以恢复其原始内容，\`base64\` 命令都是不可或缺的工具。

        `,
    };
  },
});
</script>
<style scoped></style>
