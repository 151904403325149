<template>
  <div>
    <GameCard1
      title="主机目录挂载到容器内使用"
      actions="容器数据持久化之--绑定挂载（Bind Mount）"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
## 将本地目录挂载到 \`nginx\` 容器中供容器读取使用

### 1. 创建本地目录和 \`index.html\`

首先，我们需要创建一个本地目录，并在其中放置 \`index.html\` 文件，该文件将展示为 \`nginx\` 的主页。

#### 创建目录和 \`index.html\` 文件：
\`\`\`bash
mkdir ~/nginx-web
cd ~/nginx-web
\`\`\`

在该目录中创建一个名为 \`index.html\` 的文件，并使用以下代码作为内容：

#### \`index.html\` 文件内容(您可以复制粘贴)：
\`\`\`html
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Welcome to My Nginx Server</title>
    <style>
        body {
            background-color: #f0f0f5;
            font-family: Arial, sans-serif;
            color: #333;
            text-align: center;
            padding: 50px;
        }
        h1 {
            color: #ff9800;
            font-size: 3em;
            margin-bottom: 20px;
        }
        p {
            font-size: 1.2em;
        }
        .container {
            background-color: #fff;
            border: 2px solid #ff9800;
            border-radius: 10px;
            padding: 20px;
            display: inline-block;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Welcome to My Nginx Server</h1>
        <p>This is a simple page served by Nginx.</p>
        <p>Enjoy exploring Docker and Nginx!</p>
    </div>
</body>
</html>
\`\`\`

### 2. 使用 \`docker run\` 启动容器并挂载目录

使用以下命令启动 \`nginx\` 容器并将本地的 \`~/nginx-web\` 目录挂载到容器中的 \`/usr/share/nginx/html\` 目录下：

\`\`\`bash
docker run --rm -d -p 8080:80 -v ~/nginx-web:/usr/share/nginx/html nginx
\`\`\`

#### 参数解释：
- \`--rm\`：在容器停止后自动删除容器。
- \`-d\`：将容器作为守护进程在后台运行。
- \`-p 8080:80\`：将主机的 \`8080\` 端口映射到容器的 \`80\` 端口。
- \`-v ~/nginx-web:/usr/share/nginx/html\`：将本地主机的 \`~/nginx-web\` 目录挂载到容器内的 \`nginx\` 网页根目录 \`/usr/share/nginx/html\`，这样 \`nginx\` 就会使用本地的 \`index.html\` 页面。

### 3. 访问网页

启动容器后，打开浏览器并访问 \`http://localhost:8080\`或者\`http://public_ip_addr:8080\`，你将看到以下展示的页面：

- 大标题 "Welcome to My Nginx Server" 以橙色 (#ff9800) 字体显示。
- 页面背景色是浅灰色，内容有圆角边框和阴影效果，使页面看起来美观且简洁。

### 总结

通过使用 \`docker run\` 和挂载本地目录的方式，可以快速地将自定义的 HTML 页面托管到 \`nginx\` 容器中。此方法非常适合开发和测试环境。

在 Docker 中，\`-v ~/nginx-web:/usr/share/nginx/html\` 选项用于将**本地主机目录**挂载到**容器内的目录**。这一挂载方式让容器可以访问并使用主机上的文件系统，使得在本地修改文件时，容器内部能实时反映这些变化。

### 解释挂载选项：

- \`-v\`：表示**挂载卷（volume）**的选项。
- \`~/nginx-web\`：主机上的目录路径（本地的绝对或相对路径）。在这个示例中，是本地的 \`~/nginx-web\` 目录。
- \`/usr/share/nginx/html\`：容器内的目录。在 \`nginx\` 容器中，这是默认的网页文件根目录。

通过这个选项，Docker 将主机的 \`~/nginx-web\` 目录挂载到容器的 \`/usr/share/nginx/html\` 目录下，这意味着容器内部的 \`nginx\` 服务器会使用本地主机目录中的文件（如 \`index.html\`）作为其网页内容。

### 对应的存储技术

Docker 的存储技术涉及几种不同的挂载方式，其中 \`-v\` 选项指的是**绑定挂载（bind mount）**。绑定挂载是 Docker 支持的最早的存储挂载技术之一。

#### 绑定挂载（Bind Mount）
绑定挂载允许将主机的目录或文件直接挂载到容器中的目录。具体特点如下：

1. **主机目录与容器目录直接链接**：
   - 主机上目录或文件的变化会立即体现在容器中，反之亦然（实时同步）。
   - 容器重启时，这些文件仍然会保留在主机上。

2. **灵活性强**：
   - 可以挂载任何主机上的路径，无需 Docker 专门管理这些路径。
   - 使用绑定挂载时，不受限于 Docker 的卷存储方式。管理员可以使用主机文件系统的权限和功能。

3. **没有存储抽象层**：
   - 相比 Docker Volumes（Docker 自管理的卷），绑定挂载没有提供与 Docker 卷类似的隔离或管理工具，依赖于主机的存储管理和权限设置。

4. **典型应用场景**：
   - 主要用于开发环境，例如共享源代码、配置文件或静态资源等场景。开发人员可以在不重启容器的情况下更新主机上的文件，并实时看到容器内服务的效果。

### 示例中的挂载技术

当你使用 \`-v ~/nginx-web:/usr/share/nginx/html\` 时，实际上是通过绑定挂载的方式将主机上的 \`~/nginx-web\` 目录映射到容器的 \`nginx\` 网页根目录。这意味着你可以在本地修改 \`index.html\` 等文件，立即在容器内看到效果，而不需要重新构建 Docker 镜像。

## 两个常见挂载模式ro和rw(默认)
1. **只读模式（\`ro\`）：**
   - **命令格式：**
     \`\`\`bash
     docker run -v /host_path:/container_path:ro image_name
     \`\`\`
   - **说明：**
     使用 \`:ro\` 选项，将卷以只读模式挂载到容器中。容器只能读取该目录，不能修改或删除其内容。
   - **例子：**
     \`\`\`bash
     docker run -v /my_data:/usr/share/nginx/html:ro nginx
     \`\`\`
     在这个例子中，Nginx 容器可以读取 \`/usr/share/nginx/html\` 目录，但不能对其进行任何修改。

2. **读写模式（\`rw\`）(默认模式)：**
   - **命令格式：**
     \`\`\`bash
     docker run -v /host_path:/container_path:rw image_name
     \`\`\`
   - **说明：**
     \`:rw\` 是读写模式的显式声明（默认情况下是读写模式）。如果你希望明确指定容器可以读写文件，可以使用此选项。
   - **例子：**
     \`\`\`bash
     docker run -v /my_data:/usr/share/nginx/html:rw nginx
     \`\`\`
     容器可以在挂载的目录中自由读写。

### 与其他存储技术的对比

1. **Docker Volumes（卷）**：
   - Docker Volumes 是由 Docker 引擎管理的存储卷，通常用于持久化数据，适用于生产环境。卷存储在 Docker 的默认路径下，容器重启或被删除时，卷内的数据仍然可以保留。
   - 使用方式为：\`-v my-volume:/usr/share/nginx/html\`。

2. **tmpfs Mount（临时文件系统挂载）**：
   - \`tmpfs\` 是一种存储在内存中的临时文件系统，数据在容器停止时会丢失，适用于存储敏感或临时数据。
   - 使用方式为：\`--tmpfs /path/in/container\`。

#### 总结：

\`-v ~/nginx-web:/usr/share/nginx/html\` 是**绑定挂载**技术的典型使用场景，通过该技术，可以将主机目录映射到容器内，并且允许实时修改主机上的文件从而反映在容器内。这种方式在开发和测试环境中非常有用，但生产环境中一般推荐使用 Docker 自管理的卷技术来保证数据的持久性和安全性。
        
        `,
      interviewContent: `
### 选择题

1. **在使用 \`docker run -v\` 挂载卷时，哪个符号用于分隔宿主机路径和容器内路径？**
   - A) \`:\` 
   - B) \`;\`
   - C) \`,\`
   - D) \`.\`

2. **以下命令哪个是正确的挂载卷的方法？**
   - A) \`docker run -v /container_path:/host_path image_name\`
   - B) \`docker run -v /host_path:/container_path image_name\`
   - C) \`docker run -v /container_path:/host_path image_name --volume\`
   - D) \`docker run -v /host_path --volume image_name\`

3. **以下哪一项描述 Docker 卷的默认存储位置？**
   - A) \`/usr/local/docker/volumes\`
   - B) \`/var/lib/docker/volumes\`
   - C) \`/home/docker/volumes\`
   - D) \`/etc/docker/volumes\`

4. **哪种情况适合使用 \`docker run -v /host_path:/container_path\`？**
   - A) 当需要将宿主机的目录与容器共享，并且数据需要持久化
   - B) 当不需要共享宿主机数据给容器
   - C) 当容器内的数据不需要持久化
   - D) 当需要禁用容器的文件系统

5. **挂载卷的选项 \`-v /host_path:/container_path:ro\` 中，\`ro\` 的含义是什么？**
   - A) 读写模式
   - B) 只读模式
   - C) 只写模式
   - D) 复制模式

### 问答题

1. **解释 \`docker run -v /host_path:/container_path\` 选项中，宿主机路径和容器路径分别代表什么？**

2. **使用 \`docker run -v\` 挂载的卷有哪些优势？请列出至少两点。**

3. **假设你使用 \`docker run -v /my_data:/usr/share/nginx/html\` 来挂载一个目录。请解释在启动 Nginx 容器后，宿主机上的 \`/my_data\` 目录和容器内的 \`/usr/share/nginx/html\` 目录会发生什么情况？**

4. **\`docker run -v\` 挂载的卷与 \`docker volume create\` 创建的卷有什么区别？请简要说明。**

5. **如果我们需要在运行容器时指定只读模式挂载目录，应该如何实现？请给出命令示例并解释其作用。**

---

### 答案

#### 选择题
1. **A** - \`:\` 用于分隔宿主机路径和容器内路径。
2. **B** - 正确的挂载方法是 \`docker run -v /host_path:/container_path image_name\`，宿主机路径在前，容器路径在后。
3. **B** - \`/var/lib/docker/volumes\` 是 Docker 卷的默认存储位置。
4. **A** - 当需要共享宿主机的目录并持久化数据时，适合使用 \`docker run -v /host_path:/container_path\`。
5. **B** - \`ro\` 表示只读模式。

#### 问答题

1. **解释 \`docker run -v /host_path:/container_path\` 选项中，宿主机路径和容器路径分别代表什么？**
   - 宿主机路径代表在宿主机上要挂载到容器中的文件夹路径，容器路径代表容器内映射到的目标路径。宿主机的文件系统数据将与容器的这个目录同步。

2. **使用 \`docker run -v\` 挂载的卷有哪些优势？请列出至少两点。**
   - (1) 数据持久化：即使容器停止或删除，数据仍保存在宿主机上。
   - (2) 数据共享：可以在多个容器间共享同一个卷，方便数据传递和协同工作。

3. **假设你使用 \`docker run -v /my_data:/usr/share/nginx/html\` 来挂载一个目录。请解释在启动 Nginx 容器后，宿主机上的 \`/my_data\` 目录和容器内的 \`/usr/share/nginx/html\` 目录会发生什么情况？**
   - 宿主机的 \`/my_data\` 目录内容将映射到容器的 \`/usr/share/nginx/html\` 目录中。Nginx 将使用该路径下的内容作为网站根目录。如果宿主机上的文件有变动，容器中的数据也会即时同步。

4. **\`docker run -v\` 挂载的卷与 \`docker volume create\` 创建的卷有什么区别？请简要说明。**
   - \`docker run -v /host_path:/container_path\` 直接将宿主机的指定目录挂载到容器中，而 \`docker volume create\` 创建的卷是 Docker 管理的存储空间，路径由 Docker 自动分配，不需要手动指定宿主机目录。

5. **如果我们需要在运行容器时指定只读模式挂载目录，应该如何实现？请给出命令示例并解释其作用。**
   - 使用 \`docker run -v /host_path:/container_path:ro\`，例如：\`docker run -v /my_data:/usr/share/nginx/html:ro nginx\`。这样 \`/my_data\` 将以只读模式挂载到容器内，容器可以读取该目录内的文件，但不能写入或修改内容。
      
      `,
    };
  },
});
</script>
<style scoped></style>
