<template>
  <div class="list-item" @click="openComponent">
    <p class="item-name">{{ name }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    componentToOpen: {
      type: String,
      required: true,
    },
  },
  methods: {
    openComponent() {
      this.$emit("item-clicked", this.componentToOpen);
    },
  },
};
</script>

<style scoped>
.list-item {
  padding: 20px;
  margin: 10px 0;
  border-radius: 12px;
  background-color: #444; /* 按钮背景色 */
  color: lime;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-weight: bold;
}

.list-item:hover {
  background-color: #555; /* 鼠标悬停时的背景色 */
  transform: translateY(-5px); /* 悬停效果，向上移动 */
}

.list-item:active {
  transform: translateY(2px); /* 点击效果，稍微下移 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* 点击时的阴影效果 */
}

.item-name {
  margin: 0;
}
</style>
