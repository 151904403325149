<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## renice 命令详解

\`renice\` 命令用于调整正在运行的进程的优先级（也称为“nice 值”）。通过改变进程的优先级，可以影响系统如何分配 CPU 资源给不同的进程。降低进程的 nice 值会增加其 CPU 优先级，而增加 nice 值则会降低其优先级。

### 命令格式

\`\`\`bash
renice [选项] 优先级 [PID]
\`\`\`

### 主要参数

- \`优先级\`：指定新的优先级值。可以是 -20 到 19 之间的整数，其中 -20 是最高优先级，19 是最低优先级。
- \`-p\`：指定要调整的进程 ID（PID）。如果不使用此选项，则默认调整当前用户的所有进程。
- \`-g\`：指定进程组 ID（PGID）。
- \`-u\`：指定用户 ID（UID），以调整该用户的所有进程的优先级。

### 使用示例

1. **调整单个进程的优先级**

   假设我们要将进程 ID 为 1234 的进程的优先级设置为 10：

   \`\`\`bash
   sudo renice -n 10 -p 1234
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo renice -n 10 -p 1234
   1234: old priority 0, new priority 10
   \`\`\`

   **解读**：
   - \`-n 10\` 选项设置新的 nice 值为 10。
   - \`-p 1234\` 指定了进程 ID 1234。
   - 输出显示进程的旧优先级和新优先级。

2. **调整某用户所有进程的优先级**

   假设我们要将用户 ID 为 1005 的所有进程的优先级设置为 5：

   \`\`\`bash
   sudo renice -n 5 -u 1005
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo renice -n 5 -u 1005
   \`\`\`

   **解读**：
   - \`-n 5\` 选项设置新的 nice 值为 5。
   - \`-u 1005\` 指定了用户 ID 1005 的所有进程。

3. **调整进程组的优先级**

   假设我们要将进程组 ID 为 5678 的所有进程的优先级设置为 -5：

   \`\`\`bash
   sudo renice -n -5 -g 5678
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo renice -n -5 -g 5678
   \`\`\`

   **解读**：
   - \`-n -5\` 选项设置新的 nice 值为 -5。
   - \`-g 5678\` 指定了进程组 ID 5678。

### 不同架构下的输出

\`renice\` 命令的输出在不同架构下通常是相似的，因为它依赖于进程管理系统，而不是具体的硬件架构。因此，不同架构下的输出通常没有差异。

### 版本差异

\`renice\` 命令的功能在不同版本的操作系统中通常保持一致。版本之间可能会有轻微的语法或功能差异，可以通过运行 \`renice --version\` 来检查当前版本，并查阅相关文档来确认。

### 安全性及替代命令

\`renice\` 命令本身是安全的，但调整进程的优先级可能会影响系统的性能。没有专门的替代命令，但可以通过 \`nice\` 命令设置启动时的优先级：

\`\`\`bash
nice -n 优先级 命令
\`\`\`

### 安装

\`renice\` 命令通常是 Linux 系统的一部分，随操作系统一起安装。若需要安装或更新相关包，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

### 需要root权限的选项

\`renice\` 命令通常需要 root 权限才能调整其他用户的进程的优先级：

\`\`\`bash
sudo renice -n 优先级 -p 进程ID
\`\`\`

**示例输出**：

\`\`\`bash
$ sudo renice -n 10 -p 1234
1234: old priority 0, new priority 10
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以调整其他用户进程的优先级。


        `,
    };
  },
});
</script>
<style scoped></style>
