<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## service 命令详解

\`service\` 命令是用于启动、停止、重启和管理系统服务的工具，主要用于 SysV init 和 Systemd 系统中。虽然在许多现代 Linux 发行版中，\`systemctl\` 被认为是更推荐的命令，但 \`service\` 仍然在某些环境中使用。

### 安装

在大多数 Linux 发行版中，\`service\` 命令是预装的。它通常与 \`sysvinit\` 或 \`systemd\` 一起提供，因此不需要单独安装。

### 基本语法

\`\`\`bash
service [服务名] [动作]
\`\`\`

### 常用选项

- \`start\`：启动指定的服务。
- \`stop\`：停止指定的服务。
- \`restart\`：重启指定的服务。
- \`status\`：查看指定服务的状态。
- \`reload\`：重新加载配置而不重启服务。

### 示例用法

#### 示例一：启动服务

要启动一个服务（例如 \`httpd\`），可以使用以下命令：

\`\`\`bash
service httpd start
\`\`\`

**输出示例**：

\`\`\`plaintext
Starting httpd:                                            [  OK  ]
\`\`\`

**解读**：该命令成功启动了 \`httpd\` 服务，输出 \`[ OK ]\` 表示操作成功。

#### 示例二：停止服务

要停止一个服务，可以使用以下命令：

\`\`\`bash
service httpd stop
\`\`\`

**输出示例**：

\`\`\`plaintext
Stopping httpd:                                           [  OK  ]
\`\`\`

**解读**：该命令成功停止了 \`httpd\` 服务，输出 \`[ OK ]\` 表示操作成功。

#### 示例三：重启服务

要重启一个服务，可以使用以下命令：

\`\`\`bash
service httpd restart
\`\`\`

**输出示例**：

\`\`\`plaintext
Stopping httpd:                                           [  OK  ]
Starting httpd:                                          [  OK  ]
\`\`\`

**解读**：该命令成功重启了 \`httpd\` 服务，首先停止服务然后再启动，输出表明两项操作均成功。

#### 示例四：查看服务状态

要查看一个服务的状态，可以使用以下命令：

\`\`\`bash
service httpd status
\`\`\`

**输出示例**：

\`\`\`plaintext
httpd (pid  1234) is running...
\`\`\`

**解读**：该命令显示 \`httpd\` 服务正在运行，并且提供了进程 ID（pid）。

#### 示例五：重新加载服务配置

要重新加载服务配置，而不停止服务，可以使用以下命令：

\`\`\`bash
service httpd reload
\`\`\`

**输出示例**：

\`\`\`plaintext
Reloading httpd:                                          [  OK  ]
\`\`\`

**解读**：该命令成功重新加载了 \`httpd\` 服务的配置，输出 \`[ OK ]\` 表示操作成功。

### 不同架构的输出

\`service\` 命令在 aarch64 和 x86_64/amd64 架构上的输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

在不同版本的 Linux 发行版中，\`service\` 命令的具体实现可能有所不同。一般来说，较新的版本更倾向于使用 \`systemctl\`。可以通过以下命令检查版本：

\`\`\`bash
service --version
\`\`\`

**输出示例**：

\`\`\`plaintext
service (GNU coreutils) 8.32
\`\`\`

### 权限要求

大多数 \`service\` 命令操作需要 \`root\` 权限。例如，启动或停止服务时，需要使用 \`sudo\`：

\`\`\`bash
sudo service httpd start
\`\`\`

**示例输出**：

\`\`\`plaintext
Starting httpd:                                            [  OK  ]
\`\`\`

### 替代命令

在使用 Systemd 的现代 Linux 发行版中，推荐使用 \`systemctl\` 命令来管理服务。例如：

- 启动服务：\`systemctl start httpd\`
- 停止服务：\`systemctl stop httpd\`
- 查看状态：\`systemctl status httpd\`

### 总结

\`service\` 命令是一个重要的工具，用于管理和控制系统服务。虽然在现代 Linux 系统中逐渐被 \`systemctl\` 取代，但它仍然是某些环境中的实用命令。掌握 \`service\` 命令的使用，可以帮助用户在需要时快速有效地管理服务。

        `,
    };
  },
});
</script>
<style scoped></style>
