<template>
  <div class="man-pages">
    <el-form :model="form" inline>
      <el-form-item label="Command">
        <el-input
          v-model="form.command"
          @input="validateInput"
          required
        ></el-input>
      </el-form-item>
      <el-form-item label="Section">
        <el-select
          v-model="form.section"
          placeholder="Select"
          style="min-width: 6rem"
        >
          <el-option label="Auto" value="auto"></el-option>
          <el-option
            v-for="section in sections"
            :key="section"
            :label="`${section}`"
            :value="section"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Language">
        <el-select
          v-model="form.language"
          placeholder="Select"
          style="min-width: 6rem"
        >
          <el-option label="English" value="en"></el-option>
          <el-option label="Chinese" value="zh" disabled></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-if="result" style="margin-top: 20px">
      <pre class="man-result">{{ result }}</pre>
    </div>
    <div v-if="error" style="margin-top: 20px; color: red">{{ error }}</div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      form: {
        command: "",
        section: "auto",
        language: "en",
      },
      sections: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      result: "",
      error: "",
    };
  },
  methods: {
    validateInput(value) {
      // 使用正则表达式替换不允许的字符
      //   ElMessage.error("存在非法字符");
      this.form.command = value.replace(/[$()`]/g, "");
    },
    async search() {
      try {
        const response = await axios.get(
          `http://man.sre.azlinux.com:5040/man/`,
          {
            params: { ...this.form },
          }
        );
        // 假设response.data是数组，将其转换为字符串
        this.result = Array.isArray(response.data)
          ? response.data.join("\n")
          : response.data;
        this.error = "";
      } catch (error) {
        this.result = "";
        ElMessage({
          message: "产生了异常结果",
          type: "error",
          duration: 3000,
          offset: 240,
        });
        this.error =
          "请检查输入，该命令【" +
          this.form.command +
          "】不存在或未安装帮助手册";
      }
    },
  },
};
</script>

<style scoped>
.man-pages {
  padding: 0.6rem;
}

.man-result {
  text-align: left;
  white-space: pre-wrap; /* 保留空格和换行 */
  /* min-width: 1480px; */
  /* font-family: monospace; 使用等宽字体 */
  font-family: "Consolas", "Courier New", "Menlo", "Monaco", "Ubuntu Mono",
    "DejaVu Sans Mono", monospace;
  background-color: #f5f5f5; /* 设置背景色以区分文本 */
  padding: 1rem; /* 添加一些内边距 */
  border-radius: 4px; /* 圆角边框 */
  border: 1px solid #ddd; /* 边框 */
}
</style>
