<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sleep 命令详解

\`sleep\` 命令用于在脚本或命令行中引入延迟，是Linux系统中常用的工具之一。本文将详细介绍 \`sleep\` 命令的用法，包括其重要参数，并通过示例演示如何使用该命令。模拟输出时，用户名为 \`sre\`，UID为 \`1005\`，用户组为 \`sre-group\`。示例主机名为 \`azlinux-prod-cn\`。

### sleep 命令概述

\`sleep\` 命令用于暂停命令执行指定的时间。时间的单位可以是秒、分钟、小时或天。\`sleep\` 命令属于外部命令，而不是Bash内置命令。该命令在脚本编写中非常有用，常用于定时任务或延时处理。

### 常用参数

1. **时间单位**：
   - \`s\`：秒（默认值）
   - \`m\`：分钟
   - \`h\`：小时
   - \`d\`：天

2. **时间格式**：
   - 可以使用整数或小数表示时间。
   - 支持多种时间单位组合，如 \`1h30m\` 表示1小时30分钟。

### 使用示例

#### 示例1：暂停5秒钟

\`\`\`bash
$ sleep 5
\`\`\`

**解释**：此命令使当前shell会话暂停5秒钟。在此期间不会有任何输出。

\`\`\`bash
$ ls -l
total 0
\`\`\`

**解读**：使用 \`ls -l\` 命令确认当前目录在5秒延迟后正常显示内容。

#### 示例2：暂停2.5分钟

\`\`\`bash
$ sleep 2.5m
\`\`\`

**解释**：此命令将暂停当前shell会话2.5分钟。期间没有任何输出。

\`\`\`bash
$ pwd
/home/sre
\`\`\`

**解读**：使用 \`pwd\` 命令确认当前工作目录在延迟后正确输出。

#### 示例3：暂停1小时30分钟

\`\`\`bash
$ sleep 1h30m
\`\`\`

**解释**：此命令暂停当前shell会话1小时30分钟。期间没有任何输出。

\`\`\`bash
$ file /home/sre/testfile
/home/sre/testfile: ASCII text
\`\`\`

**解读**：使用 \`file\` 命令查看文件类型，确认延迟后操作正常。

#### 示例4：脚本中使用 sleep 命令

\`\`\`bash
#!/bin/bash
echo "开始任务..."
sleep 10s
echo "任务完成！"
\`\`\`

**解释**：该脚本会在输出 "开始任务..." 后暂停10秒，然后输出 "任务完成！"。

\`\`\`bash
$ ./sleeptest.sh
开始任务...
任务完成！
\`\`\`

**解读**：在10秒延迟后，脚本继续执行，最终完成任务。

### 不同架构下的输出差异

\`sleep\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的输出通常没有差异，因此不需要专门列出不同架构下的输出。

### 版本差异

不同版本的 \`sleep\` 命令在功能上没有显著差异，因此版本间输出一致。

### 替代命令

\`sleep\` 命令在当前Linux系统中仍然广泛使用，没有安全性问题或替代需求。

### 安装

\`sleep\` 命令通常预装在大多数Linux发行版中。如果由于某些原因缺少该命令，可以通过以下命令安装：

- **Ubuntu/Debian**:
  \`\`\`bash
  $ sudo apt-get install coreutils
  \`\`\`
- **RHEL/CentOS**:
  \`\`\`bash
  $ sudo yum install coreutils
  \`\`\`

### 使用root权限的示例

\`sleep\` 命令不需要root权限，通常在脚本中使用，以下为一个示例：

\`\`\`bash
$ sudo sleep 5s
\`\`\`

**解释**：此命令以 \`root\` 权限暂停当前shell会话5秒钟。

\`\`\`bash
$ ls /root
\`\`\`

**解读**：可以在延迟后确认以 \`root\` 权限查看 \`/root\` 目录内容。

### 总结

\`sleep\` 命令是一个简单而强大的工具，在脚本中引入延迟、定时操作时非常有用。通过灵活设置时间单位和格式，可以满足各种需求。

        `,
    };
  },
});
</script>
<style scoped></style>
