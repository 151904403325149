<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## yes 命令详解

\`yes\` 命令是一个简单但非常有用的 Linux 命令。它的主要功能是连续不断地输出指定的字符串，默认情况下是输出 "y"。该命令常用于需要持续回答 "yes" 或其他内容的交互场景，避免人工反复输入。

### 基本语法

\`\`\`bash
yes [STRING]...
\`\`\`

- \`STRING\`：可选参数，表示要重复输出的字符串。如果不提供，默认输出 "y"。

### 基本用法

#### 1. 输出连续的 "y"

\`\`\`bash
yes
\`\`\`

**解读**：该命令会一直输出 "y"，直到被手动中断（如通过 \`Ctrl+C\`）。这种用法常见于需要反复确认 "yes" 的场景。

**示例输出**：

\`\`\`bash
y
y
y
y
y
...
\`\`\`

#### 2. 输出自定义字符串

\`\`\`bash
yes "hello"
\`\`\`

**解读**：该命令将不断输出自定义字符串 "hello"。

**示例输出**：

\`\`\`bash
hello
hello
hello
hello
...
\`\`\`

#### 3. 与其他命令配合使用

\`yes\` 命令的常见用途是与其他命令组合，自动确认命令执行时的交互提示。例如，当使用 \`apt-get\` 安装软件时，系统会提示用户确认是否继续安装，\`yes\` 可以自动回应。

\`\`\`bash
yes | sudo apt-get install vim
\`\`\`

**解读**：该命令通过 \`yes\` 自动回答所有的安装确认提示，避免手动输入。

**示例输出**：

\`\`\`bash
Reading package lists... Done
Building dependency tree       
Reading state information... Done
Suggested packages:
  ctags vim-doc vim-scripts
The following NEW packages will be installed:
  vim
0 upgraded, 1 newly installed, 0 to remove and 0 not upgraded.
Need to get 1,024 kB of archives.
Do you want to continue? [Y/n] 
\`\`\`

在上述命令中，\`yes\` 会自动输入 "y"，让安装过程无须人工干预。

### 实际案例

#### 1. 确认大量删除文件

当用户通过 \`rm\` 命令删除大量文件时，如果开启了交互模式（如 \`rm -i\`），系统会要求确认每个文件的删除。\`yes\` 可以通过自动输入 "y" 来简化这个过程。

\`\`\`bash
yes | rm -i *.txt
\`\`\`

**解读**：该命令会自动确认删除当前目录下所有 \`.txt\` 文件，无需手动输入。

#### 2. 测试系统性能

\`yes\` 还可用于生成大量的输出数据，来测试系统的负载处理能力。例如，测试 CPU 或 I/O 性能时，\`yes\` 会通过持续的输出给系统带来负载。

\`\`\`bash
yes > /dev/null
\`\`\`

**解读**：该命令将连续输出 "y" 字符，但输出被重定向到 \`/dev/null\`，因此不会显示在屏幕上。

#### 3. 输出指定字符序列

用户 \`sre\` 想连续输出 "12345"：

\`\`\`bash
sre@azlinux-prod-cn:~$ yes "12345"
\`\`\`

**输出**：

\`\`\`bash
12345
12345
12345
12345
...
\`\`\`

**解读**：\`yes\` 会无止境地输出 "12345"，直到用户按下 \`Ctrl+C\` 中断命令。

### Bash 内置命令与外部命令

\`yes\` 不是 Bash 的内置命令，而是一个独立的外部命令。它通常位于 \`/usr/bin/yes\`，并由 \`coreutils\` 包提供。因此在绝大多数的 Linux 发行版中，\`yes\` 都是系统默认安装的工具。

### 在不同架构上的表现

\`yes\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的表现一致，因为其主要功能是字符串输出，和硬件架构无关。

### 版本差异

\`yes\` 命令本身非常简单，不同版本之间几乎没有功能差异。由于 \`yes\` 是 GNU Coreutils 的一部分，因此大多数发行版中的版本都非常稳定，且功能一致。

### 替代命令

虽然 \`yes\` 命令非常简单，但在一些高级场景中，可以通过 Bash 脚本或者 \`echo\` 命令加循环来替代其功能。比如，下面的 Bash 脚本也可以实现类似 \`yes\` 的效果：

\`\`\`bash
while true; do echo "y"; done
\`\`\`

### 安装

在大多数基于 Debian 的系统（如 Ubuntu）中，\`yes\` 命令属于 \`coreutils\` 包的一部分，不需要额外安装。如果系统中缺少该命令，可以通过以下方式安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 使用权限

\`yes\` 命令本身不需要特殊权限。它的作用仅限于输出字符串，因此即使是普通用户也可以随意使用。

### 总结

\`yes\` 命令是一个简单而有效的工具，适用于自动化交互确认、性能测试等多种场景。它虽然功能单一，但在特定情况下非常有用，尤其是在需要持续输入相同响应的场景中。

        `,
    };
  },
});
</script>
<style scoped></style>
