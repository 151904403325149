<template>
  <div>
    <GameCard1
      title="连接和断开容器终端"
      actions="自由探索: <br>掌握docker attach <br>docker exec<br>docker的Ctrl+P, Ctrl+Q"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
在 Docker 中，容器的连接和断开操作有几种不同的方式，主要包括 \`docker attach\`、\`docker exec\` 和容器的 \`detach\` 操作。这些命令用于连接到容器的终端、在容器中执行命令，以及安全地断开与容器的连接。

### 1. **\`docker attach\`**  
   \`docker attach\` 命令允许你附加到一个正在运行的容器，并与它的标准输入、输出和错误流交互。它常用于连接到一个已经启动的容器，查看输出或与容器的进程交互。

   **用法：**
   \`\`\`bash
   docker attach <container_name_or_id>
   \`\`\`

   - 当你使用 \`docker attach\` 连接到容器时，可以看到容器的标准输出，并通过标准输入进行交互。
   - 适合用于与容器内部正在运行的主进程进行直接交互。
   - 需要注意的是，**退出 \`attach\` 会直接终止容器**，除非以 \`detach\` 模式退出，Ctrl + P, 然后 Ctrl + Q。

---

### 2. **\`docker exec\`**  
   \`docker exec\` 命令允许你在一个正在运行的容器中执行额外的命令。它不会像 \`attach\` 那样直接与容器的主进程连接，而是启动一个新的进程。

   **用法：**
   \`\`\`bash
   docker exec -it <container_name_or_id> <command>
   \`\`\`

   例如，在容器中打开一个新的 Bash 终端：
   \`\`\`bash
   docker exec -it <container_name_or_id> /bin/bash
   \`\`\`

   - \`-i\` 表示交互式，保持标准输入打开。
   - \`-t\` 表示分配一个伪终端。

   **优点：**
   - \`docker exec\` 不会中断容器的主进程，可以在容器运行的同时执行其他命令或启动交互式 shell。
   - 常用于调试或检查容器的内部状态。

---

### 3. **\`detach\`（从容器中断开连接）**
   当你附加到容器时（例如使用 \`docker attach\` 或 \`docker exec\`），可以通过以下方式安全地断开连接而不关闭容器。

   **分离的快捷键：**
   - **\`Ctrl + P\` 和 \`Ctrl + Q\`**  
     使用 \`docker attach\` 附加到容器后，如果你想断开连接并保持容器继续运行，可以按下 \`Ctrl + P\` 然后 \`Ctrl + Q\` 组合键。这会使你安全地脱离容器而不会停止它。

---

### 比较与使用场景

| 命令            | 作用                                                | 适用场景                                          |
|-----------------|---------------------------------------------------|-------------------------------------------------|
| **\`docker attach\`** | 连接到正在运行的容器的主进程，显示标准输出并可以交互 | 适合查看容器的实时输出、调试或与主进程交互            |
| **\`docker exec\`**   | 在容器中执行一个新的命令，启动新进程                     | 适合在容器中执行新命令或调试，如进入容器 Bash 终端    |
| **\`detach\`**    | 从容器中断开连接而不停止容器                         | 安全断开连接，不终止容器进程（\`Ctrl + P\` + \`Ctrl + Q\`）|

---

### 例子

1. **附加到容器的输出：**
   \`\`\`bash
   docker attach my_container
   \`\`\`
   你将看到容器的标准输出，并与之交互。

2. **在容器中执行命令：**
   \`\`\`bash
   docker exec -it my_container /bin/bash
   \`\`\`
   你将在容器中进入一个交互式的 Bash shell。

3. **断开容器连接（保持运行）：**
   使用 \`docker attach\` 后，按 \`Ctrl + P\` 然后 \`Ctrl + Q\`，安全地退出容器的终端，但容器仍在后台继续运行。

---

### 总结

- **\`docker attach\`**：连接到容器主进程，查看输出并交互，但退出可能会停止容器。
- **\`docker exec\`**：在容器中执行额外的命令，不会影响主进程，非常适合调试和排错。
- **\`detach\`**：通过 \`Ctrl + P\` + \`Ctrl + Q\` 安全退出容器的终端，保持容器继续运行。

## 常见错误

在使用 \`docker exec\` 时，可能会遇到一些常见的错误。以下是一些常见的报错类型、原因以及解决方法：

### 1. **错误：\`OCI runtime exec failed: exec failed: container_linux.go:346: starting container process caused "exec: \\"<command>\\": executable file not found in $PATH": unknown\`**

**原因：**  
该错误表明你试图执行的命令在容器的 \`$PATH\` 环境变量中无法找到，或者该命令在容器中不存在。通常是因为容器内没有安装相关工具或命令的路径不正确。

**解决方法：**  
- 确认命令是否在容器中存在，尤其是执行诸如 \`/bin/bash\` 之类的命令时。
- 如果执行的是 Bash 命令，确保容器中确实安装了 Bash，如果没有安装，可以改用 \`/bin/sh\`：
  \`\`\`bash
  docker exec -it <container_id> /bin/sh
  \`\`\`
- 使用 \`docker exec -it <container_id> /bin/sh\` 检查容器内可用的 shell，或者用 \`which <command>\` 检查命令是否在 \`$PATH\` 中。

---

### 2. **错误：\`Error response from daemon: Container <container_id> is not running\`**

**原因：**  
该错误表示你尝试在一个没有运行的容器中执行命令。\`docker exec\` 只能在**正在运行**的容器中执行命令，如果容器已停止，无法执行任何命令。

**解决方法：**  
- 使用 \`docker ps -a\` 检查容器的状态。
- 如果容器已经停止，需要先重新启动容器：
  \`\`\`bash
  docker start <container_id>
  \`\`\`
  然后再执行 \`docker exec\` 命令。

---

### 3. **权限相关的错误：\`permission denied\`**

**原因：**  
这个错误通常表示你尝试执行的命令或操作没有权限。这可能是因为你使用了一个没有足够权限的用户在容器内执行操作。

**解决方法：**
- 使用 \`docker exec\` 时指定以 \`root\` 权限运行：
  \`\`\`bash
  docker exec -u root -it <container_id> <command>
  \`\`\`
- 如果容器中需要其他特定用户执行命令，请确保指定正确的用户。       
        `,
      interviewContent: `
### 选择题

#### 1. **\`docker attach\` 的作用是什么？**  
   A) 允许在运行容器中执行新的命令  
   B) 允许附加到正在运行容器的标准输入、输出和错误流  
   C) 停止正在运行的容器  
   D) 创建一个新的容器

#### 2. **\`docker exec\` 和 \`docker attach\` 的主要区别是什么？**  
   A) \`docker exec\` 允许执行新命令，而 \`docker attach\` 只是附加到当前容器的输出流  
   B) \`docker attach\` 会启动一个新容器，而 \`docker exec\` 只能附加到现有容器  
   C) \`docker exec\` 需要容器停止后才能使用  
   D) \`docker attach\` 用于启动容器内部的 bash 会话

#### 3. **当使用 \`docker attach\` 连接到容器时，如何安全地断开连接而不终止容器？**  
   A) \`Ctrl + C\`  
   B) \`Ctrl + P\` + \`Ctrl + Q\`  
   C) \`docker stop <container_id>\`  
   D) \`docker detach\`

#### 4. **使用 \`docker exec\` 可以在容器内做什么操作？**  
   A) 创建并启动新容器  
   B) 重新启动容器  
   C) 在运行中的容器中执行新的命令  
   D) 查看容器的日志

#### 5. **下列哪种情况下你更适合使用 \`docker exec\`？**  
   A) 希望查看容器的输出  
   B) 需要在容器中运行一个新命令或进程  
   C) 需要停止容器  
   D) 需要监控容器的资源使用情况

---

### 问答题

#### 1. **简述 \`docker exec\` 和 \`docker attach\` 的主要区别以及各自的适用场景。**

#### 2. **当使用 \`docker attach\` 时，如何退出而不停止容器？解释它与 \`Ctrl + C\` 的区别。**

#### 3. **\`docker exec\` 有什么优点？举例说明如何在一个运行中的容器内启动一个交互式 shell。**

#### 4. **\`docker attach\` 在什么场景下是有用的？它是否适合调试容器内部的应用，为什么？**

#### 5. **描述 \`docker exec\` 在调试和管理容器时的优势，并解释为什么它比 \`docker attach\` 更常用于执行容器内的新命令。**

---

### 答案

#### 选择题答案：

1. **B) 允许附加到正在运行容器的标准输入、输出和错误流**  
   \`docker attach\` 附加到容器的标准 I/O 流，与容器内的主进程交互。

2. **A) \`docker exec\` 允许执行新命令，而 \`docker attach\` 只是附加到当前容器的输出流**  
   \`docker exec\` 启动一个新进程，而 \`docker attach\` 只是附加到已有进程的 I/O。

3. **B) \`Ctrl + P\` + \`Ctrl + Q\`**  
   使用 \`docker attach\` 时，\`Ctrl + P\` 然后 \`Ctrl + Q\` 可以安全地断开连接而不终止容器。

4. **C) 在运行中的容器中执行新的命令**  
   \`docker exec\` 可以在容器中执行新命令，而不影响容器的主进程。

5. **B) 需要在容器中运行一个新命令或进程**  
   \`docker exec\` 适用于在容器内启动新的进程或命令。

---

#### 问答题答案：

1. **\`docker exec\` 和 \`docker attach\` 的主要区别及适用场景：**  
   - \`docker exec\` 启动一个新进程，可以在容器中运行额外的命令，常用于调试、进入容器的 shell。
   - \`docker attach\` 连接到正在运行的容器主进程的 I/O，通常用于查看实时输出或直接与主进程交互。  
   **场景：**
   - 如果需要进入容器中执行其他操作，使用 \`docker exec\`。
   - 如果只想查看输出或与主进程交互，使用 \`docker attach\`。

2. **如何退出 \`docker attach\` 而不停止容器：**  
   使用 \`Ctrl + P\` + \`Ctrl + Q\` 安全退出，容器继续运行。如果使用 \`Ctrl + C\`，则可能终止容器的主进程并导致容器停止。

3. **\`docker exec\` 的优点：**  
   \`docker exec\` 可以在不干扰容器主进程的情况下执行新命令或进程，常用于调试。举例：
   \`\`\`bash
   docker exec -it <container_id> /bin/bash
   \`\`\`
   这会在容器中启动一个交互式的 Bash shell 以便进行操作。

4. **\`docker attach\` 的适用场景：**  
   \`docker attach\` 适合查看容器主进程的实时输出，例如运行日志或标准输出，不适合调试，因为它无法启动新的命令或进程，只能附加到现有的主进程。

5. **\`docker exec\` 的优势：**  
   \`docker exec\` 可以灵活地在容器中执行任意命令，而不依赖容器的主进程，适合调试和运行额外任务。与 \`docker attach\` 不同，它不会阻塞主进程的输出流或影响容器的运行。
      
      `,
    };
  },
});
</script>
<style scoped></style>
