<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## usermod 命令详解

\`usermod\` 命令用于修改现有用户账户的属性。通过 \`usermod\` 命令，你可以更改用户的用户名、用户组、用户的登录目录等。这是系统管理员管理用户账户时的重要工具。

### 语法

\`\`\`bash
usermod [OPTION]... LOGIN
\`\`\`

### 重要参数

- \`-l, --login NEW_LOGIN\`：更改用户的登录名。
- \`-d, --home NEW_HOME\`：更改用户的家目录，并使用 \`-m\` 选项移动现有文件到新的家目录。
- \`-m, --move-home\`：将用户的家目录内容从旧目录移动到新目录（配合 \`-d\` 使用）。
- \`-g, --gid GROUP\`：更改用户的主用户组。
- \`-a, --append\`：将用户添加到指定的附加组中，而不删除用户已经属于的其他组（配合 \`-G\` 使用）。
- \`-G, --groups GROUPS\`：设置用户所属的附加组，用逗号分隔。
- \`-s, --shell SHELL\`：更改用户的登录 shell。
- \`-u, --uid UID\`：更改用户的 UID。
- \`-e, --expiredate EXPIRE_DATE\`：设置账户的过期日期。
- \`-L, --lock\`：锁定用户账户。
- \`-U, --unlock\`：解锁用户账户。

### 示例用法

#### 更改用户名

\`\`\`bash
sudo usermod -l newusername oldusername
\`\`\`

**示例输出**：

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读**：
- \`-l newusername oldusername\`：将用户名从 \`oldusername\` 更改为 \`newusername\`。

#### 更改用户的家目录

\`\`\`bash
sudo usermod -d /newhome -m username
\`\`\`

**示例输出**：

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读**：
- \`-d /newhome\`：将用户 \`username\` 的家目录更改为 \`/newhome\`。
- \`-m\`：将现有的家目录文件移动到新的家目录。

#### 更改用户所属的附加组

\`\`\`bash
sudo usermod -a -G newgroup username
\`\`\`

**示例输出**：

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读**：
- \`-a -G newgroup\`：将用户 \`username\` 添加到附加组 \`newgroup\`。

#### 更改用户的登录 shell

\`\`\`bash
sudo usermod -s /bin/zsh username
\`\`\`

**示例输出**：

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读**：
- \`-s /bin/zsh\`：将用户 \`username\` 的登录 shell 更改为 \`/bin/zsh\`。

#### 锁定用户账户

\`\`\`bash
sudo usermod -L username
\`\`\`

**示例输出**：

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读**：
- \`-L\`：锁定用户 \`username\` 的账户，禁止用户登录。

### 不同架构下的输出

\`usermod\` 命令的输出在不同架构下通常没有差异。

### 版本差异

\`usermod\` 命令的功能在不同版本中基本一致，但某些参数的支持可能会有所不同。确保使用的版本支持所需的参数。

### 替代命令

\`usermod\` 是管理用户账户的标准命令，不存在直接的替代命令。对于更复杂的用户管理任务，可能需要结合使用其他命令如 \`adduser\`、\`deluser\` 或 \`passwd\`。

### 安装

\`usermod\` 命令通常包含在 \`passwd\` 包中，该包在大多数 Linux 发行版中默认安装。

- **Ubuntu**：
  - \`passwd\` 包通常已经预装。可以通过以下命令检查是否安装：
    \`\`\`bash
    dpkg -l | grep passwd
    \`\`\`
  - 如未安装，可使用以下命令安装：
    \`\`\`bash
    sudo apt-get install passwd
    \`\`\`

- **RHEL**：
  - \`passwd\` 包通常已经预装。可以通过以下命令检查是否安装：
    \`\`\`bash
    rpm -q passwd
    \`\`\`
  - 如未安装，可使用以下命令安装：
    \`\`\`bash
    sudo yum install passwd
    \`\`\`

### root权限

\`usermod\` 命令需要 root 权限才能执行。使用 \`sudo\` 或以 root 用户身份运行命令。例如：

\`\`\`bash
sudo usermod -l newusername oldusername
\`\`\`

### 参考

\`usermod\` 是系统用户管理的重要工具，允许管理员在用户账户创建后进行各种修改。使用该命令时请小心操作，以确保不会对用户的工作环境和系统稳定性产生负面影响。

        `,
    };
  },
});
</script>
<style scoped></style>
