<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="公司智能手表新业务启动，招聘了80个外包同学辅助完成任务，请帮创建系统账号, 无需设置密码。"
      source="外包管理中心的需求-测试环境-未接入账号中心-请完成"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="40" />
      <SalaryMedal salaryType="1" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdBashFor",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10063",
      taskOS: "Rocky",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
### 1. **\`useradd -m\` 参数**

\`useradd\` 是 Linux 中用于创建新用户的命令。\`-m\` 参数的作用是为新用户创建一个**主目录**（home directory）。

- **\`-m\`**：创建用户的同时在 \`/home\` 目录下为该用户生成一个主目录。如果用户不存在主目录，系统会自动创建一个与用户名同名的目录。比如，创建用户 \`wb20300001\` 时，会在 \`/home/wb20300001\` 目录下生成主目录。
  
#### 举例
\`\`\`bash
sudo useradd -m username
\`\`\`
执行这条命令后，系统会为用户 \`username\` 创建主目录 \`/home/username\`，并将一些默认配置文件（如 \`.bashrc\`）复制到该目录中。
您创建出来的用户默认shell可能是/bin/sh, 所以更流行的用法如下:
\`\`\`bash
sudo useradd -m -s /bin/bash username
\`\`\`

### 2. **\`seq -w\` 参数**

\`seq\` 命令用于生成一系列数字。\`-w\` 参数的作用是保持输出的数字宽度一致，**用前导零填充数字**，使输出的数字具有固定的位数。

- **\`-w\`**：表示输出的数字用前导零进行填充，使其具有相同的宽度。例如，如果需要输出 \`01\` 到 \`80\`，而不是 \`1\` 到 \`80\`，可以使用 \`-w\` 参数。

#### 举例
\`\`\`bash
seq -w 1 10
\`\`\`
输出结果为：
\`\`\`
01
02
03
04
05
06
07
08
09
10
\`\`\`
\`-w\` 参数保证了每个数字都是两位数，自动在前面填充了零。  
对于固定宽度的数字生成（比如账号规则中要求 \`wb20300001\`、\`wb20300002\`），这个参数非常有用。

  `,
      markdownContentExpand: `
### Bash 中的 \`for\` 循环使用说明

\`for\` 循环是 Bash 脚本中用于遍历列表或一系列值的常用控制结构。它可以用来重复执行特定的命令，直到遍历完所有的列表项。

#### 语法格式

\`\`\`bash
for variable in list
do
    commands
done
\`\`\`

- **\`variable\`**: 循环中的临时变量，用来存储当前循环项的值。
- **\`list\`**: 需要遍历的值的列表，可以是明确的数字、字符串，或者是通过命令生成的值。
- **\`commands\`**: 每次循环时执行的命令块。

#### 使用 \`for\` 循环的几种方式

1. **循环遍历指定值**

\`\`\`bash
#!/bin/bash
for i in 1 2 3 4 5
do
    echo "Number: $i"
done
\`\`\`

**输出**：
\`\`\`
Number: 1
Number: 2
Number: 3
Number: 4
Number: 5
\`\`\`

2. **使用 Brace Expansion** (\`{}\`)

Brace Expansion 用于生成序列值。可以结合 \`for\` 循环生成一系列数字或字符。

\`\`\`bash
#!/bin/bash
for i in {1..5}
do
    echo "Number: $i"
done
\`\`\`

**输出**：
\`\`\`
Number: 1
Number: 2
Number: 3
Number: 4
Number: 5
\`\`\`

3. **使用 \`seq\` 命令**

\`seq\` 命令可以生成一组数字，并允许指定步长。

\`\`\`bash
#!/bin/bash
for i in $(seq 1 2 10) # 从1开始，每次增加2，直到10
do
    echo "Number: $i"
done
\`\`\`

**输出**：
\`\`\`
Number: 1
Number: 3
Number: 5
Number: 7
Number: 9
\`\`\`

4. **遍历文件或目录**

可以使用 \`for\` 循环遍历目录中的文件：

\`\`\`bash
#!/bin/bash
for file in /path/to/directory/*
do
    echo "Processing $file"
done
\`\`\`

**输出**：
\`\`\`
Processing /path/to/directory/file1
Processing /path/to/directory/file2
...
\`\`\`

5. **遍历命令的输出**

可以将命令的输出结果作为循环的输入：

\`\`\`bash
#!/bin/bash
for user in $(cat /etc/passwd | cut -d ':' -f 1)
do
    echo "User: $user"
done
\`\`\`

**输出**：
\`\`\`
User: root
User: daemon
User: bin
...
\`\`\`

### 总结

Bash 的 \`for\` 循环是一种强大的工具，可以遍历列表、文件、命令输出等，实现重复任务的自动化处理。  
通过不同的语法结构，可以根据具体场景灵活使用。

  `,
      markdownInterview: ` 
#### 选择题

1. **以下哪个 \`useradd\` 命令会在 \`/home\` 目录下创建用户的主目录？**
   - A) \`useradd username\`
   - B) \`useradd -d username\`
   - C) \`useradd -m username\`
   - D) \`useradd -M username\`

2. **使用 \`seq\` 命令生成从 \`5\` 到 \`10\` 的数字序列，且每个数字用前导零补足到两位数，哪条命令正确？**
   - A) \`seq 5 10\`
   - B) \`seq -w 5 10\`
   - C) \`seq -l 5 10\`
   - D) \`seq -p 5 10\`

3. **在 \`bash\` 中，以下哪个 \`for\` 循环会正确输出从 \`1\` 到 \`5\` 的数字？**
   - A) \`for i in {1..5}; do echo $i; done\`
   - B) \`for i in $(seq 1 5); do echo $i; done\`
   - C) \`for i in $(1 5); do echo $i; done\`
   - D) A 和 B 都正确

4. **\`useradd\` 命令中，以下哪一个选项是用于设置用户密码的？**
   - A) \`-m\`
   - B) \`-d\`
   - C) \`-e\`
   - D) \`-p\`

5. **要创建用户但不为其创建主目录，应使用哪个 \`useradd\` 选项？**
   - A) \`-M\`
   - B) \`-m\`
   - C) \`-N\`
   - D) \`-D\`

---

#### 问答题

1. **解释 \`useradd\` 命令中的 \`-m\` 和 \`-d\` 参数的作用。**

2. **使用 \`seq\` 命令生成从 \`10\` 到 \`50\` 且每步递增 5 的数字序列。**

3. **编写一个 \`for\` 循环，它将从 \`1\` 到 \`10\` 的数字打印出来，数字要按递增顺序输出。**

4. **如何使用 \`useradd\` 命令创建一个用户，并为其设置账户过期时间为 30 天？**

5. **编写一个 \`bash\` 脚本，创建 5 个用户，用户命名为 \`test01\` 到 \`test05\`，且每个用户都有自己的主目录。**

---

### 答案部分

#### 选择题答案

1. **C)** \`useradd -m username\`  
   解释：\`-m\` 选项用于为用户创建主目录。

2. **B)** \`seq -w 5 10\`  
   解释：\`-w\` 选项用前导零填充数字，使数字具有一致的宽度。
   请练习seq -w 1 20, seq -w 9 100, 来观察-w参数的效果。

3. **D)** A 和 B 都正确  
   解释：\`for\` 循环可以使用 \`{1..5}\` 或 \`$(seq 1 5)\` 生成序列。

4. **D)** \`-p\`  
   解释：\`-p\` 选项用于设置用户密码，但需要提供经过加密的密码。

5. **A)** \`-M\`  
   解释：\`-M\` 选项用于创建用户但不生成主目录。

---

#### 问答题答案

1. **\`-m\` 和 \`-d\` 参数解释**：
   - \`-m\`：创建用户时为其生成主目录。
   - \`-d\`：指定用户主目录的路径，而不是默认的 \`/home/username\`。

2. **生成从 \`10\` 到 \`50\`，步长为 5 的序列**：
   \`\`\`bash
   seq 10 5 50
   \`\`\`

3. **\`for\` 循环打印 1 到 10 的数字**：
   \`\`\`bash
   for i in {1..10}; do
       echo $i
   done
   \`\`\`

4. **为用户设置账户过期时间为 30 天**：
   \`\`\`bash
   sudo useradd -e $(date -d "+30 days" +%Y-%m-%d) username
   \`\`\`

5. **创建 5 个用户，\`test01\` 到 \`test05\`**：
   \`\`\`bash
   #!/bin/bash
   for i in $(seq -w 1 5); do
       sudo useradd -m "test\${i}"
   done
   \`\`\`

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
