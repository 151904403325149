<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## id 命令详解

\`id\` 命令用于显示用户和组的身份信息，包括用户ID（UID）、组ID（GID）以及用户所属的附加组。这是一个非常有用的命令，可以帮助系统管理员和用户了解其账户的详细信息。

### 使用语法

\`\`\`bash
id [选项] [用户名]
\`\`\`

### 重要参数

- \`-u\` 或 \`--user\`：显示指定用户的 UID。
- \`-g\` 或 \`--group\`：显示指定用户的 GID。
- \`-G\` 或 \`--groups\`：显示指定用户的所有附加组。
- \`-n\` 或 \`--name\`：以名称的形式显示 UID 和 GID。
- \`-r\` 或 \`--real\`：显示真实的 UID 和 GID，而不是有效的（如果不同）。
- \`-a\` 或 \`--all\`：显示所有信息，包括 UID、GID 和所有附加组。
- \`-h\` 或 \`--help\`：显示帮助信息。

### 基本用法

#### 例1：显示当前用户的 UID 和 GID

\`\`\`bash
id
\`\`\`

**输出解释**:
该命令显示当前用户的 UID、GID 及所属的附加组。

\`\`\`bash
uid=1005(sre) gid=1005(sre-group) groups=1005(sre-group),10(wheel)
\`\`\`

**解读**:
- \`uid=1005(sre)\`：当前用户的 UID 是 1005，用户名是 \`sre\`。
- \`gid=1005(sre-group)\`：当前用户的 GID 是 1005，主组名是 \`sre-group\`。
- \`groups=1005(sre-group),10(wheel)\`：当前用户属于 \`sre-group\` 和 \`wheel\` 两个附加组。

#### 例2：显示指定用户的 UID 和 GID

\`\`\`bash
id dev01
\`\`\`

**输出解释**:
该命令显示 \`dev01\` 用户的 UID 和 GID 及所属附加组。

\`\`\`bash
uid=1006(dev01) gid=1006(dev01-group) groups=1006(dev01-group)
\`\`\`

**解读**:
- \`uid=1006(dev01)\`：用户 \`dev01\` 的 UID 是 1006。
- \`gid=1006(dev01-group)\`：用户 \`dev01\` 的 GID 是 1006。
- \`groups=1006(dev01-group)\`：用户 \`dev01\` 只属于 \`dev01-group\` 这个附加组。

#### 例3：显示当前用户的附加组

\`\`\`bash
id -G
\`\`\`

**输出解释**:
该命令仅显示当前用户所属的所有附加组的 GID。

\`\`\`bash
1005 10
\`\`\`

**解读**:
当前用户所属的附加组的 GID 为 1005 和 10。

#### 例4：显示当前用户的附加组名称

\`\`\`bash
id -Gn
\`\`\`

**输出解释**:
该命令以名称形式显示当前用户所属的所有附加组。

\`\`\`bash
sre-group wheel
\`\`\`

**解读**:
当前用户属于 \`sre-group\` 和 \`wheel\` 两个附加组。

### 不同架构下的表现差异

\`id\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下表现一致。这是因为 \`id\` 命令是由用户空间的工具提供的，与具体的硬件架构无关。

### 不同版本的差异

\`id\` 命令在不同版本的 Linux 系统中表现基本一致。但在某些旧版本中，可能不支持某些新选项。可以通过 \`id --version\` 命令查看当前版本，并参考版本发布说明了解具体差异。

### 安全性与替代命令

\`id\` 命令是一个安全的工具，不存在被淘汰的情况。它在所有现代 Linux 发行版中都被广泛使用，没有直接的替代命令。

**需要 root 权限的选项**:
- \`id\` 命令本身不需要 root 权限来运行，但显示其他用户的详细信息可能需要适当的权限。在普通用户下查看自己信息无需 root 权限。

        `,
    };
  },
});
</script>
<style scoped></style>
