<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## hostnamectl 命令详解

\`hostnamectl\` 是一个用于查询和更改系统主机名的命令行工具，属于 systemd 系列工具。它可以用于获取当前主机名、设置新的主机名、获取系统信息等。与旧的 \`hostname\` 命令相比，\`hostnamectl\` 提供了更丰富的功能和选项。

### 安装

在大多数 Linux 发行版中，\`hostnamectl\` 默认随 systemd 安装，因此无需单独安装。如果使用的是较旧的系统，可能需要更新到最新版本。

#### Ubuntu

\`\`\`bash
sudo apt install systemd
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install systemd
\`\`\`

### 基本语法

\`\`\`bash
hostnamectl [选项] [参数]
\`\`\`

- **选项**：用于指定操作类型。
- **参数**：要设置的新主机名或其他相关信息。

### 常用选项

- \`status\`：显示当前主机名和相关信息。
- \`set-hostname <name>\`：设置新的主机名。
- \`set-chassis <chassis>\`：设置硬件外壳类型。
- \`set-location <location>\`：设置物理位置。

### 示例用法

#### 示例一：查看当前主机名

使用 \`status\` 选项可以查看当前的主机名和其他信息：

\`\`\`bash
hostnamectl status
\`\`\`

**输出**：

\`\`\`plaintext
   Static hostname: azlinux-prod-cn
         Icon name: computer-vm
           Chassis: vm
        Machine ID: 0123456789abcdef0123456789abcdef
           Boot ID: 1234567890abcdef1234567890abcdef
  Operating System: Ubuntu 20.04 LTS
            Kernel: Linux 5.4.0-42-generic
      Architecture: x86-64
\`\`\`

**解读**：该命令输出了当前主机的静态主机名、图标名称、外壳类型、操作系统信息和架构等。可以清晰地看到主机名为 \`azlinux-prod-cn\`。

#### 示例二：设置新的主机名

要设置新的主机名，可以使用 \`set-hostname\` 选项：

\`\`\`bash
sudo hostnamectl set-hostname new-hostname
\`\`\`

**输出**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

**解读**：此命令成功地将主机名更改为 \`new-hostname\`。要验证更改，可以再次运行 \`hostnamectl status\`。

#### 示例三：查看更改后的主机名

可以再次查看主机名以验证更改是否成功：

\`\`\`bash
hostnamectl status
\`\`\`

**输出**：

\`\`\`plaintext
   Static hostname: new-hostname
         Icon name: computer-vm
           Chassis: vm
        Machine ID: 0123456789abcdef0123456789abcdef
           Boot ID: 1234567890abcdef1234567890abcdef
  Operating System: Ubuntu 20.04 LTS
            Kernel: Linux 5.4.0-42-generic
      Architecture: x86-64
\`\`\`

**解读**：主机名已经成功更新为 \`new-hostname\`。

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上的 \`hostnamectl\` 命令输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

\`hostnamectl\` 可能会在不同版本的 systemd 中有所不同。使用以下命令检查当前版本：

\`\`\`bash
hostnamectl --version
\`\`\`

**输出示例**：

\`\`\`plaintext
hostnamectl 245
\`\`\`

在较早的版本中，某些功能可能不完全支持，例如 \`set-chassis\` 和 \`set-location\` 选项。

### 权限要求

设置主机名通常需要 root 权限。因此，当使用 \`set-hostname\` 命令时，前面需要加上 \`sudo\`。以下是一个示例：

\`\`\`bash
sudo hostnamectl set-hostname another-hostname
\`\`\`

#### 示例输出

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 总结

\`hostnamectl\` 是一个功能强大的工具，用于管理系统的主机名和其他相关信息。通过使用该命令，用户可以轻松查询和更改主机名，提升系统的可管理性。了解 \`hostnamectl\` 的使用，对于系统管理员和普通用户都是非常重要的。

        `,
    };
  },
});
</script>
<style scoped></style>
