<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## nc 命令详解

\`nc\`（Netcat）是一款网络工具，广泛用于网络调试和数据传输。它可以作为一个简易的网络连接工具，用于建立TCP或UDP连接、监听端口、进行数据传输等。由于其功能强大和灵活性，\`nc\`被称为“网络的瑞士军刀”。

### 基本用法

\`nc\`可以用于多种场景，以下是一些常见用法的介绍。

#### 1. 连接到远程主机

要连接到远程主机的特定端口，可以使用以下命令：

\`\`\`bash
nc [hostname] [port]
\`\`\`

**示例：**

\`\`\`bash
nc example.com 80
\`\`\`

**解释：**
这将连接到\`example.com\`的80端口。如果连接成功，你会看到命令行没有任何提示，表示你已经成功连接到远程主机。

#### 2. 监听本地端口

要在本地机器上监听特定端口，可以使用以下命令：

\`\`\`bash
nc -l [port]
\`\`\`

**示例：**

\`\`\`bash
nc -l 12345
\`\`\`

**解释：**
此命令将使\`nc\`在本地机器的12345端口上监听。你可以使用另一个\`nc\`实例连接到此端口进行通信。

#### 3. 发送数据

你可以通过管道将数据发送到远程主机：

\`\`\`bash
echo "Hello" | nc [hostname] [port]
\`\`\`

**示例：**

\`\`\`bash
echo "Hello, world!" | nc example.com 12345
\`\`\`

**解释：**
这将发送字符串"Hello, world!"到\`example.com\`的12345端口。

#### 4. 接收数据

在本地端口上监听并接收数据：

\`\`\`bash
nc -l [port] > [file]
\`\`\`

**示例：**

\`\`\`bash
nc -l 12345 > received_file.txt
\`\`\`

**解释：**
此命令将在本地端口12345上监听，并将接收到的数据保存到\`received_file.txt\`文件中。

#### 5. 扫描端口

\`nc\`可以用于端口扫描，通过指定端口范围：

\`\`\`bash
nc -zv [hostname] [port_start]-[port_end]
\`\`\`

**示例：**

\`\`\`bash
nc -zv example.com 1-100
\`\`\`

**解释：**
此命令将扫描\`example.com\`的1到100端口，检查这些端口是否开放。

### 重要参数

- \`-l\`：监听模式，指定\`nc\`监听一个端口以接受连接。
- \`-z\`：扫描模式，不发送任何数据，只进行端口扫描。
- \`-v\`：详细模式，显示更多信息，例如连接过程中的状态。
- \`-u\`：使用UDP协议而非默认的TCP协议。
- \`-p\`：指定本地端口。

### 示例输出

#### 连接到远程主机

\`\`\`bash
$ nc example.com 80
\`\`\`

**输出：**

【无输出，命令已成功执行】

#### 监听本地端口

\`\`\`bash
$ nc -l 12345
\`\`\`

**输出：**

【无输出，命令已成功执行】

#### 发送数据

\`\`\`bash
$ echo "Hello, world!" | nc example.com 12345
\`\`\`

**输出：**

【无输出，命令已成功执行】

#### 接收数据

\`\`\`bash
$ nc -l 12345 > received_file.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

### 不同架构的输出

\`nc\`的输出在不同架构下（aarch64和x86_64/amd64）通常没有差异，因为\`nc\`是标准化的网络工具。然而，不同的操作系统和版本可能会导致略有不同的输出。

### 版本差异

\`nc\`的不同版本可能在命令选项和输出格式上有所不同。请参考\`nc\`的手册页（\`man nc\`）以了解特定版本的详细信息。

### 不安全命令的替代

\`nc\`本身并不被认为是不安全的，但要注意在不安全的环境中使用它进行数据传输可能会有风险。\`nc\`的替代工具包括\`nmap\`（用于端口扫描）和\`socat\`（用于更复杂的网络连接）。

### 安装

在大多数Linux发行版中，\`nc\`（通常称为\`netcat\`或\`nc\`）是标准工具。你可以通过以下方式安装它：

- **Ubuntu**：
  \`\`\`bash
  sudo apt-get install netcat
  \`\`\`

- **RHEL**：
  \`\`\`bash
  sudo yum install nc
  \`\`\`

### 需要root权限的用法

大多数\`nc\`命令不需要root权限，除非你需要监听低于1024的端口（需要root权限）。

**示例：**

\`\`\`bash
sudo nc -l 80
\`\`\`

**输出：**

【无输出，命令已成功执行】


        `,
    };
  },
});
</script>
<style scoped></style>
