<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## tree 命令详解

\`tree\` 命令用于以树状图的形式显示目录结构，这对于快速浏览目录和文件结构非常有用。本文将详细介绍 \`tree\` 命令的使用方法、重要参数以及如何解读其输出结果。

### 命令概述

\`tree\` 命令以递归方式显示目录及其子目录中的文件，呈现出目录的树状结构。它非常适合查看大型目录的结构和快速定位文件。

### 常用参数

以下是 \`tree\` 命令的一些常用参数：

- \`-L <层级>\`: 指定树的层级深度。默认显示所有层级。
- \`-d\`: 仅显示目录，不显示文件。
- \`-f\`: 显示完整路径。
- \`-a\`: 显示所有文件，包括隐藏文件。
- \`-h\`: 以可读性更高的格式显示文件大小（例如KB、MB）。
- \`--du\`: 显示目录的总大小。
- \`--noreport\`: 不显示文件和目录的统计信息。

### 示例用法

#### 示例1: 显示当前目录及其所有子目录

\`\`\`bash
tree
\`\`\`

输出示例：

\`\`\`plaintext
.
├── dir1
│   ├── file1.txt
│   └── file2.txt
└── dir2
    └── file3.txt

2 directories, 3 files
\`\`\`

#### 示例2: 仅显示目录

\`\`\`bash
tree -d
\`\`\`

输出示例：

\`\`\`plaintext
.
├── dir1
└── dir2

2 directories
\`\`\`

#### 示例3: 显示完整路径

\`\`\`bash
tree -f
\`\`\`

输出示例：

\`\`\`plaintext
/home/sre
├── /home/sre/dir1
│   ├── /home/sre/dir1/file1.txt
│   └── /home/sre/dir1/file2.txt
└── /home/sre/dir2
    └── /home/sre/dir2/file3.txt

2 directories, 3 files
\`\`\`

#### 示例4: 限制树的层级深度为2

\`\`\`bash
tree -L 2
\`\`\`

输出示例：

\`\`\`plaintext
.
├── dir1
│   ├── file1.txt
│   └── file2.txt
└── dir2
    └── file3.txt

2 directories, 3 files
\`\`\`

#### 示例5: 显示文件大小和隐藏文件

\`\`\`bash
tree -ah
\`\`\`

输出示例：

\`\`\`plaintext
.
├── [  12K]  .hidden
├── [  10K]  dir1
│   ├── [  2K]  file1.txt
│   └── [  5K]  file2.txt
└── [  15K]  dir2
    └── [  8K]  file3.txt

2 directories, 3 files
\`\`\`

### 输出结果解读

- **目录结构**: 显示目录及其子目录的树状结构。
- **文件/目录数量**: 显示目录和文件的总数。
- **文件大小**: 使用 \`-h\` 参数显示以可读格式的文件大小。
- **路径信息**: 使用 \`-f\` 参数显示文件和目录的完整路径。

### 版本差异

\`tree\` 命令的输出可能会因版本不同而有所差异。在不同版本的系统中，\`tree\` 命令可能显示不同的格式或额外的功能。

### 安装包

- **Ubuntu**: \`tree\` 命令可以通过 \`tree\` 包安装。
- **RHEL**: \`tree\` 命令可以通过 \`tree\` 包安装。

安装命令示例：

**Ubuntu:**

\`\`\`bash
sudo apt-get install tree
\`\`\`

**RHEL:**

\`\`\`bash
sudo yum install tree
\`\`\`

### 总结

\`tree\` 命令是一个非常实用的工具，能够清晰地展示目录结构，帮助用户快速浏览和管理文件系统。通过掌握 \`tree\` 命令的各种参数，用户可以根据需求自定义输出结果，从而更高效地处理文件和目录。

        `,
    };
  },
});
</script>
<style scoped></style>
