<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ls 命令详解

\`ls\`命令是Linux系统中用于列出目录内容的基本命令。它显示指定目录下的文件和子目录，支持多种参数来调整输出的详细程度。本文将详细介绍\`ls\`命令的常用参数及其应用，并通过示例演示命令的实际用法和输出结果。

### 基本用法

\`ls\`命令最基本的用法是不带任何参数，它将列出当前工作目录中的文件和子目录。

\`\`\`bash
ls
\`\`\`

**示例输出：**

\`\`\`
Desktop  Documents  Downloads  Music  Pictures  Videos
\`\`\`

**解读：** 输出的内容为当前目录下的所有文件和目录，按字母顺序排列，没有详细信息。

### \`-l\` 参数：长格式显示

\`-l\`参数可以提供文件的详细信息，包括权限、所有者、大小和修改时间等。

\`\`\`bash
ls -l
\`\`\`

**示例输出：**

\`\`\`
drwxr-xr-x  5 sre sre-group 4096 Sep  6 10:00 Desktop
drwxr-xr-x  7 sre sre-group 4096 Sep  5 15:45 Documents
drwxr-xr-x  3 sre sre-group 4096 Sep  4 11:20 Downloads
drwxr-xr-x  2 sre sre-group 4096 Sep  3 09:30 Music
drwxr-xr-x  6 sre sre-group 4096 Sep  2 14:22 Pictures
drwxr-xr-x  4 sre sre-group 4096 Sep  1 12:01 Videos
\`\`\`

**解读：** 每列的含义如下：
- **权限**（如\`drwxr-xr-x\`）：文件的权限和类型。
- **硬链接数**（如\`5\`）：文件的硬链接数。
- **所有者**（如\`sre\`）：文件的所有者。
- **组**（如\`sre-group\`）：文件所属的组。
- **文件大小**（如\`4096\`）：文件的大小（以字节为单位）。
- **最后修改时间**（如\`Sep  6 10:00\`）：文件最后修改的时间。
- **文件名**（如\`Desktop\`）：文件或目录的名称。

### \`-a\` 参数：显示所有文件

\`-a\`参数将显示包括隐藏文件（以\`.\`开头的文件）在内的所有文件。

\`\`\`bash
ls -a
\`\`\`

**示例输出：**

\`\`\`
.  ..  .bashrc  .profile  Desktop  Documents  Downloads  Music  Pictures  Videos
\`\`\`

**解读：** 除了常规文件和目录，\`-a\`参数还显示了隐藏文件（如\`.bashrc\`和\`.profile\`）。\`..\`表示上级目录，\`.\`表示当前目录。

### \`-h\` 参数：人类可读的文件大小

\`-h\`参数与\`-l\`参数一起使用时，可以将文件大小以人类可读的格式显示（如KB、MB）。

\`\`\`bash
ls -lh
\`\`\`

**示例输出：**

\`\`\`
drwxr-xr-x  5 sre sre-group 4.0K Sep  6 10:00 Desktop
drwxr-xr-x  7 sre sre-group 4.0K Sep  5 15:45 Documents
drwxr-xr-x  3 sre sre-group 4.0K Sep  4 11:20 Downloads
drwxr-xr-x  2 sre sre-group 4.0K Sep  3 09:30 Music
drwxr-xr-x  6 sre sre-group 4.0K Sep  2 14:22 Pictures
drwxr-xr-x  4 sre sre-group 4.0K Sep  1 12:01 Videos
\`\`\`

**解读：** 文件大小以易读格式显示，如\`4.0K\`代表4.0千字节，使得文件大小更容易理解。

### \`-R\` 参数：递归显示

\`-R\`参数可以递归显示指定目录及其所有子目录的内容。

\`\`\`bash
ls -R
\`\`\`

**示例输出：**

\`\`\`
Desktop:
file1.txt  file2.txt

Documents:
doc1.pdf  doc2.pdf

Downloads:
fileA.zip  fileB.zip

...
\`\`\`

**解读：** 输出列出了每个子目录及其内容，使得用户可以查看整个目录树的结构。

### \`-t\` 参数：按时间排序

\`-t\`参数根据文件的最后修改时间进行排序，最近修改的文件会排在最前面。

\`\`\`bash
ls -lt
\`\`\`

**示例输出：**

\`\`\`
-rw-r--r-- 1 sre sre-group 4096 Sep  6 09:00 file1.txt
-rw-r--r-- 1 sre sre-group 4096 Sep  5 15:45 file2.txt
-rw-r--r-- 1 sre sre-group 4096 Sep  4 11:20 fileA.zip
-rw-r--r-- 1 sre sre-group 4096 Sep  3 09:30 fileB.zip
\`\`\`

**解读：** 文件按修改时间排序，最近修改的文件显示在最上面，这对于查看最新更新的文件非常有用。

### 总结

\`ls\`命令是查看和管理文件系统中目录内容的关键工具。掌握如何使用不同的参数可以帮助你以不同的方式查看文件信息，增强文件管理的灵活性。了解如何结合使用这些参数，将使你能够更高效地处理和浏览文件和目录。

      `,
    };
  },
});
</script>
<style scoped></style>
