<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## base32 命令详解

\`base32\` 命令是一个常用的工具，用于将数据进行Base32编码或解码。Base32编码是一种将二进制数据转换为文本的编码方式，常用于在需要安全传输或储存二进制数据时进行编码。本文将详细介绍\`base32\`命令的用法及其重要参数，并提供实际使用中的示例。

### 基本用法

\`base32\` 命令的基本语法如下：

\`\`\`bash
base32 [OPTION]... [FILE]...
\`\`\`

其中，\`OPTION\` 为可选项，\`FILE\` 为需要编码或解码的文件。如果不指定文件，\`base32\` 命令将从标准输入读取数据。

### 常用参数

- \`-d, --decode\`：解码Base32编码的数据。
- \`-i, --ignore-garbage\`：在解码时忽略无效字符，这些字符通常是由于数据传输过程中的损坏引入的。
- \`--wrap=COLS\`：将输出内容自动换行到指定的列数。默认情况下，输出不会换行。

### 示例

#### 1. 基本编码

将一个文件的内容进行Base32编码：

\`\`\`bash
base32 example.txt
\`\`\`

**输出：**
\`\`\`bash
KRUGS4ZANFZSAYJAORSXG5A=
\`\`\`

在这个示例中，\`example.txt\` 的内容被编码为Base32格式。

#### 2. 基本解码

解码一个Base32编码的文件：

\`\`\`bash
base32 -d example.b32
\`\`\`

**输出：**
\`\`\`bash
This is an example of base32 decoded text.
\`\`\`

如果\`example.b32\`文件包含Base32编码的内容，上述命令将解码并输出其原始内容。

#### 3. 忽略无效字符解码

使用 \`--ignore-garbage\` 选项解码带有无效字符的Base32编码数据：

\`\`\`bash
echo "MFRGG===" | base32 -d --ignore-garbage
\`\`\`

**输出：**
\`\`\`bash
foo
\`\`\`

这里，我们使用了带有无效字符的Base32数据。\`--ignore-garbage\` 选项使得命令可以忽略这些无效字符并正确解码数据。

### 结合其他命令使用

通过管道将其他命令的输出进行Base32编码：

\`\`\`bash
echo "Hello World" | base32
\`\`\`

**输出：**
\`\`\`bash
JBSWY3DPEBLW64TMMQ======
\`\`\`

在这个示例中，\`echo\` 命令的输出被编码为Base32格式。

### 不同架构下的表现

在不同架构下（如\`aarch64\`与\`x86_64\`），\`base32\`命令的输出应该保持一致，因为Base32编码是一种标准的算法，与硬件架构无关。如果在不同架构下发现差异，通常是由于命令实现或编码库的不同版本引起的。

### 版本差异

如果您发现不同版本的\`base32\`命令在编码或解码时输出不同，这可能是由于算法实现或编码字符集的差异。建议使用相同版本的工具或库，以确保一致的输出。

### 注意事项

- \`base32\` 命令不会在数据的末尾添加换行符，这在处理输出数据时需要注意。
- 当使用 \`-d\` 选项解码时，如果输入数据不合法（例如缺少填充字符），命令将报错。

### 总结

\`base32\` 命令是处理Base32编码的有力工具，适用于各种需要将二进制数据转换为文本格式的场景。无论是在数据传输中，还是在配置文件中储存二进制数据，\`base32\` 都能提供简洁、高效的解决方案。

**提示**：如果需要更高级的编码处理或与其他命令结合使用，\`base32\` 可以很好地与Unix命令行工具链集成，如与\`echo\`、\`cat\`、\`file\`等命令结合使用。

        `,
    };
  },
});
</script>
<style scoped></style>
