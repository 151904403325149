<template>
  <div class="default-page">
    <h3>孰能生巧</h3>
  </div>
</template>

<script>
export default {
  name: "CmdDefault",
};
</script>

<style scoped>
.default-page {
  display: flex;
  margin: 2rem auto;
  font-size: 1.2rem;
  color: #555; /* 字体颜色 */
}
</style>
