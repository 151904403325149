<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## blkid 命令详解

\`blkid\`命令是Linux系统中用于显示或查找块设备属性的工具。它主要用于查找设备的UUID（Universally Unique Identifier）、文件系统类型（TYPE）、标签（LABEL）等信息。这些信息对系统管理员在配置文件系统、设置挂载点或进行故障排查时非常有用。

在本文中，我们将详细介绍\`blkid\`命令的使用方法，展示其常用参数，并通过具体示例说明如何解读命令的输出结果。

### 基本用法

\`blkid\`命令的基本语法如下：

\`\`\`bash
blkid [选项] [设备]
\`\`\`

如果不指定任何设备，\`blkid\`将列出系统中所有块设备的相关信息。

#### 示例：列出所有块设备

\`\`\`bash
blkid
\`\`\`

**示例输出：**

\`\`\`plaintext
/dev/sda1: UUID="e3d8bda6-0f37-4b5b-96e8-7813a2c59ff0" TYPE="ext4" PARTUUID="0002fbbc-01"
/dev/sda2: UUID="f25c8b5d-b6c4-4dbd-b99f-00a7ef9aee59" TYPE="swap" PARTUUID="0002fbbc-02"
/dev/sdb1: UUID="a64dbdba-95cc-498a-bf0e-c17e4dbf2335" TYPE="xfs" PARTUUID="0002fbbd-01"
\`\`\`

**解读：** 输出列出了系统中所有已识别的块设备。每个设备都有唯一的\`UUID\`、文件系统类型（\`TYPE\`）和分区UUID（\`PARTUUID\`）。这些信息在挂载文件系统时非常有用，例如，\`/dev/sda1\`分区使用的是\`ext4\`文件系统。

### 查询特定设备信息

如果你只想查看某个特定设备的信息，可以在命令后指定设备名。例如，查看\`/dev/sda1\`的详细信息：

\`\`\`bash
blkid /dev/sda1
\`\`\`

**示例输出：**

\`\`\`plaintext
/dev/sda1: UUID="e3d8bda6-0f37-4b5b-96e8-7813a2c59ff0" TYPE="ext4" PARTUUID="0002fbbc-01"
\`\`\`

**解读：** 输出显示了\`/dev/sda1\`的\`UUID\`、文件系统类型以及分区UUID。这些信息可以用于挂载此分区或在\`/etc/fstab\`中进行配置。

### 使用参数 -o

\`blkid\`命令支持多种输出格式，可以通过\`-o\`选项指定。例如，使用\`-o value\`可以只显示设备的值，而不显示属性名称：

\`\`\`bash
blkid -o value /dev/sda1
\`\`\`

**示例输出：**

\`\`\`plaintext
e3d8bda6-0f37-4b5b-96e8-7813a2c59ff0
ext4
0002fbbc-01
\`\`\`

**解读：** 此输出只显示了\`UUID\`、文件系统类型和分区UUID的值，适用于需要进一步处理输出数据的场景。

### 使用参数 -s

\`-s\`选项用于指定你只想查看的某个属性。例如，只想查看\`UUID\`：

\`\`\`bash
blkid -s UUID /dev/sda1
\`\`\`

**示例输出：**

\`\`\`plaintext
/dev/sda1: UUID="e3d8bda6-0f37-4b5b-96e8-7813a2c59ff0"
\`\`\`

**解读：** 此输出只显示了设备的\`UUID\`，忽略了其他属性。可以通过该方式精准获取特定信息。

### 检查文件系统类型

有时你可能只需要知道某个分区的文件系统类型，使用\`-s TYPE\`选项即可：

\`\`\`bash
blkid -s TYPE /dev/sda1
\`\`\`

**示例输出：**

\`\`\`plaintext
/dev/sda1: TYPE="ext4"
\`\`\`

**解读：** 输出仅显示了文件系统类型\`ext4\`，表明该分区使用的是\`ext4\`文件系统。

### 检查设备标签（LABEL）

设备标签（\`LABEL\`）用于识别设备的别名，通常用于简化挂载操作。使用\`-s LABEL\`可以查看设备的标签：

\`\`\`bash
blkid -s LABEL /dev/sda1
\`\`\`

**示例输出：**

\`\`\`plaintext
[无输出，命令已成功执行]
\`\`\`

**解读：** 如果没有设置标签，命令将无输出，表示该设备未设置\`LABEL\`。可以使用\`e2label\`命令设置\`ext2/3/4\`文件系统的标签。

### 显示未格式化的设备

使用\`-c\`选项可以结合\`blkid\`缓存文件，快速查找未格式化的设备：

\`\`\`bash
blkid -c /dev/null
\`\`\`

**示例输出：**

\`\`\`plaintext
/dev/sda: PTUUID="0002fbbc" PTTYPE="dos"
/dev/sda1: UUID="e3d8bda6-0f37-4b5b-96e8-7813a2c59ff0" TYPE="ext4"
/dev/sda2: UUID="f25c8b5d-b6c4-4dbd-b99f-00a7ef9aee59" TYPE="swap"
/dev/sdb: PTTYPE="dos"
\`\`\`

**解读：** 此输出显示了未格式化的设备\`/dev/sdb\`（没有\`UUID\`和\`TYPE\`），表明该设备还未配置文件系统。

### 总结

\`blkid\`命令是一个强大的工具，用于检索Linux系统中块设备的属性信息。它为系统管理员提供了关键的UUID、文件系统类型和设备标签信息，有助于挂载文件系统、设置分区和进行系统维护。通过结合\`-o\`和\`-s\`等选项，可以灵活地输出所需的信息，便于进一步处理和自动化任务。

使用\`blkid\`命令可以大大简化系统管理任务，尤其是在处理多个设备时。无论是新手还是经验丰富的系统管理员，都可以通过掌握该命令来提升工作效率。

        `,
    };
  },
});
</script>
<style scoped></style>
