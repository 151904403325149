<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## modprobe 命令详解

\`modprobe\` 是 Linux 系统中用于管理内核模块的命令。它能够加载和卸载内核模块，并且会自动处理模块间的依赖关系。模块是内核功能的扩展，例如设备驱动程序、文件系统支持等。通过 \`modprobe\`，我们可以动态加载和移除这些功能，而不需要重新编译内核。

在这篇文章中，我们将详细讨论 \`modprobe\` 命令的使用方法、参数，以及在不同情境下的具体应用。

### 安装

\`modprobe\` 命令是 \`kmod\` 工具包的一部分，通常默认安装在大多数 Linux 发行版上。如果需要安装，可以使用以下命令：

#### Ubuntu/Debian 系列
\`\`\`bash
sudo apt install kmod
\`\`\`

#### RHEL/CentOS 系列
\`\`\`bash
sudo yum install kmod
\`\`\`

### 基本语法

\`\`\`bash
modprobe [options] modulename
\`\`\`

- **modulename**：要加载或卸载的内核模块名称。
- **options**：控制加载或卸载行为的选项。

### 示例1：加载内核模块

假设我们要加载一个名为 \`dummy\` 的虚拟网络接口模块，可以使用以下命令：

\`\`\`bash
sudo modprobe dummy
\`\`\`

**输出示例：**

【无输出，命令已成功执行】

**解读**：\`dummy\` 模块已成功加载。可以通过 \`lsmod\` 命令确认模块是否已加载：

\`\`\`bash
lsmod | grep dummy
\`\`\`

**输出示例：**

\`\`\`bash
dummy                  20480  0
\`\`\`

**解读**：\`dummy\` 模块已成功加载并处于活动状态。

### 示例2：卸载内核模块

要卸载已经加载的模块，可以使用 \`-r\` 选项。例如，卸载之前加载的 \`dummy\` 模块：

\`\`\`bash
sudo modprobe -r dummy
\`\`\`

**输出示例：**

【无输出，命令已成功执行】

**解读**：\`dummy\` 模块已成功卸载。可以再次使用 \`lsmod\` 命令确认模块是否已卸载。

### 示例3：查看模块加载信息

可以通过 \`-v\` 选项查看加载模块的详细信息。以下是加载 \`dummy\` 模块时的详细输出：

\`\`\`bash
sudo modprobe -v dummy
\`\`\`

**输出示例：**

\`\`\`bash
insmod /lib/modules/5.11.0-37-generic/kernel/drivers/net/dummy.ko
\`\`\`

**解读**：此命令显示了模块加载的路径和过程。

### 示例4：加载特定参数的模块

某些模块支持通过参数进行自定义。可以使用 \`modprobe\` 来为模块指定参数。例如，加载 \`dummy\` 模块并为其指定设备名称：

\`\`\`bash
sudo modprobe dummy numdummies=2
\`\`\`

**输出示例：**

【无输出，命令已成功执行】

**解读**：\`dummy\` 模块已加载，并且创建了两个虚拟网络接口。可以通过 \`ip link\` 命令验证创建的设备：

\`\`\`bash
ip link show
\`\`\`

**输出示例：**

\`\`\`bash
3: dummy0: <BROADCAST,NOARP> mtu 1500 qdisc noop state DOWN group default qlen 1000
    link/ether 12:34:56:78:9a:bc brd ff:ff:ff:ff:ff:ff
4: dummy1: <BROADCAST,NOARP> mtu 1500 qdisc noop state DOWN group default qlen 1000
    link/ether 12:34:56:78:9a:bd brd ff:ff:ff:ff:ff:ff
\`\`\`

**解读**：系统创建了两个虚拟网络接口 \`dummy0\` 和 \`dummy1\`。

### 常用选项

#### \`-r\` 选项：移除模块

\`modprobe -r\` 用于从内核中移除指定模块。例如，卸载 \`dummy\` 模块：

\`\`\`bash
sudo modprobe -r dummy
\`\`\`

**输出解读**：【无输出，命令已成功执行】  
\`dummy\` 模块已成功卸载。

#### \`-f\` 选项：强制加载模块

在某些情况下，模块的内核版本不匹配时，系统会拒绝加载模块。使用 \`-f\` 选项可以强制加载不匹配的模块：

\`\`\`bash
sudo modprobe -f dummy
\`\`\`

**注意**：强制加载模块可能会导致系统不稳定，请谨慎使用。

#### \`-n\` 选项：模拟加载

\`modprobe -n\` 选项用于模拟模块的加载，而不实际执行加载过程。可以用来测试是否有问题：

\`\`\`bash
sudo modprobe -n dummy
\`\`\`

**输出解读**：【无输出，命令已成功执行】  
此选项不会实际加载模块，仅用于模拟检查。

#### \`-a\` 选项：批量加载模块

使用 \`-a\` 选项可以一次加载多个模块。例如：

\`\`\`bash
sudo modprobe -a module1 module2
\`\`\`

**输出解读**：【无输出，命令已成功执行】  
多个模块已被同时加载。

### Bash 内置命令与外部命令

\`modprobe\` 是外部命令，位于 \`/usr/sbin/modprobe\`。可以使用 \`type\` 命令确认：

\`\`\`bash
type modprobe
\`\`\`

**输出示例：**

\`\`\`bash
modprobe is /usr/sbin/modprobe
\`\`\`

**解读**：\`modprobe\` 是一个外部命令，而不是 bash 内置命令。

### 不同架构下的输出

\`modprobe\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为没有差异，因此无需单独列出不同架构下的输出。

### 版本差异

不同版本的 \`modprobe\` 在基础功能上没有太大差异。要查看当前系统上安装的 \`modprobe\` 版本，可以使用以下命令：

\`\`\`bash
modprobe --version
\`\`\`

**输出示例：**

\`\`\`bash
kmod version 27
\`\`\`

**解读**：系统中安装的是 \`kmod\` 工具包的第 27 版。

### 使用root权限的选项

大多数 \`modprobe\` 命令的操作需要 \`root\` 权限，因为它涉及到对内核的直接修改。例如，加载或卸载模块通常需要 \`sudo\`：

\`\`\`bash
sudo modprobe dummy
\`\`\`

**解读**：该命令需要 \`root\` 权限才能成功执行。

### 替代命令

\`insmod\` 和 \`rmmod\` 是较旧的命令，分别用于加载和卸载内核模块。但由于这些命令不会自动处理模块间的依赖关系，因此 \`modprobe\` 更加推荐使用。如果你遇到 \`modprobe\` 不支持的情境，可以尝试使用 \`insmod\` 和 \`rmmod\`，但需手动解决依赖关系。

### 总结

\`modprobe\` 是 Linux 内核模块管理的核心工具，提供了灵活的加载和卸载方式，能够自动处理依赖关系。通过掌握 \`modprobe\` 命令及其选项，用户可以轻松管理内核模块，从而扩展系统功能。

- \`-r\` 选项用于卸载模块。
- \`-f\` 选项可以强制加载不匹配的模块。
- \`-n\` 选项可以用于模拟加载，测试是否有错误。
  
\`modprobe\` 是处理模块化内核的关键命令，适用于管理员和开发人员的日常维护工作。

        `,
    };
  },
});
</script>
<style scoped></style>
