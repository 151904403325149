<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## groupmod 命令详解

\`groupmod\` 命令用于修改已存在的用户组的属性。在 Linux 系统中，用户组是管理用户权限和访问的关键组成部分，因此了解如何使用 \`groupmod\` 是系统管理的重要一环。本文将详细介绍 \`groupmod\` 命令的使用方法、重要参数、示例及注意事项。

### groupmod 是 bash 内置命令还是外部命令？

\`groupmod\` 是一个外部命令，通常位于 \`/usr/sbin/groupmod\`。它不是 Bash 的内置命令，因此在使用时需要确保有相应的权限。

### 基本语法

\`groupmod\` 命令的基本语法如下：

\`\`\`bash
groupmod [OPTION] GROUP_NAME
\`\`\`

### 重要参数及示例

#### 1. \`-n\`：更改组名

使用 \`-n\` 选项可以更改用户组的名称。

\`\`\`bash
sudo groupmod -n newgroup oldgroup
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

在此示例中，\`oldgroup\` 用户组被重命名为 \`newgroup\`。可以使用 \`getent group newgroup\` 命令确认更改：

\`\`\`bash
getent group newgroup
# 输出：
# newgroup:x:1002:
\`\`\`

此输出确认 \`newgroup\` 已成功创建，且其 ID 为 1002。

#### 2. \`-g\`：更改组ID

使用 \`-g\` 选项可以更改用户组的 GID。

\`\`\`bash
sudo groupmod -g 1010 mygroup
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

此命令将 \`mygroup\` 的 GID 更改为 1010。可以通过以下命令验证：

\`\`\`bash
getent group mygroup
# 输出：
# mygroup:x:1010:
\`\`\`

此输出确认 \`mygroup\` 的 GID 已成功更改。

### 查看用户组信息

可以使用 \`getent\` 命令查看当前所有用户组：

\`\`\`bash
getent group
# 输出示例：
# root:x:0:
# sre-group:x:1005:
# mygroup:x:1010:
\`\`\`

此输出列出了系统中所有的用户组及其信息。

### 不同架构下的执行结果

\`groupmod\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，无论在哪种架构下，\`groupmod\` 命令的功能和输出均相同。

### 版本差异

在不同版本的 Linux 发行版中，\`groupmod\` 命令的基本用法保持一致，但可能存在某些特定选项的差异。一般来说，常用的选项在大多数版本中都是支持的。

### 安装

在大多数 Linux 发行版中，\`groupmod\` 命令已经默认安装。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install passwd
  \`\`\`
- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install shadow-utils
  \`\`\`

### 使用root权限的场景

\`groupmod\` 命令需要 root 权限才能执行，通常使用 \`sudo\` 提升权限。以下是使用 \`sudo\` 修改用户组的示例：

\`\`\`bash
sudo groupmod -n mygroup mygroup_old
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

### 总结

\`groupmod\` 命令是 Linux 系统中用于管理用户组的重要工具，通过它可以轻松修改用户组的名称和 GID。掌握 \`groupmod\` 命令的使用对于系统管理员维护用户和权限至关重要。无论是在日常管理还是在脚本编写中，了解如何有效使用 \`groupmod\` 命令都是一项重要技能。

        `,
    };
  },
});
</script>
<style scoped></style>
