<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ifconfig 命令详解

\`ifconfig\`（Interface Configuration）是一个用于配置、查看和管理网络接口的命令。尽管在较新的系统中，它已被 \`ip\` 命令取代，但它仍在许多系统中被广泛使用。\`ifconfig\` 主要用于网络接口的设置和状态检查。

### 使用语法

\`\`\`bash
ifconfig [接口] [参数]
\`\`\`

### 重要参数

- \`[接口]\`：指定网络接口（如 eth0, wlan0）。
- \`up\`：启用指定的网络接口。
- \`down\`：禁用指定的网络接口。
- \`address\`：设置网络接口的 IP 地址。
- \`netmask\`：设置网络接口的子网掩码。
- \`broadcast\`：设置广播地址。
- \`mtu\`：设置接口的最大传输单元（MTU）。

### 基本用法

#### 例1：查看所有网络接口的信息

\`\`\`bash
ifconfig
\`\`\`

**输出解释**:
\`\`\`bash
eth0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
        inet 192.168.1.10  netmask 255.255.255.0  broadcast 192.168.1.255
        inet6 fe80::1a2b:3c4d:5e6f:7a8b  prefixlen 64  scopeid 0x20<link>
        ether 00:1a:2b:3c:4d:5e  txqueuelen 1000  (Ethernet)
        RX packets 12345  bytes 6789000 (6.7 MB)
        TX packets 54321  bytes 12345678 (12.3 MB)
\`\`\`

**解读**:
- \`eth0\` 是网络接口名称。
- \`flags\` 显示接口状态。
- \`inet\` 显示 IPv4 地址。
- \`inet6\` 显示 IPv6 地址。
- \`ether\` 显示 MAC 地址。
- \`RX packets\` 和 \`TX packets\` 显示接收和发送的数据包数量。

#### 例2：启用网络接口

\`\`\`bash
sudo ifconfig eth0 up
\`\`\`

**输出解释**:
该命令启用 \`eth0\` 网络接口。通常没有输出，但可以通过以下命令验证接口状态。

\`\`\`bash
ifconfig eth0
\`\`\`

**解读**:
\`eth0\` 接口将变为活动状态，允许数据流动。此操作通常需要 root 权限。

#### 例3：禁用网络接口

\`\`\`bash
sudo ifconfig eth0 down
\`\`\`

**输出解释**:
该命令禁用 \`eth0\` 网络接口。通常没有输出，但可以通过以下命令验证接口状态。

\`\`\`bash
ifconfig eth0
\`\`\`

**解读**:
\`eth0\` 接口将被禁用，不再接收或发送数据。此操作通常需要 root 权限。

#### 例4：设置 IP 地址

\`\`\`bash
sudo ifconfig eth0 192.168.1.100 netmask 255.255.255.0
\`\`\`

**输出解释**:
该命令将 \`eth0\` 接口的 IP 地址设置为 \`192.168.1.100\`，子网掩码为 \`255.255.255.0\`。通常没有输出，但可以通过以下命令验证设置。

\`\`\`bash
ifconfig eth0
\`\`\`

**解读**:
\`eth0\` 接口将使用新的 IP 地址和子网掩码。此操作通常需要 root 权限。

### 不同架构下的表现差异

\`ifconfig\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的表现基本一致，因为它是一个用户空间命令，与硬件架构无关。

### 不同版本的差异

\`ifconfig\` 在不同版本的 Unix/Linux 系统中可能会有所不同。例如，在较老的系统中，可能会使用 \`net-tools\` 包中的 \`ifconfig\`，而在较新的系统中，\`ifconfig\` 可能已被 \`ip\` 命令取代。

### 替代命令

在现代 Linux 系统中，\`ip\` 命令是 \`ifconfig\` 的推荐替代。\`ip\` 命令提供了更强大的功能和更丰富的选项。

\`\`\`bash
ip addr show
\`\`\`

### 安装包信息

- **Ubuntu**: \`ifconfig\` 命令属于 \`net-tools\` 包。可以使用以下命令安装：
  \`\`\`bash
  sudo apt-get install net-tools
  \`\`\`
  
- **RHEL**: \`ifconfig\` 命令也属于 \`net-tools\` 包。可以使用以下命令安装：
  \`\`\`bash
  sudo yum install net-tools
  \`\`\`

**主机名**: azlinux-prod-cn

**用户**: sre (UID: 1005, GID: sre-group)

**需要 root 权限的选项**:
- \`ifconfig\` 命令的许多操作（如启用或禁用接口、设置 IP 地址）需要 root 权限。

**示例**:
\`\`\`bash
sudo ifconfig eth0 up
\`\`\`

**输出**:
【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
