<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## getent 命令详解

\`getent\` 命令是一个用于查询系统数据库的工具，它可以从多种数据库中获取条目，包括用户账户、组、主机名、服务等。在 Linux 系统中，\`getent\` 提供了一个方便的方式来访问这些信息，而无需直接查看文件，如 \`/etc/passwd\` 和 \`/etc/group\`。本文将详细介绍 \`getent\` 命令的使用、重要参数、示例及注意事项。

### groupmod 是 bash 内置命令还是外部命令？

\`getent\` 是一个外部命令，通常位于 \`/usr/bin/getent\`。它不是 Bash 的内置命令，因此在使用时需要确保系统中已安装该命令。

### 基本语法

\`getent\` 命令的基本语法如下：

\`\`\`bash
getent [数据库] [条目]
\`\`\`

### 重要参数及示例

#### 1. 查询用户信息

要查询特定用户的信息，可以指定数据库为 \`passwd\`，后接用户名。

\`\`\`bash
getent passwd sre
# 输出：
# sre:x:1005:1005::/home/sre:/bin/bash
\`\`\`

此输出表示用户 \`sre\` 的信息，其中：
- 用户名：\`sre\`
- 密码占位符：\`x\`
- 用户ID (UID)：\`1005\`
- 组ID (GID)：\`1005\`
- 注释：空
- 主目录：\`/home/sre\`
- 默认 shell：\`/bin/bash\`

#### 2. 查询组信息

要查询特定组的信息，可以指定数据库为 \`group\`，后接组名。

\`\`\`bash
getent group sre-group
# 输出：
# sre-group:x:1005:
\`\`\`

此输出表示用户组 \`sre-group\` 的信息，其中：
- 组名：\`sre-group\`
- 密码占位符：\`x\`
- 组ID (GID)：\`1005\`

#### 3. 查询主机名信息

可以使用 \`getent\` 查询主机名信息，通过 \`hosts\` 数据库。

\`\`\`bash
getent hosts azlinux-prod-cn
# 输出示例：
# 192.168.1.1    azlinux-prod-cn
\`\`\`

此输出表示主机名 \`azlinux-prod-cn\` 对应的 IP 地址。

#### 4. 查询服务信息

要查询服务信息，可以指定数据库为 \`services\`，后接服务名。

\`\`\`bash
getent services ssh
# 输出：
# ssh 22/tcp
\`\`\`

此输出表示 \`ssh\` 服务在 TCP 端口 \`22\` 上运行。

### 不同架构下的执行结果

\`getent\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，无论在哪种架构下，\`getent\` 命令的功能和输出均相同。

### 版本差异

在不同版本的 Linux 发行版中，\`getent\` 命令的基本用法保持一致，但可能存在某些特定选项的差异。一般来说，常用的选项在大多数版本中都是支持的。

### 安装

在大多数 Linux 发行版中，\`getent\` 命令已经默认安装。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install libc-bin
  \`\`\`
- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install glibc-common
  \`\`\`

### 使用root权限的场景

\`getent\` 命令通常不需要 root 权限即可执行，但在某些情况下，查询特定敏感信息可能需要更高权限。

\`\`\`bash
sudo getent passwd root
# 输出：
# root:x:0:0:root:/root:/bin/bash
\`\`\`

此命令显示 root 用户的信息。

### 总结

\`getent\` 命令是 Linux 系统中用于查询系统数据库的重要工具，通过它可以轻松获取用户、组、主机名和服务等信息。掌握 \`getent\` 命令的使用对于系统管理员了解和管理系统资源至关重要。无论是在日常管理还是在脚本编写中，了解如何有效使用 \`getent\` 命令都是一项重要技能。

        `,
    };
  },
});
</script>
<style scoped></style>
