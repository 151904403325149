<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## more 命令详解

\`more\` 命令是一个常用的分页工具，用于在终端中逐页查看文本文件。它可以帮助用户逐步浏览文件内容，而不需要一次性加载整个文件，适用于查看长文件。

### 基本用法

\`\`\`bash
more [选项] [文件...]
\`\`\`

- \`选项\`：可选的参数，通常用于控制 \`more\` 的行为。
- \`文件\`：要查看的文件。如果没有指定文件，\`more\` 会从标准输入读取数据。

### 常用参数

- \`-d\`：显示提示信息。当没有更多内容可显示时，\`more\` 会提示用户按 \`q\` 退出。
- \`-f\`：将每个换行符都当作换行处理，而不是忽略多个连续的换行符。
- \`-l\`：将每个换行符都当作换行处理，忽略分页符。
- \`-c\`：用清屏的方式显示内容，而不是在屏幕上覆盖。
- \`-s\`：压缩多个连续的空行，只显示一行空行。

### 示例

假设有一个文本文件 \`example.txt\`，内容如下：

\`\`\`
Line 1
Line 2
Line 3
...
Line 100
\`\`\`

#### 1. 基本使用

\`\`\`bash
more example.txt
\`\`\`

**输出示例：**

\`\`\`
Line 1
Line 2
Line 3
...
--More--(100%)
\`\`\`

**解读：** 在输出中，\`more\` 命令显示了文件的第一页内容，并在底部提示用户按 \`空格\` 键继续查看，按 \`q\` 键退出。

#### 2. 使用 \`-d\` 选项

\`\`\`bash
more -d example.txt
\`\`\`

**输出示例：**

\`\`\`
Line 1
Line 2
Line 3
...
--More--(100%) [Press space to continue, 'q' to quit.]
\`\`\`

**解读：** 在使用 \`-d\` 选项时，\`more\` 命令会在文件结束时显示提示信息。

#### 3. 使用 \`-c\` 选项

\`\`\`bash
more -c example.txt
\`\`\`

**输出示例：**

\`\`\`
Line 1
Line 2
Line 3
...
--More--(100%)
\`\`\`

**解读：** 使用 \`-c\` 选项时，\`more\` 命令会在每次分页时清除屏幕，提供更清晰的视图。

### 不同架构下的输出

\`more\` 命令的行为在不同架构下基本一致，因为它主要依赖于终端的显示，而不是架构本身。无论是在 \`aarch64\` 还是 \`x86_64/amd64\` 架构下，\`more\` 的输出形式不会有显著差异。

### 版本差异

不同版本的 \`more\` 命令在功能上可能会有所不同，但大多数基本选项和用法是一致的。建议在使用时查看 \`more\` 命令的手册以了解具体版本的差异。

### 安全性和替代命令

\`more\` 命令是一种经典的分页工具，但有时可能会被更现代的工具替代，如 \`less\`。\`less\` 提供了更多的功能，如向前和向后滚动，支持搜索等。

#### \`less\` 命令基本用法

\`\`\`bash
less [选项] [文件...]
\`\`\`

**示例用法：**

\`\`\`bash
less example.txt
\`\`\`

### 安装

在大多数 Linux 发行版中，\`more\` 命令默认安装。如果需要安装 \`more\` 命令，通常会包含在 \`moreutils\` 包中。

- **Ubuntu/Debian**: \`sudo apt-get install moreutils\`
- **RHEL/CentOS**: \`sudo yum install moreutils\`

### 需要 root 权限的选项

\`more\` 命令通常不需要 root 权限，但在查看受限文件时，可能需要使用 \`sudo\` 来获取权限。例如：

\`\`\`bash
sudo more /var/log/syslog
\`\`\`

**输出示例：**

\`\`\`
Line 1
Line 2
Line 3
...
--More--(100%)
\`\`\`

**解读：** 使用 \`sudo\` 可以查看需要特权访问的系统日志文件。

【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
