<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## jobs 命令详解

\`jobs\` 命令是一个用于查看当前 shell 中的作业的 Bash 内置命令。它显示了作业的状态，包括正在运行的作业和后台作业。这个命令对于管理在后台运行的任务非常有用。

### 使用语法

\`\`\`bash
jobs [选项]
\`\`\`

### 重要参数和选项

- \`-l\`：显示作业的进程号（PID）。
- \`-p\`：仅显示作业的进程号。
- \`-n\`：仅显示状态发生变化的作业。

### 基本用法

#### 例1：查看所有作业

\`\`\`bash
jobs
\`\`\`

**输出解释**:
\`\`\`bash
[1]+ 12345 Running                 sleep 300 &
[2]   12346 Running                 top &
\`\`\`

**解读**:
- \`[1]+\` 和 \`[2]\` 是作业编号，\`+\` 表示当前作业，\`-\` 表示前一个作业。
- \`12345\` 和 \`12346\` 是进程号（PID）。
- \`Running\` 表示作业的状态。
- \`sleep 300 &\` 和 \`top &\` 是作业命令。

#### 例2：显示作业的进程号

\`\`\`bash
jobs -l
\`\`\`

**输出解释**:
\`\`\`bash
[1]+ 12345 Running                 sleep 300 &
[2]   12346 Running                 top &
\`\`\`

**解读**:
与上面的命令类似，但 \`-l\` 选项明确显示了作业的进程号。

#### 例3：仅显示进程号

\`\`\`bash
jobs -p
\`\`\`

**输出解释**:
\`\`\`bash
12345
12346
\`\`\`

**解读**:
仅显示作业的进程号，没有其他信息。

#### 例4：仅显示状态发生变化的作业

\`\`\`bash
jobs -n
\`\`\`

**输出解释**:
【无输出，命令已成功执行】

**解读**:
此命令仅在作业状态发生变化时显示作业状态。没有变化时没有输出。

### 不同架构下的表现差异

\`jobs\` 命令是一个 Bash 内置命令，不依赖于硬件架构，因此在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的输出是一样的。

### 不同版本的差异

\`jobs\` 命令在不同版本的 Bash 中可能会有轻微的差异，但其基本功能和用法在不同版本中保持一致。

### 替代命令

没有直接的替代命令，因为 \`jobs\` 是 Bash 内置命令，专门用于管理和查看作业。如果需要更复杂的作业管理功能，可以使用 \`ps\` 和 \`top\` 命令来查看和管理进程。

### 安装包信息

\`jobs\` 是 Bash 内置命令，因此无需单独安装。但 Bash 本身是由以下安装包提供的：

- **Ubuntu**:
  \`\`\`bash
  sudo apt-get install bash
  \`\`\`

- **RHEL**:
  \`\`\`bash
  sudo yum install bash
  \`\`\`

**主机名**: azlinux-prod-cn

**用户**: sre (UID: 1005, GID: sre-group)

**需要 root 权限的选项**:
- \`jobs\` 命令本身不需要 root 权限，但管理后台作业可能需要特定的权限。如果需要终止作业或对作业进行其他操作，可能需要 root 权限或相关作业的权限。

**示例**:
\`\`\`bash
jobs
\`\`\`

**输出**:
【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
