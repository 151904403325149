<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## echo 命令详解

\`echo\` 命令是Linux和Unix系统中最常用的命令之一，用于在终端输出字符串或变量的值。它简单易用且功能强大，可以结合其他命令和脚本实现复杂的功能。本文将详细介绍 \`echo\` 命令的使用方法，并通过示例展示一些常见的用法。

### 基本语法

\`echo\` 命令的基本语法如下：

\`\`\`bash
echo [选项] [字符串...]
\`\`\`

- **字符串**: 需要输出的文本或变量内容。
- **选项**: 控制输出行为的选项。

### 常用选项

#### 1. \`-n\` 选项

\`-n\` 选项用于在输出后不自动换行，通常在需要连续输出内容时使用。

\`\`\`bash
$ echo -n "Hello, "
$ echo "World!"
\`\`\`

**输出：**

\`\`\`
Hello, World!
\`\`\`

**解读：**
第一个 \`echo\` 命令在输出后没有换行，因此第二个 \`echo\` 命令的输出直接接在其后。

#### 2. \`-e\` 选项

\`-e\` 选项启用转义字符处理，支持更多格式控制，如换行符（\`\n\`）、制表符（\`\t\`）等。

\`\`\`bash
$ echo -e "Hello\nWorld\t!"
\`\`\`

**输出：**

\`\`\`
Hello
World    !
\`\`\`

**解读：**
\`\n\` 表示换行，\`\t\` 表示制表符，因此 \`World\` 后面有一个空格缩进。

#### 3. \`-E\` 选项

\`-E\` 选项用于禁用转义字符处理。默认情况下，\`echo\` 已经禁用了转义字符，使用 \`-E\` 可以确保转义字符不会被解释。

\`\`\`bash
$ echo -E "Hello\nWorld"
\`\`\`

**输出：**

\`\`\`
Hello\nWorld
\`\`\`

**解读：**
转义字符 \`\n\` 没有被解释为换行符，而是原样输出。

### 特殊用法

#### 1. 输出变量值

\`echo\` 命令可以输出变量的值。只需在变量名前添加 \`$\` 符号。

\`\`\`bash
$ USERNAME="sre"
$ echo "Current user: $USERNAME"
\`\`\`

**输出：**

\`\`\`
Current user: sre
\`\`\`

**解读：**
命令输出当前用户的用户名，变量 \`$USERNAME\` 被正确替换为其值。

#### 2. 输出环境变量

\`echo\` 也可以用来查看系统的环境变量值，例如 \`$PATH\`。

\`\`\`bash
$ echo $PATH
\`\`\`

**输出：**

\`\`\`
/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
\`\`\`

**解读：**
命令输出系统的 \`PATH\` 环境变量，列出所有可执行文件的搜索路径。

#### 3. 输出到文件

使用 \`>\` 或 \`>>\` 重定向符号，\`echo\` 的输出可以写入文件。

\`\`\`bash
$ echo "This is a test." > testfile.txt
$ cat testfile.txt
\`\`\`

**输出：**

\`\`\`
This is a test.
\`\`\`

**解读：**
\`echo\` 命令的输出被重定向到 \`testfile.txt\` 文件，随后使用 \`cat\` 命令查看文件内容，确认输出成功写入。

#### 4. 用于脚本中的提示信息

在脚本中，\`echo\` 常用于向用户显示提示信息或调试输出。

\`\`\`bash
$ echo "Script started."
\`\`\`

**输出：**

\`\`\`
Script started.
\`\`\`

**解读：**
此命令在脚本运行时向用户提示脚本已启动。

### 示例操作

#### 1. 使用 \`-n\` 选项输出连续文本

\`\`\`bash
$ echo -n "User: "
$ echo "sre"
\`\`\`

**输出：**

\`\`\`
User: sre
\`\`\`

**解读：**
使用 \`-n\` 选项后，输出 \`User: \` 后不会换行，接着输出 \`sre\`。

#### 2. 使用 \`-e\` 选项输出带有转义字符的文本

\`\`\`bash
$ echo -e "Hostname: azlinux-prod-cn\nUser: sre\tUID: 1005"
\`\`\`

**输出：**

\`\`\`
Hostname: azlinux-prod-cn
User: sre    UID: 1005
\`\`\`

**解读：**
使用 \`-e\` 选项，\`\n\` 和 \`\t\` 被解释为换行和制表符，使输出内容更具格式化。

#### 3. 输出文件内容到标准输出

\`\`\`bash
$ echo "Sample text" > sample.txt
$ echo "File content:" 
$ cat sample.txt
\`\`\`

**输出：**

\`\`\`
File content:
Sample text
\`\`\`

**解读：**
首先将 "Sample text" 写入 \`sample.txt\` 文件，然后通过 \`cat\` 命令输出文件内容到终端。

### 权限要求

\`echo\` 命令通常不需要特殊权限，但在写入文件或访问受限的环境变量时，可能需要 \`root\` 权限。例如，如果你想将内容写入系统配置文件，通常需要 \`sudo\`。

\`\`\`bash
$ sudo echo "root:123456" >> /etc/shadow
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`root\` 用户的密码信息写入 \`/etc/shadow\` 文件，执行时需要 \`root\` 权限。

### 总结

\`echo\` 命令在Linux中的应用非常广泛，既可以用于简单的文本输出，也可以在脚本中进行复杂的操作。通过掌握不同选项和用法，您可以更灵活地使用 \`echo\` 命令来实现各种任务。无论是调试、配置脚本，还是操作文件，\`echo\` 都是一个不可或缺的工具。

        `,
    };
  },
});
</script>
<style scoped></style>
