<template>
  <div class="footer-wrapper">
    <div class="footer-toggle" @click="toggleVisibility">
      <div class="arrow" :class="{ 'arrow-expanded': isVisible }"></div>
    </div>
    <el-footer class="footer" :class="{ 'footer-collapsed': !isVisible }">
      <div class="container" v-if="isVisible">
        <p class="text">
          <span>运维练兵场 © Since 2019</span>
        </p>
        <el-link type="primary" href="/about-us">关于我们</el-link>
        <el-link type="primary" href="/faq">常见问题</el-link>
        <el-link type="primary" href="/privacy">隐私政策</el-link>
        <el-link type="primary" href="/faq">陕ICP备20006263号</el-link>
      </div>
    </el-footer>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "FooterView",
  setup() {
    const isVisible = ref(true);

    function toggleVisibility() {
      isVisible.value = !isVisible.value;
    }

    return {
      isVisible,
      toggleVisibility,
    };
  },
};
</script>

<style scoped>
.footer-wrapper {
  position: relative;
  width: 100%;
  background-color: var(--el-color-info-light-9);
}

.footer-toggle {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--el-color-info-light-3);
  color: #fff;
  border-radius: 50%;
  z-index: 1;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid #fff;
  transition: transform 0.3s ease;
}

.arrow-expanded {
  transform: rotate(-90deg);
}

.footer {
  height: auto;
  font-size: 14px;
  color: #333;
  padding: 1rem;
  background-color: var(--el-color-info-light-9);
  margin-top: 0;
  transition: width 0.3s ease;
  overflow: hidden;
  z-index: 1000;
  border-top: 1px solid #ddd;
}

.footer-collapsed {
  width: 2rem;
  padding: 4px;
  text-align: center;
}

.text {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.el-link {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 13px;
  color: var(--el-color-primary-dark);
}

.el-link:hover {
  text-decoration: underline;
}

.el-footer {
  padding: 4px;
}
</style>
