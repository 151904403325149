<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## killall 命令详解

\`killall\` 是一个强大的命令行工具，用于根据进程的名称终止运行中的进程。与 \`kill\` 命令需要通过PID指定目标进程不同，\`killall\` 允许用户通过进程名称结束所有匹配该名称的进程。它非常适合在系统中存在多个相同名称的进程时使用。

\`killall\` 并非Bash内置命令，而是一个外部命令，因此需要依赖外部工具包（如 \`psmisc\`）的支持。

### 1. 基本语法

\`\`\`bash
killall [选项] <进程名>
\`\`\`

- \`<进程名>\`：要终止的进程名称。如果多个同名进程正在运行，\`killall\` 将终止所有进程。

### 2. 常用选项

- \`-i\`：在终止进程之前，提示用户进行确认。
- \`-v\`：显示详细输出，显示哪些进程被终止。
- \`-q\`：静默模式，不输出任何信息。
- \`-u <用户>\`：仅终止由指定用户运行的进程。
- \`-s <信号>\`：指定要发送的信号，默认为 \`SIGTERM\`。
- \`-r\`：支持正则表达式匹配进程名称。
- \`-w\`：等待所有进程被终止后再返回。

### 3. 示例及解释

#### 3.1 终止名为 "myapp" 的所有进程

\`\`\`bash
$ ps -ef | grep myapp
sre      23456  1  0 11:00 ?    00:00:02 /usr/bin/myapp
sre      23457  1  0 11:00 ?    00:00:02 /usr/bin/myapp
$ killall myapp
\`\`\`

**输出解释**：
- \`killall myapp\` 会结束所有名为 \`myapp\` 的进程。如果这些进程正在运行，将会被立即终止。

#### 3.2 强制终止进程

\`\`\`bash
$ killall -s SIGKILL myapp
\`\`\`

**输出解释**：
- 使用 \`-s SIGKILL\` 选项发送 \`SIGKILL\` 信号，强制终止 \`myapp\` 进程。\`SIGKILL\` 信号无法被捕获或忽略，通常用于强制终止无法正常退出的进程。

#### 3.3 在终止前进行确认

\`\`\`bash
$ killall -i myapp
Kill myapp(23456) ? (y/n) y
Kill myapp(23457) ? (y/n) y
\`\`\`

**输出解释**：
- \`-i\` 选项会在每个进程被终止前，提示用户确认是否要结束进程。

#### 3.4 仅终止指定用户的进程

\`\`\`bash
$ ps -ef | grep myapp
sre      23456  1  0 11:00 ?    00:00:02 /usr/bin/myapp
dev01    23457  1  0 11:00 ?    00:00:02 /usr/bin/myapp
$ killall -u sre myapp
\`\`\`

**输出解释**：
- 仅终止由用户 \`sre\` 运行的 \`myapp\` 进程，而不会影响其他用户（如 \`dev01\`）的同名进程。

#### 3.5 显示详细输出

\`\`\`bash
$ killall -v myapp
Killed myapp(23456) owned by sre
Killed myapp(23457) owned by sre
\`\`\`

**输出解释**：
- 使用 \`-v\` 选项可以查看被终止的进程详细信息，包括进程ID和所属用户。

### 4. 输出结果解读

#### 4.1 成功终止

\`\`\`bash
$ killall myapp
【无输出，命令已成功执行】
\`\`\`

- 如果命令成功执行且未使用 \`-v\` 选项，通常不会有任何输出。

#### 4.2 进程不存在

\`\`\`bash
$ killall myapp
myapp: no process found
\`\`\`

**输出解释**：
- 当指定的进程名称不存在时，\`killall\` 返回错误信息，说明没有找到匹配的进程。

### 5. 多架构输出一致性

在 x86_64 和 aarch64 架构下，\`killall\` 命令的行为和输出基本一致，因此没有显著差异需要说明。

### 6. 版本差异

\`killall\` 的不同版本在功能上基本保持一致，因此旧版本和新版本之间的输出差异较小。

### 7. 安全性与替代

\`killall\` 命令仍然是一个安全有效的工具，未被淘汰或替代。对于终止多个同名进程，\`killall\` 比 \`kill\` 更为便捷。

### 8. 安装信息

\`killall\` 通常包含在 \`psmisc\` 软件包中：

- **Ubuntu**：可以使用 \`apt install psmisc\` 安装。
- **RHEL/CentOS**：可以使用 \`yum install psmisc\` 安装。

### 9. 用到 root 权限的场景

有些进程可能需要使用 \`root\` 权限才能终止。例如，系统守护进程通常由 \`root\` 用户运行，此时需要 \`sudo\` 提升权限：

\`\`\`bash
$ sudo killall apache2
\`\`\`

**输出解释**：
- 使用 \`sudo\` 提升权限，终止由 \`root\` 用户运行的 \`apache2\` 进程。

### 10. 总结

\`killall\` 是管理进程的强大工具，允许用户通过进程名称一次性终止多个进程。它提供了丰富的选项，如通过用户筛选、强制终止、正则匹配等，适合系统管理员处理批量进程管理的需求。

        `,
    };
  },
});
</script>
<style scoped></style>
