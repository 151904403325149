<template>
  <div>
    <div v-if="article" class="article-container">
      <div class="get-next" style="display: flex; justify-content: flex-end">
        <el-button @click="fetchArticle" class="refresh-button">
          换一篇
        </el-button>
      </div>
      <div class="title">{{ article.title }}</div>
      <div class="learn-img" v-if="article.imglink">
        <img :src="article.imglink" alt="Article Image" class="article-image" />
      </div>
      <SimpleMarkdown :content="article.content" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import SimpleMarkdown from "./SimpleMarkdown.vue";

export default {
  name: "OpsArticles",
  components: { SimpleMarkdown },
  setup() {
    const article = ref(null);

    const fetchArticle = async () => {
      try {
        const response = await fetch(
          "http://sre.azlinux.com:5070/get_random_article"
        );
        const data = await response.json();
        article.value = data;
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    onMounted(fetchArticle);

    return { article, fetchArticle };
  },
};
</script>

<style scoped>
.article-container {
  background-image: url("http://10kazlinux.oss-cn-beijing.aliyuncs.com/imgs/reading_bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  padding: 1.4rem;
  text-align: left;
}

.title {
  font-size: 20px;
  color: darkviolet;
  margin-left: 1rem;
  font-weight: 600;
}

.content {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 16px;
}

/* 图片样式 */
.learn-img {
  text-align: left; /* 确保图片左对齐 */
}

.article-image {
  max-width: 100%; /* 使图片自适应容器宽度 */
  height: auto; /* 保持纵横比 */
}

/* 播放按钮的样式 */
el-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

el-button:hover {
  background-color: #0056b3;
}

/* 新增换一篇按钮的样式 */
.refresh-button {
  margin: 10px;
}
</style>
