<template>
  <el-container>
    <div :class="['termtitle', { wide: isWide }]">
      <el-tooltip
        class="item"
        effect="dark"
        content="未定义，保留1"
        placement="top"
      >
        <span class="indicator">⬤</span>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="未定义，保留2"
        placement="top"
      >
        <span class="indicator2">⬤</span>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="宽屏展示" placement="top">
        <span class="indicator3" @click="widerScreen">⬤</span>
      </el-tooltip>
      <span id="termname">SSH Online Terminal</span>
      <el-tooltip
        class="box-item"
        effect="light"
        content="连接环境"
        placement="bottom"
      >
        <el-button
          type="success"
          @click="connectBox"
          size="small"
          :disabled="!connectButtonEnabled"
          >连接环境</el-button
        >
      </el-tooltip>
      <span id="release_datetime"> release: {{ release_datetime }} </span>
      |
      <el-tooltip
        class="item"
        effect="dark"
        content="增加终端字体大小"
        placement="bottom"
      >
        <el-button
          plain
          @click="increaseFontSize"
          size="small"
          style="
            font-size: 18px;
            margin: 2px;
            padding: 2px 6px;
            border: none;
            width: 1.4rem;
          "
          >+</el-button
        >
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="减少终端字体大小"
        placement="bottom"
      >
        <el-button
          plain
          @click="decreaseFontSize"
          size="small"
          style="
            font-size: 12px;
            margin: 2px;
            padding: 2px 6px;
            border: none;
            width: 1.4rem;
          "
          >-</el-button
        >
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="终端字体设置为绿色"
        placement="bottom"
      >
        <el-button
          plain
          @click="setFontColorToGreen"
          size="small"
          style="
            background-color: black;
            border: none;
            font-size: 12px;
            margin: 2px;
            padding: 4px 6px;
            color: lightgreen;
          "
          >Green</el-button
        >
      </el-tooltip>
    </div>
    <div :class="['realiframe', { wide: isWide }]">
      <iframe :src="labos"></iframe>
    </div>
  </el-container>
</template>

<script>
export default {
  name: "BigTerms",

  data() {
    return {
      labos: "",
      isWide: false, // wide screen or not
      fontsize: 16,
      fontcolor: "white",
    };
  },
  props: {
    thost: {
      type: String,
      default: "",
    },
    whost: {
      type: String,
      default: "",
    },
    wport: {
      type: Number,
      default: 0,
    },
    port: {
      type: Number,
      default: 0,
    },
    lname: {
      type: String,
      default: "",
    },
    passwd: {
      type: String,
      default: "",
    },
    release_datetime: {
      type: String,
      default: "",
    },
    connectButtonEnabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    increaseFontSize() {
      this.fontsize += 2; // 每次增加2px
      console.log("Terminal fontsize: " + this.fontsize);
      this.connectBox(); // 更新labos
    },
    setFontColorToGreen() {
      this.fontcolor = "lightgreen";
      this.connectBox(); // 更新labos
    },
    decreaseFontSize() {
      if (this.fontsize > 12) {
        this.fontsize -= 2; // 每次减少2px，最小为12px
        console.log("Terminal fontsize: " + this.fontsize);
        this.connectBox(); // 更新labos
      }
    },
    connectBox() {
      this.labos = this.start_task();
      console.log(this.labos);
    },
    widerScreen() {
      this.isWide = !this.isWide; // 切换isWide的值，实现宽屏切换
    },
    start_task() {
      return (this.labos =
        "http://" +
        this.whost +
        ":" +
        this.wport +
        "/?hostname=" +
        this.thost +
        "&username=" +
        this.lname +
        "&port=" +
        this.port +
        "&password=" +
        this.passwd +
        "&title=sre-prod-labs" +
        "&fontcolor=" +
        this.fontcolor +
        "&fontsize=" +
        this.fontsize);
    },
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}
iframe {
  height: 620px;
  width: 100%; /* 默认宽度 */
  border-left: 4px solid black; /* 左侧边框 */
  border-right: 4px solid black; /* 右侧边框 */
  border-top: none; /* 无上边框 */
  border-bottom: none; /* 无下边框 */
  box-sizing: border-box;
}
.termtitle {
  background-color: #262626;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 800px; /* 默认宽度 */
  max-width: 100%;
  text-align: left;
  transition: width 0.3s ease; /* 添加过渡效果 */
}
.termtitle.wide {
  width: 100%; /* 宽屏状态下的宽度 */
}
.realiframe {
  background-color: black;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: none;
  width: 800px; /* 默认宽度 */
  max-width: 100%;
  transition: width 0.3s ease; /* 添加过渡效果 */
  margin: 0;
}
.realiframe.wide {
  width: 100%; /* 宽屏状态下的宽度 */
}
.indicator {
  color: #ff6666;
  padding: 2px;
  margin-left: 0.6rem;
}
.indicator2 {
  color: #ffcc33;
  padding: 4px;
}
.indicator3 {
  color: #33cc33;
  padding: 4px;
  margin-right: 6px;
}

.indicator3:hover {
  cursor: pointer;
}

#termname {
  color: white;
  font-size: 14px;
  margin: 0 10px;
}

.el-button {
  font-size: 14px;
  padding: 4px;
  margin: 0 1rem;
}

#release_datetime {
  font-size: 14px;
  color: grey;
}
</style>
