<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## wget 命令详解

\`wget\` 是一个常用的命令行工具，用于从网络上下载文件。它支持HTTP、HTTPS和FTP协议，并且可以处理重定向、代理服务器以及不同的认证机制。\`wget\` 是GNU项目的一部分，广泛应用于Linux和类Unix操作系统中。

### 基本用法

\`wget\` 命令的基本语法如下：

\`\`\`bash
wget [选项] [URL]
\`\`\`

其中，\`[选项]\` 是用来指定下载行为的选项，\`[URL]\` 是你想要下载的文件的网络地址。

### 常用选项

- \`-O 文件名\`：将下载的内容保存到指定的文件中，而不是使用URL中的文件名。
- \`-c\`：继续下载一个已经中断的文件。
- \`-r\`：递归下载网页和相关文件。
- \`-np\`：在递归下载时，不访问上层目录。
- \`--limit-rate=速率\`：限制下载速度。
- \`-P 目录\`：将下载的文件保存到指定的目录中。
- \`--no-check-certificate\`：忽略SSL证书检查。

### 示例用法

以下是一些 \`wget\` 命令的示例用法：

#### 示例 1: 下载一个文件

\`\`\`bash
wget https://example.com/file.txt
\`\`\`

**输出：**

\`\`\`text
--2024-09-11 12:00:00--  https://example.com/file.txt
Resolving example.com (example.com)... 93.184.216.34
Connecting to example.com (example.com)|93.184.216.34|:443... connected.
HTTP request sent, awaiting response... 200 OK
Length: 12345 (12K) [text/plain]
Saving to: ‘file.txt’

file.txt         100%[===================>]  12.06K  --.-KB/s    in 0.01s

2024-09-11 12:00:00 (1.20 MB/s) - ‘file.txt’ saved [12345/12345]
\`\`\`

**解读：** 该命令从 \`https://example.com/file.txt\` 下载文件并保存为 \`file.txt\`。

#### 示例 2: 继续下载一个中断的文件(该用法重要)

\`\`\`bash
wget -c https://example.com/largefile.zip
\`\`\`

**输出：**

\`\`\`text
--2024-09-11 12:05:00--  https://example.com/largefile.zip
Resuming transfer from byte position 2048000
Connecting to example.com (example.com)|93.184.216.34|:443... connected.
HTTP request sent, awaiting response... 206 Partial Content
Length: 4096000 (3.9M), 2048000 (1.9M) remaining [application/zip]
Saving to: ‘largefile.zip’

largefile.zip     100%[===================>]   3.9M  1.00MB/s    in 2.0s

2024-09-11 12:05:02 (1.94 MB/s) - ‘largefile.zip’ saved [4096000/4096000]
\`\`\`

**解读：** 该命令继续从中断的地方下载 \`largefile.zip\` 文件。

#### 示例 3: 递归下载网页

\`\`\`bash
wget -r -np https://example.com/
\`\`\`

**输出：**

\`\`\`text
--2024-09-11 12:10:00--  https://example.com/
Resolving example.com (example.com)... 93.184.216.34
Connecting to example.com (example.com)|93.184.216.34|:443... connected.
HTTP request sent, awaiting response... 200 OK
Length: unspecified [text/html]
Saving to: ‘index.html’

index.html         100%[===================>]  10.00K  --.-KB/s    in 0.01s

2024-09-11 12:10:00 (1.00 MB/s) - ‘index.html’ saved [10240/10240]
\`\`\`

**解读：** 该命令递归下载 \`example.com\` 网站的所有页面和资源，但不访问上层目录。

### 在不同架构下的表现

\`wget\` 命令在 aarch64 和 x86_64/amd64 架构下的输出是一致的，命令行为不会因架构不同而变化。

### 版本差异

在 \`wget\` 的旧版本和新版本之间，可能会有一些功能和选项的差异。例如，新版本可能会增加新的选项或改进现有的功能。可以使用以下命令检查版本：

\`\`\`bash
wget --version
\`\`\`

**示例输出：**

\`\`\`text
wget 1.21.3
\`\`\`

### 安全性和替代命令

\`wget\` 是一个稳定且广泛使用的工具，没有被淘汰的风险。可以使用 \`curl\` 作为替代工具，其功能也支持下载文件。

### 安装

在 Ubuntu 和 RHEL 上，\`wget\` 的安装包名称为 \`wget\`。可以使用以下命令安装：

**Ubuntu：**

\`\`\`bash
sudo apt-get install wget
\`\`\`

**RHEL：**

\`\`\`bash
sudo yum install wget
\`\`\`

### 权限

大多数 \`wget\` 操作不需要 root 权限。但如果你需要将文件下载到受限目录或需要设置系统级的代理，可能需要 root 权限。

\`\`\`bash
sudo wget -O /etc/important_file https://example.com/important_file
\`\`\`

**输出：**

\`\`\`text
--2024-09-11 12:15:00--  https://example.com/important_file
Resolving example.com (example.com)... 93.184.216.34
Connecting to example.com (example.com)|93.184.216.34|:443... connected.
HTTP request sent, awaiting response... 200 OK
Length: 56789 (56K) [application/octet-stream]
Saving to: ‘/etc/important_file’

/etc/important_file 100%[===================>]  56.78K  --.-KB/s    in 0.02s

2024-09-11 12:15:00 (2.84 MB/s) - ‘/etc/important_file’ saved [56789/56789]
\`\`\`

**解读：** 该命令以 root 权限将文件下载到 \`/etc/important_file\`。

### 总结

\`wget\` 是一个强大且灵活的工具，适合各种下载需求。通过使用不同的选项，可以定制下载过程以满足特定需求。

        `,
    };
  },
});
</script>
<style scoped></style>
