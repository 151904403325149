<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sudo 命令详解

\`sudo\` 命令是 Linux 系统中最常用的命令之一，用于以超级用户（root）权限执行命令。通过 \`sudo\`，用户可以在不直接登录 root 用户的情况下执行特权操作，从而提高系统的安全性。本文将详细介绍 \`sudo\` 命令的使用方法、常见参数、不同架构的输出差异、历史版本的变化以及相关的替代命令。

### 什么是 \`sudo\`

\`sudo\` 是 "superuser do" 的缩写，意味着“以超级用户身份执行”。在 Linux 系统中，普通用户不能直接进行需要超级用户权限的操作，例如安装软件、修改系统配置等。而 \`sudo\` 命令使用户可以在不切换到 root 用户的情况下临时获得超级用户权限，执行需要权限的操作。

#### Bash 内置命令与外部命令的区别

需要注意的是，\`sudo\` 是一个外部命令，而不是 Bash 的内置命令。这意味着当你在终端中使用 \`sudo\` 时，它会调用系统中的 \`sudo\` 程序，而不是直接在 Bash 中执行。

### \`sudo\` 命令的常见用法

#### 基本语法

\`\`\`bash
sudo [选项] <命令>
\`\`\`

- \`<命令>\`：需要以超级用户权限执行的命令。
- \`[选项]\`：可选的参数，用于控制 \`sudo\` 的行为。

#### 示例1：使用 \`sudo\` 执行单个命令

执行 \`ls\` 命令查看 \`/root\` 目录下的文件，该目录通常只有 root 用户有权限访问。

\`\`\`bash
sudo ls /root
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo ls /root
[sudo] password for sre: 
anaconda-ks.cfg
myfile.txt
\`\`\`

输出解释：\`sudo\` 要求用户输入自己的密码（而非 root 用户的密码）来验证其身份。验证通过后，\`ls\` 命令列出了 \`/root\` 目录下的文件。

#### 示例2：使用 \`sudo\` 编辑系统文件

以超级用户权限编辑 \`/etc/hosts\` 文件：

\`\`\`bash
sudo nano /etc/hosts
\`\`\`

**输出：**

【无输出，命令已成功执行】

输出解释：此命令使用 \`nano\` 编辑器打开 \`/etc/hosts\` 文件进行编辑。由于 \`nano\` 是一个文本编辑器，所以不会有标准输出。

#### 示例3：使用 \`sudo\` 切换到 root 用户

通过以下命令，可以切换到 root 用户：

\`\`\`bash
sudo su -
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo su -
[root@azlinux-prod-cn ~]#
\`\`\`

输出解释：\`sudo su -\` 命令使用户切换到 root 用户，注意终端提示符从 \`$\` 变为 \`#\`，表示当前处于超级用户模式。

### 常用参数

- \`-l\`：列出当前用户在 \`sudoers\` 文件中被授权的命令。

\`\`\`bash
sudo -l
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo -l
[sudo] password for sre: 
Matching Defaults entries for sre on this host:
    env_reset, mail_badpass,
    secure_path=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin

User sre may run the following commands on this host:
    (ALL : ALL) ALL
\`\`\`

输出解释：显示当前用户 \`sre\` 可以执行的 \`sudo\` 命令和相关设置。

- \`-u\`：指定以其他用户身份执行命令。

\`\`\`bash
sudo -u dev01 whoami
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo -u dev01 whoami
dev01
\`\`\`

输出解释：此命令以 \`dev01\` 用户的身份执行 \`whoami\` 命令，输出显示执行该命令的用户为 \`dev01\`。

- \`-k\`：清除 \`sudo\` 的时间戳，下次使用 \`sudo\` 时需要再次输入密码。

\`\`\`bash
sudo -k
\`\`\`

**输出：**

【无输出，命令已成功执行】

### 多架构和多版本下的表现

\`sudo\` 在不同架构下（如 \`aarch64\` 和 \`x86_64\`）的行为基本一致，因此不会有显著差异。

如果你使用的系统 \`sudo\` 版本较旧，某些参数或输出格式可能会有所不同。例如，旧版本的 \`sudo\` 可能不支持一些新引入的参数或安全特性。

#### 示例：旧版本和新版本的差异

在某些旧版本中，\`sudo\` 命令的提示符格式可能不同，如不要求用户确认执行高风险命令，而在新版本中会有相关提示。

### 安全性和弃用的命令

随着系统安全要求的提高，有些历史命令已经不再推荐使用，例如 \`rsh\`、\`rlogin\` 等基于 \`sudo\` 的远程访问方式，已经被更安全的 \`ssh\` 取代。

### \`sudo\` 的安装

在多数 Linux 发行版中，\`sudo\` 已默认安装。如果你的系统中没有 \`sudo\`，可以使用以下命令进行安装：

- 在 Ubuntu/Debian 系统中：

\`\`\`bash
sudo apt-get install sudo
\`\`\`

- 在 RHEL/CentOS 系统中：

\`\`\`bash
sudo yum install sudo
\`\`\`

安装包名称：\`sudo\`

### 总结

\`sudo\` 是 Linux 系统管理中不可或缺的工具，通过它，用户可以以安全的方式执行需要超级用户权限的命令。掌握 \`sudo\` 的使用方法和参数，不仅可以提升系统管理的效率，还可以增强系统的安全性。

        `,
    };
  },
});
</script>
<style scoped></style>
