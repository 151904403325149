<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
  ## touch 命令详解

\`touch\`命令是Linux系统中用于创建空文件或更新现有文件的修改时间戳的基本命令。本文将详细介绍\`touch\`命令的用法、常用参数，并通过示例演示其实际应用及输出结果。

### 基本用法

\`touch\`命令最基本的用法是创建一个新的空文件。如果指定的文件不存在，\`touch\`将创建一个新文件。例如，要创建一个名为\`example.txt\`的文件，可以使用以下命令：

\`\`\`bash
touch example.txt
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls\`命令可以验证新文件是否被创建。

\`\`\`bash
ls
\`\`\`

**示例输出：**

\`\`\`
example.txt
\`\`\`

**解读：** \`ls\`命令显示了当前目录下的新文件\`example.txt\`，确认了\`touch\`命令的成功执行。

### \`-c\` 参数：不创建新文件

\`-c\`参数用于在文件不存在时不创建新文件。如果指定的文件不存在，\`touch\`命令将不会创建它。例如，要更新文件\`example.txt\`的时间戳，但不创建不存在的文件，可以使用以下命令：

\`\`\`bash
touch -c non_existing_file.txt
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。如果文件\`non_existing_file.txt\`不存在，命令不会创建新文件，也不会产生任何错误信息。

### \`-a\` 参数：仅更新访问时间

\`-a\`参数用于仅更新文件的访问时间，不修改文件的修改时间。例如，要仅更新文件\`example.txt\`的访问时间，可以使用以下命令：

\`\`\`bash
touch -a example.txt
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls -l\`命令可以查看文件的访问时间是否已更新。

\`\`\`bash
ls -l example.txt
\`\`\`

**示例输出：**

\`\`\`
-rw-r--r-- 1 sre sre-group 0 Sep  6 10:00 example.txt
\`\`\`

**解读：** 文件的访问时间被更新为当前时间，确认了\`-a\`参数的效果。

### \`-m\` 参数：仅更新修改时间

\`-m\`参数用于仅更新文件的修改时间，不修改文件的访问时间。例如，要仅更新文件\`example.txt\`的修改时间，可以使用以下命令：

\`\`\`bash
touch -m example.txt
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls -l\`命令可以查看文件的修改时间是否已更新。

\`\`\`bash
ls -l example.txt
\`\`\`

**示例输出：**

\`\`\`
-rw-r--r-- 1 sre sre-group 0 Sep  6 10:01 example.txt
\`\`\`

**解读：** 文件的修改时间被更新为当前时间，确认了\`-m\`参数的效果。

### \`-t\` 参数：设置时间戳

\`t\`参数允许用户设置指定的时间戳，而不是使用当前时间。例如，要将文件\`example.txt\`的时间戳设置为\`2023年9月1日 12:00\`，可以使用以下命令：

\`\`\`bash
touch -t 202309011200 example.txt
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls -l\`命令可以查看文件的时间戳是否已更改为指定的时间。

\`\`\`bash
ls -l example.txt
\`\`\`

**示例输出：**

\`\`\`
-rw-r--r-- 1 sre sre-group 0 Sep  1 12:00 example.txt
\`\`\`

**解读：** 文件的时间戳被设置为\`2023年9月1日 12:00\`，确认了\`-t\`参数的效果。

### 总结

\`touch\`命令是创建空文件和更新文件时间戳的重要工具。它提供了多个参数来满足不同的需求，包括创建新文件、仅更新访问或修改时间、设置指定的时间戳等。掌握这些参数的用法，可以更灵活地管理文件的创建和时间信息。

          `,
    };
  },
});
</script>
<style scoped></style>
