<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
# nice 命令详解

\`nice\` 命令用于启动一个程序，并调整它的优先级。它在Linux和Unix系统中用于控制进程的优先级，以便系统可以合理分配CPU时间。通过改变进程的优先级，你可以确保关键任务获得更多的CPU时间，而将不那么重要的任务推迟处理。

## 基本用法

\`nice\`命令通过改变进程的“优先级”来影响其调度。优先级的范围是从-20（最高优先级）到19（最低优先级）。默认情况下，\`nice\`命令以优先级10启动进程。

### 1. 启动进程并设置优先级

要以指定优先级启动一个程序，可以使用以下语法：

\`\`\`bash
nice -n [priority] [command]
\`\`\`

**示例：**

\`\`\`bash
nice -n 15 /path/to/command
\`\`\`

**解释：**
此命令将以优先级15启动\`/path/to/command\`程序。优先级15表示这个进程的调度优先级较低，因此在系统忙碌时，它将获得较少的CPU时间。

### 2. 检查进程的优先级

使用\`ps\`命令查看进程的优先级（nice值）：

\`\`\`bash
ps -o pid,ni,cmd -p [pid]
\`\`\`

**示例：**

\`\`\`bash
ps -o pid,ni,cmd -p 1234
\`\`\`

**解释：**
此命令将显示进程ID为1234的进程的PID、nice值和命令。

## 重要参数

- \`-n [priority]\`：设置进程的nice值，范围是-20到19。较低的nice值表示较高的优先级。
- \`-h\`：显示帮助信息。

## 示例输出

### 启动进程并设置优先级

\`\`\`bash
$ nice -n 15 sleep 60
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解释：**
此命令将以优先级15启动\`sleep\`命令，程序将暂停60秒。

### 检查进程的优先级

\`\`\`bash
$ ps -o pid,ni,cmd -p 1234
\`\`\`

**输出示例：**

\`\`\`
  PID  NI CMD
 1234  15 sleep 60
\`\`\`

**解释：**
此命令显示了进程ID为1234的进程的nice值（15）和命令（sleep 60）。

## 不同架构的输出

\`nice\`命令的功能和输出在不同架构（aarch64和x86_64/amd64）下通常没有差异，因为它是一个标准的系统工具，不依赖于特定的硬件架构。

## 版本差异

\`nice\`命令的输出和功能在不同版本的Linux中通常一致。主要差异可能在于命令的实现细节或帮助信息。建议使用\`man nice\`命令查看特定版本的文档。

## 不安全命令的替代

\`nice\`命令本身并不被认为是不安全的，但它不能完全解决所有的性能调度问题。在高负载或多任务环境中，可以考虑使用\`renice\`命令来调整已运行进程的优先级，或使用\`taskset\`来设置进程的CPU亲和性。

## 安装

\`nice\`命令是Linux系统的一部分，通常已经预装在大多数发行版中。如果需要安装或重新安装它，可以通过以下方式完成：

- **Ubuntu**：
  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL**：
  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

## 需要root权限的用法

在Linux系统中，\`nice\`命令可以用普通用户权限运行，但如果你想以高优先级（负nice值）启动进程，则需要root权限。

**示例：**

\`\`\`bash
sudo nice -n -10 /path/to/command
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解释：**
此命令将以优先级-10启动指定的程序，\`sudo\`提供了必要的权限。

        `,
    };
  },
});
</script>
<style scoped></style>
