<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lsattr 命令详解

\`lsattr\` 是 Linux 系统中用于显示文件或目录的扩展属性（attributes）的命令。它能够列出文件系统中文件和目录的属性，这些属性控制了文件的行为和权限，通常用于提高文件的安全性和稳定性。

在 Linux 中，\`lsattr\` 命令属于 \`e2fsprogs\` 软件包，常用于 ext 文件系统（如 ext2、ext3、ext4），可以帮助管理员查看文件的属性，如是否可修改、是否可删除等。

### 安装

\`lsattr\` 命令通常是 \`e2fsprogs\` 软件包的一部分，默认安装在大多数 Linux 发行版上。如果没有安装，可以使用以下命令进行安装：

#### Ubuntu

\`\`\`bash
sudo apt-get install e2fsprogs
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install e2fsprogs
\`\`\`

### 基本语法

\`\`\`bash
lsattr [选项] [文件或目录]
\`\`\`

- \`选项\`：用于调整输出的格式和信息。
- \`文件或目录\`：要查看属性的文件或目录名。

### 常用选项

- \`-a\`：显示所有文件，包括以 \`.\` 开头的隐藏文件。
- \`-d\`：显示目录本身的属性，而不是显示目录内文件的属性。
- \`-R\`：递归地显示目录及其子目录下所有文件的属性。
- \`-v\`：显示文件的版本号（一般很少使用）。

### 常用属性

\`lsattr\` 命令输出中，文件属性通常以字母的形式表示。常见的属性有：

- \`a\`：只能追加数据，不能删除或修改现有数据。
- \`i\`：文件不可修改、删除、重命名，不能创建新的硬链接。
- \`d\`：文件将不被 \`dump\` 程序备份。
- \`s\`：当文件被删除时，其数据块将被填充为零并清除。
- \`u\`：当文件被删除时，其内容将会被保留，便于恢复。

### lsattr 使用示例

#### 示例一：查看文件的属性

\`\`\`bash
lsattr /home/sre/testfile
\`\`\`

**输出示例**：

\`\`\`plaintext
----i--------e---- /home/sre/testfile
\`\`\`

**解读**：\`testfile\` 文件具有 \`i\` 属性，这意味着该文件是不可修改和删除的。\`e\` 表示该文件使用了扩展属性。

#### 示例二：查看目录的属性

\`\`\`bash
lsattr -d /home/sre/testdir
\`\`\`

**输出示例**：

\`\`\`plaintext
------------- /home/sre/testdir
\`\`\`

**解读**：\`testdir\` 目录没有特殊的属性，所有属性标志位都为空。

#### 示例三：查看隐藏文件的属性

\`\`\`bash
lsattr -a /home/sre/
\`\`\`

**输出示例**：

\`\`\`plaintext
------------- .bashrc
------------- .bash_profile
----i--------e---- .importantfile
\`\`\`

**解读**：这个命令列出了 \`/home/sre/\` 目录下包括隐藏文件的所有文件属性。其中 \`.importantfile\` 文件具有 \`i\` 属性，表示该文件不能被修改或删除。

#### 示例四：递归查看目录及子目录的文件属性

\`\`\`bash
lsattr -R /home/sre/
\`\`\`

**输出示例**：

\`\`\`plaintext
------------- /home/sre/testdir
----i--------e---- /home/sre/testfile
------------- /home/sre/testdir/file1
------------- /home/sre/testdir/file2
\`\`\`

**解读**：该命令递归显示了 \`testdir\` 目录及其子目录中的文件属性。

#### 示例五：显示目录本身和文件属性

\`\`\`bash
lsattr -d /home/sre/testdir
\`\`\`

**输出示例**：

\`\`\`plaintext
------------- /home/sre/testdir
\`\`\`

**解读**：只显示了目录 \`testdir\` 自身的属性，而不是其内部文件的属性。

### 权限需求

\`lsattr\` 命令一般不需要 root 权限即可执行，但查看某些特定系统文件或目录的属性时，可能需要 \`sudo\` 提升权限。

#### 示例：使用 root 权限查看系统文件属性

\`\`\`bash
sudo lsattr /etc/passwd
\`\`\`

**输出示例**：

\`\`\`plaintext
------------- /etc/passwd
\`\`\`

**解读**：该命令使用 \`sudo\` 查看 \`/etc/passwd\` 文件的属性，显示没有特殊属性。

### 版本差异

\`lsattr\` 命令的功能和输出格式在不同的版本中变化很少。大多数系统中的 \`lsattr\` 版本输出结果是相同的。在查看文件属性时，不同版本之间没有显著的差异。

### 架构无差异

在 aarch64 和 x86_64/amd64 架构下，\`lsattr\` 命令的输出结果没有显著差异，因此无需单独列出不同架构的结果。

### 总结

\`lsattr\` 命令是用于查看 Linux 文件系统中文件和目录的扩展属性的工具。通过该命令，系统管理员能够快速了解文件的状态以及限制条件，有效地提高了文件的安全性和稳定性。

        `,
    };
  },
});
</script>
<style scoped></style>
