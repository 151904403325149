import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TermView from "../views/TermView.vue";
import BasicView from "../views/BasicView.vue";
import AdvancedView from "../views/AdvancedView.vue";
import OnlineDistros from "../views/OnlineDistros.vue";
import PortalHome from "../views/PortalHome.vue";
import LoginPage from "../views/LoginPage.vue";
import OpsTools from "../views/OpsTools.vue";
import RegPage from "../views/RegPage.vue";
import SpecialSkills from "../views/SpecialSkills.vue";
import ManPages from "../views/ManPages.vue";
import WorkMode from "../views/WorkMode.vue";
import DemoPage from "../views/DemoPage2.vue";
import GoodOps from "../views/GoodOps.vue";
import OpsEngReading from "../views/OpsEngReading.vue";
import OpsInterview from "../views/OpsInterview.vue";
import OpsSecurity from "../views/OpsSecurity.vue";
import BuyVip from "../views/BuyVip.vue";
import UserPrivacy from "../views/UserPrivacy.vue";
import SREEngineering from "../views/SREEngineering.vue";
import ITTerms from "../views/ITTerms.vue";
import UserCenter from "../views/UserCenter.vue";
import FAQ from "../views/FAQ.vue";

import NotFound from "../views/NotFound.vue";

import DockerMap from "../views/specialized/DockerMap.vue";

const routes = [
  {
    path: "/docker",
    name: "docker",
    component: DockerMap,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/term",
    name: "term",
    component: TermView,
  },
  {
    path: "/basic",
    name: "basic",
    component: BasicView,
  },
  {
    path: "/advanced/:taskId",
    name: "advanced",
    component: AdvancedView,
  },
  {
    path: "/terms",
    name: "terms",
    component: ITTerms,
  },
  {
    path: "/online-env",
    name: "online-env",
    component: OnlineDistros,
  },
  {
    path: "/good-ops",
    name: "good-ops",
    component: GoodOps,
  },
  {
    path: "/ops-eng-reading",
    name: "ops-eng-reading",
    component: OpsEngReading,
  },
  {
    path: "/interview",
    name: "interview",
    component: OpsInterview,
  },
  {
    path: "/ops-security",
    name: "ops-security",
    component: OpsSecurity,
  },
  {
    path: "/sre-engineering",
    name: "sre-engineering",
    component: SREEngineering,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/portal",
    name: "portal",
    component: PortalHome,
  },
  {
    path: "/topics",
    name: "topics",
    component: SpecialSkills,
  },
  {
    path: "/man-pages",
    name: "man-pages",
    component: ManPages,
  },
  {
    path: "/user-center",
    name: "user-center",
    component: UserCenter,
  },
  {
    path: "/buy-vip",
    name: "buy-vip",
    component: BuyVip,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/ops-tools",
    name: "ops-tools",
    component: OpsTools,
  },
  {
    path: "/register",
    name: "register",
    component: RegPage,
  },
  {
    path: "/work-mode",
    name: "work-mode",
    component: WorkMode,
  },
  {
    path: "/demo",
    name: "demo",
    component: DemoPage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: UserPrivacy,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
