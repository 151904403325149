<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## zip 命令详解

\`zip\` 是一个用于创建压缩文件的命令，广泛用于将多个文件和目录压缩为一个文件以节省空间或便于传输。它支持多种压缩选项，并且可以指定不同的压缩级别，甚至可以对压缩文件进行密码保护。在本文中，我们将详细介绍 \`zip\` 命令的重要参数和使用方法，并通过模拟用户的实际操作演示具体用法。

### 基本概念

\`zip\` 命令通过压缩多个文件生成 \`.zip\` 文件。与 \`tar\` 等命令不同，\`zip\` 支持跨平台使用，Windows 系统也能很好地处理 \`.zip\` 文件。

### 安装

大多数 Linux 系统预装了 \`zip\` 命令。如果系统中没有，可以通过以下命令进行安装：

#### Ubuntu/Debian 系列
\`\`\`bash
sudo apt install zip
\`\`\`

#### RHEL/CentOS 系列
\`\`\`bash
sudo yum install zip
\`\`\`

### 基本语法

\`\`\`bash
zip [options] output_file input_files
\`\`\`

- **output_file**：要生成的 \`.zip\` 文件的名称。
- **input_files**：需要压缩的文件或目录。
- **options**：用于控制压缩行为的选项。

### 示例1：压缩单个文件

假设我们有一个名为 \`file1.txt\` 的文件，使用 \`zip\` 将其压缩：

\`\`\`bash
zip archive.zip file1.txt
\`\`\`

**输出示例：**

\`\`\`bash
  adding: file1.txt (deflated 50%)
\`\`\`

**解读**：该命令将 \`file1.txt\` 文件压缩到 \`archive.zip\` 中，显示压缩比为 50%。

### 示例2：压缩多个文件

我们可以同时压缩多个文件或目录。例如，我们有两个文件 \`file1.txt\` 和 \`file2.txt\`，使用以下命令将其压缩：

\`\`\`bash
zip archive.zip file1.txt file2.txt
\`\`\`

**输出示例：**

\`\`\`bash
  adding: file1.txt (deflated 50%)
  adding: file2.txt (deflated 60%)
\`\`\`

**解读**：\`zip\` 成功将两个文件添加到 \`archive.zip\` 中，并分别显示了压缩比。

### 示例3：递归压缩目录

如果要压缩整个目录及其子目录中的文件，可以使用 \`-r\` 选项。例如，压缩目录 \`dir1\`：

\`\`\`bash
zip -r archive.zip dir1
\`\`\`

**输出示例：**

\`\`\`bash
  adding: dir1/ (stored 0%)
  adding: dir1/file1.txt (deflated 50%)
  adding: dir1/subdir/ (stored 0%)
  adding: dir1/subdir/file2.txt (deflated 60%)
\`\`\`

**解读**：此命令将目录 \`dir1\` 及其子目录 \`subdir\` 中的所有文件压缩到 \`archive.zip\` 中。

### 常用选项

#### \`-e\` 选项：加密压缩文件

\`zip\` 命令可以使用 \`-e\` 选项为压缩文件设置密码：

\`\`\`bash
zip -e archive.zip file1.txt
\`\`\`

**输出解读**：系统将提示输入和确认密码，文件压缩后需要密码才能解压。

#### \`-q\` 选项：静默模式

如果不想显示压缩过程的详细信息，可以使用 \`-q\` 选项：

\`\`\`bash
zip -q archive.zip file1.txt
\`\`\`

**输出解读**：【无输出，命令已成功执行】  
使用 \`-q\` 选项，压缩过程不输出任何信息。

#### \`-9\` 选项：最大压缩率

使用 \`-9\` 选项可以指定最高的压缩比，虽然压缩速度可能变慢：

\`\`\`bash
zip -9 archive.zip file1.txt
\`\`\`

**输出示例：**

\`\`\`bash
  adding: file1.txt (deflated 80%)
\`\`\`

**解读**：压缩率更高，文件体积进一步减小。

#### \`-d\` 选项：从压缩文件中删除文件

可以使用 \`-d\` 选项从现有的 \`.zip\` 文件中删除指定文件。例如，删除 \`archive.zip\` 中的 \`file1.txt\`：

\`\`\`bash
zip -d archive.zip file1.txt
\`\`\`

**输出示例：**

\`\`\`bash
deleting: file1.txt
\`\`\`

**解读**：文件 \`file1.txt\` 被从 \`archive.zip\` 中删除。

### Bash 内置命令与外部命令

\`zip\` 是外部命令，通常位于 \`/usr/bin/zip\` 路径下，可以使用 \`type\` 命令确认：

\`\`\`bash
type zip
\`\`\`

**输出示例：**

\`\`\`bash
zip is /usr/bin/zip
\`\`\`

**解读**：这表明 \`zip\` 是一个外部命令，而非 bash 内置命令。

### 不同架构下的输出

\`zip\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为没有差异，因此无需列出特定架构下的输出。

### 版本差异

不同版本的 \`zip\` 命令在功能上略有不同，但基础功能保持一致。可以使用以下命令查看系统中 \`zip\` 命令的版本：

\`\`\`bash
zip -v
\`\`\`

**输出示例：**

\`\`\`bash
This is Zip 3.0 (July 5th 2008), by Info-ZIP.
\`\`\`

**解读**：当前系统上安装的是 \`zip\` 3.0 版本。

### 使用root权限的选项

\`zip\` 命令通常不需要 \`root\` 权限，但在某些系统目录中压缩文件可能需要使用 \`sudo\` 提升权限：

\`\`\`bash
sudo zip archive.zip /var/log/syslog
\`\`\`

**解读**：此命令以 \`root\` 权限压缩 \`/var/log/syslog\` 文件到 \`archive.zip\` 中。

### 替代命令

如果 \`zip\` 命令被淘汰，可以使用 \`tar\` 命令作为替代工具。\`tar\` 命令也可以压缩文件，并且它通常与 \`gzip\` 或 \`bzip2\` 一起使用。

示例命令：

\`\`\`bash
tar -czvf archive.tar.gz file1.txt file2.txt
\`\`\`

**解读**：\`tar\` 命令与 \`gzip\` 结合使用，将多个文件压缩成一个 \`.tar.gz\` 文件。

### 总结

\`zip\` 命令是压缩和打包文件的强大工具，适用于跨平台的文件管理。通过它的各种选项，用户可以轻松地压缩、加密、删除文件，并控制压缩率等。

- \`-r\` 选项用于递归压缩目录。
- \`-e\` 选项可以为压缩文件添加密码保护。
- \`-9\` 选项用于最大压缩率。

掌握 \`zip\` 命令可以大大简化文件打包和传输的操作，在日常工作中非常有用。

        `,
    };
  },
});
</script>
<style scoped></style>
