<template>
  <a :href="currentLink" class="super-link" @click="gotoVideo">
    <slot></slot>
  </a>
</template>

<script>
import axios from "axios";

export default {
  name: "SuperLink",
  props: {
    resId: {
      type: String,
      required: true,
    },
    defaultLink: {
      type: String,
      default: "https://www.bilibili.com/",
    },
  },
  data() {
    return {
      currentLink: this.defaultLink, // 初始链接
    };
  },
  mounted() {
    this.fetchLink();
  },
  methods: {
    gotoVideo() {
      console.log("Link clicked:", this.currentLink);
    },
    fetchLink() {
      const requestUrl = `http://sre.azlinux.com:5558/get_short_url?res_id=${this.resId}`;

      // 发送GET请求获取super_link
      axios
        .get(requestUrl)
        .then((response) => {
          if (response.data && response.data.super_link) {
            this.currentLink = response.data.super_link;
          }
        })
        .catch((error) => {
          console.error("获取短链接失败: ", error);
        });
    },
  },
};
</script>

<style scoped>
.super-link {
  text-decoration: none;
  color: #409eff; /* Element Plus 的主题色 */
  cursor: pointer;
  display: block;
  font-size: 14px;
}

.super-link:hover {
  color: #66b1ff; /* 鼠标悬停时的颜色 */
}
</style>
