<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## gzip 命令详解

### 概述

\`gzip\` 是一个用于文件压缩的外部命令，它通过使用Lempel-Ziv编码（LZ77）算法来减少文件的大小。\`gzip\` 通常用于压缩单个文件，并生成一个扩展名为\`.gz\`的压缩文件。与其他压缩工具不同，\`gzip\` 不支持压缩多个文件到一个压缩包中（如\`zip\`或\`tar\`命令），但它可以与\`tar\`命令结合使用，形成常见的\`tar.gz\`格式。

### 使用语法

\`\`\`bash
gzip [option] [file...]
\`\`\`

- \`option\`: 可以指定压缩级别、递归压缩、保留原始文件等。
- \`file\`: 指定要压缩的文件名。

### 重要参数

- \`-d\` 或 \`--decompress\`: 解压缩指定的\`.gz\`文件。
- \`-k\` 或 \`--keep\`: 压缩后保留原始文件，而不是将其删除。
- \`-r\` 或 \`--recursive\`: 递归压缩目录中的所有文件。
- \`-1\` 至 \`-9\`: 指定压缩级别，\`-1\` 为最快但压缩率最低，\`-9\` 为最慢但压缩率最高。默认值为\`-6\`。
- \`-v\` 或 \`--verbose\`: 显示压缩或解压缩的详细信息。
- \`-l\` 或 \`--list\`: 显示压缩文件的内容信息，如压缩比、原始大小等。
- \`-f\` 或 \`--force\`: 强制覆盖已经存在的文件或压缩已存在的文件。

### 基本用法

#### 例1：压缩单个文件

\`\`\`bash
gzip example.txt
\`\`\`

**输出解释**:
该命令会压缩\`example.txt\`文件，生成一个名为\`example.txt.gz\`的压缩文件。

\`\`\`bash
ls
\`\`\`

\`\`\`bash
example.txt.gz
\`\`\`

**解读**:
压缩后，原始文件\`example.txt\`被替换为\`example.txt.gz\`，默认情况下，原始文件将被删除。如果希望保留原始文件，可以使用\`-k\`选项。

#### 例2：解压缩文件

\`\`\`bash
gzip -d example.txt.gz
\`\`\`

**输出解释**:
此命令会解压缩\`example.txt.gz\`文件，恢复为原始的\`example.txt\`文件。

\`\`\`bash
ls
\`\`\`

\`\`\`bash
example.txt
\`\`\`

**解读**:
解压缩后，\`.gz\`文件被删除，恢复为原始的未压缩文件。

### 进阶用法

#### 例3：递归压缩目录中的所有文件

\`\`\`bash
gzip -r myfolder/
\`\`\`

**输出解释**:
此命令会递归地压缩\`myfolder\`目录及其子目录中的所有文件。

\`\`\`bash
ls myfolder/
\`\`\`

\`\`\`bash
file1.txt.gz  file2.log.gz  subfolder/
\`\`\`

**解读**:
目录中的每个文件都被压缩为\`.gz\`格式，但目录结构保持不变。

#### 例4：保留原始文件并压缩

\`\`\`bash
gzip -k example.txt
\`\`\`

**输出解释**:
此命令会压缩\`example.txt\`文件，但保留原始文件。

\`\`\`bash
ls
\`\`\`

\`\`\`bash
example.txt  example.txt.gz
\`\`\`

**解读**:
压缩后，原始文件\`example.txt\`仍然保留，同时生成了\`example.txt.gz\`。

#### 例5：显示压缩文件信息

\`\`\`bash
gzip -l example.txt.gz
\`\`\`

**输出解释**:
此命令显示压缩文件的详细信息，包括原始文件大小、压缩后的大小以及压缩比。

\`\`\`bash
         compressed        uncompressed  ratio uncompressed_name
                72                  144  50.0% example.txt
\`\`\`

**解读**:
输出显示了压缩前后文件的大小以及压缩比，为用户提供压缩效率的参考。

### 不同架构下的表现差异

\`gzip\` 命令在\`aarch64\`和\`x86_64/amd64\`架构的机器上，功能和输出表现通常没有差异。无论是压缩文件的大小还是压缩比，\`gzip\` 都与系统架构无关，因为它使用的是标准的压缩算法。不过，执行时间可能会根据硬件的性能有所不同。

### 不同版本的差异

在旧版本的\`gzip\` 中，某些选项（如\`-k\`保留原始文件）可能尚未实现，或者对某些压缩级别的处理效率较低。建议用户在使用时查看当前系统的\`gzip\`版本，并参考对应版本的文档，确保所需功能可用。

### 安全性与替代命令

\`gzip\` 本身是一个相对安全的工具，但在压缩和解压缩文件时，用户需注意权限问题，特别是在压缩系统文件或共享目录中的文件时。使用\`-f\`选项时要谨慎，因为它会强制覆盖已经存在的文件。

在某些情况下，可以考虑使用\`bzip2\`或\`xz\`命令替代\`gzip\`。这些命令通常提供更高的压缩比，但压缩和解压缩速度可能较慢。

### 示例输出的架构差异

虽然\`gzip\` 在不同架构上的功能一致，但压缩的性能可能因硬件差异而有所不同。例如，在较旧的ARM架构（\`aarch64\`）上，压缩速度可能较慢，而在现代的\`x86_64/amd64\`架构上，性能通常更优越。

### 结论

\`gzip\` 是一个功能强大且易于使用的文件压缩工具，广泛应用于Linux和Unix系统中。它的压缩率和速度在大多数情况下都能够满足需求，并且与\`tar\`等工具结合使用，可以方便地创建和解压缩压缩包。用户在使用时应根据具体需求选择合适的选项，并了解系统环境以获得最佳性能。

        `,
    };
  },
});
</script>
<style scoped></style>
