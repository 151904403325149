<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## top 命令详解

\`top\` 是一个用于显示系统中正在运行的进程的动态信息的命令。它提供了有关系统负载、CPU 使用情况、内存使用情况和进程状态的实时更新信息。\`top\` 命令可以以交互模式运行，也可以以批处理模式运行，用于脚本和日志记录。

### 主要参数和选项

#### 1. \`-d\`（延迟时间）

指定刷新间隔时间（以秒为单位）。例如，\`-d 5\` 每5秒刷新一次。

\`\`\`bash
top -d 5
\`\`\`

#### 2. \`-n\`（更新次数）

指定更新的次数。例如，\`-n 1\` 只更新一次并退出。

\`\`\`bash
top -n 1
\`\`\`

#### 3. \`-b\`（批处理模式）

以批处理模式运行，适合将输出重定向到文件中。例如，\`top -b -n 1\`。

\`\`\`bash
top -b -n 1
\`\`\`

**示例输出**

\`\`\`plaintext
top - 10:22:03 up 1 day,  3:21,  2 users,  load average: 0.25, 0.40, 0.32
Tasks: 162 total,   1 running, 161 sleeping,   0 stopped,   0 zombie
%Cpu(s):  1.0 us,  0.5 sy,  0.0 ni, 98.0 id,  0.3 wa,  0.2 hi,  0.0 si,  0.0 st
KiB Mem :  8101024 total,  2102024 free,  2750348 used,  3242652 buff/cache
KiB Swap:  2047996 total,  2047996 free,        0 used.  5261432 avail Mem 

  PID USER      PR  NI    VIRT    RES    SHR S  %CPU %MEM     TIME+ COMMAND
 1132 sre       20   0  162040  16856  10284 S   1.0  0.2   0:00.05 top
    1 root      20   0  225944  14508   9648 S   0.0  0.2   0:03.05 systemd
    2 root      20   0      0      0      0 S   0.0  0.0   0:00.00 kthreadd
\`\`\`

**输出解读**:

- **系统信息**: 当前时间、系统运行时间、负载平均值等。
- **任务信息**: 总任务数、运行中任务数、睡眠任务数等。
- **CPU 使用情况**: CPU 使用的各个方面（用户、系统、空闲等）的百分比。
- **内存和交换区信息**: 系统内存和交换区的总量、空闲量、已用量等。
- **进程信息**: 进程的 PID、用户、优先级、虚拟内存、实际内存、共享内存、CPU 使用率、内存使用率、运行时间和命令名。

#### 4. \`-p\`（监控特定进程）

只监控指定的进程。例如，\`-p 1234\` 只监控 PID 为 1234 的进程。

\`\`\`bash
top -p 1234
\`\`\`

#### 5. \`-u\`（监控特定用户）

只监控指定用户的进程。例如，\`-u sre\` 只监控用户为 sre 的进程。

\`\`\`bash
top -u sre
\`\`\`

#### 6. \`-c\`（显示完整的命令行）

显示完整的命令行而不仅仅是命令名。例如，\`-c\`。

\`\`\`bash
top -c
\`\`\`

### 内置命令与外部命令

\`top\` 是一个外部命令，不是 Bash 内置命令。它位于 \`/usr/bin/top\`。它的功能与其他内置命令如 \`ps\` 相似，但 \`ps\` 命令通常用于一次性快照，而 \`top\` 提供了动态的、实时的系统信息。

### 不同架构下的输出

\`top\` 命令的输出在不同架构下一般不会有显著差异，因为它主要显示的是系统状态数据。不过，CPU 和内存使用情况的具体数字会根据系统的配置和负载情况有所不同。

### 版本差异

不同版本的 \`top\` 命令在输出格式和可用选项上可能有所不同。新版 \`top\` 可能会引入新的功能或改进。请查阅 \`top\` 的手册页（\`man top\`）以获取最新的信息。

### 替代命令

- \`htop\`：一个更友好的 \`top\` 变体，具有图形化界面。
- \`atop\`：提供更详细的系统监控信息。

### 安装

在 Ubuntu 和 RHEL 系统上，\`top\` 通常是系统默认安装的。如果未安装，可以使用以下命令进行安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

### 需要 root 权限的选项

一些 \`top\` 的高级功能（如修改优先级或终止进程）可能需要 root 权限。以下是示例：

\`\`\`bash
sudo top
\`\`\`

**示例输出**:

\`\`\`plaintext
top - 10:22:03 up 1 day,  3:21,  2 users,  load average: 0.25, 0.40, 0.32
Tasks: 162 total,   1 running, 161 sleeping,   0 stopped,   0 zombie
%Cpu(s):  1.0 us,  0.5 sy,  0.0 ni, 98.0 id,  0.3 wa,  0.2 hi,  0.0 si,  0.0 st
KiB Mem :  8101024 total,  2102024 free,  2750348 used,  3242652 buff/cache
KiB Swap:  2047996 total,  2047996 free,        0 used.  5261432 avail Mem 

  PID USER      PR  NI    VIRT    RES    SHR S  %CPU %MEM     TIME+ COMMAND
 1132 sre       20   0  162040  16856  10284 S   1.0  0.2   0:00.05 top
    1 root      20   0  225944  14508   9648 S   0.0  0.2   0:03.05 systemd
    2 root      20   0      0      0      0 S   0.0  0.0   0:00.00 kthreadd
\`\`\`

### 总结

\`top\` 命令是系统监控的重要工具，提供了丰富的实时数据来帮助用户分析和管理系统的资源。通过使用不同的参数和选项，可以根据需求自定义输出并监控系统的性能。

        `,
    };
  },
});
</script>
<style scoped></style>
