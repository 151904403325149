<template>
  <div class="container">
    <h1>404</h1>
    <h2>command not found</h2>
    <p>
      抱歉，您访问的页面不存在。
      <span class="highlight">【运维练兵场】</span>
    </p>
    <p><router-link to="/">首页</router-link></p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.container {
  text-align: center;
  margin-top: 4rem;
}

h1 {
  font-size: 3em;
  margin: 0;
}

h2 {
  font-size: 2em;
  margin: 10px 0;
}

p {
  font-size: 1.2em;
}

a {
  text-decoration: none;
  color: #409eff;
}

.highlight {
  color: orangered;
  font-size: 15px;
}
</style>
