<template>
  <div class="game-score-display">
    <div class="score-container">
      <span>通关积分: </span>
      <span class="score-number">{{ score }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameScoreDisplay",
  props: {
    score: {
      type: [String, Number], // 支持字符串和数字
      required: true,
      default: 0,
    },
  },
};
</script>

<style scoped>
.game-score-display {
  display: inline-block;
}

.score-container {
  padding: 8px 16px; /* 更大一点的内边距，增强可读性 */
  border-radius: 8px;
  background-color: #f3e5f5; /* 柔和的淡紫色 */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08); /* 轻微阴影，增强层次感 */
  display: flex;
  align-items: center;
  font-size: 16px;
}

.score-number {
  font-size: 22px; /* 更大的字体，突出分数 */
  font-weight: bold;
  color: #333;
  margin-left: 8px;
}
</style>
