<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="系统中出现了恶意进程system-care，请停止进程，并删掉二进制文件。（业务未受影响，后续会由主机同事进行新OS加固重装。）"
      source="安全中心（已采样分析）"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdKill",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10069",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
在 Linux 中，可以使用以下命令来结束进程名为 \`system-care\` 的进程：

### 使用 \`kill\` 命令结束进程
1. 首先，使用 \`ps\` 或 \`pgrep\` 命令查找进程的 PID（进程 ID）。
   \`\`\`bash
   ps aux | grep system-care
   \`\`\`
   或者
   \`\`\`bash
   pgrep system-care
   \`\`\`

2. 通过 PID 结束进程：
   \`\`\`bash
   kill <PID>
   \`\`\`
   如果进程无法被正常结束，可以使用 \`-9\` 强制终止：
   \`\`\`bash
   kill -9 <PID>
   \`\`\`

### 使用 \`killall\` 命令结束进程
\`killall\` 命令可以直接通过进程名终止所有匹配的进程：
\`\`\`bash
killall system-care
\`\`\`
如果需要强制终止：
\`\`\`bash
killall -9 system-care
\`\`\`

### 使用 \`pkill\` 命令结束进程
\`pkill\` 命令与 \`killall\` 类似，也可以通过进程名终止进程：
\`\`\`bash
pkill system-care
\`\`\`
强制终止：
\`\`\`bash
pkill -9 system-care
\`\`\`

### 病毒伪装的进程名
病毒或者恶意软件常常会伪装成合法的进程名，以下是一些常见的伪装类型：
1. **类似系统服务的名称**：病毒通常伪装成与系统服务相关的进程名，例如 \`systemd\`, \`svchost\`, \`init\`, \`cron\`, 或者 \`sshd\`，以避免引起注意。
2. **拼写类似的进程名**：例如，\`systemd\` 可以被伪装为 \`sytemd\`（少了一个 "s"），\`cron\` 伪装成 \`cr0n\`（数字 "0" 替代字母 "o"）。
3. **看似无害的名称**：诸如 \`update\`, \`service\`, \`syscare\`, \`security-check\` 等，可能看起来像是正常的系统更新或维护进程。

如果你怀疑某个进程是恶意软件，建议：
- 检查进程的启动路径：使用 \`ps -aux\` 或 \`lsof -p <PID>\` 查看进程的路径或打开的文件。
- 使用工具如 \`top\`, \`htop\`, \`strace\`, 或 \`netstat\` 检查其行为（如是否有异常的网络连接、系统资源占用等）。

### 要找到一个进程的可执行文件的路径，你可以使用以下几种方法：

#### 方法一：使用 ls 命令和 /proc 文件系统
每个进程在 /proc 文件系统中都有一个以其 PID（进程 ID）命名的目录。该目录中的 exe 符号链接指向进程的可执行文件。

示例：
找到进程的 PID。你可以使用 ps、top、htop 或 pgrep 等命令来找到它。
导航到 /proc/<PID>/ 目录，其中 <PID> 是你要查找的进程的 ID。
使用 'ls -l' 命令列出目录内容，并找到 exe 符号链接。
使用 readlink 命令解析 exe 符号链接，以获取可执行文件的完整路径。
\`\`\`bash
PID=<your_process_id>  
readlink -f /proc/$PID/exe
\`\`\`

  `,
      markdownContentExpand: `
\`kill -9 <PID>\` 和 \`kill <PID>\` 之间的主要区别在于信号的强度和行为：

### \`kill <PID>\`（默认发送 \`SIGTERM\` 信号）
- 当你仅使用 \`kill <PID>\`（不指定信号）时，系统会默认发送 \`SIGTERM\`（信号 15）。
- **\`SIGTERM\`** 是一种温和的信号，它请求进程自行终止。进程收到 \`SIGTERM\` 信号后，可以捕捉、处理或忽略这个信号。通常，程序会尝试进行一些清理操作（例如保存状态、关闭文件等）后才会退出。
- 如果进程响应 \`SIGTERM\`，它可以优雅地退出，避免数据丢失或其他不良后果。

### \`kill -9 <PID>\`（发送 \`SIGKILL\` 信号）
- **\`SIGKILL\`** 是信号 9，也称为 **"杀死" 信号**，它会立即终止进程，**强制结束进程**。
- 与 \`SIGTERM\` 不同，\`SIGKILL\` 是无法被捕获、忽略或处理的。进程不会有机会进行任何清理工作或保存状态，系统直接强制终止它。
- 通常，只有在 \`SIGTERM\`（默认 \`kill\`）不生效时，才会使用 \`SIGKILL\` 来强制结束顽固进程。

### 选择使用
- **\`kill <PID>\`（\`SIGTERM\`）**：优先使用它，因为它允许进程优雅地退出，避免数据丢失或不完整的清理。
- **\`kill -9 <PID>\`（\`SIGKILL\`）**：只有当进程无法响应或忽略了 \`SIGTERM\` 时，再使用它进行强制结束。

### 示例
\`\`\`bash
# 优雅结束进程
kill 1234

# 强制结束进程
kill -9 1234
\`\`\`

### 总结
- **\`kill <PID>\`**：尝试温和地结束进程，给予进程处理时间。
- **\`kill -9 <PID>\`**：立即强制终止进程，系统不会等待任何清理或保存工作。

不少公司单位的监控软件中，没有异常进程的监控功能，需要注意。    
  `,
      markdownInterview: ` 
### 选择题

1. **选择题 1**：以下哪个信号是通过 \`kill\` 命令默认发送的？
   - A) SIGKILL
   - B) SIGSTOP
   - C) SIGTERM
   - D) SIGCONT

2. **选择题 2**：使用 \`ps -ef\` 命令时，以下哪个字段表示进程的父进程ID？
   - A) PID
   - B) TTY
   - C) PPID
   - D) CMD

3. **选择题 3**：下面的命令 \`kill -9 <PID>\` 的作用是什么？
   - A) 温和地结束进程
   - B) 强制结束进程
   - C) 暂停进程
   - D) 继续进程

4. **选择题 4**：使用 \`ps\` 命令时，以下哪个选项用于显示所有进程，包括其他用户的进程？
   - A) -u
   - B) -a
   - C) -x
   - D) -e

### 问答题

1. **问答题 1**：请简要说明 \`kill\` 命令的用途及其基本语法。

2. **问答题 2**：\`ps\` 命令的作用是什么？它可以用来获取哪些信息？

3. **问答题 3**：如何使用 \`ps\` 命令查找某个特定用户正在运行的进程？

4. **问答题 4**：请解释 \`kill\` 命令中的 \`-SIGTERM\` 和 \`-SIGKILL\` 的区别。

---

### 答案

#### 选择题答案
1. C) SIGTERM
2. C) PPID
3. B) 强制结束进程
4. D) -e

#### 问答题答案
1. **\`kill\` 命令的用途及基本语法**：
   - \`kill\` 命令用于向指定进程发送信号，以控制其行为。基本语法为：\`kill [选项] <PID>\`，其中 \`<PID>\` 是要控制的进程 ID。

2. **\`ps\` 命令的作用**：
   - \`ps\` 命令用于显示当前正在运行的进程信息，包括进程 ID (PID)、父进程 ID (PPID Parent)、进程状态、占用的 CPU 和内存等信息。

3. **查找特定用户的进程**：
   - 使用命令 \`ps -u <username>\`，其中 \`<username>\` 是要查找的特定用户的用户名。例如：\`ps -u john\` 会显示用户 John 正在运行的所有进程。

4. **\`-SIGTERM\` 和 \`-SIGKILL\` 的区别**：
   - \`-SIGTERM\`（默认信号）是请求进程优雅地终止，它允许进程进行清理操作；而 \`-SIGKILL\` 是强制立即终止进程，进程不会有机会执行任何清理操作。

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
