<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## nohup 命令详解

\`nohup\` 是一个常用于在 Linux 和 Unix 系统中执行长时间运行任务的命令。它的主要功能是让进程在用户退出终端后继续运行，避免因为会话关闭而终止任务。\`nohup\` 的全称是 “no hang up”，即不挂起。

当你执行一个需要长时间运行的任务，且不希望它因关闭终端或断开会话而中止时，\`nohup\` 是非常有用的工具。该命令在后台运行时会自动将输出（标准输出和错误输出）重定向到一个文件，通常是 \`nohup.out\`。

### 安装情况

\`nohup\` 通常是 Unix/Linux 系统自带的命令，不需要额外安装。如果系统中没有该命令，可能需要通过安装基本的 shell 工具包。

#### Ubuntu/RHEL 安装包

在 Ubuntu 和 RHEL 系统中，\`nohup\` 命令属于 \`coreutils\` 包的一部分：

- **Ubuntu**: \`sudo apt-get install coreutils\`
- **RHEL**: \`sudo yum install coreutils\`

### 基本语法

\`\`\`bash
nohup COMMAND [ARG]... [OPTION]...
\`\`\`

- \`COMMAND\`：要执行的命令。
- \`ARG\`：传递给命令的参数。
- \`OPTION\`：对命令的选项设置。

### 使用 nohup 的常见场景

\`nohup\` 的主要作用是在用户退出终端后，继续保持任务的执行状态。以下是一些常见使用场景和参数。

### 示例一：基本用法

假设你要执行一个脚本 \`long_task.sh\`，并希望它在关闭终端后仍能继续运行：

\`\`\`bash
nohup ./long_task.sh &
\`\`\`

**输出示例**：

\`\`\`plaintext
nohup: ignoring input and appending output to 'nohup.out'
\`\`\`

**解读**：该命令成功执行后，所有的标准输出和错误输出会被重定向到 \`nohup.out\` 文件中。文件默认生成在当前工作目录中，如果没有写权限，则生成在用户的 home 目录中。

你可以通过以下命令查看输出文件内容：

\`\`\`bash
cat nohup.out
\`\`\`

### 示例二：重定向输出到指定文件

如果你希望 \`nohup\` 将输出重定向到指定文件而不是默认的 \`nohup.out\` 文件，可以使用重定向符号：

\`\`\`bash
nohup ./long_task.sh > output.log 2>&1 &
\`\`\`

**解读**：此命令将标准输出和标准错误输出都重定向到 \`output.log\` 文件中，避免了 \`nohup.out\` 文件的生成。

### 示例三：结合 sleep 模拟长时间任务

为了更好理解 \`nohup\` 的行为，可以结合 \`sleep\` 命令模拟一个长时间运行的任务：

\`\`\`bash
nohup sleep 300 &
\`\`\`

**输出示例**：

\`\`\`plaintext
nohup: ignoring input and appending output to 'nohup.out'
[1] 12345
\`\`\`

**解读**：命令成功启动后，\`[1]\` 表示该进程的作业号，\`12345\` 是该任务的进程 ID（PID）。\`sleep 300\` 表示该任务将在后台睡眠 300 秒（5 分钟）。即使关闭终端，任务依然会继续运行，输出会记录在 \`nohup.out\` 中。

### 示例四：使用带有参数的命令

假设需要通过 \`nohup\` 启动一个 Python 脚本，并传递参数：

\`\`\`bash
nohup python3 myscript.py arg1 arg2 > output.log 2>&1 &
\`\`\`

**解读**：此命令将执行 \`myscript.py\` 脚本，传递参数 \`arg1\` 和 \`arg2\`，并将输出和错误重定向到 \`output.log\` 文件中。

### 示例五：查看正在运行的任务

运行命令后，你可以使用 \`ps\` 命令查看后台进程是否仍在执行：

\`\`\`bash
ps -ef | grep long_task.sh
\`\`\`

**输出示例**：

\`\`\`plaintext
sre      12345  6789  0 10:22 ?        00:00:00 /bin/bash ./long_task.sh
\`\`\`

**解读**：此输出表示 \`long_task.sh\` 进程仍在运行，\`12345\` 是该任务的进程 ID。

### 示例六：终止后台任务

当你不再需要某个后台任务时，可以通过 \`kill\` 命令结束它。首先，使用 \`ps\` 查找任务的进程 ID，然后终止该任务：

\`\`\`bash
kill 12345
\`\`\`

**输出提示**：

如果命令执行成功，终端无输出。这意味着任务已经被成功终止。你也可以使用 \`kill -9\` 强制终止：

\`\`\`bash
kill -9 12345
\`\`\`

### 版本差异

\`nohup\` 的功能在不同版本的系统之间基本保持一致，输出格式没有显著差异。因此，在 x86_64 和 aarch64 架构的系统上，\`nohup\` 的使用方式和输出结果没有明显的区别。

### 权限需求

\`nohup\` 命令通常不需要 root 权限即可执行。但是，当执行某些需要特权的命令时，可能需要使用 \`sudo\` 来提升权限：

\`\`\`bash
sudo nohup ./privileged_task.sh &
\`\`\`

**解读**：此命令将以 root 用户身份执行 \`privileged_task.sh\`，并且在后台运行，即使用户退出终端，任务也会继续执行。

### 总结

\`nohup\` 是 Linux 系统中常用的工具之一，特别适合执行需要长时间运行的任务，同时确保这些任务在终端关闭后依然能够正常执行。它的使用简单且功能强大，能够重定向输出并在后台运行进程，是 Linux 用户和管理员必不可少的工具。

        `,
    };
  },
});
</script>
<style scoped></style>
