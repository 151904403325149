<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## parted 命令详解

\`parted\` 是一个强大的磁盘分区工具，用于管理 Linux 系统中的硬盘分区。它可以创建、删除、调整、移动和检查分区，还可以更改分区的文件系统类型。与传统的 \`fdisk\` 命令不同，\`parted\` 支持大于 2TB 的硬盘，并且可以管理 GPT 和 MBR 分区表格式。本文将详细介绍 \`parted\` 的常用参数和使用方法。

### 安装 parted

在大多数 Linux 发行版中，\`parted\` 通常不是默认安装的。可以通过以下命令进行安装：

- **Ubuntu**:
    \`\`\`bash
    sudo apt-get install parted
    \`\`\`
  
- **RHEL/CentOS**:
    \`\`\`bash
    sudo yum install parted
    \`\`\`

### 基本用法

\`parted\` 的基本用法是：
\`\`\`bash
sudo parted [设备] [命令]
\`\`\`

其中，\`设备\` 是硬盘设备名称，如 \`/dev/sda\`，而 \`命令\` 是 \`parted\` 支持的特定操作。

### 常用命令及示例

#### 1. 查看磁盘信息

\`\`\`bash
sudo parted /dev/sda print
\`\`\`

**输出示例**：
\`\`\`bash
Model: ATA ST1000DM003 (scsi)
Disk /dev/sda: 1000GB
Sector size (logical/physical): 512B/4096B
Partition Table: gpt
Disk Flags: 

Number  Start   End     Size    File system  Name                  Flags
 1      1049kB  500GB   500GB   ext4         primary               boot
 2      500GB   1000GB  500GB   ext4         secondary
\`\`\`

**解释**：
- \`Model\` 表示硬盘型号。
- \`Disk /dev/sda: 1000GB\` 显示硬盘大小。
- \`Partition Table\` 指明分区表类型（GPT 或 MBR）。
- \`Number\` 列显示分区号，\`Start\` 和 \`End\` 则是分区的起始和结束位置。

#### 2. 创建分区

\`\`\`bash
sudo parted /dev/sda mkpart primary ext4 1MiB 500GiB
\`\`\`

该命令将在 \`/dev/sda\` 上创建一个 \`ext4\` 文件系统的主分区，从 1MiB 开始，到 500GiB 结束。

**输出**：
\`\`\`bash
Warning: The resulting partition is not properly aligned for best performance.
Ignore/Cancel? I
\`\`\`
输入 \`I\` 忽略警告，分区将会创建成功。

**解释**：
- \`mkpart\` 用于创建新分区。
- \`primary\` 表示创建主分区。
- \`ext4\` 指定文件系统类型。
- \`1MiB\` 和 \`500GiB\` 指定分区的起始和结束位置。

#### 3. 删除分区

\`\`\`bash
sudo parted /dev/sda rm 1
\`\`\`

**输出**：  
【无输出，命令已成功执行】

**解释**：
该命令删除 \`/dev/sda\` 上的第 1 个分区。

#### 4. 调整分区大小

调整现有分区大小，可以使用 \`resizepart\` 命令。假设我们将 \`/dev/sda\` 的第一个分区扩展到 700GiB：

\`\`\`bash
sudo parted /dev/sda resizepart 1 700GiB
\`\`\`

**输出**：  
【无输出，命令已成功执行】

**解释**：
- \`resizepart\` 命令用于调整分区大小。
- \`1\` 表示要调整的分区编号。
- \`700GiB\` 是新分区大小。

#### 5. 查看分区表类型

\`\`\`bash
sudo parted /dev/sda print
\`\`\`

这条命令可以再次打印分区信息，其中会显示分区表类型为 \`gpt\` 或 \`msdos\`（MBR）。

#### 6. 更改分区表类型

创建新的磁盘分区表可以使用 \`mklabel\` 命令：

\`\`\`bash
sudo parted /dev/sda mklabel gpt
\`\`\`

**输出**：  
【无输出，命令已成功执行】

**解释**：
- \`mklabel gpt\` 会将磁盘分区表格式设置为 \`GPT\`。
- 如果想使用传统的 \`MBR\` 分区表，则可以使用 \`mklabel msdos\`。

### 高级用法

#### 1. 对齐分区

为了最佳性能，特别是在 SSD 或对齐要求较高的存储设备上，使用 \`align-check\` 来检查分区是否对齐。

\`\`\`bash
sudo parted /dev/sda align-check optimal 1
\`\`\`

**输出**：
\`\`\`bash
1 aligned
\`\`\`

**解释**：
- \`align-check optimal\` 检查第 1 个分区是否与存储块对齐。

#### 2. 设置分区标志

你可以通过 \`set\` 命令来设置分区标志，例如启动标志（boot flag）。

\`\`\`bash
sudo parted /dev/sda set 1 boot on
\`\`\`

**输出**：  
【无输出，命令已成功执行】

**解释**：
- 该命令将 \`/dev/sda\` 上的第 1 个分区标记为启动分区。

### root 权限需求

\`parted\` 通常需要以 \`root\` 权限运行，因为涉及到分区操作。使用 \`sudo\` 运行命令可以避免权限问题。

\`\`\`bash
sudo parted /dev/sda print
\`\`\`

### parted 与不同架构

在不同架构（如 \`aarch64\` 和 \`x86_64\`）下，\`parted\` 的行为和输出通常没有明显差异。它们主要依赖于硬件本身的分区布局及支持的分区表类型。

### 旧版本与新版本的差异

在较新的 \`parted\` 版本中，更多地支持 GPT 分区表，旧版本的 \`parted\` 则可能不支持 GPT 分区表或者对大容量硬盘支持有限。如果你在使用旧版本的 Linux 发行版，建议升级 \`parted\` 以支持更现代的硬盘和分区表格式。

### 总结

\`parted\` 是一个功能丰富的分区管理工具，支持的常见操作包括查看分区表、创建分区、删除分区、调整分区大小等。它相比于 \`fdisk\` 更适合现代大容量硬盘管理，特别是在支持 GPT 分区表的情况下。通过熟练掌握 \`parted\`，系统管理员可以灵活地对硬盘进行管理，尤其是针对大于 2TB 的磁盘。
        `,
    };
  },
});
</script>
<style scoped></style>
