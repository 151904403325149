<template>
  <div>
    <GameCard1
      title="导入镜像到本地"
      actions="通过docker load导入别人分享给我的镜像"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
加载导入别人传输给你的容器镜像，通常使用 \`docker load\` 命令。详细步骤如下：

### 1. **获取容器镜像文件**
当别人传输给你一个 Docker 镜像文件时，通常是一个 \`.tar\` 文件。这是使用 \`docker save\` 命令导出的容器镜像文件，它包含了镜像的所有层、元数据以及配置。

\`\`\`bash
docker save -o my_image.tar <image_name>
\`\`\`

上面的命令将镜像 \`image_name\` 导出到 \`my_image.tar\` 文件中。

### 2. **加载镜像到本地 Docker**
当你收到 \`.tar\` 文件后，可以使用 \`docker load\` 命令将该镜像加载到你的本地 Docker 环境中。

\`\`\`bash
docker load -i my_image.tar
\`\`\`

### **\`docker load\` 的工作原理**
\`docker load\` 是从一个文件中将容器镜像加载到 Docker 的镜像存储库中的过程。其工作机制如下：

- **解包镜像**：\`docker load\` 会读取并解压 \`.tar\` 文件，将它分解为各个镜像层。
- **重建镜像结构**：Docker 镜像由多个层组成，每一层代表一次文件系统的更改（如新文件的添加、删除或修改）。\`docker load\` 会重建这些镜像层，并在本地 Docker 注册表中注册这些层。
- **恢复元数据**：\`docker load\` 也会恢复镜像的相关元数据（如标签、环境变量等），并将其记录在本地。

成功加载后，你可以通过 \`docker images\` 命令查看已经加载的镜像。

### 3. **验证加载成功**
加载成功后，可以通过以下命令来验证镜像是否已成功加载：

\`\`\`bash
docker images
\`\`\`

该命令会列出本地 Docker 存储库中的所有镜像，包括你刚刚通过 \`docker load\` 命令加载的镜像。

### 4. **运行加载的镜像**
加载镜像后，可以使用该镜像启动容器，注意参数：

\`\`\`bash
docker run -it <image_name>
\`\`\`

### **\`docker load\` 和 \`docker import\` 的区别**

\`docker load\` 与 \`docker import\` 都可以从外部加载内容到 Docker 中，但它们有以下区别：

1. **\`docker load\`：**
   - 用于加载由 \`docker save\` 创建的镜像文件。
   - 它会恢复完整的 Docker 镜像，包括所有的层、元数据和历史记录。
   - 常用于团队之间传输完整的镜像。

   示例：
   \`\`\`bash
   docker save -o my_image.tar my_image
   docker load -i my_image.tar
   \`\`\`

2. **\`docker import\`：**
   - 用于导入一个文件系统快照作为新的镜像。
   - 与 \`docker load\` 不同的是，\`docker import\` 会忽略镜像的层和元数据，只保留文件系统内容。
   - 通常用于从非 Docker 环境导入容器内容。

   示例：
   \`\`\`bash
   cat ubuntu.tar | docker import - my_ubuntu
   \`\`\`

   这种方法更灵活，但丢失了历史记录和层信息。

### **Docker 镜像加载的完整流程**
当你加载镜像时，Docker 引擎会执行以下操作：
1. **读取 \`.tar\` 文件**：Docker 首先读取镜像文件，将其解压为镜像层。
2. **解析元数据**：Docker 解析镜像的相关元数据，如镜像名、标签、命令、环境变量等。
3. **镜像层解压**：每个镜像层都会解压并存储到 Docker 的存储层。Docker 使用的是联合文件系统，如 AUFS、OverlayFS、btrfs 等，这些文件系统支持分层存储。
4. **本地注册镜像**：解压后的镜像层会在本地注册，并可通过 \`docker images\` 命令查看。

### **常见错误及解决方法**
- **错误：\`Error processing tar file(exit status 1): unexpected EOF\`**
  - 可能是 \`.tar\` 文件传输或存储过程中损坏，尝试重新传输或重新生成文件。
  
- **错误：\`Cannot connect to the Docker daemon\`**
  - 表示 Docker 守护进程没有运行，可以通过 \`sudo systemctl start docker\` 来启动 Docker 守护进程。

### 总结
1. **\`docker save\` / \`docker load\`** 用于完整的镜像传输与加载。
2. **\`docker import\`** 用于从文件系统快照创建新镜像，但会丢失层信息和历史记录。
3. \`docker load\` 适合团队协作时传输完整的镜像文件，它保留了所有的元数据和层级结构。        
        `,
      interviewContent: `
### 选择题

1. **\`docker load\` 命令的主要作用是什么？**
   - A. 启动 Docker 容器
   - B. 从 tar 文件导入镜像
   - C. 删除 Docker 镜像
   - D. 更新 Docker 版本

2. **使用 \`docker load\` 时，输入的 tar 文件必须包含什么内容？**
   - A. Dockerfile
   - B. 镜像的层文件和元数据
   - C. 容器运行时的配置
   - D. 只有镜像的历史记录

3. **执行 \`docker load\` 时，如果加载的镜像已经存在，系统会如何处理？**
   - A. 覆盖已有镜像
   - B. 报错并停止操作
   - C. 自动重命名镜像
   - D. 忽略该镜像

4. **以下哪个命令可以将镜像导出为 tar 文件，以便使用 \`docker load\` 导入？**
   - A. \`docker save\`
   - B. \`docker export\`
   - C. \`docker pull\`
   - D. \`docker commit\`

5. **\`docker load\` 命令的使用场景主要是：**
   - A. 从公共 Docker Hub 下载镜像
   - B. 从本地 tar 文件导入镜像
   - C. 删除不需要的镜像
   - D. 更新正在运行的容器

### 问答题

1. **简述 \`docker load\` 命令的使用方法及其主要参数。**

2. **在使用 \`docker load\` 导入镜像时，可能会遇到哪些常见错误？请列举两到三个并简要说明原因。**

3. **比较 \`docker load\` 和 \`docker import\` 的区别和使用场景。**

4. **如何将一个正在运行的容器的镜像保存为 tar 文件，以便使用 \`docker load\` 命令进行加载？请给出命令示例。**

5. **如果你在 CI/CD 流程中使用 \`docker load\`，你认为应注意哪些事项？**

---

### 答案

1. **答案：B**  
   \`docker load\` 命令的主要作用是从 tar 文件导入镜像。

2. **答案：B**  
   输入的 tar 文件必须包含镜像的层文件和元数据。

3. **答案：A**  
   执行 \`docker load\` 时，如果加载的镜像已经存在，系统会覆盖已有镜像。

4. **答案：A**  
   使用 \`docker save\` 命令可以将镜像导出为 tar 文件。

5. **答案：B**  
   \`docker load\` 命令的使用场景主要是从本地 tar 文件导入镜像。

---

### 问答题答案

1. **\`docker load\` 命令的使用方法：**
   - 使用命令 \`docker load < filename.tar\` 或 \`cat filename.tar | docker load\` 来加载镜像。
   - 主要参数：无（但可以使用标准输入读取）。

2. **常见错误：**
   - **文件不存在**：指定的 tar 文件不存在或路径错误。
   - **不正确的格式**：tar 文件不符合 Docker 镜像格式。

3. **区别与使用场景：**
   - \`docker load\` 用于加载通过 \`docker save\` 导出的镜像，保留镜像层和元数据。
   - \`docker import\` 用于从 tar 文件生成新的镜像，主要用于文件系统快照，丢弃历史信息。

4. **命令示例：**
   \`\`\`bash
   docker commit <container_id> my_image
   docker save my_image > my_image.tar
   \`\`\`

5. **注意事项：**
   - 确保 CI/CD 环境中有适当的权限访问 tar 文件。
   - 考虑到镜像的安全性和来源，确保 tar 文件是可信的。
      
      `,
    };
  },
});
</script>
<style scoped></style>
