<template>
  <div class="failure-container">
    <div class="message">
      <h1>通关失败</h1>
      <p>很遗憾，您未通过任务检查，请重试。<span class="sad-face">😢</span></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.failure-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  height: 240px;
  background: linear-gradient(135deg, #ff9a9e 0%, #fecfef 100%);
  text-align: center;
  border-radius: 1rem;
}

.message {
  background: #fffcff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  position: relative;
}

h1 {
  color: #e74c3c;
  font-size: 36px;
  margin-bottom: 20px;
  animation: slideInFromTop 1s ease-in-out;
}

p {
  font-size: 16px;
  color: #333333;
  margin-bottom: 1rem;
  animation: slideInFromBottom 1s ease-in-out;
}

.sad-face {
  font-size: 1rem;
  /* animation: bounce 2s infinite; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
