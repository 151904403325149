<template>
  <div>
    <GameCard1
      title="安装Docker运行环境"
      actions="打开新的浏览器窗口，<br>连接【在线环境】，并安装Docker运行环境。"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: ` 
## 安装Docker运行环境: Ubuntu 和 Rocky Linux

## 一、在 Ubuntu 上安装 Docker

Ubuntu 是一种基于 Debian 的操作系统，非常适合运行 Docker。以下是最简单的安装步骤。

### 1. 更新系统包
在开始之前，确保系统是最新的。执行以下命令来更新系统软件包：
\`\`\`bash
sudo apt update
\`\`\`

### 2. 安装 Docker
更新系统包后，执行以下命令来安装 Docker：
\`\`\`bash
sudo apt install docker.io
\`\`\`

### 3. 启动并验证 Docker
安装完成后，启动 Docker 并验证是否成功运行，ubuntu系统默认安装后，就已经启动了，所以以下步骤无需执行。
\`\`\`bash
sudo systemctl is-active docker
sudo systemctl start docker
sudo systemctl enable docker
\`\`\`

验证 Docker 版本：
\`\`\`bash
docker --version
\`\`\`

## 二、在 Rocky Linux 上安装 Podman

Rocky Linux 是一个基于 Red Hat Enterprise Linux (RHEL) 的开源发行版，同样可以非常轻松地安装 Podman。

### 1. 更新系统包
首先，确保你的 Rocky Linux 系统是最新的：
\`\`\`bash
sudo dnf makecache
sudo dnf update
\`\`\`

### 2. 安装 Docker/Podman
使用以下命令安装 Docker，可能安装到的是Docker的替代品Podman：
\`\`\`bash
sudo dnf install docker
\`\`\`

### 3. 启动并验证 Docker/Podman
安装完成后，启动 Docker/Podman 并验证：
\`\`\`bash
sudo systemctl start podman
sudo systemctl enable podman
\`\`\`

验证 Docker 版本：
\`\`\`bash
docker version
\`\`\`

## 三、设置用户权限（可选）
默认情况下，使用 Docker 需要 \`sudo\` 权限。如果你希望普通用户也可以运行 Docker 命令，可以将用户添加到 \`docker\` 组：
\`\`\`bash
sudo usermod -aG docker $USER
\`\`\`
完成后，重新登录系统即可生效，无需重启。

## 四、总结

Docker 的安装在 Ubuntu 和 Rocky Linux 上都是非常简洁的，本文介绍的步骤是最常见且最简单的方式。  
通过使用 Docker，你可以快速上手容器技术，开始部署和管理你的应用程序。


成功安装 Docker 后，运行 \`hello-world\` 测试容器，验证拉取镜像是否成功。

在 Docker 的安装过程中，通常会创建以下几种账户和权限设置，具体取决于你所使用的操作系统和 Docker 的安装方式：

### 1. Docker Daemon 用户

- **用户组**：在大多数 Linux 发行版上，Docker 安装过程中会创建一个名为 \`docker\` 的用户组。
- **作用**：将用户添加到 \`docker\` 组中可以让该用户在不使用 \`sudo\` 的情况下运行 Docker 命令。这样，普通用户可以直接使用 Docker，而无需每次都以管理员身份运行命令。

  \`\`\`bash
  # 将当前用户添加到 docker 组
  sudo usermod -aG docker $USER
  \`\`\`

  添加后，用户需要注销并重新登录以使更改生效。

### 2. 服务账户

- **服务用户**：Docker Daemon 通常以 root 用户身份运行。这是因为 Docker 需要访问系统级资源，如网络和文件系统。
- **作用**：以 root 用户身份运行 Docker Daemon 使其能够管理容器的网络、存储和文件权限等。

### 3. 容器中的用户

- **默认用户**：在使用 Docker 创建和运行容器时，通常会使用容器镜像中预定义的用户。很多官方镜像默认使用 root 用户运行。
- **作用**：容器内的用户权限控制，可以通过 Dockerfile 中的 \`USER\` 指令设置要使用的用户。

### 4. 安全和访问控制

- **Linux 命名空间和控制组（cgroups）**：Docker 使用 Linux 的命名空间和控制组来提供容器的隔离性和资源限制，而不需要额外创建特定账户。
- **Docker 访问控制**：在 Docker 的设置中，尤其是在使用 Docker Swarm 或 Kubernetes 之类的编排工具时，可以通过角色基础的访问控制（RBAC）进行更精细的权限管理，但这通常是在企业环境中使用。

### 总结

在 Docker 安装过程中，主要会创建 \`docker\` 用户组以及运行 Docker Daemon 的 root 用户账户。普通用户可以通过加入 \`docker\` 组来获得使用 Docker 的权限，而容器内的用户则取决于所使用的镜像配置。Docker 的安全机制也依赖于 Linux 系统本身的权限管理功能。

`,
      interviewContent: `
## 一、选择题

**1. 在 Ubuntu 系统上安装 Docker 时，以下哪一项命令是用于添加 Docker 官方 GPG 密钥？**
- A. \`sudo apt install docker\`
- B. \`curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -\`
- C. \`sudo apt-get update\`
- D. \`sudo docker pull ubuntu\`

**2. 在 Rocky Linux 中，如何添加 Docker 仓库？**
- A. \`sudo dnf config-manager --add-repo https://download.docker.com/linux/centos/docker-ce.repo\`
- B. \`sudo yum-config-manager --add-repo https://docker.com/install\`
- C. \`sudo yum install docker\`
- D. \`sudo docker-compose up\`

**3. 安装 Docker 之后，如何验证 Docker 是否成功安装并运行？**
- A. \`docker start\`
- B. \`docker run hello-world\`
- C. \`docker test\`
- D. \`docker ps\`

**4. 在 Ubuntu 上安装 Docker 需要首先安装什么依赖包？**
- A. \`dnf\`
- B. \`apt-key\`
- C. \`apt-transport-https ca-certificates curl\`
- D. \`git\`

**5. 使用 \`docker --version\` 命令可以：**
- A. 显示当前 Docker 运行中的容器
- B. 显示当前 Docker 镜像的版本
- C. 显示当前 Docker 的安装版本
- D. 检查 Docker 是否已经启动

## 二、问答题

**1. Docker 安装过程中，为什么需要添加 GPG 密钥？**

**2. 如何在 Ubuntu 和 Rocky Linux 系统上分别启动 Docker 并设置开机自启动？**

**3. 描述从 Docker 官方仓库拉取镜像的命令及其作用？**

**4. 如何将普通用户添加到 \`docker\` 用户组，使其不使用 \`sudo\` 也能执行 Docker 命令？**

**5. 在 Rocky Linux 上，如何卸载已安装的 Docker 旧版本？**

---

## 答案

### 一、选择题

**1. 在 Ubuntu 系统上安装 Docker 时，以下哪一项命令是用于添加 Docker 官方 GPG 密钥？**  
- **答案**：B. \`curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -\`  
  这是用于确保安装的 Docker 软件包来自可信的来源。

**2. 在 Rocky Linux 中，如何添加 Docker 仓库？**  
- **答案**：A. \`sudo dnf config-manager --add-repo https://download.docker.com/linux/centos/docker-ce.repo\`  
  这是在 Rocky Linux（基于 CentOS）上添加 Docker 仓库的正确命令。

**3. 安装 Docker 之后，如何验证 Docker 是否成功安装并运行？**  
- **答案**：B. \`docker run hello-world\`  
  运行 \`hello-world\` 容器可以验证 Docker 的安装是否成功。

**4. 在 Ubuntu 上安装 Docker 需要首先安装什么依赖包？**  
- **答案**：C. \`apt-transport-https ca-certificates curl\`  
  这些是 Docker 安装所需要的依赖包。

**5. 使用 \`docker --version\` 命令可以：**  
- **答案**：C. 显示当前 Docker 的安装版本  
  这个命令会输出 Docker 安装的版本信息，帮助确认当前的 Docker 版本。

### 二、问答题

**1. Docker 安装过程中，为什么需要添加 GPG 密钥？**  
**答案**：GPG 密钥的作用是确保 Docker 软件包来自可信的来源。通过验证 GPG 密钥，系统可以确认从 Docker 官方仓库下载的软件包没有被篡改，保证安全性。

**2. 如何在 Ubuntu 和 Rocky Linux 系统上分别启动 Docker 并设置开机自启动？**  
**答案**：  
- 在 Ubuntu 上：
  \`\`\`bash
  sudo systemctl start docker
  sudo systemctl enable docker
  \`\`\`
- 在 Rocky Linux 上：
  \`\`\`bash
  sudo systemctl start docker
  sudo systemctl enable docker
  \`\`\`

两者的命令完全相同，都使用 \`systemctl\` 来启动和配置 Docker 开机自启动。

**3. 描述从 Docker 官方仓库拉取镜像的命令及其作用？**  
**答案**：命令 \`docker pull <image>\` 用于从 Docker 官方仓库（或自定义仓库）拉取指定的镜像到本地。例如，\`docker pull ubuntu\` 会拉取 Ubuntu 官方镜像。通过拉取镜像，可以在本地创建基于该镜像的容器。

**4. 如何将普通用户添加到 \`docker\` 用户组，使其不使用 \`sudo\` 也能执行 Docker 命令？**  
**答案**：
\`\`\`bash
sudo usermod -aG docker $USER
\`\`\`
执行此命令后，用户需要重新登录，或者使用 \`newgrp docker\` 使其立即生效。这使得该用户无需每次都使用 \`sudo\` 即可执行 Docker 命令。

**5. 在 Rocky Linux 上，如何卸载已安装的 Docker 旧版本？**  
**答案**：
\`\`\`bash
sudo dnf remove docker docker-client docker-client-latest docker-common docker-latest docker-latest-logrotate docker-logrotate docker-engine
\`\`\`
此命令将卸载所有与旧版本 Docker 相关的软件包，确保没有冲突的软件版本。

`,
    };
  },
});
</script>
<style scoped></style>
