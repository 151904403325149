<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## visudo 命令详解

\`visudo\` 是一个用于编辑 \`/etc/sudoers\` 文件的专用命令。\`/etc/sudoers\` 文件定义了哪些用户具有使用 \`sudo\` 命令的权限，以及可以执行哪些命令。与普通的文本编辑器不同，\`visudo\` 提供了对文件的语法检查，以防止语法错误导致系统权限设置的错误。因此，建议在修改 \`sudoers\` 文件时，始终使用 \`visudo\` 进行编辑。

### 安装

\`visudo\` 通常是 \`sudo\` 工具的一部分，默认安装在大多数 Linux 系统中。如果需要安装，可以使用以下命令：

#### Ubuntu/Debian 系列
\`\`\`bash
sudo apt install sudo
\`\`\`

#### RHEL/CentOS 系列
\`\`\`bash
sudo yum install sudo
\`\`\`

### 基本语法

\`\`\`bash
sudo visudo [options]
\`\`\`

#### 使用示例

在默认情况下，直接运行 \`visudo\` 会打开 \`/etc/sudoers\` 文件进行编辑：

\`\`\`bash
sudo visudo
\`\`\`

**输出示例**：

【无输出，命令已成功执行】

**解读**：此命令使用系统的默认文本编辑器（通常是 \`vi\` 或 \`nano\`）打开 \`/etc/sudoers\` 文件。如果你成功保存并退出，没有语法错误，系统不会有任何反馈。

### 示例1：添加用户权限

假设我们需要为用户 \`sre\` 添加使用 \`sudo\` 执行所有命令的权限，可以在 \`sudoers\` 文件中添加以下行：

\`\`\`bash
sre ALL=(ALL:ALL) ALL
\`\`\`

**步骤**：
1. 运行 \`sudo visudo\` 命令。
2. 找到合适的位置（通常在文件末尾），添加上述配置。
3. 保存并退出。

**解读**：这条规则表示用户 \`sre\` 可以在任何主机上，以任何用户身份执行所有命令。

### 示例2：为特定命令设置权限

如果你只希望 \`sre\` 用户能够执行某些特定的命令，例如 \`/usr/bin/systemctl\`，可以这样配置：

\`\`\`bash
sre ALL=(ALL) /usr/bin/systemctl
\`\`\`

**解读**：此配置允许 \`sre\` 用户使用 \`sudo\` 执行 \`systemctl\` 命令，但其他命令将被拒绝。

### visudo的主要选项

\`visudo\` 提供了一些实用的选项来增强其功能：

#### \`-c\` 选项：检查 sudoers 文件语法

在不编辑文件的情况下，可以使用 \`-c\` 选项检查 \`/etc/sudoers\` 文件的语法是否正确：

\`\`\`bash
sudo visudo -c
\`\`\`

**输出示例**：

\`\`\`bash
/etc/sudoers: parsed OK
\`\`\`

**解读**：如果输出 "parsed OK"，表示 \`sudoers\` 文件语法正确。如果有语法错误，系统会给出相应的提示，方便修复错误。

#### \`-f\` 选项：指定其他 sudoers 文件

默认情况下，\`visudo\` 编辑 \`/etc/sudoers\` 文件。如果你需要编辑另一个 \`sudoers\` 文件，可以使用 \`-f\` 选项指定路径。例如，编辑 \`/etc/sudoers.d/custom_sudoers\` 文件：

\`\`\`bash
sudo visudo -f /etc/sudoers.d/custom_sudoers
\`\`\`

**输出示例**：

【无输出，命令已成功执行】

**解读**：此命令将打开 \`/etc/sudoers.d/custom_sudoers\` 文件进行编辑。该文件可以用于分开管理不同用户的 sudo 权限，推荐使用此方法避免直接修改主 sudoers 文件。

### 检查配置文件的正确性

\`visudo\` 的核心功能是确保 \`/etc/sudoers\` 文件的语法正确，以避免用户因配置错误无法获取必要的权限。任何手动编辑文件的行为都有可能导致系统无法正常操作，因此通过 \`visudo\` 进行修改是安全的。

#### 示例：错误的 sudoers 文件

如果直接使用文本编辑器（如 \`nano\` 或 \`vi\`）编辑 sudoers 文件，可能会导致语法错误，例如忘记冒号 \`:\`：

\`\`\`bash
sre ALL=(ALL ALL) ALL  # 错误示例
\`\`\`

在使用 \`visudo\` 编辑时，这种错误会被自动检测，并提示修复：

\`\`\`bash
sudo visudo -c
\`\`\`

**输出示例**：

\`\`\`bash
/etc/sudoers: line 15: syntax error near unexpected token \`('
\`\`\`

**解读**：\`visudo\` 命令自动检查语法，并在错误时给出具体行号和错误原因。

### Bash 内置命令与外部命令

\`visudo\` 是外部命令，而非 Bash 内置命令。可以通过 \`type\` 命令确认：

\`\`\`bash
type visudo
\`\`\`

**输出示例**：

\`\`\`bash
visudo is /usr/sbin/visudo
\`\`\`

**解读**：\`visudo\` 是系统提供的外部工具，路径为 \`/usr/sbin/visudo\`。

### 不同架构下的输出

\`visudo\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为没有差异，因此无需单独列出不同架构下的输出。

### 版本差异

\`visudo\` 的不同版本之间功能上差异不大。通常随着 \`sudo\` 工具版本的更新，\`visudo\` 的功能也会一同升级。可以使用以下命令查看当前系统上安装的 \`visudo\` 版本：

\`\`\`bash
sudo --version
\`\`\`

**输出示例**：

\`\`\`bash
Sudo version 1.8.31
\`\`\`

**解读**：系统中安装的是 \`sudo\` 工具的 1.8.31 版。

### 使用root权限的选项

\`visudo\` 命令需要 \`root\` 权限进行操作，因为它涉及到对系统权限配置文件的修改。通常情况下，使用 \`sudo visudo\` 命令即可满足需求。

\`\`\`bash
sudo visudo
\`\`\`

**解读**：此命令需要管理员权限，因此必须使用 \`sudo\` 进行操作。

### 替代命令

没有直接的替代命令能够完全替代 \`visudo\`，因为它的主要功能是安全地编辑和检查 \`sudoers\` 文件。直接使用文本编辑器（如 \`nano\` 或 \`vi\`）修改 \`sudoers\` 文件可能会引发系统不可用的风险，因此不推荐。

如果确实需要在非交互式环境中批量修改 \`sudoers\` 文件，可以使用自动化工具（如 \`sed\` 或 \`ansible\`）结合语法检查命令来确保文件安全性。

### 总结

\`visudo\` 是修改和管理 Linux 系统中 \`/etc/sudoers\` 文件的最佳工具，能够有效防止因语法错误导致系统权限配置出现问题。通过 \`visudo\`，管理员可以安全、可靠地授予用户权限并定义允许执行的命令。

- \`-c\` 选项用于检查 \`sudoers\` 文件的语法。
- \`-f\` 选项可以编辑指定的 \`sudoers\` 文件。
- 通过 \`visudo\` 的自动语法检查功能，可以确保文件不会因人为错误而造成系统崩溃。

在进行系统管理时，使用 \`visudo\` 修改 sudo 配置是推荐的安全做法。
## visudo命令详解

\`visudo\` 是一个用于编辑 \`/etc/sudoers\` 文件的专用命令。\`/etc/sudoers\` 文件定义了哪些用户具有使用 \`sudo\` 命令的权限，以及可以执行哪些命令。与普通的文本编辑器不同，\`visudo\` 提供了对文件的语法检查，以防止语法错误导致系统权限设置的错误。因此，建议在修改 \`sudoers\` 文件时，始终使用 \`visudo\` 进行编辑。

### 安装

\`visudo\` 通常是 \`sudo\` 工具的一部分，默认安装在大多数 Linux 系统中。如果需要安装，可以使用以下命令：

#### Ubuntu/Debian 系列
\`\`\`bash
sudo apt install sudo
\`\`\`

#### RHEL/CentOS 系列
\`\`\`bash
sudo yum install sudo
\`\`\`

### 基本语法

\`\`\`bash
sudo visudo [options]
\`\`\`

#### 使用示例

在默认情况下，直接运行 \`visudo\` 会打开 \`/etc/sudoers\` 文件进行编辑：

\`\`\`bash
sudo visudo
\`\`\`

**输出示例**：

【无输出，命令已成功执行】

**解读**：此命令使用系统的默认文本编辑器（通常是 \`vi\` 或 \`nano\`）打开 \`/etc/sudoers\` 文件。如果你成功保存并退出，没有语法错误，系统不会有任何反馈。

### 示例1：添加用户权限

假设我们需要为用户 \`sre\` 添加使用 \`sudo\` 执行所有命令的权限，可以在 \`sudoers\` 文件中添加以下行：

\`\`\`bash
sre ALL=(ALL:ALL) ALL
\`\`\`

**步骤**：
1. 运行 \`sudo visudo\` 命令。
2. 找到合适的位置（通常在文件末尾），添加上述配置。
3. 保存并退出。

**解读**：这条规则表示用户 \`sre\` 可以在任何主机上，以任何用户身份执行所有命令。

### 示例2：为特定命令设置权限

如果你只希望 \`sre\` 用户能够执行某些特定的命令，例如 \`/usr/bin/systemctl\`，可以这样配置：

\`\`\`bash
sre ALL=(ALL) /usr/bin/systemctl
\`\`\`

**解读**：此配置允许 \`sre\` 用户使用 \`sudo\` 执行 \`systemctl\` 命令，但其他命令将被拒绝。

### visudo的主要选项

\`visudo\` 提供了一些实用的选项来增强其功能：

#### \`-c\` 选项：检查 sudoers 文件语法

在不编辑文件的情况下，可以使用 \`-c\` 选项检查 \`/etc/sudoers\` 文件的语法是否正确：

\`\`\`bash
sudo visudo -c
\`\`\`

**输出示例**：

\`\`\`bash
/etc/sudoers: parsed OK
\`\`\`

**解读**：如果输出 "parsed OK"，表示 \`sudoers\` 文件语法正确。如果有语法错误，系统会给出相应的提示，方便修复错误。

#### \`-f\` 选项：指定其他 sudoers 文件

默认情况下，\`visudo\` 编辑 \`/etc/sudoers\` 文件。如果你需要编辑另一个 \`sudoers\` 文件，可以使用 \`-f\` 选项指定路径。例如，编辑 \`/etc/sudoers.d/custom_sudoers\` 文件：

\`\`\`bash
sudo visudo -f /etc/sudoers.d/custom_sudoers
\`\`\`

**输出示例**：

【无输出，命令已成功执行】

**解读**：此命令将打开 \`/etc/sudoers.d/custom_sudoers\` 文件进行编辑。该文件可以用于分开管理不同用户的 sudo 权限，推荐使用此方法避免直接修改主 sudoers 文件。

### 检查配置文件的正确性

\`visudo\` 的核心功能是确保 \`/etc/sudoers\` 文件的语法正确，以避免用户因配置错误无法获取必要的权限。任何手动编辑文件的行为都有可能导致系统无法正常操作，因此通过 \`visudo\` 进行修改是安全的。

#### 示例：错误的 sudoers 文件

如果直接使用文本编辑器（如 \`nano\` 或 \`vi\`）编辑 sudoers 文件，可能会导致语法错误，例如忘记冒号 \`:\`：

\`\`\`bash
sre ALL=(ALL ALL) ALL  # 错误示例
\`\`\`

在使用 \`visudo\` 编辑时，这种错误会被自动检测，并提示修复：

\`\`\`bash
sudo visudo -c
\`\`\`

**输出示例**：

\`\`\`bash
/etc/sudoers: line 15: syntax error near unexpected token \`('
\`\`\`

**解读**：\`visudo\` 命令自动检查语法，并在错误时给出具体行号和错误原因。

### Bash 内置命令与外部命令

\`visudo\` 是外部命令，而非 Bash 内置命令。可以通过 \`type\` 命令确认：

\`\`\`bash
type visudo
\`\`\`

**输出示例**：

\`\`\`bash
visudo is /usr/sbin/visudo
\`\`\`

**解读**：\`visudo\` 是系统提供的外部工具，路径为 \`/usr/sbin/visudo\`。

### 不同架构下的输出

\`visudo\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为没有差异，因此无需单独列出不同架构下的输出。

### 版本差异

\`visudo\` 的不同版本之间功能上差异不大。通常随着 \`sudo\` 工具版本的更新，\`visudo\` 的功能也会一同升级。可以使用以下命令查看当前系统上安装的 \`visudo\` 版本：

\`\`\`bash
sudo --version
\`\`\`

**输出示例**：

\`\`\`bash
Sudo version 1.8.31
\`\`\`

**解读**：系统中安装的是 \`sudo\` 工具的 1.8.31 版。

### 使用root权限的选项

\`visudo\` 命令需要 \`root\` 权限进行操作，因为它涉及到对系统权限配置文件的修改。通常情况下，使用 \`sudo visudo\` 命令即可满足需求。

\`\`\`bash
sudo visudo
\`\`\`

**解读**：此命令需要管理员权限，因此必须使用 \`sudo\` 进行操作。

### 替代命令

没有直接的替代命令能够完全替代 \`visudo\`，因为它的主要功能是安全地编辑和检查 \`sudoers\` 文件。直接使用文本编辑器（如 \`nano\` 或 \`vi\`）修改 \`sudoers\` 文件可能会引发系统不可用的风险，因此不推荐。

如果确实需要在非交互式环境中批量修改 \`sudoers\` 文件，可以使用自动化工具（如 \`sed\` 或 \`ansible\`）结合语法检查命令来确保文件安全性。

### 总结

\`visudo\` 是修改和管理 Linux 系统中 \`/etc/sudoers\` 文件的最佳工具，能够有效防止因语法错误导致系统权限配置出现问题。通过 \`visudo\`，管理员可以安全、可靠地授予用户权限并定义允许执行的命令。

- \`-c\` 选项用于检查 \`sudoers\` 文件的语法。
- \`-f\` 选项可以编辑指定的 \`sudoers\` 文件。
- 通过 \`visudo\` 的自动语法检查功能，可以确保文件不会因人为错误而造成系统崩溃。

在进行系统管理时，使用 \`visudo\` 修改 sudo 配置是推荐的安全做法。

        `,
    };
  },
});
</script>
<style scoped></style>
