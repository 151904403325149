<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lshw 命令详解

\`lshw\`（List Hardware）是一个用于列出系统硬件配置的命令行工具。它能够显示详细的硬件信息，包括内存、CPU、主板、硬盘、网络设备等，有助于系统管理和故障排查。

### 安装

在大多数 Linux 发行版中，\`lshw\` 通常需要手动安装。可以使用以下命令进行安装：

#### Ubuntu

\`\`\`bash
sudo apt-get install lshw
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install lshw
\`\`\`

### 基本语法

\`\`\`bash
lshw [选项]
\`\`\`

### 常用选项

- \`-short\`：以简短的格式显示硬件信息。
- \`-json\`：以 JSON 格式输出硬件信息。
- \`-html\`：生成 HTML 格式的输出。
- \`-class <类>\`：仅显示指定类的硬件信息（如 CPU、内存、网络等）。
- \`-sanitize\`：输出时隐藏敏感信息（如序列号）。

### 示例用法

#### 示例一：查看系统硬件信息

\`\`\`bash
lshw
\`\`\`

**输出示例**：

\`\`\`plaintext
hardwae
  *-cpu
       description: CPU
       product: Intel(R) Core(TM) i7-8550U CPU @ 1.80GHz
       vendor: Intel Corp.
       physical id: 0
       bus info: cpu@0
       size: 1999MHz
       capacity: 4000MHz
       width: 64 bits
\`\`\`

**解读**：该命令列出了系统的所有硬件信息，包括 CPU、内存、硬盘等。每个硬件组件都有详细的信息，包括型号、制造商和性能参数。

#### 示例二：以简短格式查看硬件信息

\`\`\`bash
lshw -short
\`\`\`

**输出示例**：

\`\`\`plaintext
H/W path       Device      Class          Description
===================================================
                                      
/0                         bus            Motherboard
/0/0                       memory         8GiB System Memory
/0/0/0                     processor      Intel(R) Core(TM) i7
/0/0/1                     memory         512GiB SSD
\`\`\`

**解读**：此命令以简短格式显示系统硬件信息，易于快速浏览。

#### 示例三：查看指定硬件类别

\`\`\`bash
lshw -class memory
\`\`\`

**输出示例**：

\`\`\`plaintext
  *-memory
       description: System Memory
       physical id: 0
       size: 8GiB
  *-bank:0
       description: SODIMM DDR4 Synchronous 2400 MT/s (0.4 ns)
       product: M471A1G43MB1
       vendor: Samsung
       physical id: 0
       serial: 12345678
       slot: ChannelA-DIMM0
       size: 8GiB
\`\`\`

**解读**：该命令仅显示内存相关的硬件信息，包括描述、产品、制造商和物理 ID。

#### 示例四：以 JSON 格式查看硬件信息

\`\`\`bash
lshw -json
\`\`\`

**输出示例**：

\`\`\`json
{
   "class": "system",
   "description": "Computer",
   "product": "System Product Name",
   "vendor": "System Vendor",
   ...
}
\`\`\`

**解读**：此命令以 JSON 格式输出，方便进行程序处理和分析。

### 权限要求

某些 \`lshw\` 命令操作可能需要 \`root\` 权限，以便能够获取完整的硬件信息。使用 \`sudo\` 来执行命令：

\`\`\`bash
sudo lshw
\`\`\`

**示例输出**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上，\`lshw\` 命令的输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

不同版本的 \`lshw\` 可能在功能和输出格式上存在差异。可以通过以下命令检查版本：

\`\`\`bash
lshw -version
\`\`\`

**输出示例**：

\`\`\`plaintext
lshw - version 02.18
\`\`\`

### 总结

\`lshw\` 是一个功能强大的硬件信息查询工具，能够帮助系统管理员和用户快速获取详细的硬件信息，从而进行故障排查和系统管理。掌握 \`lshw\` 的使用可以有效提升系统管理的效率。

        `,
    };
  },
});
</script>
<style scoped></style>
