<template>
  <div>
    <GameCard1
      title="容器保存为镜像"
      actions="docker commit 可快速将容器状态保存为镜像。"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
\`docker commit\` 是一个用于将当前容器的文件系统更改保存为新的镜像的命令。这通常用于在容器中进行了修改（如安装软件、修改配置文件等）后，将这些更改打包成一个新的镜像，以便于未来的使用。

#### 2. **基本语法**

\`\`\`bash
docker commit [OPTIONS] CONTAINER [REPOSITORY[:TAG]]
\`\`\`

- **CONTAINER**: 要提交的容器的 ID 或名称。
- **REPOSITORY**: 新镜像的名称。
- **TAG**: （可选）新镜像的标签，默认为 \`latest\`。

#### 3. **常用选项**

- **\`-a\`, \`--author\`**: 指定镜像的作者信息。
- **\`-m\`, \`--message\`**: 提交的说明信息。
- **\`--change\`**: 修改 Dockerfile 中的指令（如 \`CMD\`、\`ENTRYPOINT\`、\`ENV\` 等）。

#### 4. **示例用法**

假设我们有一个运行中的 Ubuntu 容器，并且我们在该容器中安装了 Nginx。以下是如何使用 \`docker commit\` 将这个容器的状态保存为新的镜像的步骤：

1. **运行一个 Ubuntu 容器**

\`\`\`bash
docker run -it --name my-ubuntu ubuntu:latest /bin/bash
\`\`\`

2. **在容器中安装 Nginx**

在容器内执行：

\`\`\`bash
apt-get update
apt-get install -y nginx
exit
\`\`\`

3. **使用 \`docker commit\` 创建新的镜像**

\`\`\`bash
docker commit -a "Your Name" -m "Add Nginx" my-ubuntu my-ubuntu-nginx
\`\`\`

在这个命令中：
- \`-a "Your Name"\` 指定了作者信息。
- \`-m "Add Nginx"\` 提供了提交的说明。
- \`my-ubuntu\` 是我们之前创建的容器名称。
- \`my-ubuntu-nginx\` 是新的镜像名称。

4. **查看新镜像**

\`\`\`bash
docker images
\`\`\`

输出将包括 \`my-ubuntu-nginx\` 镜像，显示相关的镜像信息，如镜像 ID、创建时间、大小等。

5. **运行新的镜像**

\`\`\`bash
docker run -d -p 80:80 my-ubuntu-nginx
\`\`\`

此命令将基于 \`my-ubuntu-nginx\` 镜像创建并启动一个新的容器，将容器的 80 端口映射到宿主机的 80 端口。

#### 5. **注意事项**

- **版本控制**：使用 \`docker commit\` 时，建议使用适当的标签来管理镜像版本，以免混淆。
- **不可重复性**：容器的状态可能会因运行环境的不同而异，使用 \`docker commit\` 不保证镜像的可重复构建，建议在可能的情况下使用 Dockerfile。
- **镜像大小**：频繁使用 \`docker commit\` 可能导致镜像体积不断增大，应注意定期清理不再使用的镜像。

#### 6. **总结**

\`docker commit\` 是一个强大的命令，可以快速将容器状态保存为镜像。然而，为了确保镜像的可重复性和可维护性，最好结合使用 Dockerfile 来构建镜像。这种方法更符合 DevOps 和 CI/CD 的最佳实践。        
        `,
      interviewContent: `
### 选择题

1. **\`docker commit\` 命令的主要作用是什么？**
   - A. 启动一个新的容器
   - B. 将当前容器的更改保存为新的镜像
   - C. 删除一个容器
   - D. 查看镜像列表

2. **使用 \`docker commit\` 时，以下哪个选项用于指定镜像的作者信息？**
   - A. \`-m\`
   - B. \`--change\`
   - C. \`-a\`
   - D. \`--name\`

3. **下面哪个命令会将名为 \`my-container\` 的容器的状态提交为名为 \`my-image\` 的新镜像？**
   - A. \`docker commit my-image my-container\`
   - B. \`docker commit my-container my-image\`
   - C. \`docker commit my-container my-image:latest\`
   - D. \`docker commit -m "New image" my-container\`

4. **\`docker commit\` 命令的使用场景主要是什么？**
   - A. 创建新的容器
   - B. 保存容器的当前状态为镜像
   - C. 删除现有镜像
   - D. 更新容器中的应用

5. **下列哪个选项不是 \`docker commit\` 的参数？**
   - A. \`--author\`
   - B. \`--change\`
   - C. \`--volume\`
   - D. \`-m\`

### 问答题

1. **简述 \`docker commit\` 命令的使用过程和主要参数。**

2. **使用 \`docker commit\` 保存镜像与使用 Dockerfile 构建镜像的优缺点是什么？**

3. **在何种情况下，你会选择使用 \`docker commit\` 命令？请举例说明。**

4. **\`docker commit\` 后，如何查看新创建的镜像？**

5. **如何确保通过 \`docker commit\` 创建的镜像可以重复构建？**

---

## 答案

### 选择题答案
1. B. 将当前容器的更改保存为新的镜像
2. C. \`-a\`
3. B. \`docker commit my-container my-image\`
4. B. 保存容器的当前状态为镜像
5. C. \`--volume\`

### 问答题答案
1. **使用过程和主要参数**：
   - 使用 \`docker commit\` 命令时，指定要提交的容器名称或 ID，然后指定新的镜像名称，可以选择添加作者信息（\`-a\`）和提交说明（\`-m\`）。例如：\`docker commit -a "Author Name" -m "Description" my-container new-image\`。

2. **优缺点**：
   - **使用 \`docker commit\` 的优点**：
     - 快速保存当前容器状态，无需编写 Dockerfile。
     - 适合快速实验和调试。
   - **缺点**：
     - 不保证可重复构建，难以追踪更改。
     - 可能导致镜像管理混乱。
   - **使用 Dockerfile 构建镜像的优点**：
     - 可追踪和版本化。
     - 更具可维护性和可重复性。
   - **缺点**：
     - 需要编写和维护 Dockerfile，初始设置相对复杂。

3. **选择使用 \`docker commit\` 的情况**：
   - 在快速实验中，当你在容器中进行了一系列更改（如安装软件或配置），并希望快速保存当前状态为镜像时，可以使用 \`docker commit\`。例如，在测试某个新软件时，安装后希望将其打包成镜像以便于后续使用。

4. **查看新创建的镜像**：
   - 使用 \`docker images\` 命令查看所有镜像，包括刚刚创建的镜像。可以通过镜像名称、标签等信息进行查找。

5. **确保通过 \`docker commit\` 创建的镜像可以重复构建**：
   - 要确保可重复构建，建议在进行 \`docker commit\` 之前，记录所有的更改步骤，并在 Dockerfile 中重新实现这些步骤。或者，在进行容器更改时，尽量使用相同的基础镜像和相同的命令进行构建，以保持一致性。
      
      `,
    };
  },
});
</script>
<style scoped></style>
