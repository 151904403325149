<template>
  <div class="practice">
    <div class="decorated-text">
      <h4>{{ topRules }}</h4>
    </div>
    <div class="audio-zone-all">
      <div class="audio-zone">
        <div>
          <el-button type="success" @click="toggleAudio">{{
            isPlaying ? "暂停" : "播放声音"
          }}</el-button>
          <el-button @click="downloadAudio">下载声音</el-button>

          <el-button type="danger" @click="toggleAudio2">{{
            isPlaying2 ? "暂停2" : "播放声音2"
          }}</el-button>
          <el-button @click="downloadAudio2">下载声音2</el-button>

          <el-progress
            :percentage="progress"
            color="lightgreen"
            :show-text="false"
            :stroke-width="5"
            style="margin-top: 10px"
          ></el-progress>

          <el-progress
            :percentage="progress2"
            color="red"
            :show-text="false"
            :stroke-width="5"
            style="margin-top: 10px"
          ></el-progress>

          <audio
            ref="audioPlayer"
            :src="audioUrl"
            @timeupdate="onTimeUpdate"
            @error="onError"
            style="display: none"
          ></audio>
          <audio
            ref="audioPlayer2"
            :src="audioUrl2"
            @timeupdate="onTimeUpdate2"
            @error="onError2"
            style="display: none"
          ></audio>
        </div>
      </div>
    </div>
    <div class="regulations-container">
      <div class="regulations-left">
        <el-alert type="success" effect="dark" center :closable="false" />
        <div
          class="regulation"
          v-for="(regulation, index) in regulationsLeft"
          :key="index"
        >
          <el-text type="success">
            <el-icon name="error"> <CircleCheckFilled /> </el-icon>
            {{ regulation.title }}</el-text
          >
          <p>{{ regulation.content }}</p>
        </div>
      </div>
      <div class="regulations-right">
        <el-alert type="error" effect="dark" center :closable="false" />

        <div
          class="regulation"
          v-for="(regulation, index) in regulationsRight"
          :key="index"
        >
          <el-text type="danger">
            <el-icon name="error"> <CircleCloseFilled color="red" /> </el-icon>
            {{ regulation.title }}</el-text
          >
          <p>{{ regulation.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CircleCheckFilled, CircleCloseFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";

export default {
  components: {
    CircleCheckFilled,
    CircleCloseFilled,
  },
  data() {
    return {
      audioUrl:
        "http://azlinux-static.oss-cn-chengdu.aliyuncs.com/sre-media/good-practice-woman.mp3",
      audioUrl2:
        "http://azlinux-static.oss-cn-chengdu.aliyuncs.com/sre-media/bad-practice-man.mp3",
      audioPlayer: null,
      audioPlayer2: null,
      isPlaying: false,
      isPlaying2: false,
      duration: 0,
      duration2: 0,
      currentTime: 0,
      currentTime2: 0,
      topRules:
        "运维意识相比运维技术更容易被忽视，它能指导运维工程师少出错地做好工作，也是老运维相比运维新人的优点。",
      regulationsLeft: [
        {
          title: "建议1：事故发生后，恢复业务第一，排查根因第二。",
          content:
            "运维人员优先保障站点和服务的可用性，不可执着于排查根因，耽误和拖延了恢复时间。当然，排查根因是个很好的运维习惯。",
        },
        {
          title: "建议2：多采用书面交流而非口头答复。",
          content:
            "口头答复他人时，思考时间较短，存在考虑问题不周的风险。邮件等书面记录不仅留有思考时间，也有记录更加正式。",
        },
        {
          title: "建议3：持续改进运维系统。",
          content:
            "对照优秀的运维实践，运维人员可以持续地改进运维系统的稳定性，适当地引入运维平台和系统，解放自己。",
        },
        {
          title: "建议4：SRE是运维方向，要善于利用软件工程技能辅助运维工作。",
          content:
            "不懂代码仍然可以找到运维工作，但是长期平平淡淡，无法将运维能力推动到下一个阶段。",
        },
        {
          title: "建议5：个人数据和工作数据分离。",
          content:
            "适用于同时拥有个人电脑+公司办公电脑的场景。公司电脑只用于办公，个人电脑仅限于处理私人事务，不交互使用，不互传信息。",
        },
        {
          title:
            "建议6：不清楚的事情要多问一次，不可想当然凭自己经验判断，然后执行。",
          content:
            "有的工程师写的文档用字用词不规范，导致其他人阅读时容易困惑，碰到不明白的地方，要问清楚。如需要执行文档步骤，请确保理解到位。很多运维事故就是文档理解不到位导致的。",
        },
        {
          title:
            "建议7：输出文档给他人使用时，要多阅读几次，确保文档阅读流畅，不要图方便不添加标点符号！",
          content:
            "有的工程师写的文档步骤缺少标点符号，中文中，没有标点符号，影响到句子意思，需要阅读者自己断句，引入阅读障碍。",
        },
        {
          title: "建议8：更改系统配置和数据前，做好备份工作。",
          content: "修改出错后，还有挽回余地。",
        },
        {
          title:
            "建议9：监控平台（平台本身+agent+监控数据的转发agent）对所监控的系统只读取其状态数据，不对系统做出更改。",
          content:
            "监控到异常后，有的工程师认为应当调用起匹配该异常的修复脚本，对系统做出恢复。然而这样不是好的实践。应该由其他运维服务修复平台做出对应处理。",
        },
        {
          title:
            "建议10：要比客户/用户提前发现故障，而非被动等待客户/用户上报故障。",
          content:
            "部署模拟用户行为的监控系统，例如拨测系统，提前于客户/用户监控到问题，迅速做出应对服务异常的处理。客户/用户报故障时，影响范围就很大了。",
        },
        {
          title:
            "建议11：系统的关键性能参数做出调整的前后，要提示操作人员该参数的变化幅度。",
          content:
            "生产环境调整系统参数，稍不注意可能引入风险。调大参数举例: 将conUser=1000000调成成将conUser=150000，因漏掉了一个0，数值变小，引发了服务性能问题，造成严重事故。",
        },
      ],
      regulationsRight: [
        {
          title: "1：误操作之后不可隐瞒，尽快通知其他运维人员，迅速恢复。",
          content:
            "误操作之后不要有侥幸心里，不要认为不会被发现，没人知道，从而避免自己被批评，隐瞒不报会给公司带来更多损失。",
        },
        {
          title: "2：禁止运行自己不理解的命令和未知脚本。",
          content:
            "尤其是在生产环境，不要运行自己不理解的命令，可能会闯祸。有人使用dd命令测试磁盘性能，却毁掉了生产数据，给公司带来数十万损失。",
        },
        {
          title: "3：生产环境禁止用于个人试验用途，禁止用来练习脚本技能等。",
          content:
            "保持学习和持续提高自身技能，是很赞的工作态度。但是请不要在生产环境进行练习，有闯祸风险。",
        },
        {
          title:
            "4：禁止运行来路不明的破解软件，优先选择开源软件或授权的商业软件。",
          content:
            "网络上随意下载的软件可能包括木马病毒和勒索软件等，如发生内网传播，对严重破坏公司数字资产，如勒索软件。",
        },
        {
          title:
            "5：禁止从数据库中导出用户数据后，未经脱敏步骤就发送给其他人员（运营，开发等）。",
          content:
            "从源头上保护用户数据安全，减少泄露风险。禁止出于好奇心和炫耀目的，将用户数据发放到社交平台。",
        },
        {
          title: "6：禁止变更方案中不包含回退方案，要有应对意外的准备。",
          content: "不怕一万，就怕万一，要为突发情况准备应急方案。",
        },
        {
          title: "7：禁止以任何方式变相压榨外包运维工程师。",
          content:
            "道德上来说，尊重他人。其二，公司运维负担临时增加时，外包工程师辅助完成公司任务，管理层应能力范围内为外包同学多争取福利。",
        },
        {
          title: "8：禁止大范围更新配置和数据前，没有进行小范围试点。",
          content:
            "灰度发布同样适用于运维，灰度发布过程中能提前发现问题，避免大范围影响到全局环境。小范围验证通过后，按批次更新剩余节点。",
        },
        {
          title:
            "9：禁止变更后不检查业务，信心十足地认为业务无问题而无需检查。",
          content:
            "变更前尽早检查是否满足变更前提，变更后验证业务是否正常。一次成功的变更包括了业务运行状态的检查。",
        },
        {
          title:
            "10：禁止将公司敏感文档，商业方案，产品关键架构和代码上传到外部网站换取积分等。",
          content: "可能给公司带来无法预测的后果，甚至引发公司倒闭。",
        },
        {
          title: "11：运维人员身体不舒服，状态不佳时，禁止做高风险变更。",
          content:
            "带病和状态不佳时，进行高风险工作是不建议的，高风险变更前要神智清晰，不可过量饮酒等。",
        },
      ],
    };
  },
  methods: {
    toggleAudio() {
      if (this.audioPlayer) {
        if (this.isPlaying) {
          this.audioPlayer.pause();
        } else {
          this.audioPlayer.play();
        }
        this.isPlaying = !this.isPlaying;
      } else {
        console.error("audioPlayer is not initialized");
      }
    },
    toggleAudio2() {
      if (this.audioPlayer2) {
        if (this.isPlaying2) {
          this.audioPlayer2.pause();
        } else {
          this.audioPlayer2.play();
        }
        this.isPlaying2 = !this.isPlaying2;
      } else {
        console.error("audioPlayer2 is not initialized");
      }
    },
    onTimeUpdate() {
      this.currentTime = this.audioPlayer.currentTime;
      this.duration = this.audioPlayer.duration;
    },
    onTimeUpdate2() {
      this.currentTime2 = this.audioPlayer2.currentTime;
      this.duration2 = this.audioPlayer2.duration;
    },
    onError() {
      ElMessage.error("音频1加载或播放失败，请检查网络或文件。");
    },
    onError2() {
      ElMessage.error("音频2加载或播放失败，请检查网络或文件。");
    },
    downloadAudio() {
      const link = document.createElement("a");
      link.href = this.audioUrl;
      link.download = "sre-good-practice-azlinux.com.mp3";
      link.click();
    },
    downloadAudio2() {
      const link2 = document.createElement("a");
      link2.href = this.audioUrl2;
      link2.download = "sre-bad-practice-azlinux.com.mp3";
      link2.click();
    },
  },
  computed: {
    progress() {
      return this.duration ? (this.currentTime / this.duration) * 100 : 0;
    },
    progress2() {
      return this.duration2 ? (this.currentTime2 / this.duration2) * 100 : 0;
    },
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
    this.audioPlayer2 = this.$refs.audioPlayer2;
  },
};
</script>

<style scoped>
.regulations-container {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  min-height: 100vh;
}
.audio-zone,
.audio-zone-2 {
  padding-bottom: 1rem;
}

.decorated-text {
  margin: 0.6rem auto;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
  background-color: #f9f9f9;
  /* box-shadow: 0 4px 6px rgba(200, 200, 0, 0.4); */
}

.decorated-text h4 {
  color: #333;
  font-weight: bold;
  text-align: center;
}

.el-alert {
  height: 1.2rem;
  margin-bottom: 1rem;
}

.regulations-left {
  flex: 1;
  padding: 1rem 2rem;
}

.regulations-right {
  flex: 1;
  padding: 1rem 2rem;
}

.regulation {
  margin-bottom: 1.32em;
  text-align: left;
}

.regulation .el-text {
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica",
    Arial, sans-serif;
  font-size: 20px;
}
.regulation p {
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica",
    Arial, sans-serif;
  font-size: 16px;
}
</style>
