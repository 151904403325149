<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## printenv 命令详解

\`printenv\` 命令用于显示当前用户的环境变量及其值。环境变量是影响系统和进程行为的一组动态命名值。\`printenv\` 是一个非常有用的工具，可以帮助用户查看系统环境设置和调试环境变量问题。

### 命令格式

\`\`\`bash
printenv [变量名]
\`\`\`

### 主要参数

- \`<变量名>\`：可选参数，指定要显示的环境变量名称。如果未指定变量名，则显示所有环境变量。

### 使用示例

1. **显示所有环境变量**

   \`\`\`bash
   printenv
   \`\`\`

   **输出示例**：

   \`\`\`bash
   PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
   HOME=/home/sre
   LANG=en_US.UTF-8
   SHELL=/bin/bash
   \`\`\`

   **解读**：
   - \`PATH\`：系统可执行文件的搜索路径。
   - \`HOME\`：当前用户的主目录。
   - \`LANG\`：系统语言设置。
   - \`SHELL\`：当前用户的默认 shell。

2. **显示指定环境变量的值**

   \`\`\`bash
   printenv PATH
   \`\`\`

   **输出示例**：

   \`\`\`bash
   /usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
   \`\`\`

   **解读**：
   - 显示 \`PATH\` 环境变量的值，列出了系统中可执行文件的路径。

3. **使用 \`printenv\` 显示不存在的变量**

   \`\`\`bash
   printenv NON_EXISTENT_VAR
   \`\`\`

   **输出示例**：

   \`\`\`bash
   # 无输出
   \`\`\`

   **解读**：
   - 如果指定的环境变量不存在，\`printenv\` 不会显示任何内容。

### 不同架构下的输出

\`printenv\` 命令在不同架构下的输出一般是一致的。环境变量的显示格式和内容通常不会因为架构不同而发生变化，因此本节可以省略。

### 版本差异

\`printenv\` 命令的输出和功能在不同版本中基本保持一致。可以通过运行 \`printenv --version\` 来检查当前版本及其功能。如果命令不支持 \`--version\` 选项，通常会显示一个帮助信息。

### 安全性及替代命令

\`printenv\` 命令是标准的工具，没有被弃用或标记为不安全。它通常与其他工具（如 \`env\` 和 \`set\`）一起使用，来管理和调试环境变量。

### 安装

\`printenv\` 命令是 \`GNU coreutils\` 软件包的一部分，通常包含在大多数Linux发行版的基本安装中。如果需要重新安装或确认其安装，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 需要root权限的选项

\`printenv\` 命令本身不需要 root 权限来查看环境变量。用户可以在普通权限下查看自己用户的环境变量。不过，如果需要查看其他用户的环境变量，可能需要相应的权限。

\`\`\`bash
sudo printenv PATH
\`\`\`

**示例输出**：

\`\`\`bash
/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以查看所有环境变量，但通常仍只能查看当前用户的环境变量，除非使用特殊的权限切换工具。


        `,
    };
  },
});
</script>
<style scoped></style>
