<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="ps命令和rm命令被一个实习生删掉了-请恢复"
      source="测试环境-请主机管理工程师的你们协助恢复下"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="2" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdDnfReinstall",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10071",
      taskOS: "AlmaLinux",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
### \`yum provides\` 和 \`apt-file search\` 用法详解

在运维工作中，寻找包含特定文件或库的安装包是常见需求。  
\`yum provides\` 和 \`apt-file search\` 是分别在 RHEL/CentOS 和 Ubuntu 系统中使用的命令，用于定位安装包中包含的文件。

#### 提示: dnf/yum 安装软件前，建议运行dnf makecache (简写: dnf mc) 或者 yum makecache  
#### 提示: apt/apt-get 安装软件前，建议运行apt-get update 或者 apt update  

#### 1. \`yum provides\` 用法

\`yum provides\` 是 RHEL 和 CentOS 中基于 \`yum\` 包管理器的一个功能，能够搜索系统或仓库中，哪个包提供了特定的文件或库。

**用法：**
\`\`\`bash
yum provides /path/to/file
\`\`\`

**示例：**
假设你想知道哪个包提供了 \`/usr/bin/wget\`，可以使用以下命令：
\`\`\`bash
yum provides /usr/bin/wget
\`\`\`

输出可能会显示如下信息：
\`\`\`bash
wget-1.14-18.el7_6.1.x86_64 : A utility for retrieving files using the HTTP or FTP protocols
Repo        : base
Matched from:
Filename    : /usr/bin/wget
\`\`\`

这个结果表明，\`wget\` 包提供了 \`/usr/bin/wget\` 文件。

#### 2. \`apt-file search\` 用法

在 Ubuntu/Debian 系统中，\`apt-file\` 是类似的工具，用于搜索哪个安装包包含某个特定文件。

**安装 \`apt-file\`：**
\`\`\`bash
sudo apt update
sudo apt install apt-file
\`\`\`

**更新 \`apt-file\` 数据库：**
\`\`\`bash
sudo apt-file update
\`\`\`

**用法：**
\`\`\`bash
apt-file search /path/to/file
\`\`\`

**示例：**
如果你想查找哪个包提供了 \`lsb_release\`，可以使用以下命令：
\`\`\`bash
apt-file search /usr/bin/lsb_release
\`\`\`

输出可能会类似：
\`\`\`bash
lsb-release: /usr/bin/lsb_release
\`\`\`

这表明 \`lsb-release\` 包包含了 \`/usr/bin/lsb_release\` 文件。

#### 3. \`dnf provides\` 用法

在使用 \`dnf\` 作为包管理器的 RHEL/CentOS 8+ 及 Fedora 系统中，\`dnf provides\` 功能类似于 \`yum provides\`，也是用来查找特定文件属于哪个包。

**用法：**
\`\`\`bash
dnf provides /path/to/file
\`\`\`

**示例：**
\`\`\`bash
dnf provides /usr/bin/wget
\`\`\`

输出和 \`yum provides\` 相似，显示文件所属的包和相关信息。

#### 总结

- **\`yum provides\`**：用于 RHEL/CentOS，帮助查找文件属于哪个包。
- **\`apt-file search\`**：用于 Ubuntu/Debian，功能类似，需要先安装并更新数据库。
- **\`dnf provides\`**：是 \`yum provides\` 的升级版，用于 RHEL 8+/Fedora 系统，功能相同。

如果 \`rm\` 命令被意外删除了，可以通过以下方法来恢复它，具体步骤因操作系统而异。

### 1. 使用包管理器恢复 \`rm\` 命令

由于 \`rm\` 命令是 \`coreutils\` 包的一部分，恢复 \`rm\` 命令的最直接方法是重新安装或修复 \`coreutils\` 包。

#### RHEL/CentOS 系统（使用 \`yum\` 或 \`dnf\`）

**使用 \`yum\` 重新安装 \`coreutils\`：**
\`\`\`bash
sudo yum reinstall coreutils
\`\`\`

**使用 \`dnf\` 重新安装 \`coreutils\`：**
\`\`\`bash
sudo dnf reinstall coreutils
\`\`\`

这将会重新安装包含 \`rm\` 的 \`coreutils\`，并恢复 \`/usr/bin/rm\`。

#### Ubuntu/Debian 系统（使用 \`apt\`）

**重新安装 \`coreutils\`：**
\`\`\`bash
sudo apt install --reinstall coreutils
\`\`\`

这将会重新安装 \`coreutils\` 包，并恢复 \`rm\` 命令。

### 2. 临时解决方案（无 \`rm\` 时使用 \`busybox\`）

如果 \`rm\` 被删除，但暂时无法使用包管理器恢复，\`busybox\` 提供了基本的 Linux 命令集，包括 \`rm\`。

**安装 \`busybox\`（适用于大部分系统）：**
\`\`\`bash
sudo apt install busybox   # Ubuntu/Debian
sudo yum install busybox   # RHEL/CentOS
sudo dnf install busybox   # Fedora/CentOS 8+
\`\`\`

然后可以通过 \`busybox\` 执行 \`rm\` 命令：
\`\`\`bash
busybox rm <file>
\`\`\`

### 3. 手动从备份恢复 \`rm\`

如果系统上有其他服务器或备份，你也可以手动恢复 \`/usr/bin/rm\` 文件。

**从备份恢复：**
\`\`\`bash
scp user@backup_server:/usr/bin/rm /usr/bin/rm
chmod +x /usr/bin/rm
\`\`\`

确保恢复的 \`rm\` 命令具有可执行权限。

### 4. 通过软链接临时恢复 \`rm\`

如果 \`/bin/rm\` 已被删除，但仍然有其他位置的 \`rm\` 命令（如 \`/usr/local/bin/rm\`），可以创建一个软链接将其恢复：

\`\`\`bash
sudo ln -s /usr/local/bin/rm /usr/bin/rm
\`\`\`

这是一种临时方案，直到你能使用包管理器恢复 \`rm\` 命令。

---

### 总结

1. 使用包管理器（\`yum\`、\`dnf\`、\`apt\`）重新安装 \`coreutils\` 是最推荐的方法。
2. 如果包管理器不可用，\`busybox\` 提供了临时替代的 \`rm\` 功能。
3. 也可以通过从备份或其他路径手动恢复 \`rm\`。
  `,
      markdownContentExpand: `
\`dnf\`（Dandified YUM）是 \`yum\` 的替代品，它在功能和性能上进行了许多改进。以下是 \`dnf\` 相比 \`yum\` 的一些主要优点：

### 1. 更好的依赖关系管理
\`dnf\` 使用 libsolv 库来处理依赖关系，这使得它在解决复杂依赖问题时更加高效和准确。它能够处理更复杂的依赖关系，避免安装过程中出现的冲突。

### 2. 更快的性能
\`dnf\` 在执行软件包安装和更新时比 \`yum\` 更快。它优化了缓存管理和依赖关系解析的过程，从而提高了整体性能。

### 3. 并行下载
\`dnf\` 支持并行下载软件包，这使得安装和更新操作更快速。在 \`yum\` 中，下载通常是串行的，这会导致较长的等待时间。

### 4. 增强的命令行选项
\`dnf\` 提供了更丰富的命令行选项，使得用户可以更灵活地管理软件包。例如，\`dnf\` 支持 \`history\` 命令，可以查看和回滚软件包的安装历史。

### 5. 更好的插件支持
\`dnf\` 对插件的支持更加全面和灵活。用户可以更方便地编写和使用插件，以扩展 \`dnf\` 的功能。

### 6. 更加用户友好的输出
\`dnf\` 在输出信息时，提供了更清晰和易读的格式，让用户更容易理解当前操作的进展和结果。

### 7. 更好的安全性
\`dnf\` 在安装软件包时，使用更强的验证机制，确保软件包的完整性和来源。这在一定程度上提升了系统的安全性。

### 8. 更少的过时软件包
\`dnf\` 会更积极地处理过时的软件包，定期清理和更新缓存，从而减少了冗余和不必要的包。

### 总结
总体而言，\`dnf\` 提供了更快、更可靠和更灵活的包管理体验，因此在许多现代 Linux 发行版（如 Fedora、CentOS 8 和 RHEL 8）中，\`dnf\` 成为默认的包管理工具。虽然 \`yum\` 在某些老旧系统中仍然使用，但推荐用户尽快转向 \`dnf\` 以获得更好的体验。

  `,
      markdownInterview: ` 
### 选择题

1. **\`yum\` 和 \`dnf\` 的主要区别是什么？**
   - A) \`yum\` 只能在 RHEL 6 和更早版本上使用
   - B) \`dnf\` 是 \`yum\` 的替代品，并提供更好的依赖关系管理
   - C) \`yum\` 支持插件，而 \`dnf\` 不支持
   - D) \`dnf\` 是一种图形化工具

2. **在使用 \`dnf\` 安装软件包时，哪个命令可以同时更新系统中的所有软件包？**
   - A) \`dnf upgrade\`
   - B) \`dnf install --upgrade\`
   - C) \`dnf update\`
   - D) \`dnf install all\`

3. **\`yum clean all\` 命令的作用是什么？**
   - A) 删除所有已安装的软件包
   - B) 清理所有缓存的包和元数据
   - C) 更新所有软件包的缓存
   - D) 仅清理元数据

4. **哪个命令可以列出所有可用的软件包？**
   - A) \`yum list\`
   - B) \`yum search all\`
   - C) \`yum available\`
   - D) \`yum show\`

### 问答题

1. **解释 \`dnf\` 和 \`yum\` 的工作原理，以及如何管理软件包的依赖关系。**

2. **如果在使用 \`dnf\` 安装软件包时遇到依赖关系冲突，应该如何处理？请提供解决方案的步骤。**

3. **\`dnf\` 和 \`yum\` 中的 \`groupinstall\` 命令有什么作用？请给出具体示例。**

4. **在使用 \`yum\` 进行软件包安装时，如何指定要安装的特定版本？请给出示例命令。**

### 答案

#### 选择题答案
1. **B**) \`dnf\` 是 \`yum\` 的替代品，并提供更好的依赖关系管理
2. **C**) \`dnf update\`
3. **B**) 清理所有缓存的包和元数据
4. **A**) \`yum list\`

#### 问答题答案
1. **解释 \`dnf\` 和 \`yum\` 的工作原理，以及如何管理软件包的依赖关系。**
   - \`yum\` 和 \`dnf\` 都是包管理工具，用于在基于 RPM 的 Linux 发行版上安装、更新和删除软件包。它们使用 RPM 数据库来跟踪已安装的软件包及其依赖关系。\`dnf\` 提供了更强大的依赖关系解决能力，并使用 libsolv 库来处理复杂的依赖关系，而 \`yum\` 使用较为简单的解决方法。

2. **如果在使用 \`dnf\` 安装软件包时遇到依赖关系冲突，应该如何处理？请提供解决方案的步骤。**
   - 如果遇到依赖关系冲突，可以通过以下步骤处理：
     1. 使用 \`dnf info <package_name>\` 查看有关软件包的信息。
     2. 使用 \`dnf deplist <package_name>\` 检查缺少的依赖项。
     3. 尝试安装缺失的依赖包，使用命令 \`dnf install <missing_package>\`。
     4. 如果问题仍然存在，可以考虑移除冲突的软件包或更新系统中所有软件包。

3. **\`dnf\` 和 \`yum\` 中的 \`groupinstall\` 命令有什么作用？请给出具体示例。**
   - \`groupinstall\` 命令用于安装一组相关的软件包（即软件组）。例如，使用命令 \`dnf groupinstall "Development Tools"\` 可以安装所有与开发工具相关的软件包。

4. **在使用 \`yum\` 进行软件包安装时，如何指定要安装的特定版本？请给出示例命令。**
   - 可以使用 \`yum install <package_name>-<version>\` 命令来安装特定版本的软件包。例如，\`yum install httpd-2.4.6-93.el7.x86_64\` 可以安装特定版本的 Apache HTTP 服务器。

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
