<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## userdel 命令详解

\`userdel\` 命令用于从 Linux 系统中删除用户账户。这个命令是系统管理员管理用户账户的工具之一，能够有效地移除不再需要的用户。

### 1. 基本用法

\`userdel\` 命令的基本形式如下：

\`\`\`bash
userdel [OPTION...] USERNAME
\`\`\`

其中，\`USERNAME\` 是要删除的用户的用户名。

### 2. 常用选项

- \`-r, --remove\`：删除用户的主目录及其内容。
- \`-f, --force\`：强制删除用户，即使用户当前正在登录或有未完成的进程。
- \`-h, --help\`：显示帮助信息。
- \`-v, --verbose\`：输出详细信息。

### 3. 示例及解释

#### 3.1 删除一个用户

\`\`\`bash
$ sudo userdel dev01
\`\`\`

**输出解释**：
- 删除用户名为 \`dev01\` 的用户，但不删除该用户的主目录。

#### 3.2 删除用户并删除主目录

\`\`\`bash
$ sudo userdel -r dev01
\`\`\`

**输出解释**：
- \`-r\`：除了删除 \`dev01\` 用户，还删除其主目录 \`/home/dev01\` 及其中的所有文件。

#### 3.3 强制删除用户

\`\`\`bash
$ sudo userdel -f dev01
\`\`\`

**输出解释**：
- \`-f\`：即使用户 \`dev01\` 正在登录或有未完成的进程，也强制删除该用户。

### 4. 需要的权限

\`userdel\` 命令需要 root 权限才能删除用户账户。普通用户没有权限执行该命令。

\`\`\`bash
$ sudo userdel [OPTIONS] USERNAME
\`\`\`

### 5. 安装信息

\`userdel\` 命令通常包含在 \`passwd\` 包中，这个包是大多数 Linux 发行版的默认安装包之一。

- **Ubuntu**：\`passwd\` 包中包含 \`userdel\`。
- **RHEL**：\`passwd\` 包中包含 \`userdel\`。

### 6. 版本差异

\`userdel\` 命令的基本功能在不同版本中通常没有显著差异，但某些选项可能会有所不同。建议查看系统的手册页以获取详细的版本信息。

### 7. 总结

\`userdel\` 命令是管理用户账户的关键工具。通过使用不同的选项，管理员可以有效地删除用户及其相关的文件，确保系统的清洁和安全。

        `,
    };
  },
});
</script>
<style scoped></style>
