<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## watch 命令详解

\`watch\` 命令是一个用于定期执行指定命令并在终端中显示其输出的工具。它可以用来监控命令的实时输出，这对于跟踪系统状态、监控进程或调试脚本非常有用。

### 基本用法

\`watch\` 命令的基本语法如下：

\`\`\`bash
watch [选项] [命令]
\`\`\`

其中，\`[选项]\` 用于控制 \`watch\` 的行为，\`[命令]\` 是你想要定期执行的命令。

### 常用选项

- \`-n 秒\`：指定命令的执行间隔时间（单位为秒）。默认值为 2 秒。
- \`-d\`：高亮显示变化的部分。
- \`-t\`：关闭标题显示。
- \`-g\`：仅在命令输出变化时才更新显示。
- \`-b\`：在命令无法执行时发出警告。

### 示例用法

#### 示例 1: 定期执行 \`date\` 命令

\`\`\`bash
watch date
\`\`\`

**输出：**

\`\`\`text
Every 2.0s: date                                                      azlinux-prod-cn: Mon Sep 11 12:34:56 2024

Mon Sep 11 12:34:58 2024
\`\`\`

**解读：** 该命令每 2 秒更新一次 \`date\` 命令的输出，显示当前的日期和时间。输出区域上会显示时间的变化。

#### 示例 2: 每 5 秒执行 \`df -h\` 命令

\`\`\`bash
watch -n 5 df -h
\`\`\`

**输出：**

\`\`\`text
Every 5.0s: df -h                                                     azlinux-prod-cn: Mon Sep 11 12:35:00 2024

Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
\`\`\`

**解读：** 该命令每 5 秒更新一次 \`df -h\` 命令的输出，显示磁盘使用情况。

#### 示例 3: 高亮显示变化部分

\`\`\`bash
watch -d df -h
\`\`\`

**输出：**

\`\`\`text
Every 2.0s: df -h                                                     azlinux-prod-cn: Mon Sep 11 12:35:30 2024

Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
\`\`\`

**解读：** 该命令高亮显示 \`df -h\` 命令输出中发生变化的部分。

#### 示例 4: 关闭标题显示

\`\`\`bash
watch -t df -h
\`\`\`

**输出：**

\`\`\`text
Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
\`\`\`

**解读：** 该命令关闭了 \`watch\` 命令默认显示的标题信息，只显示 \`df -h\` 命令的输出。

#### 示例 5: 仅在输出变化时更新显示

\`\`\`bash
watch -g df -h
\`\`\`

**输出：**

\`\`\`text
Every 2.0s: df -h                                                     azlinux-prod-cn: Mon Sep 11 12:36:00 2024

Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
\`\`\`

**解读：** 该命令仅在 \`df -h\` 命令的输出发生变化时才会更新显示内容，减少了不必要的屏幕刷新。

### 在不同架构下的表现

\`watch\` 命令在 aarch64 和 x86_64/amd64 架构下的输出是一致的，命令行为不会因架构不同而变化。

### 版本差异

\`watch\` 的功能在不同版本间基本保持一致。使用以下命令检查版本：

\`\`\`bash
watch --version
\`\`\`

**示例输出：**

\`\`\`text
watch from procps-ng 3.3.15
\`\`\`

### 安全性和替代命令

\`watch\` 是一个稳定且常用的工具，没有被淘汰的风险。如果你需要更复杂的监控功能，可以使用 \`htop\`、\`top\` 或 \`glances\` 等工具，这些工具提供了更多的系统监控功能。

### 安装

在 Ubuntu 和 RHEL 上，\`watch\` 的安装包名称为 \`procps\`。可以使用以下命令安装：

**Ubuntu：**

\`\`\`bash
sudo apt-get install procps
\`\`\`

**RHEL：**

\`\`\`bash
sudo yum install procps-ng
\`\`\`

### 权限

\`watch\` 命令通常不需要 root 权限即可运行。但是，如果你要监控需要 root 权限的命令，可能需要使用 \`sudo\`：

\`\`\`bash
sudo watch df -h
\`\`\`

**输出：**

\`\`\`text
Every 2.0s: df -h                                                     azlinux-prod-cn: Mon Sep 11 12:36:30 2024

Filesystem      Size  Used Avail Use% Mounted on
/dev/sda1       50G   30G   18G  62% /
\`\`\`

**解读：** 该命令以 root 权限运行，显示 \`df -h\` 命令的所有输出信息。

### 总结

\`watch\` 是一个简单而强大的工具，用于定期执行命令并实时显示其输出。它提供了多种选项来定制显示内容，适用于系统监控、调试和日常运维任务。

        `,
    };
  },
});
</script>
<style scoped></style>
