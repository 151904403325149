<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## less 命令详解

\`less\` 是 Linux 和 Unix 系统中用于分页显示文件内容的命令。与 \`more\` 命令类似，但 \`less\` 提供了更强大的功能，支持在文件中前后滚动、搜索、查看压缩文件等功能。

\`less\` 命令不是 Bash 内置命令，而是一个外部命令，需要在系统中安装相关软件包才能使用。

### 基本用法

\`less\` 命令的基本语法如下：

\`\`\`bash
less [options] file_name
\`\`\`

其中，\`file_name\` 是要查看的文件名称。

示例：

\`\`\`bash
$ less /var/log/syslog
\`\`\`

这将打开 \`/var/log/syslog\` 文件，并允许你分页查看其内容。

### 重要参数

- \`-N\`：显示行号。
- \`-S\`：行过长时不自动换行，而是让用户横向滚动。
- \`-I\`：搜索时忽略大小写。
- \`-g\`：仅高亮当前搜索匹配的结果。
- \`-X\`：退出时不清屏，保留文件内容在终端上。
- \`-f\`：强制打开特殊文件，如设备文件或二进制文件。

#### 显示行号

使用 \`-N\` 参数可以显示每一行的行号：

\`\`\`bash
$ less -N /etc/passwd
\`\`\`

示例输出：

\`\`\`plaintext
    1  root:x:0:0:root:/root:/bin/bash
    2  daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin
    3  bin:x:2:2:bin:/bin:/usr/sbin/nologin
    4  sre:x:1005:1005::/home/sre:/bin/bash
\`\`\`

在上面的输出中，每行的开头都显示了对应的行号。

#### 不自动换行

当文件中有长行时，使用 \`-S\` 参数可以避免自动换行：

\`\`\`bash
$ less -S /etc/services
\`\`\`

在这种模式下，可以使用左右箭头键来横向滚动查看长行。

#### 忽略大小写搜索

使用 \`-I\` 参数可以在搜索时忽略大小写：

\`\`\`bash
$ less -I /var/log/syslog
\`\`\`

在文件打开后，按下 \`/\` 键输入搜索关键词。例如，搜索 \`error\` 时，\`Error\`, \`ERROR\`, \`error\` 都会被匹配。

### 示例：查看命令输出

\`less\` 也可以用于查看长命令的输出。例如：

\`\`\`bash
$ dmesg | less
\`\`\`

这将通过 \`less\` 来分页查看 \`dmesg\` 命令的输出内容。

#### 示例输出解释

假设运行以下命令：

\`\`\`bash
$ ls -l /usr/bin | less -S
\`\`\`

命令会以分页方式查看 \`/usr/bin\` 目录下的内容，并且行过长时不自动换行。

**输出分析：**

\`\`\`plaintext
-rwxr-xr-x 1 root root 901248 Jan 18  2022 awk
-rwxr-xr-x 1 root root 577176 Nov 13  2020 bash
-rwxr-xr-x 1 root root 587472 Jan 12  2022 bzip2
...
\`\`\`

在这个输出中，你可以使用方向键向上、向下、向左和向右滚动内容。如果使用 \`/\` 进行搜索，例如 \`/bash\`，\`less\` 会高亮显示匹配的结果。

### 不同架构下的输出差异

\`less\` 命令在 \`x86_64\` 和 \`aarch64\` 架构下的行为和输出基本相同，都是用于分页查看文件内容。

#### aarch64 架构下的输出

示例：

\`\`\`bash
$ less -N /proc/cpuinfo
\`\`\`

输出内容和 \`x86_64\` 架构类似，只是硬件相关信息有所不同。

### 不同版本的差异

较新版本的 \`less\` 命令提供了更多选项和更好的性能。例如，旧版本可能没有 \`-I\` 参数，或者对特殊文件的支持较差。

### 安装信息

在大多数Linux发行版中，\`less\` 命令默认已安装。如果未安装，可以通过以下包进行安装：

- **Ubuntu/Debian**: \`less\`
- **RHEL/CentOS**: \`less\`

### 使用root权限

通常情况下，\`less\` 命令不需要root权限，但在查看一些受限的系统文件时，可能需要使用 \`sudo\` 运行，例如：

\`\`\`bash
$ sudo less /var/log/secure
\`\`\`

### 总结

\`less\` 命令是Linux/Unix系统中非常实用的工具，尤其在处理大文件时，它的分页查看功能可以显著提高工作效率。掌握 \`less\` 命令的各种参数和用法，可以帮助用户更高效地管理和查看系统文件。

        `,
    };
  },
});
</script>
<style scoped></style>
