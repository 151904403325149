<template>
  <div>
    <GameCard1
      title="运行起来Nginx容器"
      actions="绑定容器的80端口到宿主机的8080端口<br>，通过浏览器访问它。"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
## 运行Nginx容器命令如下
\`\`\`bash
docker run --rm -d -p 8080:80 nginx
\`\`\`

### 命令解析

- \`docker run\`: 启动一个新的容器。
- \`--rm\`: 在容器停止后自动删除容器，保持环境整洁。
- \`-d\`: 让容器在后台运行。
- \`-p 8080:80\`: 将主机的 8080 端口映射到容器的 80 端口，允许从主机访问 Nginx 服务。
- \`nginx\`: 使用的镜像名称。

### 验证步骤

1. **运行命令**:
   在终端中执行上述命令。

2. **检查容器状态**:
   运行以下命令来查看正在运行的容器：

   \`\`\`bash
   docker ps
   \`\`\`

   确保你可以看到 Nginx 容器正在运行。

3. **进入容器**

使用 \`docker exec\` 命令进入刚刚启动的容器。首先，获取容器 ID：

\`\`\`bash
docker ps
\`\`\`

输出可能是这样的：

\`\`\`bash
CONTAINER ID   IMAGE     COMMAND                  CREATED         STATUS         PORTS                  NAMES
c3f279d17e0a   nginx     "/docker-entrypoint.…"   10 seconds ago   Up 10 seconds   0.0.0.0:8080->80/tcp   quizzical_hodgkin
\`\`\`

复制该容器 ID（\`c3f279d17e0a\`），使用以下命令进入容器：

\`\`\`bash
docker exec -it <CONTAINER_ID> /bin/bash
\`\`\`

例如：

\`\`\`bash
docker exec -it c3f279d17e0a /bin/bash
\`\`\`

您也可以只输入一部分容器id，从id中选取前几个，如c3f，通过较短的id能唯一定位到容器完整id即可。  
\`\`\`bash
docker exec -it c3f /bin/bash
\`\`\`

4. **修改 \`index.html\`**

进入容器后，\`nginx\` 的默认网页文件位于 \`/usr/share/nginx/html/index.html\`。你可以使用 \`echo\` 命令简单修改该文件：

\`\`\`bash
echo "Hello, Nginx" > /usr/share/nginx/html/index.html
\`\`\`

或者你可以使用 \`vi\` 或 \`nano\` 编辑器手动编辑该文件。
修改完成之后，你可以通过 \`exit\` 命令退出容器的交互会话。容器会继续在后台运行。

5. **访问服务**:
   在浏览器中打开 \`http://localhost:8080\`或者\`http://public_ip_addr:8080\`(注意多层防火墙放通8080端口，允许网络流量到达docker进程所在的主机)，如果你能看到 Nginx 的欢迎页面，说明 Docker 安装和容器运行都正常。

\`\`\`text
Hello, Nginx.
\`\`\`

6. **停止测试容器**:
   你可以通过以下命令停止并删除容器（如果没有使用 \`--rm\` 选项）：

   \`\`\`bash
   docker stop <container_id>
   \`\`\`

其中 \`<container_id>\` 是你在 \`docker ps\` 命令中看到的容器 ID。


---
下面是一个用户通过主机端口 8080 访问到容器 80 端口的 ASCII 格式调用关系图：

\`\`\`plaintext
+---------------------+
|      用户设备        |
| (Web 浏览器或 CLI)   |
+---------------------+
          |
          | HTTP 请求
          |
          v
+---------------------+
|      主机（Host）    |
|   (Docker Daemon)   |
|                     |
|  +---------------+  |
|  |  端口转发      |  |
|  |  8080 -> 80   |  |
|  +---------------+  |
|         |           |
|         v           |
|   +----------------+ |
|   |    Docker      | |
|   |  (Container    | |
|   |   Runtime)     | |
|   +----------------+ |
|         |            |
|         v            |
|   +----------------+ |
|   |     容器        | |
|   | (nginx, etc.)  | |
|   |   80端口        | |
|   +----------------+ |
+---------------------+
\`\`\`

### 调用关系说明：

1. **用户设备**：用户通过 Web 浏览器或命令行工具发起 HTTP 请求。
2. **主机（Host）**：请求首先到达主机，主机上运行着 Docker Daemon。
   - **端口转发**：主机的 8080 端口映射到容器的 80 端口。
3. **Docker Daemon**：处理请求并将其转发到相应的容器。
4. **容器**：容器内部的应用（如 Nginx）在其 80 端口上处理请求并返回响应。

这个图展示了用户如何通过主机的端口 8080 访问容器的 80 端口的流程。

---

## 主机端口和容器端口容易记混淆？
记住 \`-p 8080:80\` 中哪个是主机的端口，哪个是容器（镜像）端口，可以用以下几种方法帮助你更好地理解和记忆：

### 1. **顺序记忆**：
   - 在 \`-p\` 后的格式是 \`主机端口:容器端口\`。
   - 这个顺序是固定的，始终是 **主机** 端口在前，**容器** 端口在后。

### 2. **语义化**：
   - 可以把它想象成一个 “映射” 的概念：
     - 主机端口（例如 8080）是你访问的入口，就像一个地址。
     - 容器端口（例如 80）是实际服务运行的地方，像一个房间里的门。
   - 所以，当你在浏览器输入 \`http://localhost:8080\` 时，你实际上是在通过主机的 8080 端口进入容器的 80 端口。

### 3. **实例记忆**：
   - 记住常用的端口号：
     - **HTTP** 服务通常使用 **80** 端口。
     - **HTTPS** 服务通常使用 **443** 端口。
     - 这样可以帮助你快速判断，若使用 Nginx 等 Web 服务器，通常它的服务在容器内运行于 **80** 端口。

### 4. **可视化记忆**：
   - 想象一个“门”打开的场景：
     - 主机的 8080 端口是“外面的大门”，而容器的 80 端口是“房间里的门”。
     - 客户端（比如浏览器）通过“外面的大门”进入“房间里的门”去访问服务。

### 5. **实践记忆**：
   - 多运行一些带 \`-p\` 参数的命令，并实际访问服务，这样可以加深对端口的理解。

### 6. **命名规则**：
   - 在指定映射时，可以使用更具描述性的端口，例如使用 \`-p http:80\` 映射为 \`-p 8080:80\` 以便更好理解。

通过这些方法，你应该能更容易地记住 \`-p\` 参数中的主机和容器端口的对应关系。      
      `,
      interviewContent: `
### 选择题：

1. \`docker run --rm\` 选项的作用是什么？
   - A. 允许容器在后台运行
   - B. 在容器停止后自动删除容器
   - C. 将主机的端口映射到容器的端口
   - D. 进入交互式终端模式

2. 在 \`docker run -p 8080:80\` 命令中，\`8080\` 和 \`80\` 分别代表什么？
   - A. 容器的 IP 地址和主机的端口号
   - B. 主机的端口号和容器的端口号
   - C. 主机的 IP 地址和容器的端口号
   - D. 主机的内存大小和容器的内存大小

3. 下面哪个命令可以用于进入已经运行的容器？
   - A. \`docker start <CONTAINER_ID>\`
   - B. \`docker exec -it <CONTAINER_ID> /bin/bash\`
   - C. \`docker inspect <CONTAINER_ID>\`
   - D. \`docker kill <CONTAINER_ID>\`

4. \`docker run -d\` 选项的作用是什么？
   - A. 启动交互式容器
   - B. 在容器停止后删除镜像
   - C. 将容器启动为守护进程，在后台运行
   - D. 下载 \`nginx\` 镜像

#### 问答题：

1. 解释命令 \`docker run --rm -d -p 8080:80 nginx\` 的各个参数的作用。

2. 在 \`docker run -p 8080:80 nginx\` 中，为什么要进行端口映射？如果不映射端口，会发生什么？

3. 如何查看容器中 \`nginx\` 的状态？列出两个可用的命令，并说明它们的作用。

4. 运行 \`docker run --rm -d -p 8080:80 nginx\` 后，如何查看 \`nginx\` 服务器是否正常运行，并访问它的网页？

5. 如果需要在 \`nginx\` 容器中修改 \`index.html\`，应该如何操作？提供步骤。

---

### 答案

#### 选择题：

1. **B** - 在容器停止后自动删除容器
2. **B** - 主机的端口号和容器的端口号
3. **B** - \`docker exec -it <CONTAINER_ID> /bin/bash\`
4. **C** - 将容器启动为守护进程，在后台运行

#### 问答题：

1. **解释 \`docker run --rm -d -p 8080:80 nginx\`**:
   - \`--rm\`: 容器在停止后自动删除。
   - \`-d\`: 将容器作为守护进程在后台运行。
   - \`-p 8080:80\`: 将主机的 8080 端口映射到容器的 80 端口。
   - \`nginx\`: 使用 \`nginx\` 镜像启动容器。

2. **为什么要进行端口映射**：
   端口映射允许从主机访问容器内部的服务。如果不映射端口，\`nginx\` 服务器将只能在容器内部访问，主机外部无法访问。

3. **查看容器状态的命令**：
   - \`docker ps\`: 查看正在运行的容器。
   - \`docker logs <CONTAINER_ID>\`: 查看容器的日志，了解 \`nginx\` 的启动情况。

4. **查看 \`nginx\` 是否运行并访问网页**：
   - 可以使用 \`curl http://localhost:8080\` 或打开浏览器访问 \`http://localhost:8080\`。

5. **修改 \`nginx\` 容器中的 \`index.html\` 步骤**：
   - 使用 \`docker exec -it <CONTAINER_ID> /bin/bash\` 进入容器。
   - 修改 \`/usr/share/nginx/html/index.html\` 文件，例如使用 \`echo "New Content" > /usr/share/nginx/html/index.html\`。
   - 访问 \`http://localhost:8080\` 查看修改效果。
      
      `,
    };
  },
});
</script>
<style scoped></style>
