<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
# chage 命令详解

\`chage\` 命令是Linux系统中用于管理用户密码过期信息的工具。它允许系统管理员查看和修改用户密码的有效期、上次修改日期以及密码过期后的警告天数等。这在确保系统安全性和强制用户定期更改密码方面非常有用。

本文将详细介绍 \`chage\` 命令的重要参数及其用法，并通过具体示例展示命令的输出结果。

## 基本语法

\`chage\` 命令的基本语法如下：

\`\`\`bash
chage [选项] 用户名
\`\`\`

其中，\`用户名\` 是要管理的用户账户的名称。通过不同的选项，可以查看或修改该用户的密码有效期信息。

## 查看用户密码信息

使用 \`-l\` 参数可以查看用户的密码信息，包括上次修改密码的日期、密码过期日期等。

\`\`\`bash
$ sudo chage -l sre
\`\`\`

**输出：**

\`\`\`
Last password change                                    : Aug 30, 2024
Password expires                                        : never
Password inactive                                       : never
Account expires                                         : never
Minimum number of days between password change          : 0
Maximum number of days between password change          : 99999
Number of days of warning before password expires       : 7
\`\`\`

**解读：**
这个命令输出了用户名为 \`sre\` 的用户的密码策略信息。具体包括：
- 上次修改密码的日期
- 密码何时过期
- 密码过期前的警告天数
- 账户是否有过期时间
- 两次修改密码之间的最小和最大天数

## 设置密码过期天数

可以使用 \`-M\` 选项设置用户密码的最大有效期，即密码需要多长时间后过期。

\`\`\`bash
$ sudo chage -M 90 sre
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
上述命令设置用户名为 \`sre\` 的用户密码在90天后过期。这意味着用户必须在90天内更改密码，否则账户将被锁定。

## 设置密码最小使用期限

使用 \`-m\` 选项可以设置用户密码的最小使用期限，即用户在修改密码后必须等待多少天才能再次修改。

\`\`\`bash
$ sudo chage -m 7 sre
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
该命令设置用户名为 \`sre\` 的用户在修改密码后，至少需要等待7天才能再次修改密码。这一功能可以防止用户频繁修改密码。

## 设置密码过期警告天数

使用 \`-W\` 选项可以设置密码过期前系统发出警告的天数。这可以提醒用户在密码即将过期时进行更改。

\`\`\`bash
$ sudo chage -W 14 sre
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将用户名为 \`sre\` 的用户密码过期前的警告天数设置为14天。用户将在密码过期前14天内收到提醒信息。

## 设置账户过期日期

使用 \`-E\` 选项可以设置用户账户的过期日期。过了这个日期，账户将被禁用。

\`\`\`bash
$ sudo chage -E 2024-12-31 sre
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
这个命令将用户名为 \`sre\` 的账户设置为在2024年12月31日过期。届时，用户将无法登录系统。

## 设置账户无效天数

使用 \`-I\` 选项可以设置密码过期后账户失效前的天数。

\`\`\`bash
$ sudo chage -I 30 sre
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
该命令将用户名为 \`sre\` 的用户密码过期后账户被禁用的天数设置为30天。如果用户在密码过期后30天内没有更改密码，账户将被锁定。

## 结论

\`chage\` 命令是管理用户密码过期策略的重要工具。通过\`chage\`，系统管理员可以确保用户定期更改密码，从而提高系统的安全性。掌握\`chage\`命令的各种参数，可以更灵活地控制用户的密码策略，保障系统的正常运行。

        `,
    };
  },
});
</script>
<style scoped></style>
