<template>
  <div>
    <GameCard1
      title="Docker安装之后，系统里多了什么？"
      actions="自主探索，无需【提交练习】"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: ` 
安装 Docker 后，会在系统上生成一系列文件和目录，包含可执行文件、配置文件和镜像、容器数据存储位置。以下是详细的说明：

### 1. 可执行文件

在不同的操作系统上，Docker 的可执行文件和命令可能有所不同。以下是常见的可执行文件及其位置：

- **Docker Daemon**： 
  - **文件名**：\`dockerd\`
  - **位置**：通常位于 \`/usr/bin/dockerd\`
  - **作用**：Docker Daemon 是 Docker 的后台服务，负责管理容器、镜像、网络等资源。它处理来自 Docker CLI 的请求。
  
- **Docker CLI**：
  - **文件名**：\`docker\`
  - **位置**：通常位于 \`/usr/bin/docker\`
  - **作用**：Docker CLI 是用户与 Docker Daemon 交互的命令行工具。用户可以通过运行命令（如 \`docker run\`, \`docker pull\` 等）来管理容器和镜像。

- **Docker Compose**（可选）：
  - **文件名**：\`docker-compose\`
  - **位置**：通常位于 \`/usr/local/bin/docker-compose\`，或使用 \`curl\` 安装时可以指定位置。
  - **作用**：用于定义和管理多容器 Docker 应用的工具。可以使用 \`docker-compose.yml\` 文件定义应用服务的配置。

### 2. 配置文件

- **Docker Daemon 配置文件**：
  - **路径**：\`/etc/docker/daemon.json\`
  - **作用**：用于配置 Docker Daemon 的各种选项，包括存储驱动、网络设置、镜像加速器等。示例配置：
    \`\`\`json
    {
        "data-root": "/var/lib/docker",
        "storage-driver": "overlay2"
    }
    \`\`\`

- **Docker Compose 配置文件**（可选）：
  - **路径**：通常位于项目目录下，文件名为 \`docker-compose.yml\`。
  - **作用**：定义应用的服务、网络、卷等配置。

### 3. 数据存储目录

- **Docker 数据存储位置**：
  - **路径**：\`/var/lib/docker\`
  - **作用**：Docker 在此目录下存储镜像、容器、卷和网络的数据。
    - \`/var/lib/docker/overlay2\`：存储镜像的层数据（使用 overlay2 存储驱动时）。
    - \`/var/lib/docker/containers\`：存储容器的文件和日志。
    - \`/var/lib/docker/volumes\`：存储 Docker 卷的数据。

### 4. 其他相关文件

- **Docker 相关的服务管理文件**（如在 Linux 系统中）：
  - **路径**：通常在 \`/lib/systemd/system/\` 中找到，例如 \`docker.service\` 和 \`docker.socket\`。
  - **作用**：管理 Docker 服务的系统服务单元文件，定义 Docker Daemon 的启动方式和依赖关系。

### 5. [第一阶段总结]

安装 Docker 后，主要的可执行文件是 \`dockerd\` 和 \`docker\`，它们分别位于 \`/usr/bin/dockerd\` 和 \`/usr/bin/docker\`。配置文件通常位于 \`/etc/docker/daemon.json\`，而 Docker 的数据存储在 \`/var/lib/docker\` 目录中。这些文件和目录共同支持 Docker 的运行和管理。

### 6. 其他可执行文件和组件

除了 \`dockerd\` 和 \`docker\`，Docker 的安装过程中还包括以下关键组件和可执行文件：

- **containerd**：
  - **文件名**：\`containerd\`
  - **位置**：通常位于 \`/usr/bin/containerd\`
  - **作用**：\`containerd\` 是一个高性能的容器管理守护进程，负责管理容器的生命周期，包括镜像传输、容器执行和监控等。Docker Daemon（\`dockerd\`）依赖于 \`containerd\` 来处理容器的具体操作。

- **runc**：
  - **文件名**：\`runc\`
  - **位置**：通常位于 \`/usr/bin/runc\`
  - **作用**：\`runc\` 是一个低级别的容器运行时，负责在操作系统上运行容器。它实现了 Open Container Initiative（OCI）标准，用于提供容器的创建、启动、停止和删除等基本功能。\`containerd\` 通过调用 \`runc\` 来实际运行容器。

- **ctr**：
  - **文件名**：\`ctr\`
  - **位置**：通常位于 \`/usr/bin/ctr\`
  - **作用**：\`ctr\` 是 \`containerd\` 的命令行客户端，用于直接与 \`containerd\` 进行交互。它允许用户进行容器和镜像的管理操作，如拉取镜像、创建容器等。尽管不常用于日常操作，\`ctr\` 在调试和开发时非常有用。

### 7. 配置和存储目录

- **containerd 配置文件**：
  - **路径**：\`/etc/containerd/config.toml\`
  - **作用**：用于配置 \`containerd\` 的选项，如存储路径、运行时设置等。Docker 通常会在安装时生成这个文件，用户可以根据需要进行修改。

### 8. 数据存储目录

- **containerd 数据存储位置**：
  - **路径**：\`/var/lib/containerd\`
  - **作用**：\`containerd\` 存储镜像、容器和元数据的目录。这个目录下包含了所有与容器相关的数据和状态信息。

### 9. [第二阶段]总结

Docker 安装过程中，会创建多个关键组件和可执行文件，包括 \`containerd\`、\`runc\` 和 \`ctr\`。这些组件在 Docker 的整体架构中扮演着重要角色，确保容器的生命周期管理和运行时支持。用户可以通过这些工具更细粒度地控制和管理容器，尤其是在进行调试和开发时。

        `,
      interviewContent: `
#### 选择题

1. **Docker 中哪个组件负责容器的实际运行？**
   - A. Docker Daemon
   - B. containerd
   - C. runc
   - D. ctr

2. **以下哪个命令用于与 containerd 交互，进行容器管理？**
   - A. docker
   - B. runc
   - C. ctr
   - D. containerd

3. **Docker 的网络模式中，哪个模式允许容器直接使用宿主机的网络栈？**
   - A. bridge
   - B. host
   - C. overlay
   - D. macvlan

4. **containerd 是什么？**
   - A. 一个 Docker CLI 工具
   - B. Docker 的图形用户界面
   - C. 一个容器运行时，负责管理容器的生命周期
   - D. 一个数据存储插件

5. **在 Docker 的架构中，哪个组件负责管理镜像和容器的生命周期？**
   - A. Docker CLI
   - B. Docker Daemon
   - C. runc
   - D. ctr

#### 问答题

1. **简要描述 Docker 的架构及其主要组件。**
   - **答案要点**：Docker 的架构包括 Docker Daemon、containerd、runc 和 Docker CLI 等。Docker Daemon 负责处理用户请求，管理容器和镜像。containerd 是容器管理的核心，负责容器的生命周期管理。runc 是一个实现了 OCI 规范的容器运行时，负责实际运行容器。Docker CLI 是用户与 Docker 交互的命令行工具。

2. **解释 runc 的作用及其在 Docker 架构中的位置。**
   - **答案要点**：runc 是一个低级别的容器运行时，负责在操作系统上运行容器。它由 containerd 调用，实际执行容器的主进程并进行资源限制和隔离。runc 是实现 Open Container Initiative（OCI）标准的关键组件。

3. **如何使用 ctr 命令列出所有镜像？**
   - **答案要点**：可以使用命令 \`ctr images ls\` 来列出所有镜像。\`ctr\` 是 containerd 的命令行客户端，允许用户与 containerd 交互。

4. **在 Docker 安装后，containerd 的默认存储路径是什么？**
   - **答案要点**：containerd 的默认存储路径是 \`/var/lib/containerd\`，用于存储镜像、容器和元数据。

5. **描述 Docker 的网络架构及其提供的网络模式。**
   - **答案要点**：Docker 的网络架构支持多种网络模式，包括桥接网络（默认网络，容器间通过虚拟桥接通信）、主机网络（容器直接使用主机网络栈）、覆盖网络（用于多主机容器通信）和自定义网络（用户可以创建的网络）。这些模式允许用户根据不同的应用需求进行灵活的网络配置。

---

### 答案

#### 选择题答案
1. C. runc
2. C. ctr
3. B. host
4. C. 一个容器运行时，负责管理容器的生命周期
5. B. Docker Daemon

#### 问答题答案
1. **Docker 的架构及其主要组件**：
   - Docker 的架构包括 Docker Daemon、containerd、runc 和 Docker CLI 等。Docker Daemon 负责处理用户请求，管理容器和镜像。containerd 是容器管理的核心，负责容器的生命周期管理。runc 是一个实现了 OCI 规范的容器运行时，负责实际运行容器。Docker CLI 是用户与 Docker 交互的命令行工具。

2. **runc 的作用及位置**：
   - runc 是一个低级别的容器运行时，负责在操作系统上运行容器。它由 containerd 调用，实际执行容器的主进程并进行资源限制和隔离。runc 是实现 Open Container Initiative（OCI）标准的关键组件。

3. **使用 ctr 列出所有镜像的命令**：
   - \`ctr images ls\`

4. **containerd 的默认存储路径**：
   - \`/var/lib/containerd\`

5. **Docker 的网络架构及网络模式**：
   - Docker 的网络架构支持多种网络模式，包括桥接网络（默认网络，容器间通过虚拟桥接通信）、主机网络（容器直接使用主机网络栈）、覆盖网络（用于多主机容器通信）和自定义网络（用户可以创建的网络）。这些模式允许用户根据不同的应用需求进行灵活的网络配置。
        
        `,
    };
  },
});
</script>
<style scoped></style>
