<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## chown 命令详解

\`chown\` 命令用于更改文件或目录的所有者和/或所属组。它是 Linux 系统管理中一个非常重要的命令，用于管理文件权限和确保安全性。本文将详细介绍 \`chown\` 命令的使用方法，包括常见参数的功能和示例。

### 基本语法

\`chown\` 命令的基本语法如下：

\`\`\`bash
chown [选项] 用户:组 文件或目录
\`\`\`

### 常用参数

#### 1. 更改文件所有者

- \`chown 用户 文件\`

\`\`\`bash
$ chown dev01 example.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`example.txt\` 文件的所有者更改为 \`dev01\` 用户。如果当前用户不是 \`dev01\` 或者该用户没有权限修改文件的所有者，则会出现权限错误。

#### 2. 更改文件所属组

- \`chown :组 文件\`

\`\`\`bash
$ chown :sre-group example.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`example.txt\` 文件的所属组更改为 \`sre-group\`。文件的所有者不变，只更改了组信息。

#### 3. 同时更改文件所有者和所属组

- \`chown 用户:组 文件\`

\`\`\`bash
$ chown dev01:sre-group example.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`example.txt\` 文件的所有者更改为 \`dev01\`，所属组更改为 \`sre-group\`。如果没有足够的权限，可能会遇到错误。

#### 4. 递归更改目录及其内容的所有者和/或所属组

- \`chown -R 用户:组 目录\`

\`\`\`bash
$ chown -R dev01:sre-group /var/log/myapp
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令递归地将 \`/var/log/myapp\` 目录及其所有子目录和文件的所有者更改为 \`dev01\`，所属组更改为 \`sre-group\`。递归操作通常需要管理员权限。

#### 5. 显示更改文件所有者和所属组的详细信息

- \`chown -v 用户:组 文件\`

\`\`\`bash
$ chown -v dev01:sre-group example.txt
\`\`\`

**输出：**

\`\`\`
changed ownership of 'example.txt' from root:root to dev01:sre-group
\`\`\`

**解读：**
此命令显示了所有者和/或所属组更改前后的详细信息。它帮助用户确认文件属性是否已正确修改。

### 使用示例

#### 更改单个文件的所有者和所属组

\`\`\`bash
$ chown dev01:sre-group file1.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`file1.txt\` 的所有者更改为 \`dev01\`，所属组更改为 \`sre-group\`。可以使用 \`ls -l\` 命令确认更改。

#### 更改目录及其内容的所有者和所属组

\`\`\`bash
$ chown -R app01:dev-group /home/appdata
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令递归地将 \`/home/appdata\` 目录及其所有子目录和文件的所有者更改为 \`app01\`，所属组更改为 \`dev-group\`。确保你有足够的权限进行递归更改。

### 权限要求

有些 \`chown\` 命令选项可能需要 root 权限才能执行。例如，递归更改文件和目录的所有者和所属组通常需要 root 权限。如果当前用户没有足够权限，则会收到权限拒绝错误。

### 总结

\`chown\` 命令是 Linux 系统管理中重要的工具，用于更改文件和目录的所有者及其所属组。通过掌握 \`chown\` 的常用参数和用法，您可以有效地管理系统中的文件权限和用户组，确保系统安全和资源的合理使用。

        `,
    };
  },
});
</script>
<style scoped></style>
