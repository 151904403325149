<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lsmod命令详解

\`lsmod\` 是一个用于显示当前加载的内核模块的命令。内核模块是内核的可加载部分，它们允许内核在运行时动态地加载或卸载驱动程序或功能。

### 基本用法

\`lsmod\` 的基本语法如下：

\`\`\`bash
lsmod
\`\`\`

这个命令没有额外的参数或选项，执行后会输出系统中当前加载的所有模块及其相关信息。

### 示例

执行以下命令：

\`\`\`bash
lsmod
\`\`\`

假设输出结果如下：

\`\`\`plaintext
Module                  Size  Used by
nf_conntrack_ipv6       20480  8
nf_defrag_ipv6          16384  1 nf_conntrack_ipv6
nf_conntrack            81920  2 nf_conntrack_ipv6
x86_pkg_temp_thermal    16384  0
coretemp                16384  0
\`\`\`

在这个示例中，\`lsmod\` 列出了当前加载的模块以及每个模块的大小和使用情况。

### 输出字段解释

- **Module**: 模块的名称。
- **Size**: 模块的大小（以字节为单位）。
- **Used by**: 使用该模块的其他模块或功能的数量。

### 常见参数和用法

\`lsmod\` 命令本身没有额外的参数或选项，输出内容通常包括：

- **Module**: 模块的名称。
- **Size**: 模块的大小。
- **Used by**: 被多少个模块或功能使用。

### 不同架构下的输出

\`lsmod\` 命令在不同架构下的输出内容基本一致，但模块的具体名称和使用情况可能会有所不同。例如，在 \`x86_64\` 和 \`aarch64\` 架构下，加载的模块可能不同，但 \`lsmod\` 的格式和字段内容保持一致。

#### x86_64架构下

\`\`\`bash
lsmod
\`\`\`

输出结果：

\`\`\`plaintext
Module                  Size  Used by
nf_conntrack_ipv4       20480  6
nf_defrag_ipv4          16384  1 nf_conntrack_ipv4
nf_conntrack            81920  1 nf_conntrack_ipv4
x86_pkg_temp_thermal    16384  0
coretemp                16384  0
\`\`\`

#### aarch64架构下

\`\`\`bash
lsmod
\`\`\`

输出结果：

\`\`\`plaintext
Module                  Size  Used by
nf_conntrack_ipv6       20480  8
nf_defrag_ipv6          16384  1 nf_conntrack_ipv6
nf_conntrack            81920  2 nf_conntrack_ipv6
x86_pkg_temp_thermal    16384  0
coretemp                16384  0
\`\`\`

### 不同版本的差异

\`lsmod\` 是一个非常基础的工具，通常不会在不同版本间发生显著变化。输出格式和内容在大多数现代 Linux 发行版中是一致的。然而，旧版 \`lsmod\` 可能不支持某些新功能或模块名称。

### 安全性与替代命令

\`lsmod\` 是一个安全的工具，不存在弃用或不安全的问题。没有直接的替代命令，但可以通过以下命令获取更详细的信息：

- **\`modinfo\`**: 显示模块的详细信息。
- **\`dmesg\`**: 查看内核日志，了解模块加载情况。

### 安装信息

\`lsmod\` 命令是 \`kmod\` 包的一部分，通常在 Linux 发行版中默认安装。

- **Ubuntu/Debian**: \`lsmod\` 是 \`kmod\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo apt-get install kmod
  \`\`\`

- **RHEL/CentOS**: \`lsmod\` 也是 \`kmod\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo yum install kmod
  \`\`\`

### 使用root权限

\`lsmod\` 命令通常不需要 root 权限即可执行。它的输出只显示当前加载的模块，不涉及修改系统配置或权限操作。

### 总结

\`lsmod\` 是一个简洁且有用的工具，用于显示当前加载的内核模块及其状态。了解内核模块的加载情况有助于进行系统调试和故障排除。

        `,
    };
  },
});
</script>
<style scoped></style>
