<template>
  <div class="app-container">
    <nav>
      <el-tooltip content="首页" placement="left" effect="dark">
        <router-link to="/">运维练兵场</router-link>
      </el-tooltip>
      |
      <el-tooltip content="系统管理命令解析" placement="bottom" effect="dark">
        <router-link to="/basic">初级中级</router-link>
      </el-tooltip>
      |
      <el-tooltip
        content="模拟工作场景，熟悉工作内容"
        placement="bottom"
        effect="dark"
      >
        <router-link to="/work-mode">工作模式</router-link>
      </el-tooltip>
      |
      <el-tooltip content="在线man手册" placement="bottom" effect="dark">
        <router-link to="/man-pages">命令手册</router-link>
      </el-tooltip>
      | <router-link to="/topics">专项运维</router-link> |
      <el-tooltip
        content="秒级连接任意练习环境，无需为环境调试而困扰"
        placement="bottom"
        effect="dark"
      >
        <router-link to="/online-env">在线环境</router-link>
      </el-tooltip>
      |
      <el-tooltip
        content="所有运维平台的统一入口"
        placement="bottom"
        effect="dark"
      >
        <router-link to="/portal">运维Portal</router-link>
      </el-tooltip>
      |
      <router-link to="/sre-engineering">软件工程</router-link> |
      <el-tooltip content="运维老司机经验之谈" placement="bottom" effect="dark">
        <router-link to="/good-ops">运维意识</router-link>
      </el-tooltip>
      |
      <el-tooltip
        content="收纳了各种工具，请自由探索"
        placement="bottom"
        effect="dark"
      >
        <router-link to="/ops-tools">运维百宝箱</router-link>
      </el-tooltip>
      |
      <el-tooltip content="IT行业术语大全" placement="bottom" effect="dark">
        <router-link to="/terms">术语</router-link>
      </el-tooltip>
      |
      <router-link to="/interview">运维面试</router-link> |
      <el-tooltip content="提升职场英文能力" placement="bottom" effect="dark">
        <router-link to="/ops-eng-reading">运维英语</router-link>
      </el-tooltip>
      |
      <el-tooltip
        content="登录，注册，用户信息，用户设置"
        placement="bottom"
        effect="dark"
      >
        <router-link to="/user-center">个人中心</router-link>
      </el-tooltip>
    </nav>
    <main class="content">
      <router-view />
    </main>
    <BackToTop />
    <Footer />
  </div>
</template>

<script>
import Footer from "./views/FooterView.vue";
import BackToTop from "./views/base/BackToTop.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    BackToTop,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Microsoft YaHei", "微软雅黑", Arial, sans-serif, Inter,
    "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

nav {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  background: linear-gradient(
    to right,
    #a1c4fd,
    #fbc2eb,
    #a1c4fd
  ); /* 轻柔渐变色 */
}

nav > a {
  text-decoration: none;
  color: #333; /* 字体颜色与背景色对比更清晰 */
}

nav > a:hover {
  color: var(--el-color-primary);
}

.router-link-active {
  color: var(--el-color-primary);
  font-weight: bold;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  box-sizing: border-box;
}

.el-link {
  font-size: 15px;
  margin: 0 3px;
}

html,
body {
  margin: 0;
}
</style>
