<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## curl 命令详解

\`curl\` 是一个功能强大的命令行工具，用于通过各种协议（如 HTTP、HTTPS、FTP 等）在命令行或脚本中传输数据。\`curl\` 是 Web 开发、系统管理、网络调试中非常常用的工具。本文将详细介绍 \`curl\` 命令的使用，并着重讲解其中一些重要的参数，特别是 \`-k\` 和 \`-v\` 参数的用法。

### 基本语法

\`curl\` 命令的基本语法如下：

\`\`\`bash
curl [选项] [URL]
\`\`\`

其中，选项用于指定 \`curl\` 的行为，如请求类型、数据处理方式等，URL 是要请求的地址。

### 常用参数

#### 1. \`-o\` 和 \`-O\` 参数

- \`-o [filename]\`：将输出保存到指定文件中。
- \`-O\`：将输出保存为原文件名。

\`\`\`bash
$ curl -o example.html https://www.example.com
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`https://www.example.com\` 的内容保存为 \`example.html\` 文件。使用 \`ls\` 命令可以查看生成的文件。

\`\`\`bash
$ ls
\`\`\`

**输出：**

\`\`\`
example.html
\`\`\`

#### 2. \`-L\` 参数

- \`-L\`：跟随重定向。当服务器返回重定向时，\`curl\` 会自动跟随并请求新的 URL。

\`\`\`bash
$ curl -L https://bit.ly/3nN0RhX
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
\`-L\` 参数确保 \`curl\` 在遇到重定向时自动跟随，直到获得最终的资源。

#### 3. \`-I\` 参数

- \`-I\`：获取响应头信息，而不下载资源的主体内容。

\`\`\`bash
$ curl -I https://www.example.com
\`\`\`

**输出：**

\`\`\`
HTTP/1.1 200 OK
Date: Mon, 09 Sep 2024 12:34:56 GMT
Content-Type: text/html; charset=UTF-8
Content-Length: 1256
...
\`\`\`

**解读：**
此命令仅返回 \`https://www.example.com\` 的响应头信息，而不下载页面内容。

### \`-k\` 和 \`-v\` 参数详解

#### 1. \`-k\` 参数

- \`-k\`：允许 \`curl\` 忽略 SSL 证书验证。通常用于测试环境或当服务器使用自签名证书时。

\`\`\`bash
$ curl -k https://self-signed.badssl.com/
\`\`\`

**输出：**

\`\`\`html
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>self-signed.badssl.com</title>
  ...
</head>
<body>
  <h1>self-signed.badssl.com</h1>
  ...
</body>
</html>
\`\`\`

**解读：**
此命令允许 \`curl\` 忽略 SSL 证书的验证，直接获取内容。对于自签名证书或测试环境，这个参数非常有用。

#### 2. \`-v\` 参数

- \`-v\`：启用详细模式，\`curl\` 会显示详细的请求和响应信息，包括头信息、请求的步骤等。

\`\`\`bash
$ curl -v https://www.example.com
\`\`\`

**输出：**

\`\`\`
*   Trying 93.184.216.34:443...
* Connected to www.example.com (93.184.216.34) port 443 (#0)
> GET / HTTP/1.1
> Host: www.example.com
> User-Agent: curl/7.68.0
> Accept: */*
< HTTP/1.1 200 OK
< Content-Type: text/html; charset=UTF-8
< Content-Length: 1256
...
\`\`\`

**解读：**
此命令提供了请求的详细信息，包括与服务器的连接过程、发送的请求头、收到的响应头等。这对调试非常有帮助。

### 下载文件示例

通过 \`curl\` 可以直接下载文件，例如：

\`\`\`bash
$ curl -O https://www.example.com/file.zip
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`file.zip\` 文件下载到当前目录，文件名保持与服务器一致。

### 查看响应码

使用 \`-w\` 选项可以查看请求的响应码：

\`\`\`bash
$ curl -o /dev/null -s -w "%{http_code}\n" https://www.example.com
\`\`\`

**输出：**

\`\`\`
200
\`\`\`

**解读：**
此命令显示 \`https://www.example.com\` 的 HTTP 响应码，\`200\` 表示请求成功。

### 总结

\`curl\` 是一个非常强大且灵活的工具，可以在命令行中完成几乎所有与 Web 相关的操作。无论是简单的 HTTP 请求，还是复杂的 API 调用，\`curl\` 都能提供极大的便利。通过掌握 \`-k\` 和 \`-v\` 等重要参数，您可以更好地调试网络请求，解决SSL问题，并获取详细的请求和响应信息。

        `,
    };
  },
});
</script>
<style scoped></style>
