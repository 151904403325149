<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ln 命令详解

\`ln\` 命令用于在Linux和Unix系统中创建链接。链接分为两种：硬链接（Hard Link）和符号链接（Symbolic Link）。硬链接是指文件系统中多个文件名指向同一个文件，而符号链接则是指向另一个文件的路径。

\`ln\` 命令在Linux/Unix系统中非常重要，尤其在管理和组织文件系统时。本文将详细介绍 \`ln\` 命令的使用方法、重要参数以及在不同架构下的表现。

### 基本用法

\`\`\`bash
ln [OPTION]... TARGET [LINK_NAME]
\`\`\`

- \`TARGET\` 是要链接的目标文件或目录。
- \`LINK_NAME\` 是创建的链接名称。如果省略该参数，则默认在当前目录下创建与目标文件同名的链接。

### 创建硬链接

硬链接指向同一个inode号，即多个文件名共享同一份数据。删除硬链接不会删除数据，只有最后一个链接被删除后数据才会真正被移除。

\`\`\`bash
ln target_file hard_link_name
\`\`\`

示例：

\`\`\`bash
$ ls -l
total 0
-rw-r--r-- 1 sre sre-group 0 Sep  9 12:00 target_file
$ ln target_file hard_link
$ ls -l
total 0
-rw-r--r-- 2 sre sre-group 0 Sep  9 12:00 hard_link
-rw-r--r-- 2 sre sre-group 0 Sep  9 12:00 target_file
\`\`\`

在上例中，\`hard_link\` 和 \`target_file\` 指向同一个数据块，硬链接数为2。

### 创建符号链接

符号链接（软链接）是指向目标文件路径的快捷方式，符号链接可以跨文件系统。符号链接允许链接目录，而硬链接通常不允许。

\`\`\`bash
ln -s target_file symbolic_link_name
\`\`\`

示例：

\`\`\`bash
$ ln -s target_file symbolic_link
$ ls -l
total 0
-rw-r--r-- 1 sre sre-group 0 Sep  9 12:00 target_file
lrwxrwxrwx 1 sre sre-group 11 Sep  9 12:00 symbolic_link -> target_file
\`\`\`

符号链接的文件类型标记为 \`l\`，并且显示其指向的目标。

### 重要参数

- \`-s, --symbolic\`：创建符号链接而非硬链接。
- \`-f, --force\`：如果存在同名文件或链接，强制覆盖。
- \`-v, --verbose\`：显示详细的操作信息。

示例：

\`\`\`bash
$ ln -sf target_file symbolic_link
$ ls -l
total 0
-rw-r--r-- 1 sre sre-group 0 Sep  9 12:00 target_file
lrwxrwxrwx 1 sre sre-group 11 Sep  9 12:00 symbolic_link -> target_file
\`\`\`

在使用 \`-f\` 参数时，如果 \`symbolic_link\` 已存在，新的链接会覆盖它。

### 错误输出示例

假如尝试为不存在的文件创建符号链接：

\`\`\`bash
$ ln -s non_existent_file symbolic_link
ln: failed to create symbolic link 'symbolic_link': No such file or directory
\`\`\`

这里命令输出标准错误（stderr），表明目标文件不存在。

### 多架构下的输出差异

\`ln\` 命令的行为在不同架构下通常是一致的，无论是 \`x86_64\` 还是 \`aarch64\`。但在某些特定系统实现中，符号链接的性能可能会有所不同。

#### aarch64 架构下的输出

\`\`\`bash
$ ln -s /usr/bin/bash symbolic_link
$ ls -l
lrwxrwxrwx 1 sre sre-group 11 Sep  9 12:00 symbolic_link -> /usr/bin/bash
\`\`\`

输出和 \`x86_64\` 架构下几乎一致，功能表现没有差异。

### 不同版本的差异

不同版本的 \`ln\` 命令在功能上通常没有显著差异，但新版本可能会引入更多选项或更好的错误处理机制。例如，在更早的版本中，\`-v\` 参数可能不如现在详细。

### 安装信息

在大多数Linux发行版中，\`ln\` 命令属于核心命令，不需要额外安装。如果由于某些原因命令不可用，可以通过以下包进行安装：

- **Ubuntu/Debian**: \`coreutils\`
- **RHEL/CentOS**: \`coreutils\`

### 使用root权限

某些情况下，创建链接时可能需要root权限，尤其是在系统目录或其他受限区域。例如：

\`\`\`bash
$ sudo ln -s /etc/hostname /var/hostname_link
\`\`\`

### 总结

\`ln\` 命令是Linux/Unix系统中不可或缺的工具，通过它可以灵活地管理文件和目录链接。理解硬链接和符号链接的区别，以及合理使用各种参数，能极大提高文件系统管理的效率。

        `,
    };
  },
});
</script>
<style scoped></style>
