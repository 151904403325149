<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## partprobe 命令详解

\`partprobe\` 是 Linux 系统中的一个用于通知内核重新读取分区表的命令。当对磁盘的分区表进行了修改（例如通过 \`fdisk\`、\`parted\` 等工具），而不希望重启系统时，可以使用 \`partprobe\` 命令让内核更新分区信息。这通常用于磁盘分区之后，不希望立即重启的情况下。

### 1. 安装 \`partprobe\`

在大多数现代的 Linux 系统中，\`partprobe\` 是 \`parted\` 工具包的一部分，通常默认已经安装。如果未安装，可以根据操作系统的类型进行安装。

#### 在 Ubuntu 上安装 \`partprobe\`

\`\`\`bash
sudo apt install parted
\`\`\`

#### 在 RHEL 或 CentOS 上安装 \`partprobe\`

\`\`\`bash
sudo yum install parted
\`\`\`

安装完成后，可以使用以下命令检查 \`partprobe\` 是否可用：

\`\`\`bash
partprobe --version
\`\`\`

输出:
\`\`\`bash
partprobe (GNU parted) 3.2
\`\`\`

### 2. 基本用法

\`partprobe\` 的最常见用途是通知内核重新读取分区表。假设你已经对磁盘 \`/dev/sdb\` 进行了分区更改，可以使用以下命令让内核更新：

\`\`\`bash
sudo partprobe /dev/sdb
\`\`\`

此命令没有输出表示成功执行，使用其他工具查看分区是否已被内核更新。例如，使用 \`lsblk\` 命令查看磁盘状态：

\`\`\`bash
lsblk /dev/sdb
\`\`\`

输出:
\`\`\`bash
NAME   MAJ:MIN RM   SIZE RO TYPE MOUNTPOINT
sdb      8:16   0   100G  0 disk 
├─sdb1   8:17   0    50G  0 part 
└─sdb2   8:18   0    50G  0 part 
\`\`\`

### 3. 常用参数

#### 3.1 重新读取特定磁盘的分区表

可以通过指定磁盘设备来重新加载特定磁盘的分区表，示例如下：

\`\`\`bash
sudo partprobe /dev/sda
\`\`\`

该命令会通知内核重新读取 \`/dev/sda\` 的分区表。如果没有任何错误输出，说明命令已成功执行。【无输出，命令已成功执行】

#### 3.2 检查所有磁盘

如果不指定特定的磁盘设备，\`partprobe\` 将尝试重新读取系统中所有磁盘的分区表：

\`\`\`bash
sudo partprobe
\`\`\`

此命令同样会执行无输出的操作，如果没有发现错误。【无输出，命令已成功执行】

### 4. 检查分区表是否更新

使用 \`lsblk\` 或 \`fdisk -l\` 命令可以查看分区表更新后的状态。例如，执行 \`partprobe\` 后，使用 \`lsblk\` 命令检查 \`/dev/sda\` 的分区状态：

\`\`\`bash
lsblk /dev/sda
\`\`\`

输出:
\`\`\`bash
NAME   MAJ:MIN RM   SIZE RO TYPE MOUNTPOINT
sda      8:0    0   500G  0 disk 
├─sda1   8:1    0   200G  0 part /
└─sda2   8:2    0   300G  0 part /data
\`\`\`

此时可以确认分区表已经被内核重新读取并更新。

### 5. 不同架构下的表现

\`partprobe\` 在 \`x86_64\` 和 \`aarch64\` 架构上的使用及表现没有明显差异。命令及输出结果一致，因此不再单独列出。

### 6. 旧版本和新版本的差异

\`partprobe\` 命令在旧版本和新版本中，功能和输出的变化非常少。早期版本可能会有更少的参数支持，但基本操作和新版本一致。建议使用最新版 \`parted\` 工具包来确保兼容性和稳定性。

### 7. root 权限的使用

\`partprobe\` 是一个系统级命令，必须以 \`root\` 用户或通过 \`sudo\` 运行，否则会出现权限不足的错误。

#### 使用 \`sudo\` 运行示例

\`\`\`bash
sudo partprobe /dev/sdb
\`\`\`

没有错误信息输出即表示命令执行成功。

### 8. 异常情况

在某些情况下，如果内核无法读取更新后的分区表，\`partprobe\` 可能会返回错误。例如，当分区表损坏或者分区仍在使用时，可能会出现以下错误：

\`\`\`bash
Error: Partition(s) on /dev/sdb are being used.
\`\`\`

解决方案可以是检查是否有相关进程在使用该磁盘（通过 \`lsof\` 或 \`fuser\` 命令），然后停止相关进程或卸载分区。

### 9. 替代命令

\`partprobe\` 并未被淘汰或替代，它仍然是现代 Linux 系统中常用的分区表更新工具。不过，某些用户可能会更倾向于使用 \`parted\` 工具中的功能来更新分区表，具体命令如下：

\`\`\`bash
sudo parted /dev/sdb print
\`\`\`

该命令不仅可以查看分区表，还会自动通知内核更新分区信息。

### 总结

\`partprobe\` 是一个简单但非常有用的工具，尤其在对磁盘分区进行修改之后，能够通知内核重新读取分区表，而无需重启系统。通过本文的介绍，你可以轻松掌握如何使用 \`partprobe\` 来更新分区信息并检查是否成功执行。

        `,
    };
  },
});
</script>
<style scoped></style>
