<template>
  <el-container>
    <div :class="['termtitle', { wide: isWide }]">
      <div class="termtitle">
        <div class="title-left">
          <el-tooltip
            class="item"
            effect="dark"
            content="宽屏展示"
            placement="top"
          >
            <span class="indicator3" @click="widerScreen">⬤</span>
          </el-tooltip>
          <span id="termname">SSH Terminal</span>
        </div>
        <div class="title-right">
          <el-tooltip
            class="item"
            effect="dark"
            content="增加终端字体大小"
            placement="bottom"
          >
            <el-button
              circle
              plain
              size="small"
              @click="increaseFontSize"
              style="font-size: 18px; margin: 2px; padding: 2px 6px"
              >+</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="减少终端字体大小"
            placement="bottom"
          >
            <el-button
              circle
              plain
              size="small"
              @click="decreaseFontSize"
              style="font-size: 12px; margin: 2px; padding: 2px 6px"
              >-</el-button
            >
          </el-tooltip>
        </div>
      </div>
    </div>

    <div :class="['realiframe', { wide: isWide }]">
      <iframe :src="iframeSrc"></iframe>
    </div>
  </el-container>
</template>

<script>
export default {
  name: "BigTerms",
  props: {
    connectStr: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fontsize: 16,
      isWide: false,
      iframeSrc: "", // 用于存储 iframe 的 src
    };
  },
  watch: {
    connectStr: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          console.log(newValue);
          this.updateIframeSrc(); // 更新 iframe 的 URL
        }
      },
    },
  },
  methods: {
    widerScreen() {
      this.isWide = !this.isWide;
    },
    increaseFontSize() {
      this.fontsize += 2; // 每次增加2px
      console.log("Terminal fontsize: " + this.fontsize);
      this.updateIframeSrc(); // 更新 iframe 的 URL
    },
    decreaseFontSize() {
      if (this.fontsize > 12) {
        this.fontsize -= 2; // 每次减少2px，最小为12px
        console.log("Terminal fontsize: " + this.fontsize);
        this.updateIframeSrc(); // 更新 iframe 的 URL
      }
    },
    updateIframeSrc() {
      // 检查是否已有 fontsize 参数
      if (this.connectStr.includes("fontsize=")) {
        // 如果已有 fontsize 参数，替换它的值
        this.iframeSrc = this.connectStr.replace(
          /fontsize=\d+/,
          `fontsize=${this.fontsize}`
        );
      } else {
        // 如果没有，则在 URL 后添加 fontsize 参数
        const connector = this.connectStr.includes("?") ? "&" : "?";
        this.iframeSrc = `${this.connectStr}${connector}fontsize=${this.fontsize}`;
      }
      console.log("Updated iframe URL: " + this.iframeSrc);
    },
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
iframe {
  height: 600px;
  width: 100%; /* 默认宽度 */
  border: 2px solid #263238;
  box-sizing: border-box;
}
.termtitle {
  display: flex;
  justify-content: space-between;
  background-color: #262626;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 800px; /* 默认宽度 */
  max-width: 100%;
  text-align: left;
  transition: width 0.3s ease; /* 添加过渡效果 */
}
.termtitle.wide {
  width: 100%; /* 宽屏状态下的宽度 */
}

.title-left {
  display: flex;
  align-items: center;
}

.title-right {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.realiframe {
  background-color: #333333;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 800px; /* 默认宽度 */
  max-width: 100%;
  transition: width 0.3s ease; /* 添加过渡效果 */
}
.realiframe.wide {
  width: 100%; /* 宽屏状态下的宽度 */
}
.indicator3 {
  color: #33cc33;
  padding: 4px;
  margin-right: 6px;
}

.indicator3:hover {
  cursor: pointer;
}

#termname {
  color: white;
  font-size: 14px;
  margin: 0 10px;
}

.el-button {
  font-size: 14px;
  padding: 4px;
  margin: 0 1rem;
}
</style>
