<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="应用服务器因配置不当被入侵，经杀毒软件查杀，依然有残留文件，文件名特征为: /var/log/.system.data+两位数，（安全部门已取样），请人工全部清理掉。"
      source="安全威胁防护中心 (Operation and Maintenance Security Threat Protection Center)"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink
      :resId="resId"
      :defaultLink="bilibiliLink"
      style="margin-bottom: 1rem"
      >视频解说</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdRm",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10061",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
### 【ll命令】  【ls -a】  【ls -alF】 的用法知识点
- \`ls\`: 是"list"的缩写，用于列出目录中的文件和子目录。
- \`-a\`: 是"all"的缩写，表示列出所有文件和目录，包括以点（.）开头的隐藏文件和目录。

隐藏文件和目录通常以点（.）开头，它们默认情况下不会显示。可以使用以下步骤来查看并删除隐藏文件：
1. 使用 \`cd\` 进入目录 \`/var/log/\`。
2. 执行 \`ll\` 命令，查找隐藏文件。
3. 使用 \`rm\` 命令删除找到的隐藏文件。

### rm 删除文件知识点
删除文件时，应该谨慎使用 \`rm\` 命令。以下是一些常见的选项和建议：
- **使用 \`-i\` 选项**：删除每个文件之前要求确认。这虽然会降低操作速度，但有助于防止误删重要文件。
  示例：
  \`\`\`bash
  rm -i filename
  \`\`\`
- **避免使用 \`-f\` 选项**：\`-f\` 强制删除文件而不会提示确认。在生产环境中，应尽量避免使用，以防误删文件。

  示例：
  \`\`\`bash
  rm -f filename  # 强制删除，不提示
  \`\`\`

- **使用 \`-v\` 选项**：显示被删除的文件名，帮助确认删除操作。
  示例：
  \`\`\`bash
  rm -v filename  # 显示被删除的文件名
  \`\`\`

- **避免在根目录下使用 \`rm\`**：根目录 \`/\` 是 Linux 系统的核心部分，误删根目录下的文件可能导致系统崩溃或无法启动。
- **使用别名保护**：设置别名，例如 \`alias rm='rm -i'\`，可以强制 \`rm\` 命令每次都要求确认，从而防止误操作。
  示例：
  \`\`\`bash
  alias rm='rm -i'
  \`\`\`

- **谨慎使用带有 \`*\` 的正则表达式**：在使用 \`rm\` 时，尤其是配合通配符 \`*\` 时，务必检查参数，避免不小心删除大量文件。
  示例：
  \`\`\`bash
  rm *.log  # 删除所有以 .log 结尾的文件
  \`\`\`

总之，使用 \`rm\` 命令之前，最好稍作停留，检查命令的参数，以免误删重要文件。
      `,
      markdownContentExpand: `
### \`ls\` 命令原理深入解析

\`ls\` 是 Linux 和 Unix 系统中最常用的命令之一，用于列出目录内容。它不仅能够简单地显示文件和目录，还能配合多个选项提供详细的文件信息和排序功能。以下是 \`ls\` 的工作原理和内部机制：

#### 1. **基础原理**
\`ls\` 命令的核心原理是通过系统调用 \`readdir\` 获取当前目录的内容，并返回目录中的文件和子目录。该系统调用会读取文件系统中的目录项，并返回一个包含文件名的列表。

#### 2. **文件属性获取**
除了基本的文件名，\`ls\` 还可以显示文件的详细信息，如权限、所有者、组、大小、修改时间等。这些信息通过调用 \`stat\` 系统调用获得。\`stat\` 会返回文件的元数据，包括：
- **文件权限**：如 \`rwxr-xr-x\`，代表用户、组和其他人的读写权限。
- **文件大小**：以字节为单位表示文件的大小。
- **最后修改时间**：文件内容最后一次被修改的时间。
- **硬链接数**：指向该文件的硬链接数量。
- **文件所有者和所属组**：每个文件都会有一个用户和组，这些信息通过 \`UID\` 和 \`GID\` 表示。

#### 3. **隐藏文件的处理**
Linux 中以 \`.\` 开头的文件被认为是隐藏文件。使用 \`ls\` 默认不会显示这些文件。但如果使用 \`-a\` 或 \`-A\` 选项，\`ls\` 会强制显示隐藏文件。
- **\`-a\`**：显示所有文件，包括 \`.\` 和 \`..\`（当前目录和父目录）。
- **\`-A\`**：显示所有文件，但不包括 \`.\` 和 \`..\`。

#### 4. **排序功能**
\`ls\` 提供了丰富的排序选项，比如按文件大小、修改时间、字母顺序等。排序功能的实现是通过将目录内容读入内存，然后基于特定字段进行排序。例如：
- **按修改时间排序（\`-t\`）**：\`ls\` 会读取每个文件的 \`mtime\`（修改时间），然后按时间降序显示。
- **按文件大小排序（\`-S\`）**：文件通过 \`stat\` 调用返回的文件大小字段进行排序。
- **按字母顺序排序**：默认情况下，\`ls\` 会按文件名的字母顺序排序，这也是文件系统存储目录时的基本顺序。

#### 5. **颜色显示**
当使用 \`ls\` 时，文件可以根据类型以不同颜色显示（例如，可执行文件通常为绿色）。这是通过使用环境变量 \`LS_COLORS\` 或类似的机制来配置的。每种文件类型（普通文件、目录、符号链接、设备文件等）都有其对应的颜色。

### \`rm\` 命令原理深入解析

\`rm\` 命令用于删除文件或目录，但实际上，它并不是立即将文件的内容从磁盘中擦除，而是从文件系统的索引表中移除文件的引用。以下是 \`rm\` 的核心原理：

#### 1. **文件删除的本质**
当执行 \`rm\` 命令时，操作系统并不会立即擦除文件内容，而是删除了指向文件的目录项。这意味着文件名和路径从文件系统中消失了，但文件的数据块仍然保留在磁盘上，直到新的数据覆盖这些位置。

#### 2. **删除普通文件**
\`rm file.txt\` 实际上是调用了系统的 \`unlink\` 函数，它从文件系统的目录结构中删除对该文件的引用。操作系统中的每个文件都会有一个指向它的索引（inode）。当 \`rm\` 命令移除文件时，它会减少该文件的引用计数，只有当引用计数为 0 时，数据才会被操作系统标记为可重用。

#### 3. **删除目录**
- **\`rm -r directory\`**：这是递归删除目录的方式。\`rm\` 会通过系统调用 \`rmdir\` 删除目录中的所有文件和子目录。
- **\`rm -rf\`**：\`-r\` 是递归选项，用于删除目录及其内容，而 \`-f\` 是强制删除，不提示确认。\`rm\` 会逐个遍历目录中的文件和子目录，并调用 \`unlink\` 或 \`rmdir\` 删除。

#### 4. **安全删除机制**
普通的 \`rm\` 命令只是移除了文件的引用，但数据仍在磁盘上存在。为了真正擦除数据，可以使用诸如 \`shred\` 或 \`srm\` 这样的工具，它们会覆盖文件内容多次，确保数据无法被恢复。

#### 5. **删除权限**
\`rm\` 命令需要对文件所在的目录具有写权限。如果用户没有写权限，将无法删除文件，\`rm\` 会返回“权限不足”的错误。此外，\`rm\` 也会遵守文件的不可变标志（\`chattr +i\`），即使用户有权限，也不能删除这些文件，除非取消不可变标志。

#### 6. **误操作防护**
\`rm\` 命令本身非常强大，因此也极具危险性。为防止误删除，\`rm\` 提供了交互模式 \`rm -i\`，每删除一个文件前会提示确认。此外，现代系统通常会建议用户使用别名 \`alias rm='rm -i'\` 来减少误删风险。
---
### 总结
- **\`ls\`** 通过读取文件系统的目录结构和元数据，显示文件名及详细信息。它结合 \`stat\` 调用来获取文件的权限、大小、修改时间等属性，提供灵活的排序和显示选项。
- **\`rm\`** 实际上是通过删除文件系统中的引用来移除文件。其工作原理是修改文件系统的元数据，而不是立即擦除文件内容。高级选项如递归删除和强制删除使得 \`rm\` 非常强大，但也增加了误操作的风险。

掌握了 \`ls\` 和 \`rm\` 命令的深入原理，不仅可以帮助你理解它们在文件系统中的作用，还能更好地避免操作中的风险。

      `,
      markdownInterview: `
### 选择题

1. **\`ls\` 命令的默认行为是什么？**
   - A. 列出当前目录下的所有文件和目录
   - B. 删除指定文件
   - C. 复制文件
   - D. 移动文件

2. **如何使用 \`ls\` 查看当前目录下的所有隐藏文件（以\`.\` 开头的文件）？**
   - A. \`ls -h\`
   - B. \`ls -a\`
   - C. \`ls -l\`
   - D. \`ls -A\`

3. **以下哪个选项可以将 \`ls\` 输出的文件按修改时间排序？**
   - A. \`ls -S\`
   - B. \`ls -t\`
   - C. \`ls -l\`
   - D. \`ls -r\`

4. **\`rm\` 命令用于什么目的？**
   - A. 列出文件
   - B. 删除文件或目录
   - C. 移动文件
   - D. 复制文件

5. **要强制删除一个目录及其所有内容，正确的 \`rm\` 命令是：**
   - A. \`rm -r directory_name\`
   - B. \`rm -f directory_name\`
   - C. \`rm -d directory_name\`
   - D. \`rm -rf directory_name\`

### 问答题

1. **解释 \`ls -la\` 命令的输出结果包含哪些信息。**

2. **使用 \`rm\` 命令删除文件时，如果文件不存在，会发生什么？**

3. **如何使用 \`ls\` 命令列出某个特定目录的内容，而不是当前目录？**

4. **如果你想在删除文件之前确认操作，使用 \`rm\` 命令时应使用哪个选项？**

5. **在 Linux 中，如何同时删除多个文件或目录？请给出示例。**

### 答案

**选择题答案：** A, B, B, B, D

**问答题**
   1. \`ls -la\` 命令会列出当前目录下的所有文件和目录，包括隐藏文件。输出结果包含每个文件的权限、链接数、文件所有者、文件所属组、文件大小、最后修改时间以及文件名。
   2. 使用 \`rm\` 命令删除一个不存在的文件时，会出现一条错误消息，提示该文件不存在，但不会删除任何其他文件。
   3. 可以使用命令 \`ls /path/to/directory\` 列出指定目录的内容。例如，\`ls /home/user\` 将列出 \`/home/user\` 目录下的所有文件和目录。
   4. 可以使用 \`rm -i\` 选项来在删除文件之前进行确认。这会在删除每个文件之前提示用户确认操作。
   5. 可以使用 \`rm file1.txt file2.txt directory_name\` 删除多个文件和目录。也可以使用通配符，例如 \`rm *.txt\` 来删除当前目录下所有 \`.txt\` 文件。

`,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
