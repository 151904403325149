<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## udevadm 命令详解

\`udevadm\` 是 Linux 系统中用于管理 \`udev\` 设备管理系统的命令行工具。\`udev\` 是 Linux 内核的设备管理子系统，它动态管理 \`/dev\` 目录中的设备文件。通过 \`udevadm\` 命令，系统管理员可以监控、查询和调试设备事件，以及与内核设备管理相关的任务。

本篇文章将详细介绍 \`udevadm\` 命令的常用功能、参数，并展示其在设备管理中的实用性。

### 1. \`udevadm\` 命令概述
\`udevadm\` 允许系统管理员与设备管理系统交互，进行设备事件的监控、设备属性查询、管理和测试规则等操作。

#### 命令格式：
\`\`\`bash
udevadm [选项] 子命令
\`\`\`

子命令主要有 \`info\`、\`trigger\`、\`control\`、\`monitor\` 和 \`settle\`，每个子命令用于不同的功能场景。

### 2. 常用子命令与选项
#### 2.1 \`udevadm info\` - 查询设备信息
\`udevadm info\` 子命令用于查询设备的详细信息，包括设备的 \`udev\` 属性、驱动、路径等。

#### 示例命令：
\`\`\`bash
udevadm info --query=all --name=/dev/sda
\`\`\`
**输出：**
\`\`\`
P: /devices/pci0000:00/0000:00:17.0/ata1/host0/target0:0:0/0:0:0:0/block/sda
N: sda
S: disk/by-id/ata-VBOX_HARDDISK_VB4f7b0e5b-686a824b
S: disk/by-path/pci-0000:00:17.0-ata-1
E: DEVLINKS=/dev/disk/by-id/ata-VBOX_HARDDISK_VB4f7b0e5b-686a824b ...
\`\`\`
**解读：**
此输出展示了 \`/dev/sda\` 设备的详细属性，包含设备路径、符号链接、名称等信息。系统管理员可以通过该信息排查设备问题。

#### 2.2 \`udevadm monitor\` - 监控设备事件
\`udevadm monitor\` 子命令允许实时监控设备的插入、拔出和其他事件，非常适合调试设备的动态变化。

#### 示例命令：
\`\`\`bash
udevadm monitor
\`\`\`
**输出：**
\`\`\`
KERNEL[3413.123456] add      /devices/pci0000:00/0000:00:14.0/usb1/1-1 (usb)
UDEV  [3413.123789] add      /devices/pci0000:00/0000:00:14.0/usb1/1-1 (usb)
\`\`\`
**解读：**
当插入或移除设备时，\`udevadm monitor\` 会输出设备相关的事件日志。这对于调试 USB 设备插入或硬盘添加等事件尤为有用。

#### 2.3 \`udevadm trigger\` - 手动触发设备事件
\`udevadm trigger\` 可以触发设备事件，例如重新加载设备驱动或重新应用 \`udev\` 规则。

#### 示例命令：
\`\`\`bash
udevadm trigger --action=add
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
该命令手动触发设备的 \`add\` 事件，系统重新识别设备。

#### 2.4 \`udevadm settle\` - 等待设备事件处理
\`udevadm settle\` 命令用于等待所有设备事件处理完毕，通常用于确保所有设备初始化完成后再执行其他操作。

#### 示例命令：
\`\`\`bash
udevadm settle
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
该命令等待所有设备事件处理完成后，才返回命令提示符。

### 3. 常见选项
#### 3.1 \`--name\` 选项
\`--name\` 参数指定设备文件名称，配合 \`info\` 子命令用于查询设备信息。

#### 示例命令：
\`\`\`bash
udevadm info --name=/dev/sr0
\`\`\`
**输出：**
\`\`\`
P: /devices/pci0000:00/0000:00:17.0/ata2/host1/target1:0:0/1:0:0:0/block/sr0
N: sr0
E: DEVNAME=/dev/sr0
E: DEVTYPE=disk
E: ID_SERIAL=VBOX_CD-ROM_VB2-01700376
\`\`\`
**解读：**
该命令返回 \`/dev/sr0\` 的详细设备信息，帮助用户快速定位设备。

#### 3.2 \`--query\` 选项
\`--query\` 参数用于指定查询设备信息的类型。常见的值包括 \`name\`、\`path\`、\`property\` 等。

#### 示例命令：
\`\`\`bash
udevadm info --query=name --name=/dev/sda
\`\`\`
**输出：**
\`\`\`
sda
\`\`\`
**解读：**
该命令只返回设备的名称，简化了设备信息输出。

### 4. 安装方法
\`udevadm\` 是 Linux 系统的一部分，通常随系统内核一起安装，不需要单独安装。但是在某些精简版系统中，可能需要手动安装。

#### Ubuntu 系统：
\`\`\`bash
sudo apt install udev
\`\`\`

#### RHEL/CentOS 系统：
\`\`\`bash
sudo yum install systemd-udev
\`\`\`

### 5. \`udevadm\` 版本差异
\`udevadm\` 在不同系统版本中可能存在细微的差异。例如，早期版本的 \`udevadm\` 可能不支持某些高级功能，但基本操作在大多数系统中保持一致。

#### 示例命令：
\`\`\`bash
udevadm --version
\`\`\`
**输出：**
\`\`\`
247
\`\`\`
**解读：**
该输出展示了当前系统使用的 \`udevadm\` 版本，版本号为 247。

### 6. 使用 root 权限的 \`udevadm\`
某些操作，例如重新加载设备规则或手动触发设备事件，可能需要 root 权限。

#### 示例命令：
\`\`\`bash
sudo udevadm control --reload-rules
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令重新加载了 \`udev\` 规则，确保最新的设备配置生效。

### 7. 多用户场景下的 \`udevadm\`
在多用户系统中，管理员可以使用 \`udevadm\` 查询或监控不同用户的设备状态。例如，查看 \`dev01\` 用户的设备状态：

#### 示例命令：
\`\`\`bash
sudo -u dev01 udevadm info --query=all --name=/dev/sda
\`\`\`
**输出：**
\`\`\`
P: /devices/pci0000:00/0000:00:17.0/ata1/host0/target0:0:0/0:0:0:0/block/sda
N: sda
S: disk/by-id/ata-VBOX_HARDDISK_VB4f7b0e5b-686a824b
\`\`\`
**解读：**
此命令以 \`dev01\` 用户的身份查看 \`/dev/sda\` 的详细设备信息。

### 结论
\`udevadm\` 是 Linux 系统设备管理的重要工具，能够查询设备属性、监控设备事件、触发设备事件等操作。系统管理员应熟练掌握 \`udevadm\`，以便高效地管理设备和调试系统。在多用户和多设备环境中，\`udevadm\` 可以帮助迅速定位和解决设备相关问题。

        `,
    };
  },
});
</script>
<style scoped></style>
