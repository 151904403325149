<template>
  <div class="top-container">
    <div style="display: flex; justify-content: flex-end">
      <!-- <el-tooltip content="点击图片可听到发音" placement="left"> -->
      <el-button @click="refreshWords" plain class="refresh-button">
        刷新其他词汇
      </el-button>
      <!-- </el-tooltip> -->
    </div>
    <div class="card-container">
      <div class="card" v-for="word in words" :key="word.id" :ref="word.id">
        <el-image
          :src="word.image"
          fit="contain"
          class="card-image"
          @click="handleCardClick(word)"
        />
        <div class="card-content">
          <h2 class="word">{{ word.word }}</h2>
          <p class="ipa">{{ word.ipa }}</p>
          <p class="english">{{ word.english }}</p>
          <p class="chinese">{{ word.chinese }}</p>
          <p class="sentence">
            <span class="sen">sentence: </span>{{ word.sentence }}
          </p>
          <p class="sentence_cn">
            <span class="sen">例句:</span> {{ word.sentence_cn }}
          </p>
        </div>
      </div>
    </div>
    <OpsArticles />
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage, ElImage, ElButton } from "element-plus";
import OpsArticles from "./base/OpsArticles.vue";

export default {
  name: "OpsEngReading",
  components: {
    ElImage,
    OpsArticles,
    ElButton,
  },
  data() {
    return {
      words: [],
    };
  },
  mounted() {
    this.fetchWords();
  },
  methods: {
    fetchWords() {
      axios
        .get("http://sre.azlinux.com:5050/get_words")
        .then((response) => {
          this.words = response.data.map((word) => ({
            ...word,
            word: word.word.trim(),
            ipa: word.ipa.trim(),
            english: word.english.trim(),
            chinese: word.chinese.trim(),
            sound: word.sound.trim(),
            image: word.image.trim(),
            sentence: word.sentence.trim(),
            sentence_cn: word.sentence_cn.trim(),
          }));
        })
        .catch((error) => {
          ElMessage.error("网络通信异常！");
          console.error(error);
        });
    },
    refreshWords() {
      this.fetchWords();
      // ElMessage.success("词汇已刷新！");
    },
    handleCardClick(word) {
      console.log(word);
      const audio = new Audio(word.sound);
      audio.play().catch((error) => {
        ElMessage.error("无法播放音频！");
        console.error(error);
      });
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 6px;
  margin-bottom: 6px;
}

h2 {
  margin-bottom: 0.8rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin: 0.6rem;
  transition: transform 0.2s ease-in-out;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.card-image {
  max-width: 360px;
  max-height: 360px;
  margin-right: 1rem;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}

.card-content {
  text-align: left;
}

.word {
  font-size: 1.5rem;
}

.card-image:hover {
  transform: scale(1.05);
}

.sen {
  color: #007bff;
  font-weight: bold;
}

/* 新增的刷新按钮样式 */
.refresh-button {
  margin: 8px 0.6rem 0px 0.6rem;
}
</style>
