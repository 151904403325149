<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## adduser 命令详解

\`adduser\`命令是Linux系统中用于创建新用户的命令。它是\`useradd\`命令的一个更高层的工具，提供了更友好的用户交互界面。使用\`adduser\`可以轻松地创建用户账户并设置相应的用户环境。本文将详细介绍\`adduser\`命令的用法、常用参数，并通过实际例子演示其应用及输出结果。

### 基本用法

\`adduser\`命令的基本语法为：

\`\`\`bash
sudo adduser [选项] 用户名
\`\`\`

其中，\`用户名\`是要创建的新用户的名称。以下是创建一个名为\`john\`的新用户的示例：

\`\`\`bash
sudo adduser john
\`\`\`

**示例输出：**

\`\`\`plaintext
Adding user \`john' ...
Adding new group \`john' (1001) ...
Adding new user \`john' (1001) with group \`john' ...
Creating home directory \`/home/john' ...
Copying files from \`/etc/skel' ...
Enter new UNIX password:
Retype new UNIX password:
passwd: password updated successfully
Changing the user information for john
Enter the new value, or press ENTER for the default
        Full Name []: John Doe
        Room Number []: 101
        Work Phone []: 1234
        Home Phone []: 5678
        Other []:
Is the information correct? [Y/n] y
\`\`\`

**解读：** 该命令输出详细显示了\`adduser\`命令的各个步骤，包括创建用户、用户组、用户家目录以及设置用户密码等。用户需要输入两次密码，并可以为新用户提供额外的信息，如全名、房间号、工作电话等。

### 创建用户并指定用户组

可以使用\`--ingroup\`参数为新用户指定一个现有的用户组。例如，将新用户\`john\`加入到\`sre-group\`组：

\`\`\`bash
sudo adduser --ingroup sre-group john
\`\`\`

**示例输出：**

\`\`\`plaintext
Adding user \`john' ...
Adding new user \`john' (1001) with group \`sre-group' ...
Creating home directory \`/home/john' ...
Copying files from \`/etc/skel' ...
Enter new UNIX password:
Retype new UNIX password:
passwd: password updated successfully
Changing the user information for john
Enter the new value, or press ENTER for the default
        Full Name []: John Doe
        Room Number []: 101
        Work Phone []: 1234
        Home Phone []: 5678
        Other []:
Is the information correct? [Y/n] y
\`\`\`

**解读：** 此命令执行后，\`john\`用户将会被添加到\`sre-group\`组中，所有其他步骤与基本用法相同。

### 查看新创建用户的信息

创建用户后，可以使用\`id\`命令查看新用户的相关信息，确认用户是否正确创建以及用户组是否正确设置。

\`\`\`bash
id john
\`\`\`

**示例输出：**

\`\`\`plaintext
uid=1001(john) gid=1005(sre-group) groups=1005(sre-group)
\`\`\`

**解读：** 输出显示了\`john\`用户的用户ID（\`uid=1001\`）、主要组ID（\`gid=1005\`）以及所属组\`(sre-group)\`。从输出可以确认，\`john\`用户已经成功加入\`sre-group\`组。

### 删除用户

要删除一个用户及其家目录，可以使用\`deluser\`命令。以下是删除\`john\`用户的示例：

\`\`\`bash
sudo deluser --remove-home john
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后无输出，表示用户\`john\`及其家目录已被成功删除。可以通过\`ls\`命令检查是否删除成功。

\`\`\`bash
ls /home/john
\`\`\`

**示例输出：**

\`\`\`plaintext
ls: cannot access '/home/john': No such file or directory
\`\`\`

**解读：** 输出提示\`/home/john\`目录不存在，说明用户家目录已成功删除。

### 创建系统用户

使用\`--system\`参数，可以创建一个系统用户。系统用户一般不用于交互登录，多用于系统服务账户。

\`\`\`bash
sudo adduser --system sysuser
\`\`\`

**示例输出：**

\`\`\`plaintext
Adding system user \`sysuser' (UID 108) ...
Adding new group \`sysuser' (GID 114) ...
Adding new user \`sysuser' (UID 108) with group \`sysuser' ...
Creating home directory \`/home/sysuser' ...
\`\`\`

**解读：** 系统用户\`sysuser\`已成功创建，并分配了一个特定的UID和GID。系统用户通常没有密码，不会提示输入密码。

### 总结

\`adduser\`命令是Linux中用于创建用户的强大工具。通过合理使用其选项，可以方便地创建适合不同需求的用户账户。无论是普通用户、加入特定用户组的用户，还是系统用户，\`adduser\`命令都能提供灵活的解决方案。同时，通过结合\`id\`、\`ls\`等命令，可以验证用户创建的正确性，确保系统管理的安全性和高效性。

        `,
    };
  },
});
</script>
<style scoped></style>
