<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## if (bash) 命令详解

\`if\` 命令是 Bash 中用于条件判断的重要工具，广泛用于脚本编写中。它允许用户根据特定条件执行不同的命令或代码块，是编写控制结构的基础。本文将详细介绍 \`if\` 命令的使用方法、重要参数及其输出结果。

### if 是 bash 内置命令还是外部命令？

\`if\` 是 Bash 的内置命令，专门用于条件判断。在 Bash 中，\`if\` 命令并不作为外部程序存在，而是脚本执行中的一部分。

### 基本语法

\`if\` 命令的基本语法如下：

\`\`\`bash
if [ CONDITION ]; then
    # 如果条件为真则执行的命令
else
    # 如果条件为假则执行的命令
fi
\`\`\`

### 重要参数及示例

#### 1. \`[ ]\`：条件测试的标志

在 Bash 中，\`[\` 是 \`test\` 命令的别名，用于条件判断。

\`\`\`bash
num=10
if [ "$num" -eq 10 ]; then
    echo "num 等于 10"
fi
# 输出：
# num 等于 10
\`\`\`

#### 2. \`-eq\`：等于，用于数字比较

用于判断两个数是否相等。

\`\`\`bash
a=5
b=5
if [ "$a" -eq "$b" ]; then
    echo "a 等于 b"
fi
# 输出：
# a 等于 b
\`\`\`

#### 3. \`-ne\`：不等于，用于数字比较

用于判断两个数是否不相等。

\`\`\`bash
a=5
b=10
if [ "$a" -ne "$b" ]; then
    echo "a 不等于 b"
fi
# 输出：
# a 不等于 b
\`\`\`

#### 4. \`-lt\`：小于，用于数字比较

用于判断一个数是否小于另一个数。

\`\`\`bash
a=5
b=10
if [ "$a" -lt "$b" ]; then
    echo "a 小于 b"
fi
# 输出：
# a 小于 b
\`\`\`

#### 5. \`-gt\`：大于，用于数字比较

用于判断一个数是否大于另一个数。

\`\`\`bash
a=10
b=5
if [ "$a" -gt "$b" ]; then
    echo "a 大于 b"
fi
# 输出：
# a 大于 b
\`\`\`

#### 6. \`-le\`：小于等于，用于数字比较

用于判断一个数是否小于等于另一个数。

\`\`\`bash
a=5
b=5
if [ "$a" -le "$b" ]; then
    echo "a 小于等于 b"
fi
# 输出：
# a 小于等于 b
\`\`\`

#### 7. \`-ge\`：大于等于，用于数字比较

用于判断一个数是否大于等于另一个数。

\`\`\`bash
a=10
b=5
if [ "$a" -ge "$b" ]; then
    echo "a 大于等于 b"
fi
# 输出：
# a 大于等于 b
\`\`\`

#### 8. \`-d\`：检查是否为目录

用于判断指定的路径是否为目录。

\`\`\`bash
dir="/tmp"

if [ -d "$dir" ]; then
    echo "$dir 是一个目录"
fi
# 输出：
# /tmp 是一个目录
\`\`\`

#### 9. \`-f\`：检查是否为文件

用于判断指定的路径是否为文件。

\`\`\`bash
file="test.txt"

if [ -f "$file" ]; then
    echo "$file 是一个文件"
else
    echo "$file 不是一个文件"
fi
# 输出：
# test.txt 不是一个文件
\`\`\`

### 示例：逻辑运算符

我们可以结合逻辑运算符使用 \`if\` 命令，例如使用 \`-a\`（与）和 \`-o\`（或）。

\`\`\`bash
var1=5
var2=10

if [ "$var1" -lt 10 -a "$var2" -gt 5 ]; then
    echo "条件成立"
else
    echo "条件不成立"
fi
# 输出：
# 条件成立
\`\`\`

### 不同架构下的执行结果

\`if\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致。无论在哪种架构下，\`if\` 命令的功能和输出均相同。

### 版本差异

\`if\` 命令作为 Bash 的内置命令，在不同版本的 Bash 中，其基本语法和功能保持一致。可能在不同的 Bash 版本中，处理特定条件的方式有所不同，但大多数基本用法不受影响。

### 安装

由于 \`if\` 命令是 Bash 的内置功能，因此无需单独安装。只要在 Linux 系统中安装了 Bash，就可以使用 \`if\` 命令。

### 使用root权限的场景

一般情况下，\`if\` 命令本身不需要特权权限，但在一些情况下，如果 \`if\` 命令用于检查需要权限的文件或执行需要权限的命令时，可能需要使用 \`sudo\`。

\`\`\`bash
if [ -f "/etc/passwd" ]; then
    echo "/etc/passwd 文件存在"
else
    echo "/etc/passwd 文件不存在"
fi
# 输出：
# /etc/passwd 文件存在
\`\`\`

### 总结

\`if\` 命令是 Bash 中不可或缺的条件判断工具，能够让用户根据不同的条件执行不同的命令。掌握 \`if\` 命令的用法对于编写复杂的 Bash 脚本至关重要。如果你想在 Bash 脚本中实现逻辑控制，了解 \`if\` 命令的使用是基础。

        `,
    };
  },
});
</script>
<style scoped></style>
