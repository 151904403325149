<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## iptables 命令详解

\`iptables\` 是 Linux 系统中管理网络流量的防火墙工具，允许管理员设置、修改和检查系统的网络流量过滤规则。它通过定义不同的规则链（chains）和规则（rules），控制流入、流出及系统内部的数据包。\`iptables\` 常用于防护服务器、限制端口访问以及管理网络流量。本文将详细介绍 \`iptables\` 的常见用法及重要参数。

### 1. 安装 \`iptables\`

在大多数 Linux 发行版上，\`iptables\` 已经默认安装。如果没有安装，可以通过以下命令进行安装。

#### 在 Ubuntu 上安装 \`iptables\`

\`\`\`bash
sudo apt install iptables
\`\`\`

#### 在 RHEL 或 CentOS 上安装 \`iptables\`

\`\`\`bash
sudo yum install iptables
\`\`\`

安装完成后，可以使用 \`iptables --version\` 来验证安装。

输出:
\`\`\`bash
iptables v1.8.4 (nf_tables)
\`\`\`

### 2. 查看当前规则

查看当前所有的防火墙规则，可以使用以下命令：

\`\`\`bash
sudo iptables -L
\`\`\`

输出:
\`\`\`bash
Chain INPUT (policy ACCEPT)
target     prot opt source               destination         

Chain FORWARD (policy ACCEPT)
target     prot opt source               destination         

Chain OUTPUT (policy ACCEPT)
target     prot opt source               destination         
\`\`\`

此时，所有链（\`INPUT\`、\`FORWARD\` 和 \`OUTPUT\`）都是 \`ACCEPT\`，表示没有阻止任何流量。

### 3. 添加规则

向 \`iptables\` 添加规则时，常用的目标是 \`ACCEPT\` 和 \`DROP\`，用于分别允许或拒绝流量。

#### 允许指定端口的流量

比如，我们想允许 TCP 端口 80 上的 HTTP 流量，可以使用以下命令：

\`\`\`bash
sudo iptables -A INPUT -p tcp --dport 80 -j ACCEPT
\`\`\`

此时可以通过以下命令再次查看 \`iptables\` 规则，确认是否已成功添加：

\`\`\`bash
sudo iptables -L
\`\`\`

输出:
\`\`\`bash
Chain INPUT (policy ACCEPT)
target     prot opt source               destination         
ACCEPT     tcp  --  anywhere             anywhere             tcp dpt:http

Chain FORWARD (policy ACCEPT)
target     prot opt source               destination         

Chain OUTPUT (policy ACCEPT)
target     prot opt source               destination         
\`\`\`

此规则表示允许所有来源的 TCP 80 端口流量。

#### 拒绝指定端口的流量

如果我们希望拒绝 22 号端口（SSH）的访问，可以使用以下命令：

\`\`\`bash
sudo iptables -A INPUT -p tcp --dport 22 -j DROP
\`\`\`

再次查看规则后会发现：

\`\`\`bash
sudo iptables -L
\`\`\`

输出:
\`\`\`bash
Chain INPUT (policy ACCEPT)
target     prot opt source               destination         
ACCEPT     tcp  --  anywhere             anywhere             tcp dpt:http
DROP       tcp  --  anywhere             anywhere             tcp dpt:ssh
\`\`\`

### 4. 删除规则

删除一条规则需要先确定其编号。可以通过 \`-L --line-numbers\` 参数查看规则编号：

\`\`\`bash
sudo iptables -L --line-numbers
\`\`\`

输出:
\`\`\`bash
Chain INPUT (policy ACCEPT)
num  target     prot opt source               destination         
1    ACCEPT     tcp  --  anywhere             anywhere             tcp dpt:http
2    DROP       tcp  --  anywhere             anywhere             tcp dpt:ssh
\`\`\`

我们可以删除第二条规则（禁止 SSH 访问）：

\`\`\`bash
sudo iptables -D INPUT 2
\`\`\`

此时，再次查看规则，确认删除成功。

### 5. 保存规则

\`iptables\` 的规则并不会在重启后自动保存，需要手动保存规则。不同的 Linux 发行版有不同的保存方法。

#### Ubuntu

\`\`\`bash
sudo iptables-save > /etc/iptables/rules.v4
\`\`\`

#### RHEL/CentOS

\`\`\`bash
sudo service iptables save
\`\`\`

### 6. 重置规则

如果希望重置所有现有的规则，可以使用 \`iptables -F\` 来清空所有链的规则：

\`\`\`bash
sudo iptables -F
\`\`\`

【无输出，命令已成功执行】

可以再次使用 \`iptables -L\` 查看规则，确认所有规则已清空。

### 7. NAT 规则

\`iptables\` 也可以用来进行网络地址转换 (NAT)。下面是一个简单的示例，展示如何将外部流量转发到内部地址：

\`\`\`bash
sudo iptables -t nat -A PREROUTING -p tcp --dport 8080 -j DNAT --to-destination 192.168.1.100:80
\`\`\`

此命令会将所有到达 8080 端口的流量转发到内网的 192.168.1.100:80 上。

查看 NAT 表中的规则：

\`\`\`bash
sudo iptables -t nat -L
\`\`\`

输出:
\`\`\`bash
Chain PREROUTING (policy ACCEPT)
target     prot opt source               destination         
DNAT       tcp  --  anywhere             anywhere             tcp dpt:8080 to:192.168.1.100:80
\`\`\`

### 8. 不同架构下的表现

\`iptables\` 在 x86_64 和 aarch64 架构下的表现并没有明显差异，命令和输出一致。

### 9. 替代命令

在现代 Linux 系统中，\`iptables\` 正逐步被 \`nftables\` 所取代。\`nftables\` 提供了更简洁的语法和更高效的包过滤能力。可以使用以下命令查看 \`nftables\` 的版本：

\`\`\`bash
sudo nft --version
\`\`\`

输出:
\`\`\`bash
nftables v0.9.3 (Topsy)
\`\`\`

如果需要在 RHEL/CentOS 8 以上系统中使用 \`nftables\`，可以通过以下命令进行管理：

\`\`\`bash
sudo nft add rule ip filter input tcp dport 80 accept
\`\`\`

### 10. root 权限的使用

\`iptables\` 需要以 root 权限运行。如果没有 root 权限，可以使用 \`sudo\` 命令。例如：

\`\`\`bash
sudo iptables -A INPUT -p tcp --dport 80 -j ACCEPT
\`\`\`

此命令会以 root 权限将 HTTP 流量规则添加到 \`INPUT\` 链中。

### 总结

\`iptables\` 是 Linux 系统中强大且灵活的防火墙工具，允许管理员设置复杂的网络流量过滤规则。虽然它已经逐步被 \`nftables\` 取代，但在许多系统中，\`iptables\` 依然是主要的防火墙管理工具。通过本教程，你可以掌握 \`iptables\` 的基本用法和常见操作。
        `,
    };
  },
});
</script>
<style scoped></style>
