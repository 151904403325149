<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## timeout 命令详解

\`timeout\` 是一个用于在指定时间内限制其他命令执行时间的工具。它是 GNU Core Utilities 的一部分，在大多数 Linux 系统中默认安装。这个命令可以非常有效地防止长时间运行的进程占用系统资源，尤其是在处理批量任务或自动化脚本时。

### 基本语法

\`\`\`bash
timeout [OPTION] DURATION COMMAND [ARGUMENTS...]
\`\`\`

- \`DURATION\`：指定命令的最大运行时间。可以使用秒（默认），或者指定其他单位如分钟（m）、小时（h）等。
- \`COMMAND\`：要执行的命令及其参数。

### 常用参数

- \`-s, --signal=SIGNAL\`：指定在超时后发送的信号。默认发送 \`SIGTERM\` 信号。如果指定 \`-s KILL\`，将发送 \`SIGKILL\`。
- \`-k, --kill-after=DURATION\`：在超时后，等待 \`DURATION\` 指定的时间再发送 \`SIGKILL\` 信号。如果 \`DURATION\` 为零，则直接发送 \`SIGKILL\`。
- \`--preserve-status\`：在超时后保留命令的退出状态码，默认情况下超时会使命令的退出状态码为 124。

### 示例用法

#### 1. 基本用法

限制 \`sleep\` 命令的执行时间为 5 秒。如果 \`sleep\` 命令运行超过这个时间，它将被终止。

\`\`\`bash
$ timeout 5 sleep 10
\`\`\`

**输出示例：**

\`\`\`plaintext
^C
\`\`\`

*解释：* \`sleep\` 命令在 5 秒后被终止，输出 \`^C\` 表示命令被中断。

#### 2. 使用自定义信号

使用 \`-s\` 参数指定在超时后发送 \`SIGTERM\` 信号。

\`\`\`bash
$ timeout -s TERM 5 sleep 10
\`\`\`

**输出示例：**

\`\`\`plaintext
^C
\`\`\`

*解释：* \`sleep\` 命令在 5 秒后被终止，输出 \`^C\` 表示命令被中断。

#### 3. 使用 \`--kill-after\` 选项

设置在 \`sleep\` 超时后，等待 2 秒再发送 \`SIGKILL\`。

\`\`\`bash
$ timeout -k 2 5 sleep 10
\`\`\`

**输出示例：**

\`\`\`plaintext
^C
\`\`\`

*解释：* \`sleep\` 命令在 5 秒后被 \`SIGTERM\` 终止，如果进程没有退出，2 秒后发送 \`SIGKILL\`。

#### 4. 保留退出状态码

使用 \`--preserve-status\` 选项保留命令的退出状态码。

\`\`\`bash
$ timeout --preserve-status 5 sleep 10
echo $?
\`\`\`

**输出示例：**

\`\`\`plaintext
124
\`\`\`

*解释：* \`sleep\` 命令在 5 秒后被终止，\`echo $?\` 显示命令的退出状态码为 124，表示超时。

### 注意事项

- \`timeout\` 命令并不适用于所有 Linux 发行版，特别是较旧的系统。可以通过安装 \`coreutils\` 包来获取 \`timeout\`。
- 在不同版本的 \`timeout\` 中，可能会有轻微的差异，尤其是在信号处理和退出状态码方面。

### 安装

在 Ubuntu 和 RHEL 系统中，\`timeout\` 命令通常包含在 \`coreutils\` 包中：

- **Ubuntu**：
  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**：
  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 相关命令

- \`kill\`：用于向进程发送信号，\`timeout\` 使用类似的机制来处理超时。
- \`wait\`：用于等待后台进程完成，与 \`timeout\` 一起使用可以更好地管理进程。

### 总结

\`timeout\` 命令是一个强大的工具，用于控制进程的最大运行时间。通过合适的参数设置，能够灵活地处理超时任务，避免长时间运行的进程对系统资源造成影响。

        `,
    };
  },
});
</script>
<style scoped></style>
