<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## dig 命令详解

\`dig\`（Domain Information Groper）是一个强大的网络工具，用于查询 DNS（域名系统）记录。它可以提供域名解析的详细信息，包括 A 记录、CNAME 记录、MX 记录等。\`dig\` 是网络故障排除、网络测试和域名管理中不可或缺的工具。

### 主要参数和选项

#### 1. 基本用法

\`\`\`bash
dig example.com
\`\`\`

**示例输出**

\`\`\`plaintext
; <<>> DiG 9.16.1-Ubuntu <<>> example.com
;; global options: +cmd
;; Got answer:
;; ->>HEADER<<- opcode: QUERY, status: NOERROR, id: 12345
;; flags: qr rd ra; QUERY: 1, ANSWER: 1, AUTHORITY: 0, ADDITIONAL: 1

;; QUESTION SECTION:
;example.com.                   IN      A

;; ANSWER SECTION:
example.com.            3600    IN      A       93.184.216.34

;; Query time: 35 msec
;; SERVER: 8.8.8.8#53(8.8.8.8)
;; WHEN: Mon Sep 10 12:34:56 UTC 2024
;; MSG SIZE  rcvd: 56
\`\`\`

**输出解读**:

- \`;; QUESTION SECTION:\` 显示了查询的域名及其查询类型。
- \`;; ANSWER SECTION:\` 显示了查询的结果，包括 IP 地址和 TTL（生存时间）。
- \`;; SERVER:\` 显示了处理查询的 DNS 服务器。
- \`;; Query time:\` 显示了查询的响应时间。

#### 2. 查询特定记录类型

- **A 记录**（IPv4 地址）:

  \`\`\`bash
  dig example.com A
  \`\`\`

- **MX 记录**（邮件交换记录）:

  \`\`\`bash
  dig example.com MX
  \`\`\`

- **NS 记录**（域名服务器记录）:

  \`\`\`bash
  dig example.com NS
  \`\`\`

**示例输出**

\`\`\`plaintext
; <<>> DiG 9.16.1-Ubuntu <<>> example.com MX
;; global options: +cmd
;; Got answer:
;; ->>HEADER<<- opcode: QUERY, status: NOERROR, id: 54321
;; flags: qr rd ra; QUERY: 1, ANSWER: 2, AUTHORITY: 0, ADDITIONAL: 1

;; QUESTION SECTION:
;example.com.                   IN      MX

;; ANSWER SECTION:
example.com.            3600    IN      MX      10 mail.example.com.
example.com.            3600    IN      MX      20 backupmail.example.com.

;; Query time: 32 msec
;; SERVER: 8.8.8.8#53(8.8.8.8)
;; WHEN: Mon Sep 10 12:45:56 UTC 2024
;; MSG SIZE  rcvd: 78
\`\`\`

**输出解读**:

- \`;; ANSWER SECTION:\` 显示了邮件交换服务器的主机名和优先级。

#### 3. 查询特定 DNS 服务器

使用 \`@\` 选项指定 DNS 服务器。例如，查询 \`example.com\` 使用 Google 的 DNS 服务器（8.8.8.8）:

\`\`\`bash
dig @8.8.8.8 example.com
\`\`\`

**示例输出**

\`\`\`plaintext
; <<>> DiG 9.16.1-Ubuntu <<>> @8.8.8.8 example.com
;; global options: +cmd
;; Got answer:
;; ->>HEADER<<- opcode: QUERY, status: NOERROR, id: 65432
;; flags: qr rd ra; QUERY: 1, ANSWER: 1, AUTHORITY: 0, ADDITIONAL: 1

;; QUESTION SECTION:
;example.com.                   IN      A

;; ANSWER SECTION:
example.com.            3600    IN      A       93.184.216.34

;; Query time: 23 msec
;; SERVER: 8.8.8.8#53(8.8.8.8)
;; WHEN: Mon Sep 10 12:55:56 UTC 2024
;; MSG SIZE  rcvd: 56
\`\`\`

**输出解读**:

- \`;; SERVER:\` 显示了用于查询的 DNS 服务器。

#### 4. 调试输出

使用 \`+trace\` 选项可以查看 DNS 查询的整个过程。

\`\`\`bash
dig +trace example.com
\`\`\`

**示例输出**

\`\`\`plaintext
; <<>> DiG 9.16.1-Ubuntu <<>> +trace example.com
;; global options: +cmd
.                       3600    IN      NS      a.root-servers.net.
.                       3600    IN      NS      b.root-servers.net.
.                       3600    IN      NS      c.root-servers.net.
...
com.                    172800  IN      NS      a.gtld-servers.net.
com.                    172800  IN      NS      b.gtld-servers.net.
...
example.com.            3600    IN      A       93.184.216.34
\`\`\`

**输出解读**:

- 显示了从根 DNS 服务器到目标域名的完整查询路径。

### 内置命令与外部命令

\`dig\` 是一个外部命令，通常位于 \`/usr/bin/dig\` 路径下。它不是 Bash 内置命令。Bash 内置命令通常是用来管理 Shell 环境的工具，例如 \`cd\`、\`echo\` 等。

### 不同架构下的输出

\`dig\` 命令在不同架构下的输出格式通常是一致的，因为它主要依赖于 DNS 协议。不过，系统的 DNS 配置和网络环境可能会影响查询的结果。

### 版本差异

\`dig\` 是 GNU BIND 套件的一部分，版本不同可能会影响命令的输出格式和支持的选项。新版本可能会增加新特性或修复错误。

### 替代命令

- \`nslookup\`：另一个用于查询 DNS 记录的工具，提供了类似的功能。
- \`host\`：用于 DNS 查询的简单工具，功能较为基础。

### 安装

\`dig\` 命令通常包含在 BIND 工具包中。如果系统中未安装，可以使用以下命令进行安装：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install dnsutils
  \`\`\`

- **RHEL**:

  \`\`\`bash
  sudo yum install bind-utils
  \`\`\`

### 需要 root 权限的选项

\`dig\` 命令本身不需要 root 权限，但在一些特殊情况下，如使用某些 DNS 服务器或网络配置，可能需要管理员权限。

**示例**

\`\`\`bash
sudo dig example.com
\`\`\`

**示例输出**:

\`\`\`plaintext
; <<>> DiG 9.16.1-Ubuntu <<>> example.com
;; global options: +cmd
;; Got answer:
;; ->>HEADER<<- opcode: QUERY, status: NOERROR, id: 12345
;; flags: qr rd ra; QUERY: 1, ANSWER: 1, AUTHORITY: 0, ADDITIONAL: 1

;; QUESTION SECTION:
;example.com.                   IN      A

;; ANSWER SECTION:
example.com.            3600    IN      A       93.184.216.34

;; Query time: 35 msec
;; SERVER: 8.8.8.8#53(8.8.8.8)
;; WHEN: Mon Sep 10 12:34:56 UTC 2024
;; MSG SIZE  rcvd: 56
\`\`\`

### 总结

\`dig\` 是一个功能强大的 DNS 查询工具，适用于网络管理、故障排除和域名解析等任务。通过使用不同的参数和选项，用户可以获得详细的 DNS 记录信息和查询过程。了解 \`dig\` 的使用可以帮助用户更好地管理网络和解决 DNS 相关问题。

        `,
    };
  },
});
</script>
<style scoped></style>
