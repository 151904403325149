<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lscpu 命令详解

\`lscpu\` 是一个用来显示 CPU 架构信息的命令。它从 \`/proc/cpuinfo\` 文件读取数据，并提供有关 CPU 体系结构、核心、线程、架构类型等详细信息。这对于了解系统的硬件配置非常有用。

### 基本用法

要使用 \`lscpu\` 命令，只需在终端中输入：

\`\`\`bash
lscpu
\`\`\`

### 重要参数

\`lscpu\` 命令没有太多的参数，它主要用于显示系统的 CPU 信息。以下是一些常用的选项：

- \`-e\`, \`--extended\`：显示扩展信息，通常包括每个 CPU 的详细信息。
- \`-p\`, \`--parse\`：以逗号分隔的形式显示 CPU 信息，适合脚本处理。
- \`-s\`, \`--summary\`：只显示概要信息。

### 示例输出

#### 在 x86_64 架构下

假设你在一个 x86_64 架构的机器上运行 \`lscpu\` 命令，示例输出如下：

\`\`\`bash
$ lscpu
Architecture:                    x86_64
CPU op-mode(s):                  32-bit, 64-bit
Byte Order:                       Little Endian
CPU(s):                           4
On-line CPU(s) list:              0-3
Thread(s) per core:               2
Core(s) per socket:               2
Socket(s):                        1
NUMA node(s):                     1
Vendor ID:                        GenuineIntel
CPU family:                       6
Model:                            158
Model name:                       Intel(R) Core(TM) i5-8250U CPU @ 1.60GHz
Stepping:                         10
CPU MHz:                          2000.000
CPU max MHz:                      3400.0000
CPU min MHz:                      400.0000
BogoMIPS:                          4000.00
Virtualization:                   VT-x
L1d cache:                         32K
L1i cache:                         32K
L2 cache:                          256K
L3 cache:                          6144K
NUMA node0 CPU(s):                0-3
\`\`\`

#### 在 aarch64 架构下

假设你在一台使用 ARM 架构的服务器上运行 \`lscpu\` 命令，以下是一个可能的示例输出。这里选择了一款经典的 ARM64 服务器端 CPU —— Ampere Altra：

\`\`\`bash
$ lscpu
Architecture:                    aarch64
CPU op-mode(s):                  64-bit
Byte Order:                       Little Endian
CPU(s):                           48
On-line CPU(s) list:              0-47
Thread(s) per core:               1
Core(s) per socket:               24
Socket(s):                        2
NUMA node(s):                     2
Vendor ID:                        ARM
CPU family:                       8
Model:                            0
Model name:                       Ampere Altra
Stepping:                         0
CPU MHz:                          2400.000
CPU max MHz:                      3200.0000
CPU min MHz:                      800.0000
BogoMIPS:                          4800.00
Virtualization:                   VIRT
L1d cache:                         64K
L1i cache:                         64K
L2 cache:                          1024K
L3 cache:                          32768K
NUMA node0 CPU(s):                0-23
NUMA node1 CPU(s):                24-47
\`\`\`

### 版本差异

\`lscpu\` 命令的输出通常在不同版本中保持一致，但有时新的版本可能会添加额外的信息或格式略有变化。例如，新版本可能会增加关于 CPU 功耗或温度的信息。

### 安全性和替代命令

\`lscpu\` 是一个安全的命令，没有弃用的风险。它在 Linux 系统中广泛使用，通常没有替代命令。

### 安装

\`lscpu\` 命令通常包含在 \`util-linux\` 包中。如果你需要安装或重新安装它，可以使用以下命令：

- 在 Ubuntu 上：

    \`\`\`bash
    sudo apt-get install util-linux
    \`\`\`

- 在 RHEL 上：

    \`\`\`bash
    sudo yum install util-linux
    \`\`\`

### Root 权限

\`lscpu\` 命令不需要 root 权限即可运行。它只读取系统信息，而不涉及对系统的修改。

### 总结

\`lscpu\` 是一个有用的命令，用于获取详细的 CPU 架构信息。它提供了有关 CPU 体系结构、核心数量、线程等重要信息，并且简单易用。无论是在 x86_64 还是 aarch64 架构上，它都能帮助用户了解系统的硬件配置。

        `,
    };
  },
});
</script>
<style scoped></style>
