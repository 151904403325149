<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## groupadd 命令详解

\`groupadd\` 命令用于在 Linux 系统中创建新的用户组。通过创建用户组，系统管理员可以更好地管理用户的权限和访问控制。本文将详细介绍 \`groupadd\` 命令的使用方法、重要参数、示例以及注意事项。

### groupadd 是 bash 内置命令还是外部命令？

\`groupadd\` 是一个外部命令，通常位于 \`/usr/sbin/groupadd\`。它不是 Bash 的内置命令，因此在使用时需要确保有相应的权限。

### 基本语法

\`groupadd\` 命令的基本语法如下：

\`\`\`bash
groupadd [OPTION] GROUP_NAME
\`\`\`

### 重要参数及示例

#### 1. \`-g GID\`：指定组ID

使用 \`-g\` 选项可以指定新创建的用户组的组ID（GID）。如果不指定，系统会自动分配下一个可用的 GID。

\`\`\`bash
sudo groupadd -g 1001 devgroup
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

在此示例中，创建了名为 \`devgroup\` 的新用户组，GID 为 1001。

#### 2. \`-r\`：创建系统组

使用 \`-r\` 选项可以创建系统组，这通常用于系统服务或程序的组。

\`\`\`bash
sudo groupadd -r sysgroup
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

在此示例中，创建了一个名为 \`sysgroup\` 的系统组。

### 查看已创建的用户组

可以使用 \`getent\` 命令查看已创建的用户组：

\`\`\`bash
getent group devgroup
# 输出：
# devgroup:x:1001:
\`\`\`

这表明 \`devgroup\` 用户组已经成功创建，且其 GID 为 1001。

### 不同架构下的执行结果

\`groupadd\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，无论在哪种架构下，\`groupadd\` 命令的功能和输出均相同。

### 版本差异

在不同版本的 Linux 发行版中，\`groupadd\` 命令的基本用法保持一致，但可能存在一些特定选项的差异。一般来说，常用的选项在大多数版本中都是支持的。

### 安装

在大多数 Linux 发行版中，\`groupadd\` 命令已经默认安装。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install passwd
  \`\`\`
- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install shadow-utils
  \`\`\`

### 使用root权限的场景

\`groupadd\` 命令需要 root 权限才能执行，通常使用 \`sudo\` 提升权限。以下是使用 \`sudo\` 创建用户组的示例：

\`\`\`bash
sudo groupadd mygroup
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

### 总结

\`groupadd\` 命令是 Linux 系统中用于管理用户组的基本工具，通过它可以轻松创建新的用户组并分配 GID。掌握 \`groupadd\` 命令的使用对于系统管理员管理用户和权限至关重要。无论是在日常管理还是在脚本编写中，了解如何有效使用 \`groupadd\` 命令都是一项重要技能。

        `,
    };
  },
});
</script>
<style scoped></style>
