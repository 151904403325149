<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## apt-get 命令详解

\`apt-get\` 是 Debian 和基于 Debian 的 Linux 发行版（如 Ubuntu）中的一个常用包管理工具。它用于从软件仓库中安装、更新、升级和删除软件包，是管理员和开发者常用的工具。

### 基本语法

\`\`\`bash
sudo apt-get [options] command [package_name]
\`\`\`

- \`options\`：可选的参数，用于修改命令行为。
- \`command\`：执行的具体操作，如 \`install\`、\`update\` 等。
- \`package_name\`：要操作的软件包名称。

### 常用命令和选项

#### 1. 更新软件源

\`\`\`bash
sudo apt-get update
\`\`\`

**解读**：\`update\` 命令更新本地软件源列表，确保系统能够从最新的软件仓库中获取信息。这是安装或更新软件前的常规步骤。

**示例输出**：

\`\`\`bash
Hit:1 http://archive.ubuntu.com/ubuntu focal InRelease
Hit:2 http://archive.ubuntu.com/ubuntu focal-updates InRelease
Hit:3 http://archive.ubuntu.com/ubuntu focal-security InRelease
Reading package lists... Done
\`\`\`

#### 2. 安装软件包

\`\`\`bash
sudo apt-get install [package_name]
\`\`\`

例如，安装 \`vim\` 编辑器：

\`\`\`bash
sudo apt-get install vim
\`\`\`

**示例输出**：

\`\`\`bash
Reading package lists... Done
Building dependency tree       
Reading state information... Done
The following additional packages will be installed:
  vim-common vim-runtime
Suggested packages:
  ctags vim-doc vim-scripts
The following NEW packages will be installed:
  vim
\`\`\`

**解读**：系统会提示要安装的包及其依赖项，并且需要用户确认才能继续。

#### 3. 升级已安装的软件包

\`\`\`bash
sudo apt-get upgrade
\`\`\`

**解读**：\`upgrade\` 命令会升级系统中所有已安装软件包到最新版本。通常在 \`sudo apt-get update\` 之后执行。

**示例输出**：

\`\`\`bash
Reading package lists... Done
Building dependency tree       
Reading state information... Done
The following packages will be upgraded:
  vim
1 upgraded, 0 newly installed, 0 to remove and 0 not upgraded.
Do you want to continue? [Y/n]
\`\`\`

#### 4. 删除软件包

\`\`\`bash
sudo apt-get remove [package_name]
\`\`\`

例如，删除 \`vim\` 编辑器：

\`\`\`bash
sudo apt-get remove vim
\`\`\`

**示例输出**：

\`\`\`bash
Reading package lists... Done
Building dependency tree       
Reading state information... Done
The following packages will be REMOVED:
  vim
Do you want to continue? [Y/n]
\`\`\`

**解读**：此命令只会删除软件包本身，但不会删除其配置文件或依赖包。

#### 5. 清理未使用的包

\`\`\`bash
sudo apt-get autoremove
\`\`\`

**解读**：\`autoremove\` 命令删除已安装但不再使用的依赖包，通常在卸载某些软件后执行，以释放磁盘空间。

**示例输出**：

\`\`\`bash
Reading package lists... Done
Building dependency tree       
Reading state information... Done
The following packages will be REMOVED:
  libsomething libunused
0 upgraded, 0 newly installed, 2 to remove.
After this operation, 20.5 MB disk space will be freed.
\`\`\`

#### 6. 清除包及其配置文件

\`\`\`bash
sudo apt-get purge [package_name]
\`\`\`

**解读**：\`purge\` 命令不仅删除指定软件包，还会删除与之相关的配置文件。

### Bash 内置命令与外部命令的区分

\`apt-get\` 是一个外部命令，而不是 Bash 的内置命令。它是位于 \`/usr/bin/apt-get\` 的独立可执行文件，属于 \`dpkg\` 软件包管理器的一部分。

### 使用示例

用户 \`sre\` 通过 \`apt-get\` 安装 \`vim\` 的过程：

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo apt-get install vim
\`\`\`

**输出**：

\`\`\`
Reading package lists... Done
Building dependency tree       
Reading state information... Done
The following additional packages will be installed:
  vim-common vim-runtime
Suggested packages:
  ctags vim-doc vim-scripts
The following NEW packages will be installed:
  vim
\`\`\`

**解读**：系统提示安装 \`vim\` 及其依赖包，执行后 \`vim\` 编辑器将成功安装。

### 在不同架构上的表现

\`apt-get\` 命令在 \`aarch64\` 架构和 \`x86_64/amd64\` 架构上的表现基本一致，因为它是平台无关的包管理工具，主要依赖于软件源和包管理库，而不是具体的硬件架构。

### 版本差异

\`apt-get\` 的功能在不同版本的 Ubuntu 上可能会有细微差别。较新的 Ubuntu 版本建议使用 \`apt\`，这是一个对用户更友好的工具，功能与 \`apt-get\` 基本相同。

### 替代命令

\`apt-get\` 逐渐被新的 \`apt\` 命令替代。\`apt\` 是一个更简洁和现代化的前端，但 \`apt-get\` 仍然保留在系统中，适用于一些更复杂的场景。

### 安装包

\`apt-get\` 属于系统自带工具，通常不需要手动安装。如果需要重新安装 \`apt-get\`，可以使用以下命令：

- **Ubuntu**:

  \`\`\`bash
  sudo apt-get install apt
  \`\`\`

- **RHEL** 不使用 \`apt-get\`，它使用 \`yum\` 或 \`dnf\` 作为包管理工具。

### 使用权限

由于 \`apt-get\` 涉及到系统软件包的安装、升级和删除，通常需要 \`root\` 或 \`sudo\` 权限。执行大多数命令时，建议以 \`sudo\` 用户身份运行。

### 总结

\`apt-get\` 是 Ubuntu 和其他基于 Debian 的 Linux 发行版中不可或缺的包管理工具。通过它，用户可以轻松地管理软件包，实现软件的安装、更新、删除和清理。在了解常见命令及其用法后，能够大幅提升系统管理的效率。

        `,
    };
  },
});
</script>
<style scoped></style>
