<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## basename 命令详解

\`basename\` 命令用于去除路径中的目录部分，仅返回文件名。它是一个外部命令，通常用于处理路径字符串，使其返回最后的文件名或目录名。该命令在脚本编写中非常有用，特别是在处理路径字符串时。

### 基本用法

最基本的 \`basename\` 命令格式如下：

\`\`\`bash
basename [OPTION] NAME [SUFFIX]
\`\`\`

- **NAME**：表示带有路径的文件名。
- **SUFFIX**：表示要去除的文件后缀（可选）。

### 示例 1：基本用法

假设我们有一个文件路径 \`/home/sre/documents/report.txt\`，我们希望只提取文件名 \`report.txt\`，可以使用如下命令：

\`\`\`bash
basename /home/sre/documents/report.txt
\`\`\`

**输出：**

\`\`\`bash
report.txt
\`\`\`

这个命令成功提取了文件名。

### 示例 2：去除后缀

如果我们希望在提取文件名的同时去除文件后缀 \`.txt\`，可以这样操作：

\`\`\`bash
basename /home/sre/documents/report.txt .txt
\`\`\`

**输出：**

\`\`\`bash
report
\`\`\`

这个命令不仅提取了文件名，还去除了后缀。

### 示例 3：目录路径的处理

\`basename\` 也可以处理目录路径，返回路径中的最后一部分。例如：

\`\`\`bash
basename /home/sre/documents/
\`\`\`

**输出：**

\`\`\`bash
documents
\`\`\`

即使路径以 \`/\` 结尾，\`basename\` 仍然返回路径的最后一部分。

### 处理多个文件

\`basename\` 可以和其他命令组合使用来处理多个文件。例如，我们可以使用 \`find\` 命令结合 \`basename\` 提取某个目录下所有 \`.txt\` 文件的文件名：

\`\`\`bash
find /home/sre/documents/ -type f -name "*.txt" -exec basename {} \\;
\`\`\`

**输出：**

\`\`\`bash
file1.txt
file2.txt
...
\`\`\`

这个命令会递归查找 \`/home/sre/documents/\` 目录下的所有 \`.txt\` 文件，并输出它们的文件名。

### 不同架构下的表现

\`basename\` 命令在不同架构的机器上，如 \`aarch64\` 和 \`x86_64/amd64\`，输出结果一致，因为它处理的是字符串和路径，与硬件架构无关。

### 不同版本的对比

不同版本的 \`basename\` 命令在基本功能上没有明显差异，但一些早期版本可能在处理特殊字符时表现略有不同。建议在使用较旧的系统时，验证 \`basename\` 命令的输出是否符合预期。

### 总结

\`basename\` 是一个非常实用的命令，尤其是在脚本编写中，用于处理路径和文件名。它的用法简单直观，但在处理复杂路径时可能需要结合其他命令使用。

在常见的 Linux 系统上，无论是在 \`aarch64\` 还是 \`x86_64/amd64\` 架构下，\`basename\` 命令的行为都是一致的。

**注意**：\`basename\` 不需要 root 权限，也不会更改系统文件或目录，因此可以安全地在用户级别使用。

        `,
    };
  },
});
</script>
<style scoped></style>
