<template>
  <div class="background">
    <h4>运维工程师的运维环境编程技能（练习模式: 知识细节+小任务）</h4>
    <el-row>
      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Bash</span>
            <el-icon><StarFilled color="#00e676" /></el-icon>
          </div>
          <el-divider></el-divider>
          <el-text>
            Bash是运维工程师使用频次最高的编程语言，尤其用于系统任务管理。（必备）
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Python3</span>
            <el-icon><StarFilled color="#00e676" /></el-icon>
          </div>
          <el-divider></el-divider>
          <el-text>
            运维工程师必备的编程语言，功能比Bash强大许多，复杂任务需要Python3来完成。（必备）
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>awk</span>
            <el-icon><StarFilled color="#00e676" /></el-icon>
          </div>
          <el-divider></el-divider>
          <el-text>
            强大的文本处理工具，支持条件判断、循环等编程特性，广泛应用于日志分析和数据处理。（必备）
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>sed</span>
            <el-icon><StarFilled color="#00e676" /></el-icon>
          </div>
          <el-divider></el-divider>
          <el-text>
            主要用于文本的查找和替换。常使用它来修改配置文件或批量编辑文件内容。（必备）
          </el-text>
        </el-card>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>JSON or YAML</span>
            <el-icon><StarFilled color="#00e676" /></el-icon>
          </div>
          <el-divider></el-divider>
          <el-text>
            尽管不算编程语言，但广泛应用于配置管理工具。掌握这些格式有助于编写配置文件。(建议掌握)
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Golang</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            Golang生态已成熟，且支持依赖打包（静态编译），如有额外时间，可以掌握它。(可选)
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>C Language</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            系统底层语言，功能强大，涉及到内核等高级功能要用到它，如有额外时间，可掌握它。(可选)
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Ruby</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            主要用于自动化运维任务，语法简洁，适合快速编写，如有额外时间，可掌握它。(可选)
          </el-text>
        </el-card>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>PowerShell</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            Windows
            环境中进行管理和运维，支持自动化和系统管理任务，集成了微软系统的诸多功能。
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Data Structures</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            掌握常用的数据结构，高效率管理和访问数据等，大数据量时见内功。
          </el-text>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="card-item">
          <div class="card-header">
            <span>Algo 常见算法</span>
          </div>
          <el-divider></el-divider>
          <el-text>
            掌握常用的算法，举一反三解决诸多类似问题，大脑灵活顶呱呱。
          </el-text>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { StarFilled } from "@element-plus/icons-vue";
export default {
  components: {
    StarFilled,
  },
};
</script>

<style scoped>
.background {
  background: linear-gradient(135deg, #f3f4f6, #e0f7fa);
  padding: 18px;
  border-radius: 8px;
  height: 100vh;
}

.background h4 {
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}

.card-item {
  cursor: default;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-item:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.el-card {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-row {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
