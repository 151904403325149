<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## head 命令详解

### 概述

\`head\` 命令用于查看文件的前几行内容。它默认显示文件的前 10 行，但可以通过参数进行定制。\`head\` 常用于快速浏览文件的开头部分，尤其是在处理日志文件、配置文件或其他大型文本文件时。

\`head\` 既可以作为外部命令执行，也可以通过一些 shell 内置命令组合来实现类似功能。不过，常见的使用场景中 \`head\` 主要作为外部命令调用。

### 使用语法

\`\`\`bash
head [OPTION]... [FILE]...
\`\`\`

- \`OPTION\`: 用于指定显示行数或字节数的选项。
- \`FILE\`: 要读取的文件。如果不指定文件，\`head\` 将从标准输入读取数据。

### 重要参数

- \`-n [N]\` 或 \`--lines=[N]\`: 显示文件的前 N 行内容。\`N\` 可以是正数或负数，负数表示显示文件除最后 N 行以外的所有行。
- \`-c [N]\` 或 \`--bytes=[N]\`: 显示文件的前 N 个字节。\`N\` 同样可以是正数或负数，负数表示显示文件除最后 N 个字节以外的所有内容。
- \`-q\` 或 \`--quiet\` / \`--silent\`: 不显示文件名（适用于多个文件输入）。
- \`-v\` 或 \`--verbose\`: 总是显示文件名，即使只有一个文件输入。

### 基本用法

#### 例1：显示文件的前10行

\`\`\`bash
head /var/log/syslog
\`\`\`

**输出解释**:
该命令显示 \`syslog\` 文件的前 10 行内容。

\`\`\`bash
Sep  9 10:34:23 azlinux-prod-cn systemd[1]: Starting Session 1 of user sre.
Sep  9 10:34:23 azlinux-prod-cn systemd[1]: Started Session 1 of user sre.
...
\`\`\`

**解读**:
这是 \`syslog\` 文件的前 10 行信息，通常用于检查系统启动过程中的消息。

#### 例2：指定显示文件的前5行

\`\`\`bash
head -n 5 /etc/passwd
\`\`\`

**输出解释**:
此命令显示 \`/etc/passwd\` 文件的前 5 行内容。

\`\`\`bash
root:x:0:0:root:/root:/bin/bash
daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin
bin:x:2:2:bin:/bin:/usr/sbin/nologin
sre:x:1005:1005:sre:/home/sre:/bin/bash
...
\`\`\`

**解读**:
输出展示了 \`/etc/passwd\` 文件中前 5 个用户的账号信息，包括用户名、用户ID、组ID、主目录等。

### 进阶用法

#### 例3：显示文件的前20字节

\`\`\`bash
head -c 20 /etc/hostname
\`\`\`

**输出解释**:
该命令显示 \`/etc/hostname\` 文件的前 20 个字节。

\`\`\`bash
azlinux-prod-cn
\`\`\`

**解读**:
此命令通常用于快速查看文件的开头部分，特别是当文件内容较短时（如 hostname 文件）。

#### 例4：显示多个文件的前几行

\`\`\`bash
head -n 3 /var/log/syslog /etc/passwd
\`\`\`

**输出解释**:
此命令显示 \`syslog\` 和 \`passwd\` 文件的前 3 行内容，分别显示文件名和其内容。

\`\`\`bash
==> /var/log/syslog <==
Sep  9 10:34:23 azlinux-prod-cn systemd[1]: Starting Session 1 of user sre.
Sep  9 10:34:23 azlinux-prod-cn systemd[1]: Started Session 1 of user sre.
...

==> /etc/passwd <==
root:x:0:0:root:/root:/bin/bash
daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin
bin:x:2:2:bin:/bin:/usr/sbin/nologin
...
\`\`\`

**解读**:
在处理多个文件时，\`head\` 会默认显示每个文件的文件名，这对于区分不同文件内容非常有用。

### 不同架构下的表现差异

\`head\` 命令在不同架构（如 \`aarch64\` 和 \`x86_64/amd64\`）的机器上表现一致。因为 \`head\` 是一个与平台无关的文本处理工具，基于 POSIX 标准设计，因此在各种架构下功能和输出不会有差异。

### 不同版本的差异

较旧的 \`head\` 版本可能不支持某些高级选项，如 \`-v\`（总是显示文件名）选项。在这些情况下，用户需要手动升级到支持这些选项的版本，或者通过其他命令实现类似功能。

### 安全性与替代命令

\`head\` 是一个安全性较高的命令，它只负责读取和显示文件的内容，几乎不会对系统产生副作用。在某些情况下，用户可能会使用 \`sed\` 命令来实现类似的功能，如 \`sed -n '1,10p' filename\`，以替代 \`head -n 10 filename\`。

### 示例输出的架构差异

与其他文本处理工具类似，\`head\` 在不同系统架构上的表现主要体现在处理速度上。现代的 \`x86_64/amd64\` 架构通常比较老的 \`aarch64\` 架构在文本处理上更为高效，但功能和输出内容本身没有差异。

### 结论

\`head\` 命令是一个简单而高效的文本处理工具，广泛应用于 Unix/Linux 系统中。它的主要用途是快速查看文件的开头部分，通过指定行数或字节数可以精确控制显示的内容。在不同的系统架构或操作系统版本下，\`head\` 的功能和输出基本一致，因此是一种可靠的工具。

        `,
    };
  },
});
</script>
<style scoped></style>
