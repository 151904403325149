<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## cp 命令详解

\`cp\` 命令是Linux系统中用于复制文件和目录的工具。它能够将文件或目录从一个位置复制到另一个位置，同时保留原始文件。\`cp\` 命令是系统管理和文件操作中非常基础且重要的工具之一。本文将详细介绍 \`cp\` 命令的用法，并特别说明一些常用参数。

### 基本语法

\`cp\` 命令的基本语法如下：

\`\`\`bash
cp [选项] 源文件 目标文件
\`\`\`

其中，\`源文件\` 是要复制的文件或目录，\`目标文件\` 是复制后的文件或目录名。使用不同的选项，可以控制文件的复制方式及行为。

### 复制单个文件

最基本的用法是将一个文件复制到另一个位置。

\`\`\`bash
$ cp example.txt backup.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
上述命令将 \`example.txt\` 文件复制到当前目录下，并命名为 \`backup.txt\`。如果 \`backup.txt\` 文件已存在，它将被覆盖。

可以通过 \`ls\` 命令验证文件是否复制成功：

\`\`\`bash
$ ls
example.txt  backup.txt
\`\`\`

**输出：**

\`\`\`
backup.txt  example.txt
\`\`\`

**解读：**
\`ls\` 命令显示了当前目录中的文件列表，表明 \`example.txt\` 和 \`backup.txt\` 文件都存在，说明复制成功。

### 复制目录

使用 \`-r\` 或 \`-R\` 选项，可以递归地复制目录及其内容。

\`\`\`bash
$ cp -r mydir backupdir
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
此命令将 \`mydir\` 目录及其所有子目录和文件复制到 \`backupdir\`。\`backupdir\` 会在当前目录下创建，并包含 \`mydir\` 的所有内容。

可以通过 \`ls\` 和 \`pwd\` 命令验证复制的目录：

\`\`\`bash
$ ls
backupdir  mydir
$ ls backupdir
file1.txt  file2.txt
\`\`\`

**输出：**

\`\`\`
backupdir:
file1.txt  file2.txt
mydir:
file1.txt  file2.txt
\`\`\`

**解读：**
\`backupdir\` 目录中包含了 \`mydir\` 目录下的所有文件，验证了目录的复制。

### 强制覆盖文件

使用 \`-f\` 选项可以强制覆盖已经存在的文件，而无需提示确认。

\`\`\`bash
$ cp -f example.txt backup.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
\`-f\` 选项强制 \`cp\` 命令在目标文件存在时覆盖它，而不会提示确认。这在自动化脚本中非常有用，避免了人工干预。

### 保留文件属性

使用 \`-p\` 选项可以保留源文件的属性（如修改时间、权限等）到目标文件。

\`\`\`bash
$ cp -p example.txt backup.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
\`-p\` 选项保留了 \`example.txt\` 文件的所有属性，包括时间戳和权限等，确保 \`backup.txt\` 与 \`example.txt\` 完全相同。

可以通过 \`ls -l\` 命令查看文件属性：

\`\`\`bash
$ ls -l example.txt backup.txt
\`\`\`

**输出：**

\`\`\`
-rw-r--r-- 1 sre sre-group  0 Sep  8 14:00 backup.txt
-rw-r--r-- 1 sre sre-group  0 Sep  8 14:00 example.txt
\`\`\`

**解读：**
\`ls -l\` 输出显示 \`example.txt\` 和 \`backup.txt\` 的属性完全一致，说明属性已成功保留。

### 交互式复制

使用 \`-i\` 选项可以在覆盖目标文件时提示用户确认，避免误操作。

\`\`\`bash
$ cp -i example.txt backup.txt
cp: overwrite 'backup.txt'? y
\`\`\`

**输出：**

\`\`\`
cp: overwrite 'backup.txt'? y
\`\`\`

**解读：**
当使用 \`-i\` 选项时，\`cp\` 命令在覆盖现有文件前会提示用户确认，输入 \`y\` 后才会执行覆盖操作，这对于保护文件避免意外覆盖非常有帮助。

### 递归复制符号链接

使用 \`-L\` 选项可以复制符号链接指向的实际文件，而不是链接本身。

\`\`\`bash
$ cp -L symlink.txt destination.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
\`-L\` 选项使 \`cp\` 命令复制符号链接指向的实际文件内容，而不是仅仅复制链接。这个选项在处理符号链接时非常有用。

### 总结

\`cp\` 命令是Linux系统中非常基础且强大的工具，通过熟练掌握各种选项，可以在不同场景下高效地复制文件和目录。无论是简单的文件复制、目录的递归复制，还是需要保留文件属性或进行交互式操作，\`cp\` 命令都能提供可靠的解决方案。


        `,
    };
  },
});
</script>
<style scoped></style>
