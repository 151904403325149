<template>
  <div class="congratulation-container">
    <div class="message">
      <h1>通关成功！</h1>
      <p v-if="score !== null">恭喜您获得能力积分: {{ score }} 分。</p>
      <div class="confetti"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style scoped>
.congratulation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  height: 240px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  text-align: center;
  border-radius: 1rem;
}

.message {
  background: #fffcff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  position: relative;
}

h1 {
  color: #4caf50;
  font-size: 2rem;
  margin-bottom: 1rem;
  animation: slideInFromTop 1s ease-in-out;
}

p {
  font-size: 16px;
  color: #333333;
  margin-bottom: 1rem;
  animation: slideInFromBottom 1s ease-in-out;
}

.confetti {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  /* animation: confettiFall 2s infinite ease-in-out; */
}

.confetti::before,
.confetti::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 26px;
  background: #ff6b6b;
  top: 0;
  left: 84%;
  transform: translateX(-50%);
  animation: confettiPieceFall 1s infinite ease-in-out alternate;
}

.confetti::after {
  background: #f8e71c;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes confettiFall {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}

@keyframes confettiPieceFall {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
</style>
