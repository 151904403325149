<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## scp 命令详解

\`scp\`（Secure Copy）命令是用于在 Linux 和 Unix 系统中通过 SSH（Secure Shell）协议安全地复制文件和目录的工具。它是系统管理员和开发人员在远程传输文件时经常使用的命令行工具。\`scp\` 既可以在本地系统之间复制文件，也可以在本地系统和远程服务器之间传输文件。

### Bash内置命令与外部命令的区别

\`scp\` 是一个外部命令，通常是 OpenSSH 工具包的一部分，而非 Bash 内置命令。执行 \`scp\` 命令时，系统会调用外部程序来处理文件传输工作。

### 安装 \`scp\`

在大多数现代 Linux 发行版中，\`scp\` 命令已经默认安装。如果未安装，可以通过以下命令安装：

- **Ubuntu/Debian** 系统：
  \`\`\`bash
  sudo apt-get install openssh-client
  \`\`\`
- **RHEL/CentOS** 系统：
  \`\`\`bash
  sudo yum install openssh-clients
  \`\`\`

### 基本用法

#### 1. 复制文件到远程服务器

要将本地文件复制到远程服务器，可以使用以下格式：

\`\`\`bash
scp /path/to/local/file sre@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
scp /home/sre/file.txt sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
file.txt                                                       100%  234KB 100.0KB/s   00:02    
\`\`\`

此命令将本地的 \`file.txt\` 文件复制到远程服务器 \`azlinux-prod-cn\` 的 \`/home/sre-group/\` 目录。\`sre\` 是目标服务器上的用户名。

#### 2. 从远程服务器复制文件到本地

要从远程服务器复制文件到本地，可以使用以下格式：

\`\`\`bash
scp sre@azlinux-prod-cn:/path/to/remote/file /path/to/local/destination
\`\`\`

示例：

\`\`\`bash
scp sre@azlinux-prod-cn:/home/sre-group/file.txt /home/sre/
\`\`\`

**输出**：
\`\`\`bash
file.txt                                                       100%  234KB 100.0KB/s   00:02    
\`\`\`

此命令将远程服务器上的 \`file.txt\` 文件复制到本地的 \`/home/sre/\` 目录。

### 常见选项

#### \`-r\`：递归复制目录

要复制整个目录而不是单个文件，可以使用 \`-r\` 参数实现递归复制：

\`\`\`bash
scp -r /path/to/local/directory sre@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
scp -r /home/sre/mydir sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
mydir/file1.txt                                                100%  500KB 100.0KB/s   00:05    
mydir/file2.txt                                                100%  800KB 100.0KB/s   00:08    
\`\`\`

此命令递归复制了目录 \`mydir\` 到远程服务器的 \`/home/sre-group/\` 目录下。

#### \`-P\`：指定端口号

默认情况下，\`scp\` 使用端口 22 进行连接。如果服务器使用了不同的 SSH 端口，可以使用 \`-P\` 参数指定端口：

\`\`\`bash
scp -P 2222 /path/to/local/file sre@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
scp -P 2222 /home/sre/file.txt sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
file.txt                                                       100%  234KB 100.0KB/s   00:02    
\`\`\`

该命令使用端口 2222 将文件复制到远程服务器。

#### \`-i\`：指定私钥文件

如果使用非默认的 SSH 私钥，可以通过 \`-i\` 参数指定密钥文件：

\`\`\`bash
scp -i /path/to/private/key /path/to/local/file sre@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
scp -i ~/.ssh/id_rsa /home/sre/file.txt sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
file.txt                                                       100%  234KB 100.0KB/s   00:02    
\`\`\`

#### \`-v\`：详细模式

为了调试连接问题，可以使用 \`-v\`（verbose）选项查看更多详细信息：

\`\`\`bash
scp -v /path/to/local/file sre@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
scp -v /home/sre/file.txt sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
Executing: program /usr/bin/ssh host azlinux-prod-cn, user sre, command scp -v -t /home/sre-group/
\`\`\`

### 示例：批量传输文件

使用 \`scp\` 可以轻松地批量传输多个文件或整个目录。例如，使用 \`scp\` 递归传输本地目录到远程服务器：

\`\`\`bash
scp -r /home/sre/documents sre@azlinux-prod-cn:/home/sre-group/
\`\`\`

**输出**：
\`\`\`bash
documents/file1.txt                                             100%  2.3MB 100.0KB/s   00:23    
documents/file2.txt                                             100%  1.8MB 100.0KB/s   00:18    
\`\`\`

### root权限选项

在一些情况下，复制操作可能需要 \`root\` 权限，尤其是涉及到系统目录或权限受限的文件时。可以使用 \`sudo\` 命令来提升权限：

\`\`\`bash
sudo scp /path/to/local/file root@azlinux-prod-cn:/path/to/remote/destination
\`\`\`

示例：

\`\`\`bash
sudo scp /etc/hosts root@azlinux-prod-cn:/etc/hosts
\`\`\`

**输出**：
\`\`\`bash
hosts                                                          100%   408B  100.0KB/s   00:00    
\`\`\`

该命令复制了本地系统的 \`/etc/hosts\` 文件到远程服务器的 \`/etc/hosts\`。

### 不同架构下的表现

\`scp\` 命令在 aarch64 和 x86_64/amd64 架构下通常表现一致。其核心功能依赖于 SSH 协议，并且在两种架构下都能够正常工作，因此无需为架构差异进行特殊处理。

### 版本差异

\`scp\` 命令的主要功能在不同版本之间通常保持一致，但较新的版本可能会增加对现代加密协议和更高性能传输的支持。为了查看 \`scp\` 版本和所使用的 OpenSSH 版本，可以执行以下命令：

\`\`\`bash
scp -V
\`\`\`

### 安全替代

虽然 \`scp\` 是常用的文件传输工具，但它有一些安全性和性能上的局限，尤其在传输大文件时。推荐使用更现代化的替代工具 \`rsync\` 来替代 \`scp\`，因为 \`rsync\` 支持断点续传、增量传输，并且可以实现更高效的文件同步。

### 小结

\`scp\` 是一个功能强大的命令行工具，适用于各种文件传输场景。它通过 SSH 协议提供了安全的文件传输方式，支持常用的选项如递归复制、指定端口、使用密钥文件等。尽管 \`scp\` 仍然非常有用，但在性能和安全性方面，建议考虑使用更现代的替代工具如 \`rsync\` 进行文件传输。

**提示**：\`scp\` 通常是安装在所有基于 Linux 的系统上，但如果需要安装，请参考上文的安装指南。

        `,
    };
  },
});
</script>
<style scoped></style>
