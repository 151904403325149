<template>
  <div class="common-layout">
    <el-container>
      <component :is="activeComp"></component>
    </el-container>
  </div>
</template>

<script>
import { ElMain, ElContainer } from "element-plus";
import CmdDefault from "./subview/d/CmdDefault.vue";
import CmdRm from "./subview/r/CmdRm.vue";
import CmdMd5sum from "./subview/m/CmdMd5sum.vue";
import CmdApt from "./subview/a/CmdApt.vue";
import CmdAlias from "./subview/a/CmdAlias.vue";
import CmdDf from "./subview/d/CmdDf.vue";
import CmdSed from "./subview/s/CmdSed.vue";
import CmdUseradd from "./subview/u/CmdUseradd.vue";
import CmdKill from "./subview/k/CmdKill.vue";
import CmdGrep from "./subview/g/CmdGrep.vue";
import CmdBashFor from "./subview/b/CmdBashFor.vue";
import CmdDnfReinstall from "./subview/d/CmdDnfReinstall.vue";

export default {
  name: "AdvancedView",
  components: {
    ElMain,
    ElContainer,
    CmdLs: () => import("./subview/l/CmdLs.vue"),
    CmdDefault,
    CmdDf,
    CmdSed,
    CmdUseradd,
    CmdBashFor,
    CmdRm,
    CmdMd5sum,
    CmdApt,
    CmdAlias,
    CmdKill,
    CmdGrep,
    CmdDnfReinstall,
  },
  data() {
    return {
      activeComp: "CmdDefault", // 默认加载 CmdDefault 组件
    };
  },
  watch: {
    "$route.params.taskId": {
      immediate: true,
      handler(newVal) {
        this.loadComponent(newVal);
      },
    },
  },
  methods: {
    loadComponent(taskId) {
      const componentMap = {
        1: "CmdDf",
        2: "CmdLs",
        10060: "CmdDefault",
        10061: "CmdRm",
        10062: "CmdApt",
        10063: "CmdBashFor",
        10064: "CmdUseradd",
        10065: "CmdDf",
        10066: "CmdSed",
        10067: "CmdAlias",
        10068: "CmdMd5sum",
        10069: "CmdKill",
        10070: "CmdGrep",
        10071: "CmdDnfReinstall",
        // 添加更多的 taskId 和组件的映射
      };

      this.activeComp = componentMap[taskId] || "CmdDefault"; // 找不到则加载 CmdDefault
    },
  },
};
</script>

<style scoped>
.common-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}
</style>
