<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sysctl 命令详解

\`sysctl\` 是一个用于查看和修改Linux内核参数的命令行工具。它允许用户动态地调整内核参数，控制系统的行为和性能，而无需重启系统。这对于系统调优和配置内核参数非常有用。

### 基本语法

\`\`\`bash
sysctl [OPTION] [PARAMETER]
\`\`\`

\`sysctl\` 的基本语法如上，其中 \`OPTION\` 为命令选项，\`PARAMETER\` 为要查看或修改的内核参数。

### 常用选项

- \`-a, --all\`：显示所有内核参数及其值。
- \`-w, --write\`：修改内核参数的值。
- \`-p, --load\`：从指定的文件加载参数设置。
- \`-n, --no-pager\`：输出结果不通过分页程序显示。

### 示例用法

#### 查看所有内核参数

\`\`\`bash
sysctl -a
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sysctl -a
kernel.ostype = Linux
kernel.version = #1 SMP Fri Sep 10 12:34:56 UTC 2024
...
\`\`\`

**解释：**  
该命令显示系统所有内核参数及其当前值。

#### 查看特定内核参数

\`\`\`bash
sysctl kernel.hostname
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sysctl kernel.hostname
kernel.hostname = azlinux-prod-cn
\`\`\`

**解释：**  
此命令查看 \`kernel.hostname\` 内核参数的当前值。

#### 修改内核参数

\`\`\`bash
sudo sysctl -w kernel.hostname=newhostname
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo sysctl -w kernel.hostname=newhostname
kernel.hostname = newhostname
\`\`\`

**解释：**  
该命令将 \`kernel.hostname\` 参数的值修改为 \`newhostname\`。修改后的值立即生效。

#### 从文件加载内核参数

假设文件 \`/etc/sysctl.conf\` 包含以下配置：

\`\`\`conf
net.ipv4.ip_forward = 1
\`\`\`

可以使用以下命令加载这些设置：

\`\`\`bash
sudo sysctl -p /etc/sysctl.conf
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ sudo sysctl -p /etc/sysctl.conf
net.ipv4.ip_forward = 1
\`\`\`

**解释：**  
该命令从 \`/etc/sysctl.conf\` 文件中加载内核参数配置。

### 重要参数说明

- **kernel.hostname**：系统的主机名。
- **net.ipv4.ip_forward**：控制是否启用IP转发，用于路由功能。值为 \`1\` 启用，\`0\` 禁用。
- **vm.swappiness**：控制系统在内存不足时交换空间（swap）的使用程度。值越高，系统越倾向于使用交换空间。

### 不同架构下的表现

\`sysctl\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的行为和输出一致，因此此处不需要额外说明。

### 版本差异

\`sysctl\` 的功能和参数在不同版本的Linux系统中通常一致，主要差异可能体现在支持的内核参数上。在一些较旧的Linux发行版中，可能没有某些新引入的参数。

### 安全性和替代命令

\`sysctl\` 命令本身没有已知的安全问题，也没有被弃用的风险。其主要用于内核参数的动态调整，是系统管理的标准工具。

### 安装

\`sysctl\` 命令通常包含在 \`procps\` 包中，该包在大多数Linux发行版中默认安装。如果需要手动安装，可以使用以下命令：

- **Ubuntu:**

  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL/CentOS:**

  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

### 总结

\`sysctl\` 是一个强大的工具，允许用户动态查看和修改Linux内核参数，以优化系统性能和行为。通过掌握 \`sysctl\` 的使用，你可以更好地管理和配置你的Linux系统。

        `,
    };
  },
});
</script>
<style scoped></style>
