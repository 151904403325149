<template>
  <div v-html="renderedMarkdown" class="markdown-content"></div>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  name: "SimpleMD",
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      renderedMarkdown: "",
      md: new MarkdownIt(),
    };
  },
  watch: {
    content: {
      immediate: true,
      handler(newContent) {
        this.renderedMarkdown = this.md.render(newContent);
      },
    },
  },
};
</script>

<style scoped>
.markdown-content {
  line-height: 1.6;
}

.markdown-content h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.markdown-content h2 {
  font-size: 1.5em;
  margin-bottom: 0.75em;
}

.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content code {
  background-color: #f2f2f2;
  padding: 0.2em 0.4em;
  border-radius: 4px;
}

.markdown-content pre {
  background-color: #f5f5f5;
  padding: 1em;
  border-radius: 6px;
  overflow-x: auto;
}
</style>
