<template>
  <div
    class="knowledge-card-wrapper"
    :style="{ height: isCardVisible ? 'auto' : '100%', overflow: 'hidden' }"
  >
    <button class="expand-button" @click.stop="toggleCard">
      展开扩展知识点
    </button>
    <transition name="fade">
      <div class="knowledge-card" v-show="isCardVisible">
        <button
          v-if="isCardVisible"
          class="collapse-button-1"
          @click.stop="toggleCard"
        >
          <span class="collapse-icon">🔽</span>
          合并扩展知识点
        </button>
        <div class="knowledge-content" v-html="parsedContent"></div>
        <button
          v-if="isCardVisible"
          class="collapse-button"
          @click.stop="toggleCard"
        >
          <span class="collapse-icon">🔽</span>
          合并扩展知识点
        </button>
      </div>
    </transition>
    <div v-if="!isCardVisible" @click="toggleCard"></div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import MarkdownIt from "markdown-it";

export default {
  name: "KnowledgeCardExpand",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isCardVisible = ref(false);

    const md = new MarkdownIt();

    const toggleCard = () => {
      isCardVisible.value = !isCardVisible.value;
    };

    const parsedContent = computed(() => md.render(props.content));

    return {
      isCardVisible,
      toggleCard,
      parsedContent,
    };
  },
};
</script>

<style scoped>
.knowledge-card-wrapper {
  /* background-color: #bbdefb; */
  margin: 6px 1rem;
  overflow: hidden;
  position: relative;
  transition: height 0.8s ease;
  padding: 10px; /* 添加内间距以美化外观 */
}
.expand-button {
  padding: 8px 16px;
  background-color: teal; /* 深蓝色按钮 */
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px; /* 调整字体大小 */
  display: block; /* 将按钮设置为块级元素以实现左对齐 */
}

.knowledge-card {
  font-family: "Microsoft YaHei", "微软雅黑", "SimSun", "Inter",
    "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial,
    sans-serif;
  padding: 20px;
  background-color: #e0f2f1;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.collapse-button-1 {
  margin: 12px 1rem;
  padding: 6px 12px;
  background-color: #009688; /* 橄榄绿按钮 */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute; /* 绝对定位 */
  right: 20px; /* 右侧边距 */
  top: 20px;
}

.collapse-button {
  margin: 12px 1rem;
  padding: 6px 12px;
  background-color: #009688; /* 橄榄绿按钮 */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute; /* 绝对定位 */
  right: 20px; /* 右侧边距 */
  bottom: 20px;
}

.collapse-icon {
  margin-right: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
