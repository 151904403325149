<template>
  <div :class="['task-card', cardClass]">
    <header class="task-header">
      <h3 class="task-title">问题: {{ task.title }}</h3>
      <span :class="['task-priority', priorityClass]">{{ task.priority }}</span>
    </header>
    <p class="task-desc">{{ task.desc }}</p>
    <div class="task-info">
      <span>
        任务ID: {{ task.id }} &nbsp;
        <strong style="color: orange">金币奖励: {{ task.gold_coins }}</strong>
      </span>
      <span><strong>产生日期:</strong> {{ task.issue_date }}</span>
    </div>
    <div class="task-commands">
      <h4>AIOps提示[相关命令]:</h4>
      <div class="command-tags">
        <el-tag
          v-for="command in task.commands"
          :key="command"
          size="small"
          type="success"
          class="command-tag"
        >
          {{ command }}
        </el-tag>
      </div>
    </div>
    <div class="button-zone">
      <el-button
        type="primary"
        @click="navigateToDetail"
        class="task-action-button"
      >
        去处理
      </el-button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { toRefs } from "vue"; // 引入toRefs，用于解构props

export default {
  props: {
    task: Object,
  },
  setup(props) {
    const { task } = toRefs(props); // 解构props中的task
    const router = useRouter();

    const navigateToDetail = () => {
      router.push({
        name: "advanced",
        params: { taskId: task.value.id }, // 将taskId作为路由参数传递
      });
    };

    return {
      navigateToDetail,
    };
  },
  computed: {
    priorityClass() {
      const priorityMap = {
        紧急: "priority-urgent",
        高: "priority-high",
        一般: "priority-medium",
      };
      return priorityMap[this.task.priority] || "priority-low";
    },
    cardClass() {
      if (this.task.priority === "紧急") {
        return "card-urgent";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.task-card {
  border: 1px solid #e0e0e0;
  min-width: 480px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  margin-bottom: 8px;
}

.task-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-urgent {
  background-color: white;
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.task-title {
  font-size: 1rem;
  margin: 0;
  color: #333;
}

.task-priority {
  font-size: 0.875rem;
  padding: 4px 8px;
  border-radius: 12px;
  color: #fff;
  text-transform: uppercase;
}

.priority-urgent {
  background-color: #d9534f;
}

.priority-high {
  background-color: #f0ad4e;
}

.priority-medium {
  background-color: #5bc0de;
}

.priority-low {
  background-color: #5cb85c;
}

.task-desc {
  margin: 1rem 6px;
  text-align: left;
  color: #444;
  line-height: 1.5;
  font-size: 15px;
}

.task-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 0.875rem;
  color: #777;
}

.task-commands {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.task-commands h4 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  margin-right: 8px;
  color: gray;
}

.command-tags {
  display: flex;
  flex-wrap: wrap;
}

.command-tag {
  margin-right: 8px;
  margin-bottom: 4px;
}

.button-zone {
  display: flex;
  flex-direction: row-reverse;
}
</style>
