<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## mv 命令详解

\`mv\` 命令用于移动或重命名文件和目录。它是一个基本的文件管理工具，能够在文件系统中重新组织文件和目录结构。

### 使用语法

\`\`\`bash
mv [选项] 源文件 目标位置
\`\`\`

### 重要参数和选项

- \`-i\`：在覆盖文件时提示确认。
- \`-f\`：强制覆盖目标位置的文件而不提示确认。
- \`-u\`：仅在源文件较新或目标文件不存在时才移动。
- \`-v\`：显示详细的移动过程信息。
- \`--backup[=CONTROL]\`：备份目标文件。\`CONTROL\` 参数决定备份的方式。

### 基本用法

#### 例1：移动文件

将文件 \`file1.txt\` 移动到目录 \`dir1\` 中：

\`\`\`bash
mv file1.txt dir1/
\`\`\`

**输出解释**:
【无输出，命令已成功执行】

**解读**:
如果操作成功，\`file1.txt\` 文件将从当前目录移动到 \`dir1\` 目录中。没有输出表示操作成功。

#### 例2：重命名文件

将文件 \`oldname.txt\` 重命名为 \`newname.txt\`：

\`\`\`bash
mv oldname.txt newname.txt
\`\`\`

**输出解释**:
【无输出，命令已成功执行】

**解读**:
如果操作成功，\`oldname.txt\` 将被重命名为 \`newname.txt\`。没有输出表示操作成功。

#### 例3：使用 \`-i\` 选项提示确认

在移动文件时，如果目标位置已经存在相同名称的文件，会提示确认：

\`\`\`bash
mv -i file1.txt dir1/
\`\`\`

**输出解释**:
\`\`\`bash
mv: overwrite 'dir1/file1.txt'? 
\`\`\`

**解读**:
系统会询问是否覆盖 \`dir1\` 目录下的同名文件。用户需要输入 \`y\` 或 \`n\` 来确认或取消操作。

#### 例4：使用 \`-f\` 选项强制覆盖

强制移动文件并覆盖目标位置的文件：

\`\`\`bash
mv -f file1.txt dir1/
\`\`\`

**输出解释**:
【无输出，命令已成功执行】

**解读**:
使用 \`-f\` 选项，\`file1.txt\` 将被直接移动并覆盖目标位置的同名文件，而不会提示确认。

#### 例5：使用 \`-v\` 选项显示详细信息

显示移动文件的详细信息：

\`\`\`bash
mv -v file1.txt dir1/
\`\`\`

**输出解释**:
\`\`\`bash
'mv' 'file1.txt' -> 'dir1/file1.txt'
\`\`\`

**解读**:
\`-v\` 选项提供了详细的信息，显示了源文件和目标文件的位置。

#### 例6：使用 \`--backup\` 选项进行备份

在移动文件时进行备份：

\`\`\`bash
mv --backup=numbered file1.txt dir1/
\`\`\`

**输出解释**:
【无输出，命令已成功执行】

**解读**:
\`--backup=numbered\` 选项会在目标位置创建一个备份文件，以防文件被覆盖。备份文件名会添加数字后缀。

### 不同架构下的表现差异

\`mv\` 命令在不同架构的机器上表现一致，因为它是一个标准的文件操作命令，与硬件架构无关。

### 不同版本的差异

\`mv\` 命令的基本用法在不同版本的 Linux 系统中保持一致。尽管在不同版本的 GNU coreutils 中可能会有额外的选项，但核心功能没有显著差异。

### 替代命令

没有直接替代的命令，因为 \`mv\` 是 Unix 和类 Unix 系统中用于移动和重命名文件的标准工具。类似功能可以通过 \`cp\` 和 \`rm\` 命令组合实现，但不如 \`mv\` 命令直接和高效。

### 安装包信息

\`mv\` 命令是 GNU coreutils 包的一部分，因此需要安装 coreutils 包来获得 \`mv\` 命令：

- **Ubuntu**:
  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL**:
  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

**需要 root 权限的选项**:
- \`mv\` 命令本身不需要 root 权限，但如果目标位置需要特殊权限（如系统目录），可能需要 root 权限。

**示例**:
\`\`\`bash
mv -v file1.txt dir1/
\`\`\`

**输出**:
\`\`\`bash
'mv' 'file1.txt' -> 'dir1/file1.txt'
\`\`\`

        `,
    };
  },
});
</script>
<style scoped></style>
