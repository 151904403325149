<template>
  <div class="task-card">
    <h2>🚀 Task Steps</h2>
    <div v-for="(step, index) in steps" :key="index" class="task-step">
      <div class="step-index">{{ index + 1 }}</div>
      <div class="step-text">{{ step }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskCardSimple",
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.task-card {
  border: none;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  width: 320px;
  background: linear-gradient(135deg, #f9f9f9 0%, #e3f2fd 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.task-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #007bff;
}
.task-step {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}
.task-step:hover {
  transform: translateX(5px);
}
.step-index {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.step-text {
  font-size: 16px;
  color: #333;
}
</style>
