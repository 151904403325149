<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## journalctl 命令详解

\`journalctl\` 是用于查询和显示 systemd 日志信息的命令。它提供了一种访问系统日志、服务日志和用户日志的统一方式，使管理员能够高效地管理和排查系统问题。

### 安装

\`journalctl\` 是 systemd 的一部分，通常在现代 Linux 发行版中默认安装。如果需要安装，可以使用以下命令：

#### Ubuntu

\`\`\`bash
sudo apt install systemd
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install systemd
\`\`\`

### 基本语法

\`\`\`bash
journalctl [选项]
\`\`\`

### 常用选项

- \`-b\`：显示当前启动以来的日志。
- \`-f\`：实时跟踪日志输出，类似于 \`tail -f\`。
- \`--since\` 和 \`--until\`：指定日志的时间范围。
- \`-u\`：显示特定服务的日志。
- \`-p\`：根据日志优先级过滤日志（例如，\`-p err\`）。
- \`--no-pager\`：直接输出到终端而不使用分页器。
- \`--list-boots\`：列出启动的历史记录。

### 示例用法

#### 示例一：显示当前启动以来的日志

使用 \`-b\` 选项可以显示当前启动以来的所有日志：

\`\`\`bash
journalctl -b
\`\`\`

**输出示例**：

\`\`\`plaintext
-- Logs begin at Mon 2024-09-01 10:00:00 UTC, end at Mon 2024-09-25 12:00:00 UTC. --
Sep 25 12:00:00 azlinux-prod-cn systemd[1]: Started Journal Service.
Sep 25 12:00:01 azlinux-prod-cn kernel: [123456.789012] usb 1-1: new high-speed USB device number 3 using xhci_hcd
\`\`\`

**解读**：此命令输出自上次启动以来的所有日志。每条日志的时间戳、主机名、服务和具体消息都有清晰的显示。

#### 示例二：实时跟踪日志输出

如果希望实时跟踪日志，可以使用 \`-f\` 选项：

\`\`\`bash
journalctl -f
\`\`\`

**输出示例**：

\`\`\`plaintext
-- Logs begin at Mon 2024-09-01 10:00:00 UTC. --
Sep 25 12:00:10 azlinux-prod-cn systemd[1]: Starting Daily apt activities...
Sep 25 12:00:12 azlinux-prod-cn apt-daily[1234]: Starting...
\`\`\`

**解读**：此命令将持续输出最新的日志信息，直到用户手动停止（如按 \`Ctrl+C\`）。

#### 示例三：显示特定服务的日志

如果需要查看某个服务的日志（如 \`nginx\`），可以使用 \`-u\` 选项：

\`\`\`bash
journalctl -u nginx.service
\`\`\`

**输出示例**：

\`\`\`plaintext
-- Logs begin at Mon 2024-09-01 10:00:00 UTC. --
Sep 25 12:01:00 azlinux-prod-cn systemd[1]: Started A high-performance web server and a reverse proxy server.
Sep 25 12:01:01 azlinux-prod-cn nginx[1235]: 2024/09/25 12:01:01 [notice] 1235#1235: using the "epoll" event method
\`\`\`

**解读**：此命令仅显示与 \`nginx\` 服务相关的日志条目。

#### 示例四：根据时间范围过滤日志

可以使用 \`--since\` 和 \`--until\` 来指定日志的时间范围。例如：

\`\`\`bash
journalctl --since "2024-09-25 10:00:00" --until "2024-09-25 12:00:00"
\`\`\`

**输出示例**：

\`\`\`plaintext
-- Logs begin at Mon 2024-09-01 10:00:00 UTC. --
Sep 25 10:15:00 azlinux-prod-cn systemd[1]: Starting Some Service...
Sep 25 10:30:00 azlinux-prod-cn systemd[1]: Started Some Service.
\`\`\`

**解读**：此命令只显示在指定时间范围内的日志，便于用户查找相关信息。

#### 示例五：根据日志优先级过滤日志

可以使用 \`-p\` 选项过滤特定优先级的日志。例如，查看错误日志：

\`\`\`bash
journalctl -p err
\`\`\`

**输出示例**：

\`\`\`plaintext
-- Logs begin at Mon 2024-09-01 10:00:00 UTC. --
Sep 25 12:00:00 azlinux-prod-cn systemd[1]: Some Service failed to start.
\`\`\`

**解读**：此命令仅显示错误级别的日志信息，帮助用户快速定位问题。

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上的 \`journalctl\` 命令输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

\`journalctl\` 的基本功能在不同版本中没有显著差异，可以通过以下命令检查当前版本：

\`\`\`bash
journalctl --version
\`\`\`

**输出示例**：

\`\`\`plaintext
journalctl 241
\`\`\`

### 权限要求

使用 \`journalctl\` 命令通常不需要特定的权限，但查看某些敏感日志可能需要 root 权限。在这种情况下，可以使用 \`sudo\` 提升权限。例如：

\`\`\`bash
sudo journalctl -u nginx.service
\`\`\`

#### 示例输出

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 总结

\`journalctl\` 命令是一个强大的工具，用于访问和管理 systemd 的日志。通过掌握其常用选项和用法，用户可以高效地查询系统日志、服务日志以及进行问题排查。了解如何过滤和实时跟踪日志是使用此命令的关键。

        `,
    };
  },
});
</script>
<style scoped></style>
