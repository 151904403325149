<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## mkfs 命令详解

\`mkfs\` 命令用于在 Linux 系统中格式化磁盘或分区，以创建文件系统。它是 \`make filesystem\` 的缩写。该命令可以用来创建多种类型的文件系统，如 ext4、xfs、btrfs 等。

### 使用语法

\`\`\`bash
mkfs [选项] 文件系统类型 设备
\`\`\`

### 重要参数和选项

- \`-t\` 或 \`--type\`：指定文件系统类型（例如 ext4、xfs）。
- \`-f\` 或 \`--force\`：强制格式化，即使设备上已有数据。
- \`-L\` 或 \`--label\`：为文件系统设置标签。
- \`-b\` 或 \`--block-size\`：指定块大小。
- \`-i\` 或 \`--inode-size\`：指定 inode 大小。
- \`-n\` 或 \`--no-progress\`：不显示进度信息。
- \`-h\` 或 \`--help\`：显示帮助信息。

### 基本用法

#### 例1：创建 ext4 文件系统

在设备 \`/dev/sdX1\` 上创建一个 ext4 文件系统：

\`\`\`bash
sudo mkfs -t ext4 /dev/sdX1
\`\`\`

**输出解释**:
\`\`\`text
mke2fs 1.45.6 (20-Mar-2020)
Creating filesystem with 1048576 4k blocks and 262144 inodes
Filesystem UUID: a1b2c3d4-e5f6-7a8b-9c0d-e1f2g3h4i5j6
Superblock backups stored on blocks:
  32768, 98304, 163840, 229376, 294912

Allocating group tables: done
Writing inode tables: done
Creating journal (16384 blocks): done
Writing superblocks and filesystem accounting information: done
\`\`\`

**解读**:
输出显示了创建文件系统的进度和结果，包括超级块、inode 表、日志等信息。

#### 例2：创建 XFS 文件系统并设置标签

在设备 \`/dev/sdX2\` 上创建一个 XFS 文件系统，并设置标签为 \`mydata\`：

\`\`\`bash
sudo mkfs -t xfs -L mydata /dev/sdX2
\`\`\`

**输出解释**:
\`\`\`text
meta-data=/dev/sdX2              isize=512    agcount=4, agsize=65536 blks
       =                       sectsz=512   attr=0
data     =                       bsize=4096   blocks=2621440, imaxpct=25
       =                       sunit=0      swidth=0 blks
naming   =version 2              bsize=4096   ascii-ci=0
log      =internal log           bsize=4096   blocks=12800, version=2
       =                       sectsz=512   sunit=0 blks, lazy-count=1
realtime =none                   extsz=4096   blocks=0, rtextents=0
\`\`\`

**解读**:
输出提供了有关文件系统的详细信息，包括数据块大小、日志设置和文件系统标签。

### 不同架构下的表现差异

\`mkfs\` 命令在不同架构下的表现一致，因为它主要与设备和文件系统结构交互，不受 CPU 架构的影响。

### 不同版本的差异

\`mkfs\` 命令的功能在不同版本的 Linux 系统中保持一致，但可能会有一些新选项或功能改进。请查看命令的手册页或发行说明以了解版本间的差异。

### 替代命令

\`mkfs\` 是用于创建文件系统的标准命令。其他命令如 \`mkfs.ext4\`、\`mkfs.xfs\` 等可以用来创建特定类型的文件系统，但 \`mkfs\` 是通用的创建文件系统的工具。

### 安装包信息

\`mkfs\` 命令通常包含在 \`e2fsprogs\` 包中（用于 ext 系列文件系统）或 \`xfsprogs\` 包中（用于 XFS 文件系统）：

- **Ubuntu**:
  - **ext4**: 
    \`\`\`bash
    sudo apt-get install e2fsprogs
    \`\`\`
  - **XFS**:
    \`\`\`bash
    sudo apt-get install xfsprogs
    \`\`\`

- **RHEL**:
  - **ext4**:
    \`\`\`bash
    sudo yum install e2fsprogs
    \`\`\`
  - **XFS**:
    \`\`\`bash
    sudo yum install xfsprogs
    \`\`\`

**需要 root 权限的选项**:
- \`mkfs\` 命令通常需要 root 权限，因为格式化设备会修改系统文件结构。

**示例**:
\`\`\`bash
sudo mkfs -t ext4 /dev/sdX3
\`\`\`

**输出**:
\`\`\`text
mke2fs 1.45.6 (20-Mar-2020)
Creating filesystem with 5242880 4k blocks and 1310720 inodes
Filesystem UUID: a1b2c3d4-e5f6-7a8b-9c0d-e1f2g3h4i5j6
Superblock backups stored on blocks:
  32768, 98304, 163840, 229376, 294912

Allocating group tables: done
Writing inode tables: done
Creating journal (32768 blocks): done
Writing superblocks and filesystem accounting information: done
\`\`\`

**解读**:
使用 \`sudo\` 命令来获得 root 权限，成功创建文件系统后可以通过 \`lsblk\` 命令查看设备的文件系统类型。

        `,
    };
  },
});
</script>
<style scoped></style>
