<template>
  <div id="login">
    <div class="login-container">
      <h3>用户登录</h3>
      <el-form
        ref="loginForm"
        :model="loginForm"
        label-width="60px"
        class="login-form"
      >
        <el-form-item label="Email" prop="email">
          <el-input
            v-model.trim="loginForm.email"
            class="input-item"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model.trim="loginForm.password"
            class="input-item"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-link class="forgot-password" type="info" href="/faq"
            >忘记密码?</el-link
          >
          <el-link type="info" href="/register">没有账号? 注册账号</el-link>
        </el-form-item>
        <div class="button-container">
          <el-button type="primary" @click="login" round>登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      axios
        .post("http://sre.azlinux.com:8094/login", this.loginForm)
        .then((response) => {
          const token = response.data.access_token;
          localStorage.setItem("token", token);
          localStorage.setItem("email", "sre-" + this.loginForm.email);

          ElMessage({
            message: "登录成功，1秒后跳转到【用户中心】",
            type: "success",
            duration: 2000,
            offset: 120,
          });

          setTimeout(() => {
            window.location.href = "/user-center";
          }, 1000);
        })
        .catch((error) => {
          console.error("Login failed:", error);
          ElMessage({
            message: "登录失败！请检查输入，稍后重试。",
            type: "error",
            duration: 2000,
            offset: 120,
          });
        });
    },
  },
};
</script>

<style scoped>
#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.button-container {
  text-align: right;
}

.login-container {
  background-color: #ffffff;
  padding: 1.8rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 340px;
  text-align: center;
}

h3 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: bold;
}

.login-form {
  width: 100%;
}

.el-button {
  width: 50%;
}

.el-link {
  font-size: 12px;
  /* padding-left: 1rem; */
  margin-right: 8px;
}

.input-item:focus-within {
  border-color: #409eff;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.3);
  transition: box-shadow 0.3s ease;
}

.el-form-item {
  margin-bottom: 1.2rem;
}
</style>
