<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lsblk 命令详解

\`lsblk\` 命令用于列出系统中的块设备（如硬盘、分区和挂载点），它提供了设备的详细信息。这个命令对系统管理员非常重要，因为它可以帮助识别和管理存储设备。

### 基本用法

\`lsblk\` 命令的基本语法如下：

\`\`\`bash
lsblk [options]
\`\`\`

### 示例

假设我们要列出系统中的所有块设备，可以使用以下命令：

\`\`\`bash
lsblk
\`\`\`

假设输出如下：

\`\`\`plaintext
NAME   MAJ:MIN RM  SIZE RO TYPE MOUNTPOINT
sda      8:0    0  100G  0 disk 
├─sda1   8:1    0   50G  0 part /
├─sda2   8:2    0   30G  0 part /home
└─sda3   8:3    0   20G  0 part [SWAP]
sr0     11:0    1 1024M  0 rom  
\`\`\`

在这个示例中，\`lsblk\` 列出了三个设备（\`sda\` 和 \`sr0\`），包括每个设备的大小、类型和挂载点信息。

### 重要参数和功能

- **-a, --all**: 显示所有设备，包括不挂载的设备。

  示例：

  \`\`\`bash
  lsblk -a
  \`\`\`

  输出结果与基本用法相同，因为所有设备已被列出。

- **-f, --fs**: 显示文件系统信息。

  示例：

  \`\`\`bash
  lsblk -f
  \`\`\`

  输出结果：

  \`\`\`plaintext
  NAME   FSTYPE LABEL UUID                                 MOUNTPOINT
  sda                                                       
  ├─sda1 ext4         1234-5678-9abc-def0                 /
  ├─sda2 ext4         fedc-ba98-7654-3210                 /home
  └─sda3 swap         0987-6543-210f-edcb                 [SWAP]
  sr0                                                       
  \`\`\`

  该选项显示文件系统的类型、标签、UUID 和挂载点信息。

- **-o, --output**: 自定义输出字段。

  示例：

  \`\`\`bash
  lsblk -o NAME,SIZE,MOUNTPOINT
  \`\`\`

  输出结果：

  \`\`\`plaintext
  NAME   SIZE MOUNTPOINT
  sda    100G 
  ├─sda1  50G /
  ├─sda2  30G /home
  └─sda3  20G [SWAP]
  sr0   1024M
  \`\`\`

  该选项允许你指定要显示的字段，提供更灵活的输出格式。

- **-J, --json**: 输出 JSON 格式的结果。

  示例：

  \`\`\`bash
  lsblk -J
  \`\`\`

  输出结果：

  \`\`\`json
  {
    "blockdevices": [
      {
        "name": "sda",
        "maj:min": "8:0",
        "rm": false,
        "size": "100G",
        "ro": false,
        "type": "disk",
        "children": [
          {
            "name": "sda1",
            "maj:min": "8:1",
            "rm": false,
            "size": "50G",
            "ro": false,
            "type": "part",
            "mountpoint": "/"
          },
          {
            "name": "sda2",
            "maj:min": "8:2",
            "rm": false,
            "size": "30G",
            "ro": false,
            "type": "part",
            "mountpoint": "/home"
          },
          {
            "name": "sda3",
            "maj:min": "8:3",
            "rm": false,
            "size": "20G",
            "ro": false,
            "type": "part",
            "mountpoint": "[SWAP]"
          }
        ]
      },
      {
        "name": "sr0",
        "maj:min": "11:0",
        "rm": true,
        "size": "1024M",
        "ro": true,
        "type": "rom"
      }
    ]
  }
  \`\`\`

  该选项将输出结果以 JSON 格式显示，便于程序处理。

### 特殊用法示例

- **列出所有设备及其挂载点**

  \`\`\`bash
  lsblk -o NAME,SIZE,TYPE,MOUNTPOINT
  \`\`\`

  输出结果：

  \`\`\`plaintext
  NAME   SIZE TYPE MOUNTPOINT
  sda    100G disk 
  ├─sda1  50G part /
  ├─sda2  30G part /home
  └─sda3  20G part [SWAP]
  sr0   1024M rom  
  \`\`\`

  这个命令列出了所有设备的大小、类型和挂载点信息。

### 不同架构下的输出

\`lsblk\` 命令在不同架构下的输出格式和字段内容基本一致，但所显示的设备和大小可能有所不同。以下是 \`x86_64\` 和 \`aarch64\` 架构下的输出示例：

#### x86_64架构下

\`\`\`bash
lsblk
\`\`\`

输出结果：

\`\`\`plaintext
NAME   MAJ:MIN RM  SIZE RO TYPE MOUNTPOINT
sda      8:0    0  100G  0 disk 
├─sda1   8:1    0   50G  0 part /
├─sda2   8:2    0   30G  0 part /home
└─sda3   8:3    0   20G  0 part [SWAP]
sr0     11:0    1 1024M  0 rom  
\`\`\`

#### aarch64架构下

\`\`\`bash
lsblk
\`\`\`

输出结果：

\`\`\`plaintext
NAME   MAJ:MIN RM  SIZE RO TYPE MOUNTPOINT
sda      8:0    0  100G  0 disk 
├─sda1   8:1    0   50G  0 part /
├─sda2   8:2    0   30G  0 part /home
└─sda3   8:3    0   20G  0 part [SWAP]
sr0     11:0    1 1024M  0 rom  
\`\`\`

### 不同版本的差异

不同版本的 \`lsblk\` 可能会在输出字段和格式上有所不同。例如，较新版本可能支持更多的自定义选项，或改进了 JSON 输出格式。请参考系统上的文档以了解具体差异。

### 安装信息

- **Ubuntu/Debian**: \`lsblk\` 命令是 \`util-linux\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo apt-get install util-linux
  \`\`\`

- **RHEL/CentOS**: \`lsblk\` 命令也是 \`util-linux\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo yum install util-linux
  \`\`\`

### 使用root权限

\`lsblk\` 命令通常不需要 root 权限即可列出设备信息，但某些设备的详细信息可能需要相应的权限。如果需要查看特定的系统设备或修改设备配置，可能需要 root 权限。

### 总结

\`lsblk\` 是一个强大的工具，用于查看系统中的块设备及其详细信息。通过 \`lsblk\`，你可以轻松获取存储设备的布局、大小和挂载点信息，从而更好地管理系统存储。


        `,
    };
  },
});
</script>
<style scoped></style>
