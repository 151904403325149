<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## fdisk 命令详解

\`fdisk\` 是一个用于管理硬盘分区的命令行工具，主要用于创建、删除、调整分区大小等操作。它是一个非常强大的工具，适合高级用户和系统管理员使用。

### 安装

在大多数 Linux 发行版中，\`fdisk\` 默认已安装。若未安装，可以使用以下命令安装。

#### Ubuntu

\`\`\`bash
sudo apt install dosfstools
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install util-linux
\`\`\`

### 基本语法

\`\`\`bash
fdisk [选项] <设备>
\`\`\`

- **选项**：用于指定操作类型。
- **设备**：要操作的磁盘设备，例如 \`/dev/sda\`。

### 常用选项

- \`-l\`：列出所有分区的信息。
- \`-u\`：以扇区为单位显示分区大小。
- \`-s\`：显示特定分区的大小。
- \`-v\`：显示版本信息。

### 示例用法

#### 示例一：列出所有分区信息

可以使用 \`-l\` 选项列出当前系统中所有分区的信息：

\`\`\`bash
sudo fdisk -l
\`\`\`

**输出**：

\`\`\`plaintext
Disk /dev/sda: 500 GiB, 536870912000 bytes, 1048576000 sectors
Disk model: Samsung SSD 850
Units: sectors of 1 * 512 = 512 bytes
Sector size (logical/physical): 512 bytes / 512 bytes
I/O size (minimum/optimal): 512 bytes / 512 bytes
Disk label type: gpt
Disk identifier: 1DFA8B12-4C5D-43E5-A37A-F98F8479B5ED

Device          Start        End    Sectors  Size Type
/dev/sda1      2048     1048575    1046528  511M EFI System
/dev/sda2   1048576     2097151    1048576  511M Linux filesystem
/dev/sda3   2097152  1048575999  1046467848  500G Linux filesystem
\`\`\`

**解读**：该命令输出了磁盘 \`/dev/sda\` 的详细信息，包括总容量、分区表类型、每个分区的起始和结束扇区、大小以及类型。

#### 示例二：创建新分区

要创建一个新的分区，可以直接进入 \`fdisk\` 的交互模式：

\`\`\`bash
sudo fdisk /dev/sda
\`\`\`

在交互界面中，输入 \`n\` 来创建新分区。以下是可能的命令交互过程：

\`\`\`plaintext
Command (m for help): n
Partition type:
   p   primary (1 primary, 0 extended, 3 free)
   e   extended
Select (default p): p
Partition number (2-4, default 2): 
First sector (2097152-1048575999, default 2097152): 
Last sector, +sectors or +size{K,M,G,T,P} (2097152-1048575999, default 1048575999): +20G

Command (m for help): w
\`\`\`

**输出**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

**解读**：该操作成功创建了一个新的主分区，大小为 20G，并将更改写入磁盘。

#### 示例三：查看特定分区大小

使用 \`-s\` 选项可以查看特定分区的大小。例如，要查看 \`/dev/sda1\` 的大小：

\`\`\`bash
sudo fdisk -s /dev/sda1
\`\`\`

**输出**：

\`\`\`plaintext
524288000
\`\`\`

**解读**：该命令输出分区 \`/dev/sda1\` 的大小为 524288000 字节，约等于 500MB。

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上的 \`fdisk\` 命令输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

\`fdisk\` 可能会在不同版本的 \`util-linux\` 中有所不同。使用以下命令检查当前版本：

\`\`\`bash
fdisk -v
\`\`\`

**输出示例**：

\`\`\`plaintext
fdisk from util-linux 2.36
\`\`\`

在较早的版本中，某些功能可能不完全支持，例如新分区表类型。

### 权限要求

大多数 \`fdisk\` 操作需要 root 权限，因此在执行时需要使用 \`sudo\`。以下是一个示例：

\`\`\`bash
sudo fdisk /dev/sda
\`\`\`

#### 示例输出

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 总结

\`fdisk\` 是一个功能强大的硬盘分区管理工具。它允许用户创建、删除和调整分区大小，适用于需要管理硬盘分区的高级用户和系统管理员。掌握 \`fdisk\` 的使用，对于系统的磁盘管理至关重要。

        `,
    };
  },
});
</script>
<style scoped></style>
