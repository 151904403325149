<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## umount 命令详解

\`umount\` 是 Linux 系统中用于卸载已挂载文件系统的命令。它主要用于安全地将设备从文件系统中分离，防止数据损坏或丢失。通过 \`umount\`，用户可以卸载分区、磁盘、网络文件系统等。

本篇文章将详细介绍 \`umount\` 命令的常用功能、参数，并展示该命令在卸载文件系统时的实际用法。

### 1. \`umount\` 命令概述

\`umount\` 是一个外部命令，而不是 bash 内置命令。它允许系统管理员和普通用户在不再需要访问文件系统时卸载设备，避免数据损坏。\`umount\` 命令必须确保设备不再被任何进程使用，否则卸载操作会失败。

#### 命令格式：
\`\`\`bash
umount [选项] <挂载点或设备名>
\`\`\`
该命令可以接受设备名称或挂载点来卸载设备。

### 2. 常用选项与参数

#### 2.1 基本卸载文件系统
直接使用设备路径或挂载点卸载文件系统是 \`umount\` 的基本用法。

#### 示例命令：
\`\`\`bash
umount /dev/sdb1
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令卸载了设备 \`/dev/sdb1\`，即该设备上的文件系统已被成功分离。

#### 示例命令：
\`\`\`bash
umount /mnt/usb
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令卸载了挂载在 \`/mnt/usb\` 目录的设备。

#### 2.2 \`-f\` 选项 - 强制卸载
在某些情况下，例如网络文件系统（NFS）无法正常响应时，可以使用 \`-f\` 选项强制卸载。此选项对 NFS 文件系统非常有用。

#### 示例命令：
\`\`\`bash
umount -f /mnt/nfs_share
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
使用 \`-f\` 强制卸载了无法正常响应的 NFS 挂载点 \`/mnt/nfs_share\`。

#### 2.3 \`-l\` 选项 - 懒卸载
\`-l\`（lazy）选项用于懒卸载，它允许用户在文件系统仍然被进程使用时标记为卸载，待进程不再访问时真正执行卸载。

#### 示例命令：
\`\`\`bash
umount -l /mnt/data
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令标记 \`/mnt/data\` 文件系统为懒卸载，待进程不再使用后文件系统将自动卸载。

#### 2.4 \`-r\` 选项 - 卸载失败时重新挂载为只读
当 \`umount\` 命令因文件系统错误而无法成功卸载时，\`-r\` 选项会将文件系统重新挂载为只读模式，以防止进一步的数据损坏。

#### 示例命令：
\`\`\`bash
umount -r /mnt/faulty_disk
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令在 \`/mnt/faulty_disk\` 文件系统发生错误时，将其重新挂载为只读模式。

#### 2.5 \`-v\` 选项 - 显示详细信息
使用 \`-v\`（verbose）选项可以让 \`umount\` 显示详细的操作信息，便于调试或监控卸载过程。

#### 示例命令：
\`\`\`bash
umount -v /mnt/backup
\`\`\`
**输出：**
\`\`\`
/mnt/backup umounted
\`\`\`
**解读：**
此命令卸载了 \`/mnt/backup\` 文件系统，并输出详细的卸载信息。

### 3. 使用 root 权限的 umount

在某些情况下，普通用户无法卸载系统上的某些文件系统，例如系统分区或其他用户的挂载点。此时需要使用 \`sudo\` 提升权限。

#### 示例命令：
\`\`\`bash
sudo umount /mnt/sys
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
此命令以 root 权限成功卸载了系统分区 \`/mnt/sys\`。

### 4. 处理占用文件系统的进程

有时卸载文件系统会失败，提示文件系统正在被使用。可以通过以下命令查看占用该文件系统的进程，并选择杀掉相关进程后再执行卸载。

#### 示例命令：
\`\`\`bash
lsof /mnt/usb
\`\`\`
**输出：**
\`\`\`
COMMAND   PID  USER   FD   TYPE DEVICE SIZE/OFF NODE NAME
bash      1234 sre    cwd   DIR   8,1     4096    2 /mnt/usb
\`\`\`
**解读：**
此命令显示了进程 \`bash\` 正在占用 \`/mnt/usb\` 目录，PID 为 1234。

#### 杀掉进程后再尝试卸载：
\`\`\`bash
kill 1234
umount /mnt/usb
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
在杀掉占用进程后成功卸载 \`/mnt/usb\`。

### 5. 多用户场景下的 umount

在多用户系统中，不同用户可能挂载了各自的设备。管理员可以查看并卸载其他用户的挂载设备。

#### 示例命令：
\`\`\`bash
sudo -u dev01 umount /mnt/dev01_disk
\`\`\`
**输出：**
【无输出，命令已成功执行】

**解读：**
该命令卸载了用户 \`dev01\` 的挂载设备 \`/mnt/dev01_disk\`。

### 6. 不同架构下的输出差异

\`umount\` 命令在 \`aarch64\` 架构和 \`x86_64\`/\`amd64\` 架构下的表现没有差异，输出结果一致。因此，这部分内容不需要额外说明。

### 7. \`umount\` 版本差异

不同版本的 \`umount\` 在某些高级功能上可能有所不同。较旧版本可能缺乏懒卸载或强制卸载的支持。在较新版本中，这些功能已成为标准。

#### 示例命令：
\`\`\`bash
umount --version
\`\`\`
**输出：**
\`\`\`
umount from util-linux 2.34
\`\`\`
**解读：**
该命令输出了 \`umount\` 命令的版本信息，在 \`util-linux\` 包中提供。

### 8. 安装 umount

\`umount\` 通常随系统内核一起提供，作为 \`util-linux\` 包的一部分。在极少数情况下，如果 \`umount\` 命令缺失，可以手动安装。

#### 在 Ubuntu 系统中安装：
\`\`\`bash
sudo apt install util-linux
\`\`\`

#### 在 RHEL/CentOS 系统中安装：
\`\`\`bash
sudo yum install util-linux
\`\`\`

### 结论

\`umount\` 命令是管理 Linux 文件系统的关键工具，用于安全地卸载设备、分区或网络文件系统。它支持强制卸载、懒卸载、详细输出等多种操作模式，并提供多种选项以适应不同的场景。在使用时，应确保没有进程占用相关设备，否则需要杀掉进程或使用 \`-l\` 选项执行懒卸载。

通过学习本文中提到的 \`umount\` 命令及其相关参数，系统管理员可以有效地管理系统中的挂载和卸载任务，确保数据的完整性与安全性。

        `,
    };
  },
});
</script>
<style scoped></style>
