<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## pkill 命令详解

\`pkill\` 命令是用于根据指定的条件杀死进程的工具。它可以通过进程名、用户、组、终端等多种条件来匹配和终止进程。这个命令在系统管理和进程控制中非常有用。本文将详细介绍 \`pkill\` 命令的使用方法、重要参数、输出示例及其应用场景。

### 基本用法

\`pkill\` 的基本语法如下：

\`\`\`bash
pkill [选项] <模式>
\`\`\`

- **选项**: 指定过滤条件或输出格式的选项。
- **模式**: 用于匹配进程的名称或其他特征。

### 重要参数

1. **-u, --user <用户>**
   - 杀死指定用户的所有进程。
   - 例如，\`pkill -u sre\` 将终止所有属于用户 \`sre\` 的进程。

2. **-t, --tty <终端>**
   - 通过终端识别进程。
   - 例如，\`pkill -t tty1\` 将终止所有在 \`tty1\` 终端上运行的进程。

3. **-f, --full**
   - 匹配完整的命令行，而不仅仅是进程名。
   - 例如，\`pkill -f 'python myscript.py'\` 将终止所有运行 \`python myscript.py\` 的进程。

4. **-n, --newest**
   - 只终止最新创建的进程。
   - 例如，\`pkill -n firefox\` 只终止最近启动的 \`firefox\` 进程。

5. **-o, --oldest**
   - 只终止最早创建的进程。
   - 例如，\`pkill -o firefox\` 只终止最早启动的 \`firefox\` 进程。

6. **-s, --signal <信号>**
   - 发送指定的信号，而不是默认的 \`TERM\` 信号。
   - 例如，\`pkill -9 firefox\` 发送 \`SIGKILL\` 信号，强制终止 \`firefox\` 进程。

### 示例

以下示例展示了 \`pkill\` 命令的实际应用：

1. **终止所有名为 \`firefox\` 的进程**

    \`\`\`bash
    pkill firefox
    \`\`\`

    输出: 【无输出，命令已成功执行】

2. **终止用户 \`sre\` 的所有进程**

    \`\`\`bash
    pkill -u sre
    \`\`\`

    输出: 【无输出，命令已成功执行】

3. **终止最早启动的 \`ssh\` 进程**

    \`\`\`bash
    pkill -o ssh
    \`\`\`

    输出: 【无输出，命令已成功执行】

4. **使用 \`SIGKILL\` 终止所有名为 \`apache2\` 的进程**

    \`\`\`bash
    pkill -9 apache2
    \`\`\`

    输出: 【无输出，命令已成功执行】

### 输出解析

\`pkill\` 命令通常不会产生输出。如果命令成功执行但没有匹配到任何进程，或者没有要终止的进程，\`pkill\` 不会返回任何信息。如果命令有误或者没有权限执行，它会显示错误信息，例如：

\`\`\`bash
pkill: 失败: 没有这样的进程
\`\`\`

### 注意事项

- **权限**: 使用 \`pkill\` 需要相应的权限。如果你尝试终止其他用户的进程，可能需要 \`root\` 权限。
- **安全性**: 在使用 \`pkill\` 时，要谨慎使用广泛的匹配模式或信号，确保不会错误终止重要的系统进程。

### 安装

\`pkill\` 命令通常包含在 \`procps\` 包中，该包在大多数 Linux 发行版中默认安装。如果未安装，可以通过以下命令进行安装：

- **Ubuntu**:
    \`\`\`bash
    sudo apt-get install procps
    \`\`\`

- **RHEL**:
    \`\`\`bash
    sudo yum install procps-ng
    \`\`\`

### 总结

\`pkill\` 是一个强大的工具，用于根据各种条件终止进程。掌握其参数和用法，可以帮助管理员高效地管理系统进程。通过本文的介绍，您应能够使用 \`pkill\` 进行进程管理，并理解其输出和使用场景。

        `,
    };
  },
});
</script>
<style scoped></style>
