<template>
  <div>
    <GameCard1
      title="体验ubuntu镜像"
      actions="运行ubuntu镜像，容器启动后，连接体验"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
## 体验ubuntu镜像

以 Ubuntu 为例，探讨镜像内的用户与宿主机用户之间的关系。此外，我们还将对比启动 Docker 容器与传统虚拟机的耗时，从而体现 Docker 的快速启动特点。

## 启动 Docker 镜像的命令

要启动一个 Ubuntu 镜像，可以使用以下命令：

\`\`\`bash
docker run -it ubuntu
\`\`\`

### 命令解析

- \`docker run\`: 这是启动一个新容器的命令。
- \`-it\`: 这个选项组合使容器在交互模式下运行，并分配一个伪终端。\`-i\` 表示保持标准输入开放，\`-t\` 表示分配一个伪终端。
- \`ubuntu\`: 指定要运行的镜像名称，这里是 Ubuntu 的官方镜像。

### 默认运行的命令

在没有指定 \`CMD\` 或 \`ENTRYPOINT\` 的情况下，Docker 会默认运行镜像中指定的命令。对于 Ubuntu 镜像，默认命令是 \`/bin/bash\`。这意味着在启动容器后，你会进入一个 Bash shell。

### 测试的命令

在进入容器后，你可以执行以下命令来测试和探索 Ubuntu 容器：

1. **查看系统信息**:
   \`\`\`bash
   uname -a
   \`\`\`
   这会显示内核信息、主机名等，和宿主机输出相同，因为使用了同一个内核!

2. **查看当前用户**:
   \`\`\`bash
   whoami
   \`\`\`
   默认情况下，你在容器中以 \`root\` 用户身份运行。

3. **创建和查看文件**:
   \`\`\`bash
   echo "Hello, Docker!" > test.txt
   cat test.txt
   \`\`\`
   创建一个文件并查看其内容。

### 启动过程

1. **镜像查找**: Docker 会在本地查找名为 \`ubuntu\` 的镜像。如果找不到，Docker 会从 Docker Hub 拉取最新版本的 Ubuntu 镜像。

2. **创建容器**: Docker 根据镜像创建一个新的容器实例。

3. **分配资源**: Docker 为容器分配必要的系统资源，如网络、存储和计算资源。

4. **运行命令**: Docker 启动一个进程，通常是镜像内的默认命令，Ubuntu 的默认命令是 \`/bin/bash\`。

5. **用户交互**: 当容器启动后，用户会被带入到 Ubuntu 的命令行环境，可以直接与容器进行交互。

## 镜像内用户与宿主机用户

在 Docker 容器内，默认用户通常是 \`root\` 用户。相比之下，宿主机用户是在宿主操作系统上登录的用户。以下是它们之间的比较：

- **宿主机用户**:
  - 通常是一个具有特定权限的用户，例如 \`zhangsan\`，\`app\`，\`root\`。
  - 具有访问宿主机资源的权限。
  - 生产环境中，尽量避免使用\`root\`，推荐使用特定的用户或角色来提高安全性。

- **镜像内用户**:
  - 默认是 \`root\` 用户，这意味着可以在容器内执行任何操作。
  - 容器内的用户权限与宿主机用户是隔离的，确保了安全性。

这种隔离让开发者能够在 Docker 容器中运行应用程序，而不必担心对宿主机环境造成影响。

## 启动时间对比：Docker 容器与虚拟机

传统的虚拟机（VM）需要完整的操作系统来运行，启动过程包括以下步骤：

1. 加载虚拟机监控程序（Hypervisor）。
2. 启动操作系统。
3. 初始化系统服务。

这个过程通常需要数十秒甚至更长时间，具体取决于操作系统的大小和复杂性。

相对而言，Docker 容器的启动时间显著缩短，因为它们共享宿主机的操作系统内核。Docker 只需启动应用程序和其依赖项，因此启动时间通常在几秒钟之内。

例如：
- **启动虚拟机**: 约 30 秒至数分钟（视具体情况而定）。
- **启动 Docker 容器**: 约 1 秒到 5 秒（根据镜像大小和宿主机性能）。

这种快速启动的特性使得 Docker 成为现代开发和部署流程中不可或缺的工具，尤其是在微服务架构和持续集成/持续交付（CI/CD）流程中。

## 结论

通过上述内容，我们了解到使用 Docker 启动镜像的基本命令和过程，以及镜像内用户与宿主机用户之间的关系。同时，Docker 容器的快速启动时间相较于传统虚拟机的启动时间展现了其高效性和灵活性。这些特性使得 Docker 成为开发人员和运维人员在现代应用程序交付中的首选工具。

## Docker镜像没有缺点吗？

启动 Docker 的 Ubuntu 镜像后，相比于传统虚拟机（VM）中的 Ubuntu 环境，Docker 容器可能会缺少以下几个特性和功能：

### 1. **完整的操作系统**
- **虚拟机**: 虚拟机运行的是一个完整的操作系统，包括内核和用户空间，提供完整的操作系统特性。
- **Docker 容器**: 容器共享宿主机的操作系统内核，因此不能提供完整的操作系统特性。容器只包含运行特定应用所需的库和依赖，通常是一个简化的环境。

### 2. **系统服务与守护进程**
- **虚拟机**: 在虚拟机中，可以运行所有类型的系统服务和守护进程，用户可以启动和管理各种系统服务，如数据库服务、Web 服务器等。
- **Docker 容器**: Docker 容器通常是一次性任务，旨在运行单一应用程序或服务。虽然可以在容器中运行服务，但不推荐在一个容器中运行多个服务。对于需要多个服务的应用，建议使用多个容器（微服务架构）。

### 3. **内核功能和特性**
- **虚拟机**: 每个虚拟机都有自己的内核，这意味着它们可以使用任何内核特性，如内核模块、特定的文件系统等。
- **Docker 容器**: 容器使用宿主机的内核，无法修改内核设置或加载内核模块，因此不能使用需要特定内核特性的应用。

### 4. **设备访问**
- **虚拟机**: 虚拟机可以直接访问宿主机的硬件资源，甚至可以通过虚拟设备访问各种硬件，如 USB 设备、显卡等。
- **Docker 容器**: 容器默认不允许访问宿主机的硬件。虽然可以使用 \`--device\` 参数来访问某些设备，但容器的硬件访问受到更多限制。

### 5. **图形用户界面 (GUI)**
- **虚拟机**: 可以运行带有完整图形用户界面的应用程序，可以访问桌面环境。
- **Docker 容器**: 容器通常不提供图形界面，主要用于无头（headless）应用程序，虽然可以通过一些方法（如 X11 转发）实现图形界面，但这不是 Docker 的主要使用场景。

### 6. **持久化存储**
- **虚拟机**: 虚拟机的磁盘映像通常是持久化的，数据可以在重启虚拟机后保留。
- **Docker 容器**: 容器的文件系统是临时的，容器停止后其所有的变更（除非使用数据卷或持久化存储）都会丢失。因此，对于需要持久化存储的应用，需要特别配置数据卷。

### 总结
虽然 Docker 容器提供了快速、轻量级的环境，适合开发和部署微服务，但在完整的操作系统特性、系统服务、内核功能和设备访问等方面与虚拟机相比存在一些限制。使用 Docker 时，需要根据应用的特性和需求选择合适的解决方案。

当你使用 \`docker run -it ubuntu\` 启动 Ubuntu 容器时，你实际上是在运行一个 Ubuntu 镜像并进入一个交互式终端。以下是对容器的行为及默认运行的命令的详细解释。        
        `,
      interviewContent: `
### 选择题

1. **\`docker run -it ubuntu\` 中的 \`-it\` 参数的主要作用是什么？**
   - A) 运行一个新的容器
   - B) 保持标准输入开放并分配一个伪终端
   - C) 显示容器的日志
   - D) 删除容器

2. **运行 \`docker run -it ubuntu\` 后，默认进入哪个命令行环境？**
   - A) \`/bin/sh\`
   - B) \`/bin/bash\`
   - C) \`/bin/zsh\`
   - D) \`/usr/bin/bash\`

3. **执行 \`docker run -it ubuntu\` 时，如果镜像不存在，Docker 会如何处理？**
   - A) 返回错误，停止执行
   - B) 自动下载最新的 Ubuntu 镜像
   - C) 运行本地已存在的其他镜像
   - D) 询问用户是否要下载镜像

4. **在容器内部，你默认以哪个用户身份运行？**
   - A) ubuntu
   - B) root
   - C) admin
   - D) guest

### 问答题

1. **解释 \`docker run -it ubuntu\` 命令的作用。**
   
2. **启动 Ubuntu 容器后，你可以执行哪些命令来验证容器的功能？请列举至少三种。**

3. **与传统虚拟机相比，Docker 容器启动速度较快的原因是什么？**

4. **在容器中，你如何检查当前用户的权限和身份？**

### 答案

**选择题答案：**
1. B) 保持标准输入开放并分配一个伪终端
2. B) \`/bin/bash\`
3. B) 自动下载最新的 Ubuntu 镜像
4. B) root

**问答题答案：**
1. \`docker run -it ubuntu\` 命令的作用是基于指定的 Ubuntu 镜像启动一个新的容器，并提供一个交互式的 Bash shell，允许用户与容器进行交互。
  
2. 启动 Ubuntu 容器后，可以执行以下命令来验证功能：
   - \`uname -a\`（查看系统信息）
   - \`whoami\`（查看当前用户）
   - \`apt update\`（更新包索引）

3. Docker 容器启动速度较快的原因在于容器共享宿主机的内核，避免了虚拟机启动时需要加载整个操作系统的过程，因而能更迅速地启动。

4. 在容器中，可以使用 \`whoami\` 命令检查当前用户的身份，或使用 \`id\` 命令查看用户的权限和 UID/GID 信息。
      
      `,
    };
  },
});
</script>
<style scoped></style>
