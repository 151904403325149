<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ss 命令详解

\`ss\` 命令是用于显示 Linux 系统中网络连接的工具，它被认为是 \`netstat\` 命令的更现代化和更高效的替代品。通过 \`ss\`，用户可以快速查看系统的网络连接状态，包括 TCP、UDP 和 UNIX 套接字。

### 基本语法

\`\`\`bash
ss [选项]
\`\`\`

### 主要功能

1. **查看网络连接**：显示当前系统的所有网络连接，包括状态和相关信息。
2. **过滤和格式化输出**：提供多种选项，可以按协议、状态等过滤输出。
3. **实时监控**：可以用于实时监控网络连接的变化。

### 常用选项

- \`-t\`：显示 TCP 套接字。
- \`-u\`：显示 UDP 套接字。
- \`-a\`：显示所有套接字，包括监听和非监听状态。
- \`-n\`：以数字形式显示地址和端口，不进行 DNS 解析。
- \`-p\`：显示与每个套接字关联的进程信息。

### 示例一：查看所有网络连接

使用 \`ss\` 查看所有的网络连接。

\`\`\`bash
ss -a
\`\`\`

**输出：**

\`\`\`plaintext
Netid State      Recv-Q Send-Q  Local Address:Port     Peer Address:Port
tcp   LISTEN     0      128     0.0.0.0:22            0.0.0.0:*       
tcp   ESTAB      0      0       192.168.1.5:22        192.168.1.100:54321
\`\`\`

**解读**：此输出列出了当前系统上的所有网络连接。\`State\` 列显示连接的状态，\`Local Address:Port\` 列显示本地地址和端口，\`Peer Address:Port\` 列显示对端的地址和端口。

### 示例二：查看 TCP 连接

使用 \`ss\` 查看所有 TCP 连接。

\`\`\`bash
ss -t -a
\`\`\`

**输出：**

\`\`\`plaintext
Netid State      Recv-Q Send-Q  Local Address:Port     Peer Address:Port
tcp   LISTEN     0      128     0.0.0.0:22            0.0.0.0:*       
tcp   ESTAB      0      0       192.168.1.5:22        192.168.1.100:54321
\`\`\`

**解读**：此输出只显示了 TCP 连接，其他信息与之前的示例相同。

### 示例三：查看与进程关联的连接

使用 \`ss\` 查看与进程关联的连接。

\`\`\`bash
ss -p
\`\`\`

**输出：**

\`\`\`plaintext
Netid State      Recv-Q Send-Q  Local Address:Port     Peer Address:Port      Process
tcp   LISTEN     0      128     0.0.0.0:22            0.0.0.0:*             sshd
\`\`\`

**解读**：此命令显示了与每个套接字关联的进程信息。在这个示例中，\`sshd\` 进程在监听 22 端口。

### 架构差异

\`ss\` 命令在 aarch64 和 x86_64/amd64 架构下的输出表现没有差异，因此无需列出不同架构的输出。

### 版本差异

\`ss\` 命令的输出和可用选项可能会因不同版本的 \`iproute2\` 工具包而有所不同。一般来说，较新版本会增加更多功能和选项。建议查看手册页 (\`man ss\`) 获取具体版本的信息。

### 安装方法

在大多数 Linux 发行版中，\`ss\` 命令是 \`iproute2\` 工具包的一部分。可以通过以下方式确认是否已安装：

- **Ubuntu/Debian**：

  \`\`\`bash
  dpkg -l | grep iproute2
  \`\`\`

- **RHEL/CentOS**：

  \`\`\`bash
  rpm -q iproute
  \`\`\`

### 权限

通常，\`ss\` 命令不需要特定的权限。任何用户都可以使用它来查看网络连接状态，但使用 \`-p\` 选项可能需要更高的权限来显示某些进程的信息。

### 总结

\`ss\` 命令是一个强大且高效的工具，用于查看和监控 Linux 系统的网络连接。通过本文的详细介绍，用户可以更好地理解和使用 \`ss\` 命令，以实现对网络连接状态的高效管理和监控。

        `,
    };
  },
});
</script>
<style scoped></style>
