<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## date 命令详解

\`date\` 命令用于显示和设置系统的日期和时间。它是系统管理中一个非常重要的工具，特别是在脚本编写和调试时。本文将详细介绍 \`date\` 命令的使用方法及其常见参数，并通过示例来说明其用法。

### 基本语法

\`date\` 命令的基本语法如下：

\`\`\`bash
date [选项] [+格式]
\`\`\`

其中，\`选项\` 用于指定显示或设置日期和时间的方式，\`+格式\` 用于指定输出格式。

### 显示当前日期和时间

默认情况下，\`date\` 命令会显示当前的日期和时间：

\`\`\`bash
$ date
\`\`\`

**输出：**

\`\`\`
Sat Sep  7 14:53:12 UTC 2024
\`\`\`

**解读：**
此命令显示了当前的日期和时间，格式为：星期 月 日 时:分:秒 时区 年份。

### 格式化输出

通过 \`+格式\` 选项，\`date\` 命令可以以自定义的格式显示日期和时间。常见的格式符号包括：

- \`%Y\`：年份（例如 2024）
- \`%m\`：月份（01 到 12）
- \`%d\`：日期（01 到 31）
- \`%H\`：小时（00 到 23）
- \`%M\`：分钟（00 到 59）
- \`%S\`：秒（00 到 59）

例如，显示日期和时间的自定义格式：

\`\`\`bash
$ date "+%Y-%m-%d %H:%M:%S"
\`\`\`

**输出：**

\`\`\`
2024-09-07 14:53:12
\`\`\`

**解读：**
此命令显示当前日期和时间的格式为“年-月-日 时:分:秒”。

### 设置系统时间

\`date\` 命令也可以用于设置系统的日期和时间。需要注意的是，设置系统时间通常需要超级用户权限。

#### 设置日期和时间

例如，将系统时间设置为 2024 年 9 月 7 日 15:00:00：

\`\`\`bash
$ sudo date -s "2024-09-07 15:00:00"
\`\`\`

**输出：**
\`\`\`
【无输出，命令已成功执行】
\`\`\`
**解读：**
此命令将系统时间设置为指定的日期和时间。使用 \`sudo\` 权限来执行此操作。

### 显示 UTC 时间

可以使用 \`-u\` 选项显示 UTC 时间：

\`\`\`bash
$ date -u
\`\`\`

**输出：**

\`\`\`
Sat Sep  7 14:53:12 UTC 2024
\`\`\`

**解读：**
此命令显示了当前的 UTC 时间，与本地时间相对应。

### 设置系统日期（只设置日期）

例如，将系统日期设置为 2024 年 9 月 7 日：

\`\`\`bash
$ sudo date -s "2024-09-07"
\`\`\`

**输出：**

\`\`\`
【无输出，命令已成功执行】
\`\`\`
**解读：**
此命令将系统日期设置为指定的日期，时间部分保持不变。通常需要 \`sudo\` 权限来执行此操作。

### 计算未来或过去的时间

\`date\` 命令还支持计算相对时间。例如，显示未来 7 天的日期：

\`\`\`bash
$ date -d "+7 days"
\`\`\`

**输出：**

\`\`\`
Sat Sep 14 14:53:12 UTC 2024
\`\`\`

**解读：**
此命令显示了当前日期加 7 天后的日期和时间。

### 使用时间戳显示时间

可以将时间戳转换为日期和时间。例如，将时间戳 \`1694089200\` 转换为日期：

\`\`\`bash
$ date -d @1694089200
\`\`\`

**输出：**

\`\`\`
Sat Sep  7 15:00:00 UTC 2024
\`\`\`

**解读：**
此命令将给定的时间戳转换为相应的日期和时间。

### 总结

\`date\` 命令是一个功能强大的工具，可以用来显示和设置系统的日期和时间。通过使用各种选项和格式化符号，您可以根据需要定制日期和时间的显示方式。同时，\`date\` 还支持计算未来或过去的时间、转换时间戳等功能。在系统管理和脚本编写中，掌握 \`date\` 命令的用法可以帮助您更高效地处理与时间相关的任务。

        `,
    };
  },
});
</script>
<style scoped></style>
