<template>
  <div>
    <h3 class="page-title">玩转专项，so easy！技术深度，精益求精</h3>
    <el-row :gutter="20">
      <el-col :span="8" v-for="t in topics" :key="t.name">
        <router-link :to="t.route">
          <el-card class="box-card">
            <img :src="t.imgSrc" class="image" />
            <div class="card-content">
              <div class="card-header">
                <span class="card-title">{{ t.name }}</span>
              </div>
              <div class="text-item">
                {{ t.intro }}
              </div>
            </div>
          </el-card>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "SpecialSkills",
  data() {
    return {
      topics: [
        {
          name: "Docker",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/docker_logo.png",
          intro: "经典的容器，标准的进程运行环境，快速发放应用",
          route: "/docker",
        },
        {
          name: "Podman",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/podman_logo.png",
          intro: "不需要root权限即可运行的容器环境",
          route: "/podman",
        },
        {
          name: "Nginx",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/nginx_logo.png",
          intro: "高性能的HTTP和反向代理服务器，工程师必会",
          route: "/nginx",
        },
        {
          name: "Redis",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/redis_logo.png",
          intro: "流行的高性能内存中键值存储(key-value)系统",
          route: "/redis",
        },
        {
          name: "Zookeeper",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/zookeeper_logo.png",
          intro: "a high-performance web server and reverse proxy server",
          route: "/zookeeper",
        },
        {
          name: "Git",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/git_logo.png",
          intro: "广泛使用的分布式版本控制系统，主要指git命令",
          route: "/git",
        },
        {
          name: "Github",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/github_logo.png",
          intro: "互联网软件开发的重要网站之一，版本控制、代码托管和分享平台",
          route: "/github",
        },
        {
          name: "RabbitMQ",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/rabbitmq_logo.png",
          intro: "高效可靠的消息队列中间件",
          route: "/rabbitmq",
        },

        {
          name: "Kubernetes",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/kubernetes_logo.png",
          intro: "管理容器化应用程序的开源平台",
          route: "/kubernetes",
        },
        {
          name: "Minio",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/minio_logo.png",
          intro: "提供高性能、与S3兼容的对象存储系统，使用和部署非常简单",
          route: "/minio",
        },
        {
          name: "Markdown",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/markdown_logo.png",
          intro:
            "十分简单的标记语言，让人们更多地关注写文档的内容本身，最后渲染成HTML",
          route: "/markdown",
        },
        {
          name: "Kafka",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/kafka_logo.png",
          intro: "流处理平台，提供消息的订阅与发布的消息队列",
          route: "/kafka",
        },
        {
          name: "Zabbix",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/zabbix_logo.png",
          intro: "经典监控工具",
          route: "/zabbix",
        },
        {
          name: "Grafana",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/grafana_logo.png",
          intro: "监控数据的可视化图形展示平台",
          route: "/grafana",
        },
        {
          name: "Keepalived",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/keepalived_logo.png",
          intro: "虚拟路由冗余协议来实现服务或者网络的高可用",
          route: "/keepalived",
        },
        {
          name: "HAProxy",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/haproxy_logo.png",
          intro: "高性能TCP和HTTP负载均衡",
          route: "/haproxy",
        },
        {
          name: "KVM",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/kvm_logo.png",
          intro: "基于内核的虚拟机（KVM）",
          route: "/kvm",
        },
        {
          name: "confluence",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/confluence_logo.png",
          intro:
            "专业的团队知识管理与协同软件，成员之间共享信息、文档协作、集体讨论，信息推送。",
          route: "/confluence",
        },
        {
          name: "prometheus",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/prometheus_logo.png",
          intro: "基于时序数据库的开源监控告警系统，监控后起之秀",
          route: "/prometheus",
        },
        {
          name: "etcd",
          imgSrc:
            "http://azlinux.oss-cn-zhangjiakou.aliyuncs.com/sre/bg/topics/etcd_logo.png",
          intro: "分布式键值对存储，可靠而快速的保存关键数据并提供访问",
          route: "/etcd",
        },
      ],
    };
  },
};
</script>

<style scoped>
.box-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 12px 0;
}

.box-card:hover {
  background: linear-gradient(135deg, #f5f7f9, #c3cfe2);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.image {
  height: auto;
  height: 60px;
  object-fit: fit;
  border-radius: 8px;
  background-repeat: no-repeat;
}

.card-content {
  padding: 15px;
}

.card-header {
  margin-bottom: 10px;
  text-decoration: none;
}

.card-title {
  font-weight: 700;
  font-size: 18px;
  color: #333;
}

.text-item {
  color: #666;
  font-size: 14px;
}

.page-title {
  font-size: 21px;
  font-weight: bold;
  color: #2c3e50;
  text-align: right;
  margin: 10px 1rem;
  letter-spacing: 2px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
a {
  text-decoration: none; /* 去除下划线 */
}

.router-link-active {
  text-decoration: none; /* 确保活动链接也没有下划线 */
}
</style>
