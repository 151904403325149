<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## su 命令详解

\`su\` 命令是 Linux 系统中一个重要的命令，用于切换用户身份。通过 \`su\` 命令，用户可以在当前会话中切换到其他用户的身份，执行命令或进行系统操作。本文将详细介绍 \`su\` 命令的用法、常见参数、不同架构和版本下的输出差异，以及相关的替代命令和安装方法。

### 什么是 \`su\`

\`su\` 是 "substitute user" 或 "switch user" 的缩写，表示“切换用户”。在 Linux 系统中，用户可以使用 \`su\` 命令切换到另一个用户的身份，而不必注销当前会话。这在需要执行某些特定用户权限的操作时非常有用。

#### Bash 内置命令与外部命令的区别

\`su\` 也是一个外部命令，而不是 Bash 的内置命令。这意味着当你在终端中使用 \`su\` 时，实际上是调用了系统中的 \`su\` 程序，而不是直接在 Bash 中执行的一个内置操作。

### \`su\` 命令的常见用法

#### 基本语法

\`\`\`bash
su [选项] [用户名]
\`\`\`

- \`[用户名]\`：可选参数，如果不指定，默认切换到 root 用户。
- \`[选项]\`：可选参数，用于控制 \`su\` 的行为。

#### 示例1：切换到 root 用户

在不指定用户名的情况下，\`su\` 默认切换到 root 用户：

\`\`\`bash
su -
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ su -
Password: 
[root@azlinux-prod-cn ~]#
\`\`\`

输出解释：此命令切换到 root 用户，需要输入 root 用户的密码。切换成功后，终端提示符从 \`$\` 变为 \`#\`，表示当前用户为 root。

#### 示例2：切换到其他用户

指定用户名可以切换到其他用户，例如 \`dev01\` 用户：

\`\`\`bash
su - dev01
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ su - dev01
Password: 
dev01@azlinux-prod-cn:~$
\`\`\`

输出解释：此命令切换到 \`dev01\` 用户，需要输入 \`dev01\` 用户的密码。切换成功后，终端提示符变为该用户的提示符。

#### 示例3：在不加载用户环境的情况下切换用户

使用 \`-m\` 或 \`-p\` 选项可以保持当前环境变量不变：

\`\`\`bash
su -m dev01
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ su -m dev01
Password: 
dev01@azlinux-prod-cn:~$ echo $HOME
/home/sre
\`\`\`

输出解释：此命令切换到 \`dev01\` 用户，但保留了当前用户（\`sre\`）的环境变量 \`$HOME\`。

### 常用参数

- \`-\` 或 \`-l\`：模拟登录，加载目标用户的环境变量。

\`\`\`bash
su - dev01
\`\`\`

**输出解释**：此参数使切换后的会话与目标用户的登录会话相同，加载用户的环境变量和配置文件。

- \`-c\`：执行指定命令后退出。

\`\`\`bash
su -c "ls /root" dev01
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ su -c "ls /root" dev01
Password: 
ls: cannot open directory '/root': Permission denied
\`\`\`

输出解释：此命令切换到 \`dev01\` 用户并尝试列出 \`/root\` 目录的内容，但由于 \`dev01\` 用户没有权限，命令失败。

- \`-m\` 或 \`-p\`：保持当前环境不变。

\`\`\`bash
su -m dev01
\`\`\`

**输出解释**：如前所述，此参数保留当前用户的环境变量。

### 多架构和多版本下的表现

\`su\` 命令在不同架构下（如 \`aarch64\` 和 \`x86_64\`）的行为一致，不存在明显的差异，因此不需要特别关注架构之间的差异。

在某些历史版本中，\`su\` 命令的提示符或错误信息可能有所不同，但一般对功能影响不大。

### 安全性和弃用的命令

尽管 \`su\` 命令仍然广泛使用，但随着安全要求的提高，有时更推荐使用 \`sudo\` 来执行需要特权的操作。\`sudo\` 提供了更细粒度的权限控制，而不需要知道目标用户的密码。

### \`su\` 的安装

在大多数 Linux 发行版中，\`su\` 命令作为基础系统的一部分已默认安装。如果系统中没有 \`su\`，通常是因为该命令被限制使用，建议通过管理员进行安装或启用。

- 在 Ubuntu/Debian 系统中：

\`\`\`bash
sudo apt-get install login
\`\`\`

- 在 RHEL/CentOS 系统中：

\`\`\`bash
sudo yum install util-linux
\`\`\`

安装包名称：\`login\`（在 Ubuntu/Debian 中）或 \`util-linux\`（在 RHEL/CentOS 中）。

### 总结

\`su\` 命令是 Linux 系统中用于切换用户的常用命令，理解其用法和参数对于系统管理非常重要。通过掌握 \`su\` 命令，用户可以在不同用户身份之间灵活切换，从而更有效地管理系统资源和执行权限操作。

        `,
    };
  },
});
</script>
<style scoped></style>
