<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## rmdir 命令详解

\`rmdir\` 命令用于删除空目录。如果目录不为空，命令会报错。它是用于文件系统管理的基本工具之一。以下是对 \`rmdir\` 命令的详细介绍。

### 语法

\`\`\`bash
rmdir [选项] 目录...
\`\`\`

- \`目录\`：要删除的空目录的路径。

### 重要选项

- \`-p\`：递归删除目录及其父目录，前提是所有目录都是空的。
- \`--ignore-fail-on-non-empty\`：忽略非空目录的删除请求，不报告错误。

### 使用示例

#### 示例 1：删除单个空目录

\`\`\`bash
mkdir empty_dir
rmdir empty_dir
\`\`\`

**输出：**

\`\`\`plaintext
【无输出，命令已成功执行】
\`\`\`

**解释：**
- 使用 \`mkdir\` 创建一个名为 \`empty_dir\` 的空目录，然后使用 \`rmdir\` 删除它。

#### 示例 2：使用 \`-p\` 选项递归删除空目录

\`\`\`bash
mkdir -p parent/child
rmdir -p parent/child
\`\`\`

**输出：**

\`\`\`plaintext
【无输出，命令已成功执行】
\`\`\`

**解释：**
- 使用 \`mkdir -p\` 创建一个包含子目录的目录结构，然后使用 \`rmdir -p\` 删除空目录及其父目录。

#### 示例 3：忽略非空目录

\`\`\`bash
mkdir non_empty_dir
touch non_empty_dir/file
rmdir --ignore-fail-on-non-empty non_empty_dir
\`\`\`

**输出：**

\`\`\`plaintext
rmdir: failed to remove 'non_empty_dir': Directory not empty
\`\`\`

**解释：**
- 使用 \`touch\` 在 \`non_empty_dir\` 目录下创建一个文件，然后尝试删除该非空目录。使用 \`--ignore-fail-on-non-empty\` 选项可以忽略错误消息。

### 注意事项

- \`rmdir\` 只删除空目录。如果目录中包含文件或子目录，命令将失败。
- 对于删除非空目录，可以使用 \`rm -r\` 命令。

### 相关命令

- \`rm -r\`：用于递归删除目录及其内容。
- \`find\`：用于查找文件和目录，配合 \`-empty\` 可以查找空目录。

### 版本差异

\`rmdir\` 是 GNU coreutils 的一部分，不同版本的 \`rmdir\` 在功能上几乎没有差异。主要的变化通常涉及新功能的增加或细节的改进。

### 安全性和替代命令

\`rmdir\` 命令本身没有安全问题，但错误使用可能导致数据丢失。对于需要删除非空目录的情况，推荐使用 \`rm -r\` 命令。

- **替代命令**：\`rm -r\` 用于递归删除目录及其内容。

### 安装和配置

\`rmdir\` 命令随 GNU coreutils 提供，无需单独安装。

- **Ubuntu**：\`rmdir\` 命令随 \`coreutils\` 包安装。
- **RHEL**：同样，\`rmdir\` 命令随 \`coreutils\` 包安装。

### 权限要求

- 删除目录通常需要对目录有写权限。如果目录的所有者与当前用户不同，可能需要 \`root\` 权限来删除目录。

\`\`\`bash
sudo rmdir 目录
\`\`\`

**示例输出：**

\`\`\`plaintext
【无输出，命令已成功执行】
\`\`\`

        `,
    };
  },
});
</script>
<style scoped></style>
