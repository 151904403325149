<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## kill 命令详解

\`kill\` 命令用于向进程发送信号，通常用于终止或控制进程。\`kill\` 可以通过进程ID（PID）来指定目标进程，也可以通过信号名称或编号指定发送的信号类型。在Linux系统中，\`kill\` 既可以作为Bash内置命令，也可以作为外部命令使用，二者功能相似，但内置命令优先级更高。

### 1. 基本用法

\`\`\`bash
kill [选项] <信号或信号编号> <PID>
\`\`\`

其中：
- \`<信号或信号编号>\` 可以是信号的名称（如 \`SIGTERM\`）或编号（如 \`15\`）。
- \`<PID>\` 是进程ID，可以使用 \`ps\`, \`top\` 等命令查找。

### 2. 常用信号及其编号

- \`SIGTERM\` (15)：请求进程正常终止。默认信号。
- \`SIGKILL\` (9)：强制终止进程，不能被捕获或忽略。
- \`SIGHUP\` (1)：挂起信号，常用于重新加载进程配置。
- \`SIGINT\` (2)：中断信号，通常由 \`Ctrl+C\` 触发。

### 3. 常用选项

- \`-l\`：列出所有信号。
- \`-s\`：指定发送的信号类型。
- \`-L\`：列出信号及其编号。

### 4. 示例及解释

#### 4.1 终止一个进程

\`\`\`bash
$ ps -ef | grep myapp
sre      12345  1  0 10:00 ?    00:00:01 /usr/bin/myapp
$ kill 12345
\`\`\`

**输出解释**：
- \`ps -ef | grep myapp\` 查找正在运行的 \`myapp\` 进程，得到进程ID \`12345\`。
- \`kill 12345\` 发送默认的 \`SIGTERM\` 信号（15号信号）给该进程，尝试正常终止。

#### 4.2 强制终止进程

\`\`\`bash
$ kill -9 12345
\`\`\`

**输出解释**：
- \`-9\` 表示发送 \`SIGKILL\` 信号（9号信号），强制终止进程 \`12345\`。该信号不能被捕获或忽略。

#### 4.3 列出所有信号

\`\`\`bash
$ kill -l
\`\`\`

**输出解释**：
- 列出所有可用的信号及其编号：

\`\`\`bash
1) SIGHUP   2) SIGINT   3) SIGQUIT   4) SIGILL   ...
\`\`\`

#### 4.4 通过信号名称发送信号

\`\`\`bash
$ kill -s SIGTERM 12345
\`\`\`

**输出解释**：
- \`-s\` 指定发送 \`SIGTERM\` 信号，而不是使用信号编号。

### 5. Bash内置命令 vs 外部命令

\`kill\` 既是Bash内置命令，又可以作为外部命令。内置命令在性能上更快，因为不需要启动新的进程。可以通过以下命令确认 \`kill\` 是内置命令：

\`\`\`bash
$ type kill
kill is a shell builtin
\`\`\`

如果需要使用外部命令，可以使用其完整路径：

\`\`\`bash
$ /bin/kill -9 12345
\`\`\`

### 6. 输出结果解读

#### 6.1 成功执行

\`\`\`bash
$ kill 12345
【无输出，命令已成功执行】
\`\`\`

#### 6.2 进程不存在

\`\`\`bash
$ kill 99999
bash: kill: (99999) - No such process
\`\`\`

**输出解释**：
- \`99999\` 号进程不存在，系统返回错误提示。

### 7. 多架构输出一致性

在 x86_64 和 aarch64 架构下，\`kill\` 命令的行为和输出基本一致，因此没有差异性需要说明。

### 8. 版本差异

\`kill\` 命令的不同版本在信号的处理上基本一致，因此不涉及明显的输出差异。

### 9. 安全性与替代

\`kill\` 命令始终有效，未被淘汰或替代。不过，在某些应用场景下，使用 \`pkill\` 或 \`killall\` 可能更为方便，这些命令能够通过进程名直接终止进程。

### 10. 安装信息

\`kill\` 通常是Bash的一部分，因此不需要单独安装。

- **Ubuntu**：随 \`bash\` 安装。
- **RHEL**：随 \`bash\` 安装。

### 11. 用到 root 权限的场景

某些系统进程需要 \`root\` 权限才能被终止。可以通过 \`sudo\` 来执行：

\`\`\`bash
$ sudo kill -9 1
\`\`\`

此命令会尝试终止 \`init\` 进程，可能导致系统崩溃，因此需要谨慎使用。

### 12. 总结

\`kill\` 命令是Linux系统中管理进程的关键工具，通过向进程发送不同的信号，用户可以控制进程的行为。掌握 \`kill\` 的不同信号和选项，对于系统管理员来说至关重要。

        `,
    };
  },
});
</script>
<style scoped></style>
