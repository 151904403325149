<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lastb 命令详解

\`lastb\` 命令用于显示系统的失败登录记录。这些记录存储在 \`/var/log/btmp\` 文件中。与 \`last\` 命令显示成功的登录记录不同，\`lastb\` 主要用于帮助系统管理员检查系统的安全性，通过分析登录失败的尝试来识别潜在的攻击或异常活动。

### 基本用法

\`lastb\` 命令的基本语法如下：

\`\`\`bash
lastb [options]
\`\`\`

#### 示例

\`\`\`bash
lastb
\`\`\`

输出结果：

\`\`\`plaintext
sre       tty1         192.168.1.10    Sat Sep  7 14:01   **Failed login**
sre       tty1         192.168.1.10    Sat Sep  7 13:47   **Failed login**
\`\`\`

在这个示例中，\`lastb\` 命令显示了用户 \`sre\` 的登录失败记录，包括终端、IP 地址、失败的时间等信息。

### 重要参数和功能

- **-a**: 显示登录失败时的主机名。

  示例：

  \`\`\`bash
  lastb -a
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       tty1         192.168.1.10    azlinux-prod-cn Sat Sep  7 14:01   **Failed login**
  \`\`\`

  该选项附加了主机名信息，帮助识别登录失败的来源。

- **-n NUM**: 显示最近的 NUM 条记录。

  示例：

  \`\`\`bash
  lastb -n 5
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       tty1         192.168.1.10    Sat Sep  7 14:01   **Failed login**
  sre       tty1         192.168.1.10    Sat Sep  7 13:47   **Failed login**
  \`\`\`

  该选项限制了显示的记录数为最近的 5 条。

- **-f FILE**: 指定要查看的日志文件（默认为 \`/var/log/btmp\`）。

  示例：

  \`\`\`bash
  lastb -f /var/log/btmp.1
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       tty1         192.168.1.10    Fri Sep  6 14:01   **Failed login**
  \`\`\`

  允许从指定的日志文件中查看记录。

### 特殊用法示例

- **查看特定用户的失败登录记录**：

  \`\`\`bash
  lastb sre
  \`\`\`

  输出结果：

  \`\`\`plaintext
  sre       tty1         192.168.1.10    Sat Sep  7 14:01   **Failed login**
  sre       tty1         192.168.1.10    Sat Sep  7 13:47   **Failed login**
  \`\`\`

  显示用户 \`sre\` 的所有失败登录记录。

### 不同架构下的输出

\`lastb\` 命令在 \`x86_64\` 和 \`aarch64\` 架构下的行为和输出基本相同。以下是一个典型的输出示例：

#### aarch64架构下

\`\`\`bash
lastb
\`\`\`

输出结果：

\`\`\`plaintext
sre       tty1         192.168.1.10    Sat Sep  7 14:01   **Failed login**
sre       tty1         192.168.1.10    Sat Sep  7 13:47   **Failed login**
\`\`\`

与 \`x86_64\` 架构相同，\`lastb\` 命令在 \`aarch64\` 架构下也正常显示失败的登录记录。

### 不同版本的差异

\`lastb\` 命令在不同版本的 \`util-linux\` 包中可能有一些细微的差异，但基本功能和输出格式在现代 Linux 系统中是一致的。旧版本可能不支持某些选项，但核心功能仍然保留。

### 安装信息

- **Ubuntu/Debian**: \`lastb\` 命令是 \`util-linux\` 包的一部分，通常系统默认安装。

  安装命令：

  \`\`\`bash
  sudo apt-get install util-linux
  \`\`\`

- **RHEL/CentOS**: 同样，\`lastb\` 命令是 \`util-linux\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo yum install util-linux
  \`\`\`

### 使用root权限

\`lastb\` 命令通常不需要 root 权限即可查看失败登录记录。只有在查看某些受保护的日志文件时（如 \`/var/log/btmp\`），可能需要适当的权限。

### 总结

\`lastb\` 命令是一个有用的工具，用于查看系统的失败登录记录，帮助管理员检测和防范潜在的安全威胁。通过不同的选项，用户可以灵活地获取所需的信息，进行系统安全审计和监控。
        `,
    };
  },
});
</script>
<style scoped></style>
