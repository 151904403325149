<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="监控脚本有明显的bug, 无法正确监控进程数量。紧急告警的报错是：检测到apiserver进程数量为2，非正常值1."
      source="由外包员工【wb6009299】汇报，该bug已验证确认，混合环境下会出现问题！已奖励1包零食"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdGrep",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10070",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
\`pgrep -x\` 是用来精确匹配进程名的一个常用方式，\`-x\` 选项确保进程名必须完全匹配。这意味着它只会找到与指定名称完全相同的进程，而不会匹配部分名称。

除了 \`pgrep -x\`，还有其他几种方法可以匹配进程名：

### 1. **pgrep 其他选项**

- **不使用 \`-x\`**：直接使用 \`pgrep <PROCESS_NAME>\` 可以部分匹配进程名。例如，\`pgrep apache\` 会匹配任何包含 "apache" 的进程名，如 \`apache2\`、\`apachectl\` 等。
  
### 2. **grep -w 和 ps**

你也可以通过 \`ps\` 和 \`grep -w\` 组合来查找进程。\`grep -w\` 匹配整个单词(kube-apiserver不是一个完整的单词，而kube_apiserver则是一个完整的单词，注意下划线\`_\`和减号\`-\`的区别），它适用于确保匹配到完整的进程名，而不是部分名称。这里是一个常见的方式：

\`\`\`bash
ps -ef | grep -w "apiserver" | grep -v grep
\`\`\`

### 例子

假设你想查找名为 \`system-care\` 的进程，你可以使用以下几种方式：

- **pgrep -x**（精确匹配进程名）：
  \`\`\`bash
  pgrep -x system-care
  \`\`\`

- **pgrep**（部分匹配）：
  \`\`\`bash
  pgrep system
  \`\`\`

- **pgrep -x**：简单、精确匹配进程名，不需要过滤 \`grep\` 本身的进程。
- **grep -w + ps**：提供更详细的进程信息，但需要排除 \`grep\` 自身匹配的进程，稍微复杂，-w选项也有要注意的地方，下划线\`_\`和减号\`-\`, \`kube-apiserver\` VS \`kube_apiserver\`

您可以动手测试以下的输出,
\`\`\`bash
echo kube_apiserver | grep -w apiserver
echo kube-apiserver | grep -w apiserver
\`\`\`

总结：\`pgrep -x\` 是推荐的方式，因为它简洁并且可以直接返回匹配的进程 ID；而 \`grep -w\` 也可行，但一般用于需要 \`ps\` 提供更多信息的情况下。

  `,
      markdownContentExpand: `
\`grep -c\` 参数用于统计匹配到的行数。它不会输出匹配的具体内容，而是直接返回匹配到的行的数量。\`grep -c\` 常用于快速判断某个模式在文件或输出中出现的次数。

### 语法
\`\`\`bash
grep -c <pattern> <file>
\`\`\`

- \`<pattern>\`：你要搜索的正则表达式或字符串。
- \`<file>\`：你要在其中搜索的文件。

### 示例

1. **统计匹配行数**：
   假设有一个文件 \`file.txt\`，其内容如下：
   \`\`\`txt
   apple
   banana
   apple pie
   orange
   \`\`\`

   如果你想统计文件中包含 "apple" 的行数，可以使用：
   \`\`\`bash
   grep -c "apple" file.txt
   \`\`\`

   输出结果：
   \`\`\`txt
   2
   \`\`\`

   解释：\`grep\` 找到了两行包含 "apple"。

2. **配合其他命令使用**：
   你也可以将 \`grep -c\` 与其他命令结合使用，例如统计某个命令输出中符合条件的行数：
   \`\`\`bash
   ps aux | grep -c "apache"
   \`\`\`

   这会统计当前系统中包含 "apache" 进程的行数。

### 总结
- \`grep -c\` 只输出匹配行的数量，不会显示具体的匹配内容。
- 它非常适合用于快速统计某个模式在文件或输出中出现的次数。

\`grep --color\` 选项用于在 \`grep\` 命令的输出中高亮显示匹配的部分，使得搜索结果更容易辨认。匹配到的模式或字符串会被用不同颜色显示，通常是红色或绿色，具体取决于终端的配色设置。

### 用法

\`\`\`bash
grep --color <pattern> <file>
\`\`\`

或者
\`\`\`bash
echo "This is a test" | grep --color "test"
\`\`\`

在以上例子中，匹配到的 \`test\` 字符串会被高亮显示。

### 参数细节
- \`--color=auto\`：只在终端中启用颜色高亮。如果输出被重定向到文件或其他命令（例如通过管道传递），则不会显示颜色。这是默认设置。
- \`--color=always\`：无论输出是直接显示在终端还是通过管道传递，都始终使用颜色高亮。
- \`--color=never\`：禁用颜色显示。

### 示例

1. **在文件中搜索并高亮匹配项**：
   \`\`\`bash
   grep --color "apple" file.txt
   \`\`\`

   输出：
   \`\`\`bash
   apple pie
   \`\`\`

   其中 \`apple\` 会被高亮显示。

2. **递归搜索并高亮显示**：
   \`\`\`bash
   grep --color -r "function" /path/to/directory
   \`\`\`

   这会递归搜索文件夹中的所有文件，匹配的 \`function\` 部分会高亮。

### 典型用途
- **调试和排查问题**：高亮显示可以更清楚地找到日志文件、代码或配置文件中匹配的关键字。
- **过滤输出**：结合 \`grep --color\` 使用可以快速筛选并识别出需要的内容，尤其是当文件或命令输出量很大时。

  `,
      markdownInterview: ` 
### 选择题

1. **选择题 1**：使用 \`pgrep\` 命令查找名为 \`apache2\` 的进程，哪个命令可以确保只匹配进程名完全为 \`apache2\` 的进程？
   - A) \`pgrep apache2\`
   - B) \`pgrep -f apache2\`
   - C) \`pgrep -x apache2\`
   - D) \`pgrep -a apache2\`

2. **选择题 2**：以下哪个选项可以让 \`grep\` 搜索多个文件，并显示匹配模式的文件名？
   - A) \`grep -l\`
   - B) \`grep -i\`
   - C) \`grep -r\`
   - D) \`grep -v\`

3. **选择题 3**：使用 \`pgrep -f\` 可以查找包含指定字符的完整命令行的进程，下列哪个命令会查找包含 \`/usr/bin/python3\` 的进程？
   - A) \`pgrep python3\`
   - B) \`pgrep -f python3\`
   - C) \`pgrep -x python3\`
   - D) \`pgrep -f /usr/bin/python3\`

4. **选择题 4**：\`grep -w\` 命令的作用是什么？
   - A) 忽略大小写
   - B) 匹配整行
   - C) 匹配整个单词
   - D) 反向匹配

5. **选择题 5**：如果想要统计某个文件中匹配的行数，应该使用以下哪个 \`grep\` 参数？
   - A) \`grep -n\`
   - B) \`grep -c\`
   - C) \`grep -v\`
   - D) \`grep -l\`

### 问答题

1. **问答题 1**：\`pgrep\` 命令的用途是什么？与 \`ps\` 命令结合使用有何优势？

2. **问答题 2**：解释 \`grep\` 中 \`-r\` 和 \`-v\` 的作用，分别适用于什么场景？

3. **问答题 3**：如何使用 \`grep\` 在一个文件中搜索多个关键词，并且高亮显示匹配内容？

4. **问答题 4**：\`pgrep\` 和 \`grep\` 命令在进程管理中各自的应用场景是什么？

---

### 答案

#### 选择题答案
1. C) \`pgrep -x apache2\`
2. A) \`grep -l\`
3. D) \`pgrep -f /usr/bin/python3\`
4. C) 匹配整个单词
5. B) \`grep -c\`

#### 问答题答案
1. **\`pgrep\` 的用途及与 \`ps\` 的结合**：
   - \`pgrep\` 用于根据进程名或模式查找进程的 PID。与 \`ps\` 命令结合，\`pgrep\` 更加简洁，因为它直接输出匹配的进程 ID，而 \`ps\` 通常会输出一整行信息。\`pgrep\` 可以与 \`kill\` 等命令结合，用于快速查找并控制进程。

2. **\`grep -r\` 和 \`grep -v\` 的作用**：
   - \`-r\`：递归地搜索文件和目录，用于在多个文件中搜索模式。
   - \`-v\`：反向匹配，显示不包含指定模式的行。适合过滤掉不需要的内容。

3. **在文件中搜索多个关键词并高亮显示**：
   - 可以使用 \`grep\` 结合 \`--color\` 参数高亮显示匹配项，并通过管道搜索多个关键词：
     \`\`\`bash
     grep --color -E 'keyword1|keyword2' file.txt
     \`\`\`

4. **\`pgrep\` 和 \`grep\` 的应用场景**：
   - \`pgrep\`：用于查找正在运行的进程的 PID，适合快速定位和管理特定进程。
   - \`grep\`：可以搜索文件内容或命令输出的特定模式。对于进程管理时，可以与 \`ps\` 命令结合，过滤特定进程信息。

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
