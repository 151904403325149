<template>
  <div class="markdown-body2" v-html="renderedMarkdown" />
</template>

<script>
import MarkdownIt from "markdown-it";
import "github-markdown-css/github-markdown-light.css";
import "../../assets/styles/sre-read.css";

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      md: new MarkdownIt(), // 创建 MarkdownIt 实例
    };
  },
  computed: {
    renderedMarkdown() {
      // 这里可以进行安全处理，如果需要的话
      return this.md.render(this.content);
    },
  },
};
</script>

<style scoped>
.markdown-body2 {
  text-align: left;
}
</style>
