<template>
  <div>
    <GameCard1
      title="镜像导出为Tar包"
      actions="Docker save 将镜像导出为一个 .tar 文件"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
### 1. 什么是 \`docker save\`？

\`docker save\` 是 Docker 中用于将**镜像**导出为一个 \`.tar\` 文件的命令。它能够将指定镜像的所有层（包括其元数据和历史记录）打包并保存为一个单独的文件，便于传输、备份或共享。通过 \`docker save\`，你可以将本地的镜像保存在文件系统中，然后在其他环境中使用 \`docker load\` 恢复该镜像。

### 2. \`docker save\` 的语法

\`\`\`bash
docker save [OPTIONS] IMAGE [IMAGE...]
\`\`\`

- **IMAGE**: 指定需要保存的镜像，可以是一个或多个。
- **OPTIONS**: 可选参数，主要用于指定输出路径等。

### 3. 常见选项

- \`-o\` 或 \`--output\`：指定保存镜像的输出路径。如果没有指定输出路径，则会将镜像导出到标准输出。
  
  \`\`\`bash
  docker save -o <path> <image_name>
  \`\`\`

### 4. Docker Save 的使用案例

#### 案例 1：导出一个镜像

假设你有一个本地的镜像 \`myapp:latest\`，你想将它保存为一个 \`.tar\` 文件，以便在其他主机上使用。可以使用如下命令：

\`\`\`bash
docker save -o myapp.tar myapp:latest
\`\`\`

这个命令将 \`myapp:latest\` 镜像及其所有的镜像层打包成 \`myapp.tar\` 文件。你可以将这个文件拷贝到其他 Docker 主机上，通过 \`docker load\` 恢复镜像。

#### 案例 2：导出多个镜像

你也可以一次性保存多个镜像，\`docker save\` 支持同时保存多个镜像到同一个 \`.tar\` 文件中。例如：

\`\`\`bash
docker save -o myimages.tar myapp:latest otherapp:latest
\`\`\`

这个命令会将 \`myapp:latest\` 和 \`otherapp:latest\` 两个镜像打包到 \`myimages.tar\` 中。

#### 案例 3：将镜像通过网络传输

你可以结合 \`docker save\` 和管道，将镜像直接通过网络传输，而无需先保存为文件。比如使用 SSH：

\`\`\`bash
docker save myapp:latest | ssh user@remotehost "docker load"
\`\`\`

这个命令通过 SSH 将本地的 \`myapp:latest\` 镜像直接传输到远程主机并使用 \`docker load\` 进行加载。

### 5. \`docker save\` 背后发生了什么？

在执行 \`docker save\` 时，Docker 会进行以下几个步骤：

#### 1. **镜像层的收集**：
镜像是由多个只读层（Layer）构成的，每一层都记录了不同的文件系统状态。在运行 \`docker save\` 时，Docker 会收集指定镜像的所有层，包括其依赖的父镜像层。这些层是增量构建的，即每个镜像层只包含对下层文件系统的增量更改。

#### 2. **打包层文件**：
所有收集的镜像层会被打包到一个 tar 文件中。镜像的各个层本质上是镜像文件系统的一部分，在打包时，Docker 会按照层级顺序，将各层的差异打包。每个镜像层都是不可变的，因此可以通过打包这些层来完整恢复镜像。

#### 3. **包含元数据**：
除了打包文件系统层，\`docker save\` 还会将与镜像相关的元数据（例如镜像名称、标签、历史操作记录等）保存到 tar 文件中。这些元数据帮助 Docker 在恢复镜像时识别镜像的结构、依赖关系和操作历史。

#### 4. **输出 tar 文件**：
最终，所有的层和元数据被打包到一起，并输出为一个 \`.tar\` 文件，用户可以保存、传输或备份这个文件。

#### **镜像层如何工作？**
Docker 的镜像层通过一种称为**写时复制（Copy-on-Write, COW）**的机制工作。每个镜像层是只读的，当你创建一个新的容器时，Docker 会在镜像的顶部添加一个可写层。这种层叠文件系统使得 Docker 能够快速启动容器，同时共享基础镜像层的资源。

当使用 \`docker save\` 时，Docker 会将这些层按照依赖关系逐层保存到 \`.tar\` 文件中，从最底层的基础镜像到最终的应用镜像层。

### 6. \`docker save\` 的使用场景

#### 1. **跨主机传输镜像**
当你需要将本地的镜像在没有联网的情况下传输到其他主机上时，\`docker save\` 非常有用。你可以将镜像打包成 \`.tar\` 文件，使用 USB 或者其他文件传输方式将镜像文件拷贝到其他主机上，然后使用 \`docker load\` 恢复镜像。

#### 2. **镜像备份**
在做系统升级或备份策略时，可以使用 \`docker save\` 命令将当前所有重要镜像保存为文件，以防镜像仓库出现问题或网络中断。

#### 3. **离线部署**
在某些没有互联网访问的离线环境下，你可以使用 \`docker save\` 来提供离线部署镜像。开发者在有网络环境的地方构建和保存镜像，传输到离线环境中，然后再使用 \`docker load\` 进行部署。

### 7. 总结

\`docker save\` 是 Docker 提供的一种将镜像及其历史信息、层、元数据打包成 tar 文件的方法，非常适合跨主机传输、备份镜像和离线部署。通过理解 \`docker save\` 的背后机制，用户可以更好地管理 Docker 镜像和进行镜像备份与恢复。它的使用场景灵活多样，是 Docker 管理中的重要工具之一。
        
        `,
      interviewContent: `
#### 选择题：
1. **关于 \`docker save\` 命令，下列描述正确的是哪一个？**  
   A. \`docker save\` 只能保存容器的文件系统  
   B. \`docker save\` 会保存镜像的所有层、历史和元数据  
   C. \`docker save\` 是导出容器的当前状态的命令  
   D. \`docker save\` 仅能保存单个镜像，不能同时保存多个镜像  

2. **\`docker save\` 与 \`docker export\` 的主要区别是什么？**  
   A. \`docker save\` 用于保存镜像，而 \`docker export\` 用于保存容器的文件系统  
   B. \`docker save\` 用于保存容器的状态，而 \`docker export\` 保存镜像  
   C. \`docker save\` 可以导出容器的所有日志，而 \`docker export\` 不能  
   D. \`docker export\` 会包含镜像的历史记录和元数据，而 \`docker save\` 不会  

3. **使用 \`docker save\` 导出的文件格式是什么？**  
   A. \`.zip\`  
   B. \`.tar\`  
   C. \`.gz\`  
   D. \`.json\`

#### 问答题：

1. **简述 \`docker save\` 的功能及其使用场景？**

2. **\`docker save\` 命令背后具体做了哪些操作？解释它是如何将镜像保存为文件的。**

3. **在什么情况下你会选择使用 \`docker save\` 而不是 \`docker export\`？请给出一个示例。**

4. **描述使用 \`docker save\` 和 \`docker load\` 的完整流程，如何在两台主机之间传输 Docker 镜像。**

---

### 答案

#### 选择题：
1. **B**. \`docker save\` 会保存镜像的所有层、历史和元数据。
2. **A**. \`docker save\` 用于保存镜像，而 \`docker export\` 用于保存容器的文件系统。
3. **B**. \`docker save\` 导出的文件格式是 \`.tar\`。

#### 问答题：

1. **简述 \`docker save\` 的功能及其使用场景？**  
   \`docker save\` 是用于将镜像及其所有层、历史记录、元数据打包为 \`.tar\` 文件的命令。它的主要用途包括镜像的备份、跨主机传输以及在无网络环境下进行离线部署。\`docker save\` 可以打包一个或多个镜像，并通过 \`docker load\` 恢复这些镜像到其他主机上。

2. **\`docker save\` 命令背后具体做了哪些操作？解释它是如何将镜像保存为文件的。**  
   当执行 \`docker save\` 时，Docker 首先收集指定镜像的所有层及其依赖的父层。然后，它会将这些镜像层及相关的元数据（如标签、历史操作记录等）打包成一个 \`.tar\` 文件。镜像层是增量的文件系统变更，每层是只读的，保存时按顺序打包，确保恢复时能够按照层次还原。

3. **在什么情况下你会选择使用 \`docker save\` 而不是 \`docker export\`？请给出一个示例。**  
   如果你需要保留镜像的所有历史记录、层信息和元数据，并希望在不同的 Docker 主机上完整恢复这个镜像，应该选择 \`docker save\`。例如，当你需要将一个构建好的应用镜像传输到另一台 Docker 主机时，\`docker save\` 能确保所有的历史记录和层数据在目标主机上得到完整恢复。

4. **描述使用 \`docker save\` 和 \`docker load\` 的完整流程，如何在两台主机之间传输 Docker 镜像。**  
   假设你有一个 \`myapp:latest\` 镜像，想在两台主机之间传输。  
   - 在主机 A 上使用以下命令保存镜像：
     \`\`\`bash
     docker save -o myapp.tar myapp:latest
     \`\`\`
   - 将 \`myapp.tar\` 文件传输到主机 B（可以使用 \`scp\`、\`rsync\` 或 USB 等方式传输文件）。
   - 在主机 B 上使用以下命令加载镜像：
     \`\`\`bash
     docker load -i myapp.tar
     \`\`\`
   - 加载完成后，你可以在主机 B 上使用 \`docker images\` 查看该镜像。
      
      `,
    };
  },
});
</script>
<style scoped></style>
