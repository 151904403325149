<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## hostname 命令详解

\`hostname\` 命令用于显示或设置系统的主机名。主机名是网络上识别计算机的名称。这个命令在大多数Linux和Unix系统上都可用。它有多种用法，可以用来查看当前的主机名，设置新的主机名，或者查看与主机名相关的信息。

### 常用选项

1. **无选项**：
   - **用法**: \`hostname\`
   - **描述**: 显示当前的主机名。
   - **示例**:
     \`\`\`bash
     $ hostname
     azlinux-prod-cn
     \`\`\`

2. **-s 或 --short**：
   - **用法**: \`hostname -s\`
   - **描述**: 显示短主机名，即主机名的第一个部分。
   - **示例**:
     \`\`\`bash
     $ hostname -s
     azlinux
     \`\`\`

3. **-f 或 --fqdn**：
   - **用法**: \`hostname -f\`
   - **描述**: 显示完全合格的域名（FQDN），即包括主机名和域名。
   - **示例**:
     \`\`\`bash
     $ hostname -f
     azlinux-prod-cn.example.com
     \`\`\`

4. **-i 或 --ip-address**：
   - **用法**: \`hostname -i\`
   - **描述**: 显示主机的IP地址。
   - **示例**:
     \`\`\`bash
     $ hostname -i
     192.168.1.10
     \`\`\`

5. **-d 或 --domain**：
   - **用法**: \`hostname -d\`
   - **描述**: 显示主机名的域部分。
   - **示例**:
     \`\`\`bash
     $ hostname -d
     example.com
     \`\`\`

6. **-b 或 --broadcast**：
   - **用法**: \`hostname -b\`
   - **描述**: 显示主机的广播地址（如果有）。
   - **示例**:
     \`\`\`bash
     $ hostname -b
     192.168.1.255
     \`\`\`

7. **--help**：
   - **用法**: \`hostname --help\`
   - **描述**: 显示帮助信息。
   - **示例**:
     \`\`\`bash
     $ hostname --help
     Usage: hostname [OPTION]...
     Display or set the system's hostname.
     -s, --short     show short hostname
     -f, --fqdn      show fully qualified domain name
     -i, --ip-address show all network addresses
     -d, --domain    show domain part of hostname
     -b, --broadcast show broadcast address
     --help     display this help message and exit
     \`\`\`

### 示例用法

1. **显示当前主机名**：
   \`\`\`bash
   $ hostname
   azlinux-prod-cn
   \`\`\`

2. **设置新的主机名**：
   \`\`\`bash
   $ sudo hostname newhostname
   \`\`\`
   这将把主机名更改为 \`newhostname\`，但更改可能不会持久化到系统重启后。

3. **设置主机名并使更改持久化**：
   - 对于Ubuntu系统:
     \`\`\`bash
     $ sudo hostnamectl set-hostname newhostname
     \`\`\`
   - 对于RHEL系统:
     \`\`\`bash
     $ sudo hostnamectl set-hostname newhostname
     \`\`\`

### 注意事项

- \`hostname\` 命令不要求root权限来查看当前主机名，但设置新的主机名需要root权限。
- 更改主机名可能需要重新启动某些服务或计算机才能生效。

### 版本差异

\`hostname\` 命令的功能在不同版本的Linux发行版中基本一致，但具体选项和行为可能略有不同。通常，最新版本提供了更多的选项和改进的功能。

### 安装

\`hostname\` 命令通常是操作系统默认安装的一部分，不需要单独安装。在Ubuntu和RHEL系统上，它是 \`inetutils\` 包的一部分。

- **Ubuntu**: 已预安装
- **RHEL**: 已预安装

### 参考资料

- [man hostname](https://man7.org/linux/man-pages/man1/hostname.1.html)
- [Linux hostnamectl](https://www.freedesktop.org/wiki/Software/systemd/hostname/)

        `,
    };
  },
});
</script>
<style scoped></style>
