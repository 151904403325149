<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## sort 命令详解

\`sort\` 命令是 Linux 和 Unix 系统中用于对文本文件中的数据进行排序的常用工具。它可以根据指定的规则对文件的行进行排序，支持多种排序方式，例如按数字、按字母、忽略大小写、反向排序等。本文将详细介绍 \`sort\` 命令的使用方法，重点介绍重要的参数，并通过示例展示 \`sort\` 命令的实际输出。

### 基本语法

\`\`\`bash
sort [选项] [文件...]
\`\`\`

其中，\`[选项]\` 是可选的参数，用于控制排序的方式，而 \`[文件]\` 是要排序的文件。如果没有指定文件，\`sort\` 会从标准输入读取数据。

### 常见选项

以下是 \`sort\` 命令中一些常用的选项：

- \`-n\`：按数字排序。
- \`-r\`：反向排序。
- \`-k\`：指定排序的字段。
- \`-t\`：指定分隔符。
- \`-u\`：去除重复行。
- \`-f\`：忽略大小写进行排序。
- \`-o\`：将排序结果输出到指定文件。

### 示例

假设有一个名为 \`users.txt\` 的文件，内容如下：

\`\`\`bash
dev01 1002
app01 1003
mon_user 1004
sre 1005
\`\`\`

#### 按默认排序

默认情况下，\`sort\` 会对文件的内容进行按字母排序。

\`\`\`bash
sort users.txt
\`\`\`

**输出：**

\`\`\`bash
app01 1003
dev01 1002
mon_user 1004
sre 1005
\`\`\`

在这个例子中，\`sort\` 按字母顺序对文件的行进行了排序。

#### 按数字排序

使用 \`-n\` 选项，可以按数字对第二列的数字进行排序。

\`\`\`bash
sort -n -k 2 users.txt
\`\`\`

**输出：**

\`\`\`bash
dev01 1002
app01 1003
mon_user 1004
sre 1005
\`\`\`

**解读**：根据数字列从小到大进行排序，用户 ID 的顺序没有变化，因为它们本来就是按顺序排列的。

#### 反向排序

如果希望将结果按反向顺序排列，可以使用 \`-r\` 选项。

\`\`\`bash
sort -r users.txt
\`\`\`

**输出：**

\`\`\`bash
sre 1005
mon_user 1004
dev01 1002
app01 1003
\`\`\`

**解读**：数据按字母顺序从 Z 到 A 排列。

#### 按字段排序

使用 \`-k\` 选项可以指定按特定字段进行排序。例如，如果我们希望根据用户 ID（第二列）排序，可以这样做：

\`\`\`bash
sort -k 2 users.txt
\`\`\`

**输出：**

\`\`\`bash
dev01 1002
app01 1003
mon_user 1004
sre 1005
\`\`\`

**解读**：此命令将根据第二列（用户 ID）进行排序，结果与按数字排序相同。

#### 使用自定义分隔符

默认情况下，\`sort\` 使用空格或制表符作为分隔符。我们可以使用 \`-t\` 选项指定其他分隔符。例如，如果文件中的字段由冒号分隔，可以使用以下命令：

\`\`\`bash
sort -t ':' -k 2 users_colon.txt
\`\`\`

假设 \`users_colon.txt\` 文件内容为：

\`\`\`bash
dev01:1002
app01:1003
mon_user:1004
sre:1005
\`\`\`

**输出：**

\`\`\`bash
dev01:1002
app01:1003
mon_user:1004
sre:1005
\`\`\`

**解读**：使用 \`-t ':'\` 指定冒号作为分隔符，然后根据第二列排序。

#### 去除重复行

如果文件中包含重复的行，可以使用 \`-u\` 选项来去除重复行。例如，文件 \`duplicates.txt\` 内容为：

\`\`\`bash
sre 1005
dev01 1002
app01 1003
dev01 1002
mon_user 1004
sre 1005
\`\`\`

执行以下命令：

\`\`\`bash
sort -u duplicates.txt
\`\`\`

**输出：**

\`\`\`bash
app01 1003
dev01 1002
mon_user 1004
sre 1005
\`\`\`

**解读**：去除了文件中重复的行，结果只保留了唯一的行。

#### 将输出保存到文件

使用 \`-o\` 选项可以将排序结果保存到文件中，而不是显示在屏幕上。例如，将 \`users.txt\` 排序后的结果保存到 \`sorted_users.txt\`：

\`\`\`bash
sort -o sorted_users.txt users.txt
\`\`\`

执行后可以使用 \`cat\` 查看输出文件内容：

\`\`\`bash
cat sorted_users.txt
\`\`\`

**输出：**

\`\`\`bash
app01 1003
dev01 1002
mon_user 1004
sre 1005
\`\`\`

**解读**：排序结果已成功保存到 \`sorted_users.txt\` 文件中。

### 版本差异

\`sort\` 命令的基本功能在不同版本的 Linux 和 Unix 系统中保持一致。然而，某些版本可能对选项支持有所不同，建议使用 \`man sort\` 或 \`sort --help\` 查看系统中的具体功能和选项支持情况。

### 安装方法

在大多数现代 Linux 发行版中，\`sort\` 命令是 GNU coreutils 包的一部分，通常已经预装。如果未安装，可以通过以下方式安装：

- **Ubuntu/Debian** 系统中，可以使用以下命令安装：

  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`

- **RHEL/CentOS** 系统中，可以使用以下命令安装：

  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 总结

\`sort\` 命令是 Linux 和 Unix 系统中非常强大的文本排序工具。它不仅支持按字母顺序排序，还可以根据数字、指定字段、反向排序等多种方式进行操作。通过合理使用 \`sort\` 的各种选项，用户可以对文本数据进行灵活的排序和过滤，满足不同场景下的需求。
        `,
    };
  },
});
</script>
<style scoped></style>
