<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## vi 命令详解

\`vi\` 是 Unix 和 Linux 操作系统中广泛使用的文本编辑器之一。作为一款经典的文本编辑工具，它几乎出现在所有类 Unix 系统中，常被视为标准的文件编辑器。虽然 \`vi\` 本身功能强大，但它的界面简洁，操作也相对复杂，需要用户掌握一些基本的命令模式和编辑模式。本篇将详细介绍 \`vi\` 命令的使用方法、常用选项、及一些高级操作技巧。

### 1. \`vi\` 命令概述
\`vi\` 命令可以用于创建、查看、编辑文本文件，它主要有三种模式：
- **命令模式**：默认模式，用于执行命令和导航。
- **插入模式**：用于输入文本内容。
- **底线命令模式**：用于执行一些保存、退出等命令。

#### 命令格式：
\`\`\`bash
vi [选项] 文件名
\`\`\`

#### 示例命令：
\`\`\`bash
vi /etc/hosts
\`\`\`
**输出：**
\`\`\`
"azlinux-prod-cn" 12L, 342C
\`\`\`
解读：\`vi\` 打开了 \`/etc/hosts\` 文件，文件包含 12 行和 342 个字符。

### 2. \`vi\` 基本操作
#### 2.1 启动和退出 \`vi\`
在 \`vi\` 中，文件默认以命令模式打开。可以通过以下命令进入和退出：

- **启动 \`vi\`**：
  \`\`\`bash
  vi 文件名
  \`\`\`
  **示例：**
  \`\`\`bash
  vi /home/sre/testfile.txt
  \`\`\`
  **输出：**
  \`\`\`
  "testfile.txt" [New File]
  \`\`\`
  解读：\`vi\` 创建了一个名为 \`testfile.txt\` 的新文件。

- **保存并退出 \`vi\`**：
  在命令模式下输入 \`:wq\` 保存并退出文件。
  \`\`\`bash
  :wq
  \`\`\`
  **输出：**
  \`\`\`
  "testfile.txt" 3L, 58C written
  \`\`\`
  解读：文件 \`testfile.txt\` 已保存，包含 3 行和 58 个字符。

- **强制退出 \`vi\` 而不保存**：
  在命令模式下输入 \`:q!\` 强制退出：
  \`\`\`bash
  :q!
  \`\`\`
  【无输出，命令已成功执行】

#### 2.2 切换到插入模式
按 \`i\` 进入插入模式，开始编辑文件。插入模式用于输入文本，当需要保存或退出时，按 \`Esc\` 键返回命令模式。

示例命令：
\`\`\`bash
i
\`\`\`
【无输出，命令已成功执行】

#### 2.3 保存文件
输入 \`:w\` 保存文件而不退出 \`vi\`。

示例命令：
\`\`\`bash
:w
\`\`\`
**输出：**
\`\`\`
"testfile.txt" 3L, 58C written
\`\`\`
解读：文件 \`testfile.txt\` 已保存。

### 3. 常见参数
#### 3.1 \`-R\` 只读模式
使用 \`-R\` 参数可以让 \`vi\` 以只读模式打开文件，防止用户在未授权情况下修改文件内容。

示例命令：
\`\`\`bash
vi -R /etc/passwd
\`\`\`
**输出：**
\`\`\`
"/etc/passwd" [readonly] 24L, 1210C
\`\`\`
解读：以只读模式打开了 \`/etc/passwd\` 文件，文件包含 24 行和 1210 个字符。

#### 3.2 \`+\` 选项
使用 \`+\` 选项可以在打开文件时直接跳转到文件的最后一行。

示例命令：
\`\`\`bash
vi + /var/log/syslog
\`\`\`
**输出：**
\`\`\`
"/var/log/syslog" 1345L, 52510C
\`\`\`
解读：文件打开时直接跳到了最后一行，方便用户查看最新的日志内容。

### 4. 安装方法
在多数 Linux 发行版中，\`vi\` 是系统自带的。如果系统中没有 \`vi\`，可以手动安装。

#### Ubuntu 系统：
\`\`\`bash
sudo apt install vim
\`\`\`
（\`vi\` 实际上通常与 \`vim\` 共享二进制文件）

#### RHEL/CentOS 系统：
\`\`\`bash
sudo yum install vim-enhanced
\`\`\`

### 5. \`vi\` 的版本差异
\`vi\` 的功能相对稳定，不同版本之间变化较少，但增强版的 \`vim\` 在功能上做了大幅改进，提供了更多的高级编辑功能。如果你想要更丰富的功能，可以考虑使用 \`vim\`。

\`\`\`bash
vi --version
\`\`\`
**输出：**
\`\`\`
VIM - Vi IMproved 8.2 (2019 Dec 12, compiled Mar 30 2022 12:00:00)
\`\`\`
解读：该系统中实际安装的是 \`vim\`，它是 \`vi\` 的增强版。

### 6. 使用 root 权限的 \`vi\`
有时候需要编辑系统文件，如 \`/etc/shadow\`，这些文件需要使用 root 权限才能修改。在这种情况下，可以使用 \`sudo\` 命令提升权限。

示例命令：
\`\`\`bash
sudo vi /etc/shadow
\`\`\`
**输出：**
\`\`\`
"/etc/shadow" 44L, 1787C
\`\`\`
解读：\`/etc/shadow\` 文件成功打开，该文件包含 44 行，1787 个字符。

### 7. 多用户操作中的 \`vi\`
在多用户系统中，管理员可能需要查看不同用户的文件。例如，编辑用户 \`dev01\` 或 \`app01\` 的文件时，可以使用 \`sudo\` 切换用户：

#### 示例：
\`\`\`bash
sudo -u dev01 vi /home/dev01/project.txt
\`\`\`
**输出：**
\`\`\`
"/home/dev01/project.txt" [readonly] 10L, 320C
\`\`\`
解读：以用户 \`dev01\` 的身份打开了其文件，默认以只读模式打开。

### 8. 替代命令
在少数场景下，如果 \`vi\` 不可用，可以考虑使用 \`nano\` 或 \`ed\` 编辑器来代替。

#### 示例：使用 \`nano\`
\`\`\`bash
nano /etc/hosts
\`\`\`
**输出：**
\`\`\`
GNU nano 5.4        File: /etc/hosts
\`\`\`
解读：\`nano\` 是一款简单易用的文本编辑器。

### 结论
\`vi\` 是一款经典且功能强大的文本编辑器，适用于各种文本文件的编辑工作。尽管操作较为复杂，但掌握基本命令后可以大大提升工作效率。如果需要更多功能，可以考虑使用增强版的 \`vim\`。
        `,
    };
  },
});
</script>
<style scoped></style>
