<template>
  <div class="regular-card">
    <div class="regular-logo">MEMBER</div>
    <div class="regular-content">
      <h1>{{ vipInfo.title }}</h1>
      <p>{{ vipInfo.name }}</p>
      <p>Member ID: {{ vipInfo.memberId }}</p>
      <p>Valid Thru: {{ vipInfo.validThru }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegularCard",
  props: {
    vipInfo: Object,
  },
};
</script>

<style scoped>
.regular-card {
  width: 300px;
  height: 189px;
  background: linear-gradient(135deg, #6a89cc, #4a69bd);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  color: #fff;
  font-family: "Arial", sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.regular-logo {
  text-align: left;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 3px;
}

.regular-content h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.regular-content p {
  font-size: 14px;
  margin: 3px 0;
}

.regular-content {
  text-align: right;
}

.regular-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
</style>
