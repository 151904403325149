<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## alias 命令详解

\`alias\`命令是Linux系统中用于为命令创建简短别名的工具，能够提高日常操作的效率。通过\`alias\`，我们可以将复杂或长的命令缩短为简洁易记的单词。本文将详细介绍\`alias\`命令的用法、常用参数，并通过实际例子演示其应用及输出结果。

### 基本用法

\`alias\`命令的基本语法为：

\`\`\`bash
alias alias_name='command'
\`\`\`

其中，\`alias_name\`是别名，而\`command\`是需要简化的命令。以下是创建一个常用别名的示例：

\`\`\`bash
alias ll='ls -al'
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 此命令创建了一个名为\`ll\`的别名，代表\`ls -al\`命令。执行后无输出，表示别名创建成功。

### 使用别名

创建别名后，可以直接使用别名执行命令。例如，使用我们刚刚创建的\`ll\`别名：

\`\`\`bash
ll
\`\`\`

**示例输出：**

\`\`\`bash
total 8
drwxr-xr-x  2 sre  sre-group  4096 Sep  6 12:00 .
drwxr-xr-x 18 sre  sre-group  4096 Sep  6 11:00 ..
-rw-r--r--  1 sre  sre-group     0 Sep  6 11:30 example.txt
\`\`\`

**解读：** \`ll\`别名成功执行了\`ls -al\`命令，显示了当前目录中的所有文件和目录，包括隐藏文件及其详细信息。

### 列出所有别名

要查看系统中已定义的所有别名，可以直接输入\`alias\`命令：

\`\`\`bash
alias
\`\`\`

**示例输出：**

\`\`\`bash
alias ll='ls -al'
alias grep='grep --color=auto'
\`\`\`

**解读：** 该命令显示了当前系统中已定义的所有别名及其对应的命令。我们可以看到之前定义的\`ll\`别名，以及系统默认的一些别名。

### 删除别名

使用\`unalias\`命令可以删除指定的别名。以下是删除\`ll\`别名的示例：

\`\`\`bash
unalias ll
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功删除别名后，系统不会显示任何输出。可以通过再次使用\`alias\`命令来验证别名是否已被删除。

\`\`\`bash
alias
\`\`\`

**示例输出：**

\`\`\`bash
alias grep='grep --color=auto'
\`\`\`

**解读：** 列出的别名中已不包含\`ll\`，说明\`ll\`别名已成功删除。

### 创建永久别名

在某些情况下，您可能希望别名在每次登录时都可用。为此，需要将别名添加到用户的shell配置文件中（如\`.bashrc\`或\`.bash_profile\`）。例如，编辑\`.bashrc\`文件并添加以下内容：

\`\`\`bash
echo "alias ll='ls -al'" >> ~/.bashrc
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，无输出表示命令已执行且别名已成功添加到\`.bashrc\`文件中。要使别名立即生效，可以执行以下命令：

\`\`\`bash
source ~/.bashrc
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令已成功执行]
\`\`\`

**解读：** 通过\`source\`命令重新加载\`.bashrc\`文件后，新的别名立即生效。

### 总结

\`alias\`命令是提高命令行操作效率的重要工具。通过\`alias\`，可以为频繁使用的复杂命令创建简短别名，减少输入时间并降低出错率。了解如何创建、使用和管理别名，以及如何将别名永久保存，可以极大地简化日常操作。

        `,
    };
  },
});
</script>
<style scoped></style>
