<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## disown 命令详解

\`disown\` 是一个 Bash 内置命令，用于从当前 shell 的作业表中移除一个或多个作业，这样它们就不会再受到当前 shell 的终止信号影响。这个命令通常用于在后台运行进程时，让这些进程在用户退出 shell 后继续运行。

### 主要功能

- **移除作业**：使指定的作业不再受到 shell 的管理。
- **防止作业被挂起**：使作业在 shell 退出时仍继续运行。

### 语法

\`\`\`bash
disown [选项] [作业ID]
\`\`\`

### 选项

- \`-a\`：移除所有作业。
- \`-r\`：仅移除正在运行的作业。
- \`作业ID\`：指定要移除的作业。如果不指定，\`disown\` 将默认处理当前作业。

### 示例

以下示例展示了如何使用 \`disown\` 命令来管理后台作业。

#### 示例 1: 基本用法

\`\`\`bash
$ sleep 500 &
[1] 12345
$ jobs
[1]+ 12345  sleep 500
$ disown %1
$ jobs
\`\`\`

**解释**：在这个例子中，我们启动了一个后台作业 \`sleep 500\`。使用 \`jobs\` 命令查看作业列表，作业 ID 为 1。使用 \`disown %1\` 将该作业从作业表中移除。之后再次运行 \`jobs\` 命令，作业列表中已经不再显示这个作业。

#### 示例 2: 移除所有作业

\`\`\`bash
$ sleep 300 &
[1] 12346
$ sleep 600 &
[2] 12347
$ disown -a
$ jobs
\`\`\`

**解释**：在这个例子中，我们启动了两个后台作业。使用 \`disown -a\` 命令将所有后台作业从作业表中移除。使用 \`jobs\` 命令查看，作业列表已为空。

#### 示例 3: 仅移除正在运行的作业

\`\`\`bash
$ sleep 200 &
[1] 12348
$ sleep 100 &
[2] 12349
$ disown -r
$ jobs
\`\`\`

**解释**：在这个例子中，\`sleep 200\` 仍在运行中，而 \`sleep 100\` 已经完成。使用 \`disown -r\` 仅移除正在运行的作业。完成的作业 \`sleep 100\` 保持在作业表中。

### 输出解释

- **无输出，命令已成功执行**：当 \`disown\` 命令成功移除作业时，没有标准输出。
- **标准输出**：在运行 \`jobs\` 命令后，如果作业列表为空，表示作业已成功移除。

### 注意事项

- \`disown\` 命令仅在 Bash shell 中有效，不适用于其他 shell。
- \`disown\` 命令并不会终止作业，它只是移除作业的管理，从而让它们在 shell 退出后继续运行。

### 安装

\`disown\` 是 Bash 内置命令，默认情况下在所有 Bash 环境中可用，不需要单独安装。

### 总结

\`disown\` 命令是一个强大的工具，能够帮助用户管理后台作业，尤其在需要确保某些长时间运行的任务不受 shell 退出影响时特别有用。通过掌握 \`disown\` 的使用方法，用户可以更灵活地控制后台进程的执行状态。

        `,
    };
  },
});
</script>
<style scoped></style>
