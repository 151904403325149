<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## gunzip 命令详解

### 概述

\`gunzip\` 是一个用于解压缩 \`.gz\` 文件的命令，是 \`gzip\` 工具包中的一部分。\`gunzip\` 通常用于恢复由 \`gzip\` 压缩的文件。它的主要作用是解压缩指定的 \`.gz\` 文件，并恢复为原始文件。\`gunzip\` 与 \`gzip -d\` 命令功能相同，但更为简洁。

### 使用语法

\`\`\`bash
gunzip [option] [file...]
\`\`\`

- \`option\`: 用于指定解压缩行为的选项。
- \`file\`: 要解压缩的 \`.gz\` 文件。

### 重要参数

- \`-c\` 或 \`--stdout\`: 将解压缩结果输出到标准输出，而不更改原始文件。
- \`-k\` 或 \`--keep\`: 解压缩后保留 \`.gz\` 文件，不删除。
- \`-f\` 或 \`--force\`: 强制解压缩，即使目标文件已经存在。
- \`-v\` 或 \`--verbose\`: 显示解压缩过程中的详细信息。
- \`-r\` 或 \`--recursive\`: 递归解压缩目录中的所有 \`.gz\` 文件。

### 基本用法

#### 例1：解压缩单个文件

\`\`\`bash
gunzip example.txt.gz
\`\`\`

**输出解释**:
该命令会将 \`example.txt.gz\` 文件解压缩为 \`example.txt\`。

\`\`\`bash
ls
\`\`\`

\`\`\`bash
example.txt
\`\`\`

**解读**:
解压缩完成后，\`.gz\` 文件被删除，恢复为原始文件。

#### 例2：保留 \`.gz\` 文件并解压缩

\`\`\`bash
gunzip -k example.txt.gz
\`\`\`

**输出解释**:
此命令会解压缩 \`example.txt.gz\` 文件，同时保留压缩文件。

\`\`\`bash
ls
\`\`\`

\`\`\`bash
example.txt  example.txt.gz
\`\`\`

**解读**:
解压缩后，生成了原始的 \`example.txt\` 文件，并保留了压缩文件 \`example.txt.gz\`。

### 进阶用法

#### 例3：将解压缩结果输出到标准输出

\`\`\`bash
gunzip -c example.txt.gz
\`\`\`

**输出解释**:
此命令会将解压缩后的内容输出到标准输出，而不是写入文件。

\`\`\`bash
Hello, this is an example file content.
\`\`\`

**解读**:
该命令输出了文件的内容，但没有在文件系统中创建解压缩的文件。

#### 例4：递归解压缩目录中的所有 \`.gz\` 文件

\`\`\`bash
gunzip -r myfolder/
\`\`\`

**输出解释**:
此命令会递归解压缩 \`myfolder\` 目录及其子目录中的所有 \`.gz\` 文件。

\`\`\`bash
ls myfolder/
\`\`\`

\`\`\`bash
file1.txt  file2.log  subfolder/
\`\`\`

**解读**:
所有 \`.gz\` 文件都被解压缩为原始文件，且 \`.gz\` 文件被删除。

### 不同架构下的表现差异

在\`aarch64\`和\`x86_64/amd64\`架构的机器上，\`gunzip\` 的功能和输出表现通常没有差异。无论是解压缩文件的大小还是解压缩速度，\`gunzip\` 都与系统架构无关，因为它使用的是标准的解压缩算法。

### 不同版本的差异

早期版本的 \`gunzip\` 可能没有提供某些高级选项，如 \`-k\`（保留原始 \`.gz\` 文件）功能。用户在使用时应确保系统中安装的 \`gunzip\` 版本支持所需的选项，并根据需要查看对应版本的文档。

### 安全性与替代命令

\`gunzip\` 是一个相对安全的工具，但解压缩过程中需要注意权限问题，尤其是在解压缩到共享目录或系统目录时。使用 \`-f\` 选项时需谨慎，因为它可能覆盖已有的文件。

在某些情况下，可以使用 \`zcat\` 命令替代 \`gunzip -c\`，以直接输出压缩文件内容而无需解压缩文件。\`zcat\` 对于查看文件内容而不实际解压缩非常有用。

### 示例输出的架构差异

与\`gzip\` 类似，\`gunzip\` 在不同架构上的表现差异主要体现在解压缩速度上。现代的 \`x86_64/amd64\` 架构通常比较老的 \`aarch64\` 架构在解压缩性能上更为优越，但解压缩结果文件没有差异。

### 结论

\`gunzip\` 是一个简便易用的解压缩工具，广泛应用于Linux和Unix系统中。它的功能与\`gzip -d\`命令相同，但使用更为简洁。在使用\`gunzip\`时，根据需要选择合适的选项，以确保数据的安全和完整性。

        `,
    };
  },
});
</script>
<style scoped></style>
