<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ip 命令详解

\`ip\` 命令是 Linux 系统中用于网络配置和管理的工具，替代了较老的 \`ifconfig\` 和 \`route\` 命令。\`ip\` 命令提供了更丰富的功能，允许用户查看和管理网络接口、路由表、ARP 表、邻居发现等。本文将详细介绍 \`ip\` 命令的使用方法、重要参数及其输出结果。

### ip 是 bash 内置命令还是外部命令？

\`ip\` 命令是一个外部命令，通常位于 \`/sbin/ip\` 或 \`/usr/sbin/ip\`。由于其功能强大且复杂，\`ip\` 命令不能作为 bash 的内置命令。

### 重要参数

\`ip\` 命令的基本语法如下：

\`\`\`bash
ip [ OPTIONS ] OBJECT { COMMAND | help }
\`\`\`

其中 \`OBJECT\` 可以是 \`addr\`、\`link\`、\`route\` 等，常用的对象和命令包括：

- \`ip addr\`: 查看和管理网络地址
- \`ip link\`: 查看和管理网络接口
- \`ip route\`: 查看和管理路由表
- \`ip neigh\`: 查看和管理邻居缓存

### 示例1：查看网络接口

假设我们想要查看系统中的所有网络接口及其状态。

\`\`\`bash
ip link show
# 输出：
# 1: lo: <LOOPBACK,UP,LOWER_UP> mtu 65536 qdisc noqueue state UNKNOWN group default qlen 1
#     link/loopback 00:00:00:00:00:00
# 2: eth0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state UP group default qlen 1000
#     link/ether 02:42:ac:11:00:02 brd ff:ff:ff:ff:ff:ff
\`\`\`

**解读**: \`ip link show\` 显示了网络接口的信息，包括接口名称、状态、MTU 和 MAC 地址。接口 \`lo\` 是本地回环接口，\`eth0\` 是一个以太网接口，处于 UP 状态。

### 示例2：查看网络地址

如果我们想查看系统中配置的所有 IP 地址，可以使用以下命令：

\`\`\`bash
ip addr show
# 输出：
# 1: lo: <LOOPBACK,UP,LOWER_UP> mtu 65536 qdisc noqueue state UNKNOWN group default qlen 1
#     link/loopback 00:00:00:00:00:00
#     inet 127.0.0.1/8 scope host lo
#     valid_lft forever preferred_lft forever
# 2: eth0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state UP group default qlen 1000
#     link/ether 02:42:ac:11:00:02 brd ff:ff:ff:ff:ff:ff
#     inet 172.17.0.2/16 brd 172.17.255.255 scope global dynamic eth0
#     valid_lft 867sec preferred_lft 867sec
\`\`\`

**解读**: \`ip addr show\` 显示了每个网络接口的 IP 地址信息，包括地址、网络掩码、广播地址等。\`eth0\` 接口的 IP 地址为 \`172.17.0.2\`，其掩码为 \`/16\`。

### 示例3：添加和删除 IP 地址

我们可以使用 \`ip\` 命令来添加或删除 IP 地址。

\`\`\`bash
# 添加 IP 地址
sudo ip addr add 192.168.1.10/24 dev eth0

# 查看地址
ip addr show dev eth0
# 输出：
# 2: eth0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state UP group default qlen 1000
#     link/ether 02:42:ac:11:00:02 brd ff:ff:ff:ff:ff:ff
#     inet 172.17.0.2/16 brd 172.17.255.255 scope global dynamic eth0
#     valid_lft 867sec preferred_lft 867sec
#     inet 192.168.1.10/24 scope global eth0
#     valid_lft forever preferred_lft forever

# 删除 IP 地址
sudo ip addr del 192.168.1.10/24 dev eth0
\`\`\`

**解读**: 在上述示例中，我们通过 \`ip addr add\` 命令添加了一个新的 IP 地址，并使用 \`ip addr show\` 验证地址是否添加成功。最后，通过 \`ip addr del\` 命令删除该 IP 地址。

### 不同架构下的执行结果

\`ip\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致。无论在哪种架构下，\`ip\` 命令的功能和输出均相同。

### 版本差异

\`ip\` 命令的不同版本（如 \`iproute2\` 的不同版本）在功能上可能有所不同，但主要命令和参数保持一致。在较旧的版本中，可能缺少一些新功能或参数，因此建议使用最新版本的 \`iproute2\`。

### 被淘汰情况及替代命令

\`ip\` 命令并没有被淘汰，反而是现代 Linux 系统中推荐的网络管理工具。它替代了 \`ifconfig\`、\`route\` 和其他许多旧命令。

### 安装

\`ip\` 命令通常预装在现代 Linux 发行版中。如果未安装，可以通过以下包管理器安装：

- **Ubuntu**: \`iproute2\` 包
- **RHEL/CentOS**: \`iproute\` 包

可以通过以下命令确认 \`ip\` 命令是否已安装：

\`\`\`bash
which ip
# 输出：
# /sbin/ip
\`\`\`

### 使用root权限的场景

某些 \`ip\` 命令操作需要 \`root\` 权限，例如添加或删除 IP 地址。可以通过 \`sudo\` 执行相关命令。

\`\`\`bash
sudo ip addr add 192.168.1.10/24 dev eth0
# 输出：
# 【无输出，命令已成功执行】
\`\`\`

**解读**: 使用 \`sudo\` 提升权限来执行 IP 地址添加操作，并确认命令成功执行。

### 总结

\`ip\` 命令是网络管理的重要工具，提供了对网络接口、路由和邻居发现等功能的全面支持。它的灵活性和功能性使其成为现代 Linux 系统中不可或缺的命令。如果你需要管理网络，熟悉 \`ip\` 命令是必不可少的。

        `,
    };
  },
});
</script>
<style scoped></style>
