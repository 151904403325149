<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="烦请修改/etc/profile, 优化输入命令时候的安全确认，例如alias rm='rm -i', 还有cp, mv。也将/opt/app/bin添加到PATH环境变量里，能直接使用目录里的命令。"
      source="基础设施服务中心-操作系统部门"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="2" :popularity="5" />
      <GameScoreDisplay score="20" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdRm",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10067",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
在 \`/etc/profile\` 中配置的内容会影响所有用户的登录 shell 环境。通常会在这里设置一些全局别名、环境变量、路径、shell 选项等。以下是一些常见的配置项供您参考：

### 1. **全局别名 (Alias)**
别名用于简化命令或为常用命令设置更安全的选项。通常在 \`/etc/profile\` 中设置一些安全和常用的别名。

\`\`\`bash
# 更安全的 rm 命令，防止意外删除，覆盖重要文件
alias rm='rm -i'
alias cp='cp -i'
alias mv='mv -i'

# 简化常用命令
alias ll='ls -l'
alias grep='grep --color=auto'
\`\`\`

### 2. **环境变量 (Environment Variables)**
环境变量可以设置全局的路径、语言设置、编辑器、提示符等。

\`\`\`bash
export PATH=$PATH:/opt/app/bin
\`\`\`

  `,
      markdownContentExpand: `
\`alias\` 只会在**交互式的 Bash shell** 中生效。具体来说：

### 交互式与非交互式 Bash 区别
- **交互式 shell**：用户直接与 shell 交互（例如在终端或命令行中），它会加载用户环境配置，比如 \`~/.bashrc\` 和 \`/etc/profile.d\` 中的别名、函数等。
- **非交互式 shell**：当脚本运行时，shell 只执行命令，而不会加载交互式环境配置，也不会自动应用别名。

因此，如果你在脚本中使用 \`alias\` 命令，除非显式地加载包含别名的文件（如 \`~/.bashrc\` 或 \`/etc/profile\`），否则别名不会生效。

### 解决方法
如果希望在脚本中使用别名（不推荐），有两种方法：

1. **在脚本中显式定义别名**：
   \`\`\`bash
   alias ll='ls -l'
   ll
   \`\`\`

2. **加载 \`~/.bashrc\` 或其他配置文件**：
   \`\`\`bash
   #!/bin/bash
   source ~/.bashrc
   ll
   \`\`\`

但是，建议在脚本中使用完整命令路径或避免依赖别名，以确保脚本在不同环境中都能正确运行。

  `,
      markdownInterview: ` 
### 选择题

**1. 在修改 \`/etc/profile\` 文件后，如何使修改立即生效？**  
A. 重启计算机  
B. 注销并重新登录  
C. 使用 \`source /etc/profile\` 命令  
D. 使用 \`chmod +x /etc/profile\` 命令  

**2. \`/etc/profile\` 文件主要在什么时候被执行？**  
A. 用户执行脚本时  
B. 每次用户登录时  
C. 每次用户打开一个新终端时  
D. 关机时  

**3. 在 \`/etc/profile\` 中定义的环境变量和别名的作用范围是？**  
A. 仅限当前用户  
B. 仅限当前用户的会话  
C. 所有用户的登录 shell  
D. 所有非交互式 shell  

**4. 如果要在所有用户的 shell 中定义别名，应该将别名放在哪个文件中？**  
A. \`/etc/bashrc\`  
B. \`/etc/profile.d/custom_alias.sh\`  
C. \`/etc/profile\`  
D. \`/etc/shells\`

**5. 以下哪种情况不适合使用 \`/etc/profile\` 文件进行设置？**  
A. 设置全局环境变量  
B. 设置用户特定的路径变量  
C. 设置默认编辑器  
D. 设置命令别名  

---

### 问答题

**1. \`/etc/profile\` 文件与 \`~/.bashrc\` 文件的区别是什么？什么时候应该修改 \`/etc/profile\` 文件？**

**2. 在 \`/etc/profile\` 中，如何确保一个自定义脚本在每个用户登录时自动执行？请给出步骤和示例代码。**

**3. 假设你想为所有用户设置一个全局的 \`PS1\` 提示符，并且让它显示用户名和当前路径，你会如何在 \`/etc/profile\` 中配置？**

**4. 在 \`/etc/profile\` 中使用 \`ulimit\` 来限制系统资源时，你如何确保这些限制只对普通用户生效，而不影响 \`root\` 用户？**

**5. 如果 \`/etc/profile\` 文件被配置错误，导致用户登录时出现问题，你该如何进行排查和修复？**

---

### 答案

---

### 选择题答案
1. **C** 使用 \`source /etc/profile\` 命令使修改立即生效。
2. **B** \`/etc/profile\` 文件在每次用户登录时执行。
3. **C** \`/etc/profile\` 中定义的环境变量和别名适用于所有用户的登录 shell。
4. **B** 在 \`/etc/profile.d/\` 中创建自定义脚本文件是更好的做法。
5. **B** 用户特定的设置应该在用户的 \`~/.bashrc\` 或 \`~/.bash_profile\` 文件中，而不是 \`/etc/profile\` 中。

---

### 问答题答案
1. **区别**：  
   - \`/etc/profile\` 是一个全局配置文件，在用户登录时加载，影响所有用户。  
   - \`~/.bashrc\` 是针对单个用户的配置文件，通常在交互式 shell 启动时加载。  
   **何时修改 \`/etc/profile\`**：如果需要设置系统范围的环境变量或全局别名（对所有用户生效），则修改 \`/etc/profile\`。

2. **确保自定义脚本在每次登录时执行**：  
   在 \`/etc/profile.d/\` 目录下创建一个脚本文件，并确保它具有可执行权限。示例代码：
   \`\`\`bash
   # /etc/profile.d/custom_script.sh
   echo "Welcome to the system, $(whoami)!"
   \`\`\`

3. **设置全局 \`PS1\` 提示符**：在 \`/etc/profile\` 中添加以下代码：
   \`\`\`bash
   export PS1='\\u@\\h:\\w\\$ '
   \`\`\`

4. **限制普通用户资源**：可以在 \`/etc/profile\` 中通过检查用户是否为 \`root\` 来区别对待：
   \`\`\`bash
   if [ "$(id -u)" -ne 0 ]; then
       ulimit -n 1024   # 仅对非 root 用户生效
   fi
   \`\`\`

5. **排查和修复**：  
   - **步骤1**：通过 \`ssh\` 或终端进入系统，使用 \`single user mode\` 或 \`recovery mode\`，跳过配置文件加载。  
   - **步骤2**：编辑 \`/etc/profile\`，检查并修复错误的配置。可以通过 \`source /etc/profile\` 测试更改是否正常。

    `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
