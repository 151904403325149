<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## file 命令详解

\`file\` 命令是Linux系统中的一个非常有用的工具，用于确定文件的类型。它通过检查文件的内容而不是其扩展名来识别文件类型，因此对于处理未知文件格式或排查文件相关问题非常有帮助。

### 基本语法

\`file\` 命令的基本语法如下：

\`\`\`bash
file [选项] 文件名
\`\`\`

- **文件名**: 你想要检查类型的文件或目录。
- **选项**: 用于控制 \`file\` 命令的行为，可以调整输出的详细程度、格式等。

### 重要参数和选项

#### 1. \`-b\` 选项：去除文件名的输出

默认情况下，\`file\` 命令会输出文件名和对应的类型。使用 \`-b\` 选项可以去除文件名，只显示文件类型。

\`\`\`bash
$ file -b /bin/ls
ELF 64-bit LSB executable, x86-64, version 1 (SYSV), dynamically linked, interpreter /lib64/ld-linux-x86-64.so.2, for GNU/Linux 3.2.0, BuildID[sha1]=3d3f5ec4e080346f9af746b2c5ae0ff3cf1a5a54, stripped
\`\`\`

**输出解读：**

该命令输出了文件 \`/bin/ls\` 的类型信息，去除了文件名。这里显示的是一个64位的ELF可执行文件，适用于x86-64架构。

#### 2. \`-i\` 选项：显示MIME类型

使用 \`-i\` 选项，可以让 \`file\` 命令输出文件的MIME类型，常用于Web开发中检查文件格式。

\`\`\`bash
$ file -i /etc/passwd
/etc/passwd: text/plain; charset=us-ascii
\`\`\`

**输出解读：**

此命令输出了 \`/etc/passwd\` 文件的MIME类型，显示为 \`text/plain\`，表示这是一个纯文本文件，字符集为ASCII。

#### 3. \`-s\` 选项：检查块设备文件

使用 \`-s\` 选项可以让 \`file\` 命令检查块设备文件，而不仅仅是报告它是一个特殊文件。这个选项通常需要root权限。

\`\`\`bash
$ sudo file -s /dev/sda
/dev/sda: DOS/MBR boot sector; partition 1 : ID=0x83, start-CHS (0x1,0,1), end-CHS (0x3ff,63,32), startsector 2048, 2097152 sectors
\`\`\`

**输出解读：**

\`file\` 命令输出了块设备 \`/dev/sda\` 的详细信息，显示其是一个使用MBR分区表的磁盘，包含一个分区。

#### 4. \`-z\` 选项：检查压缩文件内容

使用 \`-z\` 选项可以让 \`file\` 命令检查压缩文件的内容，显示其压缩前的文件类型。

\`\`\`bash
$ file -z example.gz
example.gz: gzip compressed data, was "example.txt", last modified: Tue Sep  9 13:45:02 2024, from Unix
\`\`\`

**输出解读：**

该命令显示了压缩文件 \`example.gz\` 的信息，表示该文件是一个gzip压缩文件，原始文件名为 \`example.txt\`，最后修改时间为2024年9月9日。

### 示例演示

#### 1. 检查普通文件类型

\`\`\`bash
$ file /etc/hostname
/etc/hostname: ASCII text
\`\`\`

**输出解读：**

\`file\` 命令输出显示 \`/etc/hostname\` 文件为ASCII文本文件。

#### 2. 检查目录

\`\`\`bash
$ file /home/sre
/home/sre: directory
\`\`\`

**输出解读：**

\`file\` 命令显示 \`/home/sre\` 是一个目录。

#### 3. 检查符号链接

\`\`\`bash
$ file /bin/sh
/bin/sh: symbolic link to dash
\`\`\`

**输出解读：**

该命令显示 \`/bin/sh\` 是一个符号链接，指向 \`dash\`。

### 权限要求

在大多数情况下，使用 \`file\` 命令并不需要特殊权限。但当你使用 \`-s\` 选项检查块设备或其他特殊文件时，可能需要root权限。

#### 需要root权限的示例

\`\`\`bash
$ sudo file -s /dev/sda1
/dev/sda1: Linux filesystem
\`\`\`

**输出解读：**

使用 \`sudo\` 执行该命令后，\`file\` 显示 \`/dev/sda1\` 是一个Linux文件系统分区。

### 总结

\`file\` 命令是一个强大的工具，可以用于识别文件的类型、MIME类型、块设备信息等。通过掌握 \`file\` 命令及其相关选项，你可以轻松地确定任何文件的性质，无论它是文本文件、二进制文件、压缩文件、还是设备文件。这对系统管理员和开发者来说，都是一个极其有用的工具，特别是在处理多种文件类型时，可以显著提高工作效率。

        `,
    };
  },
});
</script>
<style scoped></style>
