<template>
  <div class="container">
    <div class="card">
      <div class="question">{{ question }}</div>
      <div class="radio-group">
        <label class="radio-label">
          <input
            type="radio"
            name="answer"
            value="A"
            v-model="selectedAnswer"
          />
          A. {{ ans_A }}
        </label>
        <label class="radio-label">
          <input
            type="radio"
            name="answer"
            value="B"
            v-model="selectedAnswer"
          />
          B. {{ ans_B }}
        </label>
        <label class="radio-label">
          <input
            type="radio"
            name="answer"
            value="C"
            v-model="selectedAnswer"
          />
          C. {{ ans_C }}
        </label>
        <label class="radio-label">
          <input
            type="radio"
            name="answer"
            value="D"
            v-model="selectedAnswer"
          />
          D. {{ ans_D }}
        </label>
      </div>
      <button type="button" class="submit-button" @click="submitAnswer">
        提交
      </button>
      <div v-if="submitted" class="result">
        <div v-if="isCorrect" class="correct-answer">回答正确！</div>
        <div v-else class="wrong-answer">
          回答错误！正确答案是：{{ correctAns.charAt(4) }}
        </div>
        <div class="explanation">解释：{{ explain }}</div>
        <div class="tags">
          标签：
          <el-tag
            v-for="(tag, index) in tags"
            :key="index"
            size="small"
            round
            >{{ tag.replace(/"/g, "") }}</el-tag
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      question: "",
      ans_A: "",
      ans_B: "",
      ans_C: "",
      ans_D: "",
      correctAns: "",
      explain: "",
      tags: [],
      selectedAnswer: "",
      submitted: false,
      isCorrect: false,
    };
  },
  methods: {
    fetchQuestion() {
      axios
        .get("http://sre.azlinux.com:5090/one_question_a_day")
        .then((response) => {
          this.question = response.data.q;
          this.ans_A = response.data.ans_A;
          this.ans_B = response.data.ans_B;
          this.ans_C = response.data.ans_C;
          this.ans_D = response.data.ans_D;
          this.correctAns = response.data.correct_ans;
          this.explain = response.data.explain;
          this.tags = response.data.tags;

          // this.tags = response.data.tags.split(",");
        })
        .catch((error) => {
          console.error("Error fetching the question:", error);
          ElMessage({
            message: "网络连接失败，稍后重试，或检查网络",
            type: "error",
            duration: 2000,
            offset: 120,
          });
        });
    },
    submitAnswer() {
      this.submitted = true;
      if (this.selectedAnswer === this.correctAns.charAt(4)) {
        this.isCorrect = true;
      }
    },
  },
  mounted() {
    this.fetchQuestion();
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 20px;
  text-align: left;
}

.correct-answer {
  color: #28a745; /* 绿色表示成功 */
  padding: 10px;
  /* border: 1px solid #28a745; */
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f0fff0; /* 浅绿色背景 */
}

.wrong-answer {
  color: #4a148c;
  padding: 10px;
  /* border: 1px solid #28a745; */
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fce4ec;
}

.question {
  margin-bottom: 0.8rem;
}

.radio-group {
  margin-bottom: 1rem;
}

.radio-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.submit-button {
  width: 6rem;
  height: 1.4rem;
  background-color: #00d1b2;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.result {
  margin-top: 1rem;
  font-size: 15px;
}

.explanation {
  margin: 6px;
  font-size: 15px;
}

.tags {
  margin: 6px;
  font-size: 15px;
}

.el-tag {
  font-size: 15px;
  margin-right: 6px;
}
</style>
