<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## mkdir 命令详解

\`mkdir\`命令是Linux系统中用于创建新目录的基本命令。它允许用户在指定的位置创建一个或多个目录。本文将详细介绍\`mkdir\`命令的用法、常用参数，并通过示例演示其实际应用及输出结果。

### 基本用法

\`mkdir\`命令最基本的用法是指定要创建的目录的名称。例如，要在当前目录下创建一个名为\`new_directory\`的目录，可以使用以下命令：

\`\`\`bash
mkdir new_directory
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls\`命令可以验证新目录是否被创建。

\`\`\`bash
ls
\`\`\`

**示例输出：**

\`\`\`
new_directory
\`\`\`

**解读：** \`ls\`命令显示了当前目录下的新目录\`new_directory\`，确认了\`mkdir\`命令的成功执行。

### \`-p\` 参数：递归创建目录

\`-p\`参数允许创建多层目录，如果指定的父目录不存在，\`mkdir\`将自动创建它们。例如，要创建一个目录路径\`/home/sre/new_folder/subfolder\`，可以使用以下命令：

\`\`\`bash
mkdir -p /home/sre/new_folder/subfolder
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls -R\`命令可以查看递归创建的目录结构。

\`\`\`bash
ls -R /home/sre/new_folder
\`\`\`

**示例输出：**

\`\`\`
/home/sre/new_folder:
subfolder

/home/sre/new_folder/subfolder:
[当前目录为空]
\`\`\`

**解读：** \`ls -R\`命令显示了递归创建的目录\`subfolder\`。目录结构表明\`mkdir -p\`命令创建了\`new_folder\`和\`subfolder\`。

### \`-v\` 参数：显示详细信息

\`-v\`参数使\`mkdir\`命令在创建目录时显示详细信息。例如，要创建一个目录并显示创建过程，可以使用以下命令：

\`\`\`bash
mkdir -v new_directory
\`\`\`

**示例输出：**

\`\`\`
mkdir: created directory 'new_directory'
\`\`\`

**解读：** \`-v\`参数使\`mkdir\`命令在创建目录时显示了详细的操作信息，告知用户目录\`new_directory\`已被创建。

### 目录名称中的空格

当目录名称中包含空格时，必须使用引号将目录名称括起来。例如，要创建名为\`"My Folder"\`的目录，可以使用以下命令：

\`\`\`bash
mkdir "My Folder"
\`\`\`

**示例输出：**

\`\`\`
[无输出，命令成功执行]
\`\`\`

**解读：** 成功执行后，系统不会显示任何输出。使用\`ls\`命令可以验证目录是否创建成功。

\`\`\`bash
ls
\`\`\`

**示例输出：**

\`\`\`
"My Folder"
\`\`\`

**解读：** \`ls\`命令显示了创建的目录\`"My Folder"\`。引号确保了目录名称中的空格被正确处理。

### 错误处理

如果尝试创建一个已存在的目录，\`mkdir\`命令将返回错误消息。例如，如果\`new_directory\`已经存在，再次运行\`mkdir\`命令将显示错误信息：

\`\`\`bash
mkdir new_directory
\`\`\`

**示例输出：**

\`\`\`
mkdir: cannot create directory 'new_directory': File exists
\`\`\`

**解读：** 错误消息表明目录\`new_directory\`已经存在，\`mkdir\`命令无法创建重复的目录。

### 总结

\`mkdir\`命令是创建目录的基本工具，支持多种参数来增强其功能。使用\`-p\`参数可以递归创建多层目录，使用\`-v\`参数可以显示详细的创建信息。在处理包含空格的目录名称时，使用引号是必要的。掌握\`mkdir\`命令的基本用法和参数，将使你在Linux系统中更高效地管理目录。

        `,
    };
  },
});
</script>
<style scoped></style>
