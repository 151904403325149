<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## zless 命令详解

\`zless\` 是一个用于查看压缩文件内容的命令行工具，类似于 \`less\` 命令，但能够直接处理 \`.gz\` 格式的压缩文件。它提供了一个方便的界面，让用户可以逐页查看大文件而不必解压缩文件。通过 \`zless\`，用户可以直接在压缩文件中进行浏览和搜索，非常适合处理大型日志文件。

### 安装

在使用 \`zless\` 之前，需要确保系统中安装了 \`gzip\` 包。它通常在大多数 Linux 发行版中默认安装。如果需要安装，可以使用以下命令：

#### Ubuntu

\`\`\`bash
sudo apt install gzip
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install gzip
\`\`\`

### 基本语法

\`\`\`bash
zless [选项] [压缩文件]
\`\`\`

- **选项**：用于指定所需的操作。
- **压缩文件**：指定要查看的 \`.gz\` 文件。

### 常用选项

- \`-N\`：显示行号。
- \`-S\`：在每个页面的结尾时显示压缩信息。
- \`-f\`：强制查看即使文件不是 \`.gz\` 格式。

### 示例用法

#### 示例一：查看压缩文件内容

可以使用以下命令查看压缩文件的内容：

\`\`\`bash
zless example.log.gz
\`\`\`

**输出**：

\`\`\`plaintext
<内容将显示在 zless 界面中，用户可以使用方向键进行导航>
\`\`\`

**解读**：在这里，输出将直接在 \`zless\` 的界面中显示。用户可以使用箭头键上下移动，使用 \`q\` 退出。

#### 示例二：显示行号

如果希望在浏览文件时显示行号，可以使用 \`-N\` 选项：

\`\`\`bash
zless -N example.log.gz
\`\`\`

**输出**：

\`\`\`plaintext
     1  [压缩文件内容]
     2  [压缩文件内容]
     3  [压缩文件内容]
     ...
\`\`\`

**解读**：使用 \`-N\` 选项后，每一行前都会显示行号，便于定位内容。

### 不同架构的输出

\`zless\` 在不同架构（如 aarch64 和 x86_64/amd64）上通常表现一致，因此不需要额外的输出比较。

### 版本差异

\`zless\` 的不同版本可能会在选项和功能上有差异。用户可以通过以下命令查看当前版本：

\`\`\`bash
zless --version
\`\`\`

**输出示例**：

\`\`\`plaintext
zless 5.4.0
\`\`\`

不同版本的 \`zless\` 可能增加了新选项或修复了已知问题。

### 权限要求

\`zless\` 命令不需要特定的权限，任何用户都可以查看自己的压缩文件。如果需要查看其他用户的文件，可能需要使用 \`sudo\` 提升权限。例如：

\`\`\`bash
sudo zless /var/log/syslog.gz
\`\`\`

#### 示例输出

\`\`\`plaintext
<内容将显示在 zless 界面中，用户可以使用方向键进行导航>
\`\`\`

### 总结

\`zless\` 是一个非常实用的工具，用于方便地查看压缩文件的内容。通过灵活的选项设置，用户可以根据需要浏览文件，快速查找所需的信息。了解并熟练使用 \`zless\` 可以提高工作效率，尤其是在处理大文件和日志时。

        `,
    };
  },
});
</script>
<style scoped></style>
