<template>
  <div
    class="knowledge-card-wrapper"
    :style="{ height: isCardVisible ? 'auto' : '100%', overflow: 'hidden' }"
  >
    <button class="expand-button" @click.stop="toggleCard">展开面试题</button>
    <transition name="fade">
      <div class="knowledge-card" v-show="isCardVisible">
        <button
          v-if="isCardVisible"
          class="collapse-button-1"
          @click.stop="toggleCard"
        >
          <span class="collapse-icon">🔽</span>
          合并面试题
        </button>
        <MarkdownRead :content="parsedContent" />
        <button
          v-if="isCardVisible"
          class="collapse-button"
          @click.stop="toggleCard"
        >
          <span class="collapse-icon">🔽</span>
          合并面试题
        </button>
      </div>
    </transition>
    <div v-if="!isCardVisible" @click="toggleCard"></div>
  </div>
</template>

<script>
import MarkdownRead from "./MarkdownRead.vue";

export default {
  name: "KnowledgeCardInterview",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  components: {
    MarkdownRead,
  },
  data() {
    return {
      isCardVisible: false,
      parsedContent: this.content, // 直接使用 content 作为 parsedContent
    };
  },
  methods: {
    toggleCard() {
      this.isCardVisible = !this.isCardVisible;
    },
  },
  watch: {
    content: function (newContent) {
      this.parsedContent = newContent; // 更新 parsedContent
    },
  },
};
</script>

<style scoped>
.knowledge-card-wrapper {
  margin: 6px 1rem;
  overflow: hidden;
  position: relative;
  transition: height 0.8s ease;
  padding: 10px; /* 添加内间距以美化外观 */
}

.expand-button {
  padding: 8px 16px;
  background-color: #9e9d24;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px; /* 调整字体大小 */
  display: block; /* 将按钮设置为块级元素以实现左对齐 */
}

.knowledge-card {
  font-family: "Microsoft YaHei", "微软雅黑", "SimSun", "Inter",
    "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial,
    sans-serif;
  padding: 20px;
  background-color: #f0f4f8;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.collapse-button-1 {
  margin: 12px 1rem;
  padding: 6px 12px;
  background-color: #009688; /* 橄榄绿按钮 */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute; /* 绝对定位 */
  right: 20px; /* 右侧边距 */
  top: 20px;
}

.collapse-button {
  margin: 12px 1rem;
  padding: 6px 12px;
  background-color: #009688; /* 橄榄绿按钮 */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute; /* 绝对定位 */
  right: 20px; /* 右侧边距 */
  bottom: 20px;
}

.collapse-icon {
  margin-right: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
