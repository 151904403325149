<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## netstat 命令详解

\`netstat\` 是一个用于显示网络连接、路由表、接口统计信息、伪连接等网络信息的命令行工具。它通常用于网络故障排除和性能监测。

### netstat 是 bash 内置命令还是外部命令？

\`netstat\` 是一个外部命令，通常位于 \`/bin/netstat\` 或 \`/usr/bin/netstat\`。在某些现代 Linux 发行版中，\`netstat\` 被推荐使用 \`ss\` 命令替代。

### 基本语法

\`netstat\` 命令的基本语法如下：

\`\`\`bash
netstat [选项]
\`\`\`

### 重要参数及示例

#### 1. 显示所有连接

要查看系统上所有的网络连接，包括 TCP 和 UDP 连接，可以使用 \`-a\` 参数：

\`\`\`bash
netstat -a
\`\`\`

**示例输出：**

\`\`\`plaintext
Proto Recv-Q Send-Q Local Address           Foreign Address         State
tcp        0      0 azlinux-prod-cn:22     192.168.1.10:34567     ESTABLISHED
tcp6       0      0 [::]:80                [::]:*                  LISTEN
\`\`\`

- **解读**：
  - \`Proto\`：协议类型（TCP/UDP）。
  - \`Local Address\`：本地地址和端口。
  - \`Foreign Address\`：远程地址和端口。
  - \`State\`：连接状态（如 ESTABLISHED、LISTEN）。

#### 2. 显示网络接口信息

使用 \`-i\` 参数可以显示网络接口的统计信息：

\`\`\`bash
netstat -i
\`\`\`

**示例输出：**

\`\`\`plaintext
Kernel Interface table
Iface     MTU     RX-OK RX-ERR RX-DRP RX-OVR TX-OK TX-ERR TX-DRP TX-OVR Flg
eth0      1500    12345      0      0      0  67890      0      0      0 BMRU
\`\`\`

- **解读**：
  - \`Iface\`：接口名称。
  - \`MTU\`：最大传输单元。
  - \`RX-OK\` 和 \`TX-OK\`：接收和发送的有效数据包数量。

#### 3. 显示路由表

使用 \`-r\` 参数可以查看系统的路由表：

\`\`\`bash
netstat -r
\`\`\`

**示例输出：**

\`\`\`plaintext
Kernel IP routing table
Destination     Gateway         Genmask         Flags Metric Ref    Use Iface
0.0.0.0         192.168.1.1     0.0.0.0         UG    100    0        0 eth0
\`\`\`

- **解读**：
  - \`Destination\`：目标网络。
  - \`Gateway\`：网关地址。
  - \`Genmask\`：子网掩码。

#### 4. 显示监听的端口

要查看当前正在监听的端口，可以使用 \`-l\` 参数：

\`\`\`bash
netstat -l
\`\`\`

**示例输出：**

\`\`\`plaintext
Proto Recv-Q Send-Q Local Address           Foreign Address         State
tcp        0      0 azlinux-prod-cn:22     0.0.0.0:*              LISTEN
\`\`\`

- **解读**：
  - 显示所有当前监听的 TCP 和 UDP 端口。

### 检查安装状态

可以通过 \`which\` 命令检查 \`netstat\` 是否已安装：

\`\`\`bash
which netstat
# 输出示例：
# /usr/bin/netstat
\`\`\`

输出表明 \`netstat\` 已安装并位于 \`/usr/bin\` 目录。

### 不同架构下的执行结果

\`netstat\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，功能相同，因此不需要单独列出不同架构下的输出。

### 版本差异

某些版本的 Linux 发行版可能会替代 \`netstat\` 命令，推荐使用 \`ss\` 命令。以下是 \`ss\` 的基本用法示例：

\`\`\`bash
ss -tuln
\`\`\`

**输出示例：**

\`\`\`plaintext
Netid State  Recv-Q Send-Q Local Address:Port  Peer Address:Port
tcp   LISTEN 0      128    0.0.0.0:22        0.0.0.0:*
\`\`\`

### 安装

在某些 Linux 发行版中，\`netstat\` 命令由 \`net-tools\` 包提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install net-tools
  \`\`\`

- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install net-tools
  \`\`\`

### 使用root权限的场景

某些情况下，查看网络连接信息可能需要 root 权限：

\`\`\`bash
sudo netstat -tuln
\`\`\`

**输出示例：**

\`\`\`plaintext
Proto Recv-Q Send-Q Local Address:Port  Peer Address:Port
tcp   LISTEN 0      128    0.0.0.0:80      0.0.0.0:*
\`\`\`

### 总结

\`netstat\` 是一个强大的网络工具，可以帮助用户监控网络状态和连接。虽然现代 Linux 系统推荐使用 \`ss\` 命令替代，但 \`netstat\` 仍然是很多用户的首选工具。通过熟练使用 \`netstat\` 的各种参数，用户可以轻松获取网络连接和路由信息，为网络故障排除提供有力支持。

        `,
    };
  },
});
</script>
<style scoped></style>
