<template>
  <span class="task-os-display"> 任务OS: {{ taskOS }} </span>
</template>

<script>
export default {
  name: "TaskOSDisplay",
  props: {
    taskOS: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.task-os-display {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  background: linear-gradient(
    145deg,
    #e0f7fa,
    /* 淡蓝色 */ #e0ffe0 /* 淡绿色 */
  );
  padding: 12px 24px; /* 增加按钮的尺寸感 */
  border-radius: 12px; /* 圆角按钮 */
}
</style>
