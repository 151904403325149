<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## init 命令详解

\`init\` 是一个系统初始化命令，它是 Unix 和类 Unix 系统（如 Linux）中的第一个进程。\`init\` 的主要职责是启动和管理系统的其他进程。它负责系统启动过程中的各种任务，包括启动系统服务和维护系统运行级别。

### 使用语法

\`\`\`bash
init [选项] [级别]
\`\`\`

### 重要参数

- \`0\`：关闭系统（关机）。
- \`1\`：进入单用户模式。
- \`2\`：多用户模式，没有网络服务。
- \`3\`：多用户模式，包含网络服务。
- \`4\`：保留，通常未使用。
- \`5\`：多用户模式，包含图形用户界面（GUI）。
- \`6\`：重新启动系统。
- \`-h\` 或 \`--help\`：显示帮助信息。
- \`-V\` 或 \`--version\`：显示版本信息。

### 基本用法

#### 例1：关闭系统

\`\`\`bash
sudo init 0
\`\`\`

**输出解释**:
该命令将关闭系统。

**解读**:
系统将开始关闭过程，所有进程将被安全终止，然后系统会关机。此操作通常需要 root 权限。

#### 例2：进入单用户模式

\`\`\`bash
sudo init 1
\`\`\`

**输出解释**:
该命令将系统切换到单用户模式。

**解读**:
在单用户模式下，只有 root 用户可以登录，通常用于系统维护和故障排除。此操作通常需要 root 权限。

#### 例3：重新启动系统

\`\`\`bash
sudo init 6
\`\`\`

**输出解释**:
该命令将重新启动系统。

**解读**:
系统将关闭所有进程并重新启动。这是一个安全的重新启动操作，通常用于系统更新或故障排除。此操作通常需要 root 权限。

#### 例4：查看 \`init\` 命令的版本信息

\`\`\`bash
init -V
\`\`\`

**输出解释**:
该命令显示 \`init\` 命令的版本信息。

\`\`\`bash
init version 2.96
\`\`\`

**解读**:
显示 \`init\` 命令的版本，帮助用户确认当前使用的版本。

### 不同架构下的表现差异

\`init\` 命令在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的表现基本一致，因为它是一个用户空间命令，与硬件架构无关。

### 不同版本的差异

\`init\` 命令的行为在不同的 Unix/Linux 系统中可能有所不同。例如，\`SysVinit\` 和 \`Upstart\` 的实现有所不同。现代 Linux 发行版中，\`systemd\` 已经取代了传统的 \`init\` 作为系统和服务管理器。

### 替代命令

- \`systemctl\`：在现代 Linux 系统中，\`systemd\` 提供了替代 \`init\` 的功能，包括服务管理和系统初始化。
- \`telinit\`：在使用 \`SysVinit\` 的系统中，\`telinit\` 是 \`init\` 的替代命令，用于更改运行级别。

**主机名**: azlinux-prod-cn

**用户**: sre (UID: 1005, GID: sre-group)

**需要 root 权限的选项**:
- \`init\` 命令通常需要 root 权限执行，因为它涉及系统级的操作。 

**示例**:
\`\`\`bash
sudo init 0
\`\`\`

**输出**:
【无输出，命令已成功执行】

        `,
    };
  },
});
</script>
<style scoped></style>
