<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## reboot 命令详解

\`reboot\` 命令用于重新启动计算机。它是一个系统管理命令，通常用于需要重启操作系统的场景，例如安装了新的系统更新或者配置更改后。该命令可以立即执行重启，或者在一定时间后执行。

### 命令格式

\`\`\`bash
reboot [选项]
\`\`\`

### 主要参数

- \`-f\`：强制重启，不执行正常的关机过程。
- \`-p\`：在重启之前关闭电源。这个选项在某些系统中可能会有所不同。
- \`-w\`：只记录重启事件，不实际执行重启。
- \`-h\`：显示帮助信息。

### 使用示例

1. **立即重启计算机**

   \`\`\`bash
   sudo reboot
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo reboot
   \`\`\`

   **解读**：
   - \`reboot\` 命令会立即重新启动计算机，执行时不显示任何输出，计算机会关闭并重新启动。

2. **强制重启**

   \`\`\`bash
   sudo reboot -f
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo reboot -f
   \`\`\`

   **解读**：
   - \`-f\` 选项会跳过正常的关机过程，强制立即重启计算机。这在系统挂起或无法正常关机时很有用，但可能导致未保存的数据丢失。

3. **在重启之前关闭电源**

   \`\`\`bash
   sudo reboot -p
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo reboot -p
   \`\`\`

   **解读**：
   - \`-p\` 选项在重启之前关闭电源。该选项在某些系统中可能有不同的效果，具体取决于系统配置。

4. **记录重启事件**

   \`\`\`bash
   sudo reboot -w
   \`\`\`

   **操作示例**：

   \`\`\`bash
   $ sudo reboot -w
   \`\`\`

   **解读**：
   - \`-w\` 选项只记录重启事件到日志文件，而不实际执行重启。这可以用来测试重启命令的影响。

### 不同架构下的输出

\`reboot\` 命令的输出在不同架构下通常是相似的，因为它依赖于操作系统的重启机制，而不是具体的硬件架构。通常，\`reboot\` 命令不会输出详细信息，而是直接执行重启操作。

### 版本差异

\`reboot\` 命令的功能在不同版本的操作系统中通常保持一致，但具体实现可能有所不同。你可以通过运行 \`reboot --version\` 来检查当前版本，并根据具体版本的文档来确认可能的变化。

### 安全性及替代命令

\`reboot\` 命令本身是安全的，但强制重启可能导致数据丢失。可以考虑使用 \`shutdown\` 命令来执行更为安全的重启：

\`\`\`bash
sudo shutdown -r now
\`\`\`

- \`-r\` 选项表示重启。
- \`now\` 表示立即重启。

### 安装

\`reboot\` 命令通常是 Linux 系统的一部分，随操作系统一起安装。若需要安装或更新相关包，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install systemd
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install systemd
  \`\`\`

### 需要root权限的选项

\`reboot\` 命令需要 root 权限才能执行重启操作：

\`\`\`bash
sudo reboot
\`\`\`

**示例输出**：

\`\`\`bash
$ sudo reboot
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以执行系统重启操作。

        `,
    };
  },
});
</script>
<style scoped></style>
