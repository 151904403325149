<template>
  <el-container>
    <!-- Sidebar with toggle button -->
    <el-aside :width="isCollapsed ? '50px' : '200px'" class="sidebar">
      <!-- Collapse button -->
      <div class="collapse-button" @click="toggleSidebar">
        <i :class="isCollapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>

      <!-- Menu items -->
      <el-menu
        :default-active="activeLink"
        @select="handleSelect"
        class="el-menu-vertical-demo"
        background-color="#001f3f"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="DockerIntro">
          <span v-if="!isCollapsed">Docker简介</span>
        </el-menu-item>
        <el-menu-item index="DockerInstallation">
          <span v-if="!isCollapsed">环境安装</span>
        </el-menu-item>
        <el-menu-item index="DockerAfterInstallation">
          <span v-if="!isCollapsed">安装产物</span>
        </el-menu-item>
        <el-menu-item index="DockerSearchImages">
          <span v-if="!isCollapsed">DockerHub搜索镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerImagesPull">
          <span v-if="!isCollapsed">拉取/下载Docker镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerImageInspect">
          <span v-if="!isCollapsed">获取本地镜像信息</span>
        </el-menu-item>
        <el-menu-item index="DockerFirstRunUbuntu">
          <span v-if="!isCollapsed">体验Ubuntu镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerRunNginx">
          <span v-if="!isCollapsed">体验访问Nginx容器</span>
        </el-menu-item>
        <el-menu-item index="DockerBindStorageDir">
          <span v-if="!isCollapsed">容器内访问主机目录</span>
        </el-menu-item>
        <el-menu-item index="DockerStorage">
          <span v-if="!isCollapsed">数据持久化3种方式</span>
        </el-menu-item>
        <el-menu-item index="DockerContainerLifecycle">
          <span v-if="!isCollapsed">容器生命周期</span>
        </el-menu-item>
        <el-menu-item index="DockerLimitResource">
          <span v-if="!isCollapsed">限制容器资源</span>
        </el-menu-item>
        <el-menu-item index="DockerLoadImage">
          <span v-if="!isCollapsed">从tar文件导入镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerBasicImages">
          <span v-if="!isCollapsed">常见基础镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerContainerToImage">
          <span v-if="!isCollapsed">容器另存为镜像</span>
        </el-menu-item>
        <el-menu-item index="DockerImageToTar">
          <span v-if="!isCollapsed">镜像导出为Tar包</span>
        </el-menu-item>
        <el-menu-item index="DockerTag">
          <span v-if="!isCollapsed">为镜像打标签</span>
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- Content area -->
    <el-main class="content">
      <component :is="currentComponent" />
    </el-main>
  </el-container>
</template>

<script>
import DockerIntro from "./docker/DockerIntro.vue";
import DockerImagesPull from "./docker/DockerImagesPull.vue";
import DockerRunNginx from "./docker/DockerRunNginx.vue";
import DockerInstallation from "./docker/DockerInstallation.vue";
import DockerSearchImages from "./docker/DockerSearchImages.vue";
import DockerImageInspect from "./docker/DockerImageInspect.vue";
import DockerAfterInstallation from "./docker/DockerAfterInstallation.vue";
import DockerFirstRunUbuntu from "./docker/DockerFirstRunUbuntu.vue";
import DockerBindStorageDir from "./docker/DockerBindStorageDir.vue";
import DockerStorage from "./docker/DockerStorage.vue";
import DockerContainerLifecycle from "./docker/DockerContainerLifecycle.vue";
import DockerLimitResource from "./docker/DockerLimitResource.vue";
import DockerAttachAndDetach from "./docker/DockerAttachAndDetach.vue";
import DockerLoadImage from "./docker/DockerLoadImage.vue";
import DockerBasicImages from "./docker/DockerBasicImages.vue";
import DockerContainerToImage from "./docker/DockerContainerToImage.vue";
import DockerImageToTar from "./docker/DockerImageToTar.vue";
import DockerTag from "./docker/DockerTag.vue";

export default {
  data() {
    return {
      activeLink: "DockerIntro", // 默认选中 DockerIntro
      currentComponent: "DockerIntro", // 默认加载 DockerIntro 组件
      isCollapsed: false, // 控制侧边栏是否折叠
    };
  },
  components: {
    DockerIntro,
    DockerInstallation,
    DockerAfterInstallation,
    DockerSearchImages,
    DockerImageInspect,
    DockerImagesPull,
    DockerRunNginx,
    DockerFirstRunUbuntu,
    DockerBindStorageDir,
    DockerStorage,
    DockerContainerLifecycle,
    DockerLimitResource,
    DockerAttachAndDetach,
    DockerLoadImage,
    DockerBasicImages,
    DockerContainerToImage,
    DockerImageToTar,
    DockerTag,
  },
  methods: {
    handleSelect(key) {
      this.currentComponent = key; // 根据选中的 index 动态设置 currentComponent
    },
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed; // 切换侧边栏的展开和折叠状态
    },
  },
};
</script>

<style scoped>
.sidebar {
  background-color: #001f3f;
  color: white;
  transition: width 0.3s ease;
  position: relative;
}

.collapse-button {
  position: absolute;
  top: 10px;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: #001f3f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
}

.content {
  background-color: #f5f7fa;
  padding: 20px;
}

.el-menu-vertical-demo {
  min-height: 100vh;
  border-right: none;
  font-size: 16px;
}

.el-menu-item {
  font-size: 16px;
}

.el-menu-item:hover {
  background-color: #007bff;
}
</style>
