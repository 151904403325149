<template>
  <div class="diy-css">
    <TaskBoxLeftLineB
      task="公司入职了一个外包同学，工号为wb20280050, 请为其创建测试环境的账号，bash作为默认shell，并加入附属组dev_group, test_group, 密码设置为Wb0050$a.c"
      source="该测试环境未接入自动化中心，请手动完成。【自动工单auto-ticket-1009】"
    />
    <div style="display: flex; flex-direction: row">
      <RateBox :difficulty="3" :popularity="5" />
      <GameScoreDisplay score="30" />
      <SalaryMedal salaryType="3" />
      <TaskOSDisplay :taskOS="taskOS" />
    </div>
    <div>
      <KnowledgeCard :content="markdownContent" />
      <KnowledgeCardExpand :content="markdownContentExpand" />
      <KnowledgeCardInterview :content="markdownInterview" />
    </div>
    <div class="ops-bar">
      <OperationBar :resId="resId" @connectstr-update="updateConnectStr" />
    </div>
    <BigTerms :connectStr="connectStrFromOpsBar" />
    <p id="res-id">当前页面的资源ID为：{{ resId }}</p>
    <SuperLink :resId="resId" style="margin-bottom: 12px"
      >视频详解链接: bilibili</SuperLink
    >
  </div>
</template>

<script>
import BigTerms from "../../base/BigTerms.vue";
import GameScoreDisplay from "../../base/GameScore.vue";
import KnowledgeCardExpand from "../../base/KnowledgeCardExpand.vue";
import KnowledgeCard from "../../base/KnowledgeCard.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";
import OperationBar from "../../base/OperationBar.vue";
import RateBox from "../../base/RateBox.vue";
import SalaryMedal from "../../base/SalaryMedal.vue";
import SuperLink from "../../base/SuperLink.vue";
import TaskBoxLeftLineB from "../../base/TaskBoxLeftLineB.vue";
import TaskOSDisplay from "../../base/TaskOSDisplay.vue";

export default {
  name: "CmdMd5sum",
  components: {
    BigTerms,
    GameScoreDisplay,
    KnowledgeCard,
    KnowledgeCardExpand,
    KnowledgeCardInterview,
    OperationBar,
    RateBox,
    SalaryMedal,
    SuperLink,
    TaskBoxLeftLineB,
    TaskOSDisplay,
  },
  methods: {
    updateConnectStr(valueStr) {
      console.log("Received connectStr from child:", valueStr);
      this.connectStrFromOpsBar = valueStr;
    },
  },
  data() {
    return {
      resId: "10064",
      taskOS: "Ubuntu",
      connectStrFromOpsBar: "",
      bilibiliLink: "https://www.bilibili.com/",
      markdownContent: `
\`useradd\` 是 Linux 系统中用于创建新用户账户的命令。它有许多重要参数，可以根据需求自定义用户的设置。  
以下是 \`useradd\` 命令的重要参数及其用法说明：

## 重要参数及用法

1. **-u, --uid UID**
   - 指定用户的唯一 ID (UID)。如果不指定，系统会自动分配一个下一个可用的 UID。
   - 示例：
     \`\`\`bash
     useradd -u 1001 newuser
     \`\`\`

2. **-g, --gid GROUP**
   - 指定新用户的初始组。可以使用组名或组 ID (GID)。如果指定的组不存在，命令会返回错误。
   - 示例：
     \`\`\`bash
     useradd -g users newuser
     \`\`\`

3. **-G, --groups GROUPS**
   - 指定用户所属的附加组，多个组名用逗号分隔。
   - 示例：
     \`\`\`bash
     useradd -G wheel,developers newuser
     \`\`\`

4. **-d, --home HOME_DIR**
   - 指定用户的主目录。如果未指定，系统会使用默认的主目录路径（通常是 \`/home/username\`）。
   - 示例：
     \`\`\`bash
     useradd -d /home/newuser newuser
     \`\`\`

5. **-m, --create-home**
   - 创建用户的主目录，只有在指定了主目录的情况下，才会自动创建。如果不指定，主目录不会被创建。
   - 示例：
     \`\`\`bash
     useradd -m newuser
     \`\`\`

6. **-s, --shell SHELL**
   - 指定用户的登录 shell。默认为 \`/bin/sh\`。
   - 示例：
     \`\`\`bash
     useradd -s /bin/bash newuser
     \`\`\`

7. **-e, --expiredate EXPIRE_DATE**
   - 设置用户账户的过期日期，格式为 YYYY-MM-DD。过期后用户将无法登录。
   - 示例：
     \`\`\`bash
     useradd -e 2025-12-31 newuser
     \`\`\`

8. **-f, --inactive INACTIVE**
   - 设置账户在密码过期后，用户被锁定前的非活动天数。设置为 0 表示立即锁定。
   - 示例：
     \`\`\`bash
     useradd -f 30 newuser
     \`\`\`

9. **-p, --password PASSWORD**
   - 设置用户的密码，通常需要使用 \`passwd\` 命令进行加密设置。
   - 示例：
     \`\`\`bash
     useradd -p $(openssl passwd -1 password) newuser
     \`\`\`

10. **-c, --comment COMMENT**
    - 添加用户的描述信息（通常是全名）。
    - 示例：
      \`\`\`bash
      useradd -c "New User" newuser
      \`\`\`

## 示例命令

下面是一个创建用户的示例命令，综合使用多个参数：

\`\`\`bash
useradd -m -u 1001 -g users -G wheel -s /bin/bash -c "New User" newuser
\`\`\`

## 注意事项

- 创建用户后，通常需要使用 \`passwd\` 命令设置用户密码：
  \`\`\`bash
  passwd newuser
  \`\`\`

- 需要具有 root 权限才能执行 \`useradd\` 命令。

- 请根据实际需求选择合适的参数，以确保用户设置符合安全和管理要求。

通过这些参数，您可以灵活地创建和管理用户账户。

在 Linux 和 Unix 系统中，用户可以同时属于一个**主组**和多个**附加组**，这是为了灵活地管理文件和资源的访问权限。主组和附加组的区别在于它们在文件权限和用户组织上的作用。下面详细说明：

### 主组（Primary Group）

- **定义**：主组是每个用户默认归属的组，用户创建文件时，文件的所属组（group ownership）默认就是用户的主组。
- **作用**：
  - 当用户创建文件时，这些文件默认会归属于用户的主组。
  - 主组在 \`/etc/passwd\` 文件中与用户一一对应，每个用户必须有一个主组。
- **示例**：
  - 用户 \`sre\` 的主组为 \`sre-group\`，当 \`sre\` 创建文件时，文件的所属组就是 \`sre-group\`。
  
  \`\`\`bash
  $ touch testfile
  $ ls -l testfile
  -rw-r--r-- 1 sre sre-group 0 Oct  1 10:30 testfile
  \`\`\`

在这个例子中，文件 \`testfile\` 的所属组为 \`sre-group\`，这是用户 \`sre\` 的主组。

### 附加组（Secondary/Additional Groups）

- **定义**：附加组是用户除了主组外，还可以加入的其他组。这些组通常用于控制用户对特定资源的访问权限。
- **作用**：
  - 用户可以通过附加组获得其他组的访问权限（如读、写、执行权限），而无需将该组设置为主组。
  - 附加组的存在使得系统能灵活地授予不同用户对相同资源的访问权限。
- **示例**：
  - 用户 \`sre\` 的主组是 \`sre-group\`，但通过 \`-G\` 参数将其添加到 \`developers\` 附加组，这样用户 \`sre\` 就能访问 \`developers\` 组的文件和资源。

  \`\`\`bash
  useradd -G developers sre
  \`\`\`

在这种情况下，\`sre\` 除了主组 \`sre-group\` 外，还能访问属于 \`developers\` 组的文件。

### 为什么需要主组和附加组？

1. **文件权限管理**：
   - Linux 中每个文件或目录都有三个所有者类别：文件的拥有者（user）、所属组（group）和其他用户（others）。主组决定了用户创建的文件默认属于哪个组，而附加组则决定了用户能访问哪些其他组的文件。
   - 比如，在团队协作时，不同的用户可能属于不同的组，附加组可以让一个用户同时参与多个团队，并拥有访问多个组资源的权限。

2. **灵活的权限分配**：
   - 系统管理员可以通过附加组的机制给用户分配更精细的权限。例如，一个用户可能是开发组的成员（附加组 \`developers\`），也可能需要临时获取系统管理权限（附加组 \`wheel\`）。

3. **安全性**：
   - 主组和附加组的组合能够限制用户对不同文件的访问权限，确保某些敏感文件只能由特定组的用户访问。这为系统提供了层次化的权限控制。

### 总结

- **主组**：每个用户的默认组，用户创建文件时文件默认属于该组。
- **附加组**：用户可以被添加到其他多个组，从而获得访问这些组资源的权限。

这种机制允许管理员灵活管理系统中的用户权限，确保用户可以访问需要的资源，同时保护系统的安全性。

### 答案示例
useradd -m -d /home/wb20280050 -s /bin/bash -G dev_group,test_group wb20280050

密码设置示例:  
echo 'wb20280050:Wb0050$a.c' | chpasswd  
注意使用给单引号
双引号会失效，您可以进行尝试。本环节不检查密码。
      `,
      markdownContentExpand: `
在大多数 Linux 系统中，**主组和用户名通常会保持一致**，这是系统在创建用户时的默认行为。  
这个做法在 Linux 中称为 **用户私有组（User Private Group）模型**。  
该模型的核心思想是为每个用户创建一个同名的主组，并且该组只包含该用户自己。这种结构简化了权限管理，尤其是在多人协作环境中。

### 具体行为

- **用户创建**：当使用 \`useradd\` 命令创建新用户时，如果没有指定主组（即没有使用 \`-g\` 参数），系统会自动创建一个与用户名相同的主组，且用户自己是该组的唯一成员。
  
  例如，创建名为 \`sre\` 的用户时，系统会自动创建一个名为 \`sre\` 的组，且该用户的主组就是 \`sre\`。

  \`\`\`bash
  sudo useradd sre
  \`\`\`

  在这种情况下，\`/etc/passwd\` 和 \`/etc/group\` 文件会显示如下内容：
  
  \`/etc/passwd\`：
  \`\`\`bash
  sre:x:1001:1001::/home/sre:/bin/bash
  \`\`\`
  
  \`/etc/group\`：
  \`\`\`bash
  sre:x:1001:
  \`\`\`

- **默认设置的好处**：
  - **简化管理**：每个用户都有自己的私有组，这样即使该用户创建的文件默认只对自己可用，不会影响其他用户。
  - **文件共享**：如果用户需要与其他组协作，可以通过加入附加组（\`-G\` 参数）来控制访问权限，主组不会影响其他用户的权限。
  - **避免权限冲突**：不同用户创建的文件拥有独立的组，减少了多个用户共享主组时可能出现的文件权限问题。

## \`-g\` 和 \`-G\` 是 \`useradd\` 命令中用于指定用户组的两个参数，它们之间有明显的区别：

### \`-g\` 参数

- **含义**：指定新用户的**主组**（initial group）。
- **用途**：用户创建时，\`-g\` 参数指定的组将被视为该用户的默认组。每个用户必须至少属于一个组，而主组是用户在系统中主要归属的组。
- **示例**：
  \`\`\`bash
  useradd -g users newuser
  \`\`\`
  在这个示例中，\`newuser\` 的主组被设置为 \`users\`。

### \`-G\` 参数

- **含义**：指定用户的**附加组**（additional groups）。
- **用途**：允许用户除了主组外，还可以属于其他组。用户可以通过附加组获得访问特定资源的权限。
- **多个组**：可以用逗号分隔多个组名，而不需要空格。
- **示例**：
  \`\`\`bash
  useradd -G wheel,developers newuser
  \`\`\`
  在这个示例中，\`newuser\` 除了主组外，还被添加到 \`wheel\` 和 \`developers\` 两个附加组。

### 总结

- \`-g\` 仅用于设置用户的主组，而 \`-G\` 用于设置用户的附加组。
- 主组是用户的默认组，附加组是用户可以额外加入的组。

## chpasswd命令

\`chpasswd\` 命令用于批量更新或设置用户密码，通常通过从标准输入读取用户和密码的组合，以批量的方式进行更改。典型的输入格式是 \`username:password\`，每行一对。

### \`chpasswd\` 的基本用法

#### 格式：
\`\`\`bash
echo "username:password" | sudo chpasswd
\`\`\`

在双引号内，特殊字符和变量会被解析，常见的可解析字符包括 $、\\ 等。对于包含特殊符号的密码请注意。
在单引号内，所有字符都被视为普通字符，不会被解析或替换。

#### 示例：设置单个用户密码

例如，为用户 \`sre\` 设置密码为 \`StrongPassword123\`：

\`\`\`bash
echo "sre:StrongPassword123" | sudo chpasswd
\`\`\`

此命令会将用户 \`sre\` 的密码更改为 \`StrongPassword123\`。
      `,
      markdownInterview: ` 
## 选择题

1. **\`useradd\` 命令的默认主组是什么？**
   - A. users
   - B. wheel
   - C. 与用户名相同
   - D. root

2. **使用 \`useradd\` 创建用户时，如果不指定 \`-g\` 选项，用户将属于哪个组？**
   - A. wheel
   - B. root
   - C. users
   - D. 默认主组（通常与用户名相同）

3. **以下哪个选项用于创建用户时指定用户的家目录？**
   - A. -m
   - B. -d
   - C. -h
   - D. -c

4. **\`useradd\` 命令创建用户时，默认的用户ID (UID) 从哪里开始？**
   - A. 0
   - B. 1000
   - C. 500
   - D. 2000

5. **如果想要给用户添加一个额外的组，可以使用哪个选项？**
   - A. -g
   - B. -G
   - C. -a
   - D. -u

## 问答题

1. **解释 \`useradd\` 命令的基本用途。**

2. **当使用 \`useradd\` 命令时，如何确保创建用户的同时自动创建用户的家目录？**

3. **如果想要创建一个用户并限制该用户的 shell 类型为 \`/bin/false\`，该如何操作？**

4. **在 Linux 系统中，如何查看当前系统中所有用户的列表？**

5. **\`useradd\` 和 \`adduser\` 命令有什么区别？**

---

## 答案

### 选择题答案：
1. C. 与用户名相同
2. D. 默认主组（通常与用户名相同）
3. B. -d
4. B. 1000
5. B. -G

### 问答题答案：
1. **解释 \`useradd\` 命令的基本用途。**  
   \`useradd\` 命令用于在 Linux 系统中创建新用户，并设置相关属性（如主组、家目录、shell 类型等）。

2. **当使用 \`useradd\` 命令时，如何确保创建用户的同时自动创建用户的家目录？**  
   使用 \`-m\` 选项，例如：\`sudo useradd -m username\`。

3. **如果想要创建一个用户并限制该用户的 shell 类型为 \`/bin/false\`，该如何操作？**  
   使用 \`-s\` 选项，例如：\`sudo useradd -s /bin/false username\`。

4. **在 Linux 系统中，如何查看当前系统中所有用户的列表？**  
   可以查看 \`/etc/passwd\` 文件，使用命令 \`cat /etc/passwd\` 或者 \`cut -d: -f1 /etc/passwd\` 只列出用户名。

5. **\`useradd\` 和 \`adduser\` 命令有什么区别？**  
   \`useradd\` 是一个低级命令，通常需要更多参数；而 \`adduser\` 是一个高层次的命令，通常更易于使用，并会自动创建家目录和配置用户属性。

      `,
    };
  },
};
</script>
<style>
.diy-css {
  width: 100%;
}
#res-id {
  margin-top: 1rem;
  font-size: 14px;
}
</style>
