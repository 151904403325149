<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## cat 命令详解

\`cat\` 命令是Linux系统中一个非常常用的命令，它用于连接文件并在标准输出上显示其内容。\`cat\` 是 "concatenate" 的缩写，表示将文件内容串联起来并输出。\`cat\` 命令的应用非常广泛，不仅可以查看文件内容，还可以合并多个文件的内容，甚至创建新文件。下面将详细介绍\`cat\`命令的一些重要参数及其用法。

### 基本用法

最简单的用法是直接在命令后面跟上文件名，这样\`cat\`命令就会将文件内容输出到终端。

\`\`\`bash
$ cat example.txt
\`\`\`

**输出：**

\`\`\`
This is an example file.
It contains some text for demonstration.
\`\`\`

**解读：**
上述命令读取了名为\`example.txt\`的文件，并将其内容直接显示在终端上。这个命令的作用类似于文本查看器，但不同的是\`cat\`不会进入交互模式，所有内容一次性显示。

### 合并文件

\`cat\` 命令的另一个常见用途是合并多个文件，将多个文件的内容串联在一起输出。

\`\`\`bash
$ cat file1.txt file2.txt > combined.txt
\`\`\`

**输出：**

【无输出，命令已成功执行】

**解读：**
在上述命令中，\`cat\` 将\`file1.txt\`和\`file2.txt\`的内容合并并重定向到\`combined.txt\`文件中。如果文件\`combined.txt\`已存在，其内容将被覆盖。

### 显示行号

使用\`-n\`参数，\`cat\`可以显示每一行的行号。这对于查看代码或大型文本文件时非常有用。

\`\`\`bash
$ cat -n example.txt
\`\`\`

**输出：**

\`\`\`
     1  This is an example file.
     2  It contains some text for demonstration.
\`\`\`

**解读：**
每一行的行号会被显示在内容的左侧，方便用户定位文件中的具体行。

### 显示不可打印字符

使用\`-v\`参数可以显示文件中的不可打印字符，例如制表符、回车符等。

\`\`\`bash
$ cat -v example.txt
\`\`\`

**输出：**

\`\`\`
This is an example file.^M
It contains some text for demonstration.^M
\`\`\`

**解读：**
\`^M\`表示回车符，\`^I\`表示制表符。这个功能在调试文件格式问题时非常有用。

### 创建新文件

虽然通常使用\`touch\`命令来创建空文件，但\`cat\`也可以通过重定向来创建新文件或向已有文件中添加内容。

\`\`\`bash
$ cat > newfile.txt
This is a new file created with cat.
\`\`\`

**输出：**

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读：**
在这里，用户输入的内容被\`cat\`命令捕获，并写入\`newfile.txt\`文件中。使用\`Ctrl+D\`结束输入。

### 追加内容到文件

使用\`>>\`符号，可以将输出追加到文件末尾，而不是覆盖文件内容。

\`\`\`bash
$ cat >> newfile.txt
This is an additional line.
\`\`\`

**输出：**

\`\`\`bash
【无输出，命令已成功执行】
\`\`\`

**解读：**
此命令将新的一行内容添加到\`newfile.txt\`文件的末尾，而不会覆盖文件中已有的内容。

### 总结

\`cat\`命令在Linux系统中非常实用，无论是查看文件、合并文件、创建新文件还是显示文件中的特殊字符，\`cat\`都能胜任。通过掌握\`cat\`的各类参数，用户可以更加高效地管理和处理文本文件。
        `,
    };
  },
});
</script>
<style scoped></style>
