<template>
  <div class="image-container" :class="{ 'dark-background': isDark }">
    <el-image
      v-if="imageData.img_link"
      :src="imageData.img_link"
      alt="Image"
      fit="cover"
      style="max-width: 660px; margin-top: 1rem"
      @click="toggleDarkMode"
    ></el-image>
    <el-skeleton v-else animated :loading="loading" style="max-width: 620px">
      <template #template>
        <el-skeleton-item
          variant="image"
          style="max-width: 620px"
        ></el-skeleton-item>
      </template>
    </el-skeleton>

    <!-- <el-divider></el-divider> -->

    <el-skeleton :loading="loading">
      <template #template>
        <el-skeleton-item variant="h3"></el-skeleton-item>
      </template>
      <h3>{{ imageData.title }}</h3>
    </el-skeleton>

    <!-- <el-divider></el-divider> -->

    <el-skeleton :loading="loading">
      <template #template>
        <el-skeleton-item variant="p"></el-skeleton-item>
      </template>
      <p>{{ imageData.desc }}</p>
    </el-skeleton>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

const imageData = ref({
  id: null,
  title: "",
  desc: "",
  img_link: "",
});

const loading = ref(true);
const isDark = ref(false);

const toggleDarkMode = () => {
  isDark.value = !isDark.value;
};

const fetchData = async () => {
  try {
    const response = await axios.get(
      "http://sre.azlinux.com:5080/get_ops_security_img"
    );
    imageData.value = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    if (!error.response) {
      ElMessage({
        message: "网络连接失败，稍后重试，或检查网络",
        type: "error",
        duration: 5000,
        offset: 120,
      });
    } else if (error.response.status === 404) {
      ElMessage({
        message: "资源未找到",
        type: "error",
        duration: 5000,
        offset: 120,
      });
    } else {
      // 处理其他类型的错误
      ElMessage({
        message: "请求错误: " + error.response.status,
        type: "error",
        duration: 5000,
        offset: 120,
      });
    }
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.image-container {
  /* margin: 20px; */
  text-align: center;
  transition: background-color 0.3s;
}

.image-container.dark-background {
  background-color: rgba(200, 220, 200, 0.2);
}

.el-image {
  display: inline-block;
  padding: 10px;
  background-color: #f8f8f8;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* 添加鼠标指针样式以指示可点击 */
}
p {
  padding-bottom: 1rem;
}
</style>
