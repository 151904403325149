<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## smartctl 命令详解

\`smartctl\` 是一个用于监控和管理硬盘驱动器的命令行工具，它是 \`smartmontools\` 软件包的一部分。通过 S.M.A.R.T.（Self-Monitoring, Analysis, and Reporting Technology）技术，\`smartctl\` 可以获取硬盘的健康状态和性能数据，有助于预防数据丢失。

### 安装

在使用 \`smartctl\` 之前，需要确保安装了 \`smartmontools\` 包。在不同的操作系统中，安装命令如下：

#### Ubuntu

\`\`\`bash
sudo apt install smartmontools
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install smartmontools
\`\`\`

### 基本语法

\`\`\`bash
smartctl [选项] [设备]
\`\`\`

- **选项**：用于指定所需的操作。
- **设备**：指定要监控的硬盘设备（如 \`/dev/sda\`）。

### 常用选项

- \`-a\`：显示所有可用的 S.M.A.R.T. 信息。
- \`-t\`：运行自我测试。
- \`-H\`：显示健康状态。
- \`-i\`：显示设备的基本信息。
- \`-s\`：设置 S.M.A.R.T. 功能（启用或禁用）。

### 示例用法

#### 示例一：查看硬盘的 S.M.A.R.T. 信息

可以使用以下命令查看硬盘的所有 S.M.A.R.T. 信息：

\`\`\`bash
sudo smartctl -a /dev/sda
\`\`\`

**输出：**

\`\`\`plaintext
smartctl 7.2 2020-12-30 r5155 [x86_64-linux-5.4.0-42-generic] (local build)
=== START OF INFORMATION SECTION ===
Model Number:                       ST1000DM003-1CH162
Serial Number:                      Z8A0E0XY
Firmware Version:                   CC49
User Capacity:                      1,000,204,886,016 bytes [1.00 TB]
Sector Size:                        512 bytes logical/physical
Rotation Rate:                      7200 rpm
SMART support is:                  Available - device has SMART capability.
SMART support is:                  Enabled

=== START OF SMART DATA SECTION ===
SMART overall-health self-assessment test result: PASSED

SMART Attributes Data Structure revision number: 1
Vendor Specific SMART Attributes with Thresholds:
ID## ATTRIBUTE_NAME          FLAG     VALUE WORST THRESH TYPE      UPDATED  WHEN_FAILED RAW_VALUE
  1 Raw_Read_Error_Rate     0x000f   100   100   006    Pre-fail  Always       -       0
  3 Spin_Up_Time            0x0003   095   094   000    Pre-fail  Always       -       1030
  5 Reallocated_Sector_Ct   0x0033   095   095   036    Pre-fail  Always       -       5
  7 Seek_Error_Rate         0x000f   100   100   067    Pre-fail  Always       -       0
  9 Power_On_Hours          0x0012   099   099   000    Old_age   Always       -       1504
 12 Power_Cycle_Count       0x0012   099   099   000    Old_age   Always       -       18
\`\`\`

**解读**：输出中包含了硬盘的基本信息和 S.M.A.R.T. 数据。特别重要的参数有：

- **Reallocated_Sector_Ct**：表示已重新分配的扇区数量。此值不应过高，若达到阈值，则可能意味着硬盘即将故障。
- **SMART overall-health self-assessment test result**：表示自我评估测试的结果。如果显示为 \`FAILED\`，则表明硬盘存在问题。

#### 示例二：运行自我测试

可以使用以下命令运行自我测试：

\`\`\`bash
sudo smartctl -t long /dev/sda
\`\`\`

**输出**：

\`\`\`plaintext
smartctl 7.2 2020-12-30 r5155 [x86_64-linux-5.4.0-42-generic] (local build)
=== START OF SELF-TEST HISTORY ===
Id##     Test              Status         segment  LifeLeft  Error  ...  Time
1       Short offline     Completed: pass       00%      99         -   00:01:27
2       Extended offline  Completed: fail       00%      20    0x01   00:15:32
\`\`\`

**解读**：这里的输出显示了自我测试的历史记录。在此示例中，第二个测试显示为 \`Completed: fail\`，这表明硬盘在该测试中失败，可能存在潜在故障。

#### 硬盘故障示例输出

当硬盘确实存在问题时，使用 \`smartctl -a\` 命令查看输出可能如下：

\`\`\`plaintext
smartctl 7.2 2020-12-30 r5155 [x86_64-linux-5.4.0-42-generic] (local build)
=== START OF INFORMATION SECTION ===
Model Number:                       ST1000DM003-1CH162
SMART overall-health self-assessment test result: FAILED!
\`\`\`

**解读**：在此情况下，输出的健康状态为 \`FAILED!\`，表示硬盘可能存在严重问题。

### 版本差异

\`sar\` 命令的不同版本可能会有不同的输出格式和可用选项。用户可以通过以下命令查看当前版本：

\`\`\`bash
smartctl --version
\`\`\`

**输出示例：**

\`\`\`plaintext
smartctl 7.2 2020-12-30 r5155
\`\`\`

不同版本的 \`smartmontools\` 可能会增加新的功能或修复已知问题。

### 权限要求

由于 \`smartctl\` 涉及硬盘的低级别操作，通常需要以 root 用户身份运行。可以使用以下命令：

\`\`\`bash
sudo smartctl -a /dev/sda
\`\`\`

#### 示例输出

\`\`\`plaintext
smartctl 7.2 2020-12-30 r5155 [x86_64-linux-5.4.0-42-generic] (local build)
=== START OF INFORMATION SECTION ===
Model Number:                       ST1000DM003-1CH162
\`\`\`

### 总结

\`smartctl\` 命令是硬盘监控的重要工具，可以提供关于硬盘健康状况的详细信息。通过定期检查 S.M.A.R.T. 数据，用户能够及时发现硬盘潜在问题，防止数据丢失。合理使用 \`smartctl\`，可大大提升系统的稳定性与可靠性。

        `,
    };
  },
});
</script>
<style scoped></style>
