<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## write 命令详解

\`write\` 命令是Linux和类Unix系统中用于与其他登录用户直接通信的工具。它允许一位用户向另一位正在登录系统的用户发送文本消息。消息将通过终端显示，常用于即时通知或简短的交流。\`write\`命令通过向目标用户的终端设备写入信息实现消息传递。

### 基本语法

\`\`\`bash
write 用户名 [终端号]
\`\`\`

- **用户名**：指定接收消息的用户。
- **终端号**：可选。如果目标用户有多个终端会话（如\`pts/0\`和\`pts/1\`），你可以指定发送到具体的终端号。

### 基本使用

在没有指定终端号时，\`write\`命令将自动选择目标用户的一个活动终端，消息内容将直接在对方的终端上显示。

#### 示例1：给用户\`sre\`发送消息

\`\`\`bash
write sre
\`\`\`

接下来，用户可以输入想要发送的消息。消息将立即发送给用户\`sre\`，并显示在对方的终端上。输入完消息后，按 \`Ctrl+D\` 来结束输入并发送。

**输出示例：**
\`\`\`
Hello sre, 需要在下午3点开会，请准时参加。
\`\`\`

在\`sre\`的终端上会看到：
\`\`\`
Message from sre@azlinux-prod-cn on pts/0 at 15:12 ...
Hello sre, 需要在下午3点开会，请准时参加。
EOF
\`\`\`

#### 示例2：指定终端发送消息

如果目标用户有多个会话，可以通过指定终端号来控制消息的发送。首先，可以通过\`who\`命令查看用户的终端信息：

\`\`\`bash
who
\`\`\`

**输出示例：**
\`\`\`bash
sre      pts/0        2024-09-11 14:23 (192.168.1.100)
sre      pts/1        2024-09-11 15:01 (192.168.1.102)
\`\`\`

接下来，指定发送到\`sre\`的\`pts/1\`终端：

\`\`\`bash
write sre pts/1
\`\`\`

**发送消息示例：**
\`\`\`
Hi,外包同志们,请在会议结束后提交日报,300字以上。
\`\`\`

**\`sre\`用户在\`pts/1\`终端看到的消息：**
\`\`\`
Message from sre@azlinux-prod-cn on pts/0 at 15:15 ...
Hi,外包同志们,请在会议结束后提交日报,300字以上。
EOF
\`\`\`

### 重要选项

\`write\`命令没有很多复杂的选项，但它的使用需要确保用户的终端是开放的，且目标用户的终端允许接收消息。

#### 禁止消息接收

用户可以通过\`mesg\`命令来允许或禁止从其他用户接收消息。默认情况下，终端是允许接收消息的，但可以通过以下命令来禁止：

\`\`\`bash
mesg n
\`\`\`

这将阻止其他用户通过\`write\`命令向你发送消息。要重新允许消息接收，可以执行：

\`\`\`bash
mesg y
\`\`\`

### 版本差异

\`write\`命令在不同版本的系统中表现基本一致。在现代Linux系统中，它是\`util-linux\`包的一部分，没有显著的功能变化。

### 安装信息

如果系统中没有预装\`write\`命令，您可以通过以下方式安装：

#### Ubuntu/Debian

\`\`\`bash
sudo apt install util-linux
\`\`\`

#### RHEL/CentOS

\`\`\`bash
sudo yum install util-linux
\`\`\`

### 示例输出解读

#### 情境1：发送消息后无输出

在发送消息后，发送方的终端不会显示任何反馈输出，除非出错。即使发送成功，终端也是保持静默状态。

\`\`\`bash
write sre
Hello, 下午3点有会。
Ctrl+D
\`\`\`

**解释：**【无输出，命令已成功执行】

#### 情境2：目标用户终端被禁止接收消息

如果目标用户已禁用消息接收，你将收到类似如下的错误提示：

\`\`\`bash
write dev01
write: dev01 has messages disabled on pts/0
\`\`\`

**解释：**\`dev01\`用户的终端设置为拒绝接收消息，可能已通过\`mesg n\`来禁用消息接收。

### 不同架构下的表现

在\`aarch64\`和\`x86_64/amd64\`架构下，\`write\`命令的行为没有差异。它的核心功能在不同架构的Linux系统中保持一致。

### 结论

\`write\`命令提供了一种简单、直接的用户间通讯方式，适合在不使用即时通讯软件的情况下进行系统内的用户消息传递。作为管理员，了解如何有效使用\`write\`命令能够在多用户环境中更好地传达重要通知。如果目标用户终端关闭了消息接收功能，可以通过\`mesg\`命令进行控制。

        `,
    };
  },
});
</script>
<style scoped></style>
