<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## w 命令详解

\`w\` 命令用于显示当前登录系统的用户以及他们正在执行的进程。它提供的信息包括用户登录的时间、会话时间、用户的当前工作目录、所使用的终端、正在运行的命令等。这个命令对于系统管理员来说非常有用，可以帮助他们监控系统的活动。

### 基本用法

\`\`\`
w [选项] [用户名]
\`\`\`

如果没有指定用户名，\`w\` 会显示所有当前登录的用户的信息。

### 常用选项

- \`-h\`：不显示头部信息。
- \`-s\`：简洁模式，显示的字段更少。

### 示例与解释

以下是一些使用 \`w\` 命令的示例，模拟用户 \`sre\`，uid 为 1005，组为 \`sre-group\`，主机名为 \`azlinux-prod-cn\`。

#### 示例 1：查看所有登录用户的信息

\`\`\`bash
$ w
  11:15:01 up 10 days,  5:21,  2 users,  load average: 0.20, 0.10, 0.05
USER     TTY        FROM              LOGIN@   IDLE   JCPU   PCPU WHAT
sre      pts/0      192.168.1.10       10:10    2:15   0.01s  0.01s -bash
dev01    pts/1      192.168.1.11       10:15    1:05   0.10s  0.10s vim /etc/hosts
\`\`\`

**输出解读**：
- \`11:15:01\`：当前时间。
- \`up 10 days,  5:21\`：系统已经运行了 10 天 5 小时 21 分钟。
- \`2 users\`：当前有 2 个用户登录。
- \`load average\`：系统负载平均值。
- \`USER\`：用户的登录名。
- \`TTY\`：终端。
- \`FROM\`：用户的来源 IP。
- \`LOGIN@\`：登录时间。
- \`IDLE\`：闲置时间。
- \`JCPU\`：当前终端的 CPU 时间。
- \`PCPU\`：当前进程的 CPU 时间。
- \`WHAT\`：当前正在执行的命令。

#### 示例 2：只显示用户 \`sre\` 的信息

\`\`\`bash
$ w sre
  11:15:01 up 10 days,  5:21,  1 user,  load average: 0.20, 0.10, 0.05
USER     TTY        FROM              LOGIN@   IDLE   JCPU   PCPU WHAT
sre      pts/0      192.168.1.10       10:10    2:15   0.01s  0.01s -bash
\`\`\`

**输出解读**：
- 输出内容和示例 1 类似，但只显示了用户 \`sre\` 的信息。

#### 示例 3：不显示头部信息

\`\`\`bash
$ w -h
sre      pts/0      192.168.1.10       10:10    2:15   0.01s  0.01s -bash
dev01    pts/1      192.168.1.11       10:15    1:05   0.10s  0.10s vim /etc/hosts
\`\`\`

**输出解读**：
- 不显示表头部分，结果更加简洁。

#### 示例 4：简洁模式

\`\`\`bash
$ w -s
 11:15:01 up 10 days,  5:21,  2 users,  load average: 0.20, 0.10, 0.05
USER     TTY        FROM              LOGIN@   IDLE   WHAT
sre      pts/0      192.168.1.10       10:10    2:15   -bash
dev01    pts/1      192.168.1.11       10:15    1:05   vim /etc/hosts
\`\`\`

**输出解读**：
- 使用简洁模式，输出的字段更少，\`JCPU\` 和 \`PCPU\` 字段被省略。

### 不同版本的输出差异

\`w\` 命令的输出在不同版本中可能会有所不同，但主要字段通常保持一致。不同版本可能会增加或减少某些显示的细节。

### 安全性与替代命令

\`w\` 命令本身没有安全隐患，但在某些高安全要求的环境中，可能会使用更专门的监控工具来代替，比如 \`top\` 或 \`htop\`，这些工具提供了更详细的进程监控信息。

### 安装

\`w\` 命令通常包含在 \`procps\` 包中，在大多数 Linux 发行版中默认安装。如果未安装，可以使用以下命令进行安装：

- **Ubuntu**:
  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL**:
  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

### 总结

\`w\` 命令是一个强大的工具，用于显示系统中所有登录用户的详细信息。通过不同的选项，可以根据需要调整显示的信息。了解和掌握 \`w\` 命令对于系统管理和故障排除非常重要。

        `,
    };
  },
});
</script>
<style scoped></style>
