<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## taskset 命令详解

\`taskset\` 命令用于设置或检索进程的 CPU 亲和性（CPU affinity）。CPU 亲和性定义了进程可以在哪些 CPU 上运行，这对于性能优化和系统资源管理很重要。通过限制进程在特定的 CPU 核心上运行，可以减少缓存未命中、提高处理器利用率等。

### 基本用法

\`taskset\` 命令的基本语法如下：

\`\`\`bash
taskset [OPTION]... MASK [PID | COMMAND [ARGUMENTS...]]
\`\`\`

- \`MASK\` 是一个十六进制位掩码，用于指定进程可以运行的 CPU 核心。
- \`PID\` 是进程 ID，用于设置现有进程的 CPU 亲和性。
- \`COMMAND [ARGUMENTS...]\` 用于启动新进程并设置其 CPU 亲和性。

### 常用选项

- \`-p, --pid\`: 仅对指定的进程 ID 设置 CPU 亲和性。
- \`-c, --cpu-list\`: 使用 CPU 列表指定亲和性，而不是位掩码。

### 示例

以下示例展示了如何使用 \`taskset\` 命令设置和查看进程的 CPU 亲和性。

#### 示例 1: 设置新进程的 CPU 亲和性

假设我们想启动一个新的进程，并将其绑定到 CPU 0 和 CPU 1 上。使用 \`taskset\` 可以实现这一点。

\`\`\`bash
taskset 0x3 <command> [args...]
\`\`\`

这里，\`0x3\` 是一个十六进制掩码，表示 CPU 0 和 CPU 1。假设我们要启动 \`stress\` 命令：

\`\`\`bash
taskset 0x3 stress --cpu 4
\`\`\`

**输出：**

\`\`\`bash
[2024-09-10 12:34:56] stress: stress: info: [12345] dispatching hogs: 4 cpu, 0 io, 0 vm, 0 hdd
\`\`\`

**解读：** \`stress\` 命令将绑定到 CPU 0 和 CPU 1 上运行。

#### 示例 2: 检索进程的 CPU 亲和性

要查看一个正在运行的进程的 CPU 亲和性，可以使用 \`-p\` 选项：

\`\`\`bash
taskset -p <PID>
\`\`\`

假设进程 ID 为 \`12345\`：

\`\`\`bash
taskset -p 12345
\`\`\`

**输出：**

\`\`\`bash
pid 12345's current affinity list: 0,1
\`\`\`

**解读：** 进程 ID 为 \`12345\` 的进程当前绑定在 CPU 0 和 CPU 1 上。

#### 示例 3: 使用 CPU 列表设置亲和性

如果不想使用位掩码，也可以使用 \`-c\` 选项指定 CPU 列表。例如，绑定到 CPU 1 和 CPU 3：

\`\`\`bash
taskset -c 1,3 <command> [args...]
\`\`\`

**输出：**

\`\`\`bash
[2024-09-10 12:35:12] <command> output...
\`\`\`

**解读：** 指定的命令将绑定到 CPU 1 和 CPU 3 上运行。

### 不同架构下的输出

\`taskset\` 命令的基本行为在不同架构下通常是相同的。因此，通常不需要特别说明不同架构下的输出。

### 版本差异

\`taskset\` 命令的不同版本可能会有一些细微差异，但其基本功能一般保持一致。可以通过 \`man taskset\` 查看特定版本的文档，以了解具体差异。

### 替代命令

如果 \`taskset\` 命令不可用，可以考虑使用 \`sched_setaffinity\` 系统调用或者通过 \`cgroups\` 来控制进程的 CPU 亲和性。不过，这些方法可能会更加复杂且需要额外的配置。

### 安装

- **Ubuntu:** \`taskset\` 是 \`util-linux\` 包的一部分，使用以下命令进行安装：

    \`\`\`bash
    sudo apt-get install util-linux
    \`\`\`

- **RHEL:** \`taskset\` 也在 \`util-linux\` 包中，使用以下命令进行安装：

    \`\`\`bash
    sudo yum install util-linux
    \`\`\`

### Root 权限

\`taskset\` 命令通常不需要 root 权限进行常规操作。但是，设置其他用户的进程的 CPU 亲和性可能需要使用 \`sudo\`。

**示例：**

\`\`\`bash
sudo taskset -p 12345
\`\`\`

**输出：**

\`\`\`bash
pid 12345's current affinity list: 0,1
\`\`\`

**解读：** 使用 \`sudo\` 可以查看或修改其他用户的进程的 CPU 亲和性。

### 结论

\`taskset\` 命令是一个强大的工具，用于设置和检索进程的 CPU 亲和性。它可以帮助优化系统性能并有效利用 CPU 资源。通过合理配置进程的 CPU 亲和性，可以提高系统的整体性能。

        `,
    };
  },
});
</script>
<style scoped></style>
