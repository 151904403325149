<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## at 命令详解

\`at\`命令是Linux系统中用于在指定时间执行一次性任务的工具。与\`cron\`不同，\`at\`命令不会安排周期性任务，而是用于指定一次性任务执行的日期和时间。\`at\`命令非常适用于计划需要在未来某个特定时间运行的命令或脚本，例如系统维护任务、数据备份或提醒任务等。

本文将详细介绍\`at\`命令的用法、常用参数，并通过实际例子演示其应用及输出结果。

### 安装at命令

在大多数Linux发行版中，\`at\`命令不是默认安装的。如果你发现\`at\`命令不可用，可以使用以下命令进行安装：

\`\`\`bash
sudo apt-get install at  # 对于Debian/Ubuntu系统
sudo yum install at      # 对于RHEL/CentOS系统
\`\`\`

**示例输出：**

\`\`\`plaintext
[无输出，命令已成功执行]
\`\`\`

**解读：** 安装成功后，无输出提示，表明命令已正确执行，可以继续使用\`at\`命令。

### 基本用法

\`at\`命令的基本语法如下：

\`\`\`bash
at [选项] 时间
\`\`\`

其中，\`时间\`参数用于指定任务的执行时间。可以采用多种时间格式，如\`HH:MM\`表示当天的具体时间，\`noon\`表示中午，\`midnight\`表示午夜，\`teatime\`表示下午4点。

例如，我们想在下午2点执行一个任务，可以使用以下命令：

\`\`\`bash
echo "ls > /tmp/ls_output.txt" | at 14:00
\`\`\`

**示例输出：**

\`\`\`plaintext
warning: commands will be executed using /bin/sh
job 5 at Thu Sep  7 14:00:00 2024
\`\`\`

**解读：** 输出信息显示了任务已经被安排，并分配了一个作业号（\`job 5\`），任务将在指定的时间运行。

### 查看已安排的任务

使用\`atq\`命令可以查看当前用户已安排的\`at\`任务列表：

\`\`\`bash
atq
\`\`\`

**示例输出：**

\`\`\`plaintext
5	Thu Sep  7 14:00:00 2024 a sre
\`\`\`

**解读：** 输出显示作业号为\`5\`的任务将于\`2024年9月7日14:00:00\`执行，用户为\`sre\`。

### 取消已安排的任务

如果想取消一个已安排的\`at\`任务，可以使用\`atrm\`命令，后跟任务的作业号。例如，要取消作业号为\`5\`的任务：

\`\`\`bash
atrm 5
\`\`\`

**示例输出：**

\`\`\`plaintext
[无输出，命令已成功执行]
\`\`\`

**解读：** 成功执行后，无输出，表示任务已被成功取消。可以通过\`atq\`命令再次确认任务列表。

### 在指定日期和时间执行任务

\`at\`命令还允许在特定的日期和时间执行任务。例如，我们想在\`2024年9月8日\`的上午10点执行一个任务：

\`\`\`bash
echo "df -h > /tmp/disk_usage.txt" | at 10:00 09/08/2024
\`\`\`

**示例输出：**

\`\`\`plaintext
warning: commands will be executed using /bin/sh
job 6 at Sun Sep  8 10:00:00 2024
\`\`\`

**解读：** 任务被安排在\`2024年9月8日10:00:00\`执行，并分配了作业号\`6\`。

### 结合at命令执行多个任务

可以使用\`at\`命令组合多个命令来执行复杂的任务。例如，创建一个脚本文件并在未来执行：

\`\`\`bash
echo -e "cd /var/log\nls -lh > /tmp/log_list.txt" | at now + 1 minute
\`\`\`

**示例输出：**

\`\`\`plaintext
warning: commands will be executed using /bin/sh
job 7 at Thu Sep  7 14:11:00 2024
\`\`\`

**解读：** 此任务将在1分钟后执行，并在\`/var/log\`目录中列出所有文件并保存到\`/tmp/log_list.txt\`。

### 检查任务执行结果

在执行了上面的任务之后，可以使用\`cat\`命令查看任务的输出结果：

\`\`\`bash
cat /tmp/log_list.txt
\`\`\`

**示例输出：**

\`\`\`plaintext
total 4.0K
drwxr-xr-x 2 root root 4.0K Sep  7 14:11 .
drwxr-xr-x 4 root root 4.0K Sep  7 13:45 ..
-rw-r--r-- 1 root root    0 Sep  7 14:11 log_list.txt
\`\`\`

**解读：** 输出结果显示\`/var/log\`目录的文件列表已成功保存到指定的文件中，任务成功执行。

### 总结

\`at\`命令是Linux中管理一次性任务的强大工具。通过\`at\`命令，用户可以轻松地安排任务在未来的特定时间执行。无论是简单的命令还是复杂的脚本，\`at\`命令都能有效管理。此外，通过结合\`atq\`和\`atrm\`命令，用户还可以方便地查看和管理已安排的任务。这些功能使\`at\`命令成为系统管理员进行计划任务管理的重要工具。

        `,
    };
  },
});
</script>
<style scoped></style>
