<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## lsof 命令详解

\`lsof\`（List Open Files）是一个强大的命令行工具，用于显示当前系统中打开的文件及其相关信息。在 Unix 和类 Unix 系统中，几乎所有的东西都被视为文件，包括常规文件、目录、网络连接等。\`lsof\` 可以帮助用户了解系统资源的使用情况，进行故障排查，监控文件和网络活动。

### 安装

在大多数 Linux 发行版中，\`lsof\` 通常预装，但如果没有安装，可以使用以下命令进行安装：

#### Ubuntu

\`\`\`bash
sudo apt-get install lsof
\`\`\`

#### RHEL

\`\`\`bash
sudo yum install lsof
\`\`\`

### 基本语法

\`\`\`bash
lsof [选项] [文件名|PID|用户]
\`\`\`

#### 选项说明

- \`-u <用户>\`：显示指定用户打开的文件。
- \`-p <PID>\`：显示指定进程 ID 打开的文件。
- \`-i\`：显示网络连接和端口。
- \`-t\`：以简单的格式输出进程 ID（PID）。
- \`+D <目录>\`：递归显示指定目录下打开的文件。
- \`-n\`：不进行域名解析，提高命令执行速度。

### 示例用法

#### 示例一：显示所有打开的文件

\`\`\`bash
lsof
\`\`\`

**输出示例**：

\`\`\`plaintext
COMMAND    PID    USER   FD   TYPE DEVICE SIZE/OFF      NODE NAME
bash      10010   sre  cwd    DIR  8,1     4096  12345678 /home/sre
bash      10010   sre  1u     REG  8,1    20480  12345679 /home/sre/output.txt
\`\`\`

**解读**：该命令列出了当前系统中所有打开的文件。输出包括命令名、进程 ID、用户、文件描述符、文件类型、设备、文件大小和文件路径等信息。

#### 示例二：显示指定用户打开的文件

\`\`\`bash
lsof -u sre
\`\`\`

**输出示例**：

\`\`\`plaintext
COMMAND    PID    USER   FD   TYPE DEVICE SIZE/OFF      NODE NAME
bash      10010   sre  cwd    DIR  8,1     4096  12345678 /home/sre
\`\`\`

**解读**：此命令显示用户 \`sre\` 打开的文件。输出的信息与上例类似，但只包含指定用户的文件。

#### 示例三：显示指定进程打开的文件

\`\`\`bash
lsof -p 10010
\`\`\`

**输出示例**：

\`\`\`plaintext
COMMAND    PID    USER   FD   TYPE DEVICE SIZE/OFF      NODE NAME
bash      10010   sre  cwd    DIR  8,1     4096  12345678 /home/sre
bash      10010   sre  1u     REG  8,1    20480  12345679 /home/sre/output.txt
\`\`\`

**解读**：该命令显示进程 ID 为 \`10010\` 的进程打开的文件。输出显示了该进程的工作目录和打开的文件。

#### 示例四：显示网络连接

\`\`\`bash
lsof -i
\`\`\`

**输出示例**：

\`\`\`plaintext
COMMAND    PID    USER   FD   TYPE DEVICE SIZE/OFF      NODE NAME
sshd      10015   root    3u  IPv4  1234567      0t0      TCP *:22 (LISTEN)
bash      10010   sre     4u  IPv4  1234568      0t0      TCP 192.168.1.10:49876->192.168.1.1:80 (ESTABLISHED)
\`\`\`

**解读**：该命令显示当前系统中的所有网络连接。输出包括命令名、进程 ID、用户、文件描述符、网络类型和状态等信息。

### 不同架构的输出

在 aarch64 和 x86_64/amd64 架构上，\`lsof\` 命令的输出通常没有显著差异，因此此部分不需要额外列出。

### 版本差异

不同版本的 \`lsof\` 可能在功能和输出格式上存在差异。可以通过以下命令检查版本：

\`\`\`bash
lsof -v
\`\`\`

**输出示例**：

\`\`\`plaintext
lsof version 4.93.2
\`\`\`

### 权限要求

某些 \`lsof\` 命令操作可能需要 \`root\` 权限，以便能够查看所有用户的打开文件。例如，显示所有打开的文件时可能会出现权限不足的问题：

\`\`\`bash
sudo lsof
\`\`\`

**示例输出**：

\`\`\`plaintext
无输出，命令已成功执行
\`\`\`

### 总结

\`lsof\` 是一个非常实用的工具，用于监控和管理系统中的打开文件和网络连接。掌握 \`lsof\` 的使用可以帮助用户更好地了解系统的资源使用情况，进行故障排查和性能优化。

        `,
    };
  },
});
</script>
<style scoped></style>
