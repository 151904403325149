<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ps 命令详解

\`ps\`（process status）命令用于显示当前系统的进程状态。它是管理和监控系统进程的基本工具，可以用于获取有关正在运行的进程的详细信息。

### 命令格式

\`\`\`bash
ps [选项]
\`\`\`

### 主要参数

- \`-e\` 或 \`-A\`：显示所有进程。
- \`-f\`：以完整格式显示进程信息，包括父进程ID和启动时间等。
- \`-u <用户名>\`：显示指定用户的进程。
- \`-p <PID>\`：显示指定PID的进程。
- \`-l\`：以长格式显示进程信息，提供更多的详细信息。
- \`-aux\`：显示所有进程的详细信息，包括其他用户的进程（注意：在某些系统上 \`-aux\` 可能被认为是无效参数，应该使用 \`-e -u\`）。

### 使用示例

1. **显示所有进程**

   \`\`\`bash
   ps -e
   \`\`\`

   **输出示例**：

   \`\`\`bash
     PID TTY          TIME CMD
       1 ?        00:00:05 init
     123 ?        00:00:00 sshd
    2345 ?        00:00:10 nginx
   \`\`\`

   **解读**：
   - \`PID\`：进程ID。
   - \`TTY\`：终端类型。
   - \`TIME\`：进程使用的CPU时间。
   - \`CMD\`：执行的命令。

2. **显示指定用户的进程**

   \`\`\`bash
   ps -u sre
   \`\`\`

   **输出示例**：

   \`\`\`bash
     PID TTY          TIME CMD
    1001 ?        00:00:02 bash
    1002 ?        00:00:01 top
   \`\`\`

   **解读**：
   - 显示用户名为 \`sre\` 的所有进程。

3. **显示进程的完整信息**

   \`\`\`bash
   ps -ef
   \`\`\`

   **输出示例**：

   \`\`\`bash
     UID        PID  PPID  C STIME TTY          TIME CMD
   sre       1001     1   0 09:00 ?        00:00:02 /bin/bash
   sre       1002  1001   0 09:01 ?        00:00:01 /usr/bin/top
   \`\`\`

   **解读**：
   - \`UID\`：用户ID。
   - \`PID\`：进程ID。
   - \`PPID\`：父进程ID。
   - \`C\`：CPU使用百分比。
   - \`STIME\`：进程启动时间。
   - \`TTY\`：终端类型。
   - \`TIME\`：进程使用的CPU时间。
   - \`CMD\`：执行的命令。

4. **显示指定PID的进程**

   \`\`\`bash
   ps -p 1001
   \`\`\`

   **输出示例**：

   \`\`\`bash
     PID TTY          TIME CMD
    1001 ?        00:00:02 bash
   \`\`\`

   **解读**：
   - 显示PID为1001的进程信息。

5. **以长格式显示进程信息**

   \`\`\`bash
   ps -l
   \`\`\`

   **输出示例**：

   \`\`\`bash
     F S   UID   PID  PPID  C PRI  NI   ADDR  SZ WCHAN  TTY          TIME CMD
   4 S 1005  1001     1  0  80   0 -  4244 wait   ?        00:00:02 bash
   4 R 1005  1002  1001  0  80   0 -  2648 -      ?        00:00:01 top
   \`\`\`

   **解读**：
   - \`F\`：标志字段。
   - \`S\`：状态。
   - \`UID\`：用户ID。
   - \`PID\`：进程ID。
   - \`PPID\`：父进程ID。
   - \`C\`：CPU使用百分比。
   - \`PRI\`：优先级。
   - \`NI\`：优先级的调整值。
   - \`ADDR\`：内存地址。
   - \`SZ\`：虚拟内存大小。
   - \`WCHAN\`：等待的事件。
   - \`TTY\`：终端类型。
   - \`TIME\`：进程使用的CPU时间。
   - \`CMD\`：执行的命令。

### 不同架构下的输出

\`ps\` 命令的输出在不同架构下通常是一致的，不会因为架构不同而有所变化。因此，本节通常不需要特殊处理。

### 版本差异

\`ps\` 命令在不同版本中，其主要功能和用法保持一致，但可能会有些微的变化或新增选项。可以使用 \`ps --version\` 查看当前版本及其支持的功能。

### 安全性及替代命令

\`ps\` 命令是系统中常用的工具，没有弃用或不安全的替代命令。如果需要更详细的进程监控，可以结合 \`top\`、\`htop\` 或 \`pgrep\` 等工具使用。

### 安装

\`ps\` 命令通常包含在基本的Linux系统安装中。如果需要重新安装，可以使用以下命令：

- **Ubuntu**：

  \`\`\`bash
  sudo apt-get install procps
  \`\`\`

- **RHEL**：

  \`\`\`bash
  sudo yum install procps-ng
  \`\`\`

### 需要root权限的选项

一些操作（如查看所有用户的进程）可能需要root权限。例如：

\`\`\`bash
sudo ps -e
\`\`\`

**示例输出**：

\`\`\`bash
  PID TTY          TIME CMD
    1 ?        00:00:05 init
   123 ?        00:00:00 sshd
  2345 ?        00:00:10 nginx
\`\`\`

**解读**：
- 使用 \`sudo\` 提升权限后，可以查看所有进程的信息。


        `,
    };
  },
});
</script>
<style scoped></style>
