<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## strace 命令详解

\`strace\` 是 Linux 系统中的一个强大工具，用于监视和调试进程的系统调用及接收到的信号。通过 \`strace\`，用户可以观察到程序与操作系统内核之间的交互，帮助诊断程序行为异常、性能问题或故障原因。

### 什么是 \`strace\`

\`strace\` 是一个外部命令，主要用于跟踪一个进程所发出的所有系统调用以及它所接收到的信号。由于它能详细显示程序执行过程中所涉及的底层系统调用，因此特别适用于调试和性能分析。

### 安装 \`strace\`

在大多数 Linux 发行版中，\`strace\` 并不会默认安装，可以通过包管理器来安装：

- 在 Ubuntu/Debian 系统中：

  \`\`\`bash
  sudo apt-get install strace
  \`\`\`

- 在 RHEL/CentOS 系统中：

  \`\`\`bash
  sudo yum install strace
  \`\`\`

安装包名称：\`strace\`。

### 基本语法

\`\`\`bash
strace [选项] 程序 [程序参数...]
\`\`\`

- \`程序\`：需要跟踪的目标程序。
- \`[选项]\`：控制 \`strace\` 的行为，指定要跟踪的系统调用类型等。

### 示例1：跟踪程序执行的系统调用

最基本的用法是直接运行 \`strace\` 来跟踪一个程序的所有系统调用：

\`\`\`bash
strace ls
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ strace ls
execve("/bin/ls", ["ls"], 0x7fff6b2e7360 /* 24 vars */) = 0
brk(NULL)                               = 0x5643e2562000
access("/etc/ld.so.nohwcap", F_OK)      = -1 ENOENT (No such file or directory)
...
\`\`\`

输出解释：此命令显示了 \`ls\` 命令执行过程中所有的系统调用，第一行显示的是 \`execve\` 系统调用，它用于执行 \`/bin/ls\` 程序。

### 示例2：跟踪指定系统调用

可以使用 \`-e\` 选项来过滤和跟踪特定的系统调用。例如，只跟踪 \`open\` 系统调用：

\`\`\`bash
strace -e open ls
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ strace -e open ls
open("/etc/ld.so.cache", O_RDONLY|O_CLOEXEC) = 3
open("/lib/x86_64-linux-gnu/libselinux.so.1", O_RDONLY|O_CLOEXEC) = 3
...
\`\`\`

输出解释：此命令仅显示 \`ls\` 命令执行过程中与文件打开相关的 \`open\` 系统调用，便于分析文件操作行为。

### 示例3：跟踪子进程的系统调用

有些程序会创建子进程，使用 \`-f\` 选项可以跟踪子进程的系统调用：

\`\`\`bash
strace -f bash -c "ls | grep strace"
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ strace -f bash -c "ls | grep strace"
[pid 12968] execve("/bin/ls", ["ls"], 0x7ffeefce9938 /* 24 vars */) = 0
[pid 12968] pipe([3, 4])                 = 0
...
\`\`\`

输出解释：\`-f\` 选项使 \`strace\` 能够跟踪由主进程派生的所有子进程的系统调用，并显示其调用的细节。

### 常用参数

- \`-c\`：统计每个系统调用的次数、时间和错误。

\`\`\`bash
strace -c ls
\`\`\`

**输出示例：**

\`\`\`bash
sre@azlinux-prod-cn:~$ strace -c ls
% time     seconds  usecs/call     calls    errors syscall
------ ----------- ----------- --------- --------- ----------------
 36.26    0.000026           0        13           openat
 21.73    0.000016           0        13           fstat
 ...
\`\`\`

输出解释：此命令输出 \`ls\` 命令执行过程中每个系统调用的统计信息，包括调用次数、总时间和错误数量等。

- \`-o\`：将输出重定向到文件，而不是直接打印到终端。

\`\`\`bash
strace -o strace_output.txt ls
\`\`\`

输出解释：此命令将 \`strace\` 的所有输出写入 \`strace_output.txt\` 文件，便于后续分析。

- \`-p\`：跟踪已经运行的进程。

\`\`\`bash
strace -p 1234
\`\`\`

输出解释：此命令将 \`strace\` 附加到 PID 为 1234 的运行进程上，并开始跟踪其系统调用。

### 不同架构和版本下的表现

\`strace\` 在 \`aarch64\` 和 \`x86_64/amd64\` 架构下的表现一致，主要依赖于内核提供的系统调用接口。因此，不同架构的输出格式和内容几乎没有差异。

### 安全性和替代命令

\`strace\` 对系统资源消耗较大，尤其在跟踪频繁调用系统调用的进程时，会对性能产生显著影响。因此，应避免在生产环境中长时间使用 \`strace\`。在某些情况下，可以使用更轻量级的工具，如 \`ltrace\`（用于跟踪库函数调用）或 \`perf\`（用于性能分析）来替代 \`strace\`。

### 总结

\`strace\` 是一个强大的调试工具，广泛用于分析程序行为、排查问题及性能优化。通过本指南，你应该能够熟练运用 \`strace\` 跟踪系统调用，分析程序与操作系统的交互，并理解其输出。

        `,
    };
  },
});
</script>
<style scoped></style>
