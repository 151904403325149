<template>
  <div>
    <el-select
      v-model="selectedSpecification"
      placeholder="默认规格"
      size="small"
    >
      <el-option
        v-for="(spec, index) in specifications"
        :key="index"
        :label="spec"
        :value="spec"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { ElSelect, ElOption, ElMessage } from "element-plus";

export default {
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    isForeign: {
      type: Boolean,
      default: false,
    },
    selectedValueFromParent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      specifications: [
        "1C2G",
        "2C2G",
        "2C4G",
        "4C8G",
        "4C16G",
        "4C32G",
        "8C16G",
        "8C32G",
      ],
      selectedSpecification: "",
    };
  },
  mounted() {
    if (this.selectedValueFromParent) {
      this.selectedSpecification = this.selectedValueFromParent;
    } else {
      this.selectedSpecification = "1C2G"; // 如果父组件未传值，设定默认值
    }
  },
  methods: {
    updateSpecifications() {
      if (this.isForeign) {
        this.specifications = ["1C2G", "2C2G", "2C4G"];
        const largeSpecs = ["4C8G", "4C16G", "4C32G", "8C16G", "8C32G"];
        if (largeSpecs.includes(this.selectedSpecification)) {
          this.selectedSpecification = "2C4G";
          ElMessage({
            message: "请注意：国外站点最高规格为2C4G，已自动切换",
            type: "warning",
            duration: 3000,
            offset: 180,
            showClose: true,
          });
        }
      } else if (this.specifications.length !== 8) {
        this.specifications = [
          "1C2G",
          "2C2G",
          "2C4G",
          "4C8G",
          "4C16G",
          "4C32G",
          "8C16G",
          "8C32G",
        ];
      }
    },
  },
  watch: {
    selectedSpecification(newVal) {
      this.$emit("update:selectedValue", newVal); // 向父组件传递当前选择的值
    },
    isForeign() {
      this.updateSpecifications();
    },
    selectedValueFromParent(newVal) {
      if (this.specifications.includes(newVal)) {
        this.selectedSpecification = newVal;
      } else {
        ElMessage({
          message: `传递的值“${newVal}”不在规格列表中，已自动切换为默认值“1C2G”`,
          type: "warning",
          duration: 3000,
          offset: 180,
          showClose: true,
        });
        this.selectedSpecification = "1C2G";
      }
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 80px;
  margin-right: 1rem;
}
</style>
