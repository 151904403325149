<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## ldd 命令详解

\`ldd\` 命令用于打印程序或库的共享库依赖信息。这对于调试动态链接库的问题以及了解程序运行时所依赖的库文件是非常有用的。

### 基本用法

\`ldd\` 命令的基本语法如下：

\`\`\`bash
ldd [options] <executable>
\`\`\`

其中，\`<executable>\` 是你要检查的可执行文件或共享库的路径。

### 示例

假设我们要检查 \`/usr/bin/ls\` 可执行文件的库依赖，我们可以使用 \`ldd\` 命令：

\`\`\`bash
ldd /usr/bin/ls
\`\`\`

输出结果可能如下：

\`\`\`plaintext
    linux-vdso.so.1 (0x00007ffecf7ff000)
    libselinux.so.1 => /lib/x86_64-linux-gnu/libselinux.so.1 (0x00007f5b75c00000)
    libc.so.6 => /lib/x86_64-linux-gnu/libc.so.6 (0x00007f5b758c2000)
    /lib64/ld-linux-x86-64.so.2 (0x00007f5b75a84000)
\`\`\`

在这个示例中，\`ldd\` 显示了 \`/usr/bin/ls\` 程序所依赖的共享库，包括它们的路径和内存地址。

### 重要参数和功能

- **-v**: 显示版本信息。

  示例：

  \`\`\`bash
  ldd -v /usr/bin/ls
  \`\`\`

  输出结果：

  \`\`\`plaintext
  ldd (Ubuntu GLIBC 2.31-0ubuntu9.9) 2.31
  ...
  \`\`\`

  该选项显示 \`ldd\` 命令的版本信息。

- **--version**: 显示 \`ldd\` 命令的版本信息并退出。

  示例：

  \`\`\`bash
  ldd --version
  \`\`\`

  输出结果：

  \`\`\`plaintext
  ldd (Ubuntu GLIBC 2.31-0ubuntu9.9) 2.31
  \`\`\`

  与 \`-v\` 类似，\`--version\` 提供了版本信息。

- **--help**: 显示帮助信息。

  示例：

  \`\`\`bash
  ldd --help
  \`\`\`

  输出结果：

  \`\`\`plaintext
  Usage: ldd [OPTION]... PROGRAM...
  ...
  \`\`\`

  显示 \`ldd\` 命令的帮助信息。

### 特殊用法示例

- **检查共享库的路径**：

  如果你需要检查某个库文件的路径，可以使用以下命令：

  \`\`\`bash
  ldd /lib/x86_64-linux-gnu/libc.so.6
  \`\`\`

  输出结果：

  \`\`\`plaintext
    linux-vdso.so.1 (0x00007ffecf7ff000)
    libnss_files.so.2 => /lib/x86_64-linux-gnu/libnss_files.so.2 (0x00007f5b755a5000)
    ...
  \`\`\`

  显示了 \`libc.so.6\` 的共享库依赖情况。

### 不同架构下的输出

\`ldd\` 命令在不同架构下的输出格式基本一致，但所显示的库文件和路径会有所不同。以下是 \`x86_64\` 和 \`aarch64\` 架构下的输出示例：

#### x86_64架构下

\`\`\`bash
ldd /usr/bin/ls
\`\`\`

输出结果：

\`\`\`plaintext
    linux-vdso.so.1 (0x00007ffecf7ff000)
    libselinux.so.1 => /lib/x86_64-linux-gnu/libselinux.so.1 (0x00007f5b75c00000)
    libc.so.6 => /lib/x86_64-linux-gnu/libc.so.6 (0x00007f5b758c2000)
    /lib64/ld-linux-x86-64.so.2 (0x00007f5b75a84000)
\`\`\`

#### aarch64架构下

\`\`\`bash
ldd /usr/bin/ls
\`\`\`

输出结果：

\`\`\`plaintext
    linux-vdso.so.1 (0x0000007f726ff000)
    libselinux.so.1 => /lib/aarch64-linux-gnu/libselinux.so.1 (0x0000007f72710000)
    libc.so.6 => /lib/aarch64-linux-gnu/libc.so.6 (0x0000007f72560000)
    /lib/ld-linux-aarch64.so.1 (0x0000007f72520000)
\`\`\`

### 不同版本的差异

不同版本的 \`ldd\` 可能在显示格式和功能上有所不同。例如，较旧版本可能不支持 \`--version\` 选项。请参考你系统上 \`ldd\` 的文档以了解具体差异。

### 安装信息

- **Ubuntu/Debian**: \`ldd\` 命令是 \`libc-bin\` 包的一部分，通常系统默认安装。

  安装命令：

  \`\`\`bash
  sudo apt-get install libc-bin
  \`\`\`

- **RHEL/CentOS**: \`ldd\` 命令是 \`glibc\` 包的一部分。

  安装命令：

  \`\`\`bash
  sudo yum install glibc
  \`\`\`

### 使用root权限

\`ldd\` 命令通常不需要 root 权限即可查看库依赖信息。但如果你要查看系统级别的库文件或受保护的文件，可能需要相应的权限。

### 总结

\`ldd\` 命令是一个强大的工具，用于检查程序和共享库的动态链接依赖。通过 \`ldd\`，你可以快速了解程序运行时所依赖的库文件，从而进行调试和优化。

        `,
    };
  },
});
</script>
<style scoped></style>
