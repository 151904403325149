<template>
  <div>
    <h2>This is Component 1</h2>
    <p>Content for Component 1 goes here.</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  color: #333;
}
</style>
