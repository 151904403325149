<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## man 命令详解

\`man\` 命令用于查看 Unix 和 Linux 系统中命令的手册页（manual page）。手册页提供了有关系统命令、函数、配置文件和系统调用的详细信息，是获取命令帮助的标准方式。

### 使用语法

\`\`\`bash
man [选项] 命令或文件
\`\`\`

### 重要参数和选项

- \`-k\` 或 \`--apropos\`：搜索所有手册页中包含指定关键字的条目。
- \`-f\` 或 \`--whatis\`：显示指定命令的简要描述。
- \`-a\` 或 \`--all\`：显示所有匹配的手册页。
- \`-M\` 或 \`--manpath\`：指定手册页路径。
- \`-s\` 或 \`--section\`：指定手册页的章节。

### 基本用法

#### 例1：查看命令的手册页

查看 \`ls\` 命令的手册页：

\`\`\`bash
man ls
\`\`\`

**输出解释**:
\`\`\`text
LS(1)                        User Commands                        LS(1)

NAME
       ls - list directory contents

SYNOPSIS
       ls [OPTION]... [FILE]...

DESCRIPTION
       List information about the FILEs (the current directory by default).

OPTIONS
       -a, --all
           Do not ignore entries starting with .

       -l
           Use a long listing format
\`\`\`

**解读**:
手册页包括命令名称、简要描述、用法、选项等信息。\`man\` 命令显示了 \`ls\` 的完整文档。

#### 例2：搜索手册页中的关键字

搜索包含 "copy" 关键字的手册页：

\`\`\`bash
man -k copy
\`\`\`

**输出解释**:
\`\`\`text
cp (1)             - copy files and directories
cpio (1)           - copy files to and from archives
\`\`\`

**解读**:
\`-k\` 选项搜索所有手册页中包含“copy”的条目，输出了相关的命令及其简要描述。

#### 例3：查看特定章节的手册页

查看第 5 章中的 \`passwd\` 手册页：

\`\`\`bash
man 5 passwd
\`\`\`

**输出解释**:
\`\`\`text
PASSWD(5)                   File Formats Manual                   PASSWD(5)

NAME
       passwd - password file

DESCRIPTION
       The /etc/passwd file is a text file that defines user accounts.
\`\`\`

**解读**:
\`-s\` 选项指定了手册页的章节，这里显示了第 5 章（文件格式）的 \`passwd\` 手册页。

#### 例4：显示命令的简要描述

显示 \`ls\` 命令的简要描述：

\`\`\`bash
man -f ls
\`\`\`

**输出解释**:
\`\`\`text
ls (1) - list directory contents
\`\`\`

**解读**:
\`-f\` 选项提供了指定命令的简要描述，帮助快速了解命令的功能。

### 不同架构下的表现差异

\`man\` 命令在不同架构的机器上表现一致，因为它仅依赖于手册页文件，架构不影响其输出。

### 不同版本的差异

\`man\` 命令的基本功能在不同版本的 Linux 系统中保持一致。尽管可能存在一些扩展选项或修复，但核心功能没有显著差异。

### 替代命令

没有直接替代的命令，\`man\` 是获取命令和函数详细文档的标准工具。如果需要帮助，\`--help\` 选项有时也可以用来获取命令的简要说明：

\`\`\`bash
ls --help
\`\`\`

### 安装包信息

\`man\` 命令是 \`man-db\` 包的一部分，因此需要安装 \`man-db\` 包来获得 \`man\` 命令：

- **Ubuntu**:
  \`\`\`bash
  sudo apt-get install man-db
  \`\`\`

- **RHEL**:
  \`\`\`bash
  sudo yum install man-db
  \`\`\`

**需要 root 权限的选项**:
- \`man\` 命令本身不需要 root 权限，但查看特定手册页的权限可能受系统配置影响。

**示例**:
\`\`\`bash
man ls
\`\`\`

**输出**:
\`\`\`text
LS(1)                        User Commands                        LS(1)

NAME
       ls - list directory contents

SYNOPSIS
       ls [OPTION]... [FILE]...

DESCRIPTION
       List information about the FILEs (the current directory by default).

OPTIONS
       -a, --all
           Do not ignore entries starting with .

       -l
           Use a long listing format
\`\`\`

        `,
    };
  },
});
</script>
<style scoped></style>
