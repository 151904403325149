<template>
  <div>
    <GameCard1
      title="容器生命周期"
      actions="自由探索，<br>从容器启动，暂停，解除暂停，停止，重启，到重命名等。"
    />
    <MarkdownRenderer :markdown="markdownContent" />
    <KnowledgeCardInterview :content="interviewContent" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";
import KnowledgeCardInterview from "@/views/base/KnowledgeCardInterview.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
    GameCard1,
    KnowledgeCardInterview,
  },
  data() {
    return {
      markdownContent: `
## 使用 Nginx 的 Docker 容器生命周期操作演示和示例

Docker 是一种开源的容器化平台，使得应用程序的部署和管理变得简单高效。在这篇文章中，我们将通过使用 Nginx 的稳定版本，演示 Docker 容器的生命周期操作，包括启动、暂停、恢复、停止、重启、重命名和删除。

### 1. 启动 Nginx 容器

首先，我们使用 \`docker run\` 命令启动一个 Nginx 容器。我们将 Nginx 的稳定版本拉取并运行。

\`\`\`bash
docker run --name my-nginx -d -p 8080:80 nginx:stable
\`\`\`

- \`--name my-nginx\`：给容器指定一个名称。
- \`-d\`：后台运行容器。
- \`-p 8080:80\`：将宿主机的 8080 端口映射到容器的 80 端口。

运行成功后，可以在浏览器中访问 \`http://localhost:8080\`或者\`http://public_ip_addr:8080\`(公网ip访问)来查看 Nginx 欢迎页面。

### 2. 暂停容器

使用 \`docker pause\` 命令可以暂停正在运行的 Nginx 容器。

\`\`\`bash
docker pause my-nginx
\`\`\`

这个命令会将容器内的所有进程挂起，但不会释放资源。

### 3. 恢复容器

要恢复暂停的容器，可以使用 \`docker unpause\` 命令。

\`\`\`bash
docker unpause my-nginx
\`\`\`

这将会恢复 Nginx 容器的所有进程。

### 4. 停止容器

使用 \`docker stop\` 命令可以安全地停止 Nginx 容器。

\`\`\`bash
docker stop my-nginx
\`\`\`

该命令会发送 SIGTERM 信号给容器中的主进程，等待其安全退出。

### 5. 重启容器

如果需要重新启动容器，可以使用 \`docker restart\` 命令。

\`\`\`bash
docker restart my-nginx
\`\`\`

该命令会先停止容器，然后再启动它。

### 6. 重命名容器

你可以使用 \`docker rename\` 命令更改容器的名称。

\`\`\`bash
docker rename my-nginx my-nginx-renamed
\`\`\`

此命令将容器的名称更改为 \`my-nginx-renamed\`。

### 7. 删除容器

当你不再需要容器时，可以使用 \`docker rm\` 命令删除它。

\`\`\`bash
docker rm my-nginx-renamed
\`\`\`

请确保容器已停止，否则需要使用 \`-f\` 选项强制删除。

### 总结

通过上述操作，我们演示了使用 Nginx 的 Docker 容器的生命周期管理，包括启动、暂停、恢复、停止、重启、重命名和删除等操作。Docker 的这些功能极大地简化了应用程序的管理，使得开发和运维人员能够更高效地工作。

### 参考资料

- [Docker Documentation](https://docs.docker.com/)
- [Nginx Official Image on Docker Hub](https://hub.docker.com/_/nginx)

这些命令和操作是管理 Docker 容器的基础，掌握这些内容将为进一步深入 Docker 的使用和理解打下良好的基础。

## 上文步骤中2和3的理解
\`pause\` 和 \`unpause\` 命令在 Docker 中的意义主要体现在容器的进程管理上。以下是它们的具体作用和应用场景：

### 1. 暂停容器 (\`docker pause\`)

- **作用**：\`docker pause\` 命令可以将正在运行的容器内的所有进程挂起。执行这个命令会暂停容器内的所有进程，类似于将计算机置于休眠状态。具体来说，它会向容器中的所有进程发送 \`SIGSTOP\` 信号。
- **用途**：
  - **资源管理**：在需要临时释放系统资源时，可以暂停容器，避免其占用 CPU 时间。
  - **状态保存**：可以在不停止容器的情况下，保存其当前状态，方便后续恢复。

### 2. 恢复容器 (\`docker unpause\`)

- **作用**：\`docker unpause\` 命令用于恢复被暂停的容器。它会向容器中的所有进程发送 \`SIGCONT\` 信号，使其重新开始执行。
- **用途**：
  - **恢复运行**：在需要恢复容器的正常操作时，使用此命令即可快速恢复之前的状态。
  - **快速调试**：在调试过程中，可能需要暂停容器以检查某些状态，之后再恢复其执行。

### 总结

通过这两个命令，Docker 提供了一种灵活的方式来管理容器的进程状态。暂停和恢复容器的能力使得用户能够高效地进行资源管理和容器调试，而不必完全停止和重启容器，保持了更高的灵活性和性能。

更多关于这些命令的详细信息可以参考以下链接：
- [Docker pause documentation](https://docs.docker.com/engine/reference/commandline/pause/)
- [Docker unpause documentation](https://docs.docker.com/engine/reference/commandline/unpause/)
        
        `,
      interviewContent: `
### 选择题

1. **容器的生命周期中，以下哪个命令可以用于暂停容器的所有进程？**
   - A) \`docker stop\`
   - B) \`docker pause\`
   - C) \`docker kill\`
   - D) \`docker restart\`

2. **使用以下哪个命令可以删除一个已停止的容器？**
   - A) \`docker rm\`
   - B) \`docker rmi\`
   - C) \`docker container prune\`
   - D) \`docker delete\`

3. **在 Docker 中，哪个命令用于重启一个容器？**
   - A) \`docker restart\`
   - B) \`docker unpause\`
   - C) \`docker resume\`
   - D) \`docker start\`

4. **以下哪个命令将显示容器的状态和信息？**
   - A) \`docker ps\`
   - B) \`docker inspect\`
   - C) \`docker stats\`
   - D) \`docker logs\`

### 问答题

1. **描述 Docker 容器的生命周期及其各个状态的转变过程。**

2. **\`docker pause\` 和 \`docker unpause\` 命令的作用是什么？在什么场景下使用它们？**

3. **如何通过 Docker 命令查看正在运行的容器和已停止的容器？请给出相应的命令。**

4. **解释 \`docker rm\` 和 \`docker rmi\` 的区别。它们分别用于什么场景？**

---

### 答案

1. **B) \`docker pause\`**
2. **A) \`docker rm\`**
3. **A) \`docker restart\`**
4. **B) \`docker inspect\`**

**问答题答案：**

1. **Docker 容器的生命周期包括创建、运行、暂停、停止、重启、删除等状态。容器可以从运行状态转变为暂停状态，再从暂停状态恢复到运行状态，也可以从运行状态停止，再删除。**
  
2. **\`docker pause\` 用于暂停容器的所有进程，\`docker unpause\` 用于恢复被暂停的容器。使用场景包括临时释放资源或调试容器。**
  
3. **查看正在运行的容器可以使用 \`docker ps\`，查看所有容器（包括已停止的）可以使用 \`docker ps -a\`。**
  
4. **\`docker rm\` 用于删除容器，而 \`docker rmi\` 用于删除镜像。\`docker rm\` 在容器停止后执行，而 \`docker rmi\` 是在需要删除镜像时执行的。**
      
      `,
    };
  },
});
</script>
<style scoped></style>
