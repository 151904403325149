<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## uniq 命令详解

\`uniq\` 是一个用于过滤文本文件中重复行的命令。它通常与 \`sort\` 命令配合使用，以确保输入数据已排序，从而使重复行能够被识别并去除。

### 主要功能

\`uniq\` 命令用于从已排序的文件中删除相邻的重复行，并可以显示唯一行的统计信息。它主要用于处理文本文件，特别是当你希望去除或分析重复数据时。

### 重要参数

以下是 \`uniq\` 命令的一些重要参数及其用途：

- \`-c\`：在输出中显示每行的重复次数。
- \`-d\`：仅显示重复的行。
- \`-u\`：仅显示唯一的行。
- \`-i\`：在比较行时忽略大小写。
- \`-w N\`：在比较行时只考虑前 N 个字符。

### 示例用法

#### 示例 1：基本用法

假设有一个文件 \`example.txt\`，内容如下：

\`\`\`
apple
banana
apple
apple
orange
banana
\`\`\`

我们可以使用 \`uniq\` 命令去除重复行：

\`\`\`bash
$ uniq example.txt
apple
banana
apple
orange
banana
\`\`\`

#### 示例 2：统计重复次数

使用 \`-c\` 参数来显示每行的重复次数：

\`\`\`bash
$ uniq -c example.txt
      1 apple
      2 banana
      2 apple
      1 orange
      1 banana
\`\`\`

#### 示例 3：仅显示重复行

使用 \`-d\` 参数来仅显示重复的行：

\`\`\`bash
$ uniq -d example.txt
apple
banana
\`\`\`

#### 示例 4：仅显示唯一行

使用 \`-u\` 参数来仅显示唯一的行：

\`\`\`bash
$ uniq -u example.txt
orange
\`\`\`

#### 示例 5：忽略大小写

使用 \`-i\` 参数来在比较时忽略大小写：

\`\`\`bash
$ uniq -i example.txt
apple
banana
orange
\`\`\`

#### 示例 6：比较前 N 个字符

使用 \`-w N\` 参数来只考虑前 N 个字符：

\`\`\`bash
$ uniq -w 5 example.txt
apple
banana
apple
orange
banana
\`\`\`

### 常见问题

#### \`uniq\` 命令是否需要排序？

是的，\`uniq\` 命令通常与 \`sort\` 命令一起使用，以确保处理的数据是已排序的，这样才能正确地删除相邻的重复行。例如：

\`\`\`bash
$ sort example.txt | uniq
\`\`\`

### 不同版本的输出差异

不同版本的 \`uniq\` 命令可能会有轻微的差异，但基本用法和参数通常是一致的。请根据系统的 \`man\` 页或 \`--version\` 输出检查具体差异。

### 安全性和替代命令

\`uniq\` 是一个安全的命令，没有被淘汰的替代命令。它是处理文本文件中重复数据的标准工具。

### 安装包信息

在大多数 Linux 发行版中，\`uniq\` 命令是 \`coreutils\` 包的一部分。如果你的系统没有安装，可以使用以下命令进行安装：

- **Ubuntu**:
  \`\`\`bash
  sudo apt-get install coreutils
  \`\`\`
- **RHEL**:
  \`\`\`bash
  sudo yum install coreutils
  \`\`\`

### 示例主机名

假设主机名为 \`azlinux-prod-cn\`。

### 使用 root 权限的情况

\`uniq\` 命令本身不需要 root 权限。然而，如果你需要处理的文件或目录权限设置为只允许特定用户访问，则可能需要 root 权限来读取或写入文件。以下是一个示例：

\`\`\`bash
sudo uniq /path/to/protected-file
\`\`\`
        `,
    };
  },
});
</script>
<style scoped></style>
