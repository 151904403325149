<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## growpart 命令详解

\`growpart\` 命令是一个用于扩展分区大小的工具，通常在调整磁盘分区时使用。它可以通过扩展分区来利用额外的磁盘空间，而无需删除和重新创建分区。\`growpart\` 主要用于处理 Linux 系统中的分区表，适合用于物理和虚拟机的分区调整。

### growpart 是 bash 内置命令还是外部命令？

\`growpart\` 是一个外部命令，通常安装在 \`/usr/bin/growpart\`。它不是 Bash 的内置命令，因此需要确保系统中已安装该命令。

### 基本语法

\`growpart\` 命令的基本语法如下：

\`\`\`bash
growpart [设备] [分区号]
\`\`\`

### 重要参数及示例

#### 1. 扩展分区

要扩展特定的分区，可以使用 \`growpart\` 命令。以下示例将扩展 \`/dev/sda1\` 分区：

\`\`\`bash
sudo growpart /dev/sda 1
# 输出：
# CHANGED: partition 1 has been grown
\`\`\`

此输出表示分区 \`/dev/sda1\` 已成功扩展。

#### 2. 检查分区状态

扩展分区后，可以使用 \`lsblk\` 命令来检查分区的状态和大小：

\`\`\`bash
lsblk
# 输出示例：
# NAME   MAJ:MIN RM   SIZE RO TYPE MOUNTPOINT
# sda      8:0    0 100G  0 disk 
# ├─sda1   8:1    0  50G  0 part /
# └─sda2   8:2    0  50G  0 part /data
\`\`\`

输出中显示了分区 \`sda1\` 的大小，若已扩展，应该会反映出新大小。

#### 3. 扩展根分区

如果需要扩展根分区，可以直接执行：

\`\`\`bash
sudo growpart /dev/sda 1
# 输出：
# CHANGED: partition 1 has been grown
\`\`\`

在扩展后，再次使用 \`df -h\` 查看文件系统使用情况：

\`\`\`bash
df -h
# 输出示例：
# 文件系统      容量  已用  可用 已用% 挂载点
# /dev/sda1      100G   20G   80G   20% /
\`\`\`

### 不同架构下的执行结果

\`growpart\` 命令在 aarch64 和 x86_64/amd64 架构下的行为一致，无论在哪种架构下，\`growpart\` 命令的功能和输出均相同。

### 版本差异

在不同版本的 Linux 发行版中，\`growpart\` 命令的基本用法保持一致，但可能存在某些特定选项的差异。一般来说，常用的选项在大多数版本中都是支持的。

### 安装

在大多数 Linux 发行版中，\`growpart\` 命令通常与 \`cloud-utils\` 包一起提供。如果需要安装，可以通过以下方式进行：

- **Ubuntu/Debian**：
  \`\`\`bash
  sudo apt install cloud-guest-utils
  \`\`\`
- **RHEL/CentOS**：
  \`\`\`bash
  sudo yum install cloud-utils-growpart
  \`\`\`

### 使用root权限的场景

\`growpart\` 命令需要使用 root 权限进行分区扩展。使用示例：

\`\`\`bash
sudo growpart /dev/sda 1
# 输出：
# CHANGED: partition 1 has been grown
\`\`\`

### 总结

\`growpart\` 命令是一个重要的工具，用于扩展分区以利用额外的磁盘空间。它在调整分区时提供了灵活性，使系统管理员能够有效管理存储资源。通过掌握 \`growpart\` 命令的使用，用户能够轻松扩展分区，确保系统运行的高效性和灵活性。

        `,
    };
  },
});
</script>
<style scoped></style>
