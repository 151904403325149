<template>
  <div>
    <MarkdownRenderer :markdown="markdownContent" />
    <el-divider></el-divider>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      markdownContent: `
## time 命令详解

\`time\`命令用于测量程序执行所需的时间。它通常用于性能分析和调试，帮助用户了解程序的运行时间和系统资源使用情况。

### 基本用法

\`time\`命令的基本语法如下：

\`\`\`bash
time [OPTION...] COMMAND [ARGUMENTS...]
\`\`\`

### 常用参数

- \`-p\`：使用POSIX格式输出时间信息。
- \`-v\`：显示详细的时间和资源使用信息（verbose）。
- \`--quiet\`：只输出命令执行时间。
- \`--format=FORMAT\`：自定义输出格式。

### 示例用法

#### 测量命令执行时间

测量\`ls\`命令的执行时间：

\`\`\`bash
time ls
\`\`\`

**输出：**

\`\`\`
file1.txt
file2.txt
file3.txt

real    0m0.001s
user    0m0.000s
sys     0m0.000s
\`\`\`

**解读：** \`real\`表示从命令开始到命令完成所经过的实际时间。\`user\`表示在用户空间消耗的CPU时间。\`sys\`表示在内核空间消耗的CPU时间。

#### 使用POSIX格式输出

使用\`-p\`选项输出POSIX格式时间：

\`\`\`bash
time -p ls
\`\`\`

**输出：**

\`\`\`
file1.txt
file2.txt
file3.txt
real 0.00
user 0.00
sys 0.00
\`\`\`

**解读：** \`-p\`选项以POSIX标准格式显示时间信息，更简洁直观。

#### 显示详细信息

使用\`-v\`选项输出详细的时间和资源使用信息：

\`\`\`bash
time -v ls
\`\`\`

**输出：**

\`\`\`
file1.txt
file2.txt
file3.txt

        Command being timed: "ls"
        User time (seconds): 0.00
        System time (seconds): 0.00
        Percent of CPU this job got: 100%
        Elapsed (wall clock) time (h:mm:ss or m:ss): 0:00.00
        Maximum resident set size (kbytes): 8192
        Major (requiring I/O) page faults: 0
        Minor (reclaiming a frame) page faults: 0
        Voluntary context switches: 0
        Involuntary context switches: 0
        Swaps: 0
        File system inputs: 0
        File system outputs: 0
\`\`\`

**解读：** \`-v\`选项显示了详细的资源使用情况，包括CPU时间、内存使用、页面错误等信息。

#### 自定义输出格式

使用\`--format\`选项自定义输出格式：

\`\`\`bash
time --format="%e real %U user %S sys" ls
\`\`\`

**输出：**

\`\`\`
file1.txt
file2.txt
file3.txt
0.00 real 0.00 user 0.00 sys
\`\`\`

**解读：** \`--format\`选项允许用户自定义输出格式，\`%e\`为实际时间，\`%U\`为用户时间，\`%S\`为系统时间。

### 版本差异

不同版本的\`time\`可能在输出格式和支持的选项上有所不同。您可以通过运行\`time --version\`来查看当前版本，并参阅其手册页（\`man time\`）以了解更多信息。

### 安全性和替代命令

\`time\`命令本身没有被弃用。如果需要更多高级的性能分析工具，可以考虑使用\`perf\`等工具来获取更详细的性能数据。

### 安装

\`time\`命令通常是预装的，作为GNU核心工具集的一部分。如果需要安装，可以使用以下命令：

- **Ubuntu：**

  \`\`\`bash
  sudo apt-get install time
  \`\`\`

- **RHEL：**

  \`\`\`bash
  sudo yum install time
  \`\`\`

### 权限使用

\`time\`命令本身不需要特殊的root权限，但被测量的命令可能需要。如果命令需要root权限，则需要以root用户运行\`time\`命令。

**示例：**

\`\`\`bash
sudo time ls /root
\`\`\`

**输出：**

\`\`\`
file1.txt
file2.txt

real    0m0.005s
user    0m0.000s
sys     0m0.000s
\`\`\`

**解读：** 使用\`sudo\`以root权限运行\`time\`命令来测量需要root权限访问的目录。

        `,
    };
  },
});
</script>
<style scoped></style>
